// Dependencies
import React, { useState, useEffect } from "react";
import { peerReviewAPI } from "../../../api";
import { useIntl } from "react-intl";
import clsx from "clsx";
import { isEmpty } from "lodash";
import { useHistory } from "react-router-dom";
import { firebaseFunctions } from "../../../firebase";
import { captureException } from "../../../utils/errorHandlers";
import { useQuery } from "../../../hooks";
import {
  PEER_REVIEW_TASK,
  INTERACTION_SUBTYPES,
  INTERACTION_TYPES,
  SECONDARY_SIDEBAR_ACTION_BUTTONS
} from "../../../consts";
import { handleThumbnailPanel } from "../../SharedComponents/buttons/utils";

// Redux dependencies
import { selectCourseByTaskId } from "../../../redux/coursesSlice";
import {
  selectTask,
  selectSubmission,
  selectSubmissionCollaboration,
  updateSubmissionIsChecked
} from "../../../redux/tasksSlice";
import {
  selectPeerReviewReflection,
  selectPeerReviewReply,
  selectPeerReviewReview,
  selectSubmissionFeedback,
  updateInteraction
} from "../../../redux/interactionsSlice";
import { useSelector, useDispatch } from "react-redux";

import { setBreadcrumbs } from "../../../redux/readerActionsSlice";
import { isPdfSelector } from "../../../redux/textsSlice";
import {
  selectDarkMode,
  selectIsThumbnails
} from "../../../redux/firestoreSelectors";
import useCreatePortal from "../../../hooks/useCreatePortal";

// Components
import {
  TextEditor,
  TextEditorHeader,
  TextEditorInput
} from "../../SharedComponents/textEditor";
import PeerReviewMissingReviewModal from "./PeerReviewMissingReviewModal";
import PeerReviewFeedbackValidationModal from "./PeerReviewFeedbackValidationModal";
import PeerReviewTabs from "./PeerReviewTabs";
import PeerReviewReply from "./PeerReviewReply";
import PeerReviewTabPanels from "./PeerReviewTabPanels";
import PangeaSpinner from "../../SharedComponents/PangeaSpinner";
import ScrollBox from "../../SharedComponents/ScrollBox";
import RenderEditorContent from "../../SharedComponents/RenderEditorContent";
import PDFThumbBar from "../../reader/pdf/sidebar/PDFThumbBar";
import useConvertToTimezone from "../../../hooks/useConvertToTimezone";
import { pdfThumbnailsIconClicked } from "../../../redux/layoutSlice";
import ToggleBtnConstructor from "../../SharedComponents/buttons/toggleBtnConstructor";
import { PdfControls } from "../../menus/PdfControls";

// Material UI
import makeStyles from "@mui/styles/makeStyles";
import { Box, Button } from "@mui/material";

// Styles
const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    columnGap: theme.spacing(2)
  },

  tabs: {
    gridColumnEnd: "span 12",
    zIndex: 2
  },
  grid: {
    height: "calc(100% - 50px)", //subtract the tabs
    width: `calc(100% - ${theme.spacing(3)})`, //subtract the margins
    marginInlineStart: theme.spacing(3),
    flexWrap: "nowrap"
  },
  textContainer: {
    gridColumnEnd: "span 6",
    fontSize: "22px",
    height: "calc(100vh - 164px)",
    paddingInlineStart: theme.spacing(5)
  },
  feedbackContainer: {
    gridColumnEnd: "span 3",
    background: "rgba(0,0,0, 0.08)",
    height: "calc(100vh - 164px)",
    display: "flex",
    paddingBlock: theme.spacing(4.5),
    color: theme.palette.text.blue
  },
  darkFeedbackContainer: {
    backgroundColor: theme.palette.background.sidePanel
  },

  reviewContainer: {
    gridColumnEnd: "span 3",
    height: "calc(100vh - 164px)",
    "& $header": { color: theme.palette.text.blue }
  },

  review: {
    marginBlockStart: theme.spacing(4.5)
  },

  header: {
    fontSize: "12px",
    fontWeight: 600,
    textTransform: "uppercase",
    letterSpacing: "1px"
  },
  userInput: {
    fontFamily: '"Crimson Pro" ,"Frank Ruhl Libre"',
    fontSize: 16,
    lineHeight: "24px"
  },

  paddedScrollBox: {
    paddingInlineStart: 0
  },
  lightIcon: {
    color: "#FFFFFF !important",
    backgroundColor: "transparent !important"
  },

  thumbnailContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "250px",
    gridColumnEnd: "span 2",
    height: "calc(100vh - 167px)"
  },
  textContainerWithThumbnails: {
    gridColumnEnd: "span 7"
  }
}));

export default function PeerReviewFeedback({
  currentTab,
  setCurrentTab,
  zenMode,
  setZenMode,
  saveCurrentTabLocation,
  textBookmark,
  answerBookmark,
  answerRef
}) {
  // Hooks
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { submission_id } = useQuery();

  const ActionButtonPortal = useCreatePortal(
    document && document.getElementById("global-action-btn")
  );
  const ThumbnailsPortal = useCreatePortal(
    document && document.getElementById("global-thumbnails-btn")
  );
  const PdfControlPortal = useCreatePortal(
    document && document.getElementById("global-pdfControl-btn")
  );
  // Redux state

  const darkMode = useSelector((state) => selectDarkMode(state));
  const submission = useSelector((state) =>
    selectSubmission(state, Number(submission_id))
  );
  const collaboration = useSelector((state) =>
    selectSubmissionCollaboration(state, Number(submission_id))
  );
  const course = useSelector((state) =>
    selectCourseByTaskId(state, submission.task_id)
  );
  const task = useSelector((state) => selectTask(state, submission.task_id));
  const isPdf = useSelector(isPdfSelector);

  const reflection = useSelector((state) =>
    selectPeerReviewReflection(state, Number(submission_id))
  );
  const review = useSelector((state) =>
    selectPeerReviewReview(state, collaboration.id)
  );
  const reply = useSelector((state) =>
    selectPeerReviewReply(state, Number(submission_id))
  );
  const feedback = useSelector((state) =>
    selectSubmissionFeedback(state, Number(submission_id))
  );
  const currentUser = useSelector((state) => state.user.auth);
  const readerActions = useSelector((state) => state.readerActions);
  const isThumbnailsInView = useSelector((state) => selectIsThumbnails(state));
  const isThumbnails = useSelector((state) => selectIsThumbnails(state));

  // Ephemeral state
  const [, TZDeadline] = useConvertToTimezone(
    task.collaborator_due_date,
    course.timezone
  );
  const [validationDialogOpen, setValidationDialogOpen] = useState(false);
  const [missinReviewDialogOpen, setMissinReviewDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Derived state
  const now = new Date();
  const isTeacher = course.course_role === "Teacher";
  const taskSubmitted = submission.status === "Submitted";
  const taskPendingResponse = submission.status === "pendingResponse";
  const isEditorActive = isTeacher && (taskSubmitted || taskPendingResponse);
  const isChecked = submission.is_checked;
  const isSubmitActive =
    (isTeacher && taskSubmitted) ||
    (isTeacher && taskPendingResponse && TZDeadline < now);
  const currentTabIsText = currentTab === 1;

  // Behavior
  // set breadcrumbs
  useEffect(() => {
    dispatch(
      setBreadcrumbs({
        ...readerActions,
        blue: true,
        showTextMenu: true
      })
    );
  }, [TZDeadline, dispatch, readerActions]);

  useEffect(() => {
    if (isTeacher && taskPendingResponse && TZDeadline > now)
      setMissinReviewDialogOpen(true);
  }, [TZDeadline, isTeacher, taskPendingResponse]); // now can't be in the dependency array

  useEffect(() => {
    if (!currentTabIsText && isThumbnails) {
      dispatch(pdfThumbnailsIconClicked());
    }
  }, [dispatch, currentTabIsText, isThumbnailsInView]);

  function validateFeedback() {
    if (isEmpty(feedback)) return;
    // the rich text editor returns an array of text lines
    // checkling to see if at least one line isn't empty
    const feedbackIsValid = feedback.rich_text.blocks.some(
      (element) => element.text !== ""
    );

    if (!feedbackIsValid) {
      setValidationDialogOpen(true);
    } else {
      submitFeedback();
    }
  }

  function submitFeedback() {
    setIsLoading(true);
    dispatch(
      updateSubmissionIsChecked({
        id: Number(submission_id),
        is_checked: true
      })
    );

    const taskFuncions = firebaseFunctions.httpsCallable("tasks-taskFunctions");
    taskFuncions({
      func_name: "updatePeerReviewSubmissionToChecked",
      id: Number(submission_id)
    })
      .then(() => {
        setIsLoading(false);
        // Snackbar
        history.push(`/tasks?course_id=${task.course_id}`);
      })
      .catch((err) => {
        setIsLoading(false);
        // Snackbar
        dispatch(
          updateSubmissionIsChecked({
            id: Number(submission_id),
            is_checked: false
          })
        );
        captureException(err);
      });
  }

  function createFeedback(content) {
    const { richText, plainText } = content;
    peerReviewAPI.createFeedback({
      content: plainText,
      rich_text: richText,
      course_id: course.id,
      task_id: task.id,
      submission_id: submission.id,
      text_id: task.text_id,
      interaction_type: INTERACTION_TYPES.FEEDBACK,
      interaction_subtype: INTERACTION_SUBTYPES.TASK
    });
  }

  function updateFeedback(interaction, content) {
    const { richText, plainText, wordCount } = content;
    dispatch(
      updateInteraction({
        disableRollback: true, // we don't want to to erase users input if the request fails
        interaction: interaction,
        update: {
          content: plainText,
          rich_text: richText,
          word_count: wordCount
        }
      })
    );
  }

  if (isEmpty(reflection) || isLoading) {
    return <PangeaSpinner />;
  } else {
    return (
      <>
        <PeerReviewMissingReviewModal
          dialogOpen={missinReviewDialogOpen}
          setDialogOpen={setMissinReviewDialogOpen}
        />
        <PeerReviewFeedbackValidationModal
          dialogOpen={validationDialogOpen}
          setDialogOpen={setValidationDialogOpen}
        />
        {isPdf && currentTabIsText && (
          <>
            <ThumbnailsPortal>
              <ToggleBtnConstructor
                type={SECONDARY_SIDEBAR_ACTION_BUTTONS.THUBMNAILS}
                handleBtnAction={handleThumbnailPanel}
                secondarySideBarMode={isThumbnailsInView}
                disabled={!currentTabIsText}
              />
            </ThumbnailsPortal>
            <PdfControlPortal>
              <PdfControls />
            </PdfControlPortal>
          </>
        )}
        <ActionButtonPortal>
          {isChecked && (
            <Button
              onClick={() => {
                history.push(`/tasks?course_id=${course.id}`);
              }}
              data-test-id="submit-task-button"
              size="small">
              {intl.formatMessage({
                id: "gr.confirm.btn",
                defaultMessage: "Done"
              })}
            </Button>
          )}

          {!isChecked && isSubmitActive && (
            <Button
              onClick={validateFeedback}
              data-test-id="submit-task-button"
              size="small">
              {intl.formatMessage({
                id: "task.submit",
                defaultMessage: "Submit"
              })}
            </Button>
          )}
        </ActionButtonPortal>
        <Box className={classes.container}>
          <Box className={classes.tabs}>
            <PeerReviewTabs
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              answerBookmark={answerBookmark}
              zenMode={zenMode}
              saveCurrentTabLocation={saveCurrentTabLocation}
              classes={{
                item: classes.tabPanels
              }}
            />
          </Box>
          <Box
            className={clsx(
              classes.textContainer,
              isThumbnailsInView && classes.textContainerWithThumbnails
            )}>
            <PeerReviewTabPanels
              currentTab={currentTab}
              zenMode={zenMode}
              setZenMode={setZenMode}
              textBookmark={textBookmark}
              answerRef={answerRef}
            />
          </Box>
          {isThumbnailsInView ? (
            <Box className={isThumbnailsInView && classes.thumbnailContainer}>
              <PDFThumbBar />
            </Box>
          ) : (
            <Box className={classes.reviewContainer}>
              <ScrollBox className={classes.paddedScrollBox}>
                <Box
                  className={clsx(
                    classes.review,
                    currentUser.uid === task.owner &&
                      (taskSubmitted || task.submission_status === "Graded")
                  )}>
                  <Box className={classes.header}>
                    {currentUser.uid === review.creator &&
                      PEER_REVIEW_TASK.REVIEW.MY_REVIEW}
                    {currentUser.uid !== review.creator &&
                      (review.user_name
                        ? `${review.user_name}'s review`
                        : PEER_REVIEW_TASK.REVIEW.REVIEW)}
                  </Box>
                  <Box className={classes.userInput}>
                    <RenderEditorContent>
                      {review.rich_text}
                    </RenderEditorContent>
                  </Box>
                </Box>
                {(currentUser.uid === reflection.user_uid ||
                  reply?.content) && <PeerReviewReply />}
              </ScrollBox>
            </Box>
          )}

          <ScrollBox
            className={clsx(
              classes.feedbackContainer,
              darkMode && classes.darkFeedbackContainer
            )}>
            <TextEditor
              content={feedback.rich_text}
              disabled={!isEditorActive}
              variant="contained">
              <TextEditorHeader
                title={
                  isTeacher
                    ? PEER_REVIEW_TASK.FEEDBACK.MY_FEEDBACK
                    : PEER_REVIEW_TASK.FEEDBACK.TEACHER_FEEDBACK
                }
              />
              <TextEditorInput
                fontSize={PEER_REVIEW_TASK.SIZE.SMALL}
                onChange={(content) =>
                  isEmpty(feedback)
                    ? createFeedback(content)
                    : updateFeedback(feedback, content)
                }
                placeholder={intl.formatMessage({
                  id: "task.open.placeholder.feedback",
                  defaultMessage: "Type your feedback here"
                })}
              />
            </TextEditor>
          </ScrollBox>
        </Box>
      </>
    );
  }
}
