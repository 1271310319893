// Dependencies
import React, { useState, useEffect } from "react";
import { TABLE_VIEW } from "../consts";

// Components
import { CustomIconButton } from "../CustomToggleButton";
import LineChart from "../lineChart/LineChart";
import GradeHistogram from "../histogram/GradeHistogram";

// Redux

// Material-UI
import { Box, Typography } from "@mui/material";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import TasksDropDownSelection from "../histogram/TasksDropDownSelection";
import { useGetTheme } from "../../../../hooks";

const CourseGradesReport = ({ data, courseAssignments, courseStudents }) => {
  // Hooks
  const theme = useGetTheme();
  // Redux

  //Ephemeral state
  const [reportView, setReportView] = useState(TABLE_VIEW.AVERAGE_VIEW);
  const [selectedAssignment, setSelectedAssignment] = useState(
    courseAssignments[0]
  );
  const [submissionsBySelectedAssignment, setSubmissionsBySelectedAssignment] =
    useState([]);

  // Behavior
  const averageView = reportView === TABLE_VIEW.AVERAGE_VIEW;
  const handleSubmissionsViewChange = (view) => {
    setReportView(view);
  };

  useEffect(() => {
    if (selectedAssignment?.id) {
      const filteredSubmissions = data.filter(
        (assignment) => assignment.task_id === selectedAssignment.id
      );
      setSubmissionsBySelectedAssignment(filteredSubmissions);
    }
  }, [selectedAssignment, data]);

  return (
    <Box
      sx={{
        padding: "0 24px 24px 24px",
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.border.secondary}`,
        marginBlockStart: "16px",
        borderRadius: "5px"
      }}>
      {/* HEADER */}
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "60px",
          flexFlow: "row nowrap",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: `1px solid ${theme.palette.border.secondary}`
        }}>
        {averageView ? (
          <Typography variant="subtitle1">Grades - course average</Typography>
        ) : (
          <Typography variant="subtitle1">
            Grades - course distribution
          </Typography>
        )}
      </Box>

      {/* TABLE */}
      <Box
        sx={{
          display: "flex",
          flexFlow: "column nowrap",
          width: "inherit"
        }}>
        <Box sx={{ paddingTop: "16px" }}>
          <CustomIconButton
            icon={InsertChartOutlinedIcon}
            label="Average"
            isActive={averageView}
            onClick={() => handleSubmissionsViewChange(TABLE_VIEW.AVERAGE_VIEW)}
          />
          <CustomIconButton
            icon={InsertChartIcon}
            label="Distribution"
            isActive={!averageView}
            onClick={() =>
              handleSubmissionsViewChange(TABLE_VIEW.DISTRIBUTION_VIEW)
            }
          />
          {!averageView && (
            <TasksDropDownSelection
              assignments={courseAssignments}
              setSelectedAssignment={setSelectedAssignment}
              selectedAssignment={selectedAssignment}
            />
          )}
        </Box>
        <Box sx={{ margin: "24px 0" }}>
          {averageView ? (
            <LineChart data={data} courseAssignments={courseAssignments} />
          ) : (
            <GradeHistogram
              data={submissionsBySelectedAssignment}
              courseStudents={courseStudents}
              selectedAssignment={selectedAssignment}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CourseGradesReport;
