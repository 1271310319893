// Dependencies
import React, { useState, useEffect, useCallback, useMemo } from "react";
import ePub from "epubjs";
import { useIntl } from "react-intl";
import { Box, Paper } from "@mui/material";
import { Label, Marker, TitleText } from "./TImeManagmentStats";
import { makeStyles } from "@mui/styles";
import { useChartDimensions } from "../../../hooks";
import PieChart from "./PieChart";

// Styles
const useStyles = makeStyles((theme) => ({
  textTitle: {
    flexBasis: "100%",
    marginBottom: "32px"
  },
  legend: {
    display: "flex",
    flexDirection: "column"
  },
  item: {
    width: "100%",
    padding: "24px",
    border: "1px solid rgba(0, 0, 0, 0.12)",

    "&:not(:last-child)": {
      borderBottom: "0"
    }
  },
  chart: {
    padding: "0",
    minHeight: "300px",
    height: "100%"
  },
  label: {
    fontSize: "16px !important",
    display: "inline-block",
    textAlign: "left",
    verticalAlign: "middle",
    "& span": {
      fontSize: "16px !important"
    }
  },
  legendContainer: {}
}));

export default function CitationChart({ submissions, question, answers, i }) {
  // Hooks
  const classes = useStyles();

  // Ephemeral State
  const [stats, setStats] = useState([]);
  const intl = useIntl();
  // Derived state

  const subCompare = (needle, haystack, min_substring_length = 1) => {
    // Search possible substrings from largest to smallest:
    for (var i = needle.length; i >= min_substring_length; i--) {
      for (var j = 0; j <= needle.length - i; j++) {
        var substring = needle.substr(j, i);
        var k = haystack.indexOf(substring);
        if (k != -1) {
          return {
            found: 1,
            substring: substring,
            needleIndex: j,
            haystackIndex: k
          };
        }
      }
    }
    return {
      found: 0
    };
  };
  const EpubCFI = useMemo(() => new ePub.CFI(), []);

  const calcScore = useCallback(
    (question, answer) => {
      if (!question || !answer) return;
      // The theacer's answer in currently saved in the interaction metadata as quotes:[{text: String, cfi: String}]
      const teacherAnswer = question.quotes;

      let tempScore = 0;
      // ? not sure if I need to group all the student's cfi or not
      // if (!answer.quotes || !answer.quotes.length) return 0;
      if (!answer.length) return 0;
      if (!teacherAnswer || !teacherAnswer.length) return 1;

      let teacherSortedQuotes = [...teacherAnswer].sort(function (a, b) {
        return EpubCFI.compare(a.cfi, b.cfi);
      });

      let studentSorted = [...answer].sort(function (a, b) {
        return EpubCFI.compare(a.cfi, b.cfi);
      });

      let studentInd = 0;
      let teacherInd = 0;

      while (
        studentInd < studentSorted.length &&
        teacherInd < teacherSortedQuotes.length
      ) {
        let currentStudentRange = EpubCFI.parse(studentSorted[studentInd].cfi); // EpubCFI.getRange(studentSorted[studentInd].cfi);
        let currentTeacherRange = EpubCFI.parse(
          teacherSortedQuotes[teacherInd].cfi
        );

        const cfiRange =
          "epubcfi(/6/4[chap01ref]!/4[body01]/10[para05],/2/1:1,/3:4)";

        let cfiParts = studentSorted[studentInd].cfi.split(","); // cfiBase:  cfiParts[0]
        let studentStartCfi = cfiParts[0] + cfiParts[1] + ")"; // start: 'epubcfi(/6/4[chap01ref]!/4[body01]/10[para05]/2/1:1)'
        let studentEndCfi = cfiParts[0] + cfiParts[2];

        cfiParts = teacherSortedQuotes[teacherInd].cfi.split(","); // cfiBase:  cfiParts[0]
        let teacherStartCfi = cfiParts[0] + cfiParts[1] + ")"; // start: 'epubcfi(/6/4[chap01ref]!/4[body01]/10[para05]/2/1:1)'
        let teacherEndCfi = cfiParts[0] + cfiParts[2];
        if (EpubCFI.compare(studentEndCfi, teacherStartCfi) <= 0) {
          studentInd++;
        } else if (EpubCFI.compare(teacherEndCfi, studentStartCfi) <= 0) {
          teacherInd++;
        } else {
          let needle = studentSorted[studentInd].content;
          let haystack = teacherSortedQuotes[teacherInd].content;

          if (haystack.length < needle.length) {
            haystack = needle;
            needle = teacherSortedQuotes[teacherInd].content;
          }
          let retVal = subCompare(needle, haystack);
          if (retVal.found) {
            tempScore += retVal.substring.length;
          }

          if (EpubCFI.compare(studentEndCfi, teacherEndCfi) >= 0) {
            studentInd++;
          } else {
            teacherInd++;
          }
        }
      }
      let totalTeacher = teacherSortedQuotes.reduce(
        (acc, curr) => acc + curr.content.length,
        0
      );

      return (1.0 * tempScore) / totalTeacher;
    },
    [EpubCFI]
  );

  useEffect(() => {
    const PARTIAL_THRESHOLD = 20; //TODO - move to consts
    if (submissions.length) {
      let submissionsToAnswer = {};
      answers.forEach((answer) => {
        if (!(answer.submission_id in submissionsToAnswer)) {
          submissionsToAnswer[answer.submission_id] = [];
        }
        submissionsToAnswer[answer.submission_id].push(answer);
      });
      let matches = Object.values(submissionsToAnswer).map(
        (submissionAnswers) => {
          return calcScore(question, submissionAnswers) * 100;
        }
      );

      setStats([
        {
          cat: intl.formatMessage({
            id: "task.statistics.rightOnTheSpot",
            defaultMessage: "Right on the spot"
          }),
          val: matches.filter((a) => a > 70).length,
          color: "#168fee"
        },
        {
          cat: intl.formatMessage({
            id: "task.statistics.prettyClose",
            defaultMessage: "Partial match"
          }),
          val: matches.filter((a) => a > PARTIAL_THRESHOLD && a < 70).length,
          color: "#BCD9F1"
        },
        {
          cat: intl.formatMessage({
            id: "task.statistics.wayOff",
            defaultMessage: "Divergent selections"
          }),
          val: matches.filter((a) => a < PARTIAL_THRESHOLD).length,
          color: "#FF6432"
        }
      ]);
    } else {
      setStats([
        {
          cat: intl.formatMessage({
            id: "task.statistics.noSubmissionsYet",
            defaultMessage: "No submissions yet"
          }),
          val: 1,
          color: "#D8D8D8"
        }
      ]);
    }
  }, [intl, answers, calcScore, question, submissions]);

  return (
    <Paper elevation={0}>
      {/* Render the title */}
      <TitleText
        text={intl.formatMessage({
          id: "task.statistics.performance",
          defaultMessage: "Student performance"
        })}
      />
      <Box sx={{ display: "inline-flex" }}>
        {/* Render the legend */}
        <Box className={classes.legendContainer}>
          <Box className={classes.legend}>
            {stats
              .filter((s) => s.val > 0)
              .map((dataPoint, index) => (
                <Box key={index} className={classes.item}>
                  <Marker color={dataPoint.color} />
                  <Label>{dataPoint.cat}</Label>
                </Box>
              ))}
          </Box>
        </Box>
        {/* Render the chart using D3.js */}
        <PieChart data={stats} width="100%" height={305} />
      </Box>
    </Paper>
  );
}
