import { useFirestore, useFirestoreDocData } from "reactfire";
import { doc } from "firebase/firestore";

export function usePromptTemplate(botName) {
  const firestore = useFirestore();

  const eMentorSettings = doc(firestore, "openAiPrompts", botName);
  const { data } = useFirestoreDocData(eMentorSettings);
  // Firebase functions calls
  return data;
}
