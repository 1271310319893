// Dependencies
import React from "react";
import { useIntl } from "react-intl";

// Redux dependencies
import { useDispatch, useSelector } from "react-redux";
import { updateAlertsDuration } from "../../redux/userSlice";

import makeStyles from "@mui/styles/makeStyles";
import { MenuItem, TextField } from "@mui/material";
import { selectAlertsDuration } from "../../redux/firestoreSelectors";

//Styles
const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: "100%"
  }
}));

export default function ProfilePage() {
  //Hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  //Ephemeral state

  //Redux state
  const alertsDuration = useSelector((state) => selectAlertsDuration(state));

  //Variables
  const durations = [5, 10, 20, 30];

  //Behavior

  // Render
  return (
    <TextField
      fullWidth
      className={classes.select}
      labelId="alert-duration-select-lable"
      id="alert-duration-select"
      label={intl.formatMessage({
        id: "profilePage.alertsDuration",
        defaultMessage: "Alerts duration"
      })}
      value={alertsDuration / 1000}
      select
      variant="outlined">
      {durations.map((duration, index) => {
        return (
          <MenuItem
            key={index}
            onClick={() => {
              dispatch(updateAlertsDuration(duration * 1000));
            }}
            value={duration}>
            {`${duration} ${intl.formatMessage({
              id: "profilePage.seconds",
              defaultMessage: "seconds"
            })}`}
          </MenuItem>
        );
      })}
    </TextField>
  );
}
