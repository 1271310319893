// Dependencies
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";

// Redux Dependencies

//Components
import SubmissionDetails from "./SubmissionDetails";

import makeStyles from "@mui/styles/makeStyles";
import ClearIcon from "@mui/icons-material/Clear";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";

// Styles
const useStyles = makeStyles((theme) => ({
  tableContainer: {
    paddingLeft: "10%"
  },
  tableRow: {
    // Equelize the height of the table rows with and without action buttons
    "& > *": {
      padding: "8px",
      "& > *": {
        paddingTop: "12px",
        paddingBottom: "12px"
      }
    }
  },
  pointer: {
    cursor: "pointer"
  },
  chip: { margin: "-8px" },
  missed: {
    backgroundColor: "#f9413e"
  },
  late: {
    backgroundColor: "#fcb63f"
  },
  pending: {
    border: "1px solid black"
  },
  submitted: {
    backgroundColor: "transparent",
    color: "#0eac72",
    border: "1px solid #0eac72"
  },
  graded: {
    backgroundColor: "#0eac72"
  },
  sent: {
    backgroundColor: theme.palette.primary,
    color: theme.palette.text.primary
  },
  add: {
    border: `1px solid ${theme.palette.primary}`,
    color: theme.palette.primary
  },
  actions: {
    textAlign: "end",
    paddingRight: 0
  },
  link: {
    color: theme.palette.text.primary,
    display: "block"
  },
  actionIcon: {
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  grade: {
    textAlign: "center"
  }
}));

export default function TaskDetails({ course, task, graders, submissions }) {
  //Hooks
  const classes = useStyles();
  const history = useHistory();

  // Firestore State

  // Ephemeral State
  const [modalVisible, setModalVisible] = useState(false);

  // Variables

  // Behavior
  const closeModal = () => {
    setModalVisible(false);
  };

  // Render
  return (
    <>
      <Dialog
        open={!!modalVisible}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ className: classes.modal }}>
        <ClearIcon className={classes.modalCancel} onClick={closeModal} />
        <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Another teacher already started grading this submissions, Do you
            wish to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.modalActions}>
          <Button
            onClick={closeModal}
            className={clsx(classes.button, classes.buttonDiscard)}>
            Close
          </Button>

          <Button
            className={classes.button}
            onClick={() => {
              setModalVisible(false);
              history.push(`/task?submission_id=${modalVisible.submission_id}`);
            }}
            color="primary"
            autoFocus>
            Continue anyway
          </Button>
        </DialogActions>
      </Dialog>

      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography>
                  <FormattedMessage
                    id="tasks.manager.student"
                    defaultMessage="Student"
                  />
                </Typography>
              </TableCell>
              {task.task_type === "peerReview" ? (
                <>
                  <TableCell>
                    <Typography component="span" variant="body2">
                      Reflection
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>Review</Typography>
                  </TableCell>
                </>
              ) : (
                <TableCell>
                  <Typography component="span" variant="body2">
                    <FormattedMessage
                      id="tasks.manager.status"
                      defaultMessage="Status"
                    />
                  </Typography>
                </TableCell>
              )}
              <TableCell>
                <Typography component="span" variant="body2">
                  <FormattedMessage
                    id="tasks.manager.submissionDate"
                    defaultMessage="Submission date"
                  />
                </Typography>
              </TableCell>
              <TableCell>
                <Typography component="span" variant="body2">
                  <FormattedMessage
                    id="tasks.manager.feedback"
                    defaultMessage="Feedback"
                  />
                </Typography>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {submissions.map(
              (submission) =>
                !submission.related_submission_id && (
                  <SubmissionDetails
                    key={submission.id}
                    submission={submission}
                    course={course}
                    task={task}
                    graders={graders}
                    setModalVisible={setModalVisible}
                  />
                )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
