// Dependencies
import React, { useEffect, useRef } from "react";
import { useRendition } from "../../../RenditionContext";

// Redux dependencies
import { useSelector } from "react-redux";
import { selectCurrentText, isPdfSelector } from "../../../redux/textsSlice";
import { selectZoom } from "../../../redux/pdfSlice";

// Components
import EpubView from "../../reader/EpubView/EpubView";
import PdfView from "../../reader/pdf/PdfView";

import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";
import { selectDarkMode } from "../../../redux/firestoreSelectors";

// Styles
const useStyles = makeStyles((theme) => ({
  tabPanel: {
    fontSize: "22px",
    height: "100%",
    overflow: "auto",
    position: "relative"
  }
}));

export default function PeerReviewReaderPanel({ textBookmark }) {
  // Hooks
  const classes = useStyles();
  const epubContainerRef = useRef(null);
  const { rendition } = useRendition();

  // Redux state
  const darkMode = useSelector((state) => selectDarkMode(state));
  const fontSizeValue = useSelector((state) => state.user.userProfile.fontSize);
  const fontSize = useSelector((state) => state.user.fontSizeOptions);
  const text = useSelector(selectCurrentText);
  const isPdf = useSelector(isPdfSelector);
  const zoom = useSelector(selectZoom);

  // Ephemeral state

  // Variables

  // Behavior
  useEffect(() => {
    rendition && rendition.themes.default(fontSize[fontSizeValue]);
  }, [fontSize, fontSizeValue, rendition]);

  //render
  return (
    <Box
      className={classes.tabPanel}
      role="tabpanel"
      id={`tabpanel-${1}`}
      aria-labelledby={`text`}>
      {isPdf ? (
        <PdfView
          zoom={zoom}
          isVisible={true}
          url={text.url}
          handleTextSelected={() => {}}
          highlights={[]}
          underlines={[]}
        />
      ) : (
        <EpubView
          fullSize={false}
          fontSize={fontSize[fontSizeValue]}
          epubOptions={{ flow: "scrolled-doc" }}
          darkMode={darkMode}
          textDirection={text.text_language === "he" ? "rtl" : "ltr"}
          url={text.url}
          text_id={Number(text.id)}
          location={textBookmark}
          defaultLocation={text.file_location}
          containerRef={epubContainerRef}
          bodyClassName="scrollBox"
        />
      )}
    </Box>
  );
}
