/***
 *
 * This code was removed from pdf.js.
 * But it is necessary for smooth text selection
 * in chrome
 * (https://github.com/mozilla/pdf.js/issues/9843)
 * , copying it from
 * https://github.com/mozilla/pdf.js/blob/v2.14.305/src/display/text_layer.js#L284
 * to here so it won't block react-pdf upgrades.
 *
 * This is stil tightly bound to the pdf.js implemention
 * of the renderer Task, but
 * ¯\_(ツ)_/¯
 */
import { Util } from "pdfjs-dist";

function findPositiveMin(ts, offset, count) {
  let result = 0;
  for (let i = 0; i < count; i++) {
    const t = ts[offset++];
    if (t > 0) {
      result = result ? Math.min(t, result) : t;
    }
  }
  return result;
}

function expandBounds(width, height, boxes) {
  const bounds = boxes.map(function (box, i) {
    return {
      x1: box.left,
      y1: box.top,
      x2: box.right,
      y2: box.bottom,
      index: i,
      x1New: undefined,
      x2New: undefined
    };
  });
  expandBoundsLTR(width, bounds);

  const expanded = new Array(boxes.length);
  for (const b of bounds) {
    const i = b.index;
    expanded[i] = {
      left: b.x1New,
      top: 0,
      right: b.x2New,
      bottom: 0
    };
  }

  // Rotating on 90 degrees and extending extended boxes. Reusing the bounds
  // array and objects.
  boxes.map(function (box, i) {
    const e = expanded[i],
      b = bounds[i];
    b.x1 = box.top;
    b.y1 = width - e.right;
    b.x2 = box.bottom;
    b.y2 = width - e.left;
    b.index = i;
    b.x1New = undefined;
    b.x2New = undefined;
  });
  expandBoundsLTR(height, bounds);

  for (const b of bounds) {
    const i = b.index;
    expanded[i].top = b.x1New;
    expanded[i].bottom = b.x2New;
  }
  return expanded;
}

function expandBoundsLTR(width, bounds) {
  // Sorting by x1 coordinate and walk by the bounds in the same order.
  bounds.sort(function (a, b) {
    return a.x1 - b.x1 || a.index - b.index;
  });

  // First we see on the horizon is a fake boundary.
  const fakeBoundary = {
    x1: -Infinity,
    y1: -Infinity,
    x2: 0,
    y2: Infinity,
    index: -1,
    x1New: 0,
    x2New: 0
  };
  const horizon = [
    {
      start: -Infinity,
      end: Infinity,
      boundary: fakeBoundary
    }
  ];

  for (const boundary of bounds) {
    // Searching for the affected part of horizon.
    // TODO red-black tree or simple binary search
    let i = 0;
    while (i < horizon.length && horizon[i].end <= boundary.y1) {
      i++;
    }
    let j = horizon.length - 1;
    while (j >= 0 && horizon[j].start >= boundary.y2) {
      j--;
    }

    let horizonPart, affectedBoundary;
    let q,
      k,
      maxXNew = -Infinity;
    for (q = i; q <= j; q++) {
      horizonPart = horizon[q];
      affectedBoundary = horizonPart.boundary;
      let xNew;
      if (affectedBoundary.x2 > boundary.x1) {
        // In the middle of the previous element, new x shall be at the
        // boundary start. Extending if further if the affected boundary
        // placed on top of the current one.
        xNew =
          affectedBoundary.index > boundary.index
            ? affectedBoundary.x1New
            : boundary.x1;
      } else if (affectedBoundary.x2New === undefined) {
        // We have some space in between, new x in middle will be a fair
        // choice.
        xNew = (affectedBoundary.x2 + boundary.x1) / 2;
      } else {
        // Affected boundary has x2new set, using it as new x.
        xNew = affectedBoundary.x2New;
      }
      if (xNew > maxXNew) {
        maxXNew = xNew;
      }
    }

    // Set new x1 for current boundary.
    boundary.x1New = maxXNew;

    // Adjusts new x2 for the affected boundaries.
    for (q = i; q <= j; q++) {
      horizonPart = horizon[q];
      affectedBoundary = horizonPart.boundary;
      if (affectedBoundary.x2New === undefined) {
        // Was not set yet, choosing new x if possible.
        if (affectedBoundary.x2 > boundary.x1) {
          // Current and affected boundaries intersect. If affected boundary
          // is placed on top of the current, shrinking the affected.
          if (affectedBoundary.index > boundary.index) {
            affectedBoundary.x2New = affectedBoundary.x2;
          }
        } else {
          affectedBoundary.x2New = maxXNew;
        }
      } else if (affectedBoundary.x2New > maxXNew) {
        // Affected boundary is touching new x, pushing it back.
        affectedBoundary.x2New = Math.max(maxXNew, affectedBoundary.x2);
      }
    }

    // Fixing the horizon.
    const changedHorizon = [];
    let lastBoundary = null;
    for (q = i; q <= j; q++) {
      horizonPart = horizon[q];
      affectedBoundary = horizonPart.boundary;
      // Checking which boundary will be visible.
      const useBoundary =
        affectedBoundary.x2 > boundary.x2 ? affectedBoundary : boundary;
      if (lastBoundary === useBoundary) {
        // Merging with previous.
        changedHorizon[changedHorizon.length - 1].end = horizonPart.end;
      } else {
        changedHorizon.push({
          start: horizonPart.start,
          end: horizonPart.end,
          boundary: useBoundary
        });
        lastBoundary = useBoundary;
      }
    }
    if (horizon[i].start < boundary.y1) {
      changedHorizon[0].start = boundary.y1;
      changedHorizon.unshift({
        start: horizon[i].start,
        end: boundary.y1,
        boundary: horizon[i].boundary
      });
    }
    if (boundary.y2 < horizon[j].end) {
      changedHorizon[changedHorizon.length - 1].end = boundary.y2;
      changedHorizon.push({
        start: boundary.y2,
        end: horizon[j].end,
        boundary: horizon[j].boundary
      });
    }

    // Set x2 new of boundary that is no longer visible (see overlapping case
    // above).
    // TODO more efficient, e.g. via reference counting.
    for (q = i; q <= j; q++) {
      horizonPart = horizon[q];
      affectedBoundary = horizonPart.boundary;
      if (affectedBoundary.x2New !== undefined) {
        continue;
      }
      let used = false;
      for (
        k = i - 1;
        !used && k >= 0 && horizon[k].start >= affectedBoundary.y1;
        k--
      ) {
        used = horizon[k].boundary === affectedBoundary;
      }
      for (
        k = j + 1;
        !used && k < horizon.length && horizon[k].end <= affectedBoundary.y2;
        k++
      ) {
        used = horizon[k].boundary === affectedBoundary;
      }
      for (k = 0; !used && k < changedHorizon.length; k++) {
        used = changedHorizon[k].boundary === affectedBoundary;
      }
      if (!used) {
        affectedBoundary.x2New = maxXNew;
      }
    }

    Array.prototype.splice.apply(
      horizon,
      [i, j - i + 1].concat(changedHorizon)
    );
  }

  // Set new x2 for all unset boundaries.
  for (const horizonPart of horizon) {
    const affectedBoundary = horizonPart.boundary;
    if (affectedBoundary.x2New === undefined) {
      affectedBoundary.x2New = Math.max(width, affectedBoundary.x2);
    }
  }
}

function applyTransform(p, m) {
  const xt = p[0] * m[0] + p[1] * m[2] + m[4];
  const yt = p[0] * m[1] + p[1] * m[3] + m[5];
  return [xt, yt];
}

const drawRectangles = (bounds) => {
  bounds.forEach((b, i) => {
    const parent = document.getElementsByClassName("textLayer")[0];
    const elem = document.createElement("div");
    elem.className = "bound_" + i;
    elem.style.border = "1px solid red";
    elem.style.position = "absolute";
    elem.style.left = b.left + "px";
    elem.style.width = b.right - b.left + "px";
    elem.style.top = b.top + "px";
    elem.style.height = b.bottom - b.top + "px";
    parent.appendChild(elem);
  });
};

function expand(task, bounds, { viewport }) {
  //drawRectangles(bounds);
  const expanded = expandBounds(viewport.width, viewport.height, bounds);
  for (let i = 0; i < expanded.length; i++) {
    const div = bounds[i].div;
    const divProperties = task._textDivProperties.get(div);
    if (divProperties.angle === 0) {
      divProperties.paddingLeft = bounds[i].left - expanded[i].left;
      divProperties.paddingTop = bounds[i].top - expanded[i].top;
      divProperties.paddingRight = expanded[i].right - bounds[i].right;
      divProperties.paddingBottom = expanded[i].bottom - bounds[i].bottom;
      task._textDivProperties.set(div, divProperties);
      continue;
    }
    // Box is rotated -- trying to find padding so rotated div will not
    // exceed its expanded bounds.
    const e = expanded[i],
      b = bounds[i];
    const m = b.m,
      c = m[0],
      s = m[1];
    // Finding intersections with expanded box.
    const points = [[0, 0], [0, b.size[1]], [b.size[0], 0], b.size];
    const ts = new Float64Array(64);
    for (let j = 0, jj = points.length; j < jj; j++) {
      const t = applyTransform(points[j], m);
      ts[j + 0] = c && (e.left - t[0]) / c;
      ts[j + 4] = s && (e.top - t[1]) / s;
      ts[j + 8] = c && (e.right - t[0]) / c;
      ts[j + 12] = s && (e.bottom - t[1]) / s;

      ts[j + 16] = s && (e.left - t[0]) / -s;
      ts[j + 20] = c && (e.top - t[1]) / c;
      ts[j + 24] = s && (e.right - t[0]) / -s;
      ts[j + 28] = c && (e.bottom - t[1]) / c;

      ts[j + 32] = c && (e.left - t[0]) / -c;
      ts[j + 36] = s && (e.top - t[1]) / -s;
      ts[j + 40] = c && (e.right - t[0]) / -c;
      ts[j + 44] = s && (e.bottom - t[1]) / -s;

      ts[j + 48] = s && (e.left - t[0]) / s;
      ts[j + 52] = c && (e.top - t[1]) / -c;
      ts[j + 56] = s && (e.right - t[0]) / s;
      ts[j + 60] = c && (e.bottom - t[1]) / -c;
    }
    // Not based on math, but to simplify calculations, using cos and sin
    // absolute values to not exceed the box (it can but insignificantly).
    const boxScale = 1 + Math.min(Math.abs(c), Math.abs(s));
    divProperties.paddingLeft = findPositiveMin(ts, 32, 16) / boxScale;
    divProperties.paddingTop = findPositiveMin(ts, 48, 16) / boxScale;
    divProperties.paddingRight = findPositiveMin(ts, 0, 16) / boxScale;
    divProperties.paddingBottom = findPositiveMin(ts, 16, 16) / boxScale;
    task._textDivProperties.set(div, divProperties);
  }
}

const getBounds = (task) => {
  const textDivs = task._textDivs;
  const textDivProperties = task._textDivProperties;
  return textDivs.flatMap((value, i) => {
    const divProperties = textDivProperties.get(value);
    if (divProperties.hasText) {
      let angleCos = 1,
        angleSin = 0;
      const angle = divProperties.angle;
      if (angle !== 0) {
        angleCos = Math.cos(angle);
        angleSin = Math.sin(angle);
      }
      divProperties.originalTransform = value.style.transform;

      let scale = 1;
      const parts = /scaleX\((\d*\.*\d+)\)/.exec(
        divProperties.originalTransform
      );
      if (parts) {
        scale = parseFloat(parts[1]);
      }
      const divWidth = value.offsetWidth * scale;
      const divHeight = value.offsetHeight;

      const left = value.offsetLeft;
      const top = value.offsetTop;
      let m, b;
      if (angle !== 0) {
        m = [angleCos, angleSin, -angleSin, angleCos, left, top];
        b = Util.getAxialAlignedBoundingBox([0, 0, divWidth, divHeight], m);
      } else {
        b = [left, top, left + divWidth, top + divHeight];
      }
      return [
        {
          left: b[0],
          top: b[1],
          right: b[2],
          bottom: b[3],
          div: value,
          size: [divWidth, divHeight],
          m
        }
      ];
    }
    return [];
  });
};

export const expandTextDivs = (textLayerRenderer, { viewport }) => {
  const expandDivs = true;
  const bounds = getBounds(textLayerRenderer, { viewport });
  if (bounds !== null) {
    expand(textLayerRenderer, bounds, { viewport });
    textLayerRenderer._bounds = null;
  }
  const transformBuf = [],
    paddingBuf = [];

  for (let i = 0, ii = textLayerRenderer._textDivs.length; i < ii; i++) {
    const div = textLayerRenderer._textDivs[i];
    const divProps = textLayerRenderer._textDivProperties.get(div);

    if (!divProps.hasText) {
      continue;
    }
    let scale = 1; // viewport.scale;
    if (expandDivs) {
      transformBuf.length = 0;
      paddingBuf.length = 0;

      if (divProps.originalTransform) {
        const parts = /scaleX\((\d*\.*\d+)\)/.exec(divProps.originalTransform);
        if (parts) {
          scale = parseFloat(parts[1]);
        }
        transformBuf.push(divProps.originalTransform);
      }
      if (divProps.paddingTop > 0) {
        paddingBuf.push(`${divProps.paddingTop}px`);
        transformBuf.push(`translateY(${-divProps.paddingTop}px)`);
      } else {
        paddingBuf.push(0);
      }
      if (divProps.paddingRight > 0) {
        paddingBuf.push(`${divProps.paddingRight / scale}px`);
      } else {
        paddingBuf.push(0);
      }
      if (divProps.paddingBottom > 0) {
        paddingBuf.push(`${divProps.paddingBottom}px`);
      } else {
        paddingBuf.push(0);
      }
      if (divProps.paddingLeft > 0) {
        paddingBuf.push(`${divProps.paddingLeft / scale}px`);
        transformBuf.push(`translateX(${-divProps.paddingLeft / scale}px)`);
      } else {
        paddingBuf.push(0);
      }

      div.style.padding = paddingBuf.join(" ");
      if (transformBuf.length) {
        div.style.transform = transformBuf.join(" ");
      }
    } else {
      div.style.padding = null;
      div.style.transform = divProps.originalTransform;
    }
  }
};
