// Dependencies
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useTextEditorContext } from "./TextEditor";
import { generateEditorOutput, moveFocusToEnd } from "./utils";

import {
  Editor,
  EditorState,
  Modifier,
  RichUtils,
  getDefaultKeyBinding
} from "draft-js";
import "draft-js/dist/Draft.css";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { setSelection } from "../../../redux/LinkSlice";
import { setSelectedInteractionForChat } from "../../../redux/interactionsSlice";

import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";

// Styles
const useStyles = makeStyles((theme) => ({
  editor: {
    flex: 1,
    overflow: "auto",
    fontSize: 22,
    lineHeight: "32px",
    letterSpacing: "0.275px",
    fontFamily: theme.typography.fontFamily
  },
  outlined: {
    paddingBlock: theme.spacing(2), // this is smaller than the inline padding because the line height adds to the padding
    paddingInline: theme.spacing(3)
  },
  contained: {},
  smallText: {
    fontSize: 16,
    lineHeight: "24px",
    letterSpacing: "0.15px"
  }
}));
function TextEditorInput({
  fontSize = "small",
  placeholder,
  onChange,
  onEveryChange = (newState) => {},
  className = false,
  onEnterPress = false,
  shiftEnterNewLine = false
}) {
  //Hooks
  const dispatch = useDispatch();
  const classes = useStyles();
  const debounce = useRef(null);
  const {
    editorRef,
    editorState,
    setEditorState,
    disabled,
    variant,
    resetEditorState
  } = useTextEditorContext();

  // Redux state
  const selected = useSelector((state) => state.link.selected);
  const chatInteraction = useSelector(
    (state) => state.interactions.selectedInteractionForChat
  );
  // Ephemeral state

  // Variables
  const smallText = Boolean(fontSize === "small");

  // Behavior
  function addText(text, maintainCursorPosition = true, replaceLength = 0) {
    const selection = editorState.getSelection();
    const content = editorState.getCurrentContent();

    let baseEditorState;
    if (replaceLength > 0) {
      const replaceSelection = selection.merge({
        anchorOffset: this.cursorPosition(),
        focusOffset: this.cursorPosition() + replaceLength
      });
      baseEditorState = EditorState.push(
        editorState,
        Modifier.replaceText(content, replaceSelection, text),
        "paste"
      );
    } else {
      baseEditorState = EditorState.push(
        editorState,
        Modifier.insertText(content, selection, text),
        "paste"
      );
    }
    if (!maintainCursorPosition) {
      return baseEditorState;
    }
    let output = generateEditorOutput(baseEditorState);
    debouncedOnChange(output);
    setEditorState(baseEditorState);
    dispatch(setSelectedInteractionForChat({}));
  }
  useEffect(() => {
    if (editorRef && editorRef.current && editorRef.current.focus) {
      editorRef.current.focus();
    } // focus the cursor in the input field on edit start
  }, [editorRef]);

  useEffect(() => {
    if (Object.prototype.hasOwnProperty.call(chatInteraction, "content")) {
      addText(chatInteraction.content);
    }
  }, [chatInteraction]);

  function debouncedOnChange(output) {
    if (!onChange) return;
    clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      onChange(output);
    }, 1000);
  }

  function handleChange(newState) {
    // The onChange event fires on both content and selection change
    const currentContent = editorState.getCurrentContent();
    const newContent = newState.getCurrentContent();
    const newSelectionState = newState.getSelection();

    if (selected !== newSelectionState.isCollapsed()) {
      dispatch(
        setSelection({
          selected: !newSelectionState.isCollapsed()
          // selectionContext: componentContext
        })
      );
    }

    const output = generateEditorOutput(newState);

    if (currentContent !== newContent) {
      if (onEveryChange) {
        onEveryChange(output);
      }
      debouncedOnChange(output);
    }

    if (
      currentContent.getBlockMap().size === 1 &&
      newContent.getBlockMap().size === 1 &&
      newContent.getBlockMap().first().getText().length === 1
    ) {
      setEditorState(moveFocusToEnd(newState));
    } else {
      setEditorState(newState);
    }
  }

  const myKeyBindingFn = (e) => {
    if (e.keyCode === 13 && !e.altKey && !e.ctrlKey && !e.metaKey) {
      if (shiftEnterNewLine && e.shiftKey) {
        return "newline";
      } else if (!!onEnterPress && !e.shiftKey) {
        return "enter";
      }
    }
    return getDefaultKeyBinding(e);
  };

  const handleKeyCommand = (command) => {
    if (command === "newline") {
      const newEditorState = RichUtils.insertSoftNewline(editorState);
      if (newEditorState !== editorState) {
        handleChange(newEditorState);
      }
      return "handled";
    } else if (command === "enter" && !!onEnterPress) {
      onEnterPress(generateEditorOutput(editorState));
      resetEditorState();
      return "handled";
    }
    return "not-handled";
  };

  //Render
  return (
    <Box
      className={clsx(
        classes.editor,
        classes[variant],
        smallText && classes.smallText,
        className
      )}
      onClick={(event) => {
        //TODO: check for a11y
        if (
          editorRef.current &&
          !editorRef.current.editor.contains(event.target)
        ) {
          editorRef.current.focus();
          setEditorState(moveFocusToEnd(editorState));
        }
      }}>
      <Editor
        readOnly={disabled}
        placeholder={!disabled && placeholder}
        spellCheck={!disabled}
        editorState={editorState}
        onChange={handleChange}
        ref={editorRef}
        onEditorStateChange={setEditorState}
        handleKeyCommand={handleKeyCommand}
        keyBindingFn={myKeyBindingFn}
        ariaLabel={"Chat input"}
      />
    </Box>
  );
}

TextEditorInput.propTypes = {
  onChange: PropTypes.func,
  onEnterPress: PropTypes.func,
  onEveryChange: PropTypes.func,
  shiftEnterNewLine: PropTypes.bool,
  fontSize: PropTypes.oneOf(["large", "small"]),
  placeholder: PropTypes.string
};

export default TextEditorInput;
