// Dependencies
import React, { useState, useEffect } from "react";
import { v4 as uuid } from "uuid";
import clsx from "clsx";
import { useGetTheme, useQuery } from "../../../hooks";
import { FormattedMessage } from "react-intl";
import ReactReaderActions from "../../annotations/ReactReaderActions";
import ePub from "epubjs";
import { removeAllAnotationsOfType } from "../../reader/utils";
import { ANNOTATION_TYPES } from "../../../consts";
// Redux Dependencies
import { useSelector, useDispatch } from "react-redux";
import { closeAnnotatorBar } from "../../../redux/highlightSlice";
import { isPdfSelector } from "../../../redux/textsSlice";
import { selectSubmission } from "../../../redux/tasksSlice";

// Components
import { getHighlightColor } from "../../../utils/colors";
// Material UI
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Typography
} from "@mui/material";
import DialogContentBook from "../../SharedComponents/DialogContentBook";
import {
  selectDarkMode,
  selectTextDirection
} from "../../../redux/firestoreSelectors";

//Style
const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    background: "#168FEE",
    color: "#ffffff"
  },
  green: {
    background: "#5ec891"
  },
  dialogHeader: {},
  dialogActions: {
    justifyContent: "left",
    position: "relative"
  },
  dialogActionsRtl: {
    justifyContent: "right",
    position: "relative"
  },

  blue: {
    color: "#168FEE"
  },
  dialogBtn: {
    position: "absolute",
    right: "16px",
    color: "#ffffff"
  },
  dialog: {
    padding: 0,
    zIndex: 10,
    position: "relative",
    overflow: "hidden"
  },
  pdfDialog: {
    padding: "0px"
  },
  expandMsg: {
    cursor: "pointer",
    textAlign: "left",
    paddingLeft: "24px",
    paddingTop: "8px"
  },
  span: {
    textAlign: "left",
    paddingLeft: "24px",
    paddingBottom: "8px"
  },
  modal: {
    position: "relative",
    zIndex: 1,
    width: "90%",
    maxWidth: "90%"
  },
  modalCancel: {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer"
  },
  modalActions: {
    justifyContent: "center",
    "& button": {
      fontSize: "inharit",
      fontWeight: "600"
    }
  },
  buttonDiscard: {
    color: "#787877"
  },
  epubDialog: {
    "& .epub-container": {
      paddingInline: "20%"
    }
  }
}));

export default function CitationDialog({
  openDialog,
  highlights,
  addHighlight,
  removeHighlight,
  setOpenDialog,
  color = "secondary",
  citationTitleFormattedMessage,
  readOnly = false
}) {
  //Hooks
  const classes = useStyles();
  const epubContainerRef = React.useRef(null);
  const dispatch = useDispatch();
  const { submission_id } = useQuery();

  //Redux state
  const darkMode = useSelector((state) => selectDarkMode(state));
  const textDirection = useSelector((state) => selectTextDirection(state));
  const isPdf = useSelector(isPdfSelector);
  const submission = useSelector((state) =>
    selectSubmission(state, Number(submission_id))
  );
  //Ephemeral state
  const [scrollTop, setScrollTop] = useState(false);
  const [rendition, setRendition] = useState(null);
  const [highlightsElements, setHighlightsElements] = useState([]);

  //Variables
  const EpubCFI = new ePub.CFI();
  const containerScrollTop =
    epubContainerRef &&
    epubContainerRef.current &&
    epubContainerRef.current.scrollTop;
  const dialogLocked = submission.status
    ? Boolean(submission.status !== "Pending")
    : readOnly;

  useEffect(() => {
    if (!rendition) return;

    if (!rendition || !highlights) return;
    removeAllAnotationsOfType(rendition, ANNOTATION_TYPES.HIGHLIGHT);

    // render highlights from redux
    highlights.forEach((highlight) => {
      const cfi = highlight.cfi;
      const color = highlight.color;
      rendition.annotations.add(
        ANNOTATION_TYPES.HIGHLIGHT,
        cfi,
        { id: highlight.id },
        () => {},
        ANNOTATION_TYPES.HIGHLIGHT.toLowerCase(),
        {
          "z-index": 20,
          "mix-blend-mode": "multiply",
          "fill-opacity": 0.8,
          fill: getHighlightColor(color, darkMode)
        }
      );
    });
  }, [darkMode, highlights, rendition]);

  useEffect(() => {
    if (
      scrollTop &&
      epubContainerRef &&
      epubContainerRef.current &&
      Math.abs(epubContainerRef.current.scrollTop - scrollTop) > 2
    ) {
      epubContainerRef.current.scrollTop = scrollTop;
    }
    if (
      epubContainerRef &&
      epubContainerRef.current &&
      scrollTop &&
      Math.abs(epubContainerRef.current.scrollTop - scrollTop) < 2
    ) {
      setScrollTop(false);
    }
  }, [scrollTop, containerScrollTop]);

  const addHighlightToAnswer = (quote, shouldDelete = false) => {
    let adjustedHighlight = {
      ...quote,
      // HACK: giving this a temp id so we could delete by id...
      // ... The proper solution would be to create an interaciton
      // ... instead of an array of quotes
      id: uuid(),
      source: "taskFindAnswer",
      color: getHighlightColor("#00B9FB", darkMode)
    };
    if (addHighlight || removeHighlight) {
      setScrollTop(0);
    }
    if (!shouldDelete) {
      addHighlight && addHighlight(adjustedHighlight);
    } else removeHighlight && removeHighlight(quote);
  };

  const onTextSelected = (params) => {
    if (params.shouldClose) {
      dispatch(closeAnnotatorBar());
    } else {
      addHighlightToAnswer(params.selection);
      let content = rendition && rendition.getContents()[0];

      if (content && content.window && content.window.getSelection()) {
        content.window.getSelection().empty();
      }
    }
  };

  const onRenditionCreated = (rendition) => {
    const gotoQuote = (rendition, target) => {
      let section = rendition.book.spine.get(target);
      var visible = section && rendition.manager.views.find(section); // View is already shown, just move to correct location in view

      if (target === section.href) {
        target = undefined;
      }
      if (visible && section) {
        let offset = target ? visible.locationOf(target) : visible.offset();
        let destTop = offset.top;

        setScrollTop(destTop);
      }
    };

    let sortedQuotes =
      highlights &&
      highlights.slice().sort(function (a, b) {
        return EpubCFI.compare(a.cfi, b.cfi);
      });
    sortedQuotes &&
      rendition &&
      epubContainerRef &&
      epubContainerRef.current &&
      sortedQuotes[0] &&
      rendition.display(sortedQuotes[0].cfi).then(() => {
        gotoQuote(rendition, sortedQuotes[0].cfi);
      });

    setRendition(rendition);
  };

  const theme = useGetTheme();

  if (openDialog) {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Dialog
            open={openDialog}
            PaperProps={{
              "data-test-id": "citation-dialog",
              style: {
                direction: textDirection,
                width: "90%",
                height: "90%",
                maxWidth: "90%"
              }
            }}
            aria-labelledby="form-dialog-title">
            <DialogTitle
              className={clsx(
                classes.dialogTitle,
                color === "secondary" && classes.green
              )}
              id="form-dialog-title">
              <DialogActions className={clsx(classes.dialogActions)}>
                <Typography className={classes.dialogHeader}>
                  {citationTitleFormattedMessage}
                </Typography>
                <Button
                  variant="outlined"
                  className={classes.dialogBtn}
                  name="close-btn"
                  onClick={(e) => {
                    setOpenDialog(false);
                    setHighlightsElements([]);
                    e.stopPropagation();
                    e.preventDefault();
                  }}>
                  <FormattedMessage defaultMessage="Done" id="gr.confirm.btn" />
                </Button>
              </DialogActions>
            </DialogTitle>
            <DialogContent
              onScroll={() => {
                if (isPdf) dispatch(closeAnnotatorBar());
              }}
              ref={epubContainerRef}
              className={clsx(
                classes.dialog,
                isPdf && classes.pdfDialog,
                !isPdf && classes.epubDialog
              )}>
              <ReactReaderActions
                id="reactReaderActions"
                minimal={true}
                canComment={false}
                deleteHighlight={removeHighlight}
                container={isPdf ? window.document.body : null}>
                <DialogContentBook
                  highlights={highlights}
                  containerRef={epubContainerRef}
                  onTextSelected={onTextSelected}
                  onRenditionCreated={onRenditionCreated}
                  removeHighlight={removeHighlight}
                  dialogLocked={dialogLocked}
                />
              </ReactReaderActions>
            </DialogContent>
          </Dialog>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  } else return null;
}
