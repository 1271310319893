import React, { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import RichTooltip from "../SharedComponents/RichTooltip";

import { useLayer } from "react-laag";
import { removeTempHighlight } from "../../redux/interactionsSlice";

export default function AdaptingHighlightTooltip() {
  const dispatch = useDispatch();
  let containerRef = useRef();

  const selectedTempHighlight = useSelector(
    (state) => state.interactions.selectedTempHighlight
  );

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(!!selectedTempHighlight);
  }, [selectedTempHighlight]);

  const layerParams = {
    isOpen,
    overflowContainer: false,
    trigger: {
      getBounds: () => {
        let { height, width, x, y, top, bottom } = document
          .getElementById("questions")
          .getBoundingClientRect();
        height =
          window.innerHeight < bottom ? window.innerHeight - top : height;
        return new DOMRect(x, y, width, height);
      }
    },
    container: containerRef.current,
    placement: "right-center",
    preferY: "top",
    auto: false,
    arrowOffset: 8,
    containerOffset: 8,
    triggerOffset: 30
  };
  const { layerProps, renderLayer, arrowProps } = useLayer(layerParams);

  const cancelAdapting = () => {
    dispatch(removeTempHighlight(selectedTempHighlight));
  };

  return (
    <>
      {isOpen &&
        renderLayer(
          <RichTooltip
            layerProps={layerProps}
            arrowProps={arrowProps}
            body="gr.adaptHighlights.message"
            action="gr.adaptHighlights.action"
            onActionClick={cancelAdapting}
          />
        )}
    </>
  );
}
