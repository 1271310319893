import { of, from } from "rxjs";
import { captureException } from "../../utils/errorHandlers";
import store from "../store";
import { STATUS, setStatus } from "../tasksSlice";

export function fetchAsObservable(
  callback,
  action,
  errorMsg = `Error fetching data for ${callback.name}/${action}`,
  args = {}
) {
  args = { ...args, func_name: action };

  const promise = callback(args).then(({ data }) => {
    const { success } = data;
    if (success) {
      return JSON.parse(data.payload);
    } else {
      let error = new Error(errorMsg);
      error.message = data?.error;
      error.data = { ...args };
      throw error;
    }
  });

  return from(promise);
}

export function handleError(error, callback = null) {
  captureException(error);

  if (callback && typeof callback === "function") {
    return callback(error);
  }

  return of({ type: "error", payload: { error: error.toString() } });
}

export function showLoader() {
  store.dispatch(setStatus({ status: STATUS.PENDING }));
}

export function hideLoader() {
  store.dispatch(setStatus({ status: STATUS.IDLE }));
}
