import React from "react";
import TooltipWithIntl from "./tooltip/TooltipWithIntl";
import { IconButton, Box } from "@mui/material";

const HeaderButtonConstructor = React.forwardRef((props, ref) => {
  const {
    disabled = false,
    intlStringId,
    defaultMessage,
    placement,
    iconLabel,
    className,
    icon,
    handleClick
  } = props;

  return (
    <TooltipWithIntl
      intlStringId={intlStringId}
      defaultMessage={defaultMessage}
      placement={placement}>
      <Box component="span">
        <IconButton
          ref={ref}
          aria-label={iconLabel}
          className={className}
          onClick={handleClick}
          disabled={disabled}
          size="large">
          {icon}
        </IconButton>
      </Box>
    </TooltipWithIntl>
  );
});

HeaderButtonConstructor.displayName = "HeaderButtonConstructor";

export default HeaderButtonConstructor;
