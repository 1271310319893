import React from "react";
import { Chip } from "@mui/material";
import BouncingDots from "./BouncingDots";

const SubmittingChip = () => (
  <Chip
    label={
      <>
        Submitting <BouncingDots />
      </>
    }
  />
);

export default SubmittingChip;
