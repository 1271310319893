import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";

// Styles
const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    top: "50%",
    right: "50%",
    transform: "translate(50%, -50%)"
  }
}));

// Reusable loading spinner to help keeping consistency across the app
// IMPORTANT: the spinner will be centered in the nearest relatively position parent

// Requierd Props: None
// Optional Props:  size (integer)

export default function PangeaSpinner({ size, ...props }) {
  const classes = useStyles();
  // Redux State

  return (
    <Box className={classes.container}>
      <ClipLoader color="#5ec891" loading="true" size={size || 150} />
    </Box>
  );
}
