// Dependencies
import React, { useEffect, useState, useCallback } from "react";
import { useGetTheme, useQuery } from "../../../../hooks";
import _, { isEmpty, truncate } from "lodash";
import { v4 as uuid } from "uuid";
import { httpCallables } from "../../../../firebase";
import { addSnackbarItem } from "../../../../redux/snackbarSlice";
import { captureException } from "../../../../utils/errorHandlers";
import { systemTimezoneToUtc } from "../../../../utils/dateUtils";
import {
  sortCurrentUsersIdsBySubmissionStatus,
  sortUsersBySubmissionStatus
} from "../../../admin/reports/courseActivity/utils";

// Components
import TablePaginationFooter from "../../../admin/reports/courseActivity/TablePaginationFooter";
import RichTooltip from "../../RichTooltip";
import AssignmentSubmissions from "./AssignmentSubmissions";
import StudentEngagementChip from "./StudentEngagementChip";
// Redux
import { useDispatch } from "react-redux";

// Material-UI
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Skeleton,
  Popover,
  Link
} from "@mui/material";
import PersonRemoveAlt1Icon from "@mui/icons-material/PersonRemoveAlt1";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useIntl } from "react-intl";
import TooltipWithIntl from "../../tooltip/TooltipWithIntl";

const TableHeadComponent = ({
  title,
  ShowExcludedUsersPopover,
  handleTooltipClose,
  setShowExcludedUsersPopoverAnchor,
  handleTooltipClick,
  ShowExcludedUsersPopoverAnchor,
  intl
}) => (
  <TableHead>
    <TableRow>
      {/* <TableCell></TableCell> */}
      <TableCell>
        {title}

        {title === "Excluded students" && (
          <IconButton
            onClick={(event) =>
              handleTooltipClick(event, setShowExcludedUsersPopoverAnchor)
            }
            aria-expanded={ShowExcludedUsersPopover}
            aria-label="Show excluded students information"
            aria-controls={"excluded-students-popover"}
            size="small">
            <InfoOutlinedIcon color="primary" />
          </IconButton>
        )}
        {ShowExcludedUsersPopover && (
          <Popover
            id="excluded-students-popover"
            open={ShowExcludedUsersPopover}
            anchorEl={ShowExcludedUsersPopoverAnchor}
            onClose={() =>
              handleTooltipClose(setShowExcludedUsersPopoverAnchor)
            }
            anchorOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
            sx={{
              left: "20px"
            }}>
            <RichTooltip
              style={{ width: "300px" }}
              title="analytics.excludeUsersTitle"
              bodyHtml={
                <Typography variant="h6">
                  {intl.formatMessage({
                    id: "analytics.excludeUsers",
                    defaultMessage:
                      "By excluding these students, all graphs and charts on the analytics page will be updated."
                  })}
                </Typography>
              }></RichTooltip>
          </Popover>
        )}
      </TableCell>
      <TableCell>Assignment submissions</TableCell>
      <TableCell>Engagement</TableCell>
    </TableRow>
  </TableHead>
);

const TableBodyComponent = ({
  sortedUsers,
  currentUsers,
  indexOfFirstUser,
  indexOfLastUser,
  users,
  rawSubmissions,
  submissions,
  dispatchUsers,
  isSelected,
  usersBySubmissionStatus,
  timeSpentOnAssignmentData,
  maxPagePerTextData,
  setStudentsAtRiskIds,
  studentsAtRiskIds,
  theme
}) => {
  const [hovered, setHovered] = useState(null);
  const { course_id } = useQuery();
  const handleMouseEnter = useCallback(
    (userId) => {
      setHovered(userId);
    },
    [setHovered]
  );

  const handleMouseLeave = useCallback(() => {
    setHovered(null);
  }, [setHovered]);
  return (
    <TableBody>
      {isEmpty(sortedUsers) || isEmpty(maxPagePerTextData)
        ? _.fill(Array(3)).map((placeholder, i) => (
            <TableRow key={i}>
              <TableCell>
                <Skeleton variant="circular" width={20} height={20} />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
              <TableCell>
                <Skeleton />
              </TableCell>
            </TableRow>
          ))
        : currentUsers
            .slice(indexOfFirstUser, indexOfLastUser)
            .map((userId, i) => {
              const user = users[userId];
              const icon = isSelected ? (
                <PersonRemoveAlt1Icon fontSize="small" />
              ) : (
                <PersonAddAlt1Icon fontSize="small" />
              );
              const isStudentAtRisk = studentsAtRiskIds.find(
                (student) => student.user_id === userId
              );
              const studentMsg = isSelected
                ? "Exclude student from data"
                : "Include student in data";

              return (
                user.selected === isSelected && (
                  <React.Fragment key={user.course_user}>
                    <TableRow
                      onMouseEnter={() => handleMouseEnter(userId)}
                      onMouseLeave={handleMouseLeave}
                      sx={{
                        borderBottom: isStudentAtRisk
                          ? "none"
                          : `1px solid ${theme.palette.border.secondary}`
                      }}>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          minWidth: 220,
                          height: isStudentAtRisk ? 50 : "auto",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          borderBottom: "none",
                          paddingBottom: isStudentAtRisk ? 0 : undefined // Add this line
                        }}>
                        <Box display="flex" alignItems="center">
                          <Typography variant="body2">
                            {i + 1}
                            <Link
                              href={`tasks/stats?course_id=${course_id}&user_id=${userId}`}
                              aria-label={`${user.name} student analytics`}
                              underline={"hover"}
                              color={"inherit"}>
                              <span style={{ marginInlineStart: "8px" }}>
                                {truncate(`${user.name}`, { length: 20 })}
                              </span>
                            </Link>
                            {hovered === userId && (
                              <IconButton
                                size="small"
                                sx={{ padding: 0, marginInlineStart: "8px" }}
                                onClick={() =>
                                  dispatchUsers({
                                    type: "toggle",
                                    payload: { user: userId }
                                  })
                                }
                                aria-label="Toggle user">
                                <TooltipWithIntl
                                  intlStringId={"analytics.exclude.user.msg"}
                                  defaultMessage={studentMsg}
                                  placement={"top"}>
                                  {icon}
                                </TooltipWithIntl>
                              </IconButton>
                            )}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ borderBottom: "none" }}>
                        <AssignmentSubmissions
                          rawSubmissions={rawSubmissions[userId]}
                        />
                      </TableCell>
                      <TableCell sx={{ borderBottom: "none" }}>
                        <StudentEngagementChip
                          usersBySubmissionStatus={usersBySubmissionStatus.filter(
                            (entry) => entry.id === userId
                          )}
                          timeSpentOnAssignmentData={timeSpentOnAssignmentData}
                          userId={userId}
                          maxPagePerTextData={maxPagePerTextData}
                          rawSubmissions={submissions.filter(
                            (entry) => entry.owner === userId
                          )}
                          setStudentsAtRiskIds={setStudentsAtRiskIds}
                        />
                      </TableCell>
                    </TableRow>
                    {isStudentAtRisk && (
                      <TableRow
                        sx={{
                          borderTop: "none"
                        }}>
                        <TableCell
                          colSpan={3}
                          sx={{
                            borderTop: "none",
                            borderBottom: `1px solid ${theme.palette.border.secondary}`,
                            paddingTop: 0,
                            paddingBottom: 1 // Adjust this value as needed
                          }}>
                          <Typography
                            variant="caption"
                            sx={{ display: "block", width: "100%" }}>
                            {isStudentAtRisk.percentage.toFixed(0)}% of
                            submissions are missed
                            {isStudentAtRisk?.warning &&
                              isStudentAtRisk.percentage &&
                              " | "}
                            {isStudentAtRisk?.warning &&
                              `${isStudentAtRisk.warning}`}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                )
              );
            })}
    </TableBody>
  );
};

const StudentEngagementReport = ({
  users,
  dispatchUsers,
  start,
  end,
  courseStudents,
  timeSpentOnAssignmentData,
  rawSubmissions,
  maxPagePerTextData
}) => {
  // Hooks
  const dispatch = useDispatch();
  const { course_id } = useQuery();
  const theme = useGetTheme();
  const intl = useIntl();
  // Ephemeral state
  const [submissions, setSubmissions] = useState([]);
  const [currentUsers, setCurrentUsers] = useState([]);
  const [usersBySubmissionStatus, setUsersBySubmissionStatus] = useState([]);
  const [studentsAtRiskIds, setStudentsAtRiskIds] = useState([]);
  const [ShowExcludedUsersPopoverAnchor, setShowExcludedUsersPopoverAnchor] =
    useState(null);
  const ShowExcludedUsersPopover = Boolean(ShowExcludedUsersPopoverAnchor);

  // Derived state
  const sortedUsers = Object.values(users)
    .filter((user) => user.course_role !== "Teacher")
    .map((user) => user.course_user);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const indexOfLastUser = currentPage * rowsPerPage;
  const indexOfFirstUser = indexOfLastUser - rowsPerPage;

  useEffect(() => {
    if (!end || !start || !course_id) return;
    httpCallables
      .readTasksAndSubmissionsInRange({
        course_id: Number(course_id),
        start: systemTimezoneToUtc(start),
        end: systemTimezoneToUtc(end)
      })
      .then(({ data }) => {
        if (data.success) {
          const { submissions } = JSON.parse(data.payload);
          setSubmissions(submissions);
        } else {
          const { error } = data.payload;
          dispatch(
            addSnackbarItem({
              intlId: "error.readCourseUsersFailed",
              intlDefaultMessage:
                "There was a problem getting the course information. Please check your connection and try again",
              id: uuid()
            })
          );
          captureException(error, `Failed to get course tasks and submissions`);
        }
      });
  }, [course_id, dispatch, end, start]);

  useEffect(() => {
    const sortedUsersBySubmissionStatus = sortUsersBySubmissionStatus(
      sortedUsers,
      submissions
    );
    setUsersBySubmissionStatus(sortedUsersBySubmissionStatus);
    const sortedCurrentUsersIdsBySubmissions =
      sortCurrentUsersIdsBySubmissionStatus(
        sortedUsers,
        sortedUsersBySubmissionStatus
      );
    setCurrentUsers(sortedCurrentUsersIdsBySubmissions);
  }, [sortedUsers, submissions]);

  const handleTooltipClose = (setAnchor) => {
    setAnchor(null);
  };

  const handleTooltipClick = (event, setAnchor) => {
    setAnchor(event.currentTarget);
  };

  return (
    <Box
      sx={{
        padding: "0 24px 24px 24px",
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: "5px"
      }}>
      {/* HEADER */}
      <Box
        sx={{
          display: "flex",
          height: "60px",
          width: "100%",
          flexFlow: "row nowrap",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: `1px solid ${theme.palette.border.secondary}`
        }}>
        <Typography variant="subtitle1">
          Students engagement data: {courseStudents.length}
        </Typography>
      </Box>

      {/* TABLE */}
      <Box
        sx={{
          display: "flex",
          flexFlow: "column nowrap",
          width: "inherit",
          height: "100%"
        }}>
        <Box>
          <TableContainer>
            <Table aria-label="users table">
              <TableHeadComponent title="Active students" />
              <TableBodyComponent
                sortedUsers={sortedUsers}
                currentUsers={currentUsers}
                indexOfFirstUser={indexOfFirstUser}
                indexOfLastUser={indexOfLastUser}
                users={users}
                rawSubmissions={rawSubmissions}
                submissions={submissions}
                dispatchUsers={dispatchUsers}
                isSelected={true}
                usersBySubmissionStatus={usersBySubmissionStatus}
                timeSpentOnAssignmentData={timeSpentOnAssignmentData}
                maxPagePerTextData={maxPagePerTextData}
                setStudentsAtRiskIds={setStudentsAtRiskIds}
                studentsAtRiskIds={studentsAtRiskIds}
                theme={theme}
              />
            </Table>
          </TableContainer>

          {/* Centered Box */}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            height="40px" // Adjust the height as needed
            width="100%" // Adjust the width as needed
          ></Box>

          <TableContainer>
            <Table aria-label="users table">
              <TableHeadComponent
                title="Excluded students"
                handleTooltipClose={handleTooltipClose}
                ShowExcludedUsersPopover={ShowExcludedUsersPopover}
                setShowExcludedUsersPopoverAnchor={
                  setShowExcludedUsersPopoverAnchor
                }
                ShowExcludedUsersPopoverAnchor={ShowExcludedUsersPopoverAnchor}
                handleTooltipClick={handleTooltipClick}
                intl={intl}
              />
              <TableBodyComponent
                sortedUsers={sortedUsers}
                currentUsers={currentUsers}
                indexOfFirstUser={indexOfFirstUser}
                indexOfLastUser={indexOfLastUser}
                users={users}
                rawSubmissions={rawSubmissions}
                submissions={submissions}
                dispatchUsers={dispatchUsers}
                isSelected={false}
                usersBySubmissionStatus={usersBySubmissionStatus}
                timeSpentOnAssignmentData={timeSpentOnAssignmentData}
                maxPagePerTextData={maxPagePerTextData}
                setStudentsAtRiskIds={setStudentsAtRiskIds}
                studentsAtRiskIds={studentsAtRiskIds}
                theme={theme}
              />
            </Table>
            <TablePaginationFooter
              currentPage={currentPage}
              indexOfFirstUser={indexOfFirstUser}
              indexOfLastUser={indexOfLastUser}
              sortedUsers={sortedUsers}
              setCurrentPage={setCurrentPage}
              setRowsPerPage={setRowsPerPage}
              rowsPerPage={rowsPerPage}
              totalUsers={sortedUsers.length}
            />
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default StudentEngagementReport;
