// Dependencies
import React, { useRef, useEffect, useState, useCallback } from "react";
import * as d3 from "d3";
import { useChartDimensions, useGetTheme } from "../../../../hooks";
// Components

import {
  flattenObject,
  formatMillisecondsTime,
  transformSubmissionsData,
  sortSubmissionsObject
} from "../utils";

//Redux

// Material-UI
import { Box, Typography, IconButton } from "@mui/material";
import { LEGEND_MARKER_FOR_TIME_SPENT_ON_ASSIGNMENT } from "../consts";

function PieChartBody({
  type,
  data,
  totalSubmissions,
  includeFutureAssignments,
  courseAssignments
}) {
  // Hooks
  const { ref, dimensions } = useChartDimensions({});
  const chartRef = useRef();
  const theme = useGetTheme();
  // Redux
  // Ephemeral state
  const [hoveredVal, setHoveredVal] = useState(null);

  // Behavior
  const removeSubmissionAndReplaceUnderscore = useCallback((str) => {
    return str
      .replace("Submission", "") // Remove "Submission"
      .replace(/_/g, " ") // Replace underscores with spaces
      .replace(/([a-z])([A-Z])/g, "$1 $2"); // Insert spaces before uppercase
    // letters
  }, []);

  useEffect(() => {
    // Create the pie chart
    if (data === undefined || data.length < 1) return;
    const chartData = transformSubmissionsData(
      Object.entries(
        flattenObject(
          type === "submissions"
            ? sortSubmissionsObject(data?.statusBased)
            : data
        )
      ),
      theme
    );
    d3.select(chartRef.current).selectAll("*").remove();

    const pie = d3.pie().value((d) => d.val);
    const arcGenerator = d3
      .arc()
      .innerRadius((0.5 * Math.min(dimensions.width, dimensions.height)) / 2)
      .outerRadius((0.8 * Math.min(dimensions.width, dimensions.height)) / 2);

    const arcs = pie(chartData);

    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", dimensions.width)
      .attr("height", dimensions.height);

    const g = svg
      .append("g")
      .attr(
        "transform",
        `translate(${dimensions.width / 3.5}, ${dimensions.height / 2.5})`
      );

    const tooltip = d3
      .select(chartRef.current) // Use the chart container as the reference
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0)
      .style("color", "#fff")
      .style("width", "fit-content")
      .style("padding", " 0 10px")
      .style("border-radius", "5px")
      .style("background-color", "rgba(97, 97, 97, 0.9)");

    g.selectAll("path")
      .data(arcs)
      .enter()
      .append("path")
      .attr("d", arcGenerator)
      .attr("fill", (d, i) => chartData[i].color)
      .on("mouseover", (event, d) => {
        const [x, y] = d3.pointer(event);
        const box = tooltip.node().getBoundingClientRect();

        tooltip.transition().duration(200).style("opacity", 0.9);
        tooltip
          .html(
            type === "submissions"
              ? `${d.data.val} ${removeSubmissionAndReplaceUnderscore(
                  d.data.cat
                )} submissions`
              : `${
                  LEGEND_MARKER_FOR_TIME_SPENT_ON_ASSIGNMENT[d.data.cat]
                } ${formatMillisecondsTime(d.data.val, courseAssignments)}`
          )
          .style("left", x + dimensions.width / 3.6 + "px")
          .style("top", y - dimensions.height / 1.9 + "px")
          .style("position", "relative")
          .style("font-size", "12px");
        setHoveredVal(d.data.val);
      })
      .on("mouseout", () => {
        tooltip.transition().duration(500).style("opacity", 0);
        setHoveredVal(null);
      });
  }, [
    data,
    type,
    dimensions,
    hoveredVal,
    removeSubmissionAndReplaceUnderscore,
    totalSubmissions,
    includeFutureAssignments,
    theme
  ]);

  return (
    <Box
      sx={{
        display: "flex",
        flexFlow: "row nowrap",
        width: "70%",
        height: "300px"
      }}
      ref={ref}>
      <div ref={chartRef} />
    </Box>
  );
}

export default PieChartBody;
