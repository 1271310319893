// Dependencies
import React, { useLayoutEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

// Redux

//Components

// Material UI
import { Chip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

// Styles
const useStyles = makeStyles((theme) => ({
  pending: {
    backgroundColor: theme.palette.grey[300]
  },
  teacher: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  Low: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  },
  Moderate: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText
  },
  Extraordinary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  High: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  }
}));

export default function EngagementChip({ userBySubmissionStatus }) {
  //Hooks
  const classes = useStyles();
  //Redux

  // Ephemeral State

  //   // Ephemeral State
  const [label, setLabel] = useState("");
  const [style, setStyle] = useState([]);
  // Derived state

  //Behavior

  function calculateEngagement(user) {
    const { submitted, missed, pending, late } = user.submissionStatusCount;
    const totalSubmissions = submitted + missed + pending + late;
    let engagementLevel;

    const missedPercentage = (missed / totalSubmissions) * 100;
    const submittedPercentage = (submitted / totalSubmissions) * 100;
    const lateSubmittedPercentage = (submitted / totalSubmissions) * 100;
    if (submittedPercentage >= 99) {
      engagementLevel = "Extraordinary";
    } else if (submittedPercentage >= 75) {
      engagementLevel = "High";
    } else if (submittedPercentage <= 74) {
      engagementLevel = "Moderate";
    }
    if (missedPercentage > 60 || totalSubmissions === 0) {
      engagementLevel = "Low";
    }
    return engagementLevel;
  }

  useLayoutEffect(() => {
    // Set the style
    const engagmentCategoly = calculateEngagement(userBySubmissionStatus[0]);
    setStyle(engagmentCategoly);
    setLabel(engagmentCategoly);
  }, [userBySubmissionStatus]);

  return (
    <Chip
      className={clsx(classes.chip, style && classes[style])}
      label={label}
      size="small"
      clickable={false}
    />
  );
}

EngagementChip.propTypes = {
  userBySubmissionStatus: PropTypes.array.isRequired
};
