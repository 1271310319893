// Dependencies
import React, { useRef, useState, useEffect } from "react";
import {
  firebaseApp,
  firebaseFunctions,
  httpCallables
} from "../../../firebase";
import { FormattedMessage, injectIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { useQuery } from "../../../hooks";
import { captureException } from "../../../utils/errorHandlers";
import { mapConcepts, calculateFindInTextScore } from "./utils";
import { isEmpty } from "lodash";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import {
  selectCurrentText,
  setSelectedTextId,
  setTextUrl
} from "../../../redux/textsSlice";
import {
  selectSubmission,
  selectTask,
  updateSubmissionIsChecked
} from "../../../redux/tasksSlice";
import { selectCourseByTaskId } from "../../../redux/coursesSlice";

import { sendFeedback } from "../../../redux/taskSlice";

import {
  selectSubmissionTaskFeedback,
  selectSubmissionQuestions
} from "../../../redux/interactionsSlice";
import { setBreadcrumbs } from "../../../redux/readerActionsSlice";

// Components
import TaskFeedbackSidebar from "./TaskFeedbackSidebar";
import FeedbackScreen from "./FeedbackScreen";
import FeedbackSubmmisionModal from "./FeedbackSubmissionModal";
import FeedbackValidationModal from "./FeedbackValidationModal";
import PeerReview from "../PeerReview/PeerReview";

import makeStyles from "@mui/styles/makeStyles";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  InputAdornment,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
import PangeaSpinner from "../../SharedComponents/PangeaSpinner";
import { selectTextDirection } from "../../../redux/firestoreSelectors";
import useCreatePortal from "../../../hooks/useCreatePortal";
import { TASK, USER_TYPE } from "../../../consts";
import useSelectedQuestion from "../../../hooks/firestore/useSelectedQuestion";

//Styles
const useStyles = makeStyles((theme) => {
  return {
    actions: {
      padding: "16px",
      position: "relative",
      display: "block",
      minHeight: "64px"
    },
    pointsMsg: {
      display: "inline-block",
      lineHeight: "34px"
    },
    points: {
      display: "inline-block",
      border: "1px solid #168fee",
      borderRadius: "4px",
      paddingLeft: "8px",
      paddingRight: "8px",
      width: "64px",
      "& input": {
        textAlign: "center"
      }
    },
    adornment: {
      color: "#168fee"
    },
    eval: {
      color: "#168fee",
      fontSize: "20px",
      lineHeight: "32px",
      "& ::placeholder": {
        /* Chrome, Firefox, Opera, Safari 10.1+ */ color: "#168fee",
        opacity: 1
      },
      "& button": {
        visibility: "hidden"
      },
      "&:hover button": {
        visibility: "visible"
      }
    },
    writenEval: {
      "& :focus::placeholder": {
        color: "#bdc1c9"
      },
      "& ::placeholder": {
        color: "#9197a3"
      }
    },
    hidden: {
      visibility: "hidden",
      fontSize: "20px",
      position: "absolute",
      maxWidth: "95%"
    },
    content: {
      position: "relative",
      width: "100%",
      height: "max-content",
      minHeight: "calc(100vh - 120px)",
      display: "grid"
    }
  };
});

function TaskFeedback({ intl }) {
  // Hooks
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const evalTitleSpanRef = useRef();
  const editTitleRef = useRef();
  const ActionButtonPortal = useCreatePortal(
    document && document.getElementById("global-action-btn")
  );
  let { submission_id } = useQuery();

  // Redux state
  const textDirection = useSelector((state) => selectTextDirection(state));

  const submission = useSelector((state) =>
    selectSubmission(state, Number(submission_id))
  );
  const task = useSelector((state) => selectTask(state, submission.task_id));

  const text = useSelector(selectCurrentText);
  const questions = useSelector(selectSubmissionQuestions);
  const currentQuestionId = useSelector(
    (state) => state.interactions.selectedInteractionId
  );
  const highlights = useSelector((state) => state.interactions.highlights);
  const answers = useSelector((state) => state.interactions.answers);
  const feedbacks = useSelector((state) => state.interactions.feedbacks);
  const taskEval = useSelector((state) =>
    selectSubmissionTaskFeedback(state, Number(submission_id))
  );

  const course = useSelector((state) =>
    selectCourseByTaskId(state, submission.task_id)
  );
  // Ephemeral state
  const [isLoading, setIsLoading] = useState(false);
  const [evalTitle, setEvalTitle] = useState("");
  const [isTitleEditing, setIsTitleEditing] = useState(false);
  const [feedbackComment, setFeedbackComment] = useState("");
  const [grade, setGrade] = useState(0);
  const [conceptMapping, setConceptMapping] = useState([]);
  const [highlightMatch, setHighlightMatch] = useState(0);
  const [openEval, setOpenEval] = useState(submission.is_checked);
  const [showSubmissionDialog, setShowSubmissionDialog] = useState(false);
  const [dialogValidationResult, setDialogValidationResult] = useState({
    grade: true,
    comment: true
  });
  const [feedbackValidationResult, setFeedbackValidationResult] = useState({
    noPoints: [],
    invalidPoints: [],
    comment: []
  });
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);
  const [showValidationDialog, setShowValidationDialog] = useState(false);
  const [validPointsPerQuestion, setValidPointsPerQuestion] = useState(true);
  const [alertOnPointNotValid, setAlertOnPointsNotValid] = useState("");
  // Derived state
  const isTeacher = course.course_role === "Teacher";
  const isChecked = submission.is_checked;
  const currentUser = firebaseApp.auth().currentUser;
  const totalPoints = questions.reduce((accumulator, current) => {
    // TODO: check that points can only be a number of a string representing a number and get rid of the check
    const points =
      current.points && !isNaN(current.points) ? Number(current.points) : 0;
    return accumulator + points;
  }, 0);

  const titlePlaceholder = intl.formatMessage({
    defaultMessage: "Enter evaluation title",
    id: "task.title.placeholder"
  });

  // Behavior

  //set Breadcrumbs
  useEffect(() => {
    let parts = [];
    // let course = false;
    parts.push({
      course: course.id,
      url: "/tasks",
      text: intl.formatMessage({
        id: "appBar.tasks",
        defaultMessage: "Assignments"
      })
    });

    course.name &&
      parts.push({
        course: course.id,
        url: `/tasks?course_id=${course.id}`,
        text: course.name
      });

    task.name &&
      parts.push({
        course: course.id,
        url: `/tasks?course_id=${task.course_id}&task_id=${task.id}&submission_id=${submission_id}`,

        text: task.name
      });

    submission.user_name &&
      submission.owner !== currentUser.uid &&
      parts.push({
        course: course.id,
        url: `/task?submission_id=${submission_id}`,
        text: submission.user_name
      });
    dispatch(setBreadcrumbs({ breadcrumbs: parts, blue: true }));
  }, [
    currentUser.uid,
    dispatch,
    intl,
    submission.owner,
    submission_id,
    task.course_id,
    task.id,
    task.name,
    task.student,
    submission.user_name,
    course.name,
    course.id
  ]);

  useEffect(() => {
    if (!task.text_id) return;
    dispatch(setSelectedTextId(task.text_id));
  }, [dispatch, task.text_id]);

  useEffect(() => {
    if (!text.course_id || !text.file_url) return;
    httpCallables
      .textFunctions({
        func_name: "getTextDownloadUrl",
        courseId: text.course_id,
        fileUrl: text.file_url
      })
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setTextUrl({ url: response.data.payload, text_id: text.id })
          );
        }
      });
  }, [dispatch, text.course_id, text.file_url]);

  useEffect(() => {
    if (taskEval?.id) {
      setEvalTitle(taskEval.title || "");
      setFeedbackComment(taskEval.content || "");
    }
  }, [taskEval?.id]);

  useEffect(() => {
    if (!currentQuestionId) return;

    const answer = answers.find(
      (answer) => answer.interaction_id === currentQuestionId
    );

    const question = questions.find(
      (question) => question.id === currentQuestionId
    );

    const conceptMap = mapConcepts(question, answer);
    setConceptMapping(conceptMap);
  }, [answers, currentQuestionId, questions]);

  useEffect(() => {
    if (!currentQuestionId) return;

    const question = questions.find(
      (question) => question.id === currentQuestionId
    );
    const studentHighlights = highlights.filter(
      (highlight) => highlight.interaction_id === currentQuestionId
    );

    const score = calculateFindInTextScore(question, studentHighlights);
    setHighlightMatch(score);
  }, [currentQuestionId, highlights, questions]);

  useEffect(() => {
    setGrade(
      feedbacks.reduce((acc, curr) => {
        return acc + (Number(curr.points) || 0);
      }, 0)
    );
  }, [feedbacks]);

  useEffect(() => {
    setOpenEval(isChecked);
  }, [isChecked]);

  const titleEditMargin = () => {
    let marginVal = "0px";
    marginVal =
      evalTitleSpanRef && evalTitleSpanRef.current
        ? evalTitleSpanRef.current.getBoundingClientRect().width + "px"
        : evalTitle * 0.7 + "ch";

    if (textDirection === "rtl") return { marginRight: marginVal };
    else return { marginLeft: marginVal };
  };

  const renderPoints = (grade) => {
    if (
      (!isChecked || task.max_grade || submission.grade > 0) &&
      task.max_grade > 0
    ) {
      return (
        <>
          <TextField
            variant="standard"
            className={classes.points}
            id="standard-number"
            type="number"
            InputProps={{
              disableUnderline: true,
              readOnly: isChecked
            }}
            aria-label="task-feedback-points-input"
            inputProps={{
              "aria-label": `Points out of ${totalPoints}`
            }}
            defaultValue={
              isChecked
                ? submission.grade !== null
                  ? submission.grade
                  : ""
                : grade
            }
            onChange={(e) => {
              if (e.target.value <= totalPoints) {
                setGrade(Math.round(e.target.value));
                setValidPointsPerQuestion(true);
              } else setValidPointsPerQuestion(false);
            }}
          />
          <Typography
            className={classes.pointsMsg}
            variant="body1"
            component="span">
            {" "}
            <FormattedMessage
              defaultMessage="Points out of"
              id="task.points_of"
            />{" "}
            {totalPoints}
          </Typography>
          <Box sx={{ display: "flex", flexFlow: "column" }}>
            {!validPointsPerQuestion && (
              <Typography
                className={classes.pointsMsg}
                variant="body2"
                component="span"
                sx={{ color: "#D32F2F" }}>
                <FormattedMessage
                  defaultMessage="* You exceeded the maximum points of "
                  id="task.feedback.invalidPoint"
                />
                {totalPoints} points.
              </Typography>
            )}
          </Box>
        </>
      );
    }
  };

  const getDialogTitle = () => {
    if (!isChecked || submission.feedbackcomment || submission.evaltitle) {
      return (
        <FormattedMessage
          id="tasks.general_evaluation"
          defaultMessage="Overall evaluation"
        />
      );
    } else
      return (
        <FormattedMessage
          id="tasks.general_evaluation_score"
          defaultMessage="Your score"
        />
      );
  };

  function validateFeedback() {
    // Return and object with arrays of invalid answers and citations
    const noPoints = [];
    const invalidPoints = [];
    const comment = [];

    questions.forEach((question, index) => {
      const questionFeedback = feedbacks.find(
        (questionFeedback) => questionFeedback.interaction_id === question.id
      );
      if (!questionFeedback) {
        comment.push(index);
        return;
      }
      if (question.points > 0 && !questionFeedback) {
        noPoints.push(index);
        return;
      }
      if (Number(questionFeedback.points) > Number(question.points)) {
        invalidPoints.push(index);
        setValidPointsPerQuestion(false);
      } else {
        setValidPointsPerQuestion(true);
      }

      // the rich text editor returns an array of text lines
      // checkling to see if at least one line isn't empty
      let commentIsValid = false;
      if (questionFeedback.rich_text && questionFeedback.rich_text.blocks) {
        commentIsValid = questionFeedback.rich_text.blocks.some(
          (element) => element.text !== ""
        );
      }

      if (
        !commentIsValid &&
        (!questionFeedback.title || questionFeedback.title === "")
      ) {
        comment.push(index);
      }
    });
    setFeedbackValidationResult({ noPoints, invalidPoints, comment });

    // if something failed validation - open the dialog

    if (noPoints.length > 0 || invalidPoints.length > 0 || comment.length > 0) {
      setShowValidationDialog(true);
    } else {
      setOpenEval(true);
    }
    // }
  }

  function validateDialog() {
    setDialogValidationResult({
      grade: true,
      comment: true
    });

    let gradePresent = true;
    let commentPresent = true;

    if (Number.isNaN(grade) || grade === "") {
      gradePresent = false;
    }
    if (evalTitle === "" && feedbackComment === "") {
      commentPresent = false;
    }
    setDialogValidationResult({ grade: gradePresent, comment: commentPresent });

    if (!gradePresent || !commentPresent) {
      setShowSubmissionDialog(true);
    } else {
      submitFeedback();
    }
  }

  function submitFeedback() {
    setIsLoading(true);

    dispatch(
      updateSubmissionIsChecked({
        id: Number(submission_id),
        is_checked: true
      })
    );

    //Update the submission is_checked in Redux
    const taskFuncions = firebaseFunctions.httpsCallable("tasks-taskFunctions");
    taskFuncions({
      func_name: "updateTaskSubmissionWithFeedback",
      id: Number(submission_id),
      content: feedbackComment,
      title: evalTitle,
      grade: Number(grade)
    })
      .then(() => {
        // Snackbar
        setIsLoading(false);
        history.push(`/tasks?course_id=${task.course_id}&task_id=${task.id}`);
      })
      .catch((err) => {
        // Snackbar
        dispatch(
          updateSubmissionIsChecked({
            id: Number(submission_id),
            is_checked: false
          })
        );
        captureException(err);
      });
  }

  const renderTitle = () => {
    const handleBlur = (event) => {
      setIsTitleEditing(false);
      setEvalTitle(event.target.value);
    };

    const handleKeyDown = (event) => {
      if (event.key === "Enter" || event.key === " ") {
        event.preventDefault();
        setIsTitleEditing(true);
      }
    };

    if (isTitleEditing) {
      return (
        <TextField
          value={evalTitle}
          onChange={(event) => setEvalTitle(event.target.value)}
          onBlur={handleBlur}
          placeholder={intl.formatMessage({
            id: "feedback.title",
            defaultMessage: "Title (optional)"
          })}
          variant="outlined"
          size="small"
          autoFocus
          fullWidth
          sx={{ marginBottom: "12px" }}
        />
      );
    }

    return (
      <Box
        onClick={() => !isChecked && setIsTitleEditing(true)}
        onKeyDown={handleKeyDown}
        sx={{
          cursor: isChecked ? "text" : "pointer",
          display: "flex",
          minHeight: "52px",
          alignItems: "center"
        }}>
        <Typography
          tabIndex={0}
          variant="body2"
          role="button"
          color={isChecked ? "text.primary" : "text.secondary"}
          sx={{
            display: "inline-block",
            marginBottom: "16px",
            fontSize: "20px",
            "&:hover": {
              backgroundColor: isChecked
                ? "rgba(0, 0, 0, 0)"
                : "rgba(0, 0, 0, 0.04)"
            }
          }}>
          {evalTitle ||
            intl.formatMessage({
              id: "feedback.title",
              defaultMessage: "Title (optional)"
            })}
        </Typography>
      </Box>
    );
  };

  const renderDialog = () => {
    if (!task) return <></>;

    return (
      <Dialog
        open={openEval}
        PaperProps={{
          style: {
            borderRadius: "12px",
            border: 1,
            borderColor: "rgba(0, 0, 0, 0.23)",
            boxShadow: 3,
            padding: "24px",
            width: "500px"
          }
        }}
        onClose={() => {
          dispatch(sendFeedback(false));
          setOpenEval(false);
        }}
        aria-labelledby="form-dialog-title">
        <DialogContent sx={{ padding: 0 }}>
          <Typography
            variant="body2"
            sx={{
              color: "text.secondary",
              marginBottom: "8px",
              fontSize: "12px"
            }}>
            {intl.formatMessage({
              id: "feedback.overallEvaluation",
              defaultMessage: "OVERALL EVALUATION"
            })}
          </Typography>
          {renderTitle()}
          {isChecked ? (
            <Typography sx={{ marginBottom: "28px" }}>
              {feedbackComment}
            </Typography>
          ) : (
            <TextField
              variant="outlined"
              multiline
              rows={3}
              sx={{ width: "100%", marginBottom: "24px" }}
              disabled={isChecked}
              label={intl.formatMessage({
                id: "feedback.typeYourFeedback",
                defaultMessage: "Type your feedback..."
              })}
              value={feedbackComment}
              onChange={(e) => setFeedbackComment(e.target.value)}
            />
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginBottom: "24px"
            }}>
            <TextField
              sx={{ maxWidth: "80px", width: "100px", marginRight: "16px" }}
              variant="outlined"
              value={
                isChecked
                  ? submission.grade !== null
                    ? submission.grade
                    : ""
                  : grade
              }
              onChange={(e) => {
                setGrade(Math.round(e.target.value));
                if (e.target.value <= totalPoints || !totalPoints) {
                  setValidPointsPerQuestion(true);
                } else setValidPointsPerQuestion(false);
              }}
              type="text"
              inputMode="numeric"
              pattern="[0-9]*"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                readOnly: isChecked,
                style: { height: "36px" }
              }}
              label={intl.formatMessage({
                id: "feedback.points",
                defaultMessage: "Points"
              })}
              disabled={isChecked}
            />
            {!!totalPoints && (
              <Typography component="span" sx={{ color: "text.secondary" }}>
                {intl.formatMessage(
                  {
                    id: "feedback.outOfPoints",
                    defaultMessage: "out of points"
                  },
                  { points: totalPoints }
                )}
              </Typography>
            )}
            {!validPointsPerQuestion && (
              <Typography
                component="span"
                sx={{ color: "#D32F2F", marginLeft: "8px" }}>
                {intl.formatMessage({
                  id: "feedback.pointsExceeded",
                  defaultMessage: "Maximum points exceeded"
                })}
              </Typography>
            )}
          </Box>
          <DialogActions sx={{ padding: 0 }}>
            <Box className={classes.btnContainer}>
              {!isChecked && (
                <Button
                  sx={{ fontSize: "16px", color: "#787877" }}
                  onClick={() => {
                    setOpenEval(false);
                    dispatch(sendFeedback(false));
                  }}>
                  {intl.formatMessage({
                    id: "general.cancel",
                    defaultMessage: "Cancel"
                  })}
                </Button>
              )}
              {!isChecked && (
                <Button
                  color="primary"
                  sx={{ fontSize: "16px" }}
                  onClick={() => {
                    // setShowSubmissionDialog(true);
                    validateDialog();
                    setOpenEval(false);
                  }}>
                  {intl.formatMessage({
                    id: "feedback.send",
                    defaultMessage: "Send feedback"
                  })}
                </Button>
              )}
              {isChecked && (
                <Button
                  onClick={() => {
                    setOpenEval(false);
                    dispatch(sendFeedback(false));
                  }}
                  color="primary"
                  sx={{ fontSize: "16px" }}>
                  {intl.formatMessage({
                    id: "general.close",
                    defaultMessage: "Close"
                  })}
                </Button>
              )}
            </Box>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  };

  //Render
  if (isLoading || !answers || isEmpty(task)) {
    return <PangeaSpinner />;
  } else if (task.task_type === "peerReview") {
    return <PeerReview />;
  } else
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%"
        }}>
        <ActionButtonPortal>
          {!isChecked && isTeacher && (
            <Button
              onClick={validateFeedback} // TODO: refactor validations
              data-test-id="submit-task-button"
              size="small">
              <FormattedMessage
                id="task.feedback.submit"
                defaultMessage="Send feedback"
              />
            </Button>
          )}

          {isChecked && (
            <Button
              onClick={() => {
                isTeacher
                  ? history.push(
                      `/tasks?course_id=${course.id}&task_id=${task.id}`
                    )
                  : history.push(`/tasks?course_id=${course.id}`);
              }}
              data-test-id="submit-task-button"
              size="small">
              <FormattedMessage id="gr.confirm.btn" defaultMessage="Done" />
            </Button>
          )}
        </ActionButtonPortal>
        {showValidationDialog && (
          <FeedbackValidationModal
            task={task}
            showValidationDialog={showValidationDialog}
            setShowValidationDialog={setShowValidationDialog}
            onSubmit={() => setOpenEval(true)}
            validationResult={feedbackValidationResult}
          />
        )}
        {showSubmissionDialog && (
          <FeedbackSubmmisionModal
            grade={grade}
            evalTitle={evalTitle}
            feedbackComment={feedbackComment}
            showSubmissionDialog={showSubmissionDialog}
            setShowSubmissionDialog={setShowSubmissionDialog}
            validationResult={dialogValidationResult}
            openEval={openEval}
            setOpenEval={setOpenEval}
            submitFeedback={submitFeedback}
          />
        )}
        <TaskFeedbackSidebar
          editable={task.creator === currentUser.uid && !isChecked}
          onQuestionIndex={setSelectedQuestionIndex}
          //TODO: isTeacher and showMatch are mostly doing the same thing, check if we can get rid of showMatch
          isTeacher={course.course_role === "Teacher"}
          showMatch={course.course_role === "Teacher"}
          selectedQuestionIndex={selectedQuestionIndex}
          task={task}
          questions={questions}
          highlights={highlights}
          validateFeedback={validateFeedback}
          highlightMatch={highlightMatch}
        />
        <FeedbackScreen
          task={task}
          selectedQuestionIndex={selectedQuestionIndex}
          showMatch={task.creator === currentUser.uid}
          isTeacher={task.creator === currentUser.uid}
          onQuestionIndex={setSelectedQuestionIndex}
          conceptMapping={conceptMapping}
          highlightMatch={highlightMatch}
        />
        {openEval && renderDialog()}
        <Box component="span" ref={evalTitleSpanRef} className={classes.hidden}>
          {!evalTitle.length ? titlePlaceholder : evalTitle}
        </Box>
      </Box>
    );
}

export default injectIntl(TaskFeedback);
