import { useState } from "react";
import { captureException } from "../utils/errorHandlers";

// Hook
export function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === "undefined") {
      return initialValue;
    }
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? item : initialValue;
    } catch (error) {
      // If error also return initialValue
      captureException(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  let max_retries = 4;
  const setValue = (value) => {
    for (let i = 0; i < max_retries; i++) {
      try {
        // Allow value to be a function so we have same API as useState
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;
        // Save state
        setStoredValue(valueToStore);
        // Save to local storage
        if (typeof window !== "undefined") {
          window.localStorage.setItem(key, JSON.stringify(valueToStore));
        }
        return;
      } catch (error) {
        if (isQuotaExceededError(error)) {
          clearBiggestFileInCache();
        } else captureException(error);
      }
    }
  };
  return [storedValue, setValue];
}

function clearBiggestFileInCache() {
  let cache = { size: 0 };
  let _lsTotal = 0,
    file_size,
    cached_file_name;
  for (cached_file_name in localStorage) {
    if (!Object.prototype.hasOwnProperty.call(localStorage, cached_file_name)) {
      continue;
    }
    // The variable file_size is The calculation including the length of the key itself and value length.
    // Each length is multiplied by 2 because the char in javascript stores as UTF - 16(occupies 2 bytes)(edited)
    file_size =
      (localStorage[cached_file_name].length + cached_file_name.length) * 2;

    // _isTotal accumulates the total size of the files in localStorage
    _lsTotal += file_size;

    // This log shows all the files in localStorage and their sizes
    // console.log(cached_file_name.substr(0, 50) + " = " + (file_size / 1024).toFixed(2) + " KB");
    if (Object.values(cache) < file_size) {
      cache = { [cached_file_name]: file_size };
    }
    window.localStorage.removeItem(Object.keys(cache));
  }
  // This log shows the total occupied space in localStorage
  // console.log("Total = " + (_lsTotal / 1024).toFixed(2) + " KB");
}

function isQuotaExceededError(err) {
  return (
    err instanceof DOMException &&
    // everything except Firefox
    (err.code === QUOTA_EXCEEDED.ERROR_CODE ||
      // Firefox
      err.code === QUOTA_EXCEEDED.ERROR_CODE_FF ||
      // test name field too, because code might not be present
      // everything except Firefox
      err.name === QUOTA_EXCEEDED.ERROR_NAME ||
      // Firefox
      err.name === QUOTA_EXCEEDED.RROR_NAME_FF)
  );
}

const QUOTA_EXCEEDED = {
  ERROR_CODE: 22,
  ERROR_CODE_FF: 1014,
  ERROR_NAME: "QuotaExceededError",
  RROR_NAME_FF: "NS_ERROR_DOM_QUOTA_REACHED"
};
