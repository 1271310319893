import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import TYPES from "../../../types";
import makeStyles from "@mui/styles/makeStyles";
import { motion } from "framer-motion";
import clsx from "clsx";
import CloseIcon from "@mui/icons-material/Close";
import {
  Link,
  TextField,
  Input,
  InputAdornment,
  IconButton
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { selectTextDirection } from "../../../redux/firestoreSelectors";

const useStyles = makeStyles((theme) => ({
  ConceptsWrapper: {
    marginBlockStart: 10,
    marginLeft: "20px"
  },
  expandMsg: {
    display: "block",
    cursor: "pointer"
  },
  concept: {
    boxSizing: "border-box",
    marginRight: "15px",
    marginTop: "4px",
    marginBottom: "4px",
    resize: "none",
    border: "none",
    lineHeight: "20px",
    letteSpacing: "0.13px",
    paddingLeft: "0px",
    paddingTop: "0px",
    paddingRight: "0px",
    paddingBottom: "0px",
    fontSize: "14px",
    width: "145px",
    minWidth: "145px",
    height: "32px",
    fontFamily: theme.typography.fontFamily,
    "&:focus": {
      outline: "none"
    },
    "& ::placeholder": {
      /* Chrome, Firefox, Opera, Safari 10.1+ */ color: "#adadad",
      fontSize: "14px"
    }
  },
  conceptsContainer: {
    overflow: "hidden",
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap"
  }
}));

function NewConcept({ concepts = [], newConceptRef, onChange, setEditMode }) {
  const classes = useStyles();
  const intl = useIntl();

  const textDirection = useSelector((state) => selectTextDirection(state));

  return (
    <TextField
      variant="standard"
      inputRef={newConceptRef}
      placeholder={
        intl.formatMessage({
          id: "task.concept",
          defaultMessage: "Concept"
        }) + (textDirection === "rtl" ? "" : " " + (concepts.length + 1))
      }
      key={concepts.length}
      className={classes.concept}
      name="concept-input"
      onChange={(e) => {
        setEditMode(concepts.length);
        let items = [...concepts, e.target.value];
        onChange(items);
      }}
      aria-label={`concept ${concepts.length + 1}`}
    />
  );
}

export default function AssignmentQuestionConcepts({
  concepts = [],
  readOnly = true,
  onChange = () => {}
}) {
  const newConceptRef = useRef();
  const keywordsContainerRef = useRef();
  const classes = useStyles();
  const [expand, setExpand] = useState(Boolean(concepts.length));
  const [editMode, setEditMode] = useState(-1);
  const [shouldFocus, setShouldFocus] = useState(false);

  useEffect(() => {
    if (shouldFocus && expand && newConceptRef.current) {
      newConceptRef.current.focus();
      setShouldFocus(false);
    }
  }, [shouldFocus, newConceptRef, expand]);

  useEffect(() => {
    if (concepts.length) setExpand(true);
  }, [concepts.length]);

  const toggleExpand = () => {
    setExpand(!expand);
    if (!expand) {
      setShouldFocus(true);
    }
    setEditMode(-1);
  };

  return (
    <div ref={keywordsContainerRef} className={classes.ConceptsWrapper}>
      <Link
        component="button"
        variant="body2"
        onClick={toggleExpand}
        className={clsx(classes.expandMsg, classes.fakeLink)}
        name="conceptes-toggle"
        underline="hover">
        <FormattedMessage
          id="task.create.assignment.defineKeyConcepts"
          defaultMessage="Define key concepts (optional)"
        />
      </Link>
      <motion.div
        animate={{
          height: expand ? "auto" : 0
        }}
        style={{
          height: 0
        }}
        className={classes.conceptsContainer}
        transition={{ type: "tween" }}>
        {concepts.map((concept, index) => {
          return (
            <Input
              readOnly={readOnly}
              disabled={readOnly}
              key={index} //othersize deleting a concept in the middle causes problems
              type="text"
              aria-labelledby="task-question-input"
              value={concept}
              className={classes.concept}
              autoFocus={!readOnly && index === editMode}
              onChange={(e) => {
                let items = [...concepts];
                items[index] = e.target.value;
                onChange(items);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  setEditMode(-1);
                  newConceptRef.current.focus();
                }
              }}
              endAdornment={
                !readOnly && (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        let items = [...concepts];
                        items.splice(index, 1);
                        onChange(items);
                      }}
                      size="small">
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                )
              }
              inputProps={{
                "aria-label": `concept ${index + 1}`
              }}
            />
          );
        })}
        {readOnly ? null : (
          <NewConcept
            concepts={concepts}
            newConceptRef={newConceptRef}
            onChange={onChange}
            setEditMode={setEditMode}
          />
        )}
      </motion.div>
    </div>
  );
}

AssignmentQuestionConcepts.propTypes = {
  concepts: TYPES.CONCEPTS,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func
};

NewConcept.propTypes = {
  concepts: TYPES.CONCEPTS,
  newConceptRef: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  setEditMode: PropTypes.func.isRequired
};
