// Dependencies
import React, { useRef, useEffect, useState } from "react";
import {
  flattenObject,
  processStackedGraphDataBySubmissionStatus,
  processStackedGraphDataByAssignmentStepTime
} from "../utils";
import * as d3 from "d3";
import { colorMapping } from "../consts";
import { useChartDimensions, useGetTheme } from "../../../../hooks";
import { isAfter, parseISO, millisecondsToMinutes } from "date-fns";

// Material-UI
import { Box } from "@mui/material";

// Helper functions
const getFutureAssignmentsIds = (data) => {
  const today = new Date();
  return data
    .filter((item) => {
      if (item.status === "Draft") return;
      const originalDueDate = parseISO(item?.original_due_date);
      return isAfter(originalDueDate, today);
    })
    .map((item) => item.id);
};

const removeFutureAssignments = (obj, ids) => {
  const filteredData = obj.data.filter(
    (item) => !ids.includes(Number(item.id))
  );
  return { ...obj, data: filteredData };
};

const findBiggestTotal = (data) => {
  return data.reduce((max, item) => Math.max(max, item.total), 0);
};

// Component
function StackedGraphBody({
  type,
  data,
  includeFutureAssignments,
  courseAssignments,
  setCurrentTaskData,
  selectedAssignment
}) {
  // Hooks
  const { ref, dimensions } = useChartDimensions({});
  const svgRef = useRef();
  const isSubmissionsType = type === "submissions";
  const theme = useGetTheme();
  // Ephemeral state
  const [selectedSubmissions, setSelectedSubmissions] = useState(
    () =>
      processStackedGraphDataBySubmissionStatus(
        flattenObject(data.userBased)
      ) || {}
  );

  useEffect(() => {
    if (data === undefined || data.length < 1) return;
    if (!isSubmissionsType) {
      setSelectedSubmissions(
        processStackedGraphDataByAssignmentStepTime(flattenObject(data))
      );
    } else {
      const futureAssignmentIds = includeFutureAssignments
        ? []
        : getFutureAssignmentsIds(courseAssignments);
      const processedData = processStackedGraphDataBySubmissionStatus(
        flattenObject(data.userBased)
      );
      setSelectedSubmissions(
        includeFutureAssignments
          ? processedData
          : removeFutureAssignments(processedData, futureAssignmentIds)
      );
    }
  }, [includeFutureAssignments, courseAssignments, data, isSubmissionsType]);

  useEffect(() => {
    const { keys, data: chartData } = selectedSubmissions;
    const margin = { top: 20, right: 30, bottom: 30, left: 60 };
    const width = dimensions.width - margin.left - margin.right;
    const height = dimensions.height - margin.top - margin.bottom;
    const barPadding = 8;
    const barWidth = 20;
    const chartLength = chartData.length;

    d3.select(svgRef.current).selectAll("*").remove();
    const svg = d3
      .select(svgRef.current)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .attr("min-height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    const xScale = d3
      .scaleBand()
      .domain(chartData.map((d) => d.id))
      .range([0, Math.max(chartLength * (barWidth + barPadding), width)])
      .padding(0.1);

    const maxTotal = millisecondsToMinutes(findBiggestTotal(chartData));
    const yScale = isSubmissionsType
      ? d3.scaleLinear().domain([0, 100]).range([height, 0])
      : d3.scaleLinear().domain([0, maxTotal]).range([height, 0]);

    const stack = d3.stack().keys(keys);
    const series = stack(chartData);
    const tickValues = [
      0,
      (maxTotal * 0.25).toFixed(0),
      (maxTotal * 0.5).toFixed(0),
      (maxTotal * 0.75).toFixed(0),
      maxTotal
    ];

    const axis = svg.append("g").call(
      isSubmissionsType
        ? d3
            .axisLeft(yScale)
            .tickValues([0, 25, 50, 75, 100])
            .tickFormat((d) => `${d}%`)
        : d3
            .axisLeft(yScale)
            .tickValues(tickValues)
            .tickFormat((d) => `${d} min`)
    );

    axis.selectAll(".tick line").remove();
    svg.select(".domain").remove();

    axis
      .selectAll(".tick")
      .append("line")
      .attr("x1", 0)
      .attr("y1", 0)
      .attr("x2", width)
      .attr("y2", 0)
      .attr("stroke", "lightgray");

    svg
      .append("g")
      .selectAll("g")
      .data(series)
      .join("g")
      .attr("fill", (d) => colorMapping[d.key])
      .selectAll("rect")
      .data((d) => d)
      .join("rect")
      .attr("x", (d) => xScale(d.data.id) + (xScale.bandwidth() - barWidth) / 2)
      .attr("width", barWidth)
      .attr("cursor", "pointer")
      .attr("y", (d) =>
        isSubmissionsType
          ? yScale(d[1]) + 15 / (keys.length - 2)
          : yScale(d[1]) + 15 / keys.length
      )
      .attr("height", (d) =>
        Math.max(
          0,
          isSubmissionsType
            ? yScale(d[0]) - yScale(d[1]) - 20 / (keys.length - 1)
            : yScale(d[0]) - yScale(d[1]) - barPadding / (keys.length - 1)
        )
      )
      .on("click", (event, d) => setCurrentTaskData(Number(d.data.id)));

    svg
      .append("g")
      .attr("transform", `translate(0,${height})`)
      .attr("class", "x-axis")
      .call(d3.axisBottom(xScale).tickFormat((d, i) => i + 1))
      .attr("text-anchor", "center")
      .selectAll(".tick line")
      .remove();

    svg
      .selectAll(".x-axis text")
      .style("font-size", "14px")
      .attr("cursor", "pointer")
      .attr("text-anchor", "center")
      .attr("dx", "-0.5em")
      .style("font-family", "Chivo")
      .on("click", (event, d) => setCurrentTaskData(Number(d)))
      .style("color", (d) =>
        selectedAssignment && selectedAssignment.id === Number(d)
          ? "#000"
          : theme.palette.text.primary
      )
      .style("font-weight", (d) =>
        selectedAssignment && selectedAssignment.id === Number(d) ? 800 : 400
      )
      .each(function (d, i) {
        const isSelected =
          selectedAssignment && selectedAssignment.id === Number(d);
        d3.select(this)
          .style("color", theme.palette.text.primary)
          .style("font-weight", isSelected ? 600 : 400);

        d3.select(this.parentNode).selectAll("circle").remove();

        if (isSelected) {
          d3.select(this.parentNode)
            .append("circle")
            .attr("cy", 15)
            .attr("r", 12)
            .style("fill", theme.palette.grey.main)
            .style("opacity", 0.5);
        }
      });

    axis
      .selectAll("text")
      .style("font-size", "14px")
      .style("color", theme.palette.text.primary)
      .style("font-family", "Chivo")
      .style("font-weight", "400");

    axis.selectAll(".tick text").attr("x", -0.5);

    svg.select(".domain").remove();
  }, [
    selectedSubmissions,
    dimensions.width,
    dimensions.height,
    setCurrentTaskData,
    ref,
    selectedAssignment,
    isSubmissionsType,
    theme
  ]);

  return (
    <Box
      sx={{
        display: "flex",
        flexFlow: "row nowrap",
        width: "100%",
        height: "70%",
        minHeight: "250px",
        alignItems: "center"
      }}
      ref={ref}>
      <svg ref={svgRef}></svg>
    </Box>
  );
}

export default StackedGraphBody;
