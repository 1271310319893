/**
 * Functions to transform the api responses to charts expected format
 */
import {
  USAGE_PIE_CHART,
  USGAE_BAR_GRAPH,
  CHART_NAMES,
  SUBMISSION_PIE_CHART_COLOR_MAPPING
} from "../consts";
import { range } from "lodash";
import { bucketSubmissionByStatus } from "../../../Tasks/Stats/utils";

const calculatePercentage = (value, total) => Math.round((value / total) * 100);

const getStudentsFromAllUsers = (allUsers) => {
  return allUsers.filter((user) => user.course_role === "Student").length;
};

function countUniqueUserIds(array) {
  const uniqueUserIds = new Set();

  array.forEach((item) => {
    if (item.user_id) {
      uniqueUserIds.add(item.user_id);
    }
  });

  return uniqueUserIds.size;
}

const getAcademicCoachUsage = (acedmicCoachData, allUsers) => {
  const studentsList = getStudentsFromAllUsers(allUsers);

  const data = {
    title: USAGE_PIE_CHART.ACADEMIC_COACH.title,
    footer: `${acedmicCoachData.val} of ${studentsList} students`,
    data: [
      {
        cat: "usage",
        val: acedmicCoachData.val,
        color: "#003C8F",
        percentage: calculatePercentage(acedmicCoachData.val, studentsList) // Adjust total as needed
      }
    ]
  };
  return data;
};

const getGrUsage = (grUsage, allUsers) => {
  const studentsList = getStudentsFromAllUsers(allUsers);

  const data = {
    title: USAGE_PIE_CHART.GUIDED_READING.title,
    footer: `${grUsage.val} of ${studentsList} students`,
    data: [
      {
        cat: "usage",
        val: grUsage.val,
        color: "#003C8F",
        percentage: calculatePercentage(grUsage.val, studentsList) // Adjust total as needed
      }
    ]
  };
  return data;
};

const getSubmissionData = (submissions) => {
  const transformedData = [];

  for (const key in submissions) {
    const submission = submissions[key];
    const nestedKeys = Object.keys(submission);

    for (const nestedKey of nestedKeys) {
      const newKey = `${key}_${nestedKey}`;
      const value = submission[nestedKey];
      transformedData.push({
        val: value,
        cat: newKey,
        color: SUBMISSION_PIE_CHART_COLOR_MAPPING[newKey]
      });
    }
  }

  return transformedData;
};

const getIsWeekWithTask = (apiResponse, weekNum) => {
  return apiResponse.TASK_WEEKS.includes(weekNum);
};

const getWeekData = (
  apiResponse,
  reponsePart,
  activeUsers,
  isDuration,
  weekNum
) => {
  const weekRawData = reponsePart.filter((a) => a.week_num == weekNum);

  const total = weekRawData.reduce((prev, current) => {
    const val = isDuration ? current.duration : current.count;
    return prev + val;
  }, 0);
  const week = weekNum + 1;
  const uniqueUsers = countUniqueUserIds(weekRawData);
  return {
    week: `w${week}`,
    Total: total,
    Unique: calculatePercentage(weekRawData.length, activeUsers),
    uniqueUsers: uniqueUsers,
    Average: uniqueUsers ? +(total / uniqueUsers).toFixed(2) : 0,
    weekWithTask: getIsWeekWithTask(apiResponse, weekNum)
  };
};

const getTaskData = (
  apiResponse,
  responsePart,
  activeUsers,
  uniqueActiveUsers,
  isDuration,
  task_id,
  i
) => {
  const taskRawData = responsePart.filter((a) => a.task_id == task_id);

  const total = taskRawData.reduce((prev, current) => {
    const val = isDuration ? current.duration : current.count;
    return prev + val;
  }, 0);

  const uniqueUsers = countUniqueUserIds(taskRawData);

  return {
    week: `A${task_id}`,
    task_id: task_id,
    Total: total,
    Unique: calculatePercentage(taskRawData.length, activeUsers),
    uniqueUsers: uniqueUsers,
    allActiveUsers: uniqueActiveUsers.length,
    Average: uniqueUsers ? +(total / uniqueUsers).toFixed(2) : 0
  };
};

const getSplitDataForWeek = (
  apiResponse,
  responsePart,
  activeUsers,
  isDuration,
  weekNum
) => {
  const tasksRawData = responsePart.tasks
    .filter((a) => a.week_num == weekNum)
    .reduce((prev, current) => {
      return prev + current.count;
    }, 0);
  const grRawData = responsePart.gr
    .filter((a) => a.week_num == weekNum)
    .reduce((prev, current) => {
      return prev + current.count;
    }, 0);
  const week = weekNum + 1;
  const weekRawData = responsePart.gr
    .filter((a) => a.week_num == weekNum)
    .concat(responsePart.tasks.filter((a) => a.week_num == weekNum));
  const uniqueUsers = countUniqueUserIds(weekRawData);

  return {
    week: `w${week}`,
    task: tasksRawData,
    gr: grRawData,
    uniqueUsers: uniqueUsers,
    weekWithTask: getIsWeekWithTask(apiResponse, weekNum)
  };
};

const getDataForEntry = (
  apiResponse,
  responsePart,
  chart,
  isDuration = false,
  handlerFunc = getWeekData
) => {
  const weekMin = apiResponse.MIN_WEEK,
    weekMax = apiResponse.MAX_WEEK,
    activeUsers = apiResponse.ACTIVE_USERS;
  const data = range(weekMin, weekMax + 1).map((i) => {
    return handlerFunc(apiResponse, responsePart, activeUsers, isDuration, i);
  });
  return {
    ...chart,
    data
  };
};

const splitDataToTasks = (
  apiResponse,
  responsePart,
  activeUsers,
  uniqueActiveUsers,
  isDuration,
  task_id
) => {
  // Helper function to calculate sum of count property for given task_id
  const calculateSum = (data, task_id) => {
    return data
      .filter((entry) => entry.task_id === task_id)
      .reduce((prev, current) => prev + current.count, 0);
  };

  // Calculate tasksRawData
  const tasksRawData = calculateSum(responsePart.tasks, task_id);
  // Calculate grRawData
  const grRawData = calculateSum(responsePart.gr, task_id);

  // Helper function to count unique user IDs
  const countUniqueUserIds = (data) => {
    const uniqueUsers = new Set();
    data.forEach((entry) => uniqueUsers.add(entry.user_id));
    return uniqueUsers.size;
  };

  // Calculate uniqueUsers
  const weekRawData = [...responsePart.gr, ...responsePart.tasks];
  const uniqueUsers = countUniqueUserIds(
    weekRawData.filter((entry) => entry.task_id === task_id)
  );

  return {
    week: `A${task_id}`,
    task: tasksRawData,
    gr: grRawData,
    uniqueUsers: uniqueUsers
  };
};

const getTaskDataForEntry = (
  apiResponse,
  responsePart,
  chart,
  isDuration = false,
  handlerFunc
) => {
  let tasks = [];
  let gr = [];
  let users = [];

  if (
    responsePart?.task !== undefined ||
    responsePart[0]?.task_id !== undefined
  ) {
    tasks = responsePart.map((entry) => entry.task_id);
    users = responsePart.map((entry) => entry.user_id);
  }
  if (responsePart?.gr !== undefined) {
    gr = responsePart.gr.map((entry) => entry.task_id);
  }
  const uniqueTasks = [...new Set(tasks.concat(gr))];
  const uniqueActiveUsers = [...new Set(users)];
  const activeUsers = apiResponse.ACTIVE_USERS;

  const data = uniqueTasks.map((taskId, i) => {
    return handlerFunc(
      apiResponse,
      responsePart,
      activeUsers,
      uniqueActiveUsers,
      isDuration,
      taskId,
      i
    );
  });

  return {
    ...chart,
    data
  };
};

const getTasksData = (apiResponse, responsePart, chart) => {
  const data = responsePart.tasks
    .filter((task) => task.status !== "Draft")
    .map((task, i) => {
      const submissions = responsePart.submissions.filter(
        (a) => a.task_id == task.id
      );
      const stats = bucketSubmissionByStatus(submissions);
      return {
        week: i + 1,
        submitted: stats.onTime || 0,
        late: stats.late || 0,
        missed: stats.missed || 0,
        pending: stats.pending || 0
      };
    });

  return {
    ...chart,
    data
  };
};

export const getWeekChartsDataFromApiResponse = (apiResponse) => {
  const chartData = {};

  chartData[CHART_NAMES.ACADEMIC_COACH] = getAcademicCoachUsage(
    apiResponse[CHART_NAMES.ACADEMIC_COACH],
    apiResponse["ALL_USERS"]
  );
  chartData[CHART_NAMES.GUIDED_READING] = getGrUsage(
    apiResponse["GUIDED_READING"],
    apiResponse["ALL_USERS"]
  );
  /* chartData[CHART_NAMES.ACTIVITY] = getDataForEntry(
    apiResponse,
    apiResponse.ACTIVITY,
    USGAE_BAR_GRAPH.ROUNDED.ACTIVITY
  ); 
  chartData[CHART_NAMES.READING_TIME] = getDataForEntry(
    apiResponse,
    apiResponse.READING_TIME,
    USGAE_BAR_GRAPH.ROUNDED.READING_TIME,
    true
  );
  chartData[CHART_NAMES.WRITING_TIME] = getDataForEntry(
    apiResponse,
    apiResponse.WRITING_TIME,
    USGAE_BAR_GRAPH.ROUNDED.WRITING_TIME,
    true
  );*/
  chartData[CHART_NAMES.ACADEMIC_COACH_TIME] = getDataForEntry(
    apiResponse,
    apiResponse.ACADEMIC_COACH_TIME,
    USGAE_BAR_GRAPH.ROUNDED.ACADEMIC_COACH_TIME,
    true
  );
  chartData[CHART_NAMES.COMMENTS_CREATED] = getDataForEntry(
    apiResponse,
    apiResponse.COMMENTS_CREATED,
    USGAE_BAR_GRAPH.ROUNDED.COMMENTS_CREATED
  );
  chartData[CHART_NAMES.QUESTION_CREATED] = getDataForEntry(
    apiResponse,
    apiResponse.QUESTION_CREATED,
    USGAE_BAR_GRAPH.STACKED.QUESTION_CREATED,
    false,
    getSplitDataForWeek
  );
  chartData[CHART_NAMES.HIGHLIGHTS_CREATED] = getDataForEntry(
    apiResponse,
    apiResponse.HIGHLIGHTS_CREATED,
    USGAE_BAR_GRAPH.STACKED.HIGHLIGHTS_CREATED,
    false,
    getSplitDataForWeek
  );
  chartData[CHART_NAMES.PUBLISHED_TASKS] = getTasksData(
    apiResponse,
    apiResponse.PUBLISHED_TASKS,
    USGAE_BAR_GRAPH.PUBLISHED_TASKS
  );
  chartData[CHART_NAMES.SUBMISSIONS_STATUS] = getSubmissionData(
    apiResponse.SUBMISSIONS_STATUS
  );

  return chartData;
};

export const getTaskChartsDataFromApiResponse = (apiResponse) => {
  const chartData = {};
  chartData[CHART_NAMES.ACADEMIC_COACH] = getAcademicCoachUsage(
    apiResponse[CHART_NAMES.ACADEMIC_COACH],
    apiResponse["ALL_USERS"]
  );
  chartData[CHART_NAMES.GUIDED_READING] = getGrUsage(
    apiResponse["GUIDED_READING"],
    apiResponse["ALL_USERS"]
  );
  chartData[CHART_NAMES.ACTIVITY] = getTaskDataForEntry(
    apiResponse,
    apiResponse.ACTIVITY,
    USGAE_BAR_GRAPH.ROUNDED.ACTIVITY,
    true,
    getTaskData
  );
  chartData[CHART_NAMES.READING_TIME] = getTaskDataForEntry(
    apiResponse,
    apiResponse.READING_TIME,
    USGAE_BAR_GRAPH.ROUNDED.READING_TIME,
    true,
    getTaskData
  );
  chartData[CHART_NAMES.WRITING_TIME] = getTaskDataForEntry(
    apiResponse,
    apiResponse.WRITING_TIME,
    USGAE_BAR_GRAPH.ROUNDED.WRITING_TIME,
    true,
    getTaskData
  );
  chartData[CHART_NAMES.ACADEMIC_COACH_TIME] = getTaskDataForEntry(
    apiResponse,
    apiResponse.ACADEMIC_COACH_TIME,
    USGAE_BAR_GRAPH.ROUNDED.ACADEMIC_COACH_TIME,
    true,
    getTaskData
  );
  chartData[CHART_NAMES.COMMENTS_CREATED] = getTaskDataForEntry(
    apiResponse,
    apiResponse.COMMENTS_CREATED,
    USGAE_BAR_GRAPH.ROUNDED.COMMENTS_CREATED,
    false,
    getTaskData
  );
  chartData[CHART_NAMES.QUESTION_CREATED] = getTaskDataForEntry(
    apiResponse,
    apiResponse.QUESTION_CREATED,
    USGAE_BAR_GRAPH.STACKED.QUESTION_CREATED,
    false,
    splitDataToTasks
  );
  chartData[CHART_NAMES.HIGHLIGHTS_CREATED] = getTaskDataForEntry(
    apiResponse,
    apiResponse.HIGHLIGHTS_CREATED,
    USGAE_BAR_GRAPH.STACKED.HIGHLIGHTS_CREATED,
    false,
    splitDataToTasks
  );
  chartData[CHART_NAMES.PUBLISHED_TASKS] = getTasksData(
    apiResponse,
    apiResponse.PUBLISHED_TASKS,
    USGAE_BAR_GRAPH.PUBLISHED_TASKS
  );
  chartData[CHART_NAMES.SUBMISSIONS_STATUS] = getSubmissionData(
    apiResponse.SUBMISSIONS_STATUS.statusBased
  );

  return chartData;
};
