// Dependencies
import React, { useState, useContext, useEffect, useRef } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { moveFocusToEnd } from "./utils";
import {
  CompositeDecorator,
  convertFromRaw,
  EditorState,
  ContentState
} from "draft-js";
import "draft-js/dist/Draft.css";
// Redux dependencies

// Components
import Link from "./TextEditorLink";
import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";
// Styles
const useStyles = makeStyles((theme) => ({
  textEditorContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1
  },
  outlined: {
    border: "1px solid",
    borderColor: theme.palette.grey.main,
    borderRadius: 4
  },
  contained: {
    border: "none"
  }
}));

export function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
}

export const TextEditorContext = React.createContext();

// Use this in the text editor sub components in order to get [editorState, setEditorState]
export function useTextEditorContext() {
  const context = useContext(TextEditorContext);
  if (!context) {
    throw new Error(
      "useTextEditorContext have to be used inside TextEditorContext provider"
    );
  }

  return context;
}

function TextEditor({
  children,
  content = null,
  className,
  disabled = false,
  variant = "outlined"
}) {
  // Variables
  const decorator = new CompositeDecorator([
    { strategy: findLinkEntities, component: Link }
  ]);

  //Hooks
  const classes = useStyles();
  const editorRef = useRef(); // Ref that points to the editor, can be use to manage focus
  const isFirstRender = useRef(true);

  // Redux state

  // Ephemeral state
  const [editorState, setEditorState] = useState(
    EditorState.createEmpty(decorator)
  );
  const [isDisabled, setIsDisabled] = useState(disabled);

  // Behavior
  useEffect(() => {
    if (!content) {
      setEditorState(EditorState.createEmpty(decorator));
    } else if (content) {
      const currentSelection = editorState.getSelection();
      const newContentState = convertFromRaw(content);
      const updatedEditorState = EditorState.push(editorState, newContentState);
      const updatedEditorStateWithSelection = isFirstRender.current
        ? EditorState.moveFocusToEnd(updatedEditorState)
        : EditorState.acceptSelection(updatedEditorState, currentSelection);

      if (editorState !== updatedEditorState)
        setEditorState(updatedEditorStateWithSelection);

      if (isFirstRender.current) {
        isFirstRender.current = false;
      }
    }
  }, [content]);

  function resetEditorState() {
    // public function that resets the editor state (for reset/cancel buttons)
    const emptyState = EditorState.push(
      editorState,
      ContentState.createFromText("")
    );
    setEditorState(emptyState);
  }
  function restorePreviousContentEditorState(editorState) {
    const previousContentState = convertFromRaw(content);
    const restoredState = EditorState.push(editorState, previousContentState);
    setIsDisabled(true);
    setEditorState(restoredState);
  }

  function editEditorState() {
    // public function that allow editing a disabled editor (for edit button)
    setIsDisabled(false);
    editorRef.current.focus();
    setEditorState(moveFocusToEnd(editorState));
  }
  //Render
  return (
    <Box
      className={clsx(
        classes.textEditorContainer,
        classes[variant],
        className
      )}>
      <TextEditorContext.Provider
        value={{
          variant,
          editorRef,
          editorState,
          setEditorState,
          resetEditorState,
          editEditorState,
          setIsDisabled,
          restorePreviousContentEditorState,
          disabled: isDisabled
        }}>
        {children}
      </TextEditorContext.Provider>
    </Box>
  );
}

export default TextEditor;
TextEditor.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  content: PropTypes.object,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(["outlined", "contained"])
};
