// Dependencies
import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
// Redux dependencies

// Components

// Material UI
import SingleHorizontalStackedBar from "../../../../SharedComponents/chart/charts/SingleHorizontalStackedBar";
import { chain } from "lodash";
import { isAfter, isBefore } from "date-fns";
import { useTheme } from "@mui/material";
import { countUserSubmissionStatus, getSubmissionStatus } from "../utils";

// Styles

function UserSubmissionBarChart({ submissions }) {
  const theme = useTheme();
  // input data format: {id: uid, pending: int, Submitted: int, late: int, missed: int }

  // Status options
  const PENDING = "pending";
  const SUBMITTED = "submitted";
  const LATE = "late";
  const MISSED = "missed";

  // color options
  const GRAY = theme.palette.grey[300];
  const GREEN = theme.palette.secondary.main;
  const ORANGE = theme.palette.warning.main;
  const RED = theme.palette.error.main;

  const statuses = [PENDING, SUBMITTED, LATE, MISSED];
  const colors = [GRAY, GREEN, ORANGE, RED];

  function pivot(data) {
    const result = chain(data)
      .map((d) => getSubmissionStatus(d))
      .countUserSubmissionStatus()
      .value();
    return result ? [result] : [];
  }

  _.mixin({
    countUserSubmissionStatus: countUserSubmissionStatus
  });

  // Behavior
  //Render

  return (
    <SingleHorizontalStackedBar
      data={pivot(submissions)}
      yAccessor={(d) => d.id}
      zDomain={statuses}
      colors={colors}
      barHeight={24}
      hideX
      hideY
    />
  );
}
UserSubmissionBarChart.propTypes = {
  submissions: PropTypes.array
};

export default UserSubmissionBarChart;
