import {
  createSlice
  // current
} from "@reduxjs/toolkit";

const initialState = {
  comments: [],
  commentRef: false,
  activeComment: false,
  publicComments: [],
  commentView: false,
  mode: "private"
};

export const commentSlice = createSlice({
  name: "comments",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase("editPublicComments", (state, value) => {
        state.commentRef = false;
      })
      .addCase("deleteCommentPublicComments", (state, value) => {
        state.commentRef = false;
      })
      .addCase("updatePrivateComments", (state, value) => {
        state.commentRef = false;
      })

      .addCase("updatePublicComments", (state, value) => {
        state.commentRef = false;
      })
      .addCase("deletePublicComments", (state, value) => {
        state.commentRef = false;
      })
      .addCase("editReplyPublicComments", (state, value) => {
        state.commentRef = false;
      })
      .addCase("addPublicComments", (state, value) => {
        state.commentRef = false;
      })
      .addCase("gotComments", (state, value) => {
        state.comments = value.payload;
      })
      .addCase("gotPublicComments", (state, value) => {
        state.publicComments = value.payload;
      })
      .addCase("texts/setSelectedText", (state, value) => {
        state.activeComment = false;
        state.commentRef = false;
      })
      .addCase("texts/setSelectedTextId", (state, value) => {
        state.activeComment = false;
        state.commentRef = false;
      })
      .addCase("user/logout", () => initialState);
  },
  reducers: {
    resetComments: (state, value) => {
      return initialState;
    },
    setComment: (state, value) => {
      state.commentRef = value.payload;
    },
    setMode: (state, value) => {
      state.mode = value.payload;
    },
    setActiveComment: (state, value) => {
      state.activeComment = value.payload;
    },
    setommentViewOn: (state, value) => {
      state.newCommentView = !state.newCommentView;
    }
  }
});

export const {
  setComment,
  setMode,
  setActiveComment,
  setommentViewOn,
  resetComments
} = commentSlice.actions;
//   openAnnotatorBar  ,closeAnnotatorBar, onBarShown, onBarClosed} = highlightSlice.actions;

export default commentSlice.reducer;

export const getCommentsDict = (state) => {
  let commentDict = {};
  if (state.readerActions.persistentActionState.actionBar === "comments") {
    let allComments =
      state.comments.mode === "private"
        ? state.comments.comments
        : state.comments.publicComments;

    allComments.forEach((comment) => {
      commentDict[comment.ref.cfi] = comment;
    });
  }
  return commentDict;
};
