// Dependancies
import React, { useState, useRef } from "react";
import * as d3 from "d3";

// Components
import EngagementChartTooltip from "./EngagementChartTooltip";
// Components
import Chart from "../../../../../SharedComponents/chart/Chart";
import Line from "../../../../../SharedComponents/chart/chartPrimitives/Line";
import Circles from "../../../../../SharedComponents/chart/chartPrimitives/Circles";
import Bars from "../../../../../SharedComponents/chart/chartPrimitives/Bars";
import Axis from "../../../../../SharedComponents/chart/chartPrimitives/Axis";

// Mui
import { Box, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { eachDayOfInterval, isWeekend } from "date-fns";

// styles
const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    height: "100%"
  }
}));

const EngagementChartBody = React.forwardRef((props, ref) => {
  const {
    data = {},
    dataGroups = [],
    dimensions,
    xScale,
    xAccessor,
    yScale,
    yAccessor,
    zScale,
    userIdsMap = {},
    totalUsers
  } = props;

  //hooks
  const theme = useTheme();
  const classes = useStyles();
  const tooltipRef = useRef();

  //Ephemeral state
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipData, setTooltipData] = useState(null);

  // Derived state

  const start = xScale.domain()[0];
  const end = xScale.domain()[1];
  const eachDayInRange = eachDayOfInterval({ start, end });
  const grey = theme.palette.grey[600];

  // D3
  const formatDate = d3.timeFormat("%-b %-d");

  const xAccessorScaled = (d) => xScale(xAccessor(d));
  const yAccessorScaled = (d) => yScale(yAccessor(d));
  const keyAccessor = (d, i) => i;

  // Day/task due date circles
  const colorAccessor = (d) => d.tasks_due.length && "primary";
  const variantAccessor = (d) => d.tasks_due.length && "outlined";
  const radiusAccessor = (d) => (d.tasks_due.length ? 12 : 3);

  // Weekend marks
  const singleDayWidth = Math.abs(xScale(eachDayInRange[1]));
  const weekendXAccessor = (d) => xScale(d);

  // Interactions
  const handleMouseEnter = (key) => (e, d, x, y) => {
    tooltipRef.current = e.currentTarget;
    setTooltipData({
      ...d,
      key,
      totalUsers: totalUsers,
      x: Number(x),
      y: Number(y)
    });
    setShowTooltip(true);
  };

  function handleMouseLeave() {
    setShowTooltip(false);
    tooltipRef.current = null;
    setTooltipData(null);
  }

  return (
    <Box id="wrapper" className={classes.container}>
      <EngagementChartTooltip
        data={tooltipData}
        open={showTooltip}
        userIdsMap={userIdsMap}
        anchorEl={tooltipRef.current}
      />
      <Chart ref={ref} dimensions={dimensions}>
        <Axis
          dimension="x"
          scale={xScale}
          formatTick={formatDate}
          hideAxisLines
        />
        <Axis dimension="y" scale={yScale} label={null} hideAxisLines />

        <g>
          <Bars
            data={eachDayInRange.filter((d) => isWeekend(d))}
            keyAccessor={keyAccessor}
            xAccessor={weekendXAccessor}
            yAccessor={0}
            widthAccessor={singleDayWidth}
            heightAccessor={dimensions.boundedHeight}
            fill={"gray"}
            fillOpacity={0.2}
          />
        </g>
        {dataGroups.map((group) => {
          const groupData = data[group];
          return (
            <g key={group}>
              <Line
                data={groupData}
                stroke={group === "class" ? grey : zScale(group)}
                xAccessor={xAccessorScaled}
                yAccessor={yAccessorScaled}
              />
              <Circles
                data={groupData}
                keyAccessor={keyAccessor}
                xAccessor={xAccessorScaled}
                yAccessor={yAccessorScaled}
                radius={radiusAccessor}
                color={colorAccessor}
                variant={variantAccessor}
                onMouseEnter={handleMouseEnter(group)} // this is a curried function
                onMouseLeave={handleMouseLeave}
              />
            </g>
          );
        })}
      </Chart>
    </Box>
  );
});

EngagementChartBody.displayName = "EngagementChartBody";

export default EngagementChartBody;
