import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import makeStyles from "@mui/styles/makeStyles";
import EmptyState from "../emptyState/EmptyState";
import { Box, ListItem, List } from "@mui/material";
import { useSelector } from "react-redux";
import { interactionsAPI } from "../../../api";
import clsx from "clsx";
import { CHATBOX_STATUS } from "../../../redux/chatSlice";
import AnswerCard from "./AnswerCard";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    maxWidth: 700,
    margin: "0 auto",
    marginTop: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  containerWithChat: {
    maxWidth: 700,
    marginTop: 24,
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  cardList: {
    width: "100%"
  }
}));

export function Cards({ cards = [], showInText, disable = false }) {
  const classes = useStyles();
  const visibleCards =
    cards && cards.filter && cards.filter((el) => !el.hidden);
  const hiddenCards = cards && cards.filter && cards.filter((el) => el.hidden);

  const chatStatus = useSelector((state) => state.chat.status);
  const chatIsVisible = chatStatus === CHATBOX_STATUS.OPEN;
  return cards.length ? (
    <Box
      className={clsx(
        classes.container,
        chatIsVisible && classes.containerWithChat
      )}>
      {visibleCards && (
        <DragDropContext
          onDragEnd={(result) => {
            if (!result.destination) return;
            const order = visibleCards.map((card) => card.id);
            const rollback = [...order];
            const sourceRef = order[result.source.index];
            order.splice(result.source.index, 1);
            order.splice(result.destination.index, 0, sourceRef);
            interactionsAPI.updateHighlightsOrder(order, rollback);
          }}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <List
                disablePadding
                dense
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={classes.cardList}>
                {[...visibleCards, ...hiddenCards].map((answer, index) => (
                  <Draggable
                    key={answer.id}
                    draggableId={answer.id}
                    index={index}
                    isDragDisabled={answer.hidden || disable}>
                    {(provided, snapshot) => (
                      <ListItem
                        disableGutters
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        role={"listitem"}
                        tabIndex="-1">
                        <AnswerCard
                          answer={answer}
                          showInText={showInText}
                          disable={disable}
                        />
                      </ListItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </Box>
  ) : (
    <EmptyState step="review" />
  );
}
