import React from "react";
import { SvgIcon } from "@mui/material";

export default function AIIcon(props) {
  const color = props.color ?? "#757575";

  return (
    <SvgIcon
      sx={{
        fill: props.disabled ? "rgba(0, 0, 0, 0.26)" : color,
        width: "20px",
        height: "20px"
      }}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M17.1045 3.30911L19.0767 1.23056L16.309 1.97222L14.2305 0L14.9721 2.76766L12.9999 4.84621L15.7676 4.10455L17.8461 6.07677L17.1045 3.30911Z" />
        <path d="M6.608 14.11H2.786L2.114 16H0.028L3.514 6.396H6.09L9.576 16H7.308L6.608 14.11ZM3.374 12.472H6.034C5.67933 11.4173 5.25 10.162 4.746 8.706H4.676L3.374 12.472ZM10.6057 6.396H15.0297V8.118H13.9097V14.278H15.0297V16H10.6057V14.278H11.7397V8.118H10.6057V6.396Z" />
      </svg>
    </SvgIcon>
  );
}
