// Dependencies
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useImpersonate, useQuery } from "../../../../hooks";
import _ from "lodash";
import { v4 as uuid } from "uuid";

import makeStyles from "@mui/styles/makeStyles";

import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  Link
} from "@mui/material";

import UserSubmissionBarChart from "./charts/UserSubmissionsBarChart";
import EngagementChip from "./EngagementChip";
import { isEmpty } from "lodash";
import { Skeleton } from "@mui/material";
import { httpCallables } from "../../../../firebase";
import { systemTimezoneToUtc } from "../../../../utils/dateUtils";
import { useDispatch } from "react-redux";
import { addSnackbarItem } from "../../../../redux/snackbarSlice";
import { captureException } from "@sentry/react";
import TablePaginationFooter from "./TablePaginationFooter";
import {
  countUserSubmissionStatus,
  getSubmissionStatus,
  sortCurrentUsersIdsBySubmissionStatus,
  sortUsersBySubmissionStatus
} from "./utils";

// Styles
const useStyles = makeStyles((theme) => ({
  table: {
    marginBlockEnd: theme.spacing(3)
  },
  submissionChartContainer: {
    maxWidth: 270
  }
}));

export default function UsersTable({ users, dispatchUsers, start, end }) {
  //dispatchUsers excpect a type of "set" or "toggle" and a payload

  //Hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const { course_id } = useQuery();

  //Redux
  const { impersonate } = useImpersonate();

  // Ephemeral State
  const [submissions, setSubmissions] = useState([]);
  const [engagement, setEngagement] = useState({});
  const [currentUsers, setCurrentUsers] = useState([]);
  const [usersBySubmissionStatus, setUsersBySubmissionStatus] = useState([]);
  // Derived state
  const sortedUsers = Object.values(users)
    .filter((user) => user.course_role !== "Teacher")
    .map((user) => user.course_user);
  const [currentPage, setCurrentPage] = useState(1);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const indexOfLastUser = currentPage * rowsPerPage;
  const indexOfFirstUser = indexOfLastUser - rowsPerPage;

  //Behavior
  useEffect(() => {
    httpCallables
      .readTasksAndSubmissionsInRange({
        course_id: Number(course_id),
        start: systemTimezoneToUtc(start),
        end: systemTimezoneToUtc(end)
      })
      .then(({ data }) => {
        if (data.success) {
          const { submissions } = JSON.parse(data.payload);
          setSubmissions(submissions);
        } else {
          const { error } = data.payload;
          dispatch(
            addSnackbarItem({
              intlId: "error.readCourseUsersFailed",
              intlDefaultMessage:
                "There was a problem getting the course information. Please check your connection and try again",
              id: uuid()
            })
          );
          captureException(error, `Faild to get course tasks and submissions`);
        }
      });
  }, [course_id, dispatch, end, start]);

  useEffect(() => {
    const sortedUsersBySubmissionStatus = sortUsersBySubmissionStatus(
      sortedUsers,
      submissions
    );
    setUsersBySubmissionStatus(sortedUsersBySubmissionStatus);
    const sortedCurrentUsersIdsBySubmissions =
      sortCurrentUsersIdsBySubmissionStatus(
        sortedUsers,
        sortedUsersBySubmissionStatus
      );
    setCurrentUsers(sortedCurrentUsersIdsBySubmissions);
  }, [sortedUsers, submissions]);

  return (
    <TableContainer>
      <Table className={classes.table} aria-label="users table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Active users</TableCell>
            <TableCell>Task submissions</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>Engagement</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isEmpty(sortedUsers)
            ? // Loading state
              _.fill(Array(3)).map((placeholder, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <Skeleton variant="circular" width={20} height={20} />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>

                  <TableCell>
                    <Skeleton />
                  </TableCell>

                  <TableCell>
                    <Skeleton />
                  </TableCell>

                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
              ))
            : currentUsers
                .slice(indexOfFirstUser, indexOfLastUser)
                .map((userId) => {
                  // Loaded state
                  const user = users[userId];
                  return (
                    <TableRow key={user.course_user}>
                      <TableCell padding="checkbox" component="th" scope="row">
                        <Checkbox
                          checked={user.selected}
                          onChange={() =>
                            dispatchUsers({
                              type: "toggle",
                              payload: { user: userId }
                            })
                          }
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Link
                          component="button"
                          onClick={() => {
                            impersonate(user.course_user);
                          }}
                          underline="hover">
                          {user.course_user}
                        </Link>
                      </TableCell>
                      <TableCell className={classes.submissionChartContainer}>
                        <UserSubmissionBarChart
                          submissions={submissions.filter(
                            (submission) => submission.owner === userId
                          )}
                        />
                      </TableCell>
                      <TableCell>{user.course_role}</TableCell>
                      <TableCell>
                        <EngagementChip
                          userBySubmissionStatus={usersBySubmissionStatus.filter(
                            (user) => user.id === userId
                          )}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
        </TableBody>
      </Table>
      <TablePaginationFooter
        currentPage={currentPage}
        indexOfFirstUser={indexOfFirstUser}
        indexOfLastUser={indexOfLastUser}
        sortedUsers={sortedUsers}
        setCurrentPage={setCurrentPage}
        setRowsPerPage={setRowsPerPage}
        rowsPerPage={rowsPerPage}
        totalUsers={sortedUsers.length}
      />
    </TableContainer>
  );
}

UsersTable.propTypes = {
  users: PropTypes.object.isRequired,
  dispatchUsers: PropTypes.func.isRequired,
  submissions: PropTypes.array
};
