// Dependencies
import React, { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { firebaseFunctions, getRecaptchaKey } from "../../firebase";
import { useHistory, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import { captureException } from "../../utils/errorHandlers";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import ReCAPTCHA from "react-google-recaptcha";

import makeStyles from "@mui/styles/makeStyles";
import { Box, TextField, Typography, IconButton, Button } from "@mui/material";

//Styles
const useStyles = makeStyles((theme) => {
  return {
    container: {
      width: "100%",
      height: "100vh",
      display: "flex",
      backgroundImage: 'url("/resources/landingImage20211023.jpg")',
      backgroundSize: "auto 100vh",
      backgroundPosition: "right",
      backgroundRepeat: "no-repeat"
    },
    backBtn: {
      position: "absolute",
      left: "-48px"
    },
    newAccount: {
      color: theme.palette.secondary.main
    },
    resetLink: {
      right: "0px",
      color: theme.palette.secondary.main,
      alignSelf: "flex-end"
    },

    main: {
      flexBasis: "50%",
      display: "flex",
      justifyContent: "center",
      backgroundColor: theme.palette.background.paper
    },
    header: {
      whiteSpace: "nowrap",
      marginBottom: "60px"
    },
    loginBox: {
      marginTop: "160px",
      //height: "50%",
      //display: "flex",
      //flexFlow: "column nowrap",
      //flexBasis: "50%",
      alignItems: "center",
      justifyContent: "space-between",
      textAlign: "center",
      position: "relative"
    },
    googleLoginBtn: {
      padding: "12px",
      marginBottom: "40px",
      width: "100%",
      "& .MuiButton-startIcon": {
        marginInlineEnd: "auto"
      }
    },
    googleLoginBtnText: {
      marginInlineEnd: "auto",
      fontSize: "16px"
    },
    emailLoginBox: {
      width: "100%",
      display: "flex",
      flexFlow: "column nowrap",
      justifyContent: "space-between"
    },
    emailLoginInput: {
      marginBlockEnd: "8px"
    }
  };
});

export default function SendPasswordReset({ setPending, back }) {
  // Hooks
  const history = useHistory();

  const location = useLocation();
  const classes = useStyles();
  const intl = useIntl();
  // Ephemeral state
  const [email, setEmail] = useState("");
  const [emailValidationMessage, setEmailValidationMessage] = useState(null);
  const [verifiedRecaptcha, setVerifiedRecaptcha] = useState(false);

  const [loading, setLoading] = useState(false);

  function validateEmail(userInput) {
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        userInput
      )
    ) {
      return true;
    } else return false;
  }

  function sendResetLink() {
    if (!validateEmail(email)) {
      setEmailValidationMessage(
        intl.formatMessage({
          id: "signin.validation.emailInvalid",
          defaultMessage: "Please enter a valid email address"
        })
      );
      return false;
    }

    setLoading(true);
    let sendLink = firebaseFunctions.httpsCallable("users-sendPasswordReset");
    sendLink({ email: email, url: window.location.origin })
      .then((response) => {
        // if (response) {
        window.localStorage.setItem("emailForSignIn", email);
        setLoading(false);
        setPending(true);
      })
      .catch((err) => {
        setLoading(false);
        setEmailValidationMessage(
          intl.formatMessage({
            id: "signin.validation.generalError",
            defaultMessage:
              "There was a problem sending the password reset email. Please double check that you entered the email correctly"
          })
        );
        captureException(
          err,
          `Failed to send password reset email. email ${email}`
        );
      });
  }

  const renderLoader = () => {
    if (loading) {
      return (
        <Box>
          <ClipLoader color="#5ec891" loading="true" size={150} />
        </Box>
      );
    }
  };

  return (
    <>
      <IconButton
        className={classes.backBtn}
        onClick={() => {
          back();
        }}
        size="large">
        <ArrowBackIcon />
      </IconButton>{" "}
      <Typography component="h1" variant="h3" className={classes.header}>
        {intl.formatMessage({
          id: "resetPassword.header",
          defaultMessage: "Reset Password"
        })}
      </Typography>
      <Box className={classes.userPassowrdContainer}>
        {!loading && ( // Hide the login form when showing spinner
          <form className={classes.emailLoginBox} noValidate autoComplete="off">
            <TextField
              required
              fullWidth
              type="email"
              aria-label="send-reset-password-input"
              defaultValue={email}
              onChange={(e) => setEmail(e.target.value)}
              label={intl.formatMessage({
                id: "signin.emailAddress",
                defaultMessage: "Email:"
              })}
              variant="standard"
              id="email"
              className={classes.emailLoginInput}
              error={emailValidationMessage && true}
              helperText={emailValidationMessage || " "}
            />
            <ReCAPTCHA
              sitekey={getRecaptchaKey()}
              onChange={(value) => {
                setVerifiedRecaptcha(true);
              }}
            />
            <Button
              disabled={!email || !verifiedRecaptcha}
              disableElevation
              onClick={sendResetLink}
              className={classes.emailLoginBtn}
              color="secondary"
              variant="contained"
              type="submit">
              {intl.formatMessage({
                id: "resetPassword.action",
                defaultMessage: "Confirm"
              })}
            </Button>
          </form>
        )}
      </Box>
      {renderLoader()}
    </>
  );
}
