import React, { forwardRef, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";

// Styles
const useStyles = makeStyles((theme) => ({
  scrollBox: {
    width: "100%",
    height: "100%",
    overflow: "auto"
    // paddingInline: theme.spacing(2) // -- TODO - Think of a different solution as this is ugly
  }
}));

// This is a presentational component that adds overflow auto and padding on the scroller side

// Requierd props: children
// Optional props: flexDirection - overrides flex direction
//                 alignItems - overrides flex align items. Defaults to column

const ScrollBox = forwardRef(
  ({ children, flexDirection = "column", alignItems, className }, ref) => {
    // Hooks
    const classes = useStyles();

    const boxRef = useRef(null);

    useEffect(() => {
      if (ref) {
        ref.current = boxRef.current;
      }
    }, [ref]);

    const handleKeyDown = (event) => {
      if (event.key === "ArrowDown" || event.key === "ArrowUp") {
        const direction = event.key === "ArrowDown" ? 1 : -1;
        boxRef.current.scrollTop += direction * 40; // Adjust the scroll speed as needed
      }
    };

    return (
      <Box
        ref={boxRef}
        id="scroll-box"
        tabIndex="0" // Make the Box focusable
        role="region" // Provide a role for screen readers
        aria-label="Scrollable Region" // Provide a label for screen readers
        style={{ flexDirection, alignItems }}
        className={clsx(classes.scrollBox, className)}
        onKeyDown={handleKeyDown}>
        {children}
      </Box>
    );
  }
);

ScrollBox.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  flexDirection: PropTypes.string,
  alignItems: PropTypes.string,
  className: PropTypes.string
};

ScrollBox.displayName = "ScrollBox";

export default ScrollBox;
