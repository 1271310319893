import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  que: []
};

export const userSlice = createSlice({
  name: "snackbar",
  initialState,

  reducers: {
    addSnackbarItem: (state, value) => {
      state.que.push(value.payload);
    },
    removeSnackbarItem: (state, value) => {
      state.que = state.que.filter((queItm) => queItm.id !== value.payload.id);
    }
  }
});

export const { addSnackbarItem, removeSnackbarItem } = userSlice.actions;

export default userSlice.reducer;
