import { Grid } from "@mui/material";
import React from "react";
import { GraphChart } from "./BarGraphChart";
import { StackedGraph } from "./stackedGraph";
import { USGAE_BAR_GRAPH } from "../../consts";

const ActivityReportGraphs = ({ chartsData, totalUsers, dataPlotType }) => {
  const createGraphComponent = (
    chartName,
    graphButtons,
    data,
    footer,
    title,
    Component,
    dataPlotType
  ) => {
    const xs = data.length > 20 ? 12 : 6;
    return (
      <Grid item xs={xs} key={`graph-${chartName}-${title}`}>
        <Component
          key={`graph-${chartName}-${title}`}
          graphButtons={graphButtons}
          footer={footer}
          totalUsers={totalUsers}
          title={title}
          data={data}
          height="100%"
          width="100%"
          dataPlotType={dataPlotType}
        />
      </Grid>
    );
  };

  const createGraphs = () => {
    return Object.keys(USGAE_BAR_GRAPH).flatMap((key) => {
      return Object.keys(USGAE_BAR_GRAPH[key]).map((chartName) => {
        const { BUTTONS, FOOTER, TITLE } = USGAE_BAR_GRAPH[key][chartName];
        const { data } = chartsData[chartName];
        if (key === "ROUNDED") {
          return createGraphComponent(
            chartName,
            BUTTONS,
            data,
            FOOTER,
            TITLE,
            GraphChart,
            dataPlotType
          );
        } else {
          return createGraphComponent(
            chartName,
            BUTTONS,
            data,
            FOOTER,
            TITLE,
            StackedGraph,
            dataPlotType
          );
        }
      });
    });
  };

  return createGraphs();
};

export default ActivityReportGraphs;
