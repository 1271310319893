// Dependencies
import React, { useEffect, useState } from "react";
import { flattenObject } from "../utils";
// Components
import { generateMarkers } from "../pieChart/PieChartLegend";
// Material-UI
import { Box } from "@mui/material";
import { useGetTheme } from "../../../../hooks";

function AssignmentSubmissions({ rawSubmissions }) {
  // Hooks
  const theme = useGetTheme();
  const [assignmentSubmissions, setAssignmentSubmissions] = useState({});

  // Sort function
  function sortBySubmissionStatus(submissions, sortOrder) {
    const getSortValue = (entry) => {
      const [submissionType, status] = Object.entries(entry)[0];
      const statusKey = Object.keys(status).find((key) => status[key] === 1);
      return statusKey ? sortOrder[submissionType][statusKey] : Infinity;
    };

    const sortedEntries = Object.entries(submissions)
      .filter(([_, entry]) =>
        Object.values(Object.values(entry)[0]).includes(1)
      )
      .sort((a, b) => getSortValue(a[1]) - getSortValue(b[1]))
      .map(([id, entry]) => {
        const [submissionType, status] = Object.entries(entry)[0];
        const statusKey = Object.keys(status).find((key) => status[key] === 1);
        return [id, { [submissionType]: { [statusKey]: 1 } }];
      });

    return sortedEntries;
  }

  // Render legend item function
  const renderLegendItem = (key, value) => {
    if (value !== 1) return;
    const parts = key.split("_"); // Split key by '_'
    const status = `${parts[2]}_${parts[3]}`; // "SubmissionOnTime", "SubmissionLate", etc.

    return value <= 0 ? null : ( // Conditionally return null or JSX
      <Box
        key={key}
        sx={{
          display: "flex",
          alignItems: "center",
          flexFlow: "row nowrap"
        }}>
        {generateMarkers(status, value, theme)}
      </Box>
    );
  };

  // Effect hook for sorting and setting submissions
  useEffect(() => {
    if (!rawSubmissions || Object.keys(rawSubmissions).length === 0) return;

    const sortOrder = {
      SubmissionOnTime: {
        complete: 0,
        incomplete: 1,
        no_activity: 2
      },
      SubmissionLate: {
        complete: 3,
        incomplete: 4,
        no_activity: 5
      },
      SubmissionMissed: {
        complete: 6,
        incomplete: 7,
        no_activity: 8
      },
      SubmissionPending: {
        complete: 9,
        incomplete: 10,
        no_activity: 11
      }
    };
    const userData = sortBySubmissionStatus(rawSubmissions, sortOrder);

    setAssignmentSubmissions(flattenObject(userData));
  }, [rawSubmissions]);

  // Render component
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexFlow: "row wrap" // Change flexFlow to "row wrap" to allow wrapping
      }}>
      {Object.keys(assignmentSubmissions).length > 0 &&
        Object.entries(assignmentSubmissions).map(([key, value]) => (
          <React.Fragment key={key}>
            {renderLegendItem(key, value)}
          </React.Fragment>
        ))}
    </Box>
  );
}

export default AssignmentSubmissions;
