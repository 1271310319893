import React from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { truncateMiddle } from "../utils";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  menuItem: {
    maxWidth: 400
  },
  select: {}
}));
const SelectCourseTasks = ({ courseTasks, setSelectedTask, selectedTask }) => {
  const classes = useStyles();

  const handleTaskChange = (event) => {
    const selectedCourseObject = courseTasks.find(
      (course) => course.name === event.target.value
    );
    setSelectedTask(selectedCourseObject);
  };

  if (!courseTasks.length) return;

  return (
    <FormControl sx={{ m: 1, width: 140, minWidth: 140 }} size="small">
      <InputLabel id="select-label-task">Task</InputLabel>

      <Select
        value={selectedTask.name}
        label="Task"
        className={classes.select}
        onChange={handleTaskChange}>
        {courseTasks.map((task) => (
          <MenuItem
            className={classes.menuItem}
            key={task.id}
            value={task.name}
            // onClick={() => setSelectedStudentChat(student)}
          >
            {` ${task.id} - ${truncateMiddle(task.name, 25)}`}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectCourseTasks;
