// Dependancies
import { combineEpics } from "redux-observable";

// Epics
import { fetchConversationEpic } from "./fetchConversationEpic";
import { outgoingMessageEpic } from "./outgoingMessageEpic";
import { initMentorEpic } from "./initMentorEpic";
import { mentorFabClickedEpic } from "./mentorFabClickedEpic";
import { changeSessionEpic } from "./changeSessionEpic";

export const eMentorEpics = combineEpics(
  fetchConversationEpic,
  mentorFabClickedEpic,
  initMentorEpic,
  outgoingMessageEpic,
  changeSessionEpic
);
