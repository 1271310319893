import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { grAPI, standardTaskAPI } from "../api";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom";
import { useFirestoreDocData, useFirestore } from "reactfire";
import { doc } from "firebase/firestore";
import { setStep as setTaskStep } from "../redux/taskSlice";
import { setStage as setGrStep } from "../redux/grSlice";

const STEPS = ["highlight", "review", "answer"];

function useStepStage() {
  const dispatch = useDispatch();
  const firestore = useFirestore();
  const userId = useSelector((state) => state.firebase.auth.uid);
  const taskId = useSelector((state) => state.tasks.selectedTaskId || 0);
  const textId = useSelector((state) => state.gr.text_id || 0);

  const isTask = !!useRouteMatch({ path: "/task" });

  const [updatedAt, setUpdatedAt] = useState(new Date(-8640000000000000));
  const [step, setStep] = useState("highlight");

  const firestoreDoc =
    taskId && isTask
      ? doc(firestore, "tasks", userId, "task", "" + taskId)
      : doc(firestore, "sq3r", userId, "texts", String(textId));

  const { data, status } = useFirestoreDocData(firestoreDoc);

  useEffect(() => {
    if (step)
      if (isTask) {
        dispatch(setTaskStep(step));
      } else {
        dispatch(setGrStep({ stage: step }));
      }
  }, [step, isTask, taskId, textId]);

  useEffect(() => {
    if (status !== "loading") {
      const newDocUpdate = new Date(data?.updatedAt || -8630000000000000);

      if (newDocUpdate > updatedAt) {
        let newStep = isTask ? data?.step : data?.grStage;
        newStep = newStep || "highlight";
        if (!isTask) {
          newStep = isNaN(newStep) ? newStep : STEPS[newStep];
        }
        setUpdatedAt(newDocUpdate);

        newStep !== step && setStep(newStep);
      }
    }
  }, [data, status, updatedAt, isTask]);

  const changeStep = (newStep) => {
    isTask
      ? standardTaskAPI.updateSelectedStep(newStep, taskId, step)
      : grAPI.updateSelectedStep(newStep, step);
  };

  return [step, changeStep];
}

export default useStepStage;
