import { useState } from "react";
import { calculateQuestionPoints } from "../components/Tasks/TaskFeedback/utils";
import { useSelector } from "react-redux";
import {
  questionHighlights,
  selectCurrentInteraction,
  selectAnswerComment
} from "../redux/interactionsSlice";
import { useEffect } from "react";

export const useCurrentInteractionWithPoints = () => {
  const question = useSelector(selectCurrentInteraction);
  const answer = useSelector((state) =>
    selectAnswerComment(state, state.interactions.selectedInteractionId)
  );

  const highlights = useSelector((state) =>
    questionHighlights(state, state.interactions.selectedInteractionId)
  );

  const [questionWithPoints, setQuestionWithPoints] = useState({});

  useEffect(() => {
    if (Object.keys(question).length) {
      setQuestionWithPoints({
        ...question,
        calculatedPoints: calculateQuestionPoints(question, answer, highlights)
      });
    } else setQuestionWithPoints({});
  }, [question, answer, highlights]);

  return questionWithPoints;
};
