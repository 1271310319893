// Dependancies

import React from "react";

// Components

// Mui
import { Box, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { truncate } from "lodash";

// styles
const useStyles = makeStyles((theme) => ({
  legend: {
    width: "100%",
    paddingBlockStart: theme.spacing(8),
    paddingBlockEnd: theme.spacing(4),
    paddingLeft: theme.spacing(6),
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "left",
    "& div": {
      marginInlineEnd: theme.spacing(2),
      display: "flex",
      alignItems: "center"
    }
  },
  symbol: {
    display: "inline-block",
    height: 4,
    width: 22,
    margin: theme.spacing(1)
  }
}));

function EngagementChartHeader({ series = [], scale, userIdsMap = {} }) {
  const classes = useStyles();
  const theme = useTheme();

  const grey = theme.palette.grey[600];

  return (
    <Box className={classes.legend}>
      {series.map((item) => {
        const userName = userIdsMap[item] || item;
        return (
          <Box key={item}>
            <Box
              component="span"
              className={classes.symbol}
              style={{
                backgroundColor: item === "class" ? grey : scale(item)
              }}
            />
            {truncate(`${userName}`, { length: 9 })}
          </Box>
        );
      })}
    </Box>
  );
}

export default EngagementChartHeader;
