// Dependencies
import React from "react";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";

// Redux
import {
  setAnnotatorMode,
  setAction,
  ToggleReaderMode
} from "../../redux/readerActionsSlice";
import { selectCurrentTask } from "../../redux/tasksSlice";
import {
  selectPrimarySidebarState,
  selectReadrMode
} from "../../redux/firestoreSelectors";

import makeStyles from "@mui/styles/makeStyles";
import { IconButton, Box } from "@mui/material";
import LiveHelpOutlinedIcon from "@mui/icons-material/LiveHelpOutlined";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { togglePrimarySidebar } from "../../redux/layoutSlice";
import { PRIMARY_SIDEBAR_STATE } from "../../consts";
import TooltipWithIntl from "./tooltip/TooltipWithIntl";
import { READER_MODES } from "../../consts";
// Styles
const useStyles = makeStyles((theme) => {
  return {
    sidePanel: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.background.sidePanel,
      zIndex: "9999"
    },
    panelButton: {
      fontSize: "24px",
      color: theme.palette.grey["400"],
      "&:hover": {
        color: theme.palette.grey["050"]
      }
    },
    selected: { color: "#FFFFFF" },
    blueColor: {
      color: "#168FEE"
    }
  };
});

export default function SidePanel() {
  // Hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const isReader = useRouteMatch({ path: "/reader" });
  const isTask = useRouteMatch({ path: "/task" });

  // Redux State
  const primarySidebarState = useSelector((state) =>
    selectPrimarySidebarState(state)
  );
  const action = useSelector((state) => {
    return state.readerActions.persistentActionState.actionBar;
  });
  const readerMode = useSelector((state) => selectReadrMode(state));

  const selectedTextId = useSelector((state) => state.texts.selectedTextId);
  const task = useSelector(selectCurrentTask);
  const ActiveTaskUrl = useSelector((state) => state.texts.activeTask);

  //Derived State
  const isSidebarCollapsed =
    primarySidebarState === PRIMARY_SIDEBAR_STATE.COLLAPSED;

  const PrTask = task.task_type === "peerReview";
  // Behavior

  function toggleTaskAction(wantedAction) {
    if (isReader) {
      navigateToTask();
    } else if (PrTask) {
      togglePrTask(wantedAction);
    } else {
      switchTaskToReader();
    }
  }

  function toggleReaderModes(wantedAction) {
    if (readerMode === READER_MODES.GUIDED_READING) {
      dispatch(setAnnotatorMode("highlight"));
      dispatch(setAction(""));
    } else {
      dispatch(setAction(wantedAction));
      dispatch(setAnnotatorMode(wantedAction));
    }
    dispatch(ToggleReaderMode());
  }

  function togglePrTask(wantedAction) {
    if (!isReader) dispatch(togglePrimarySidebar());
    dispatch(setAction(wantedAction));
    navigateToTask();
  }

  function switchTaskToReader() {
    if (readerMode === READER_MODES.GUIDED_READING) {
      dispatch(setAction(""));
      dispatch(setAnnotatorMode("highlight"));
    } else if (readerMode === READER_MODES.FREE_ANNOTATIONS) {
      dispatch(setAnnotatorMode("poc"));
      dispatch(setAction("poc"));
    }
    navigateToReader();
  }

  function navigateToTask() {
    return history.push(ActiveTaskUrl);
  }

  function navigateToReader() {
    return history.push(`/reader?text_id=${selectedTextId}`);
  }

  // side panel is disabled until further development
  return;

  // eslint-disable-next-line no-unreachable
  return (
    <Box className={classes.sidePanel}>
      <Box className={classes.guidedReadingBtn}>
        <TooltipWithIntl
          intlStringId="actionBar.tooltip.gr"
          defaultMessage="Guided reading"
          placement="bottom">
          <IconButton
            data-test-id="toggle-gr-sidebar"
            onClick={() => {
              isReader ? toggleReaderModes("poc") : switchTaskToReader();
            }}
            className={clsx(
              classes.panelButton,
              !isSidebarCollapsed && isReader && classes.selected
            )}
            aria-current={action === "poc" && "location"}
            size="large">
            <LiveHelpOutlinedIcon label="Guided reading" />
          </IconButton>
        </TooltipWithIntl>
      </Box>

      {ActiveTaskUrl && !isTask && (
        <>
          <Box className={classes.taskBtn}>
            <TooltipWithIntl
              intlStringId="actionBar.tooltip.task"
              defaultMessage="Task"
              placement="bottom">
              <IconButton
                onClick={() => {
                  toggleTaskAction("task");
                }}
                className={clsx(
                  classes.panelButton,
                  !isSidebarCollapsed && !isReader && classes.selected,
                  !isSidebarCollapsed && !isReader && task && classes.blueColor
                )}
                aria-current={action === "task" && "location"}
                size="large">
                <AssignmentIcon aria-label="Task" />
              </IconButton>
            </TooltipWithIntl>
          </Box>
        </>
      )}
    </Box>
  );
}
