import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { selectTextDirection } from "../../../redux/firestoreSelectors";
import { Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import * as d3 from "d3";

// Define CSS styles using makeStyles
const useStyles = makeStyles((theme) => ({
  titleText: {
    lineHeight: "1",
    padding: "24px 0",
    marginBottom: theme.spacing(1),
    fontWeight: "bold",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)"
  },
  emptyChart: {
    fill: "#D8D8D8",
    padding: "0",
    "& #top-container": {
      flexDirection: "column !important",
      "&::after": {
        content: `"No grade distribution yet"`,
        fontSize: "20px",
        paddingTop: "36px",
        display: "absolute",
        color: "#D8D8D8"
      }
    },
    "& #center-container": {
      padding: "24px"
    }
  },
  chart: {
    padding: "0",
    "& #center-container": {
      padding: "24px"
    }
  },
  argumentAxisLabel: {
    fontSize: "16px !important"
  },
  rtlArgumentAxisLabel: {
    fontSize: "16px !important"
  },
  argumentAxisRoot: {
    marginInline: "20px"
  },
  bar: {
    fill: "#3c90ee",
    display: "flex",
    alignSelf: "baseline"
  }
}));

const GradeDistributionStats = ({
  submissions,
  task,
  generalStatsHeight,
  totalPoints
}) => {
  // Use React-Intl for internationalization
  const intl = useIntl();
  // Get CSS classes
  const classes = useStyles();

  // State to store grade distribution data
  const [hist, setHist] = useState([]);
  const checkedSubmissions = submissions.filter((a) => a.is_checked);
  const [tooltipData, setTooltipData] = useState(null);

  // Calculate grade distribution when submissions or totalPoints change
  useEffect(() => {
    let graded = submissions.filter((a) => a.is_checked);
    let currHist = [
      // Define grade distribution data
      {
        grade: "0-50",
        val: graded.filter((a) => a.grade < 0.5 * totalPoints).length
      },
      {
        grade: "50-60",
        val: graded.filter(
          (a) => a.grade < 0.6 * totalPoints && a.grade >= 0.5 * totalPoints
        ).length
      },
      {
        grade: "60-70",
        val: graded.filter(
          (a) => a.grade < 0.7 * totalPoints && a.grade >= 0.6 * totalPoints
        ).length
      },
      {
        grade: "70-80",
        val: graded.filter(
          (a) => a.grade < 0.8 * totalPoints && a.grade >= 0.7 * totalPoints
        ).length
      },
      {
        grade: "80-90",
        val: graded.filter(
          (a) => a.grade < 0.9 * totalPoints && a.grade >= 0.8 * totalPoints
        ).length
      },
      {
        grade: "90-100",
        val: graded.filter((a) => a.grade >= 0.9 * totalPoints).length
      }
    ];

    setHist(currHist);
  }, [task, submissions, totalPoints]);

  // Create D3.js bar chart when grade distribution data changes
  useEffect(() => {
    if (hist && hist.length > 0) {
      const svg = d3.select("#chart");
      svg.selectAll("*").remove();

      // Define margins and dimensions
      const margin = { top: 55, right: 0, bottom: 45, left: 0 };
      const width = generalStatsHeight;
      const height = generalStatsHeight - margin.top - margin.bottom;

      // Create scales for x and y axes
      const x = d3
        .scaleBand()
        .domain(hist.map((d) => d.grade))
        .range([0, width])
        .padding(0.3);

      const y = d3
        .scaleLinear()
        .domain([0, d3.max(hist, (d) => d.val)])
        .nice()
        .range([height, 0]);

      const g = svg
        .append("g")
        .attr("class", !checkedSubmissions.length && classes.emptyChart)
        .attr("transform", `translate(${margin.left},${margin.top})`);

      // Create and style bar chart bars
      const bars = g
        .selectAll(".bar")
        .data(hist)
        .enter()
        .append("rect")
        .attr(
          "class",
          checkedSubmissions && checkedSubmissions.length
            ? classes.bar
            : classes.emptyChart
        )
        .attr("x", (d) => x(d.grade))
        .attr("y", (d) => y(d.val))
        .attr("width", x.bandwidth())
        .attr("height", (d) => height - y(d.val) - 150)
        // Add event listeners for mouseover and mouseout
        .on("mouseover", (d) => setTooltipData(d))
        .on("mouseout", () => setTooltipData(null));

      // Create and style x-axis labels
      g.append("g")
        .attr("class", classes.rtlArgumentAxisLabel)
        .attr("transform", `translate(0,${height})`)
        .call(d3.axisBottom(x).tickValues(x.domain()).tickSize(0))
        .selectAll("text")
        .style("text-anchor", "center")
        .attr("transform", "rotate(-90)")
        .attr("dy", "-0.5px")
        .attr("dx", "105px");
      svg.select(".domain").remove();
    }
  }, [hist, generalStatsHeight, classes, submissions]);

  // Render the component with Paper, Typography, and SVG elements
  return (
    <Paper elevation={0} sx={{ height: generalStatsHeight }}>
      <Typography variant="h5" className={classes.titleText}>
        {intl.formatMessage({
          id: "task.statistics.gradeDist",
          defaultMessage: "Grade distribution"
        })}
      </Typography>
      {checkedSubmissions.length === 0 && (
        <Typography
          variant="h5"
          style={{
            textAlign: "center",
            color: "#D8D8D8",
            display: "flex",
            justifyContent: "center"
          }}>
          {intl.formatMessage({
            id: "task.statistics.gradeDist",
            defaultMessage: "No grade distribution yet"
          })}
        </Typography>
      )}
      <svg id="chart" width={generalStatsHeight} height={generalStatsHeight} />
      {tooltipData && (
        // Render tooltip if there's data to display
        <div className="tooltip">
          <strong>Submissions:</strong> {tooltipData.val}
        </div>
      )}
    </Paper>
  );
};

export default GradeDistributionStats;
