// Dependencies
import React, { useState } from "react";
import clsx from "clsx";
import { useQuery, useStepStage } from "../../../hooks";
import { motion, AnimatePresence } from "framer-motion";

// Redux
import { useSelector } from "react-redux";
import { selectSubmission } from "../../../redux/tasksSlice";
import {
  selectSecondarySidebarCollapsed,
  selectIsComments,
  selectIsThumbnails,
  selectTextDirection
} from "../../../redux/firestoreSelectors";

// Components
import TaskFeedback from "../TaskFeedback/TaskFeedback";
import SidePanel from "../../SharedComponents/SidePanel";
import PrimarySidebarLayout from "../../SharedComponents/PrimarySidebarLayout";
import { GrInstructions } from "../../guidedReading/GrInstructions";

// Material UI
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { SecondarySidebarLayout } from "../../SharedComponents";
import PDFThumbBar from "../../reader/pdf/sidebar/PDFThumbBar";
import CommentPanel from "../../comments/CommentPanel";
import StandardSidebar from "../../Sidebar/StandardSidebar";
import TasksButton from "../TasksButton";
import { USER_TYPE } from "../../../consts";
import ReaderContent from "../../reader/ReaderContent";

// Styles
const useStyles = makeStyles((theme) => {
  return {
    readerViewContainer: {
      position: "relative",
      flex: 1,
      height: "100%",
      justifyContent: "center"
    },
    innerWrapper: {
      width: "100%",
      height: "100%",
      top: 0,
      left: 0
    },
    dirLtr: {
      direction: "ltr"
    },
    instructionsWrapper: {
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 2,
      width: "100%",
      height: "100%"
    },
    stageHeader: {
      paddingInline: `${100 / 6}%`,
      marginTop: theme.spacing(5)
    },
    subTitle: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(4),
      opacity: 0.6
    }
  };
});
export default function GuidedReading() {
  // Hooks
  const classes = useStyles();
  const { submission_id, type = null } = useQuery();

  // Redux Selectors
  const PrimarySidebarWidth = useSelector(
    (state) => state.layout.primarySidebarWidth
  );
  const SecondarySidebarWidth = useSelector(
    (state) => state.layout.secondarySidebarWidth
  );

  const submission = useSelector((state) =>
    selectSubmission(state, Number(submission_id))
  );
  const detailedInstructions = useSelector(
    (state) => state.gr.detailedInstructions
  );
  const courseRole = useSelector(
    (state) => state.user.userProfile.selectedCourse.course_role
  );
  const textDirection = useSelector((state) => selectTextDirection(state));
  const isSecondarySidebarCollapsed = useSelector((state) =>
    selectSecondarySidebarCollapsed(state)
  );
  const isCommentsInSecondarySidebar = useSelector((state) =>
    selectIsComments(state)
  );
  const grMode = useSelector((state) => state.gr.mode);
  const action = useSelector(
    (state) => state.readerActions.persistentActionState.actionBar
  );
  const [step] = useStepStage();

  const isThumbnailsInSecondarySidebar = useSelector((state) =>
    selectIsThumbnails(state)
  );

  // Ephemeral state
  const [showBook, setShowBook] = useState(true);
  const disableSeconderySidebar =
    ["poc", "", "task"].includes(action) && ["review", "answer"].includes(step);
  //Derived state

  //user role
  const isTeacher =
    courseRole === "Teacher" && type?.toUpperCase() !== USER_TYPE.STUDENT;
  const isAdmin =
    courseRole === "Admin" && type?.toUpperCase() !== USER_TYPE.STUDENT;

  const isStudent =
    courseRole === "Student" || type?.toUpperCase() === USER_TYPE.STUDENT;

  //submission status
  const status = submission.status;
  const isGraded = status === "Graded";
  const isSubmitted = status === "Submitted";

  //Behavior

  // Render
  if (isStudent && !isGraded) {
    return (
      <>
        <TasksButton />
        <PrimarySidebarLayout>
          <SidePanel />
          <StandardSidebar />
        </PrimarySidebarLayout>
        <Box
          className={clsx(classes.readerViewContainer, classes.dirLtr)}
          style={{
            width: `calc(100vw - ${PrimarySidebarWidth}px - ${SecondarySidebarWidth}px)`
          }}>
          <AnimatePresence>
            {detailedInstructions && (
              <motion.div
                className={classes.instructionsWrapper}
                initial={{ x: textDirection === "rtl" ? "100%" : "-100%" }}
                animate={{ x: "0" }}
                exit={{ x: textDirection === "rtl" ? "100%" : "-100%" }}
                transition={{ type: "spring", damping: 20 }}>
                <GrInstructions />
              </motion.div>
            )}
          </AnimatePresence>
          <motion.div
            className={classes.innerWrapper}
            animate={{
              zIndex: showBook && 0,
              opacity: showBook ? 1 : 0,
              transitionEnd: {
                zIndex: !showBook && -1
              }
            }}
            style={{
              pointerEvents: showBook ? "all" : "none"
            }}
            transition={{ duration: showBook ? 0.5 : 0 }}>
            <ReaderContent />
          </motion.div>
        </Box>

        <SecondarySidebarLayout
          collapsed={!isSecondarySidebarCollapsed || disableSeconderySidebar}>
          {isThumbnailsInSecondarySidebar && <PDFThumbBar />}
          {isCommentsInSecondarySidebar && <CommentPanel />}
        </SecondarySidebarLayout>
      </>
    );
  }
  if ((isTeacher || isAdmin) && isSubmitted) return <TaskFeedback />;
  if (isGraded) return <TaskFeedback />;
}

GuidedReading.propTypes = {};
