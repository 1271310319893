import { isAfter, isFuture, isPast } from "date-fns";

export function normalizeNum(value, min, max) {
  if (value - min === 0 || max - min === 0) return 0;
  else return ((value - min) * 100) / (max - min);
}

export function normalize(value, min, max) {
  // Normalize the function to 100
  // we can add a fourth arg for accuracy if
  return normalizeNum(value, min, max).toFixed(1);
}

export function getStudentIdsFromSubmissions(submissions) {
  // Input: submissions array
  // Output: Array if unique student ids

  return submissions.reduce((accumulator, current) => {
    // generate an array of all the student how submitted a task
    const currentStudent = current.owner;
    if (!accumulator.includes(currentStudent)) {
      accumulator.push(currentStudent);
    }
    return accumulator;
  }, []);
}

export function calculateSubmissionsGradeAvarage(submissions, tasks) {
  // Input: submissions array
  // Output: number that represent the avarage grade of submissions, scaled to 100%
  const taskMaxGrade = tasks.reduce((accumulator, current) => {
    accumulator[current.id] = current.max_grade;
    return accumulator;
  }, {});

  const { sum, count } = submissions.reduce(
    (accumulator, current) => {
      const grade =
        (100.0 * (current.grade || 0)) / taskMaxGrade[current.task_id];

      accumulator.sum += grade;
      accumulator.count++;

      return accumulator;
    },
    { sum: 0, count: 0 }
  );

  return count > 0 ? Math.round(sum / count) : NaN;
}

export function calculateStudentsAvarages(students, submissions, tasks) {
  // Input: students ids array and submissions array
  // Output: object {user: string (uid), avarage: number }

  return students.reduce((accumulator, current) => {
    const studentSubmissions = submissions.filter(
      (submission) => submission.owner === current
    );

    if (studentSubmissions.length > 0) {
      const gradeAvarage = calculateSubmissionsGradeAvarage(
        studentSubmissions,
        tasks
      );
      accumulator.push({
        user: current,
        avarage: gradeAvarage,
        displayName: studentSubmissions[0].user_name
      });
    }

    return accumulator;
  }, []);
}

export function bucketSubmissionByStatus(submissions, dueDate) {
  const stats = submissions.reduce((accumulator, current) => {
    const dueDate = new Date(current.due_date);
    const submissionDate = current.submission_date
      ? new Date(current.submission_date)
      : null;

    if (!submissionDate && isPast(dueDate)) {
      return accumulator.missed
        ? { ...accumulator, missed: accumulator.missed + 1 }
        : { ...accumulator, missed: 1 };
    } else if (!submissionDate && isFuture(dueDate)) {
      return accumulator.pending
        ? { ...accumulator, pending: accumulator.pending + 1 }
        : { ...accumulator, pending: 1 };
    } else if (isAfter(submissionDate, dueDate)) {
      return accumulator.late
        ? { ...accumulator, late: accumulator.late + 1 }
        : { ...accumulator, late: 1 };
    } else {
      return accumulator.onTime
        ? { ...accumulator, onTime: accumulator.onTime + 1 }
        : { ...accumulator, onTime: 1 };
    }
  }, {});
  return stats;
}

export function setHoveredBarLabel(
  event,
  d,
  barAttributes,
  selectedGraph,
  totalUsers,
  activeUsers,
  graphType
) {
  // Calculate percentage or absolute difference
  const rangeDifference = d[1] - d[0];

  let msg = "";
  let displayText;
  if (graphType === "barGraph")
    displayText = `${Math.round((activeUsers / totalUsers) * 100).toFixed(
      0
    )}% active students`;
  else {
    msg =
      selectedGraph === "Percentage"
        ? `${((rangeDifference / totalUsers) * 100).toFixed(2)}%`
        : rangeDifference;

    // Find the key corresponding to the hovered segment in d.data
    let key;
    for (const [k, value] of Object.entries(d.data)) {
      if (value === rangeDifference && k !== "week") {
        key = k;
        break;
      }
    }

    displayText =
      key && activeUsers
        ? `${msg} ${key} \n ${Math.round(
            (activeUsers / totalUsers) * 100
          ).toFixed(0)}% active students`
        : `${msg}\n`;
  }

  // Construct the display text

  // Calculate tooltip position
  const x = barAttributes.x(d) + barAttributes.width / 2;
  const y = barAttributes.y(d) - 10;

  return { displayText, x, y };
}

export const setHoveredBarLabelForPublishedTasks = (
  event,
  d,
  barAttributes,
  selectedGraph,
  totalUsers,
  data
) => {
  // Calculate total sum of numeric values in d.data, excluding 'week'
  const total = Object.entries(d.data)
    .filter(
      ([key, val]) => key !== "week" && typeof val === "number" && !isNaN(val)
    )
    .reduce((acc, [, val]) => acc + val, 0);
  const value = d[1] - d[0];
  const percentage = (value / total) * 100;
  // Step 1: Calculate the difference between d[0] and d[1]
  const difference = Math.abs(d[1] - d[0]);

  // Step 2: Find the key in d.data whose value is closest to the calculated difference
  let closestKey;
  let closestDifference = Infinity;
  let hoveredValue;
  for (const [key, val] of Object.entries(d.data)) {
    if (typeof val === "number" && !isNaN(val)) {
      const diff = Math.abs(val - difference);
      if (diff < closestDifference) {
        closestDifference = diff;
        closestKey = key;
      }
    }
  }

  // Step 3: Use the identified key and week to find the value in data
  const hoveredWeek = d.data.week; // Extract the week from d.data
  const weekData = data.find((weekObj) => weekObj.week === hoveredWeek);

  if (weekData && closestKey) {
    hoveredValue = weekData[closestKey];
  } else {
    console.error(
      `Week ${hoveredWeek} not found in data or closest key is undefined.`
    );
  }

  let displayText;
  if (selectedGraph === "Percentage") {
    displayText = `${percentage.toFixed(1)}%`;
  } else if (selectedGraph === "Numbers") {
    displayText = `Value: ${hoveredValue} \n Percentage: ${Math.round(value)}%`;
  }

  const x = event.offsetX - 60;
  const y = event.offsetY + 20;

  return { displayText, x, y };
};
