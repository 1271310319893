import { useEffect, useState } from "react";
import { useFirestoreDocData, useFirestore } from "reactfire";
import { doc } from "firebase/firestore";
// Redux
import { useSelector } from "react-redux";

export default function useSelectedQuestion(task_id = null, text_id) {
  // Hooks
  const firestore = useFirestore();
  const userId = useSelector((state) => state.firebase.auth.uid);
  
  const selectedInteractionId = useSelector(
    (state) => state.interactions.selectedInteractionId
  );
  const [selectedQuestionId, setSelectedQuestionId] = useState("");
  const [updatedAt, setUpdatedAt] = useState(new Date(-8640000000000000));

  //console.log("params", {task_id, normalizedTextId});
  const useTaskDoc = task_id
    ? doc(firestore, "tasks", userId, "task", "" + task_id)
    : doc(firestore, "sq3r", userId, "texts", String(text_id));

  const { data, status } = useFirestoreDocData(useTaskDoc);

  useEffect(() => {
    if (!selectedInteractionId) {
      
    
    if (status !== "loading") {

      const newDocUpdate = new Date(data?.updatedAt || -8630000000000000);
      if (newDocUpdate > updatedAt) {
        setUpdatedAt(newDocUpdate);
        //console.log("setSelectedQuestionId",{setSelectedQuestionId: data?.selectedQuestion || data?.grQuestionId || null, status, data});
        setSelectedQuestionId(
          data?.selectedQuestion || data?.grQuestionId || null
        );
      }
    }
    }
    else setSelectedQuestionId(selectedInteractionId); 
  }, [data, status, updatedAt, selectedInteractionId]);

  return selectedQuestionId;
}
