// Dependencies
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  flattenObject,
  formatLegendLabel,
  calculateSubmissionPercentage,
  formatMillisecondsTime,
  formatDate,
  sortTimeSpentOnAssignmentObject,
  sumTaskById,
  extractAssignmentNumberFromString
} from "../utils";
import { isEmpty } from "lodash";
// Components
import { generateMarkers } from "../pieChart/PieChartLegend";

// Material-UI
import { Box, Typography, IconButton } from "@mui/material";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { LEGEND_MARKER_FOR_TIME_SPENT_ON_ASSIGNMENT } from "../consts";
import { useGetTheme } from "../../../../hooks";

function StackedGraphLegend({
  type,
  data,
  selectedAssignment,
  courseStudents
}) {
  // Hooks
  const history = useHistory();
  const theme = useGetTheme();
  const [legendData, setLegendData] = useState([]);
  const isSubmissionsType = type === "submissions";

  useEffect(() => {
    if (isEmpty(data) || !selectedAssignment) return;
    if (selectedAssignment?.id) {
      const assignmentData = isSubmissionsType
        ? sumTaskById(data.userBased, selectedAssignment.id)
        : sortTimeSpentOnAssignmentObject(data[selectedAssignment.id]);
      setLegendData(flattenObject(assignmentData));
    }
  }, [selectedAssignment, data, isSubmissionsType]);

  const renderLegendItem = (key, value) => {
    if (
      value <= 0 ||
      (isSubmissionsType &&
        selectedAssignment.id !== extractAssignmentNumberFromString(key))
    )
      return null;
    const [part1, part2] = key.split(/_(.+)/);

    const markerKey = isSubmissionsType ? part2 : `${key}_total`;
    const legendText = isSubmissionsType
      ? `${formatLegendLabel(markerKey)}: ${calculateSubmissionPercentage(
          value,
          courseStudents.length
        )}`
      : `${
          LEGEND_MARKER_FOR_TIME_SPENT_ON_ASSIGNMENT[markerKey]
        }${formatMillisecondsTime(value)}`;

    return (
      <Box
        key={key}
        sx={{
          display: "flex",
          alignItems: "center",
          marginInlineStart: "8px",
          marginInlineEnd: "8px",
          marginBlockEnd: "16px"
        }}>
        {generateMarkers(markerKey, value, theme)}
        <Typography
          variant="caption"
          sx={{ marginInlineStart: "8px", color: theme.palette.text.primary }}>
          {legendText}
        </Typography>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexFlow: "column",
        width: "100%",
        height: "30%",
        marginInlineStart: "24px",
        marginBlockStart: "24px"
      }}>
      <Box
        sx={{
          paddingInlineStart: "8px",
          width: "fit-content"
        }}>
        {selectedAssignment && (
          <Typography
            variant="subtitle1"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              history.push(`/task/stats?task_id=${selectedAssignment.id}`);
            }}>
            {selectedAssignment?.name}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexFlow: "row",
          marginBlockStart: "16px"
        }}>
        {isSubmissionsType && selectedAssignment && (
          <Box
            sx={{
              paddingInline: "8px",
              marginBlockEnd: "16px",
              minWidth: "fit-content",
              height: "min-content",
              borderRight: `1px solid ${theme.palette.text.primary}`
            }}>
            <IconButton onClick={() => {}} size="medium" sx={{ padding: 0 }}>
              <CalendarTodayOutlinedIcon fontSize="small" />
            </IconButton>
            <Typography
              variant="caption"
              sx={{
                marginInline: "8px"
              }}>
              Due date: {formatDate(selectedAssignment.original_due_date)}
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            width: "auto",
            display: "flex",
            flexFlow: "row wrap",
            paddingInlineEnd: "40px",
            paddingInlineStart: isSubmissionsType ? "8px" : 0
          }}>
          {Object.entries(legendData).map(([key, value]) =>
            renderLegendItem(key, value)
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default StackedGraphLegend;
