export const truncateMiddle = (text, maxLength) => {
  if (text.length <= maxLength) {
    return text;
  }

  const ellipsis = "...";

  // Split the text into words
  const words = text.split(" ");

  // Initialize variables to track the length of the truncated text
  let truncatedText = "";
  let currentLength = 0;

  // Iterate through the words
  for (const word of words) {
    // Check if adding the current word exceeds the maxLength
    if (currentLength + word.length + ellipsis.length <= maxLength) {
      truncatedText += `${word} `;
      currentLength += word.length + 1; // Add 1 for the space
    } else {
      // Stop if adding the current word exceeds the maxLength
      break;
    }
  }

  // Trim the trailing space and add the ellipsis
  truncatedText = truncatedText.trim() + ellipsis;

  return truncatedText;
};

export const filterConversationsByQuestionId = (
  conversations,
  selectedQuestionId
) => {
  return Object.entries(conversations).reduce((accumulator, [key, value]) => {
    if (value.question_id === selectedQuestionId) {
      accumulator[key] = value;
    }
    return accumulator;
  }, {});
};
