// Dependencies
import React from "react";
import { aggregateActionsByDay } from "../courseActivity/utils";
//Components
import ExpandableTableRow from "./ExpandableTableRow";
// Material UI
import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from "@mui/material";
import TableDropdown from "./TableDropdown";

// Styles

export default function ReportTable({ actionsByDay }) {
  //Hooks

  // Ephemeral State

  //Derived state

  const actionsCount = aggregateActionsByDay(actionsByDay);
  const actionNames = actionsCount.length
    ? Object.keys(actionsCount[0]).filter((action) => action !== "date")
    : [];

  //Behavior
  const actionsAttributeMap = {
    logins: { displayName: "Logins" },
    tasksVisited: { displayName: "Tasks Visited" },
    feedbackVisited: { displayName: "Feedback Visited" },
    tasksCreated: { displayName: "Tasks Created" },
    textVisited: { displayName: "Text Visited" },
    grQuestionsCreated: { displayName: "GR Questions Created" },
    grHighlightsCreated: { displayName: "GR Highlights Created" }
  };

  function getDisplayName(attribute) {
    return actionsAttributeMap[attribute].displayName;
  }

  return (
    <Box style={{ width: "100%" }}>
      <Table aria-label="table">
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox" />
            <TableCell />
            {actionsCount.map((courseAction) => (
              <TableCell key={courseAction.date} align="center">
                {courseAction.date}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {actionNames.map((courseActionName, index) => (
            <ExpandableTableRow
              key={courseActionName}
              expandComponent={
                <TableCell padding="none" colSpan={courseActionName.length}>
                  <TableDropdown
                    actionsCount={actionsCount}
                    courseActionName={courseActionName}
                  />
                </TableCell>
              }>
              <TableCell component="th" scope="row">
                {getDisplayName(actionNames[index])}
              </TableCell>
              {actionsCount.map((courseAction) => {
                const currentAction = courseAction[courseActionName];
                return (
                  <TableCell
                    key={`${courseAction.date}-${currentAction}`}
                    align="center">
                    {currentAction.count.total}
                  </TableCell>
                );
              })}
            </ExpandableTableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}
