// Dependencies
import React, { useEffect, useState } from "react";
import { firebaseFunctions, firebaseApp, httpCallables } from "../../firebase";
import { v4 as uuid } from "uuid";
import { FormattedMessage, useIntl } from "react-intl";
import { useQuery } from "../../hooks";
import Epub from "epubjs/lib/index";
import * as pdfjs from "pdfjs-dist";
import firebase from "firebase/compat/app";
import SnowflakeId from "snowflake-id";
// Redux dependencies
import { useDispatch, useSelector } from "react-redux";
import { selectCourse } from "../../redux/coursesSlice";
import {
  dequeueFlashMessage,
  enqueueFlashMessage
} from "../../redux/userSlice";
import { selectTexts, addText, setTexts } from "../../redux/textsSlice";
import { addSnackbarItem } from "../../redux/snackbarSlice";
import { selectAlertsDuration } from "../../redux/firestoreSelectors";
// Components

import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress,
  TextField,
  MenuItem
} from "@mui/material";

import ErrorIcon from "@mui/icons-material/Error";
//Styles
const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    flex: 1,
    display: "flex",
    position: "relative",
    justifyContent: "center",
    alignItems: "center"
  },
  pointer: {
    cursor: "pointer",
    height: "73px"
  },
  libraryContainer: {
    height: "100%",
    width: "83.333333%", // 10 culumns on a 12 column grid
    alignItems: "center",
    position: "relative",
    margin: "0 auto",

    [theme.breakpoints.up("desktop")]: {
      marginInline: "auto"
    }
  },
  tableContainer: {
    width: "auto", // 10 culumns on a 12 column grid,
    overflow: "hidden",
    marginTop: theme.spacing(3)
  },
  tableHead: {
    fontWeight: "800"
  },
  headerFont: {
    fontSize: "20px"
  },
  link: {
    color: theme.palette.text.primary
  },
  libraryHeader: {
    marginTop: theme.spacing(7.5),
    marginBottom: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between"
  },

  cell: {
    marginTop: "16px",
    marginBottom: "16px",
    height: "100%"
  },
  authorName: {
    whiteSpace: "nowrap",
    maxWidth: "200px",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  textName: {
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    textOverflow: "ellipsis",
    "-webkit-box-orient": "vertical",
    overflow: "hidden"
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh"
  },
  readerViewContainer: {
    position: "relative",
    width: "100%",
    height: "max-content",
    minHeight: "calc(100vh - 120px)"
  },
  drawerContainer: {
    position: "relative",
    width: "100%",
    height: "max-content",
    minHeight: "calc(100vh - 120px)"
  },
  drawerLtr: {
    left: 0
  },
  drawer: {
    width: "56px",
    height: "100vh",
    position: "fixed",
    background: "black",
    zIndex: 120,
    top: 0,
    bottom: 0,
    whiteSpace: "nowrap"
  },
  dirLtr: {
    direction: "ltr"
  },
  content: {
    position: "relative",
    width: "100%",
    height: "max-content",
    minHeight: "calc(100vh - 120px)",
    display: "grid"
  },
  selectEmpty: {
    width: "60%",
    height: "80%",
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  chip: {
    margin: theme.spacing(0.5)
  },
  fileName: {
    marginBlockStart: theme.spacing(1),
    color: "var(--light-text-secondary, rgba(0, 0, 0, 0.60))",
    maxWidth: "100%", // Adjust the value based on the available space
    whiteSpace: "nowrap"
  },
  categoriesAndActions: {
    display: "flex",
    justifyContent: "space-between",
    height: "73px"
  },
  dialog: {
    padding: "20px 24px 0 24px"
  },
  input: { display: "none" }
}));

function HandleTextModal({ openTexts, setOpenTexts, text, setUploadedText }) {
  // Hooks
  const intl = useIntl();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { course_id } = useQuery();
  // Redux state
  const userProfile = useSelector((state) => state.user.auth);
  const course = useSelector((state) => selectCourse(state, Number(course_id)));
  const texts = useSelector((state) => selectTexts(state, Number(course_id)));
  const alertsDuration = useSelector((state) => selectAlertsDuration(state));

  // Ephemeral state
  const [author, setAuthor] = useState("");

  const [title, setTitle] = useState("");
  const [textLanguage, setTextLanguage] = useState("en");
  const [link, setLink] = useState("");
  const [fileName, setFileName] = useState("");
  const [uploadState, setUploadState] = useState({
    uploading: false,
    progress: 0
  });
  const [location, setLocation] = useState("");
  const [chapters, setChapters] = useState([]);
  const [fileStatus, setFileStatus] = useState({ type: "pending", msg: "" });
  const [saving, setSaving] = useState(false);

  // Bahavior
  const errorMsg = {
    tooManyPages:
      "The file is too long, please upload a file with less than 100 pages",
    invalidFile:
      "The file is invalid, please contact Alethea or the library for support",
    fileTooBig:
      "The file exceeds the size limit, please upload a file less than 100MB"
  };

  const notifySupport = firebaseFunctions.httpsCallable(
    "courses-NotifySupportOnfailedToUploadText"
  );

  const handleFileChange = (event) => {
    let currentFileStatus = { type: "pending", msg: "" };
    setFileStatus(currentFileStatus);
    const file = event.target.files[0];
    if (!file) return;

    const newSnowflakeId = new SnowflakeId({ mid: course_id });
    const prefix = newSnowflakeId.generate();
    const filename = `${prefix}_${file.name}`;

    const storageRef = firebase
      .storage()
      .ref(`courseTexts/${course_id}/${filename}`);

    setUploadState({ ...uploadState, uploading: true });
    const uploadTask = storageRef.put(file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadState({ ...uploadState, progress });
      },
      (error) => {
        console.error("Upload error:", error);
        setUploadState({ ...uploadState, uploading: false });
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((url) => {
          const extension = filename.split(".").pop();
          if (extension === "epub") {
            let book = new Epub(url, {});
            book.loaded.navigation.then((toc) => {
              setLocation(toc.toc[0].href);
              setFileStatus({ type: "approved", msg: "" });
              setChapters(getAllTocItems(toc.toc));
            });
            book.loaded.metadata.then((metadata) => {
              if (metadata.creator) {
                setAuthor((prevValue) => prevValue || metadata.creator);
              }
              if (metadata.title) {
                setTitle((prevValue) => prevValue || metadata.title);
              }
            });
          } else if (extension === "pdf") {
            fetch(url)
              .then((response) => {
                const contentLength = response.headers.get("Content-Length");
                if (!contentLength) {
                  throw new Error("Content-Length header is missing");
                }
                return parseInt(contentLength, 10);
              })
              .then(async (fileSize) => {
                if (fileSize > 100 * 1024 * 1024) {
                  currentFileStatus = {
                    type: "warning",
                    msg: "File is too big"
                  };
                  setFileStatus(currentFileStatus);
                  return;
                }
                return fetch(url).then((response) => response.arrayBuffer());
              })
              .then((data) => {
                if (!data) return;
                const uint8Array = new Uint8Array(data);
                readPdfData(data, currentFileStatus);
                if (uint8Array.length < 4) {
                  throw new Error("Invalid PDF file");
                }
                const header = String.fromCharCode(
                  uint8Array[0],
                  uint8Array[1],
                  uint8Array[2],
                  uint8Array[3]
                );
                if (header !== "%PDF") {
                  throw new Error("Invalid PDF file");
                }
                const authorMatch = /\/Author\s*\((.*?)\)/.exec(
                  new TextDecoder().decode(uint8Array)
                );
                const titleMatch = /\/Title\s*\((.*?)\)/.exec(
                  new TextDecoder().decode(uint8Array)
                );
                const pdfAuthor = authorMatch ? authorMatch[1] : "";
                const pdfTitle = titleMatch ? titleMatch[1] : "";
                if (pdfAuthor) {
                  setAuthor((prevValue) => prevValue || pdfAuthor);
                }
                if (pdfTitle) {
                  setTitle((prevValue) => prevValue || pdfTitle);
                }
              })
              .catch((error) => {
                console.error("Error:", error);
              });
          }
          setUploadState({ uploading: false, progress: Number(100) });
          setLink(filename);
          setFileName(truncateMiddle(file.name, 30));
        });
      }
    );
  };

  useEffect(() => {
    if (openTexts === "Edit") {
      setAuthor(text.author);
      setTitle(text.name);
      setLink(text.file_url);
      setFileName(truncateMiddle(text.file_url, 30));
      setLocation(text.file_location);
      httpCallables
        .textFunctions({
          func_name: "getTextDownloadUrl",
          courseId: text.course_id,
          fileUrl: text.file_url
        })
        .then((response) => {
          if (response.data.success) {
            const extension = text.file_url.split(".").pop();
            if (extension == "epub") {
              let book = new Epub(response.data.payload, {});
              book.loaded.navigation.then((toc) => {
                setChapters(getAllTocItems(toc.toc));
              });
            }
          }
        });
    }
  }, [openTexts, text]);

  const truncateMiddle = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    const ellipsis = "...";
    const snippetLength = Math.floor((maxLength - ellipsis.length) / 2);
    const snippetStart = text.slice(0, snippetLength);
    const snippetEnd = text.slice(-snippetLength);
    return `${snippetStart}${ellipsis}${snippetEnd}`;
  };

  const resetState = () => {
    setUploadState({ uploading: true, progress: 0 });
    setLocation("");
    setAuthor("");
    setChapters([]);
    setTitle("");
    setLink("");
    setFileName("");
    setFileStatus({ type: "pending", msg: "" });
  };

  const handleClose = () => {
    resetState();
    setOpenTexts(false);
  };
  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          variant="determinate"
          {...props}
          aria-label="text upload progress"
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}>
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }

  const getPagesWithText = async (pdfDoc, numPages) => {
    const pageTasks = [...Array(numPages).keys()];
    const promises = pageTasks.map((page) => {
      // page is 0 based, pdfjs use 1 based numbers
      return pdfDoc
        .getPage(page + 1)
        .then((page) => {
          return page.getTextContent().then((textContent) => {
            const text = textContent.items.map((item) => item.str).join("");
            if (text.length > 50) {
              return 1;
            }
            return 0;
          });
        })
        .catch((e) => {
          //console.log('Error reading page:', {e, page});
          return 0;
        });
    });

    const results = await Promise.all(promises);
    const pagesWithText = results.reduce((acc, val) => {
      return acc + val;
    }, 0);
    return pagesWithText;
  };

  const readPdfData = async (data, currentFileStatus) => {
    const arrayBuffer = data;

    // Use pdfjs to get the document
    const pdf = await pdfjs.getDocument(arrayBuffer).promise;

    // Get the number of pages
    const numPages = pdf.numPages;

    // Get pages with text
    const pagesWithText = await getPagesWithText(pdf, numPages);
    // Check validity conditions
    if (
      numPages > 100 ||
      numPages === 0 ||
      pagesWithText / numPages < 0.7 ||
      currentFileStatus.type === "warning"
    ) {
      setFileStatus({
        type: "warning",
        msg: numPages > 100 ? errorMsg.tooManyPages : errorMsg.invalidFile
      });
    } else {
      setFileStatus({ type: "approved", msg: "" });
    }
    return true;
  };
  // For new texts
  const getAllTocItems = (toc) => {
    /*toc.flatMap( chapter=> {
    if (chapter.subItems && chapter.subItems.length)
    return getAllTocItems(chapter.subItems) 
    return chapterAr;

  })*/
    return toc;
  };

  const saveText = async () => {
    setSaving(true);
    try {
      let text = {
        course: Number(course.id),
        name: title,
        author: author,
        text_language: textLanguage,
        file_location: location,
        file_url: link
      };
      httpCallables.createCourseText(text).then(({ data }) => {
        setSaving(false);
        const { success } = data;
        dispatch(dequeueFlashMessage());
        if (success) {
          const { text, offline_task_id = null } = JSON.parse(data.payload);

          dispatch(addText(text));
          setUploadedText && setUploadedText(text);
          dispatch(
            addSnackbarItem({
              actions: [
                {
                  intlId: "undo",
                  intlDefaultMsg: "undo",
                  callBack: "undoUploadText"
                }
              ],
              intlId: "text.uploadSuccessful",
              intlDefaultMessage: "Text added to library",
              id: uuid(),
              text,
              offline_task_id
            })
          );
        }
        handleClose();
      });
    } catch (error) {
      setSaving(false);
      dispatch(
        enqueueFlashMessage({
          message: intl.formatMessage({
            id: "texts.errorInUploadingNewText",
            defaultMessage: error.message
          }),
          duration: alertsDuration
        })
      );
    }
  };

  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };

  const updateText = async () => {
    setSaving(true);
    let updatedText = {
      course_id: Number(course_id),
      id: text.id,
      name: title,
      author: author,
      text_language: textLanguage,
      file_location: location,
      file_url: link
    };
    httpCallables.updateCourseText(updatedText).then(({ data }) => {
      setSaving(false);
      const { success } = data;
      if (success) {
        const textToDelete = text;
        const filteredTexts = texts.filter((text) => {
          if (text.id === textToDelete.id) return;
          return text;
        });
        dispatch(setTexts(filteredTexts));
        dispatch(addText(updatedText));
        handleClose();
      }
    });
  };

  useEffect(() => {
    if (fileStatus.type === "warning") {
      notifySupport({
        fullName: userProfile.displayName,
        email: userProfile.email,
        error_content: fileStatus.msg,
        text_name: fileName,
        course_id: course_id
      });
      // setFileStatus({ type: "pending", msg: "" });
    }
  }, [fileStatus, course_id]);

  return (
    <Dialog
      open={Boolean(openTexts)}
      PaperProps={{
        style: {
          width: "552px"
        }
      }}
      onClose={handleClose}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        <FormattedMessage
          id={`opentexts.${openTexts}.author`}
          defaultMessage={`${openTexts} text`}
        />
      </DialogTitle>
      <DialogContent className={classes.dialog}>
        <Box
          style={{
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "space-between",
            margin: 0,
            padding: 0
          }}>
          <TextField
            accept=".docx, application/pdf, application/msword, image/*"
            className={classes.input}
            id="file-upload"
            type="file"
            aria-label="Upload file"
            onChange={handleFileChange}
          />
          <label htmlFor="file-upload">
            <Button
              disableElevation
              variant="contained"
              color="secondary"
              component="span"
              className={classes.uploadImageButton}
              disabled={uploadState.uploading}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  document.getElementById("file-upload").click();
                }
              }}>
              Upload file
            </Button>
          </label>

          <Box className={classes.fileName}>
            {fileName && fileName.length && (
              <Typography variant="body">{fileName}</Typography>
            )}
          </Box>
          <CircularProgressWithLabel
            color="secondary"
            variant="determinate"
            value={uploadState.progress}
          />
        </Box>
        <Box sx={{ width: "100%", margin: "8px 0" }}>
          <Typography variant="caption" sx={{ width: "100%" }}>
            Ensure file is in PDF format, no larger than 100 MB and doesn&apos;t
            exceed 100 pages
          </Typography>
        </Box>
        <TextField
          required
          variant="outlined"
          onChange={(e) => {
            setAuthor(e.target.value);
          }}
          margin="dense"
          id="author"
          aria-label="text-author-input"
          label={intl.formatMessage({
            id: "texts.dialog.author",
            defaultMessage: "Author"
          })}
          type="text"
          value={author}
          fullWidth
        />
        <TextField
          required
          variant="outlined"
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          aria-label="text-title-input"
          value={title}
          margin="dense"
          id="title"
          label={intl.formatMessage({
            id: "texts.dialog.title",
            defaultMessage: "Title"
          })}
          type="text"
          fullWidth
        />
        {chapters.length > 0 && (
          <TextField
            margin="dense"
            fullWidth
            variant="outlined"
            inputProps={{
              "aria-label": intl.formatMessage({
                id: "texts.dialog.chapter",
                defaultMessage: "Chapter"
              })
            }}
            select
            value={location}
            onChange={handleLocationChange}
            aria-label="text-chapter-input"
            id="chapter"
            label={intl.formatMessage({
              id: "texts.dialog.chapter",
              defaultMessage: "Chapter"
            })}>
            {chapters.map((chapter, index) => {
              return (
                <MenuItem key={index} value={chapter.href}>
                  {chapter.label}
                </MenuItem>
              );
            })}
          </TextField>
        )}
        {fileStatus.type === "warning" ? (
          <>
            <Box
              sx={{
                display: "flex",
                flexFlow: "row nowrap",
                alignItems: "center",
                justifyContent: "center",
                margin: "8px 0",
                paddingBlock: "12px"
              }}>
              <ErrorIcon color="warning" />
              <Typography
                variant="body2"
                sx={{
                  width: "100%",
                  marginInlineStart: "10px",
                  fontWeight: 600
                }}>
                {fileStatus.msg}
              </Typography>
            </Box>
          </>
        ) : (
          <></>
        )}
      </DialogContent>
      <DialogActions>
        {saving && (
          <CircularProgress
            color="secondary"
            size={24}
            sx={{ marginRight: "8px" }}
          />
        )}
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={
            openTexts === "Add"
              ? async () => await saveText()
              : async () => await updateText()
          }
          color="secondary"
          disabled={
            !fileName ||
            !author ||
            !title ||
            (openTexts === "Add" && uploadState.progress !== 100) ||
            (openTexts === "Add" && fileStatus.type !== "approved") ||
            saving
          }>
          {openTexts === "Edit" ? "Save" : "Add Text"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default HandleTextModal;
