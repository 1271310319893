import React, { useRef } from "react";
import { v4 as uuid } from "uuid";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import { isPdfSelector } from "../../../redux/textsSlice";
import { useSelector, useDispatch } from "react-redux";
import { interactionsAPI } from "../../../api";
import clsx from "clsx";
import {
  InteractionCardEditableHeader,
  InteractionCardContent,
  InteractionCardFooter,
  InteractionCard,
  InteractionCardAction
} from "../card";
import { useRouteMatch } from "react-router-dom";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { addSnackbarItem } from "../../../redux/snackbarSlice";
import { updateInteraction } from "../../../redux/interactionsSlice";

const useStyles = makeStyles((theme) => ({
  hidden: {
    background: theme.palette.card.background.disabled,
    color: theme.palette?.card?.text?.disabled
  }
}));

function AnswerCard({ answer, showInText, disable }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const titleRef = useRef();
  const { path } = useRouteMatch();

  const color = path.includes("task") ? "primary" : "secondary";
  const isPdf = useSelector(isPdfSelector);

  function toggleAnswerVisibility(answer) {
    dispatch(
      updateInteraction({
        interaction: answer,
        update: { hidden: !answer.hidden }
      })
    );
  }

  function copyContentToClipBoard(content) {
    navigator.clipboard.writeText(content);
    dispatch(
      addSnackbarItem({
        intlId: "textCopied.success",
        intlDefaultMessage: "Text copied successfully",
        id: uuid()
      })
    );
  }

  return (
    <InteractionCard
      showInText={showInText}
      interaction={answer}
      color={color}
      collapseContent={true}
      // this is passed from the parent since the hidden state was only implamented in the answe view
      // When the hidden state is implamented for all cards it should be moved to the Card component
      className={clsx(answer?.hidden && classes.hidden)}>
      <InteractionCardEditableHeader ref={titleRef} />
      <InteractionCardContent />
      <InteractionCardFooter>
        {/* copy highlights */}
        <InteractionCardAction
          onClick={({ content }) => {
            copyContentToClipBoard(content);
          }}
          intlStringId="menu.tooltip.copyText"
          defaultMessage="Copy highlight content">
          <ContentCopyIcon />
        </InteractionCardAction>

        {/* show in text */}
        <InteractionCardAction
          onClick={() => {
            const quoteCard = isPdf ? { ...answer, isPdf: true } : answer;
            showInText(quoteCard);
          }}
          intlStringId="highlights.showInText"
          defaultMessage="Show in text">
          <LocationOnOutlinedIcon />
        </InteractionCardAction>

        {/* edit title */}
        <InteractionCardAction
          disabled={disable}
          onClick={() => {
            titleRef.current.focus();
          }}
          intlStringId={
            !answer?.title ? "highlights.addTitle" : "highlights.editTitle"
          }
          defaultMessage={!answer?.title ? "Add title" : "Edit title"}>
          <EditOutlinedIcon />
        </InteractionCardAction>

        {/* hide highlight */}
        {answer.hidden ? (
          <InteractionCardAction
            disabled={disable}
            onClick={() => {
              toggleAnswerVisibility(answer);
            }}
            intlStringId="highlights.hideQuote"
            defaultMessage="Hide">
            <VisibilityOffOutlinedIcon />
          </InteractionCardAction>
        ) : (
          <InteractionCardAction
            disabled={disable}
            onClick={() => {
              toggleAnswerVisibility(answer);
            }}
            intlStringId="highlights.hideQuote"
            defaultMessage="Hide">
            <VisibilityOutlinedIcon />
          </InteractionCardAction>
        )}

        {/* delete highlight */}
        <InteractionCardAction
          disabled={disable}
          onClick={() => {
            interactionsAPI.deleteHighlight(answer);
          }}
          intlStringId="highlights.delete"
          defaultMessage="Delete highlight">
          <DeleteOutlineOutlinedIcon />
        </InteractionCardAction>
      </InteractionCardFooter>
    </InteractionCard>
  );
}

AnswerCard.propTypes = {
  answer: PropTypes.object,
  showInText: PropTypes.func,
  disable: PropTypes.bool
};

export default AnswerCard;
