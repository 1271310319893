// Dependencies
import React from "react";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";
import { Draggable } from "react-beautiful-dnd";

// Redux Dependencies
import { useSelector } from "react-redux";

//Components
import TaskListItemDetails from "./TaskListItemDetails";
import ToggleTriangle from "../../SharedComponents/ToggleTriangle";
import useConvertToTimeZone from "../../../hooks/useConvertToTimezone";

import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Collapse,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@mui/material";
import { selectDarkMode } from "../../../redux/firestoreSelectors";

const useStyles = makeStyles((theme) => ({
  task: {
    borderBottomColor: theme.palette.divider,
    borderBottomStyle: "solid",
    borderBottomWidth: "1px"
  },
  taskHeader: {
    padding: "8px 16px"
  },
  taskSummary: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  tooltip: {
    // opacity:'1',
    backgroundColor: "rgba(97, 97, 97, 1);"
  },
  taskName: {
    flexBasis: "33%",
    fontWeight: "bold",
    color: theme.palette.text.primary,
    textAlign: "start",
    cursor: "pointer"
  },
  taskDates: {
    display: "inline-block",
    color: theme.palette.text.secondary,
    fontWeight: "100",
    flexShrink: "0",
    "& span:first-child": {
      marginRight: "48px"
    }
  },
  actionContainer: {
    flexShrink: "0"
  },
  actionIcon: {
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  taskStatus: {
    color: theme.palette.text.secondary,
    margin: "0 48px"
  }
}));

export default function TasksListItem({
  taskIndex,
  task,
  deleteTask,
  submissions,
  graders,
  course,
  ...props
}) {
  //Hooks
  const classes = useStyles();
  const [, TZPublishDate] = useConvertToTimeZone(
    task.publish_date,
    course.timezone
  );
  const [, TZDueDate] = useConvertToTimeZone(
    task.original_due_date,
    course.timezone
  );

  // Redux State
  const darkMode = useSelector((state) => selectDarkMode(state));

  const [isOpen, setIsOpen] = React.useState(props.singleItemView || false);

  const handleClick = (e) => {
    setIsOpen(!isOpen);
    e.stopPropagation();
    e.preventDefault();
  };

  // Display the task's name and the status if aplicable
  function renderTaskName() {
    return (
      <Typography
        // component="button"
        variant="body1"
        className={classes.taskName}>
        {task.name}
      </Typography>
    );
  }

  // Display the taks's publish date
  function renderPublishDate() {
    if (
      task.status !== "Draft" &&
      //  open &&
      task.publish_date
    ) {
      return (
        <Typography
          className={classes.taskPublish}
          component="span"
          variant="body1">
          <FormattedMessage id="tasks.published" defaultMessage="Published" />
          {TZPublishDate}
        </Typography>
      );
    }
  }

  // Display the task's deadline
  function renderDueDate() {
    if (
      task.status !== "Draft" &&
      task.publish_date &&
      task.original_due_date
    ) {
      return (
        <Typography
          className={classes.taskDeadline}
          component="span"
          variant="body1">
          <FormattedMessage
            id="tasks.manager.deadline"
            defaultMessage="Due date"
          />{" "}
          {TZDueDate}
        </Typography>
      );
    }
  }

  // display the avaliable action icons

  const getItemStyle = (isDragging, draggableStyle, darkMode) => {
    return {
      // styles we need to apply on draggables
      ...draggableStyle,

      ...(isDragging && {
        display: "table",
        background: darkMode ? "rgb(5,15,15)" : "rgb(235,235,235)"
      })
    };
  };

  // Render
  return (
    <>
      {props.singleItemView ? (
        <>
          <Box className={clsx(classes.task, classes.taskHeader)}>
            <Box className={classes.taskSummary}>
              {renderTaskName()}
              {isOpen && (
                <Box className={classes.taskDates}>
                  {renderPublishDate()}
                  {renderDueDate()}
                </Box>
              )}
            </Box>
          </Box>
          <TaskListItemDetails
            taskIndex={taskIndex}
            course={course}
            task={task}
            submissions={submissions}
            graders={graders}
          />
        </>
      ) : (
        <Draggable draggableId={"task_" + task.id} index={taskIndex}>
          {(provided, snapshot) => (
            <>
              <ListItem
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getItemStyle(
                  snapshot.isDragging,
                  provided.draggableProps.style,
                  darkMode
                )}
                className={classes.task}
                disableGutters={true}>
                <ListItemIcon onClick={handleClick}>
                  {task.status !== "Draft" && (
                    <ToggleTriangle isOpen={isOpen} />
                  )}
                </ListItemIcon>
                <ListItemText
                  disableTypography={true}
                  className={classes.taskSummary}>
                  {renderTaskName()}
                  {isOpen && (
                    <Box className={classes.taskDates}>
                      {renderPublishDate()}
                      {renderDueDate()}
                    </Box>
                  )}
                </ListItemText>
              </ListItem>
              <Collapse
                in={isOpen}
                timeout="auto"
                unmountOnExit
                role={"listitem"}>
                <TaskListItemDetails
                  taskIndex={taskIndex}
                  course={course}
                  task={task}
                  submissions={submissions}
                  graders={graders}
                />
              </Collapse>
            </>
          )}
        </Draggable>
      )}
    </>
  );
}
