// Dependencies
import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { motion, useAnimation } from "framer-motion";
import { useRouteMatch } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { selectTextDirection } from "../../redux/firestoreSelectors";
import { grAPI, interactionsAPI } from "../../api";
import { updateSq3r } from "../../redux/firebaseMiddleware";

// Components
import QuestionEditMenu from "./QuestionEditMenu";
import EditingButton from "../EditingButton";

import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { TriangleRight, TriangleLeft } from "@styled-icons/octicons";
import { useDrop } from "react-dnd";
import { setInteractionColor } from "../../redux/interactionsSlice";

const useStyles = makeStyles((theme) => ({
  header: {
    // paddingInline: theme.spacing(2),
    paddingBlock: theme.spacing(1.5),
    display: "flex",
    flexWrap: "wrap",
    cursor: "pointer",
    "&:hover button, &:focus-within button": {
      opacity: 1,
      transition: ".5s ease-out",
      right: "0px"
    },
    alignItems: "center",
    paddingInlineEnd: theme.spacing(2)
  },
  selectedHeader: {
    paddingBlock: theme.spacing(0.2)
  },
  triangleIcon: {
    "& > div": {
      borderRadius: 25,
      transition: ".15s ease-out",
      padding: 3,
      marginBlockStart: -3,
      marginLeftInlineStart: -3
    },
    "& > div:hover": {
      background: "rgba(255, 255, 255, 0.1)"
    },
    paddingInline: theme.spacing(1)
  },
  selected: {
    "& > div:hover": {
      background: "rgba(0, 0, 0, 0.1)"
    }
  },
  title: {
    flex: "75%"
  },
  subTitle: {
    opacity: 0.5
  },
  grSubTitle: {
    color: "rgba(144, 202, 249, 1)",
    "&.selected": {
      opacity: 0.7
    }
  },
  editTitle: {
    flex: "75%",
    display: "flex"
  }
}));

export default function QuestionHeader({
  isSelected,
  qId,
  internalQId,
  isGr,
  isUserGrQuestion,
  onClick,
  onExpand,
  title,
  subTitle,
  onCardDrop = () => {},
  disableDrop = false,
  forceQuestionSelection = false,
  onDelete = () => {},
  editable = true,
  updateQuestion = () => {}
}) {
  const classes = useStyles();
  const [isHovered, setHovered] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const controls = useAnimation();
  const isTask = useRouteMatch({ path: "/task" });
  const inputRef = useRef(null);
  const dispatch = useDispatch();

  const textDirection = useSelector((state) => selectTextDirection(state));
  const [mousePos, setMousePos] = useState({});
  const selectedQuestionId = useSelector(
    (state) => state.interactions.selectedInteractionId
  );
  const [editingMode, setEditingMode] = useState(null);

  // Ephemeral State
  const [{ isOver, canDrop, targetId }, drop] = useDrop({
    accept: "QUESTION_CARD",
    collect: (monitor) => {
      return {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        targetId: monitor.targetId
      };
    },
    drop: (item, monitor) => {
      setExpanded(true);
      onCardDrop(item, qId);
    },
    canDrop: () => !disableDrop
  });

  //Behavior
  useEffect(() => {
    if (isHovered || isOver) {
      controls.start("hovered");
    } else {
      controls.start(isSelected ? "selected" : "default");
    }
  }, [isHovered, isSelected, isOver]);

  useEffect(() => {
    onExpand(expanded);
  }, [expanded]);

  useEffect(() => {
    if (editingMode && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editingMode, inputRef]);

  function deleteQuestion() {
    setMousePos({});
    onDelete(qId);
  }

  return (
    <motion.div
      ref={drop}
      className={clsx(classes.header, editingMode && classes.selectedHeader)}
      initial={false}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      transition={{ duration: 0.1 }}
      data-test-class="question-header"
      variants={{
        selected: {
          background: isTask
            ? "rgba(144, 202, 249, 1)"
            : "rgba(94, 200, 145, 1)",
          color: "rgba(0, 0, 0, 0.87)"
        },
        default: {
          background: isTask
            ? "rgba(144, 202, 249, 0)"
            : "rgba(94, 200, 145, 0)",
          color: "#FFF"
        },
        hovered: {
          background: isSelected
            ? isTask
              ? "rgba(144, 202, 249, 0.7)"
              : "rgba(94, 200, 145, 0.7)"
            : isTask
              ? "rgba(144, 202, 249, 0.1)"
              : "rgba(94, 200, 145, 0.1)",
          color: isSelected ? "rgba(0, 0, 0, 0.87)" : "#FFF"
        }
      }}
      animate={controls}
      onClick={(e) => {
        onClick(forceQuestionSelection ? qId : isSelected ? false : qId);
      }}>
      <div
        className={clsx(classes.triangleIcon, {
          [classes.selected]: isSelected
        })}>
        <motion.div
          transition={{ type: "spring", damping: 15 }}
          onClick={(e) => {
            e.stopPropagation();
            setExpanded(!expanded);
          }}
          data-test-class="question-header-chevron"
          style={{
            display: "inline-block"
          }}
          animate={expanded ? "expanded" : "default"}
          variants={{
            expanded: {
              rotate: textDirection === "rtl" ? -90 : 90
            },
            default: {
              rotate: 0
            }
          }}>
          {textDirection === "rtl" ? (
            <TriangleLeft size={24} />
          ) : (
            <TriangleRight size={24} />
          )}
        </motion.div>
      </div>
      {editable && editingMode === qId ? (
        <div className={classes.editTitle} data-no-dnd="true">
          <EditingButton
            multiline={true}
            text={title}
            editingMode={editingMode === qId}
            editIcon={<span />}
            onFocusOut={(e) => {
              updateQuestion(e);
              setEditingMode(null);
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                setEditingMode(null);
                updateQuestion(e.target.value);
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              e.preventDefault();
            }}
          />
        </div>
      ) : (
        <div className={classes.title}>
          <Typography>{title}</Typography>
          {!isUserGrQuestion && subTitle && (
            <Typography
              variant="body2"
              className={clsx(
                isGr && !isSelected ? classes.grSubTitle : classes.subTitle
              )}>
              {subTitle}
            </Typography>
          )}
        </div>
      )}
      {editingMode !== qId && (
        <QuestionEditMenu
          editable={editable}
          questionId={qId}
          className={classes.menu}
          selected={selectedQuestionId === qId}
          open={"X" in mousePos}
          handleClose={() => {
            setMousePos({});
          }}
          onDelete={deleteQuestion}
          onEdit={(e) => {
            grAPI.updateGrState({ grQuestionId: qId });
            grAPI.updateSelectedQuestionId(qId);
            setEditingMode(qId);
            setMousePos({});
          }}
          onColor={(color) => {
            dispatch(setInteractionColor(qId, color));
            interactionsAPI.updateHighlightsColorByQuestionId(
              qId,
              internalQId,
              color
            );
          }}
          mouseX={mousePos ? mousePos.X : null}
          mouseY={mousePos ? mousePos.Y : null}
        />
      )}
    </motion.div>
  );
}

QuestionHeader.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.node,
  qId: PropTypes.string.isRequired,
  isGr: PropTypes.bool.isRequired,
  isUserGrQuestion: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onExpand: PropTypes.func.isRequired,
  onCardDrop: PropTypes.func.isRequired,
  disableDrop: PropTypes.bool,
  forceQuestionSelection: PropTypes.bool,
  onDelete: PropTypes.func,
  onSortQuestions: PropTypes.func,
  editable: PropTypes.bool,
  updateQuestion: PropTypes.func
};
