import React, { useState, useEffect, useCallback } from "react";

import { useIntl } from "react-intl";
import { Box, Typography, Paper, IconButton } from "@mui/material";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import { makeStyles } from "@mui/styles";
import StackedPieChart from "./StackedPieChart";
import { useGetTheme } from "../../../../../hooks";
import { CHARTS_VIEW_TYPE } from "../../consts";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  titleText: {
    lineHeight: "1",
    marginBottom: "0",
    padding: "24px 12px"
  },
  legend: {
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between"
  },
  item: {
    width: "auto",
    padding: "24px 0",
    display: "flex",
    flexFlow: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    "&:last-child": {
      borderBottom: "none"
    },
    paddingInlineStart: theme.spacing(2)
  },
  chart: {
    padding: "0"
  },
  label: {
    fontSize: "16px !important",
    display: "inline-block",
    textAlign: "left",
    verticalAlign: "middle",
    "& span": {
      fontSize: "16px !important"
    },
    marginInlineStart: theme.spacing(1)
  },
  legendContainer: {
    borderTop: "1px solid rgba(0, 0, 0, 0.12)"
  }
}));

export const Marker = ({ color, ...restProps }) => {
  return (
    <svg
      style={{ verticalAlign: "middle" }}
      fill={color}
      width="24"
      height="24"
      {...restProps}>
      <circle r={12} cx={12} cy={12} {...restProps} />
    </svg>
  );
};

export const Label = ({ ...restProps }) => {
  const classes = useStyles();
  return <Typography {...restProps} className={classes.label} />;
};

export const TitleText = ({ text, ...restProps }) => {
  const classes = useStyles();
  return (
    <Box component="header" {...restProps}>
      <Typography variant="h5" className={classes.titleText}>
        {text}
      </Typography>
    </Box>
  );
};

const ToggleButton = ({ condition, handleClick, label }) => (
  <Box sx={{ display: "flex", alignItems: "center" }}>
    <IconButton
      aria-label={`${label}: ${condition === true ? "on" : "off"}`}
      onClick={handleClick}
      size="large">
      {condition ? (
        <ToggleOnIcon fontSize="large" color="primary" />
      ) : (
        <ToggleOffIcon fontSize="large" />
      )}
    </IconButton>
    <Typography variant="body1">{label}</Typography>
  </Box>
);

export default function StackedPieChartBody({
  data,
  height,
  width,
  totalSubmissions,
  chartType
}) {
  const intl = useIntl();
  const theme = useGetTheme();
  const classes = useStyles();
  const [array1, setArray1] = useState([]);
  const [array2, setArray2] = useState([]);
  const [includeFutureAssignments, setIncludeFutureAssignments] =
    useState(false);

  const [selectedSubmissions, setSelectedSubmissions] = useState(data);
  const [totalSelectedSubmissions, setTotalSelectedSubmissions] =
    useState(totalSubmissions);

  function removeSubmissionAndReplaceUnderscore(str) {
    return str.replace("Submission", "").replace(/_/g, " ");
  }
  const calculateSubmissionPercentage = useCallback(
    (data) => {
      return `${Math.round((Number(data) / totalSelectedSubmissions) * 100)}%`;
    },
    [totalSelectedSubmissions]
  );

  function sumAndRemovePendingEntries(data, totalSubmissions) {
    // Filter out entries with "Pending" in the "cat" property
    const filteredData = data.filter((entry) => entry.cat.includes("Pending"));

    // Calculate the sum of the "val" properties of the filtered entries
    const sumVals = filteredData.reduce((sum, entry) => sum + entry.val, 0);

    // Subtract the sum of "val" from totalSubmissions
    const adjustedTotalSubmissions = totalSubmissions - sumVals;

    return adjustedTotalSubmissions;
  }

  function splitData(data) {
    const array1 = data.slice(0, Math.floor(data.length / 2));
    const array2 = data.slice(Math.floor(data.length / 2));
    return [array1, array2];
  }

  function removePendingEntries(data) {
    return data.filter((entry) => !entry.cat.includes("Pending"));
  }
  useEffect(() => {
    const [array1, array2] = splitData(selectedSubmissions);
    setArray1(array1);
    setArray2(array2);
  }, [selectedSubmissions]);

  function updateAndFilterCategories(arr) {
    // Define the allowed categories
    const allowedCategories = [
      "SubmissionPending",
      "SubmissionOnTime",
      "SubmissionMissed",
      "SubmissionLate"
    ];

    return arr
      .map((obj) => {
        const { val, cat } = obj;
        // Update the `cat` property by removing everything before the underscore
        const updatedCat = cat.includes("_") ? cat.split("_")[1] : cat;
        return { val, cat: updatedCat };
      })
      .filter((obj) => allowedCategories.includes(obj.cat)); // Filter to keep only the allowed categories
  }

  function transformData(data) {
    // Define the color mapping for each category and subcategory combination
    const colorMapping = {
      SubmissionPending_complete: "#616161",
      SubmissionPending_incomplete: "#9E9E9E",
      SubmissionPending_no_activity: "#E0E0E0",
      SubmissionOnTime_complete: "#2E7D32",
      SubmissionOnTime_incomplete: "#4CAF50",
      SubmissionOnTime_no_activity: "#A5D6A7",
      SubmissionMissed_complete: "#D32F2F",
      SubmissionMissed_incomplete: "#BE134D",
      SubmissionMissed_no_activity: "#EF9A9A",
      SubmissionLate_complete: "#ED6C02",
      SubmissionLate_incomplete: "#FFA726",
      SubmissionLate_no_activity: "#FFCC80"
    };

    // Transform the data to match the desired output format
    return data.flatMap(({ val, cat }) => [
      {
        val: val.complete,
        cat: `${cat}_complete`,
        color: colorMapping[`${cat}_complete`]
      },
      {
        val: val.incomplete,
        cat: `${cat}_incomplete`,
        color: colorMapping[`${cat}_incomplete`]
      },
      {
        val: val.no_activity,
        cat: `${cat}_no_activity`,
        color: colorMapping[`${cat}_no_activity`]
      }
    ]);
  }

  useEffect(() => {
    if (!data.length) return;

    if (includeFutureAssignments) {
      setSelectedSubmissions(
        chartType === CHARTS_VIEW_TYPE.WEEK
          ? transformData(updateAndFilterCategories(data))
          : data
      );
      setTotalSelectedSubmissions(totalSubmissions);
    } else {
      const filteredData = removePendingEntries(
        chartType === CHARTS_VIEW_TYPE.WEEK
          ? transformData(updateAndFilterCategories(data))
          : data
      );
      setSelectedSubmissions(filteredData);
      const filteredTotalSubmissions = sumAndRemovePendingEntries(
        data,
        totalSubmissions
      );
      setTotalSelectedSubmissions(filteredTotalSubmissions);
    }
  }, [includeFutureAssignments, data, totalSubmissions]);

  const handleToggle = (setter, value) => async () => {
    const newValue = !value;
    setter(newValue);
  };
  return (
    <Paper elevation={0}>
      {/* Render the title */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: `1px solid ${theme.palette.border.main}`
        }}>
        <TitleText
          text={intl.formatMessage({
            id: "task.submissions.submissionsStatus",
            defaultMessage: `Submissions status - total submissions: ${totalSelectedSubmissions}`
          })}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            paddingInlineEnd: theme.spacing(2)
          }}>
          <ToggleButton
            condition={includeFutureAssignments}
            handleClick={handleToggle(
              setIncludeFutureAssignments,
              includeFutureAssignments,
              "includeFutureAssignments"
            )}
            label="Include future assignments"
          />
        </Box>
      </Box>
      <Box className={classes.container}>
        <Box className={classes.legendContainer}>
          <Box className={classes.legend}>
            {array1.map((dataPoint, index) => (
              <Box key={index} className={classes.item}>
                <Box sx={{ marginInlineEnd: "16px" }}>
                  <Marker color={dataPoint.color} />
                  <Label>
                    {removeSubmissionAndReplaceUnderscore(dataPoint.cat)}:
                  </Label>
                </Box>
                <Typography variant="body1" sx={{ paddingInlineStart: "8pxs" }}>
                  {calculateSubmissionPercentage(dataPoint.val)}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
        {/* Render the chart using D3.js */}
        <StackedPieChart
          data={selectedSubmissions}
          width={width}
          height={height}
          totalSubmissions={totalSelectedSubmissions}
          removeSubmissionAndReplaceUnderscore={
            removeSubmissionAndReplaceUnderscore
          }
        />

        {/* Render the legend */}
        <Box className={classes.legendContainer}>
          <Box className={classes.legend}>
            {array2.map((dataPoint, index) => (
              <Box key={index} className={classes.item}>
                <Box sx={{ marginInlineEnd: "16px" }}>
                  <Marker color={dataPoint.color} />
                  <Label>
                    {removeSubmissionAndReplaceUnderscore(dataPoint.cat)}:
                  </Label>
                </Box>
                <Typography variant="body1" sx={{ paddingInlineEnd: "16px" }}>
                  {calculateSubmissionPercentage(dataPoint.val)}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}
