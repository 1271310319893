/* eslint react/prop-types: 0 */
// Dependencies
import React from "react";
import { makeStyles } from "@mui/styles";
import { Typography, TextField, Autocomplete } from "@mui/material";
import { collection, query, where } from "firebase/firestore";
import { useFirestoreCollectionData, useFirestore } from "reactfire";

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    fontWeight: "bold",
    fontSize: theme.typography.caption.fontSize
  }
}));

const AssistantName = ({
  botType,
  assistantName,
  handleNameChange,
  ...params
}) => {
  const classes = useStyles();
  // Get botType options
  const firestore = useFirestore();
  const promptsCollection = collection(firestore, "openAiPrompts");
  const promptsRef = query(
    promptsCollection,
    where("botType", "==", botType || "")
  );

  const { data } = useFirestoreCollectionData(promptsRef);
  const botNames = data?.map((d) => d.name) || [];
  return (
    <>
      <Typography variant="p" className={classes.sectionTitle}>
        Name
      </Typography>
      <Autocomplete
        id="combo-box-demo"
        options={botNames}
        freeSolo
        sx={{
          fontWeight: "bold",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              transition: "border-color 0.2s" // Optional: Add a transition for a smoother effect
            },
            "&:hover fieldset": {
              borderColor: "initial" // Reset border color on hover
            }
          }
        }}
        renderInput={(params) => <TextField {...params} label="Bot Name" />}
        value={assistantName}
        onChange={(event, newValue) => {
          handleNameChange(newValue);
        }}
      />
    </>
  );
};

export default AssistantName;
