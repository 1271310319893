import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import { Box, Divider, Typography, Button } from "@mui/material";
import * as d3 from "d3";
import { makeStyles } from "@mui/styles";
import { useChartDimensions } from "../../../../../hooks";
import clsx from "clsx";
import GraphTitle from "./BarGraphTitle";
import { colorMapping } from "../../consts";
import {
  setHoveredBarLabel,
  setHoveredBarLabelForPublishedTasks
} from "../../../../Tasks/Stats/utils";

const useStyles = makeStyles((theme) => ({
  barGraphContainer: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "15px",
    padding: theme.spacing(2),
    height: "100%"
  },
  barGraph: {
    height: "300px"
  },
  footer: {
    display: "flex",
    flexFlow: "row",
    justifyContent: "space-between",
    paddingBlockStart: theme.spacing(1)
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(1)
  },
  graphMetricsButton: {
    marginInlineStart: theme.spacing(1),
    borderRadius: "20px",
    height: "80%",
    color: theme.palette.text.primary
  },
  selectedButton: {
    backgroundColor: "rgba(21, 101, 192, 0.3)",
    color: theme.palette.text.primary
  }
}));

const Footer = ({ footer, totalOfStudents, colorMapping }) => {
  const classes = useStyles();
  if (!footer) return;
  const footNote = `Total students: ${totalOfStudents}`;
  return (
    <Box
      sx={{
        display: "inline-flex",
        flexFlow: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        paddintTop: "4px"
      }}>
      <Typography variant="p" align="right" sx={{ fontSize: "10 px" }}>
        {footNote}
      </Typography>
      <Box className={classes.footer}>
        {Object.values(footer).map((note, i) => {
          return (
            <Box component="footer" key={i}>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "8px"
                }}>
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                    backgroundColor:
                      colorMapping[note.toLowerCase().split(" ")[0]],
                    marginRight: "5px"
                  }}></div>
                <Typography variant="p">{note}</Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

Footer.propTypes = {
  footer: PropTypes.object.isRequired,
  colorMapping: PropTypes.object.isRequired,
  totalOfStudents: PropTypes.number.isRequired
};

export const StackedGraph = ({
  data,
  width,
  height,
  graphButtons,
  footer,
  title,
  totalUsers,
  dataPlotType
}) => {
  const classes = useStyles();
  const graphRef = useRef();
  const [selectedGraph, setSelectedGraph] = useState(
    Object.values(graphButtons)[0]
  );
  const { ref, dimensions } = useChartDimensions({});

  const publishedTasksGraph = title === "Published tasks";
  const handleGraphChange = (event) => {
    setSelectedGraph(event.target.innerText);
  };

  const setLabelByGraph = useCallback(
    (d) => {
      let sum = 0;
      Object.values(d).forEach((value) => {
        if (typeof value === "number") {
          sum += value;
        }
      });
      if (selectedGraph === "Average")
        return Math.floor(sum / d.uniqueUsers).toFixed(0);
      if (sum === 0) return 0;
      return sum;
    },
    [selectedGraph]
  );

  const configureStackedGraph = useCallback(
    (selectedGraph, data, dimensions, colorMapping) => {
      let yScale, labelYPosition, barAttributes, stackedData;
      const xScale = d3
        .scaleBand()
        .domain(data.map((d) => d.week))
        .range([0, dimensions.boundedWidth]);

      const stackKeys = data.length
        ? Object.keys(data[0]).filter(
            (key) => key !== "week" && key !== "uniqueUsers"
          )
        : [];

      // Normalize data to percentages
      const normalizedData = data.map((d) => {
        const total = stackKeys.reduce((sum, key) => sum + d[key], 0);
        const normalized = { week: d.week };
        stackKeys.forEach((key) => {
          normalized[key] = total > 0 ? (d[key] / total) * 100 : 0;
        });
        return normalized;
      });

      const stack = d3.stack().keys(stackKeys);
      stackedData = stack(publishedTasksGraph ? normalizedData : data);

      // Set y-scale to always go from 0 to 100%
      yScale = publishedTasksGraph
        ? d3
            .scaleLinear()
            .domain([0, 100])
            .range([dimensions.boundedHeight - 15, 0])
        : d3
            .scaleLinear()
            .domain([
              0,
              d3.max(stackedData, (layer) => d3.max(layer, (d) => d[1]))
            ])
            .range([dimensions.boundedHeight - 15, 0]);

      labelYPosition = -5;

      barAttributes = {
        x: (d) => xScale(d.data.week) + (xScale.bandwidth() - 9) / 2,
        y: (d) => yScale(d[1]),
        width: 9,
        height: (d) =>
          yScale(d[0]) - yScale(d[1]) > 0 ? yScale(d[0]) - yScale(d[1]) : 0,
        fill: (d) => colorMapping[d.key],
        rx: 10,
        ry: 6
      };

      return { xScale, yScale, labelYPosition, barAttributes, stackedData };
    },
    [publishedTasksGraph]
  );

  const setupChart = useCallback(() => {
    if (dimensions.boundedWidth <= 0 || dimensions.boundedHeight <= 0) return;

    // Configure the graph based on the selected graph type
    const { xScale, labelYPosition, stackedData, barAttributes } =
      configureStackedGraph(
        selectedGraph, // This should be one of "Percentage", "Total", "Average", "Numbers"
        data,
        dimensions,
        colorMapping,
        setLabelByGraph
      );
    // Initialize the SVG and Chart
    const svg = d3
      .select(graphRef.current)
      .attr("width", dimensions.width)
      .attr("height", dimensions.height);

    const chart = svg
      .selectAll("g.chart")
      .data([null])
      .join("g")
      .attr("class", "chart")
      .attr("transform", `translate(45, ${dimensions.marginTop})`);

    chart.selectAll(".bar-group").remove(); // Clear existing bar groups
    chart.selectAll(".bar").remove();
    chart.selectAll(".bar-label").remove();
    chart.selectAll(".x-axis").remove();
    chart.selectAll(".text-background").remove();
    // Draw stacked bars
    chart
      .selectAll(".bar-group")
      .data(stackedData)
      .join("g")
      .attr("class", "bar-group")
      .attr("fill", (d) => {
        return colorMapping[d.key];
      })
      .style("display", (d) => {
        return colorMapping[d.key] ? "block" : "none";
      })
      .style("z-index", 0)
      .selectAll(".bar")
      .data((d) =>
        d.filter((innerArray) => !(innerArray[0] === 0 && innerArray[1] === 0))
      )
      .join("rect")
      .attr("class", "bar")
      .attr("x", barAttributes.x)
      .attr("y", barAttributes.y)
      .attr("width", barAttributes.width)
      .attr("height", barAttributes.height)
      .on("mouseover", (event, d) => {
        const { displayText, x, y } = publishedTasksGraph
          ? setHoveredBarLabelForPublishedTasks(
              event,
              d,
              barAttributes,
              selectedGraph,
              totalUsers,
              data
            )
          : setHoveredBarLabel(
              event,
              d,
              barAttributes,
              selectedGraph,
              totalUsers,
              d.data.uniqueUsers,
              "stackedGraph"
            );

        const textHeight = 12;
        const borderRadius = 5;
        const paddingX = 4;
        const paddingY = 6;
        const svgRect = chart.node().getBoundingClientRect();

        const relativeX = event.clientX - svgRect.left + 40;
        const relativeY = event.clientY - svgRect.top - 30;

        const textElement = chart
          .append("text")
          .attr("class", "bar-value")
          .attr("x", relativeX)
          .attr("y", relativeY)
          .style("text-anchor", "middle")
          .style("fill", "white")
          .style("font-size", "12px")
          .style("z-index", 9999);

        const lines = displayText.split("\n");
        lines.forEach((line, index) => {
          textElement
            .append("tspan")
            .attr("x", x)
            .attr("dy", `${index * textHeight}px`)
            .style("z-index", 9999)
            .text(line);
        });

        const textBBox = textElement.node().getBBox();
        const textBackgroundWidth = textBBox.width + paddingX * 2;
        const textBackgroundHeight = textBBox.height + paddingY * 2;

        chart
          .insert("rect", ":first-child")
          .attr("class", "text-background")
          .attr("x", x - textBackgroundWidth / 2)
          .attr("y", textBBox.y - paddingY)
          .attr("width", textBackgroundWidth)
          .attr("height", textBackgroundHeight)
          .style("fill", "rgba(97, 97, 97)")
          .style("ry", borderRadius)
          .style("rx", borderRadius)
          .style("z-index", 9999);
      })
      .on("mouseout", () => {
        chart.selectAll(".bar-value").remove();
        chart.selectAll(".text-background").remove();
      });

    // Draw x-axis
    chart
      .append("g")
      .attr("class", "x-axis")
      .attr("transform", `translate(0, ${dimensions.boundedHeight})`)
      .call(d3.axisBottom(xScale).tickSize(0))
      .call((g) => g.select(".domain").remove())
      .selectAll("text")
      .style("text-anchor", "middle");

    // Draw labels
    title !== "Published tasks" &&
      chart
        .selectAll(".bar-label")
        .data(data)
        .join("text")
        .attr("class", "bar-label")
        .attr("x", (d) => xScale(d.week) + xScale.bandwidth() / 2)
        .attr("y", labelYPosition)
        .style("text-anchor", "middle")
        .style("fill", "black")
        .style("font-size", "12px")
        .text(setLabelByGraph);
  }, [
    data,
    dimensions,
    graphRef,
    selectedGraph,
    title,
    totalUsers,
    setLabelByGraph,
    configureStackedGraph,
    publishedTasksGraph
  ]);

  useEffect(() => {
    if (dimensions.boundedWidth > 0 && dimensions.boundedHeight > 0) {
      setupChart();
    }
  }, [setupChart, dimensions, dataPlotType]);
  return (
    <Box className={classes.barGraphContainer} height={height * 1.4}>
      <Box className={classes.header}>
        <GraphTitle text={title} />
        <Box>
          {Object.values(graphButtons).map((button, i) => {
            return (
              <Button
                variant="outlined"
                color="primary"
                key={i}
                className={clsx(
                  classes.graphMetricsButton,
                  selectedGraph === button && classes.selectedButton
                )}
                onClick={(button) => handleGraphChange(button)}>
                {button}
              </Button>
            );
          })}
        </Box>
      </Box>
      <Divider />
      <Box className={classes.barGraph} ref={ref}>
        <svg
          className={classes.svgBarGraph}
          width={dimensions.width}
          ref={graphRef}
          height={dimensions.height}
        />
      </Box>
      <Divider />
      <Footer
        footer={footer}
        totalOfStudents={totalUsers}
        colorMapping={colorMapping}
      />
    </Box>
  );
};

StackedGraph.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      week: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      // Add more specific prop types for other properties in the data array
    })
  ),
  width: PropTypes.string,
  height: PropTypes.string,
  graphButtons: PropTypes.objectOf(PropTypes.string),
  footer: PropTypes.object,
  title: PropTypes.string,
  totalUsers: PropTypes.number,
  dataPlotType: PropTypes.string
};
