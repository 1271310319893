import React, { useEffect, useRef, useState, useCallback } from "react";
import PropTypes from "prop-types";

import { Box, Divider, Button } from "@mui/material";
import * as d3 from "d3";
import { makeStyles } from "@mui/styles";
import { useChartDimensions, useQuery } from "../../../../../hooks";
import clsx from "clsx";
import { formatDuration } from "../utils";
import { Footer } from "./BarGraphFooter";
import GraphTitle from "./BarGraphTitle";
import { colorMapping, CHARTS_VIEW_TYPE } from "../../consts";
import { setHoveredBarLabel } from "../../../../Tasks/Stats/utils";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  barGraphContainer: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "15px",
    padding: theme.spacing(2),
    height: "100%"
  },
  barGraph: {
    height: "300px"
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(1)
  },
  graphMetricsButton: {
    borderRadius: "20px",
    marginInlineStart: theme.spacing(1),
    height: "80%",
    color: theme.palette.text.primary
  },
  selectedButton: {
    backgroundColor: "rgba(21, 101, 192, 0.3)",
    color: theme.palette.text.primary
  }
}));

export const GraphChart = ({
  data,
  height,
  graphButtons,
  footer,
  title,
  totalUsers,
  dataPlotType
}) => {
  const classes = useStyles();
  const graphRef = useRef();
  const { ref, dimensions } = useChartDimensions({});
  const [selectedGraph, setSelectedGraph] = useState("Total");
  const { course_id } = useQuery();
  const history = useHistory();
  const plotTypeWeek = dataPlotType === CHARTS_VIEW_TYPE.WEEK;
  const handleGraphChange = (event) => {
    setSelectedGraph(event.target.innerText);
  };
  const setLabelByGraph = useCallback(
    (d) => {
      if (selectedGraph === "Unique") {
        return `${d[selectedGraph]}%`;
      }
      if (title === "Activity" && plotTypeWeek) {
        return `${d[selectedGraph].toFixed(0)}`;
      } else if (
        ["Reading time", "Writing time", "Academic coach time"].includes(
          title
        ) ||
        (title === "Activity" && !plotTypeWeek)
      ) {
        return formatDuration(d[selectedGraph]);
      }
      return Math.floor(d[selectedGraph]);
    },
    [title, selectedGraph, plotTypeWeek]
  );

  const getBarColor = useCallback(
    (d) =>
      selectedGraph === "Unique" || d.weekWithTask ? "#003C8F" : "#E0E0E0",
    [selectedGraph]
  );

  const createBarAttributesAndScale = useCallback(
    (d, xScale) => {
      let yScale;
      let barAttributes;
      let labelYPosition;
      switch (selectedGraph) {
        case "Unique":
          yScale = d3
            .scaleLinear()
            .domain([0, 100])
            .range([dimensions.boundedHeight, 0]);

          labelYPosition = (d) =>
            title === "Activity"
              ? yScale(105)
              : yScale(Number(d[selectedGraph])) - 20;

          barAttributes = {
            x: (d) => xScale(d.plotData) + (xScale.bandwidth() - 9) / 2,
            y: (d) => yScale(d[selectedGraph]) - 5,
            width: 9,
            height: (d) => dimensions.boundedHeight - yScale(d[selectedGraph]),
            fill: (d) => getBarColor(d),
            rx: 14,
            ry: 6
          };

          break;

        case "Total":
          yScale = d3
            .scaleLinear()
            .domain([0, d3.max(data, (d) => d[selectedGraph])])
            .range([dimensions.boundedHeight, 0]);

          labelYPosition = (d) => yScale(Number(d[selectedGraph])) - 20;

          barAttributes = {
            x: (d) => xScale(d.plotData) + (xScale.bandwidth() - 9) / 2,
            y: (d) => yScale(d[selectedGraph]) - 5,
            width: 9,
            height: (d) => dimensions.boundedHeight - yScale(d[selectedGraph]),
            fill: (d) => getBarColor(d),
            rx: 14,
            ry: 6
          };
          break;

        case "Average":
          yScale = d3
            .scaleLinear()
            .domain([0, d3.max(data, (d) => d[selectedGraph])])
            .range([dimensions.boundedHeight, 0]);

          labelYPosition = (d) => yScale(d[selectedGraph] || 0) - 20; // Handle potential undefined values

          barAttributes = {
            x: (d) => xScale(d.plotData) + (xScale.bandwidth() - 9) / 2,
            y: (d) => yScale(d[selectedGraph]) - 5,
            width: 9,
            height: (d) => dimensions.boundedHeight - yScale(d[selectedGraph]),
            fill: (d) => getBarColor(d),
            rx: 14,
            ry: 6
          };

          break;

        default:
          // Default yScale and attributes if selectedGraph does not match any case
          yScale = d3
            .scaleLinear()
            .domain([0, 100])
            .range([dimensions.boundedHeight, 0]);

          labelYPosition = (d) => yScale(Number(d[selectedGraph])) - 20;

          barAttributes = {
            x: (d) => xScale(d.plotData) + (xScale.bandwidth() - 9) / 2,
            y: (d) => yScale(d[selectedGraph]) - 5,
            width: 9,
            height: (d) => dimensions.boundedHeight - yScale(d[selectedGraph]),
            fill: (d) => getBarColor(d),
            rx: 10,
            ry: 6
          };
      }
      return { barAttributes, yScale, labelYPosition };
    },
    [selectedGraph, data, dimensions, title, getBarColor]
  );

  const setupChart = useCallback(() => {
    const xScale = d3
      .scaleBand()
      .domain(data.map((d) => d.week))
      .range([0, dimensions.boundedWidth]);

    const svg = d3
      .select(graphRef.current)
      .attr("width", dimensions.width)
      .attr("height", dimensions.height)
      .selectAll("g")
      .data([null]); // Single group data join
    const chart = svg
      .enter()
      .append("g") // Only append if 'g' doesn't exist
      .merge(svg)
      .attr("width", dimensions.width)
      .attr("height", dimensions.height)
      .attr("transform", `translate(45, ${dimensions.marginTop})`);

    const tranformedData = data.map((d) => ({
      plotData: d.week,
      [selectedGraph]: d[selectedGraph],
      weekWithTask: plotTypeWeek ? d.weekWithTask : true,
      totalUsers: d.uniqueUsers,
      task_id: d.task_id
    }));
    const { yScale, labelYPosition } = createBarAttributesAndScale(
      tranformedData,
      xScale
    );

    // Remove existing bars before drawing new ones
    chart.selectAll(".bar").remove();
    chart.selectAll(".bar-label").remove();

    // Draw bars
    chart
      .selectAll(".bar")
      .data(tranformedData)
      .join("rect")
      .attr("class", "bar")
      .each(function (tranformedData) {
        const { barAttributes } = createBarAttributesAndScale(
          tranformedData,
          xScale
        );
        d3.select(this)
          .attr("x", barAttributes.x)
          .attr("y", barAttributes.y)
          .attr("width", barAttributes.width)
          .attr("height", barAttributes.height)
          .attr("fill", barAttributes.fill)
          .attr("rx", barAttributes.rx)
          .attr("ry", barAttributes.ry)
          .on("mouseover", (event, d) => {
            const { x, y, displayText } = setHoveredBarLabel(
              event,
              d,
              barAttributes,
              selectedGraph,
              totalUsers,
              d.totalUsers,
              "barGraph"
            );
            const textHeight = 12; // Approximate text height
            const borderRadius = 5; // Set the border radius
            const paddingX = 4; // Adjust padding for x-axis as needed
            const paddingY = 6; // Adjust padding for y-axis as needed

            // Calculate text width for background sizing
            const textWidth = displayText.length * 6.5 + paddingX * 2;

            // Get mouse position relative to the SVG container
            const svgRect = chart.node().getBoundingClientRect();
            const relativeX = event.offsetX - 100;
            const relativeY = event.clientY - svgRect.top - 40;
            // Append rect for background
            chart
              .append("rect")
              .attr("class", "text-background")
              .attr("x", relativeX + paddingX) // Position tooltip based on mouse X
              .attr("y", relativeY - paddingY * 2) // Position tooltip based on mouse Y
              .attr("width", textWidth)
              .style("text-anchor", "middle")
              .attr("height", textHeight + paddingY)
              .style("fill", "rgba(97, 97, 97, 0.9)")
              .style("ry", borderRadius)
              .style("rx", borderRadius);

            // Append text
            chart
              .append("text")
              .attr("class", "bar-value")
              .attr("x", relativeX + paddingX * 3)
              .attr("y", relativeY) // Adjust text position for vertical alignment
              .style("text-anchor", "start")
              .style("fill", "white")
              .style("font-size", "12px")
              .text(displayText);
          })
          .on("mouseout", () => {
            chart.selectAll(".bar-value").remove();
            chart.selectAll(".text-background").remove();
          });
      })
      .attr("display", (d) => {
        return d[selectedGraph] > 0 ? "block" : "none";
      });
    chart.selectAll(".grey-bar").remove();
    // Draw grey bars
    if (selectedGraph === "Unique") {
      chart
        .selectAll(".grey-bar")
        .data(data)
        .join("rect")
        .attr("class", "grey-bar")
        .attr("x", (d) => xScale(d.week) + (xScale.bandwidth() - 9) / 2)
        .attr("y", -5)
        .attr("width", 9)
        .attr("height", yScale(0))
        .attr("fill", "rgba(0, 0, 0, 0.12)")
        .attr("rx", 10)
        .attr("ry", 10);
    }
    // Draw x-axis
    // Assuming 'data' is your array of data points
    const xAxisGroup = chart.selectAll(".x-axis").data([null]);
    xAxisGroup
      .enter()
      .append("g")
      .attr("class", "x-axis")
      .merge(xAxisGroup)
      .attr("transform", `translate(0, ${dimensions.boundedHeight})`) // Position the x-axis at the bottom
      .call(d3.axisBottom(xScale).tickSize(0))
      .call((g) => g.select(".domain").remove()) // Remove the axis line
      .selectAll("text")
      .on("click", (d) => {
        if (plotTypeWeek) return;

        const task_id = d.target.textContent.slice(1);
        history.push(`/tasks?course_id=${course_id}&task_id=${task_id}`);
      })
      .attr("transform", plotTypeWeek ? "rotate(0)" : "rotate(45)")
      .style("text-anchor", plotTypeWeek ? "middle" : "start");

    // Draw labels
    chart
      .selectAll(".bar-label")
      .data(tranformedData)
      .join("text")
      .attr("class", "bar-label")
      .attr("x", (d) => xScale(d.plotData) + xScale.bandwidth() / 2)
      .attr("y", (d) => labelYPosition(d))
      .style("text-anchor", "middle")
      .style("fill", "black")
      .style("font-size", "12px")
      .text((d) => setLabelByGraph(d)); // Set the text of the label
  }, [
    data,
    dimensions,
    selectedGraph,
    plotTypeWeek,
    totalUsers,
    setLabelByGraph,
    createBarAttributesAndScale,
    course_id,
    history
  ]);

  useEffect(() => {
    if (dimensions.boundedWidth > 0 && dimensions.boundedHeight > 0) {
      setupChart();
    }
  }, [setupChart, dimensions, plotTypeWeek]);

  return (
    <Box className={classes.barGraphContainer} height={height * 1.4}>
      <Box className={classes.header}>
        <GraphTitle text={title} />
        <Box>
          {Object.values(graphButtons).map((button, i) => (
            <Button
              variant="outlined"
              color="primary"
              key={i}
              className={clsx(
                classes.graphMetricsButton,
                selectedGraph === button && classes.selectedButton
              )}
              onClick={(button) => handleGraphChange(button)}>
              {button}
            </Button>
          ))}
        </Box>
      </Box>
      <Divider />
      <Box className={classes.barGraph} ref={ref}>
        <svg
          className={classes.svgBarGraph}
          width={dimensions.width}
          ref={graphRef}
          height={dimensions.height}
        />
      </Box>
      <Divider />
      <Footer
        footerText={footer}
        totalOfStudents={totalUsers}
        width={dimensions.width}
        colorMapping={colorMapping}
        selectedGraph={selectedGraph}
        plotTypeWeek={plotTypeWeek}
        data={data}
        title={title}
      />
    </Box>
  );
};
GraphChart.propTypes = {
  data: PropTypes.array.isRequired,
  graphButtons: PropTypes.object.isRequired,
  height: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  footer: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  totalUsers: PropTypes.number.isRequired,
  dataPlotType: PropTypes.string
};
