import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";

// Styles
const useStyles = makeStyles((theme) => {
  return {
    sidebar: {
      backgroundColor: "#f5f5f5",
      display: "flex",
      flexShrink: 0,
      // transition: ".3s ease-out",
      zIndex: 1
    },
    expanded: {
      height: "100%",
      width: 240
    },
    collapsed: {
      width: 0
    }
  };
});

// This is a component that abstract the styling of the sidebar so that it could be controlled in a single locaiton

// Requierd props: children,
// Optional props: className, collapsed (defaults to false)
function SecondarySidebarLayout({ children, className, collapsed }) {
  //Hooks
  const classes = useStyles();
  //Global state

  // Derived State

  return (
    <Box
      id="secondary-sidebar"
      className={clsx(
        classes.sidebar,
        collapsed ? classes.collapsed : classes.expanded,
        className
      )}>
      {children}
    </Box>
  );
}

SecondarySidebarLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
  className: PropTypes.string,
  collapsed: PropTypes.bool
};

export default SecondarySidebarLayout;
