import React from "react";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import { standardTaskAPI } from "../../../../api";
import { useQuery } from "../../../../hooks";
import {
  selectAnswerComment,
  selectCurrentQuestion,
  updateInteraction
} from "../../../../redux/interactionsSlice";
import { selectSubmission } from "../../../../redux/tasksSlice";
import { selectCurrentText } from "../../../../redux/textsSlice";
import { isEmpty } from "lodash";
import {
  Typography,
  Box,
  Paper,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider
} from "@mui/material";
import { INTERACTION_SUBTYPES, INTERACTION_TYPES } from "../../../../consts";

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(12,1fr)"
  },
  paper: {
    gridColumn: " 2 / 11 ",
    marginLeft: "56px",

    height: "max-content",
    position: "relative"
  },
  commentContainer: {
    padding: "16px",
    fontSize: "20px"
  },
  container: {
    paddingTop: theme.spacing(8),
    zIndex: 1,
    flexGrow: 1
  },
  label: { padding: "10px 0" },
  radio: {
    "&.Mui-checked": {
      color: "#168fee"
    }
  },
  answerHeader: {
    fontSize: "0.75rem",
    marginBottom: "20px"
  }
}));

export default function MultiChoice() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { submission_id } = useQuery();
  const submission = useSelector((state) =>
    selectSubmission(state, Number(submission_id))
  );
  const currentQuestionId = useSelector(
    (state) => state.interactions.selectedInteractionId
  );
  const answer = useSelector((state) =>
    selectAnswerComment(state, currentQuestionId)
  );
  const text = useSelector(selectCurrentText);

  const question = useSelector(selectCurrentQuestion);

  function createAnswer(value) {
    const content = question.options[value];
    standardTaskAPI.createAnswerSelection({
      content,
      choice: value,
      interaction_id: currentQuestionId,
      text_id: text.id,
      course_id: text.course_id,
      submission_id: submission.id,
      task_id: submission.task_id,
      interaction_type: INTERACTION_TYPES.ANSWER,
      interaction_subtype: INTERACTION_SUBTYPES.MULTI_CHOICE
    });
  }

  function updateAnswer(question, answer, value) {
    const content = question.options[value];
    dispatch(
      updateInteraction({
        interaction: answer,
        update: {
          choice: value,
          content
        }
      })
    );
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.paperContainer}>
        <Paper className={classes.paper}>
          <Box className={classes.commentContainer}>
            <Typography className={classes.answerHeader}>
              <FormattedMessage
                id="task.answer.multiple.instructions"
                defaultMessage="Choose the correct answer"
              />
            </Typography>
            <RadioGroup
              name="answer"
              value={answer.choice}
              onChange={(e) =>
                isEmpty(answer)
                  ? createAnswer(e.target.value)
                  : updateAnswer(question, answer, e.target.value)
              }>
              {question.options.map((option, index) => {
                return (
                  <React.Fragment key={index}>
                    <FormControlLabel
                      className={classes.label}
                      value={index}
                      control={
                        <Radio
                          className={clsx(classes.radio)}
                          checked={index === parseInt(answer.choice)}
                          disabled={submission.status === "Submitted"}
                        />
                      }
                      label={option}
                    />
                    <Divider />
                  </React.Fragment>
                );
              })}
            </RadioGroup>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}
