import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { createRoot } from "react-dom/client";

const useCreatePortal = (el) => {
  const rootRef = useRef(null);
  const [portal, setPortal] = useState({
    render: () => null,
    remove: () => null
  });

  const createPortalInstance = React.useCallback((el) => {
    const Portal = ({ children }) => el && createPortal(children, el);
    const remove = () => {
      if (el && rootRef.current) {
        rootRef.current.unmount();
        rootRef.current = null;
      }
    };
    return { render: Portal, remove };
  }, []);

  useEffect(() => {
    if (el) {
      const newPortal = createPortalInstance(el);
      setPortal(newPortal);
      rootRef.current = createRoot(el);
      return () => {
        if (newPortal && el) newPortal.remove(el);
      };
    }
  }, [el, createPortalInstance]);

  return portal.render;
};

export default useCreatePortal;
