// Dependencies
import React from "react";
import { QuestionBotSettings } from "./QuestionBotSettings";

// Styles
import {
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexFlow: "row nowrap",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  headerTitle: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "baseline"
  },
  title: {
    marginInline: theme.spacing(2),
    alignSelf: "center"
  },
  button: {
    marginInlineEnd: theme.spacing(2),
    color: theme.palette.text.primary
  },
  selectType: {
    height: "50%",
    margin: 0,
    borderRadius: "15px",
    "& div": {
      padding: theme.spacing(1),
      margin: 0,
      overflow: "visible !important"
    }
  }
}));

const Header = ({
  selectedAssistant,
  setSelectedAssistant,
  runQuery,
  setRunQuery,
  assistantInstructions,
  ...props
}) => {
  const classes = useStyles();

  function handleSelectedAssistantChange(event) {
    setSelectedAssistant(event.target.value);
  }

  const renderSettingsSelection = () => {
    if (selectedAssistant === "mentor") {
      return <QuestionBotSettings {...props} />;
    }
    return <></>;
  };

  return (
    <Box className={classes.header}>
      <Box className={classes.headerTitle}>
        <Typography className={classes.title} variant="h5">
          Playground
        </Typography>
        <FormControl
          variant="filled"
          sx={{
            width: 120,
            display: "flex",
            paddingTop: "25px",
            height: 70,
            borderRadius: "50%",
            alignSelf: "flex-end"
          }}>
          <Select
            value={selectedAssistant}
            onChange={handleSelectedAssistantChange}
            className={classes.selectType}
            autoWidth
            variant="standard"
            disableUnderline>
            <MenuItem value="mentor">Mentor</MenuItem>
            {/* <MenuItem value="chat">Chat</MenuItem>
            <MenuItem value="complete">Complete</MenuItem> */}
          </Select>
        </FormControl>
      </Box>
      {renderSettingsSelection()}
      <Box>
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          disabled={!assistantInstructions || !assistantInstructions.length}
          className={classes.button}
          onClick={() => {
            setRunQuery(!runQuery);
          }}>
          Run Query
        </Button>
      </Box>
    </Box>
  );
};

export default Header;
