// dependancies
import { reFetchSubmission } from "../../tasksSlice";
import { httpCallables } from "../../../firebase";

// Redux
import { setInteractions } from "../../interactionsSlice";

// Rx
import { ofType } from "redux-observable";
import { EMPTY } from "rxjs";
import { map, switchMap, catchError } from "rxjs/operators";
import { captureException } from "../../../utils/errorHandlers";

const TASK_CALLBACK = httpCallables.taskFunctions;
const TASK_ACTION = "readSubmission";

const GR_CALLBACK = httpCallables.interactionFunctions;
const GR_ACTION = "readUserInteractionsPerText";

const ERROR_MSG = `FETCH_SUBMISSION`;

export const fetchSubmissionEpic = (action$, state$, { fetchAsObservable }) => {
  return action$.pipe(
    ofType(reFetchSubmission.type),
    map(({ payload }) => ({ submission_id: Number(payload), ...payload })),
    switchMap((args) => {
      if (args.submission_id) {
        // should reload submission
        return fetchAsObservable(
          TASK_CALLBACK,
          TASK_ACTION,
          ERROR_MSG,
          args
        ).pipe(
          map(({ interactions }) => ({
            type: setInteractions.type,
            payload: interactions
          }))
        );
      } else {
        // should reload GR
        return fetchAsObservable(GR_CALLBACK, GR_ACTION, ERROR_MSG, args).pipe(
          map(({ interactions }) => ({
            type: setInteractions.type,
            payload: interactions
          }))
        );
      }
    }),
    catchError((error) => {
      captureException(error, "Error in fetchSubmissionEpic");
      return EMPTY;
    })
  );
};
