export const CHARTS_VIEW_TYPE = {
  WEEK: "week",
  TASK: "task",
  INSTITUTION: "institution"
};
export const USAGE_PIE_CHART = {
  ACADEMIC_COACH: {
    title: "Academic Coach usage",
    footer: "$DATA of $DATA2 students"
  },
  GUIDED_READING: {
    title: "Guided Reading usage",
    footer: "$DATA of $DATA2 students"
  }
};

export const CHART_NAMES = {
  ACADEMIC_COACH: "ACADEMIC_COACH",
  GUIDED_READING: "GUIDED_READING",
  LOGINS: "LOGINS",
  // READING_TIME: "READING_TIME",
  // WRITING_TIME: "WRITING_TIME",
  ACADEMIC_COACH_TIME: "ACADEMIC_COACH_TIME",
  COMMENTS_CREATED: "COMMENTS_CREATED",
  QUESTION_CREATED: "QUESTION_CREATED",
  HIGHLIGHTS_CREATED: "HIGHLIGHTS_CREATED",
  PUBLISHED_TASKS: "PUBLISHED_TASKS",
  SUBMISSIONS_STATUS: "SUBMISSIONS_STATUS"
  // ACTIVITY: "ACTIVITY"
};

export const USGAE_BAR_GRAPH = {
  ROUNDED: {
    // ACTIVITY: {
    //   TITLE: "Activity",
    //   BUTTONS: {
    //     TOTAL: "Total",
    //     AVERAGE: "Average"
    //   },
    //   FOOTER: {
    //     WITH_TASKS: "Week with tasks",
    //     WITHOUT_TASKS: "Week without tasks"
    //   }
    // },
    // READING_TIME: {
    //   TITLE: "Reading time",
    //   BUTTONS: {
    //     TOTAL: "Total",
    //     AVERAGE: "Average"
    //   },
    //   FOOTER: {
    //     WITH_TASKS: "Week with tasks",
    //     WITHOUT_TASKS: "Week without tasks"
    //   }
    // },
    // WRITING_TIME: {
    //   TITLE: "Writing time",
    //   BUTTONS: {
    //     TOTAL: "Total",
    //     AVERAGE: "Average"
    //   },
    //   FOOTER: {
    //     WITH_TASKS: "Week with tasks",
    //     WITHOUT_TASKS: "Week without tasks"
    //   }
    // },
    ACADEMIC_COACH_TIME: {
      TITLE: "Academic coach time",
      BUTTONS: {
        TOTAL: "Total",
        AVERAGE: "Average"
      },
      FOOTER: {
        WITH_TASKS: "Week with tasks",
        WITHOUT_TASKS: "Week without tasks"
      }
    },
    COMMENTS_CREATED: {
      TITLE: "Comments created",
      BUTTONS: {
        UNIQUE: "Unique",
        TOTAL: "Total"
      },
      FOOTER: {
        WITH_TASKS: "Week with tasks",
        WITHOUT_TASKS: "Week without tasks"
      }
    }
  },
  STACKED: {
    QUESTION_CREATED: {
      TITLE: "Questions created",
      BUTTONS: {
        TOTAL: "Total",
        AVERAGE: "Average"
      },
      FOOTER: {
        TASK_RELATED: "Task related",
        GR_RELATED: "GR related"
      }
    },
    HIGHLIGHTS_CREATED: {
      TITLE: "Highlights created",
      BUTTONS: {
        TOTAL: "Total",
        AVERAGE: "Average"
      },
      FOOTER: {
        TASK_RELATED: "Task related",
        GR_RELATED: "GR related"
      }
    },

    PUBLISHED_TASKS: {
      TITLE: "Published tasks",
      BUTTONS: {
        PERCENTAGE: "Percentage",
        NUMBERS: "Numbers"
      },
      FOOTER: {
        PENDING: "Pending",
        SUBMITTED: "Submitted",
        LATE: "Late",
        MISSED: "Missed"
      }
    }
  }
};

export const colorMapping = {
  gr: "#4CAF50",
  task: "#003C8F",
  missed: "#D32F2F",
  late: "#ED6C02",
  submitted: "#2E7D32",
  pending: "#E0E0E0",
  "Week with tasks": "#003C8F",
  "Week without tasks": "#E0E0E0"
};

export const SUBMISSION_PIE_CHART_COLOR_MAPPING = {
  SubmissionMissed_complete: "#D32F2F",
  SubmissionMissed_incomplete: "#BE134D",
  SubmissionMissed_no_activity: "#EF9A9A",

  SubmissionLate_complete: "#ED6C02",
  SubmissionLate_incomplete: "#FFA726",
  SubmissionLate_no_activity: "#FFCC80",

  SubmissionOnTime_complete: "#2E7D32",
  SubmissionOnTime_incomplete: "#4CAF50",
  SubmissionOnTime_no_activity: "#A5D6A7",

  SubmissionPending_complete: "#616161",
  SubmissionPending_incomplete: "#9E9E9E",
  SubmissionPending_no_activity: "#E0E0E0"
};
