import React from "react";

// Redux Dependencies
import { useSelector, useDispatch } from "react-redux";
import { updateInteraction } from "../../redux/interactionsSlice";
import {
  selectSelectedHighlight,
  toggleAnnotatorBar
} from "../../redux/highlightSlice";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
const useStyles = makeStyles((theme) => ({
  colorBtn: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    cursor: "pointer",
    marginInline: theme.spacing(1),
    borderRadius: 8
  },

  highlightBtn: {
    "& :hover": {
      color: "#BEF3BF"
    }
  }
}));

function HighlightColor({ color }) {
  //Hooks
  const classes = useStyles();
  const dispatch = useDispatch();

  // Redux State

  const selectedHighlight = useSelector((state) =>
    selectSelectedHighlight(state)
  );
  //Behavior

  function updateColor(highlight, color) {
    dispatch(
      updateInteraction({
        interaction: highlight,
        update: { color: color }
      })
    );
    dispatch(toggleAnnotatorBar());
  }

  //Render
  return (
    <Box
      onClick={() => updateColor(selectedHighlight, color)}
      className={classes.colorBtn}
      style={{ backgroundColor: color }}></Box>
  );
}

export default HighlightColor;
