// Dependencies
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { uniq } from "lodash";
import TYPES from "../../types";
import { useQuery, useStepStage } from "../../hooks";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { grAPI, interactionsAPI, standardTaskAPI } from "../../api";
import {
  setSelectedTempHighlight,
  selectQuestionHighlights,
  updateInteraction,
  setSelectedInteractionId
} from "../../redux/interactionsSlice";
import { selectSubmission, selectTask } from "../../redux/tasksSlice";
import { mentorFabClicked } from "../../redux/fabSlice";
import { closeChatbox, fetchConversation } from "../../redux/chatSlice";
import store from "../../redux/store";
// Components
import QuestionHeader from "./QuestionHeader";
import QuestionCard from "./QuestionCard";

import { Box, List, ListItem, Collapse } from "@mui/material";
import {
  TASK,
  CHAT,
  USER_TYPE,
  FAB_STYLE_VIEW,
  QUESTION_INTL
} from "../../consts";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

export default function Question({
  selectedQuestionId,
  question,
  onCardDrop = () => {},
  disableActions = false,
  forceQuestionSelection = false,
  showInText,
  index,
  onSortQuestions,
  onDelete = () => {},
  newQuestionMode,
  expandedItems,
  setExpandedItems
}) {
  const intl = useIntl();
  const { submission_id } = useQuery();
  const dispatch = useDispatch();

  const submission = useSelector((state) =>
    selectSubmission(state, Number(submission_id))
  );
  const task = useSelector((state) => selectTask(state, submission.task_id));

  const selectedInteractionId = useSelector(
    (state) => state.interactions.selectedInteractionId
  );
  const [step] = useStepStage();
  // to reconcile diffs, we need ot verify the chat_submission is the one we are looking for
  const chat_submission = useSelector((state) => state.chat.submission_id);
  const chatSessionId = useSelector((state) => state.chat.sessionId);
  const highlights = useSelector((state) =>
    selectQuestionHighlights(state, question.id)
  );
  const chatInteractions = useSelector((state) => state.chat.interactions);
  const fabViewStyle = useSelector((state) => state.fab.fabViewStyle);
  const selectedTempHighlightId = useSelector(
    (state) => state.interactions.selectedTempHighlight
  );
  const user_uid = useSelector((state) => state.user.auth.uid);

  // Ephemeral State
  const [selectQuestionMode, setSelectQuestionMode] =
    useState("showHighlights");

  //Derived state
  const questionType = question.interaction_subtype;
  // no task_id = reader, task_id AND submission_id = question created in a student context
  const isUserGrQuestion =
    !question.task_id || (question.task_id && question.submission_id);
  const isGr = question.question_context === TASK.TYPE.GUIDED_READING;
  const isTask = !!submission_id;
  const hasConversation = chatInteractions.find(
    (interaction) => interaction.interaction_id === selectedQuestionId
  );
  const hasHighlights = !!highlights.length;
  const fabIsMinimized = fabViewStyle === FAB_STYLE_VIEW.MINIMIZED;
  const canOpenChat = () => {
    const statusCond =
      isGr ||
      submission.status === TASK.SUBMISSION_STATUS.PENDING ||
      submission.status === TASK.SUBMISSION_STATUS.ACTIVE;
    const questionTypeCond =
      isGr || questionType === TASK.QUESTION_TYPES.OPEN_ENDED;
    const taskCond = statusCond && chat_submission === submission.id;
    return (
      step === TASK.STEPS.REVIEW &&
      questionTypeCond &&
      hasHighlights &&
      hasConversation &&
      !fabIsMinimized &&
      (taskCond || !isTask)
    );
  };
  const locked = submission?.status === "Submitted";
  const editable = isUserGrQuestion && !locked;

  //Behavior
  useEffect(() => {
    // Open coach by default when switching to review , when open ended question and we have highlights
    if (question.id === selectedInteractionId && canOpenChat()) {
      dispatch(mentorFabClicked({ chatType: CHAT.TYPE.REVIEW }));
    }
    if (question.id === selectedInteractionId && !canOpenChat()) {
      dispatch(closeChatbox());
    }
  }, [selectedInteractionId, step, questionType, chat_submission]);

  useEffect(() => {
    if (selectedTempHighlightId) {
      setSelectQuestionMode("adaptHighlight");
    }
  }, [selectedTempHighlightId]);

  function getSubTitle(question) {
    let subtitle = "";
    if (question.question_context === TASK.TYPE.GUIDED_READING) {
      subtitle +=
        intl.formatMessage({
          id: "gr.instructorQuestion",
          defaultMessage: "Instructor question"
        }) + ": ";
    }
    subtitle += intl.formatMessage({
      id: QUESTION_INTL[question.interaction_subtype].translationId,
      defaultMessage: QUESTION_INTL[question.interaction_subtype].defaultMessage
    });
    return subtitle;
  }

  const deleteQuestion = () => {
    interactionsAPI.deleteQuestion(question, highlights).then((success) => {
      if (success && question.id === selectedInteractionId) {
        store.dispatch(setSelectedInteractionId(null));
      }
    });
  };

  const deleteHighlight = (highlight) => {
    interactionsAPI.deleteHighlight(highlight);
  };

  const handleQuestionSelection = (questionId) => {
    if (!questionId) return;

    if (selectQuestionMode === "adaptHighlight") {
      interactionsAPI.createHighlightFromTemp(
        selectedTempHighlightId,
        questionId
      );
      setSelectQuestionMode("showHighlights");
      dispatch(setSelectedTempHighlight(null));
    }
    isTask
      ? standardTaskAPI.updateSelectedQuestionId(questionId, submission.task_id)
      : grAPI.updateSelectedQuestionId(questionId);
  };

  const updateQuestion = (newTitle) => {
    if (newTitle) {
      dispatch(
        updateInteraction({
          interaction: question,
          update: { content: newTitle }
        })
      );
    }
  };

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: `sort-student-questions-${question.id}`,
      data: {
        questionId: question.id,
        type: "SortStudentQuestions",
        onSortQuestions: onSortQuestions
      }
    });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };
  const sortProps =
    editable && expandedItems.length === 0
      ? { ref: setNodeRef, style: style, ...attributes, ...listeners }
      : {};

  return (
    <Box key={question.id} data-test-class="question" {...sortProps}>
      <QuestionHeader
        forceQuestionSelection={forceQuestionSelection}
        isSelected={question.id === selectedInteractionId && !newQuestionMode}
        qId={question.id}
        internalQId={question.internal_id}
        isGr={isGr}
        isUserGrQuestion={isUserGrQuestion}
        onClick={handleQuestionSelection}
        onExpand={(exapnded) => {
          if (exapnded) {
            setExpandedItems(uniq(expandedItems.concat([question.id])));
          } else {
            setExpandedItems(
              expandedItems.filter((itm) => itm !== question.id)
            );
          }
        }}
        title={question.content}
        subTitle={getSubTitle(question)}
        onCardDrop={onCardDrop}
        disableDrop={disableActions}
        onSortQuestions={onSortQuestions}
        onDelete={deleteQuestion}
        editable={editable}
        updateQuestion={updateQuestion}
      />

      <Collapse in={expandedItems.includes(question.id)}>
        <List data-no-dnd="true">
          {highlights.map((highlight, index) => (
            <ListItem key={highlight.id}>
              <QuestionCard
                showInText={showInText}
                card={highlight}
                qId={question.id}
                onDelete={(highlight) => deleteHighlight(highlight)}
                disableActions={disableActions}
                cardIndex={index}
                onCardDrop={onCardDrop}
              />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </Box>
  );
}

Question.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    content: PropTypes.string,
    interaction_subtype: TYPES.INTERACTION_SUBTYPE,
    includeCitation: PropTypes.bool
  }),
  getSubTitle: PropTypes.func,
  onCardDrop: PropTypes.func,
  disableActions: PropTypes.bool,
  forceQuestionSelection: PropTypes.bool,
  showInText: PropTypes.oneOf(["inline", "dialog"]).isRequired,
  index: PropTypes.number,
  onSortQuestions: PropTypes.func,
  onDelete: PropTypes.func,
  newQuestionMode: PropTypes.bool,
  expandedItems: PropTypes.array,
  setExpandedItems: PropTypes.func
};
