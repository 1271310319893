import { useFirestore, useFirestoreDocData } from "reactfire";
import { doc } from "firebase/firestore";
import { useSelector } from "react-redux";
import { FEATURES, USER_PROFILE } from "../consts";

export const useHighlightColor = (questionId, onlyQuestionColor = false) => {
  const firestore = useFirestore();

  const userId = useSelector((state) => state.firebase.auth.uid);
  const systemColor = useSelector((state) =>
    onlyQuestionColor
      ? undefined
      : state.readerActions.persistentActionState.highlightColor
  );

  const interactionRef = doc(
    firestore,
    `${USER_PROFILE.CUSTOM_CONFIG_PATH}/${userId}/${FEATURES.INTERACTIONS}/${questionId}/color`
  );
  const { data: interactionRefData } = useFirestoreDocData(interactionRef);
  const questionColor = interactionRefData?.color;

  return questionColor ?? systemColor;
};
