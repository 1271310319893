/* eslint react/prop-types: 0 */
// Dependencies
import React, { useEffect, useRef, useState } from "react";
import { useGetTheme } from "../../../../hooks";

// Redux
import { CONVERSATION_TYPES } from "../../../../redux/chatSlice";

// Components
import ChatBubble from "../../../chat/ChatBubble";
import { ScrollBox } from "../../../SharedComponents";
import BouncingDots from "../../../SharedComponents/BouncingDots";

// Material UI
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  TextEditor,
  TextEditorButton,
  TextEditorFooter,
  TextEditorInput
} from "../../../SharedComponents/textEditor";

// Styles
const useStyles = makeStyles((theme) => {
  return {
    container: {
      height: "100%",
      width: "100%",
      display: "flex",
      flexFlow: "column",
      justifyContent: "space-between"
    },
    footer: {
      display: "flex",
      maxHeight: 332,
      height: "auto",
      padding: theme.spacing(2),
      marginBlockEnd: theme.spacing(2),
      marginInlineEnd: theme.spacing(2),
      border: `1px solid ${theme.palette.grey.main}`,
      borderRadius: theme.spacing(2)
    },
    footerActions: {
      paddingInline: 0,
      display: "flex",
      flexDirection: "row-reverse",
      justifyContent: "space-between",
      minHeight: 52
    },
    editor: {},
    input: {},
    sendButton: {
      color: theme.palette.primary.light
    }
  };
});
const NewConversion = ({
  showLogs,
  runQuery,
  setRunQuery,
  conversation,
  setConversation
}) => {
  const classes = useStyles();
  const theme = useGetTheme();
  const chatBoxRef = useRef(null);
  const chatContentRef = useRef(null);

  // Redux Selectors

  // Ephemeral State
  const [activeChat, setActiveChat] = useState(false);

  // Derived State
  useEffect(() => {
    if (!chatContentRef.current) return;

    setTimeout(() => {
      scrollChatToBottom();
    }, 100);
  }, [conversation]);

  function scrollChatToBottom() {
    const targetNode = chatContentRef.current;
    if (
      targetNode &&
      targetNode.scrollTop + targetNode.clientHeight !== targetNode.scrollHeight
    ) {
      targetNode.scroll({
        top: targetNode.scrollHeight,
        behavior: "smooth"
      });
    }
  }

  function PendingBubble() {
    return (
      <ChatBubble
        variation={CONVERSATION_TYPES.INCOMING}
        content={
          <BouncingDots
            size="large"
            color={theme.palette.primary.contrastText}
          />
        }></ChatBubble>
    );
  }

  function handleIncomingMessage(message) {
    const incomingMessage = {
      content: message,
      type: CONVERSATION_TYPES.OUTGOING,
      created_at: new Date(),
      role: "user"
    };
    setConversation([incomingMessage]);
    setRunQuery(!runQuery);
  }

  const renderMessage = (message, key) => {
    let paragraphs = message.type === "ANSWER" ? [] : [message];
    let botAnswer = null;

    if (message.answer) {
      botAnswer = message.answer.replace(/<\/?p>/g, " ");
    }
    if (message.type === "INCOMING") {
      paragraphs = message.content
        .replace(/<p>/g, "")
        .split("</p>")
        .filter((a) => a)
        .map((a) => {
          return {
            type: "INCOMING",
            content: a,
            created_at: new Date(),
            role: "assistant"
          };
        });
    }

    return (
      <React.Fragment key={key}>
        {paragraphs.map((item, index) => {
          return (
            <ChatBubble
              key={key + "_" + index}
              variation={item.type}
              content={item.content}
              animate={false}
              scroll={scrollChatToBottom}
            />
          );
        })}
        {botAnswer && (
          <Box
            sx={{
              display: "block",
              width: "fit-content",
              mb: 1,
              padding: 1.5,
              borderRadius: "8px",
              marginInlineEnd: 3,
              background: theme.palette.secondary.main,
              color: theme.palette.secondary.contrastText
            }}>
            {botAnswer}
          </Box>
        )}
      </React.Fragment>
    );
  };

  return (
    <Box
      sx={{
        borderRight: showLogs ? `1px solid ${theme.palette.divider}` : "none",
        paddingRight: showLogs ? theme.spacing(4) : 0
      }}
      className={classes.container}
      ref={chatBoxRef}>
      <ScrollBox ref={chatContentRef} className={classes.main}>
        {conversation?.map((item, index) => {
          return renderMessage(item, index);
        })}
        {runQuery && <PendingBubble />}
      </ScrollBox>
      <Box className={classes.footer}>
        <TextEditor variant="contained" className={classes.editor}>
          <TextEditorInput
            onChange={() => {
              if (!activeChat) {
                setActiveChat(true);
              }
            }}
            className={classes.input}
            placeholder="enter your text here"
          />
          <TextEditorFooter
            showWordCount={true}
            wordLimit={300}
            className={classes.footerActions}
          />
          <TextEditorButton
            color="primary"
            className={classes.sendButton}
            onClick={async ({ plainText }) => {
              handleIncomingMessage(plainText);
            }}
            resetOnClick={true}
            disabled={!conversation.length}>
            Send
          </TextEditorButton>
        </TextEditor>
      </Box>
    </Box>
  );
};

export default NewConversion;
