// Dependencies
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

// Redux
import { useSelector } from "react-redux";
import { selectTask, selectSubmission } from "../../../redux/tasksSlice";
import makeStyles from "@mui/styles/makeStyles";
import { Typography, Card, CardContent, CardActions, Box } from "@mui/material";
import { useIntl } from "react-intl";
import clsx from "clsx";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import PushPinIcon from "@mui/icons-material/PushPin";
import { InteractionCardAction } from "../../SharedComponents/card";
// Styles
const useStyles = makeStyles((theme) => {
  return {
    answer: {
      margin: theme.spacing(1),
      display: "flex",
      flexFlow: "column",
      breakInside: "avoid",
      pageBreakInside: "avoid",
      cursor: "pointer",
      minHeight: "max-content",
      justifyContent: "space-between"
    },
    answerBody: {
      display: "-webkit-box",
      overflow: "hidden",
      "-webkit-line-clamp": "6",
      "-webkit-box-orient": "vertical",
      textOverflow: "ellipsis"
    },
    answerBodyExpanded: {
      fontFamily: theme.typography.fontFamily,
      fontSize: "16px",
      textAlign: "start"
    },
    answerActions: {
      padding: "16px",
      color: "#168FEE",
      display: "flex",
      justifyContent: "space-between"
    },
    showQuoteBtn: { color: "secondary", marginInlineEnd: theme.spacing(2) },
    showQuoteBtnOnly: {
      display: "flex",
      justifyContent: "flex-end"
    },
    userName: {
      color: theme.palette.primary.main
    },
    actionButtons: {
      display: "flex",
      color: theme.palette.primary.main,
      flexFlow: "row nowrap",
      marginBlockStart: theme.spacing(1)
    },
    unpinnedIconBtn: { color: theme.palette.grey.main },
    pinnedIconBtn: { color: theme.palette.text.primary }
  };
});

export default function OpenQuestionStats({
  answer,
  index,
  annonymizeForm,
  togglePin
}) {
  // Hooks
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();
  const divRef = useRef();

  // Redux State
  const task = useSelector((state) => selectTask(state, answer.task_id));
  const submission = useSelector((state) =>
    selectSubmission(state, answer.submission_id)
  );

  // Ephemeral State
  const [quoteTextExpanded, setQuoteTextExpanded] = useState(false);
  const [shortText, setShortText] = useState(false);

  useEffect(() => {
    if (divRef.current)
      if (divRef.current.offsetHeight < divRef.current.scrollHeight) {
        setShortText(false);
      } else {
        setShortText(true);
      }
  }, [answer.content]);

  // Derived State
  return (
    <Card className={classes.answer} variant="outlined">
      <CardContent
        onClick={() => {
          !annonymizeForm &&
            history.push(
              `/task?submission_id=${answer.submission_id}&question_id=${index}`
            );
        }}>
        <Typography
          className={clsx(
            !quoteTextExpanded && classes.answerBody,
            quoteTextExpanded && classes.answerBodyExpanded
          )}
          variant="body1"
          component="p"
          ref={divRef}>
          {answer.content || "(No answer)"}
        </Typography>
      </CardContent>
      <CardActions
        className={clsx(
          classes.answerActions,
          annonymizeForm && classes.showQuoteBtnOnly
        )}>
        <Box>
          {!annonymizeForm && (
            <Typography
              size="small"
              className={clsx(classes.userName, "sentry-mask")}>
              {submission.user_name}
            </Typography>
          )}
        </Box>
        <Box className={classes.actionButtons}>
          <Typography
            variant="subtitle2"
            color={"primary"}
            className={classes.showQuoteBtn}
            onClick={() => {
              setQuoteTextExpanded(!quoteTextExpanded);
            }}>
            {!shortText && (
              <>
                {intl.formatMessage({
                  id: !quoteTextExpanded ? "showLess.text" : "show.text",
                  defaultMessage: !quoteTextExpanded ? "Show more" : "Show less"
                })}
              </>
            )}
          </Typography>

          <InteractionCardAction
            intlStringId={"Pin to top"}
            defaultMessage={"Pin to top"}
            tooltipPlacement="bottom">
            {answer?.pinned ? (
              <PushPinIcon
                onClick={() => {
                  togglePin(answer);
                }}
              />
            ) : (
              <PushPinOutlinedIcon
                onClick={() => {
                  togglePin(answer);
                }}
              />
            )}
          </InteractionCardAction>
        </Box>
      </CardActions>
    </Card>
  );
}
