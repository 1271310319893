// Dependencies
import React, { useState, useEffect, useLayoutEffect } from "react";
import useEffectOnce from "./hooks/useEffectOnce";
import { useLocation } from "react-router-dom";
import clsx from "clsx";
import { add } from "date-fns";
import { FEATURES } from "./consts";
import { userAPI } from "./api";
import {
  selectIsSuggestions,
  selectTextDirection
} from "./redux/firestoreSelectors";

// Redux dependencies
import { useDispatch, useSelector } from "react-redux";
import { initializeAppLayout } from "./redux/layoutSlice";
import { useFirestoreConnect } from "react-redux-firebase";

// Components
import useLogUserSession from "./hooks/useLogUserSession";
import MyAppBar from "./MyAppBar";
import TopBar from "./TopBar";
import Router from "./Router";
import Sidebar from "./Sidebar";
import FlashMessages from "./FlashMessages";
import Chat from "./components/chat/Chat";

// Material UI
import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";
import VersionUpdateDialog from "./VersionUpdateDialog";
import CustomFab from "./components/SharedComponents/CustomFab";
import CustomCursor from "./components/SharedComponents/CustomCursor";
import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import {
  CustomKeyboardSensor,
  CustomPointerSensor,
  onDragEnd
} from "./utils/dnd-utils";
import UnsupportedDeviceBanner from "./components/SharedComponents/UnsupportedDeviceBanner";
import { ZendeskLabels } from "./components/menus/ZendeskLabels";

// Styles
const useStyles = makeStyles((theme) => {
  return {
    header: {
      position: "fixed",
      top: "0",
      width: "100vw",
      transition: "top 0.6s",
      zIndex: theme.zIndex.drawer + 1
    },
    mainContent: {
      display: "flex",
      alignContent: "flex-start",
      height: "100%",
      width: "100%",
      paddingTop: "120px"

      // overflowX: "hidden",
      // overflowY: "auto"
    },
    mainContentWithSuggestions: {
      position: "fixed"
    }
  };
});

export function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default function Layout() {
  // Redux state
  const textDirection = useSelector((state) => selectTextDirection(state));
  const userId = useSelector((state) => state.firebase.auth.uid);
  const isSuggestions = useSelector((state) => selectIsSuggestions(state));

  const selectedCourse = useSelector(
    (state) => state.user.userProfile?.selectedCourse?.id
  );

  // Hooks
  useLogUserSession("ACTIVITY_EVENT", { course_id: selectedCourse || null });
  const dispatch = useDispatch();
  const classes = useStyles();
  const { pathname } = useLocation();

  useFirestoreConnect([
    {
      collection: "userProfiles",
      doc: "customConfig",
      subcollections: [{ collection: userId, doc: FEATURES.CROSS_FEATURE }],
      storeAs: "crossFeatureConfig"
    },
    {
      collection: "queuedTasks",
      doc: userId,
      subcollections: [{ collection: "queuedTasksPerUser" }],
      storeAs: "queuedTasks"
    },
    {
      collection: "userProfiles",
      doc: "defaultConfig",
      storeAs: "defaultConfig"
    }
  ]);

  // Ephemeral state
  const [actionBarVisable] = useState(true); // TODO: this doesn't make sense - it is always true
  const sensors = useSensors(
    useSensor(CustomPointerSensor, {
      activationConstraint: {
        distance: 0.01
      }
    }),
    useSensor(CustomKeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  // Behavior

  // TODO: this is here becouse we know that the user is loged in by now...
  // ... ideally this log should happen somewhere else
  useEffectOnce(() => {
    const isReload = window.performance
      .getEntriesByType("navigation")
      .map((navigation) => navigation.type)
      .includes("reload");

    if (isReload) {
      const now = new Date();
      userAPI.log({
        user_id: userId,
        action_name: "RELOAD_EVENT",
        created_at: now,
        ttl: add(now, { months: 1 }),
        payload: {
          path: pathname
        }
      });
    }
  });

  useLayoutEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      dispatch(initializeAppLayout(window.innerWidth));
    }

    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [dispatch]);

  //Render
  return (
    // <>
    <DndContext
      onDragEnd={onDragEnd}
      sensors={sensors}
      collisionDetection={closestCenter}>
      <ZendeskLabels />
      <Box component="header" className={classes.header}>
        <UnsupportedDeviceBanner />
        <MyAppBar />
        {actionBarVisable && <TopBar />}
      </Box>
      <Box
        className={clsx(
          classes.mainContent,
          isSuggestions && classes.mainContentWithSuggestions
        )}
        component="main"
        dir={textDirection}>
        <CustomCursor />
        <Sidebar />

        <Router>
          <ScrollToTop />
        </Router>
      </Box>

      <Chat />
      <CustomFab />
      <FlashMessages />
      <VersionUpdateDialog />
    </DndContext>
    // </>
  );
}
