// Dependecies
import React from "react";
import PropTypes from "prop-types";
import { SECONDARY_SIDEBAR_ACTION_BUTTONS } from "../../../consts";
import clsx from "clsx";

// Redux

// Components
import TooltipWithIntl from "../tooltip/TooltipWithIntl";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import VerticalSplitIcon from "@mui/icons-material/VerticalSplit";
import NavigationOutlinedIcon from "@mui/icons-material/NavigationOutlined";
// Material UI
import makeStyles from "@mui/styles/makeStyles";
import { Box, IconButton } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  lightIcon: {
    color: "#FFFFFF",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)"
    }
  },
  active: {
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: "rgba(255, 255, 255, 0.3)"
  }
}));

const ToggleBtnConstructor = ({
  type,
  disabled = false,
  handleBtnAction,
  className,
  secondarySideBarMode
}) => {
  const classes = useStyles();

  function renderIcon(type) {
    switch (true) {
      case type === SECONDARY_SIDEBAR_ACTION_BUTTONS.COMMENTS:
        return <CommentOutlinedIcon />;
      case type === SECONDARY_SIDEBAR_ACTION_BUTTONS.THUBMNAILS:
        return <VerticalSplitIcon />;
      case type === SECONDARY_SIDEBAR_ACTION_BUTTONS.SUGGESTIONS:
        return <NavigationOutlinedIcon />;
      default:
        return <></>;
    }
  }

  function renderLabel(type) {
    switch (true) {
      case type === SECONDARY_SIDEBAR_ACTION_BUTTONS.COMMENTS:
        return "Comments";
      case type === SECONDARY_SIDEBAR_ACTION_BUTTONS.THUBMNAILS:
        return "Thumbnails";
      case type === SECONDARY_SIDEBAR_ACTION_BUTTONS.SUGGESTIONS:
        return "Navigator";
      default:
        return <></>;
    }
  }
  return (
    <TooltipWithIntl
      intlStringId={`menu.tooltip.${type}`}
      defaultMessage={renderLabel(type)}
      placement="bottom">
      <Box component="span">
        <IconButton
          aria-label={`${type}`}
          className={clsx(
            classes.lightIcon,
            className,
            secondarySideBarMode && classes.active
          )}
          onClick={!disabled && handleBtnAction}
          size="large">
          {renderIcon(type)}
        </IconButton>
      </Box>
    </TooltipWithIntl>
  );
};
ToggleBtnConstructor.propTypes = {
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  handleBtnAction: PropTypes.func.isRequired,
  className: PropTypes.object,
  secondarySideBarMode: PropTypes.bool.isRequired
};

export default ToggleBtnConstructor;
