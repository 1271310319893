// Dependencies
import React, { useState, useEffect, useRef, useContext } from "react";
import { useQuery } from "../../../hooks";

// Redux dependencies
// Redux
import { useSelector } from "react-redux";
import { selectSubmission } from "../../../redux/tasksSlice";

// Components
import PeerReviewFeedback from "./PeerReviewFeedbak";
import PeerReviewReflection from "./PeerReviewReflection";
import PeerReviewReview from "./PeerReviewReview";
import PangeaSpinner from "../../SharedComponents/PangeaSpinner";
import PeerReviewSidebar from "./PeerReviewSidebar";
import SidePanel from "../../SharedComponents/SidePanel";
import PrimarySidebarLayout from "../../SharedComponents/PrimarySidebarLayout";
import { useRendition } from "../../../RenditionContext";
import { PRIMARY_SIDEBAR_STATE } from "../../../consts";
import { selectIsPrimarySidebar } from "../../../redux/firestoreSelectors";

// Material UI

// Styles
export default function PeerReview({ showSubmissionDialog }) {
  // Hooks

  const answerRef = useRef(); // used to get the scroll position of the reflection
  const { submission_id } = useQuery();
  const { rendition } = useRendition();

  // Redux state
  const submission = useSelector((state) =>
    selectSubmission(state, Number(submission_id))
  );

  const userUid = useSelector((state) => state.user.auth.uid);
  const courseRole = useSelector(
    (state) => state.user.userProfile.selectedCourse.course_role
  );
  const showSidebar = useSelector((state) => selectIsPrimarySidebar(state));
  // Ephemeral state
  const [currentTab, setCurrentTab] = useState(0);
  const [zenMode, setZenMode] = useState(false);
  const [textBookmark, SetTextBookmark] = useState(null);
  const [answerBookmark, SetAnswerBookmark] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Derived state
  // user role
  const isTeacher = courseRole === "Teacher";
  const isStudent = courseRole === "Student";
  const isReflector = submission.owner === userUid;
  const isReviewer = isStudent && submission.owner !== userUid;

  //submission status
  const status = submission.status;
  const isActive = status === "Pending" || status === "Active";
  const isPendingResponse = status === "pendingResponse";
  const isSubmitted = status === "Submitted";
  const isGraded = status === "Graded";
  // Behavior

  // Scroll to saved reflection position
  useEffect(() => {
    if (answerRef.current && answerBookmark && (currentTab === 0 || !zenMode)) {
      answerRef.current.scrollTo(0, answerBookmark);
    }
  }, [currentTab, zenMode, answerBookmark, answerRef]);

  const saveCurrentTabLocation = () => {
    if (currentTab === 0) {
      saveAnswerLocation();
    } else {
      saveTextLocation();
    }
  };

  const saveTextLocation = () => {
    if (rendition) {
      let location = rendition.currentLocation();
      if (location && location.start && location.start.cfi) {
        SetTextBookmark(location.start.cfi);
      }
    }
  };

  const saveAnswerLocation = () => {
    SetAnswerBookmark(answerRef.current.scrollTop);
  };

  // Render
  if (isLoading) {
    return <PangeaSpinner />;
  } else if ((isReflector && isActive) || (isReflector && isPendingResponse)) {
    return (
      <>
        <PrimarySidebarLayout collapsed={!showSidebar}>
          <SidePanel />
          {showSidebar && <PeerReviewSidebar />}
        </PrimarySidebarLayout>
        <PeerReviewReflection
          saveTextLocation={saveTextLocation}
          textBookmark={textBookmark}
          setCurrentTab={setCurrentTab}
          answerBookmark={answerBookmark}
        />
      </>
    );
  } else if ((isReviewer && isPendingResponse) || (isStudent && isSubmitted)) {
    return (
      <PeerReviewReview
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        zenMode={zenMode}
        setZenMode={setZenMode}
        saveCurrentTabLocation={saveCurrentTabLocation}
        textBookmark={textBookmark}
        answerRef={answerRef}
      />
    );
    // }
  } else if ((isTeacher && (isPendingResponse || isSubmitted)) || isGraded) {
    return (
      <PeerReviewFeedback
        showSubmissionDialog={showSubmissionDialog}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        zenMode={zenMode}
        setZenMode={setZenMode}
        saveCurrentTabLocation={saveCurrentTabLocation}
        textBookmark={textBookmark}
        answerBookmark={answerBookmark}
        answerRef={answerRef}
      />
    );
  } else return null;
}
