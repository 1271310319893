/* eslint react/prop-types: 0 */
// Dependencies
import React from "react";

// Redux dependencies

//Components
import PlaygroundPanel from "./PlaygroundPanel";
import PlaygroundChat from "./PlaygroundChat";

// Styles
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  playgroundBody: {
    width: "100%",
    display: "flex",
    height: "calc(100vh - 190px)",
    flexFlow: "row nowrap"
  },
  playgrounPanel: {
    width: "25%",
    borderRight: `1px solid ${theme.palette.divider}`
  },
  playgroundChat: {
    width: "75%"
  }
}));
const PlaygroundBody = ({
  selectedAssistant,
  setSelectedAssistant,
  assistantName,
  setAssistantName,
  assistantInstructions,
  setAssistantInstructions,
  modelName,
  setModelName,
  selectedUser,
  selectedQuestion,
  selectedTask,
  selectedCourse,
  setMessages,
  selectedSubmission,
  runQuery,
  setRunQuery,
  conversation,
  setConversation,
  submissionId,
  template,
  setTemplate,
  temperature,
  setTemperature,
  fetchPrompt
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.playgroundBody}>
      <Box className={classes.playgrounPanel}>
        <PlaygroundPanel
          taskId={selectedTask}
          courseId={selectedCourse}
          questionId={selectedQuestion}
          userId={selectedUser}
          selectedAssistant={selectedAssistant}
          setSelectedAssistant={setSelectedAssistant}
          assistantName={assistantName}
          setAssistantName={setAssistantName}
          assistantInstructions={assistantInstructions}
          setAssistantInstructions={setAssistantInstructions}
          modelName={modelName}
          setModelName={setModelName}
          submissionId={selectedSubmission}
          template={template}
          setTemplate={setTemplate}
          fetchPrompt={fetchPrompt}
          temperature={temperature}
          setTemperature={setTemperature}
        />
      </Box>
      <Box className={classes.playgroundChat}>
        <PlaygroundChat
          selectedUser={selectedUser}
          selectedQuestion={selectedQuestion}
          selectedTask={selectedTask}
          selectedCourse={selectedCourse}
          setMessages={setMessages}
          selectedSubmission={selectedSubmission}
          runQuery={runQuery}
          setRunQuery={setRunQuery}
          conversation={conversation}
          setConversation={setConversation}
        />
      </Box>
    </Box>
  );
};

export default PlaygroundBody;
