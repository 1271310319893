import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Typography, Button } from "@mui/material";
import { Arrow } from "react-laag";

// Styles
const useStyles = makeStyles((theme) => ({
  tooltip: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    zIndex: 1500,
    padding: theme.spacing(2),
    borderRadius: 10,
    paddingInline: theme.spacing(2),
    width: "200px",
    fontSize: "14px"
  }
}));

// Rich tooltip component as details in https://m3.material.io/components/tooltips/overview
const RichTooltip = React.forwardRef(function RichTooltip(
  {
    title,
    body,
    bodyHtml,
    action,
    onActionClick = () => {},
    layerProps = {},
    arrowProps,
    style = {}
  },
  ref
) {
  const classes = useStyles();
  const intl = useIntl();
  layerProps.style = { ...layerProps.style, ...style };

  return (
    <Box {...layerProps} className={classes.tooltip} boxShadow={4}>
      {title && (
        <Typography
          variant="h5"
          sx={{
            marginBottom: "10px"
          }}>
          {intl.formatMessage({
            id: title,
            defaultMessage: "Title"
          })}
        </Typography>
      )}
      {body && (
        <Typography variant="body2">
          {intl.formatMessage({
            id: body,
            defaultMessage: "Body"
          })}
        </Typography>
      )}
      {bodyHtml && bodyHtml}
      {action && (
        <Button
          sx={{
            marginTop: 2,
            padding: 0,
            justifyContent: "flex-start",
            minWidth: "inherit"
          }}
          ref={ref}
          variant="text"
          onClick={onActionClick}>
          {intl.formatMessage({
            id: action,
            defaultMessage: "Action"
          })}
        </Button>
      )}
      {arrowProps && <Arrow {...arrowProps} />}
    </Box>
  );
});

RichTooltip.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  action: PropTypes.string,
  onActionClick: PropTypes.func,
  layerProps: PropTypes.object,
  arrowProps: PropTypes.object,
  bodyHtml: PropTypes.node,
  style: PropTypes.object
};

export default RichTooltip;
