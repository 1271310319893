// Dependencies
import React, { useEffect, useState } from "react";
import { Route, Switch, useLocation, Redirect } from "react-router-dom";
import { useQuery } from "./hooks";

// Redux dependencies
import { useSelector } from "react-redux";

//login
import PermissionDenied from "./components/auth/PermissionDenied";

//main navigation
import Library from "./components/library/Library";
import ContactUs from "./components/contactUs/ContactUs";
import ProfilePage from "./components/ProfilePage/ProfilePage";
import Logout from "./components/auth/Logout";
import Notifications from "./Notifications";

// reader & GR
import Reader from "./components/reader/Reader";

// tasks
import Task from "./components/Tasks/Task";
import CreateAssignmentScreen from "./components/Tasks/CreateTask/CreateAssignmentScreen";
import TaskStatsView from "./components/Tasks/Stats/TaskStatsView";
import StudentReport from "./components/Tasks/Stats/studentReport/StudentReport";
import TaskEvaluation from "./components/Tasks/Admin/TaskEvaluation";
import TasksAdminView from "./components/Tasks/Admin/TasksAdminView";

// admin
import AdminRoutes from "./components/admin/AdminRoutes";
import Admin from "./components/admin/Admin";
import AdminCourse from "./components/admin/Course";
import ActivityReport from "./components/admin/reports/activityReport/ActivityReport";
import CourseActivityReport from "./components/admin/reports/courseActivity/CourseActivityReport";
import UserConversations from "./components/admin/UserConversations";
import FailedQueuedTasks from "./components/admin/monitoring/FailedQueuedTasks";
import Playground from "./components/admin/playground/Playground";

// Report
import CourseExternalReport from "./components/SharedComponents/reports/CourseExternalReport";

import TaskManager from "./components/Tasks/TaskManager/TaskManager";
import { REPORTS_VERSION } from "./consts";
import InstitutionActivityReport from "./components/admin/reports/institutionActivity/InstitutionActivityReport";
import StudentAnalytics from "./components/SharedComponents/studentAnalytics/StudentAnalytics";


export function ScrollToTopOnMount() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
}

export default function Router() {
  const { course_id } = useQuery();
  const courseRole = useSelector(
    (state) => state.user.userProfile.selectedCourse.course_role
  );

  const [reportVersion, setReportsVersion] = useState(REPORTS_VERSION.NEW);

  //Render
  return (
    <Switch>
      {/* ROOT */}
      <Route exact path="/">
        <Redirect to="/tasks" />
      </Route>

      {/* READER */}
      <Route exact path="/reader">
        <Reader grSettings={{ showSelfFeedback: true }} />
      </Route>

      {/* **** TASKS **** */}

      {/* create task*/}
      <Route exact path="/tasks/new">
        {courseRole === "Teacher" ? (
          <CreateAssignmentScreen />
        ) : (
          <Redirect to={`/tasks?course_id=${course_id}`} />
        )}
      </Route>

      {/* edit */}
      <Route exact path="/tasks/edit" component={CreateAssignmentScreen} />

      {/* task manager */}
      <Route exact path="/tasks" component={TaskManager} />

      {/* task */}
      <Route exact path="/task">
        <Task />
      </Route>

      {/* task stats */}
      <Route exact path="/task/stats" component={TaskStatsView} />
      <Route exact path="/tasks/stats" component={StudentAnalytics} />

      {/*  LIBRARY  */}
      <Route exact path="/library" component={Library} />

      {/*  CONTACT US  */}
      <Route exact path="/contactUs" component={ContactUs} />

      {/*  NOTIFICATIONS  */}
      <Route exact path="/notifications" component={Notifications} />

      {/*  USER PROFILE  */}
      <Route exact path="/profile" component={ProfilePage} />

      {/*  UTILL  */}
      <Route exact path="/logout" component={Logout} />
      <Route exact path="/permissionDenied" component={PermissionDenied} />

      {/*  INTERNAL REPORTS  */}
      <Route
        exact
        path="/CourseExternalReport"
        component={CourseExternalReport}
      />

      {/* ADMIN */}
      <AdminRoutes>
        <Route exact path="/admin" component={Admin} />
        <Route exact path="/admin/playground" component={Playground} />
        <Route exact path="/admin/course" component={AdminCourse} />
        <Route
          exact
          path="/admin/conversations"
          component={UserConversations}
        />
        <Route exact path="/admin/monitoring" component={FailedQueuedTasks} />
        <Route exact path="/admin/activityReport" component={ActivityReport} />
        <Route
          exact
          path="/admin/CourseActivityReport"
          component={CourseActivityReport}
        />
        <Route
          exact
          path="/admin/institutionActivityReport"
          component={InstitutionActivityReport}
        />
        {/* QA */}
        <Route exact path="/test"></Route>
        <Route exact path="/test/crash">
          <ErrorComponent />
        </Route>

        {/* TASK EVALUATION - currently not active and needs a new route  */}
        <Route exact path="/adminEvaluation/:submission_id">
          <ScrollToTopOnMount />
          <TaskEvaluation />
        </Route>
        <Route exact path="/adminTaskView/:task_id">
          <ScrollToTopOnMount />
          <TasksAdminView />
        </Route>
      </AdminRoutes>
    </Switch>
  );
}

// Utils

// Component for testing error boundaries
export function ErrorComponent() {
  throw new Error("Test error");
}
