// Dependancies
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";
import ePub from "epubjs";
import {
  combinePartialCfisToCfi,
  removeAllAnotationsOfType
} from "../../reader/utils";
import { ANNOTATION_TYPES } from "../../../consts";
// Redux dependancies
import { useSelector, useDispatch } from "react-redux";
import { selectCurrentText } from "../../../redux/textsSlice";

// Components
import DialogContentBook from "../../SharedComponents/DialogContentBook";

import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  ListItemIcon,
  ListItemText,
  ListItem,
  List,
  Typography
} from "@mui/material";

//Styles
import Brightness1Icon from "@mui/icons-material/Brightness1";
import { selectTextDirection } from "../../../redux/firestoreSelectors";
const useStyles = makeStyles((theme) => ({
  dialog: {
    zIndex: 10,
    display: "flex",
    flexFlow: "row"
  },
  epubViewContainer: {
    paddingLeft: "4vw",
    paddingRight: "1vw",
    width: "90%",
    height: "100%",
    overFlow: "hidden"
  },
  dialogTitle: {
    background: "#168FEE",
    color: "#ffffff"
  },
  dialogHeader: {},
  dialogActions: {
    justifyContent: "left",
    position: "relative"
  },
  legendIcon: {
    minWidth: "24px",
    marginInlineEnd: 4
  },
  legendLi: {
    paddingLeft: "0px",
    paddingRight: "0px",
    paddingTop: "0px",
    paddingBottom: "0px"
  },
  legendContainer: {
    height: "270px",
    zIndex: "1",
    right: "100px",
    paddingTop: theme.spacing(2),
    width: "150px"
  },
  legendText: {
    fontSize: "12px",
    fontFamily: "Helvetica",
    paddingInlineStart: 6
  },
  dialogActionsRtl: {
    justifyContent: "right",
    position: "relative"
  },
  blue: {
    color: "#168FEE"
  },
  dialogBtn: {
    position: "absolute",
    right: "16px",
    color: "#ffffff"
  },
  expandMsg: {
    cursor: "pointer",
    textAlign: "left",
    paddingLeft: "24px",
    paddingTop: "8px"
  },
  span: {
    textAlign: "left",
    paddingLeft: "24px",
    paddingBottom: "8px"
  },
  modal: {
    position: "relative",
    zIndex: 1,
    backgroundColor: "white",
    width: "90%",
    maxWidth: "90%"
  },
  modalCancel: {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer"
  },
  modalActions: {
    justifyContent: "center",
    "& button": {
      fontSize: "inharit",
      fontWeight: "600"
    }
  },
  buttonDiscard: {
    color: "#787877"
  }
}));

function HeatMap({
  openDialog,
  teacherHighlights,
  highlights,
  setOpenDialog,
  totalCount
}) {
  // Hooks
  const classes = useStyles();

  // Redux state

  // Ephemeral state
  const [rendition, setRendition] = useState(null);
  const [highlightsElements, setHighlightsElements] = useState([]);

  //Derived state
  //maybe need colors for dark mode?
  const EpubCFI = useMemo(() => {
    return new ePub.CFI();
  }, []);

  const colors = useMemo(() => {
    return [
      "#d7f7e6",
      "#aeefce",
      "#8ae1bb",
      "#5dd6a3",
      "#13cc8c",
      "#ffd863",
      "#ffaf2c",
      "#ff8719",
      "#ff3906",
      "#f80000"
    ];
  }, []);

  const getColorFromFreq = useCallback((frequence, totalCount, colors) => {
    let index = Math.floor(((frequence * 1.0) / totalCount) * 10) - 1;
    if (index < 0) index = 0;
    return colors[index];
  }, []);

  const getRanges = useCallback(
    (highlights, totalCount, colors) => {
      let coll = [];
      highlights &&
        highlights
          .filter((h) => h && h.cfi)
          .flatMap((h) => {
            if (h.isPdf) {
              // split multi page highlights to their parts
              let col = [];
              h.pdfPosition.forEach((pdf) => {
                col.push({ ...pdf, pdfPosition: [pdf], content: h.content });
              });
              return col;
            } else {
              return [h];
            }
          })
          .forEach((hl) => {
            let cfiParts = hl.cfi.split(","); // cfiBase:  cfiParts[0]
            let startCfi = cfiParts[0] + cfiParts[1] + ")"; // start: 'epubcfi(/6/4[chap01ref]!/4[body01]/10[para05]/2/1:1)'
            let endCfi = cfiParts[0] + cfiParts[2];
            coll.push({ ...hl, cfi: startCfi, val: 1 });
            coll.push({ ...hl, cfi: endCfi, val: -1 });
          });

      let sortedHl = [...coll].sort(function (a, b) {
        let val = EpubCFI.compare(a.cfi, b.cfi);
        if (val === 0) {
          if (a.val === b.val) return 0;
          return b.val < 0 ? 1 : -1;
        }
        return val;
      });

      let current = 0;
      let start = null;
      let frequencies = [];
      for (let i = 0; i < sortedHl.length; i++) {
        if (start == null) {
          if (sortedHl[i].val > 0) {
            start = sortedHl[i].cfi;
            current = 1;
          }
        } else {
          frequencies.push({
            ...sortedHl[i],
            start: start,
            end: sortedHl[i].cfi,
            freq: current
          });
          start = sortedHl[i].cfi;
          if (sortedHl[i].val > 0) {
            current++;
          } else {
            current--;
            if (current === 0) {
              start = null;
            }
          }
        }
      }

      //freq to colors :
      const output = frequencies.map((el, i) => {
        const isPdf = el?.isPdf || false;
        const cfi = combinePartialCfisToCfi(el.start, el.end, { isPdf });

        if (el.pdfPosition) {
          el.pdfPosition = [{ ...el.pdfPosition[0], cfi: cfi }];
        }

        return {
          ...el,
          cfi,
          color: getColorFromFreq(el.freq, totalCount, colors)
        };
      });
      return output;
    },
    [EpubCFI, getColorFromFreq]
  );

  // setting the highlights
  useEffect(() => {
    let ranges = getRanges(highlights, totalCount, colors);
    setHighlightsElements(ranges);
    if (!rendition) return;
    removeAllAnotationsOfType(rendition, ANNOTATION_TYPES.HIGHLIGHT);
    // render highlights from redux
    ranges.forEach((highlight) => {
      const cfi = highlight.cfi;
      const color = highlight.color;
      rendition.annotations.add(
        ANNOTATION_TYPES.HIGHLIGHT,
        cfi,
        { id: highlight.id },
        () => {},
        ANNOTATION_TYPES.HIGHLIGHT.toLowerCase(),
        {
          "z-index": 10,
          "mix-blend-mode": "multiply",
          "fill-opacity": 1,
          fill: color
        }
      );
    });
  }, [colors, getRanges, highlights, rendition, totalCount]);

  // setting the teachers highlight as an underline
  useEffect(() => {
    if (!rendition) return;
    removeAllAnotationsOfType(rendition, "underline");
    // render highlights from redux
    teacherHighlights.forEach((highlight) => {
      const cfi = highlight.cfi;
      const underlines = rendition.annotations.add(
        "underline",
        cfi,
        { id: highlight.id },
        () => {},
        ANNOTATION_TYPES.UNDERLINE.toLowerCase(),
        {
          stroke: "none",
          "stroke-opacity": 1
        }
      );
      // The passed style if only got the g element so we need to style the line directly:
      let createdDomElements = Array.from(underlines.mark.element.children);
      createdDomElements.forEach((element) => {
        element.style.strokeWidth = "2";
      });
    });

    // if (openDialog) renderHighlights(ranges, teacherHighlights);
  }, [rendition, teacherHighlights]);

  // We are using tha local version of rendition here
  function onRenditionCreated(rendition) {
    setRendition(rendition);
  }

  const textDirection = useSelector((state) => selectTextDirection(state));

  const renderCreateCourseDialog = () => {
    return (
      <Dialog
        open={openDialog}
        PaperProps={{
          style: {
            direction: textDirection,
            width: "90%",
            maxWidth: "90%",
            height: "100%"
          }
        }}
        aria-labelledby="form-dialog-title">
        <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
          <DialogActions className={clsx(classes.dialogActions)}>
            <Typography className={classes.dialogHeader}>
              <FormattedMessage id="text.mark" defaultMessage="Mark in Text" />
            </Typography>
            <Button
              variant="outlined"
              className={classes.dialogBtn}
              onClick={(e) => {
                setOpenDialog(false);
                setHighlightsElements([]);
              }}>
              <FormattedMessage defaultMessage="Done" id="gr.confirm.btn" />
            </Button>
          </DialogActions>
        </DialogTitle>
        <DialogContent className={classes.dialog}>
          <div id="epubViewContainer" className={classes.epubViewContainer}>
            <DialogContentBook
              highlights={highlightsElements}
              underlines={teacherHighlights}
              onTextSelected={() => {}}
              onRenditionCreated={onRenditionCreated}
            />
          </div>
          <Box className={classes.legendContainer}>
            <Typography className={classes.legendText}>
              % of students
            </Typography>
            <List>
              {colors.map((color, i) => {
                let min = i * 10 + 1;
                let max = (i + 1) * 10;

                return (
                  <ListItem key={color} className={classes.legendLi}>
                    <ListItemIcon className={classes.legendIcon}>
                      <Brightness1Icon style={{ fill: color }} />
                    </ListItemIcon>
                    <ListItemText
                      className={classes.legendText}
                      primary={min + " - " + max}
                    />
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </DialogContent>
      </Dialog>
    );
  };
  return <>{renderCreateCourseDialog()}</>;
}

export default HeatMap;
