// Dependencies
import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import clsx from "clsx";

// Material UI
import makeStyles from "@mui/styles/makeStyles";
import { Chip } from "@mui/material";

// Styles
const useStyles = makeStyles((theme) => ({
  SubmissionMissed: {
    color: theme.palette.lightText.main,
    backgroundColor: theme.palette.pieChart.SubmissionMissed_total
  },
  SubmissionLateIncomplete: {
    color: theme.palette.warning.contrast,
    backgroundColor: theme.palette.pieChart.SubmissionLate_incomplete
  },
  SubmissionPending: {
    backgroundColor: theme.palette.pieChart.SubmissionPending_no
  },
  SubmissionOnTimeIncomplete: {
    color: theme.palette.lightText.main,
    backgroundColor: theme.palette.pieChart.SubmissionOnTime_incomplete
  },
  SubmissionOnTime: {
    color: theme.palette.primary.contrast,
    backgroundColor: theme.palette.pieChart.SubmissionOnTime_complete
  },
  SubmissionLate: {
    color: theme.palette.primary.contrast,
    backgroundColor: theme.palette.pieChart.SubmissionLate_complete
  }
}));

export default function SubmissionStatusChip({ status }) {
  //Hooks
  const classes = useStyles();
  const intl = useIntl();

  // Ephemeral State
  const [label, setLabel] = useState("");
  const [chipStyle, setChipStyle] = useState([]);

  // Behavior

  const parseStatus = (status) => {
    if (!status) return;
    const statusType = Object.keys(status)[0];
    if (
      statusType === "SubmissionMissed" ||
      statusType === "SubmissionPending"
    ) {
      return statusType;
    } else if (
      statusType === "SubmissionOnTime" ||
      statusType === "SubmissionLate"
    ) {
      if (!(status[statusType].complete > 0)) {
        return `${statusType}Incomplete`;
      }
      return statusType;
    }
  };

  useEffect(() => {
    const chipStatus = parseStatus(status);
    // Set the style
    switch (chipStatus) {
      case "SubmissionMissed":
        setChipStyle("SubmissionMissed");
        setLabel("Missed");
        break;

      case "SubmissionPending":
        setChipStyle("SubmissionPending");
        setLabel("Pending");
        break;

      case "SubmissionOnTime":
        setChipStyle("SubmissionOnTime");
        setLabel("On time");
        break;

      case "SubmissionLate":
        setChipStyle("SubmissionLate");
        setLabel("Late Complete");
        break;

      case "SubmissionOnTimeIncomplete":
        setChipStyle("SubmissionOnTimeIncomplete");
        setLabel("On time incomplete");
        break;

      case "SubmissionLateIncomplete":
        setChipStyle("SubmissionLateIncomplete");
        setLabel("Late incomplete");
        break;
      default:
        setChipStyle("SubmissionPending");
        setLabel("Pending");
    }
  }, [status]);

  // Render
  return (
    <Chip
      className={clsx(
        chipStyle === "SubmissionMissed" && classes.SubmissionMissed,
        chipStyle === "SubmissionLate" && classes.SubmissionLate,
        chipStyle === "SubmissionPending" && classes.SubmissionPending,
        chipStyle === "SubmissionOnTimeIncomplete" &&
          classes.SubmissionOnTimeIncomplete,
        chipStyle === "SubmissionOnTime" && classes.SubmissionOnTime,
        chipStyle === "SubmissionLateIncomplete" &&
          classes.SubmissionLateIncomplete
      )}
      label={label}
      size="small"
    />
  );
}
