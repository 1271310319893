import React, { useState, useEffect, useCallback } from "react";
import { Box, Typography } from "@mui/material";
import {
  LEGEND_MARKER_FOR_TIME_SPENT_ON_ASSIGNMENT,
  colorMapping
} from "../consts";
import {
  flattenObject,
  sortSubmissionsObject,
  formatLegendLabel,
  calculateSubmissionPercentage,
  removeFutureAssignments,
  sortTimeSpentOnAssignmentObject,
  formatMillisecondsTime
} from "../utils";
import { useGetTheme } from "../../../../hooks";

export const Marker = ({ color, stroke, ...restProps }) => (
  <svg
    style={{ verticalAlign: "middle" }}
    fill={color}
    width="17"
    height="15"
    {...restProps}>
    <circle stroke={stroke} r={6} cx={7.5} cy={8} {...restProps} />
  </svg>
);

export const Label = ({ ...restProps }) => <Typography {...restProps} />;

export function generateMarkers(key, value, theme) {
  const markers = [];
  const color = theme.palette.pieChart[key] || "#E0E0E0";
  markers.push(
    <Marker
      stroke={color === "#495761" ? "#FFFFFF" : null}
      key={key}
      color={color}
    />
  );

  return markers;
}

function PieChartLegend({
  type,
  data,
  totalSubmissions,
  includeFutureAssignments,
  courseAssignments
}) {
  const [legendData, setLegendData] = useState([]);
  const isSubmissionsType = type === "submissions";
  const theme = useGetTheme();
  const prepareLegendData = useCallback(
    (data) => {
      let sortedSubmissions;
      if (isSubmissionsType) {
        sortedSubmissions = sortSubmissionsObject(
          includeFutureAssignments
            ? data.statusBased
            : removeFutureAssignments(data.statusBased).cleanedData
        );
      } else {
        sortedSubmissions = sortTimeSpentOnAssignmentObject(data);
      }
      return flattenObject(sortedSubmissions);
    },
    [includeFutureAssignments, isSubmissionsType]
  );

  useEffect(() => {
    if (!totalSubmissions || !data) {
      return;
    }

    const chartData = prepareLegendData(data);
    setLegendData(chartData);
  }, [
    data,
    totalSubmissions,
    type,
    includeFutureAssignments,
    prepareLegendData
  ]);

  return (
    <Box
      sx={{
        display: "flex",
        flexFlow: "column nowrap",
        height: "100%",
        width: "30%",
        marginBlockStart: "24px"
      }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column"
        }}>
        {Object.entries(legendData).map(([key, value], index) => (
          <Box
            key={key}
            sx={{
              display: "flex",
              alignItems: "center",
              marginBottom: 2.5,
              marginInlineStart: "12px"
            }}>
            {generateMarkers(key, value, theme)}
            {isSubmissionsType ? (
              <Typography
                variant="caption"
                sx={{
                  marginInlineStart: "8px",
                  color: theme.palette.text.primary
                }}>
                {formatLegendLabel(key)}:{" "}
                {calculateSubmissionPercentage(value, totalSubmissions)}
              </Typography>
            ) : (
              <Typography
                variant="caption"
                sx={{
                  marginInlineStart: "8px",
                  color: theme.palette.text.primary
                }}>
                {`${LEGEND_MARKER_FOR_TIME_SPENT_ON_ASSIGNMENT[key]}`}
                {formatMillisecondsTime(value, courseAssignments)}
              </Typography>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default PieChartLegend;
