/* eslint react/prop-types: 0 */
// Dependencies
import React from "react";

// Redux

// Components

// Material UI
import { Box, Typography, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

// Styles
const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: "flex",
      flexFlow: "row nowrap",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      height: "70px"
    },
    logsButton: {
      backgroundColor: theme.palette.card.background.disabled,
      color: theme.palette.text.primary,
      "&:hover": {
        backgroundColor: theme.palette.grey.main
      }
    },
    thread: {
      fontWeight: "bold",
      paddingBlockEnd: theme.spacing(1),
      color: theme.palette.text.primary,
      fontSize: theme.typography.caption.fontSize
    }
  };
});

const ChatHeader = ({ setShowLogs, showLogs, selectedQuestionId }) => {
  const classes = useStyles();

  const toggleLogs = () => {
    setShowLogs(!showLogs);
  };
  return (
    <Box className={classes.container}>
      <Box>
        <Typography variant="p" className={classes.thread}>
          {`Question Id: ${selectedQuestionId}`}
        </Typography>
      </Box>
      <Box>
        <Button
          onClick={toggleLogs}
          variant="contained"
          className={classes.logsButton}
          size="small">
          {showLogs ? "Hide logs" : "Logs"}
        </Button>
      </Box>
    </Box>
  );
};

export default ChatHeader;
