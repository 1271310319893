// dependancies
import { httpCallables } from "../../../firebase";
import { captureException } from "../../../utils/errorHandlers";

// Redux
import { fetchConversation, setConversations } from "../../chatSlice";

// rx
import { ofType } from "redux-observable";
import { EMPTY } from "rxjs";
import { map, catchError, switchMap } from "rxjs/operators";

const CALLBACK = httpCallables.interactionFunctions;
const ACTION = "readConversationBySubmissionId";
const ERROR_MSG = `FETCH_CONVERSATION_BY_INTERACTION_ID`;

export const fetchConversationEpic = (
  action$,
  state$,
  { fetchAsObservable }
) => {
  return action$.pipe(
    /* TODO: This is a naive implementaion of the loading state.
     * improve on this by not delaying the loader for fast API ~250ms
     * and setting the min time for showing the loader ~500ms
     */

    ofType(fetchConversation.type),
    map(({ payload: { submission_id, text_id } }) => ({
      submission_id: Number(submission_id) || null,
      text_id: Number(text_id)
    })),
    switchMap((args) => {
      const { submission_id, text_id } = args;
      return fetchAsObservable(CALLBACK, ACTION, ERROR_MSG, args).pipe(
        map((data) => ({
          type: setConversations.type,
          payload: {
            ...data,
            submission_id: Number(submission_id) || null,
            text_id: Number(text_id)
          }
        }))
      );
    }),
    catchError((error) => {
      captureException(error, "Error in fetchConversationEpic");
      return EMPTY;
    })
  );
};
