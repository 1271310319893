import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { normalizeGrade, reverseNormalizeGrade } from "../utils";
import { Box } from "@mui/material";
import { useGetTheme } from "../../../../hooks";

const GradeHistogram = ({ data, courseStudents, selectedAssignment }) => {
  const svgRef = useRef();
  const theme = useGetTheme();
  const isDark = theme.palette.mode === "dark";

  function processGrades(array, maxGrade) {
    return array
      .map((obj) => {
        let newObj = { ...obj };
        if (
          Object.prototype.hasOwnProperty.call(newObj, "grade") &&
          newObj.grade !== null
        ) {
          newObj.grade = reverseNormalizeGrade(Number(newObj.grade), maxGrade);
        }
        return newObj;
      })
      .filter(
        (obj) =>
          Object.prototype.hasOwnProperty.call(obj, "grade") &&
          obj.grade !== null
      );
  }

  useEffect(() => {
    d3.select(svgRef.current).selectAll("*").remove();

    let processedData = data;

    // Filter data based on selected assignment if max_grade is 10
    if (selectedAssignment && selectedAssignment.max_grade < 100) {
      processedData = data.filter((d) => d.task_id === selectedAssignment.id);
      processedData = processGrades(
        processedData,
        selectedAssignment.max_grade
      );
    }
    // Set dimensions and margins for the chart
    const margin = { top: 30, right: 30, bottom: 50, left: 40 };
    const width = 850 - margin.left - margin.right;
    const height = 350 - margin.top - margin.bottom;

    // Append SVG object to the ref
    const svg = d3
      .select(svgRef.current)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Set the ranges

    const x = d3
      .scaleLinear()
      .domain([0, 100])
      .range([30, width - margin.left - margin.right]);

    // Create histogram data
    const histogram = d3
      .histogram()
      .value((d) => d.grade)
      .domain(x.domain())
      .thresholds(x.ticks(20));

    const bins = histogram(processedData);

    // Calculate the width of each bin
    const binWidth = (x(bins[0].x1) - x(bins[0].x0)) * 1;
    const maxBinCount = d3.max(bins, (d) => d.length);

    const y = d3.scaleLinear().domain([0, maxBinCount]).range([height, 0]);
    // Append the background bars with margins, excluding the last bin
    bins.slice(0, -1).forEach((bin, i) => {
      if (
        bin.length > 0 ||
        (i > 0 && i < bins.length - 1 && bins[i - 1].length > 0)
      ) {
        svg
          .append("rect")
          .attr("class", "background-bar")
          .attr("x", x(bin.x0) + binWidth * 0.05) // Adjusted x position for margin
          .attr("y", 10)
          .attr("width", binWidth * 0.9) // Adjusted width for margin
          .attr("height", height - 10)
          .attr("fill", theme.palette.primary.light)
          .attr("opacity", 0.5);
      }
    });

    // Calculate the y values for the curve shape
    const curvePath = d3
      .area()
      .curve(d3.curveBasis)
      .x0((d) => x(d.x0))
      .x1((d) => x(d.x1))
      .y1((d) => y(d.length))
      .y0(y(0));

    // Append the curve path
    svg
      .append("path")
      .datum(bins)
      .attr(
        "fill",
        isDark ? theme.palette.primary.dark : "rgba(94, 146, 243, 1)"
      )
      // .attr("fill", "rgba(94, 146, 243, 1)")
      .attr("opacity", 0.7)
      .attr("d", curvePath);

    // Add the x Axis
    svg
      .append("g")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(x).tickFormat((d) => `${d}%`))
      .append("text")
      .attr("class", "axis-label")
      .attr("x", width)
      .attr("y", -10)
      .attr("dy", "2.1em")
      .style("text-anchor", "middle")
      .attr("fill", theme.palette.text.primary)
      .style("font-size", "14px")
      .text("Grade");

    // Add the y Axis
    const gradeCount = d3.rollup(
      processedData,
      (D) => D.length,
      (d) => d.grade
    );
    function findMaxValueOfStudents(map) {
      let maxValue = -Infinity;

      for (let value of map.values()) {
        if (typeof value === "number" && value > maxValue) {
          maxValue = value;
        }
      }

      return maxValue === -Infinity ? null : maxValue;
    }
    const maxValue = findMaxValueOfStudents(gradeCount);
    svg
      .append("g")
      .call(
        d3
          .axisLeft(y)
          .ticks(maxValue)
          .tickFormat((d) => d.toFixed(0))
      )
      .append("text")
      .attr("class", "y-axis-label")
      .attr("y", -15)
      .attr("x", 15)
      .attr("dx", "1em")
      .style("text-anchor", "middle")
      .style("fill", theme.palette.text.primary)
      .style("font-size", "14px")
      .text("Number of students");

    svg.selectAll(".tick text").style("fill", theme.palette.text.primary);

    // Add a tooltip
    const tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0)
      .style("position", "absolute")
      .style(
        "background",
        isDark ? theme.palette.primary.light : "lightsteelblue"
      )
      .style("padding", "8px")
      .style("border", "1px solid #ccc")
      .style("pointer-events", "none");
    tooltip.remove();

    // Interaction with the curve path
    svg
      .selectAll("path")
      .on("mouseover", (event, d) => {
        const mouseX = event.pageX - margin.left;
        const binIndex = Math.floor(x.invert(mouseX) / (100 / bins.length - 1));
        if (binIndex >= 0 && binIndex < d.length && d[binIndex].length > 0) {
          const binData = d[binIndex];
          tooltip.transition().duration(200).style("opacity", 0.9);
          tooltip
            .html(`Count: ${binData.length}`)
            .style("left", event.pageX + "px")
            .style("top", event.pageY - 28 + "px");
        }
      })
      .on("mouseout", () => {
        tooltip.transition().duration(500).style("opacity", 0);
      });
    svg.selectAll(".tick line").remove();
    svg.selectAll(".tick text").style("font-size", "14px");
    // Add lines across the chart
    svg
      .selectAll(".tick")
      .append("line")
      .attr("x1", 0)
      .attr("y1", 0)
      .attr("x2", width)
      .attr("y2", 0)
      .attr("stroke", "lightgray");
    svg.selectAll(".domain").remove(); // Remove x-axis and y-axis lines

    return () => {
      tooltip.remove();
    };
  }, [data, courseStudents.length, selectedAssignment, theme, isDark]);

  return (
    <Box style={{ overflow: "hidden", width: "100%", height: "100%" }}>
      <svg ref={svgRef}></svg>
    </Box>
  );
};

export default GradeHistogram;
