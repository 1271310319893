import { Mark } from "marks-pane/lib/marks";

import svg from "marks-pane/lib/svg";

//When not in an iframe, the marks pane incorrectly positions studf, overriding the relevant methods here

// import 'babelify/polyfill'; // needed for Object.assign

const events = {
  proxyMouse: proxyMouse
};

function coords(el, container) {
  var offset = container.getBoundingClientRect();
  var rect = el.getBoundingClientRect();

  return {
    top: rect.top - offset.top,
    left: rect.left - offset.left,
    height: el.scrollHeight,
    width: el.scrollWidth
  };
}

function setCoords(el, coords) {
  el.style.setProperty("top", `${coords.top}px`, "important");
  el.style.setProperty("left", `${coords.left}px`, "important");
  // el.style.setProperty('right', `0px`, 'important');
  //el.style.setProperty('bottom', `0px`, 'important');
  //another change, makes easier to resize stuff
  el.style.setProperty("height", `100%`, "important");
  el.style.setProperty("width", `100%`, "important");
}

/**
 * Start proxying all mouse events that occur on the target node to each node in
 * a set of tracked nodes.
 *
 * The items in tracked do not strictly have to be DOM Nodes, but they do have
 * to have dispatchEvent, getBoundingClientRect, and getClientRects methods.
 *
 * @param target {Node} The node on which to listen for mouse events.
 * @param tracked {Node[]} A (possibly mutable) array of nodes to which to proxy
 *                         events.
 */
export function proxyMouse(target, tracked) {
  function dispatch(e) {
    // We walk through the set of tracked elements in reverse order so that
    // events are sent to those most recently added first.
    //
    // This is the least surprising behaviour as it simulates the way the
    // browser would work if items added later were drawn "on top of"
    // earlier ones.
    //Added patch
    let elementToAttachEventTo = null;
    let allMatchedElements = [];

    for (var i = tracked.length - 1; i >= 0; i--) {
      var t = tracked[i];
      var x = e.clientX;
      var y = e.clientY;

      if (e.touches && e.touches.length) {
        x = e.touches[0].clientX;
        y = e.touches[0].clientY;
      }

      if (!eventTargetContains(t, x, y)) {
        continue;
      }

      // 9/4/22: in order to pass a list of all matching elements with the event, we save the first matching element (and attach the event to it) and we save all matching elements and attache it to event.detail
      if (!elementToAttachEventTo) elementToAttachEventTo = t;
      allMatchedElements.push(t.data || {});
    }
    elementToAttachEventTo &&
      elementToAttachEventTo.dispatchEvent(
        clone(e, { detail: allMatchedElements })
      );
  }

  if (target.nodeName === "iframe" || target.nodeName === "IFRAME") {
    try {
      // Try to get the contents if same domain
      this.target = target.contentDocument;
    } catch (err) {
      this.target = target;
    }
  } else {
    this.target = target;
  }

  for (var ev of ["mouseup", "mousedown", "click", "touchstart"]) {
    this.target.addEventListener(ev, (e) => dispatch(e), false);
  }
}

/**
 * Clone a mouse event object.
 *
 * @param e {MouseEvent} A mouse event object to clone.
 * @returns {MouseEvent}
 */
export function clone(e, options) {
  var opts = Object.assign({}, e, { bubbles: false, ...options });
  try {
    return new CustomEvent(e.type, opts);
  } catch (err) {
    // compat: webkit
    var copy = document.createEvent("MouseEvents");
    copy.initMouseEvent(
      e.type,
      false,
      opts.cancelable,
      opts.view,
      opts.detail,
      opts.screenX,
      opts.screenY,
      opts.clientX,
      opts.clientY,
      opts.ctrlKey,
      opts.altKey,
      opts.shiftKey,
      opts.metaKey,
      opts.button,
      opts.relatedTarget
    );
    return copy;
  }
}

/**
 * Check if the item contains the point denoted by the passed coordinates
 * @param item {Object} An object with getBoundingClientRect and getClientRects
 *                      methods.
 * @param x {Number}
 * @param y {Number}
 * @returns {Boolean}
 */
function eventTargetContains(item, x, y) {
  // offset
  function rectContains(r, x, y) {
    var top = r.top;
    var left = r.left;
    var bottom = top + r.height;
    var right = left + r.width;
    return top <= y && left <= x && bottom > y && right > x;
  }

  // Check overall bounding box first
  var rect = item.getBoundingClientRect();
  if (!rectContains(rect, x, y)) {
    return false;
  }

  // Then continue to check each child rect
  var rects = item.getClientRects();
  for (var i = 0, len = rects.length; i < len; i++) {
    if (rectContains(rects[i], x, y)) {
      return true;
    }
  }
  return false;
}

export class PdfPane {
  constructor(target, container = document.body) {
    this.target = target;
    this.element = svg.createElement("svg");
    this.marks = [];

    // Match the coordinates of the target element
    this.element.style.position = "absolute";
    // Disable pointer events
    this.element.setAttribute("pointer-events", "none");

    // Set up mouse event proxying between the target element and the marks
    events.proxyMouse(this.target, this.marks);

    this.container = container;
    this.container.appendChild(this.element);

    this.render();
  }

  addMark(mark) {
    var g = svg.createElement("g");
    this.element.appendChild(g);
    mark.bind(g, this.container);

    this.marks.push(mark);

    mark.render();
    return mark;
  }

  removeMark(mark) {
    var idx = this.marks.indexOf(mark);
    if (idx === -1) {
      return;
    }
    var el = mark.unbind();
    this.element.removeChild(el);
    this.marks.splice(idx, 1);
  }

  render() {
    setCoords(this.element, coords(this.target, this.container));
    for (var m of this.marks) {
      m.render();
    }
  }
}

export class PdfHighlightMark extends Mark {
  constructor(range, className, data, attributes) {
    super();
    this.range = range;
    this.className = className;
    this.data = data || {};
    this.attributes = attributes || {};
  }

  bind(element, container) {
    super.bind(element, container);

    for (var data_attr in this.data) {
      if (Object.prototype.hasOwnProperty.call(this.data, data_attr)) {
        this.element.dataset[data_attr] = this.data[data_attr];
      }
    }

    for (var attr in this.attributes) {
      if (Object.prototype.hasOwnProperty.call(this.attributes, attr)) {
        this.element.setAttribute(attr, this.attributes[attr]);
      }
    }

    if (this.className) {
      this.element.classList.add(this.className);
    }
  }

  render() {
    // Empty element
    while (this.element.firstChild) {
      this.element.removeChild(this.element.firstChild);
    }

    var docFrag = this.element.ownerDocument.createDocumentFragment();
    var filtered = this.filteredRanges();
    var container = this.container.getBoundingClientRect();

    for (var i = 0, len = filtered.length; i < len; i++) {
      var r = filtered[i];
      var el = svg.createElement("rect");
      //changed from originl - no need for offset
      el.setAttribute("x", r.left - container.left);
      el.setAttribute("y", r.top - container.top);
      el.setAttribute("height", r.height);
      el.setAttribute("width", r.width);
      docFrag.appendChild(el);
    }

    this.element.appendChild(docFrag);
  }
}

export class PdfUnderlineMark extends PdfHighlightMark {
  render() {
    // Empty element
    while (this.element.firstChild) {
      this.element.removeChild(this.element.firstChild);
    }

    var docFrag = this.element.ownerDocument.createDocumentFragment();
    var filtered = this.filteredRanges();
    //no need fo offset when not in iframe var offset = this.element.getBoundingClientRect();
    var container = this.container.getBoundingClientRect();

    for (var i = 0, len = filtered.length; i < len; i++) {
      var r = filtered[i];
      //all positions changed to ignore offset
      var rect = svg.createElement("rect");
      rect.setAttribute("x", r.left - container.left);
      rect.setAttribute("y", r.top - container.top);
      rect.setAttribute("height", r.height);
      rect.setAttribute("width", r.width);
      rect.setAttribute("fill", "none");

      var line = svg.createElement("line");
      line.setAttribute("x1", r.left - container.left);
      line.setAttribute("x2", r.left - container.left + r.width);
      line.setAttribute("y1", r.top - container.top + r.height - 1);
      line.setAttribute("y2", r.top - container.top + r.height - 1);

      line.setAttribute("stroke-width", 1);
      line.setAttribute("stroke", "black"); //TODO: match text color?
      line.setAttribute("stroke-linecap", "square");

      docFrag.appendChild(rect);

      docFrag.appendChild(line);
    }

    this.element.appendChild(docFrag);
  }
}
