import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useGetTheme } from "../../../hooks";
import { truncateMiddle } from "../../contactUs/utils";

const TableHeadComponent = () => (
  <TableHead>
    <TableRow>
      <TableCell sx={{ fontWeight: 900, borderBottom: "none" }}>
        Assignments
      </TableCell>
      <TableCell sx={{ fontWeight: 900, borderBottom: "none" }}>
        Step 1: Highlight
      </TableCell>
      <TableCell sx={{ fontWeight: 900, borderBottom: "none" }}>
        Step 2: Review
      </TableCell>
      <TableCell sx={{ fontWeight: 900, borderBottom: "none" }}>
        Step 3: Answer
      </TableCell>
    </TableRow>
  </TableHead>
);
const mapAssignments = (assignments) =>
  new Map(assignments.map((assignment) => [assignment.id, assignment]));

const StudentSubmissionsTime = ({ submissions, assignments, timeSpent }) => {
  // Hooks
  const theme = useGetTheme();
  // Ephemeral state
  const [courseAssignments, setCourseAssignments] = useState(
    mapAssignments(assignments)
  );
  const stepsColumnWidth = 150;

  const createAssignmentsTimeSpent = (timeSpent) => {
    const assignmentsTimeSpent = [];
    assignments.map((assignment) => {
      const highlightTime = timeSpent.HIGHLIGHT_TIME?.find(
        (item) => item.task_id === assignment.id
      )?.duration;
      const reviewTime = timeSpent.REVIEW_TIME?.find(
        (item) => item.task_id === assignment.id
      )?.duration;
      const answerTime = timeSpent.ANSWER_TIME?.find(
        (item) => item.task_id === assignment.id
      )?.duration;
      const totalTimeSpent = highlightTime + reviewTime + answerTime;
      // convert to percentage and push
      if (highlightTime && reviewTime && answerTime) {
        assignmentsTimeSpent.push({
          id: assignment.id,
          highlightTime: ((highlightTime / totalTimeSpent) * 100).toFixed(0),
          reviewTime: ((reviewTime / totalTimeSpent) * 100).toFixed(0),
          answerTime: ((answerTime / totalTimeSpent) * 100).toFixed(0)
        });
      }
    });
    return assignmentsTimeSpent;
  };

  const assignmentsTimeSpent = createAssignmentsTimeSpent(timeSpent);
  const getTimeSpentById = (assignmentId) => {
    return assignmentsTimeSpent.find(
      (assignment) => assignment.id === assignmentId
    );
  };

  // Behavior
  useEffect(() => {
    setCourseAssignments(mapAssignments(assignments));
  }, [assignments]);

  return (
    <Box
      sx={{
        padding: "0 24px 24px 24px",
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: "5px"
      }}>
      {/* HEADER */}
      <Box
        sx={{
          display: "flex",
          height: "60px",
          width: "100%",
          flexFlow: "row nowrap",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: `1px solid ${theme.palette.border.secondary}`
        }}>
        <Typography variant="subtitle1">
          Time spent on assignments steps
        </Typography>
      </Box>
      <Box>
        <TableContainer>
          <Table aria-label="Submission times table">
            <TableHeadComponent />
            <TableBody>
              {submissions.map((submission, index) => (
                <TableRow
                  key={index}
                  sx={{
                    borderBottom: `1px solid ${theme.palette.border.secondary}`
                  }}>
                  <TableCell
                    scope="row"
                    sx={{
                      minWidth: 220,
                      height: "auto",
                      borderBottom: "none",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center"
                    }}>
                    <Box display="flex">
                      <Typography
                        style={{ marginInlineEnd: "12px" }}
                        variant="body2">
                        {index + 1}
                      </Typography>
                      <Typography variant="body2">
                        <span>
                          {truncateMiddle(
                            courseAssignments.get(submission.task_id)?.name,
                            170
                          )}
                        </span>
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell
                    scope="row"
                    sx={{
                      borderBottom: "none",
                      minWidth: stepsColumnWidth
                    }}>
                    <Box display="flex">
                      <Typography
                        style={{ marginInlineEnd: "12px" }}
                        variant="body2">
                        {getTimeSpentById(submission.task_id)?.highlightTime &&
                          `${getTimeSpentById(submission.task_id)?.highlightTime}%`}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell
                    scope="row"
                    sx={{
                      borderBottom: "none",
                      minWidth: stepsColumnWidth
                    }}>
                    <Box display="flex">
                      <Typography
                        style={{ marginInlineEnd: "12px" }}
                        variant="body2">
                        {getTimeSpentById(submission.task_id)?.reviewTime &&
                          `${getTimeSpentById(submission.task_id)?.reviewTime}%`}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell
                    scope="row"
                    sx={{
                      borderBottom: "none",
                      minWidth: stepsColumnWidth
                    }}>
                    <Box display="flex">
                      <Typography
                        style={{ marginInlineEnd: "12px" }}
                        variant="body2">
                        {getTimeSpentById(submission.task_id)?.answerTime &&
                          `${getTimeSpentById(submission.task_id)?.answerTime}%`}
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default StudentSubmissionsTime;
