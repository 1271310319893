// Dependencies
import React, { useEffect, useState } from "react";
import { firebaseFunctions, httpCallables } from "../../../firebase";
import { useHistory } from "react-router-dom";
import { useQuery } from "../../../hooks";

import { FormattedMessage, useIntl } from "react-intl";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { captureException } from "../../../utils/errorHandlers";
import { useFeatureFlags } from "../../../hooks/useFeatureFlags";
// Redux Dependencies
import { useSelector, useDispatch } from "react-redux";
import { enqueueFlashMessage, undo } from "../../../redux/userSlice";
import { selectCourse } from "../../../redux/coursesSlice";
import {
  selectTasks,
  reorderTasks,
  selectCourseSubmissionsSortedByUserName,
  fetchTeacherCourseTasks,
  STATUS
} from "../../../redux/tasksSlice";
//Components
import TasksListItem from "./TasksListItem";
import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Button,
  Typography,
  List,
  Divider,
  IconButton
} from "@mui/material";
import PangeaSpinner from "../../SharedComponents/PangeaSpinner";
import { selectAlertsDuration } from "../../../redux/firestoreSelectors";
import ExportModal from "./courseMaterialExport/ExportModal";
import { COURSE_MATERIAL_TYPE } from "./courseMaterialExport/consts";
import { USER_TYPE, FEATURE_FLAGS, USER_ACTIONS } from "../../../consts";
import InsightsIcon from "@mui/icons-material/Insights";
import { TooltipWithIntl } from "../../SharedComponents";
import { userAPI } from "../../../api";

const useStyles = makeStyles((theme) => ({
  wideCell: {
    width: "80%"
  },
  left: {
    textAlign: "left"
  },
  tableRow: {
    height: "52px"
  },
  studenBox: {
    marginLeft: "56px"
  },
  tableContainer: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "48px",
    marginBottom: "24px"
  },
  taskContainer: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    marginBlockStart: theme.spacing(6),
    marginBlockEnd: theme.spacing(3)
  },
  tasksHeader: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: theme.spacing(7.5),
    marginBottom: theme.spacing(5)
  },
  tasks: {
    backgroundColor: "transparent",
    boxShadow: "none",
    border: "none",
    "& .MuiAccordionSummary-content": {
      alignItems: "center",
      justifyContent: "space-between",
      order: 2
    }
  }
}));

export default function TasksTeacherView() {
  //Hooks
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const { task_id } = useQuery();
  const selectedCourseId = useSelector(
    (state) => state.user.userProfile?.selectedCourse?.id
  );
  const isAnalyticsEnabled = useFeatureFlags(FEATURE_FLAGS.TEACHER_ANALYTICS);

  const userId = useSelector((state) => state.firebase.auth.uid);

  // Redux State
  const shouldUndo = useSelector((state) => state.user.undo);
  const alertsDuration = useSelector((state) => selectAlertsDuration(state));

  const course = useSelector((state) => selectCourse(state, selectedCourseId));
  const tasks = useSelector((state) =>
    selectTasks(state, selectedCourseId?.toString())
  );
  const submissions = useSelector((state) =>
    selectCourseSubmissionsSortedByUserName(state, selectedCourseId)
  );
  const status = useSelector((state) => state.tasks.status);
  const courseTexts = useSelector((state) => state.texts.texts);

  // Ephemeral State
  const [taskGraders, setTaskGraders] = useState(false);
  const [undoData, setUndoData] = useState(null);
  const [exportModal, setExportModal] = useState(false);
  const [courseAndTeachers, setCourseAndTeachers] = useState([]);
  //TODO: this is duplicated code from Library
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEnd(result) {
    const { source, destination } = result;

    // dropped outside the list
    if (!result.destination) return;
    // dropped in the same place
    if (destination.index === source.index) return;
    const items = reorder(tasks, source.index, destination.index);
    //call function for reorder:
    let taskFuncions = firebaseFunctions.httpsCallable("tasks-taskFunctions");

    taskFuncions({
      func_name: "reorderTasks",
      course: course.id,
      tasks: items
    }).catch((err) => {
      captureException(
        err,
        `Failed to update teacher's tasks order. courseId ${course.id}`
      );
    });
    dispatch(reorderTasks(items));
  }
  useEffect(() => {
    dispatch(fetchTeacherCourseTasks({ course_id: selectedCourseId }));
  }, [dispatch, selectedCourseId]);

  // Behavior
  // Invoke the undo logic when the Redux undo flag is true
  useEffect(() => {
    const undoDeleteTask = () => {
      if (undoData?.type === "deleteTask") {
        let saveTask = firebaseFunctions.httpsCallable("courses-saveTask");
        saveTask({
          ...undoData.task,
          questions: undoData.task.questions.questions,
          answers: undoData.task.answers.answers,
          id: 0
        }).then(() => {
          // refreshTasks();
        });
      }
    };

    if (shouldUndo) {
      undoDeleteTask();
      dispatch(undo(false));
      // OPTION: change this when implementing multiple undos
      setUndoData(null);
    }
  }, [
    shouldUndo,
    dispatch,
    undoData
    // refreshTasks
  ]);

  // Show flash message when there is undo data
  useEffect(() => {
    if (undoData) {
      dispatch(
        enqueueFlashMessage({
          message: intl.formatMessage({
            id: "task.deleted",
            defaultMessage: "Task deleted"
          }),
          duration: alertsDuration,
          undoButton: true
        })
      );
    }
  }, [undoData, dispatch, intl]);

  useEffect(() => {
    const courseId = selectedCourseId;
    httpCallables
      .coursesFunctions({
        func_name: "getCourseInstitutionId",
        course_id: courseId
      })
      .then((response) => {
        const { institution_id } = response.data;
        httpCallables
          .coursesFunctions({
            func_name: "getAllCoursesWithTeachersByInstitutionId",
            institution_id: institution_id
          })
          .then((response) => {
            const { data } = response;
            const filteredCourses = data.filter(
              (course) => course.id !== selectedCourseId
            );
            setCourseAndTeachers(filteredCourses);
          });
      });
  }, [selectedCourseId]);

  const renderAnalyticsButton = () => {
    if (isAnalyticsEnabled) {
      return (
        <Button
          variant="outlined"
          color="primary"
          size="medium"
          sx={{
            marginInlineEnd: "8px"
          }}
          onClick={() => {
            history.push(`/CourseExternalReport?course_id=${course.id}`);
          }}>
          <FormattedMessage
            id="course.reports.button"
            defaultMessage="Analytics"
          />
        </Button>
      );
    } else return null;
  };

  if (status === STATUS.PENDING) {
    return <PangeaSpinner />;
  } else if (task_id) {
    const task = tasks.filter((task) => task.id == task_id)[0];
    const relevantSubmissions = submissions
      .filter(
        (submission) =>
          submission.task_id == task_id &&
          (submission.role?.toUpperCase?.() === USER_TYPE.STUDENT ||
            submission.owner === userId)
      )
      .sort((a, b) => {
        // Show teacher on top
        if (a.role?.toUpperCase?.() === USER_TYPE.TEACHER) return -1;
        else return 1;
      });
    return (
      <Box className={classes.taskContainer} key={task_id}>
        {task ? (
          <TasksListItem
            course={course}
            openTasks={task_id}
            toggleTask={false}
            task={task}
            submissions={relevantSubmissions}
            singleItemView={true}
          />
        ) : (
          <PangeaSpinner />
        )}
      </Box>
    );
  } else {
    return (
      <>
        <Box className={classes.tasksHeader}>
          <Box sx={{ display: "flex", flexFlow: "row nowrap" }}>
            <Typography variant="h5" sx={{ marginInlineEnd: "16px" }}>
              {course.name}
            </Typography>
          </Box>
          <Box sx={{ minWidth: "fit-content" }}>
            {renderAnalyticsButton()}
            {tasks.length ? (
              <Button
                variant="outlined"
                color="primary"
                size="medium"
                sx={{
                  marginInlineEnd: "8px"
                }}
                onClick={() => {
                  setExportModal(true);
                }}>
                <FormattedMessage id="tasks.share" defaultMessage="Share" />
              </Button>
            ) : (
              <></>
            )}
            <Button
              variant="contained"
              color="primary"
              size="medium"
              onClick={() => {
                history.push(`/tasks/new?course_id=${course.id}`);
                userAPI.logAction({
                  action_name: USER_ACTIONS.TASK_CREATION_SESSION,
                  payload: { course_id: course.id }
                });
              }}>
              <FormattedMessage id="tasks.create" defaultMessage="Create" />
            </Button>
          </Box>
        </Box>

        <Divider />
        {!tasks.length ? (
          <Typography variant="h6">
            There are currently no tasks for this course
          </Typography>
        ) : (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={"1"} direction="vertical">
              {(provided) => {
                return (
                  <List
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className={classes.tasks}>
                    {tasks.map((task, index) => {
                      return (
                        <TasksListItem
                          key={task.id}
                          course={course}
                          task={task}
                          taskIndex={index}
                          graders={taskGraders}
                          submissions={submissions
                            .filter(
                              (submission) =>
                                submission.task_id === task.id &&
                                submission.related_submission_id === null &&
                                (submission.role?.toUpperCase?.() ===
                                  USER_TYPE.STUDENT ||
                                  submission.owner === userId)
                            )
                            .sort((a, b) => {
                              // Show teacher on top
                              if (a.role?.toUpperCase?.() === USER_TYPE.TEACHER)
                                return -1;
                              else return 1;
                            })}
                          singleItemView={false}
                        />
                      );
                    })}
                    {provided.placeholder}
                  </List>
                );
              }}
            </Droppable>
          </DragDropContext>
        )}
        <ExportModal
          exportModal={exportModal}
          setExportModal={setExportModal}
          tasks={tasks}
          courseTexts={courseTexts}
          type={COURSE_MATERIAL_TYPE.TASK}
          courseAndTeachers={courseAndTeachers}
        />
      </>
    );
  }
}
