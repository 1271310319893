import React from "react";
import { Button } from "@mui/material";

export default function RedirectIframe() {
  const openLinkInNewWindow = () => {
    window.open(window.location.href, "_blank");
  };

  return (
    <div>
      {
        <Button
          sx={{ m: 2 }}
          variant="contained"
          color="primary"
          onClick={openLinkInNewWindow}>
          Open Alethea in A New Tab
        </Button>
      }
    </div>
  );
}
