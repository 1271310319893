// Dependencies
import React, { useState, useEffect } from "react";
import { TABLE_VIEW } from "../consts";
import { isEmpty } from "lodash";
import { transformDataPerAssignment, aggregateTaskData } from "../utils";
// Components
import { CustomIconButton } from "../CustomToggleButton";
import PieChart from "../pieChart/PieChart";
import StackedGraph from "../stackedGraph/StackedGraph";
// Redux

// Material-UI
import { Box, Typography } from "@mui/material";
import PieChartOutlineIcon from "@mui/icons-material/PieChartOutline";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import { useGetTheme } from "../../../../hooks";

const TimeOnAssignmentReport = ({
  data,
  courseStudents,
  courseAssignments
}) => {
  // Hooks
  const theme = useGetTheme();
  // Redux

  //Ephemeral state
  const [timeView, setTimeView] = useState(TABLE_VIEW.COURSE_VIEW);
  const courseView = timeView === TABLE_VIEW.COURSE_VIEW;
  const [taskBasedTimeSpentOnAssignment, setTaskBasedTimeSpentOnAssignment] =
    useState({});
  const [courseViewTimeSpentOnAssignment, setCourseViewTimeSpentOnAssignment] =
    useState({});

  // Behavior

  const handleSubmissionsViewChange = (view) => {
    setTimeView(view);
  };

  useEffect(() => {
    if (Object.keys(data).length === 0) return;
    const taskData = transformDataPerAssignment(data);
    setTaskBasedTimeSpentOnAssignment(taskData);
    const courseData = aggregateTaskData(taskData);
    setCourseViewTimeSpentOnAssignment(courseData);
  }, [data]);

  return (
    <Box
      sx={{
        paddingInline: "24px",
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.border.secondary}`,
        height: courseView ? "400px" : "500px",
        borderRadius: "5px"
      }}>
      {/* HEADER */}
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "60px",
          flexFlow: "row nowrap",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: `1px solid ${theme.palette.border.secondary}`
        }}>
        <Typography variant="subtitle1">
          Average time spent on assignments step
        </Typography>
      </Box>

      {/* TABLE */}
      <Box
        sx={{
          display: "flex",
          flexFlow: "column nowrap",
          width: "inherit",
          height: "100%"
        }}>
        <Box sx={{ paddingTop: "16px" }}>
          <CustomIconButton
            icon={PieChartOutlineIcon}
            label="Course view"
            isActive={courseView}
            onClick={() => handleSubmissionsViewChange(TABLE_VIEW.COURSE_VIEW)}
          />
          <CustomIconButton
            icon={InsertChartIcon}
            label="Assignment view"
            isActive={!courseView}
            onClick={() =>
              handleSubmissionsViewChange(TABLE_VIEW.ASSIGNMENT_VIEW)
            }
          />
        </Box>
        <Box>
          {" "}
          <Box sx={{ height: "80%" }}>
            {courseView ? (
              <PieChart
                type="timeSpentOnAssignment"
                data={courseViewTimeSpentOnAssignment}
                totalSubmissions={Object.values(
                  courseViewTimeSpentOnAssignment
                ).reduce((acc, curr) => acc + curr, 0)}
              />
            ) : (
              <StackedGraph
                type="timeSpentOnAssignment"
                data={taskBasedTimeSpentOnAssignment}
                courseAssignments={courseAssignments}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TimeOnAssignmentReport;
