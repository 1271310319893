// Dependencies
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import clsx from "clsx";

// Redux dependencies
import { useSelector } from "react-redux";

import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import { selectTextDirection } from "../../../redux/firestoreSelectors";

//Styles
const useStyles = makeStyles((theme) => ({
  // this is styling the paper component within the dialog
  modal: {
    position: "relative",
    width: "50%",
    padding: "10px",
    textAlign: "center"
  },
  modalCancel: {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer"
  },
  dialogTxt: {},
  modalActions: {
    justifyContent: "center",
    "& button": {
      fontSize: "inharit",
      fontWeight: "600"
    }
  },
  buttonDiscard: {
    color: "#787877"
  },
  button: { textTransform: "none" }
}));

export default function FeedbackValidationModal({
  dialogOpen,
  setDialogOpen,
  submitFeedback
}) {
  // Hooks
  const classes = useStyles();
  const intl = useIntl();

  //Redux State
  const textDirection = useSelector((state) => selectTextDirection(state));

  // Ephemeral State

  // Behavior

  const closeModal = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <div>
        <Dialog
          open={dialogOpen}
          onClose={closeModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            className: classes.modal,
            style: {
              direction: textDirection
            }
          }}>
          <DialogTitle id="alert-dialog-title">
            <FormattedMessage
              id="task.feedback.submit"
              defaultMessage="Send Feedback"
            />
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              className={classes.dialogTxt}>
              You didn&apos;t add a comment on the peer review task
              <Box>
                {" "}
                {intl.formatMessage({
                  id: "task.feedback.confirmation",
                  defaultMessage: "Do you want to proceed anyway?"
                })}
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.modalActions}>
            <Button
              onClick={closeModal}
              className={clsx(classes.buttonDiscard, classes.button)}>
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </Button>
            <Button
              className={classes.button}
              onClick={() => {
                setDialogOpen(false);
                submitFeedback();
              }}
              color="primary"
              // autoFocus
            >
              <FormattedMessage
                id="button.next"
                defaultMessage="Proceed anyway"
              />
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
