// Dependencies
import React from "react";
import { useIntl } from "react-intl";

// Redux dependencies
import { useSelector } from "react-redux";

// Components
import LanguageSelect from "./LanguageSelect";
import AlertsDurationSelect from "./AlertsDurationSelect";

import makeStyles from "@mui/styles/makeStyles";
import { Typography, Divider, TextField, Grid } from "@mui/material";

//Styles
const useStyles = makeStyles((theme) => ({
  header: {
    marginBlockEnd: theme.spacing(2)
  },
  form: {
    marginBlockStart: theme.spacing(6)
  }
}));

export default function ProfilePageForm() {
  //Hooks
  const classes = useStyles();
  const intl = useIntl();

  //Redux state
  const currentUser = useSelector((state) => state.user.auth);
  const isAdminOrDataViewer = currentUser.isAdmin || currentUser.isDataViewer;

  //Ephemeral state

  //Variables

  //Behavior

  // Render
  return (
    <>
      <Typography className={classes.header} variant="h6">
        {intl.formatMessage({
          id: "profilePage.myProfile",
          defaultMessage: "My profile"
        })}
      </Typography>
      <Divider />
      <form className={classes.form} noValidate autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={isAdminOrDataViewer ? 6 : 12}>
            <TextField
              disabled
              fullWidth
              className="sentry-mask"
              id="profile-name"
              label={intl.formatMessage({
                id: "profilePage.name",
                defaultMessage: "Name"
              })}
              variant="outlined"
              defaultValue={currentUser.displayName}
            />
          </Grid>
          {isAdminOrDataViewer && (
            <Grid item xs={6}>
              <TextField
                disabled
                fullWidth
                id="User role"
                label={intl.formatMessage({
                  id: "roles.userRole",
                  defaultMessage: "User role"
                })}
                variant="outlined"
                defaultValue={currentUser.isAdmin ? "Admin" : "Data Viewer"}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <LanguageSelect />
          </Grid>
          <Grid item xs={6}>
            <AlertsDurationSelect />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              fullWidth
              id="profile-email"
              label={intl.formatMessage({
                id: "profilePage.email",
                defaultMessage: "Email"
              })}
              variant="outlined"
              defaultValue={currentUser.email}
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
}
