// Dependancies
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

import VisibilityIcon from "@mui/icons-material/Visibility";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import {
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  IconButton,
  Box,
  Tooltip,
  Typography,
  Button
} from "@mui/material";

import { ScrollBox } from "../SharedComponents";

function InstitutionsList({ institutions }) {
  const history = useHistory();
  const intl = useIntl();

  return (
    <ScrollBox>
      <Box sx={{ padding: "40px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px"
          }}>
          <Typography variant="h4">
            {intl.formatMessage({
              id: "admin.institutionsList", // FIXME
              defaultMessage: "Institutions"
            })}
          </Typography>
        </Box>
        <TableContainer sx={{ width: "800px" }}>
          <Table aria-label="course table">
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Name</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {institutions.map((institution) => (
                <TableRow key={institution.id}>
                  <TableCell sx={{ width: "100px" }}>
                    {institution.id}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "600px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }}>
                    <Tooltip title={institution.name}>
                      <Box>{institution.name}</Box>
                    </Tooltip>
                  </TableCell>
                  <TableCell sx={{ width: "100px" }}>
                    <Box sx={{ display: "flex" }}>
                      <IconButton
                        onClick={() => {
                          history.push(
                            `admin/institutionActivityReport?institution_id=${institution.id}`
                          );
                        }}
                        size="large">
                        <InsertChartIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </ScrollBox>
  );
}

InstitutionsList.propTypes = {
  institutions: PropTypes.array.isRequired
};

export default InstitutionsList;
