import React from "react";
import { useSelector } from "react-redux";
import { selectTextDirection } from "../../redux/firestoreSelectors";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import makeStyles from "@mui/styles/makeStyles";

// Styles
const useStyles = makeStyles((theme) => ({
  icon: {
    color: "currentcolor"
  }
}));

export default function DirectionalChevronIcon({ fontSize, reverse = false }) {
  const classes = useStyles();
  const textDirection = useSelector((state) => selectTextDirection(state));

  if (textDirection === "rtl") {
    if (reverse) {
      return <ChevronRightIcon className={classes.icon} fontSize={fontSize} />;
    } else
      return <ChevronLeftIcon className={classes.icon} fontSize={fontSize} />;
  } else {
    if (reverse) {
      return <ChevronLeftIcon className={classes.icon} fontSize={fontSize} />;
    }
    return <ChevronRightIcon className={classes.icon} fontSize={fontSize} />;
  }
}
