// dependancies
import { captureException } from "../../../../utils/errorHandlers";
import {
  FEATURES,
  FEATURE_FLAGS,
  INTERACTION_SUBTYPES,
  TASK
} from "../../../../consts";
import { questionHasHighlights } from "../../eMentorEpics/utils";

// Redux
import { CHATBOX_STATUS } from "../../../chatSlice";
import { hideFab, showFab, FAB_TYPE } from "../../../fabSlice";

// Rx
import { ofType } from "redux-observable";
import { interval, of, EMPTY } from "rxjs";
import {
  map,
  withLatestFrom,
  delayWhen,
  switchMap,
  catchError,
  filter
  //tap
} from "rxjs/operators";
import { setSelectedInteractionId } from "../../../interactionsSlice";
import { determineFeatureByPathname } from "../../../firestoreSelectors";
import { selectTask } from "../../../tasksSlice";

export const displayTaskFabEpic = (action$, state$) => {
  const GOT_ANSWERS = "gotAnswers";
  const SET_SELECTED_INTERACTION_ID = "setSelectedInteractionId";
  const OPEN_CHAT = "chat/openChatbox";
  const CLOSE_CHAT = "chat/closeChatbox";
  const LOCATION_CHANGE = "@@router/LOCATION_CHANGE";

  const isReader =
    determineFeatureByPathname(
      state$.value.router.location.pathname
    ).toUpperCase() === FEATURES.READER;
  return action$.pipe(
    // These types are coming from a firebase middleware. The upload flow is currently messy and should be changed

    ofType(
      LOCATION_CHANGE,
      OPEN_CHAT,
      CLOSE_CHAT,
      SET_SELECTED_INTERACTION_ID,
      GOT_ANSWERS,
      setSelectedInteractionId
    ),
    withLatestFrom(state$),
    switchMap(([action, state]) =>
      shouldDisplayFab(state, action).pipe(
        delayWhen((shouldDisplay) => interval(shouldDisplay ? 500 : 0))
      )
    ),
    map((shouldDisplay) => ({
      type: shouldDisplay ? showFab.type : hideFab.type,
      payload: {
        type: isReader ? FAB_TYPE.MENTOR_READER : FAB_TYPE.MENTOR_REVIEW
      }
    })),
    catchError((error) => {
      captureException(error, "Error in displayTaskFabEpic");
      return EMPTY;
    })
  );
};
// utils
function shouldDisplayFab(state, action) {
  const submissionId = Number(state.router.location.query.submission_id);
  const submission = state.tasks.submissions.find(
    (submission) => submission.id === submissionId
  );
  const task = selectTask(state, submission?.task_id);
  const selectedInteraction = state.interactions.selectedInteractionId;
  const questions = state.interactions.questions;
  const questionType = getQuestionType(selectedInteraction, questions);

  const isChatRelatedQuestion =
    questionType === INTERACTION_SUBTYPES.OPEN_ENDED;
  const isNotSubmitted =
    submission?.status !== TASK.SUBMISSION_STATUS.SUBMITTED &&
    submission?.status !== TASK.SUBMISSION_STATUS.GRADED;
  const taskStep = action.payload?.step || state.task?.step;
  const readerStepReview =
    state.gr.stage === 1 || state.gr.stage === TASK.STEPS.REVIEW;
  const isReviewStep = taskStep === TASK.STEPS.REVIEW;
  const hasSelectedInteraction = !!selectedInteraction;
  const hasHighlights = questionHasHighlights(state);
  const isChatClosed = state.chat.status === CHATBOX_STATUS.CLOSED;
  const isChatEnabled = task.bot_mode !== "disabled";

  const pathname = state.router.location.pathname;
  const isReader = determineFeatureByPathname(pathname) === FEATURES.READER;
  // Conditions for displaying the chat FAB
  const showFabTask =
    isNotSubmitted &&
    isReviewStep &&
    hasSelectedInteraction &&
    hasHighlights &&
    isChatClosed &&
    isChatRelatedQuestion &&
    isChatEnabled;
  const showFabReader =
    isReader &&
    readerStepReview &&
    isChatRelatedQuestion &&
    hasSelectedInteraction &&
    hasHighlights;
  return of(showFabTask || showFabReader);
}

function getQuestionType(questionId, questions) {
  if (!questions.length || !questionId) return null;

  const question = questions.find((question) => question.id === questionId);
  return question?.interaction_subtype || null;
}
