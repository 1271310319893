import React from "react";
import { Box, Typography } from "@mui/material";

const InstitutionOverview = ({
  activeCourses,
  publishedAssignments,
  activeStudents,
  submissionRate
}) => {
  const items = [
    {
      title: "Active Courses",
      value: activeCourses
    },
    {
      title: "Published Assignments",
      value: publishedAssignments
    },
    {
      title: "Active Students",
      value: activeStudents
    },
    {
      title: "Submission Rate",
      value: `${Math.round(submissionRate * 100)}%`
    }
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        margin: 0,
        padding: 0
      }}>
      {items.map((item, index) => (
        <Box
          key={index}
          sx={{
            flex: "1 1 calc(25% - 16px)",
            marginInlineEnd: index % 4 !== 3 ? "16px" : 0,
            height: "130px",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "border.main",
            borderRadius: "5px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "background.paper"
          }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexFlow: "column",
              height: "100%",
              paddingTop: "18px",
              paddingBottom: "18px"
            }}>
            <Typography variant="p" sx={{}}>
              {item.title}
            </Typography>
            <Typography variant="h4">{item.value}</Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default InstitutionOverview;
