// Dependancies
import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { accessorPropsType, callAccessor } from "../utils";

import makeStyles from "@mui/styles/makeStyles";

// Styles
const useStyles = makeStyles((theme) => ({
  circle: {
    fill: theme.palette.text.primary,
    stroke: theme.palette.text.primary
  },
  animate: { transition: "all 0.3s ease-out" },
  secondary: {
    fill: theme.palette.primary.main,
    stroke: theme.palette.primary.main
  },
  primary: {
    fill: theme.palette.secondary.main,
    stroke: theme.palette.secondary.main
  },
  outlined: {
    fill: theme.palette.background.paper,
    strokeWidth: 3
  }
}));

function Circles({
  data = [],
  keyAccessor,
  xAccessor,
  yAccessor,
  radius,
  color,
  variant,
  onMouseEnter,
  onMouseLeave,
  animate = false
}) {
  //Hooks
  const classes = useStyles();
  return (
    <>
      {data.map((d, i) => {
        const scaledColor = callAccessor(color, d);
        const scaledVariant = callAccessor(variant, d);
        const cx = xAccessor(d, i);
        const cy = yAccessor(d, i);
        return (
          <circle
            className={clsx(
              classes.circle,
              animate && classes.animate,
              color && classes[scaledColor],
              variant && classes[scaledVariant]
            )}
            key={keyAccessor(d, i)}
            cx={cx}
            cy={cy}
            r={callAccessor(radius, d)}
            onMouseEnter={(e) => onMouseEnter(e, d, cx, cy)}
            onMouseLeave={() => onMouseLeave(d)}
          />
        );
      })}
    </>
  );
}

Circles.propTypes = {
  data: PropTypes.array,
  keyAccessor: accessorPropsType,
  xAccessor: accessorPropsType,
  yAccessor: accessorPropsType,
  radius: accessorPropsType
};

Circles.defaultProps = {
  radius: 3
};

export default Circles;
