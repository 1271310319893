// Dependancies

import React, { useRef, useState } from "react";
import _ from "lodash";

// Components
import ChartFilterMenu from "./ChartFilterMenu";
// Mui
import {
  Box,
  Typography,
  IconButton,
  Popper,
  Grow,
  Paper,
  MenuList,
  MenuItem,
  ClickAwayListener,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  ListItemText
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import FilterListIcon from "@mui/icons-material/FilterList";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// styles
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexFlow: "row",
    justifyContent: "space-between",
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    alignItems: "center"
  },
  menu: {},
  filterDetails: {
    maxHeight: 250,
    overflowY: "scroll"
  },
  filterItem: {
    paddingInline: 0
  }
}));

const EngagementChartHeader = ({ filters = {}, dispatchFilters, title }) => {
  // hooks
  const classes = useStyles();
  const menuAnchorRef = useRef(null);

  // Ephemeral state
  const [menuOpen, setMenuOpen] = useState(false);
  const [expandedFilter, setExpandedFilter] = React.useState(false);

  // DerivedState
  const filterGroups = Object.keys(filters);
  // Behavior;
  function toggleMenu(e) {
    if (e.currentTarget === menuAnchorRef.current)
      setMenuOpen((prevState) => !prevState);
  }
  const expandFilter = (e, isExpanded) => {
    const filterGroup = e.currentTarget?.id;
    setExpandedFilter(isExpanded ? filterGroup : false);
  };

  const closeMenu = (event) => {
    if (menuAnchorRef.current && menuAnchorRef.current.contains(event.target)) {
      return;
    }

    setMenuOpen(false);
  };

  function getFilterItemsByOrder(filtersObj) {
    const keys = Object.keys(filtersObj);
    const sortedKeys = keys.sort(
      (a, b) => filtersObj[a]?.order - filtersObj[b]?.order
    );

    return sortedKeys;
  }
  return (
    <Box className={classes.container}>
      <Typography variant="h5">{title}</Typography>
      <IconButton ref={menuAnchorRef} onClick={toggleMenu} size="small">
        <FilterListIcon />
      </IconButton>
      <ClickAwayListener onClickAway={closeMenu}>
        <Popper
          open={menuOpen}
          placement="bottom-end"
          anchorEl={menuAnchorRef.current}
          role={undefined}
          transition
          modifiers={[
            {
              name: "flip",
              options: {
                fallbackPlacements: []
              }
            }
          ]}>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}>
              <Paper className={classes.menu}>
                {filterGroups.map((filterGroup) => {
                  const filterGroupItems = filters[filterGroup].value;
                  const filterGroupName = filters[filterGroup].displayName;
                  // const filterGroupItem = filters[filterGroup];

                  const filterItems = getFilterItemsByOrder(filterGroupItems);
                  return (
                    <Accordion
                      key={filterGroup}
                      expanded={expandedFilter === filterGroup}
                      onChange={expandFilter}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        id={filterGroup}>
                        <Typography className={classes.heading}>
                          {filterGroupName}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails className={classes.filterDetails}>
                        <MenuList>
                          {filterItems.map((filterItem) => {
                            const current = filterGroupItems[filterItem];
                            return (
                              <MenuItem
                                className={classes.filterItem}
                                key={filterItem}
                                value={filterItem} //SESSIONS
                                onClick={() =>
                                  dispatchFilters({
                                    type: "update",
                                    payload: {
                                      filter: filterGroup,
                                      item: filterItem,
                                      update: !current["value"]
                                    }
                                  })
                                }>
                                <Checkbox checked={current["value"]} />
                                <ListItemText
                                  primary={current["displayName"]}
                                />
                              </MenuItem>
                            );
                          })}
                        </MenuList>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </Paper>
            </Grow>
          )}
        </Popper>
      </ClickAwayListener>
    </Box>
  );
};

export default EngagementChartHeader;
