// Dependencies
import React, { useRef, useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import clsx from "clsx";
import { standardTaskAPI, interactionsAPI } from "../../../../api";
import { isEmpty } from "lodash";
import { captureException } from "../../../../utils/errorHandlers";
import { useQuery } from "../../../../hooks";
import {
  removeAllAnotationsOfType,
  getHighlightColor
} from "../../../reader/utils";
import {
  ANNOTATION_TYPES,
  INTERACTION_SUBTYPES,
  INTERACTION_TYPES,
  appActions
} from "../../../../consts";

// Redux Dependencies
import { useSelector, useDispatch } from "react-redux";
import {
  setLinkType,
  resetLink,
  setLinkContent
} from "../../../../redux/LinkSlice";
import ReactReaderActions from "../../../annotations/ReactReaderActions";
import { selectCurrentText } from "../../../../redux/textsSlice";
import { closeAnnotatorBar } from "../../../../redux/highlightSlice";
import {
  selectQuestionHighlights,
  selectAnswerComment,
  updateInteraction
} from "../../../../redux/interactionsSlice";
import { selectSubmission } from "../../../../redux/tasksSlice";

//Components
import { useRendition } from "../../../../RenditionContext";
import {
  TextEditor,
  TextEditorInput,
  TextEditorHeader,
  TextEditorFooter
} from "../../../SharedComponents/textEditor";
import DialogContentBook from "../../../SharedComponents/DialogContentBook";

import makeStyles from "@mui/styles/makeStyles";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import {
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography
} from "@mui/material";
import { ScrollBox } from "../../../SharedComponents";
import { showInText } from "../../../../utils/showInTextHelper";
import {
  selectDarkMode,
  selectTextDirection
} from "../../../../redux/firestoreSelectors";
import TooltipWithIntl from "../../../SharedComponents/tooltip/TooltipWithIntl";
import EmptyState from "../../../SharedComponents/emptyState/EmptyState";

// Styles
const useStyles = makeStyles((theme) => ({
  scrollBox: {
    height: "calc(100vh - 120px)",
    display: "flex",
    alignItems: "center"
  },
  container: {
    height: "calc(100vh - 120px)",
    width: 640,
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    paddingBlock: theme.spacing(8),
    justifyContent: "space-between"
  },
  editor: {
    minHeight: 240,
    flexGrow: 1
  },
  dialog: {
    zIndex: 10
  },
  dialogActions: {
    justifyContent: "left"
  },
  dialogActionsRtl: {
    justifyContent: "right"
  },
  dialogBtn: {
    position: "absolute",
    color: "#ffffff",
    right: "16px"
  },
  dialogTitle: {
    color: "#ffffff"
  }
}));

export default function OpenEnded({ question }) {
  //Hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const { submission_id } = useQuery();
  const epubElemRef = useRef();
  const epubRef = useRef();
  const intl = useIntl();

  // Redux state
  const submission = useSelector((state) =>
    selectSubmission(state, Number(submission_id))
  );
  const currentQuestionId = useSelector(
    (state) => state.interactions.selectedInteractionId
  );
  const answer = useSelector((state) =>
    selectAnswerComment(state, currentQuestionId)
  );
  const highlights = useSelector((state) =>
    selectQuestionHighlights(state, currentQuestionId)
  );
  const user_id = useSelector((state) => state.user.auth.uid);

  const text = useSelector(selectCurrentText);
  const textDirection = useSelector((state) => selectTextDirection(state));
  const darkMode = useSelector((state) => selectDarkMode(state));

  //Ephemeral State
  const [openDialog, setOpenDialog] = useState(false);
  const { rendition } = useRendition();
  const [linksCfi, setLinksCfi] = useState([]);
  const [wordCount, setWordCount] = useState(0);
  //Behavior

  useEffect(() => {
    if (!rendition || !highlights) return;

    removeAllAnotationsOfType(rendition, ANNOTATION_TYPES.HIGHLIGHT);
    // render highlights from redux
    highlights.forEach((highlight) => {
      const cfi = highlight.cfi;
      const color = highlight.color;
      rendition.annotations.add(
        ANNOTATION_TYPES.HIGHLIGHT,
        cfi,
        { id: highlight.id },
        () => {},
        ANNOTATION_TYPES.HIGHLIGHT.toLowerCase(),
        {
          "z-index": 20,
          "mix-blend-mode": "multiply",
          "fill-opacity": 0.8,
          fill: getHighlightColor(color, darkMode)
        }
      );
    });
  }, [darkMode, highlights, rendition]);

  useEffect(() => {
    if (answer.word_count > 0) {
      setWordCount(answer.word_count);
    } else setWordCount(0);
  }, [question, answer]);

  const addHighlightToAnswer = (cfi) => {
    setLinksCfi([...linksCfi, cfi]);
  };

  const onRenditionCreated = (rendition) => {
    if (rendition) {
      answer &&
        answer.quotes &&
        answer.quotes.map((quote, i) => {
          let hlStyleObj = {
            "z-index": 10,
            "mix-blend-mode": "multiply",
            "fill-opacity": 1,
            fill: quote.color
          };
          try {
            rendition.annotations.highlight(
              quote.cfi,
              {},
              (e) => {},
              "highlightClass",
              hlStyleObj
            );
          } catch (err) {
            captureException(err);
          }

          return 1;
        });
    }
  };

  function removeHighlight(highlight) {
    interactionsAPI.deleteHighlight(highlight);
  }
  const onTextSelected = (params) => {
    if (openDialog !== 1) return;
    if (params.shouldClose) {
      dispatch(closeAnnotatorBar());
    } else {
      addHighlightToAnswer(params.selection.cfi);
      let content = rendition && rendition.getContents()[0];

      if (content && content.window && content.window.getSelection()) {
        content.window.getSelection().empty();
      }
    }
  };
  const renderTextLinkDialog = () => {
    return (
      <Dialog
        open={Boolean(openDialog)}
        PaperProps={{
          style: {
            direction: textDirection,
            width: "90%",
            maxWidth: "90%"
          }
        }}
        aria-labelledby="form-dialog-title">
        <DialogTitle
          className={classes.dialogTitle}
          id="form-dialog-title"
          sx={{
            background: submission_id
              ? "rgba(144, 202, 249, 1)"
              : "rgba(94, 200, 145, 1)"
          }}>
          <DialogActions
            className={clsx(
              classes.dialogActions,
              textDirection === "rtl" && classes.dialogActionsRtl
            )}>
            <Typography className={classes.dialogHeader}></Typography>
            <Button
              variant="outlined"
              className={classes.dialogBtn}
              onClick={() => {
                setOpenDialog(false);
                if (linksCfi.length > 0) {
                  dispatch(setLinkType("text"));
                  dispatch(setLinkContent({ cfis: linksCfi }));
                } else {
                  dispatch(resetLink());
                }
                setLinksCfi([]);
                epubRef.current && epubRef.current.book.destroy();
              }}>
              <FormattedMessage defaultMessage="Done" id="gr.confirm.btn" />
            </Button>
          </DialogActions>
        </DialogTitle>
        <DialogContent ref={epubElemRef} className={classes.dialog}>
          <ReactReaderActions
            id="reactReaderActions"
            minimal={true}
            canComment={false}
            highlightFunc={() => {}}
            deleteHighlight={removeHighlight}>
            <DialogContentBook
              highlights={highlights}
              onTextSelected={onTextSelected}
              onRenditionCreated={onRenditionCreated}
              removeHighlight={removeHighlight}
            />
          </ReactReaderActions>
        </DialogContent>
      </Dialog>
    );
  };

  function createAnswer(content) {
    const { richText, plainText, wordCount } = content;
    standardTaskAPI.createAnswerComment({
      content: plainText,
      rich_text: richText,
      word_count: wordCount,
      interaction_id: currentQuestionId,
      text_id: text.id,
      course_id: text.course_id,
      submission_id: submission.id,
      task_id: submission.task_id,
      interaction_type: INTERACTION_TYPES.ANSWER,
      interaction_subtype: INTERACTION_SUBTYPES.COMMENT
    });
  }

  function updateAnswer(answer, content) {
    const { richText, plainText, wordCount } = content;
    dispatch(
      updateInteraction({
        disableRollback: true, // we don't want to to erase users input if the request fails
        interaction: answer,
        update: {
          content: plainText,
          rich_text: richText,
          word_count: wordCount
        }
      })
    );
  }

  const showInTextHandler = () => {
    const cb = () => {
      setOpenDialog(2);
    };
    showInText(appActions.SHOULD_LOG, highlights[0], user_id, rendition, cb);
  };

  return Object.keys(question).length > 0 ? (
    <ScrollBox className={classes.scrollBox}>
      <Box className={classes.container}>
        <TextEditor
          content={answer.rich_text}
          className={classes.editor}
          disabled={submission.status === "Submitted"}>
          <TextEditorHeader
            title={intl.formatMessage({
              id: "task.type.open.inYouOwnWords",
              defaultMessage: "In your own words"
            })}>
            <TooltipWithIntl
              intlStringId="feedback.tooltip.showText"
              defaultMessage="Show text"
              placement="bottom">
              <span>
                <IconButton
                  className={classes.textIcon}
                  onClick={() => showInTextHandler()}
                  aria-label="show text"
                  size="large">
                  <LibraryBooksIcon />
                </IconButton>
              </span>
            </TooltipWithIntl>
          </TextEditorHeader>
          <TextEditorInput
            placeholder={intl.formatMessage({
              id: "task.open.placeholder",
              defaultMessage: "Type your written response here"
            })}
            onChange={(content) => {
              isEmpty(answer)
                ? createAnswer(content)
                : updateAnswer(answer, content);
            }}
            onEveryChange={(content) => {
              setWordCount(content.wordCount);
            }}
          />
          <TextEditorFooter
            wordLimit={question.wordLimit}
            wordCount={wordCount}
          />
        </TextEditor>
        {/* LEAVE THIS HERE UNTIL LINKS ARE RE IMPLEMENTED */}
        {/* <Tooltip
                title={intl.formatMessage({
                  id: "feedback.tooltip.link",
                  defaultMessage: "Add link"
                })}
                arrow
                placement={rtlValue ? "left" : "right"}
              >
                <span>
                  <IconButton
                    ref={linkBtnRef}
                    className={classes.textIcon}
                    disabled={!selectedFeedbackText}
                    onClick={() => {
                      setLinksCfi([]);
                      setOpenDialog(1);
                    }}
                    aria-label="add link"
                  >
                    <LinkIcon />
                  </IconButton>
                </span>
              </Tooltip> */}
        {openDialog && renderTextLinkDialog()}
      </Box>
    </ScrollBox>
  ) : (
    <EmptyState step="answer" />
  );
}
