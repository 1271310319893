import React, { useState } from "react";

import {
  renderTextItem,
  renderTasksItem,
  renderCourseAndTeacher
} from "./utils";

const ExportDataCheckbox = ({
  step,
  tasks,
  texts,
  selectedTexts,
  type,
  handleChange,
  courseAndTeachers,
  selectedTasks,
  onIncludeTextToggle,
  handleSelectAll,
  selectedCourses
}) => {
  const [filteredResults, setFilteredResults] = useState(courseAndTeachers);
  const [searchQuery, setSearchQuery] = useState(""); // State to manage search query
  const [hovered, setHovered] = useState(false);
  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchQuery(value);
    // Filter results based on search query
    const filtered = courseAndTeachers.filter(
      (item) =>
        item.name.toLowerCase().includes(value.toLowerCase()) ||
        item.teachers.some((teacher) =>
          teacher.toLowerCase().includes(value.toLowerCase())
        )
    );
    setFilteredResults(filtered);
  };

  return (
    <>
      {step === 0
        ? type === "texts"
          ? renderTextItem(texts, selectedTexts, handleChange, handleSelectAll)
          : renderTasksItem(
              tasks,
              texts,
              handleChange,
              setHovered,
              hovered,
              onIncludeTextToggle,
              selectedTasks,
              handleSelectAll
            )
        : renderCourseAndTeacher(
            courseAndTeachers,
            searchQuery,
            filteredResults,
            handleSearch,
            handleChange,
            handleSelectAll,
            selectedCourses
          )}
    </>
  );
};

export default ExportDataCheckbox;
