import { setActiveTaskPerText } from "../redux/firebaseMiddleware";

export const navigateToTaskHandler = async (
  task,
  submission,
  history,
  dispatch
) => {
  // dispatch(setAction("task"));
  const isCollaboration = submission.related_submission_id !== null;
  const submissionUrl = `/task?submission_id=${submission.id}`;
  const collaborationUrl = `/task?submission_id=${submission.related_submission_id}`;
  // Navigate to peerReview collaboration
  if (
    (isCollaboration && !submission.submission_date) ||
    (isCollaboration &&
      submission.status === "Submitted" &&
      !submission.is_checked)
  ) {
    dispatch(
      setActiveTaskPerText({
        text_id: task.text_id,
        taskUrl: collaborationUrl
      })
    );
    history.push(collaborationUrl);
  } else if (isCollaboration && submission.is_checked) {
    history.push(collaborationUrl);
  } else if (submission.is_checked) {
    history.push(submissionUrl);
    // Navigate to reader for GR task
  } else if (
    task.task_type === "peerReview" &&
    !submission.is_checked &&
    submission.status === "Submitted"
  ) {
    !submission.submission_date &&
      dispatch(
        setActiveTaskPerText({
          text_id: task.text_id,
          taskUrl: submissionUrl
        })
      );

    history.push(submissionUrl);

    // Navigate to teacher's feedback
  } else if (task.task_type === "guidedReading") {
    history.push(submissionUrl);
    // Direct to task
  } else {
    history.push(submissionUrl);
  }
};
