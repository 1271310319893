import React, { useState, useEffect, useReducer } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  Divider
} from "@mui/material";
import ExportStepsToggler from "./ExportStepsToggler";
import ExportDataCheckbox from "./ExportDataCheckbox";
import { ACCEPTENCE_STATUS, COURSE_MATERIAL_TYPE, stepTitle } from "./consts";
import { extractDataForTasksShare, extractIdsFromArray } from "./utils";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "../../../../hooks";
import { httpCallables } from "../../../../firebase";
import { v4 as uuid } from "uuid";
import { add } from "date-fns";
import { addSnackbarItem } from "../../../../redux/snackbarSlice";

const initialState = {
  texts: [],
  tasks: [],
  courses: []
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_SELECTED_TEXTS":
    case "SET_SELECTED_TASKS":
    case "SET_SELECTED_COURSES":
      return {
        ...state,
        [action.type.split("_")[2].toLowerCase()]: action.payload
      };
    default:
      return state;
  }
};

const ExportModal = ({
  exportModal,
  setExportModal,
  tasks: initialTasks = [],
  courseTexts,
  type,
  courseAndTeachers
}) => {
  const { course_id } = useQuery();
  const [step, setStep] = useState(0);

  const [nextEnabled, setNextEnabled] = useState(true);
  const [shareEnabled, setShareEnabled] = useState(false);

  const [state, dispatch] = useReducer(reducer, initialState);
  const storeDispatch = useDispatch();

  const userName = useSelector((state) => state.user.auth.displayName);
  const handleClose = () => {
    resetState();
    setStep(0);
    setExportModal(false);
  };

  const resetState = () => {
    dispatch({ type: "SET_SELECTED_TEXTS", payload: courseTexts });
    dispatch({ type: "SET_SELECTED_COURSES", payload: [] });
    dispatch({
      type: "SET_SELECTED_TASKS",
      payload: initialTasks.map((task) => ({ ...task, includeText: true }))
    });
  };

  useEffect(() => {
    if (type === COURSE_MATERIAL_TYPE.TASK) {
      setNextEnabled(state.tasks.length > 0);
    }
    if (type === COURSE_MATERIAL_TYPE.TEXT) {
      setNextEnabled(state.texts.length > 0);
    }
  }, [state.tasks, state.texts]);

  useEffect(() => {
    setShareEnabled(state.courses.length > 0);
  }, [state.courses]);

  const onIncludeTextToggle = (taskId) => {
    const updatedTasks = state.tasks.map((task) => {
      if (task.id === taskId) {
        return { ...task, includeText: !task.includeText };
      } else return task;
    });
    dispatch({ type: "SET_SELECTED_TASKS", payload: updatedTasks });
  };

  const handleChange = (event, item) => {
    const { name, checked } = event.target;
    const payload = checked
      ? [...state[name], { ...item, includeText: true }]
      : state[name].filter((selectedItemId) => selectedItemId.id !== item.id);
    dispatch({ type: `SET_SELECTED_${name.toUpperCase()}`, payload });
  };

  const handleSelectAll = (event) => {
    const { name, checked } = event.target;
    let payload;
    switch (name.toUpperCase()) {
      case "TASKS":
        payload = checked
          ? initialTasks.map((task) => ({ ...task, includeText: true }))
          : [];
        break;
      case "TEXTS":
        payload = checked ? courseTexts : [];
        break;
      case "COURSES":
        payload = checked ? courseAndTeachers : [];
        break;
    }
    dispatch({ type: `SET_SELECTED_${name.toUpperCase()}`, payload });
  };

  const handleNextOrShare = () => {
    if (step === 0) {
      setStep(step + 1);
    } else {
      const coursesPayload = extractIdsFromArray(state.courses);

      const status = Object.fromEntries(
        coursesPayload.map((id) => [id, ACCEPTENCE_STATUS.PENDING])
      );

      const dataObj = {
        sender: userName,
        created_at: new Date(),
        senderCourseId: course_id,
        status: status,
        ttl: add(new Date(), { months: 6 }),

        id: uuid()
      };
      if (type === COURSE_MATERIAL_TYPE.TASK) {
        const tasksPayload = extractDataForTasksShare(state.tasks);
        const data = {
          ...dataObj,
          type: COURSE_MATERIAL_TYPE.TASK,
          tasks: tasksPayload,
          courses: coursesPayload
        };
        httpCallables
          .coursesFunctions({
            func_name: "saveCourseMaterialToFirestore",
            data
          })
          .then((response) => {
            const { success } = response.data;
            if (success) {
              storeDispatch(
                addSnackbarItem({
                  itemId: data.id,
                  actions: [
                    {
                      intlId: "undo",
                      infDefaultMsg: "undo",
                      callBack: "undoShareCourseMaterial"
                    }
                  ],
                  intlId: "shareCourseMaterial.undo",
                  intlDefaultMessage: "Tasks shared successfullly",
                  id: uuid()
                })
              );
            }
          });
      } else if (type === COURSE_MATERIAL_TYPE.TEXT) {
        const textsPayload = extractIdsFromArray(state.texts);
        const data = {
          ...dataObj,
          type: COURSE_MATERIAL_TYPE.TEXT,
          texts: textsPayload,
          courses: coursesPayload
        };
        httpCallables
          .coursesFunctions({
            func_name: "saveCourseMaterialToFirestore",
            data
          })
          .then((response) => {
            const { success } = response.data;
            if (success) {
              storeDispatch(
                addSnackbarItem({
                  itemId: data.id,
                  actions: [
                    {
                      intlId: "undo",
                      infDefaultMsg: "undo",
                      callBack: "undoShareCourseMaterial"
                    }
                  ],
                  intlId: "shareCourseMaterial.undo",
                  intlDefaultMessage: "Texts shared successfully",
                  id: uuid()
                })
              );
            }
          });
      }
      handleClose();
    }
  };

  useEffect(() => {
    resetState();
  }, [course_id]);

  return (
    <Dialog
      open={exportModal}
      PaperProps={{ style: { width: "700px", height: "600px" } }}
      onClose={handleClose}
      aria-labelledby="form-dialog-title">
      <DialogTitle sx={{ display: "flex", flexFlow: "row nowrap" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexFlow: "column",
            width: "100%"
          }}>
          <Typography variant="h6">{stepTitle[type][step]}</Typography>
          <ExportStepsToggler step={step} setStep={setStep} type={type} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "center"
          }}>
          <Button
            variant="outlined"
            size="small"
            onClick={handleClose}
            sx={{
              color: type === "texts" ? "#2E7D32" : "primary",
              borderColor: type === "texts" ? "#2E7D32" : "primary",
              height: "30px",
              marginInlineEnd: "8px"
            }}>
            Cancel
          </Button>
          <Button
            onClick={handleNextOrShare}
            disabled={step == 0 ? !nextEnabled : !shareEnabled}
            variant="contained"
            size="small"
            sx={{
              color: "#FFFFFF",
              height: "30px",
              backgroundColor: type === "texts" ? "#2E7D32" : "primary"
            }}>
            {step < 1 ? "Next" : "Share"}
          </Button>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ overflow: "hidden" }}>
        <Divider variant="fullWidth" sx={{ width: "100%" }} />
        <ExportDataCheckbox
          step={step}
          tasks={initialTasks}
          texts={courseTexts}
          selectedTexts={state.texts}
          selectedTasks={state.tasks}
          handleChange={handleChange}
          type={type}
          courseAndTeachers={courseAndTeachers}
          onIncludeTextToggle={onIncludeTextToggle}
          handleSelectAll={handleSelectAll}
          selectedCourses={state.courses}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ExportModal;
