export function getHighlightColor(color, darkMode) {
  let colors = [
    {
      color: "#BEF3BF",
      text: "color.green",
      hlColorOld: "#BEF3BF",
      hlColor: "#BEF3BF",
      darkMode: "#BEF3BF"
    },
    {
      color: "#B9E7FF",
      text: "color.blue",
      hlColorOld: "#B9E7FF",
      hlColor: "#B9E7FF",
      darkMode: "#B9E7FF"
    },
    {
      color: "#ECC5FF",
      text: "color.pink",
      hlColorOld: "#ECC5FF",
      hlColor: "#ECC5FF",
      darkMode: "#ECC5FF"
    },
    {
      color: "#FF7F74",
      text: "color.red",
      hlColorOld: "#FF7F74",
      hlColor: "#FF7F74",
      darkMode: "#FF7F74"
    },
    {
      color: "#FFE690",
      text: "color.yellow",
      hlColorOld: "#F5BC21",
      hlColor: "#FFE690",
      darkMode: "#FFE690"
    }
  ];

  let relEntry = colors.filter(
    (c) =>
      c.color === color ||
      c.hlColor === color ||
      c.hlColorOld === color ||
      c.darkMode === color
  );
  if (relEntry && relEntry.length) {
    let entry = relEntry[0];
    if (darkMode && entry.darkMode) {
      return entry.darkMode;
    } else return entry.hlColor;
  }

  return color;
}
