import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Question, QUESTION, TASK } from "../../../consts";
import { Box } from "@mui/material";
import AssignmentQuestion from "./AssignmentQuestion";
import Grow from "@mui/material/Grow";

function AiQuestionsPlaceholder({ startIndex, count, taskType }) {
  const questionsPlaceholderRef = useRef([]);
  const indexes = Array.from({ length: count }, (v, i) => {
    return i;
  });

  const [visiblePlaceholders, setVisiblePlaceholders] = useState(0);

  useEffect(() => {
    let timeout;
    if (count > 0 && visiblePlaceholders === 0) {
      setVisiblePlaceholders(1);
    } else if (visiblePlaceholders < count) {
      timeout = setTimeout(() => {
        setVisiblePlaceholders((prev) => prev + 1);
      }, 1000);
    }
    return () => clearTimeout(timeout);
  }, [visiblePlaceholders, count]);

  useEffect(() => {
    if (count === 0) {
      setVisiblePlaceholders(0);
    }
  }, [count]);

  useEffect(() => {
    const scrollBox = document.getElementById("scroll-box");
    const scrollPosition = questionsPlaceholderRef.current[0]?.offsetTop - 24;
    if (scrollPosition) {
      scrollBox.scrollTo({
        top: scrollPosition,
        behavior: "smooth"
      });
    }
  }, [visiblePlaceholders]);

  // Render
  return (
    <Box id="ai-questions-placeholder">
      {[...Array(visiblePlaceholders)].map((_, index) => {
        const question = new Question(QUESTION.OPEN_ENDED, 0);
        question.aiQuestionGenerating = true;
        question.aiQuestion = true;
        return (
          <Grow
            key={index}
            in={true}
            style={{ transformOrigin: "0 0 0" }}
            timeout={500}>
            <Box
              sx={{ marginBottom: "30px" }}
              ref={(el) => {
                if (el) questionsPlaceholderRef.current[index] = el;
              }}>
              <AssignmentQuestion
                readOnly={true}
                disabled={true}
                taskType={taskType}
                questionIndex={startIndex + index}
                {...question}
                showType={taskType === TASK.TYPE.STANDARD}
                showPoints={taskType === TASK.TYPE.STANDARD}
              />
            </Box>
          </Grow>
        );
      })}
    </Box>
  );
}

AiQuestionsPlaceholder.propTypes = {
  count: PropTypes.number,
  taskType: PropTypes.string
};

export default AiQuestionsPlaceholder;
