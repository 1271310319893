import { useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import { userAPI } from "../api";
import { add } from "date-fns";
import { useSelector } from "react-redux";
import { ACTIVITY_EVENT, IDLE_PERIOD } from "../consts";
import { selectCourseByTextId } from "../redux/coursesSlice";

function useLogUserSession(
  activityEvent,
  additionalPayload = {},
  isActive = true
) {
  const userId = useSelector((state) => state.firebase.auth.uid);

  useEffect(() => {
    if (activityEvent) {
      if (isActive) {
        onActive();
      } else {
        onIdle();
      }
    }
  }, [additionalPayload, isActive, activityEvent]);

  function onActive() {
    if (isActive) {
      const now = new Date();
      userAPI.log({
        action_name: activityEvent,
        user_id: userId,
        created_at: now,
        ttl: add(now, { months: 1 }),
        payload: {
          type: ACTIVITY_EVENT.START,
          ...additionalPayload
        }
      });
    }
  }

  function onIdle(shouldAwait = false) {
    const now = new Date();
    userAPI.log(
      {
        action_name: activityEvent,
        user_id: userId,
        created_at: now,
        ttl: add(now, { months: 1 }),
        payload: {
          type: ACTIVITY_EVENT.END,
          idle_period: IDLE_PERIOD,
          ...additionalPayload
        }
      },
      shouldAwait
    );
  }

  const {
    start,
    reset,
    activate,
    pause,
    resume,
    isIdle,
    isPrompted,
    isLeader,
    getTabId,
    getRemainingTime,
    getElapsedTime,
    getLastIdleTime,
    getLastActiveTime,
    getTotalIdleTime,
    getTotalActiveTime
  } = useIdleTimer({
    onIdle,
    onActive,
    // timeout: 1000 * 60 * 10,
    timeout: IDLE_PERIOD,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange"
    ],
    immediateEvents: [],
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: "idle-timer",
    syncTimers: 0,
    leaderElection: false
  });

  useEffect(() => {
    if (activityEvent) {
      window.addEventListener("beforeunload", function (e) {
        onIdle(true);
      });
      return () => {
        window.removeEventListener("beforeunload", function (e) {
          onIdle(true);
        });
        onIdle(true);
      };
    }
  }, [activityEvent]);
}

export default useLogUserSession;
