// TODO: burn this component with fire and replace it with something that makes sense and is not a mess of code and logic and state and redux and hooks and whatnot

// Dependencies
import React, { useRef, useState, useEffect, useCallback } from "react";
import { firebaseApp, httpCallables } from "../../../firebase";

import { FormattedMessage, useIntl } from "react-intl";
import EpubView from "../../reader/EpubView/EpubView";
import { useRendition } from "../../../RenditionContext";
import clsx from "clsx";
import ePub from "epubjs";
import PdfCFI from "../../../utils/pdf-cfi";
import { v4 as uuid } from "uuid";
import { isEmpty } from "lodash";
import { captureException } from "../../../utils/errorHandlers";
import {
  combinePartialCfisToCfi,
  removeAllAnotationsOfType
} from "../../reader/utils";
import {
  ANNOTATION_TYPES,
  INTERACTION_TYPES,
  USER_TYPE
} from "../../../consts";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { selectCurrentText, isPdfSelector } from "../../../redux/textsSlice";

import ReactReaderActions from "../../annotations/ReactReaderActions";
import { setClickedMatch } from "../../../redux/taskSlice";
import { selectSubmission } from "../../../redux/tasksSlice";
import {
  selectTeacherQuestionFeedback,
  questionHighlights,
  selectAnswerComment,
  createInteraction,
  updateInteraction,
  selectMentorQuestionFeedback
} from "../../../redux/interactionsSlice";
import {
  setLinkType,
  resetLink,
  setLinkContent,
  setLinkTarget
} from "../../../redux/LinkSlice";
import { closeAnnotatorBar } from "../../../redux/highlightSlice";
import { selectZoom } from "../../../redux/pdfSlice";
import {
  selectDarkMode,
  selectTextDirection
} from "../../../redux/firestoreSelectors";

// Components
import { TextEditor, TextEditorInput } from "../../SharedComponents/textEditor";
import PdfView from "../../reader/pdf/PdfView";
import { getHighlightColor } from "../../../utils/colors";
import makeStyles from "@mui/styles/makeStyles";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import EditIcon from "@mui/icons-material/Edit";
import {
  Divider,
  Menu,
  MenuItem,
  Box,
  ListItemText,
  IconButton,
  InputAdornment,
  Button,
  Card,
  Typography,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Tooltip
} from "@mui/material";
import { calculateFindInTextScore, mapConcepts } from "./utils";
import { useQuery } from "../../../hooks";
import { useCurrentInteractionWithPoints } from "../../../hooks/useCurrentInteractionWithPoints";

const POSITIONS = { START: "start", END: "end" };
const TYPES = { STUDENT: "student", TEACHER: "teacher" };
const MARKS_TYPES_ARRAY = [TYPES.STUDENT, TYPES.TEACHER];
const colors = { match: "#91fcc2", teacher: "#04E3FD", student: "#FFFF00" };

//Styles
const useStyles = makeStyles((theme) => ({
  pointsContainer: {
    padding: "16px",
    position: "relative",
    minHeight: "64px"
  },
  feedbackBtns: {
    position: "absolute",
    right: "8px"
  },
  dialogActions: {
    justifyContent: "left"
  },
  dialog: {
    zIndex: 10
  },
  dialogActionsRtl: {
    justifyContent: "right"
  },
  dialogBtn: {
    position: "absolute",
    color: "#ffffff",
    right: "16px"
  },

  dialogTitle: {
    background: "#168FEE",
    display: "inline-flex",
    color: "#ffffff"
  },
  points: {
    display: "inline-block",
    border: "1px solid #168fee",
    borderRadius: "4px",
    paddingLeft: "8px",
    paddingRight: "8px",
    width: "64px",
    "& input": {
      textAlign: "center",
      color: theme.palette.text.disabled
    },
    "& input[type='number']::-webkit-inner-spin-button, & input[type='number']::-webkit-outer-spin-button":
      {
        "-webkit-appearance": "none",
        margin: 0,
        display: "none"
      }
  },
  hidden: {
    visibility: "hidden",
    position: "absolute",
    fontSize: "20px",
    maxWidth: "95%"
  },
  epubViewContainer: {
    position: "relative"
  },
  left: {
    textAlign: "left"
  },
  pointsMsg: {
    color: "#168fee",
    display: "inline-block",
    lineHeight: "34px"
  },
  eval: {
    color: "#168fee",
    fontSize: "20px",
    lineHeight: "32px",
    "& ::placeholder": {
      /* Chrome, Firefox, Opera, Safari 10.1+ */ color: "#168fee",
      opacity: 1
    },
    "& button": {
      visibility: "hidden"
    },
    "&:hover button": {
      visibility: "visible"
    }
  },

  adornment: {
    color: "#168fee"
  },
  colorsReader: {
    display: "flex",
    zIndex: "2",
    color: theme.palette.text.primary
  },
  matchColor: {
    marginBottom: "8px",
    marginLeft: "8px",
    marginRight: "8px"
  },
  textIcon: {
    padding: "4px"
  },
  studentColor: {
    marginBottom: "8px",
    marginLeft: "8px",
    marginRight: "8px"
  },
  yourColor: {
    marginBottom: "8px",
    marginLeft: "8px",
    marginRight: "8px"
  },
  feedbackCard: {
    fontSize: "16px"
  },
  feedbackBody: {
    padding: "16px",
    position: "relative"
  },
  feedbackHeader: {
    color: "#168fee",
    fontSize: "20px",
    lineHeight: "32px",
    padding: "16px"
  }
}));

export default function FeedbackQuestionContainer({ index, task, showMatch }) {
  const EpubCFI = new ePub.CFI();
  const PdfCfi = new PdfCFI();

  //Hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const titleRef = useRef();
  const titleSpanRef = useRef();
  const linkBtnRef = useRef();
  const intl = useIntl();
  const epubElemRef = useRef();
  const epubRef = useRef();
  const { submission_id } = useQuery();

  //Redux state
  const text = useSelector(selectCurrentText);
  const fontSizeValue = useSelector((state) => state.user.userProfile.fontSize);
  const fontSize = useSelector((state) => state.user.fontSizeOptions);
  const clickedMatch = useSelector((state) => state.task.clickedMatch);
  const darkMode = useSelector((state) => selectDarkMode(state));
  const textDirection = useSelector((state) => selectTextDirection(state));
  const question = useCurrentInteractionWithPoints();

  const studentHighlights = useSelector((state) =>
    questionHighlights(state, question.id)
  );
  const studentAnswer = useSelector((state) =>
    selectAnswerComment(state, question.id)
  );
  const isPdf = useSelector(isPdfSelector);
  const zoom = useSelector(selectZoom);
  const mentorFeedback = useSelector((state) =>
    selectMentorQuestionFeedback(state, question.id)
  );
  const teacherFeedback = useSelector((state) =>
    selectTeacherQuestionFeedback(state, question.id)
  );
  const submission = useSelector((state) =>
    selectSubmission(state, Number(submission_id))
  );

  // Ephemeral State
  const [menuOpen, setMenuOpen] = useState(false);
  const [editingTitle, setEditingTitle] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openLinkDialog, setOpenLinkDialog] = useState(false);
  const [linksCfi, setLinksCfi] = useState([]);
  const [title, setTitle] = useState("");
  const [points, setPoints] = useState(0);
  const { rendition } = useRendition();
  const [pdfHighlights, setPdfHighlights] = useState([]);
  const [feedbackContent, setFeedbackContent] = useState(null);
  const [validPointsPerQuestion, setValidPointsPerQuestion] = useState(true);

  // Variables
  const CFIByFileType = isPdf ? PdfCfi : EpubCFI;
  const readOnly = submission.is_checked;

  // Deraived state
  // const parsedPoints = Number(question.points) || 0;

  // const feedbackContent = isEmpty(teacherFeedback)
  //   ? teacherFeedback.rich_text
  //   : mentorFeedback.rich_text;

  useEffect(() => {
    if (teacherFeedback.content) {
      setFeedbackContent(teacherFeedback.rich_text);
    } else {
      setFeedbackContent(mentorFeedback.rich_text);
    }
  }, [mentorFeedback, teacherFeedback]);

  useEffect(() => {
    if (!teacherFeedback.points) return;
    setPoints(Number(Math.floor(teacherFeedback.points)));
  }, [teacherFeedback.points]);

  function splitHighlights(highlights, idPdf) {
    //this function split highlights to unique ranges
    let coll = [];
    highlights
      .filter((h) => h && h.cfi)
      .forEach((hl) => {
        let cfiParts = hl.cfi.split(","); // cfiBase:  cfiParts[0]
        let startCfi = cfiParts[0] + cfiParts[1] + ")"; // start: ‘epubcfi(/6/4[chap01ref]!/4[body01]/10[para05]/2/1:1)’
        let endCfi = cfiParts[0] + cfiParts[2];
        coll.push({ cfi: startCfi, val: 1 });
        coll.push({ cfi: endCfi, val: -1 });
      });
    let sortedHl = [...coll].sort(function (a, b) {
      let val = CFIByFileType.compare(a.cfi, b.cfi);
      if (val === 0) {
        if (a.val === b.val) return 0;
        return b.val < 0 ? 1 : -1;
      }
      return val;
    });
    let current = 0;
    let start = null;
    let hlSplitted = [];
    for (var i = 0; i < sortedHl.length; i++) {
      if (start == null) {
        if (sortedHl[i].val > 0) {
          start = sortedHl[i].cfi;
          current = 1;
        }
      } else {
        if (sortedHl[i].val > 0) {
          current++;
        } else {
          current--;
          if (current === 0) {
            let cfi = combinePartialCfisToCfi(start, sortedHl[i].cfi, {
              isPdf
            });
            hlSplitted.push({ cfi });
            start = null;
          }
        }
      }
    }
    return hlSplitted;
  }

  function selectSegColor(firstCfiSeg, secCfiSeg, startedSegs) {
    let selectedColor = null;

    //must be of different types i.e Teacher && Student
    const regEx = /\[.*\]/;
    firstCfiSeg.cfi = firstCfiSeg.cfi.replace(regEx, "");
    secCfiSeg.cfi = secCfiSeg.cfi.replace(regEx, "");

    if (
      firstCfiSeg.pos === secCfiSeg.pos &&
      firstCfiSeg.cfi !== secCfiSeg.cfi
    ) {
      selectedColor =
        firstCfiSeg.pos === POSITIONS.START
          ? colors[firstCfiSeg.type]
          : colors[secCfiSeg.type];
      //can be both same or different types
      // IF not same type mark as match
      // OR if same mark but a mark of different type has already started before and didn't end
      // FINALLY... if same type mark as the type of the first
    } else if (
      firstCfiSeg.pos === POSITIONS.START &&
      secCfiSeg.pos === POSITIONS.END
    ) {
      const otherType = MARKS_TYPES_ARRAY.find((a) => {
        return a !== firstCfiSeg.type;
      });
      selectedColor =
        startedSegs[otherType] || firstCfiSeg.type !== secCfiSeg.type
          ? colors.match
          : colors[firstCfiSeg.type];
    } else if (
      firstCfiSeg.pos === POSITIONS.END &&
      secCfiSeg.pos === POSITIONS.START &&
      firstCfiSeg.type === secCfiSeg.type
    ) {
      const otherType = MARKS_TYPES_ARRAY.find((a) => {
        return a !== firstCfiSeg.type;
      });
      selectedColor = startedSegs[otherType] ? colors[otherType] : null;
    }
    return selectedColor;
  }

  function createMatchedHighlights(teacherHl, studentHl, isPdf) {
    let highlights = [];
    let splitHl = [];

    if (!studentHl?.length) {
      if (!teacherHl?.length) {
        return [];
      }
      return (highlights = [...teacherHl]);
    } else if (!teacherHl?.length) return (highlights = [...studentHl]);

    const types = { 0: TYPES.TEACHER, 1: TYPES.STUDENT };
    const pos = { 0: POSITIONS.START, 1: POSITIONS.END };
    let splittedCfiToId = {};
    let allSplittedCfis = [];
    [teacherHl, studentHl].forEach((hl, idx) => {
      const type = types[idx];
      if (hl && hl.length) splitHl = splitHighlights(hl, isPdf);
      splitHl.forEach((el, index) => {
        let cfiParts = el.cfi.split(","); // cfiBase:  cfiParts[0]
        let startCfi = cfiParts[0] + cfiParts[1] + ")"; // start: ‘epubcfi(/6/4[chap01ref]!/4[body01]/10[para05]/2/1:1)’
        let endCfi = cfiParts[0] + cfiParts[2];
        const regEx = /(epubcfi\(\/\d+\/\d+)/;
        startCfi = startCfi.replace(regEx, "$1" + `[${type}]`);
        endCfi = endCfi.replace(regEx, "$1" + `[${type}]`);
        [startCfi, endCfi].forEach((cfiSeg, i) => {
          splittedCfiToId[cfiSeg] = {
            ...hl[index],
            type: type,
            pos: pos[i],
            cfi: cfiSeg
          };
          allSplittedCfis.push(cfiSeg);
        });
      });
    });

    let sortedSplittedCfis = allSplittedCfis.sort(function (a, b) {
      return CFIByFileType.compare(a, b);
    });

    let hasStarted = {};
    for (let i = 0; i < Object.keys(sortedSplittedCfis).length - 1; i++) {
      let firstSeg = splittedCfiToId[sortedSplittedCfis[i]];
      let secondSeg = splittedCfiToId[sortedSplittedCfis[i + 1]];
      hasStarted[firstSeg.type] = firstSeg.pos === POSITIONS.START;
      let segColor = selectSegColor(firstSeg, secondSeg, hasStarted);
      if (segColor) {
        let cfi = combinePartialCfisToCfi(firstSeg.cfi, secondSeg.cfi, {
          isPdf
        });
        if (isPdf)
          firstSeg.pdfPosition = [{ ...firstSeg.pdfPosition[0], cfi: cfi }];

        highlights.push({
          ...firstSeg,
          cfi,
          color: segColor
        });
      }
    }
    return highlights;
  }
  const getHighlights = useCallback(
    (teacherHl, studentHl, isPdf) => {
      //teacher  04E3FDד
      //student FFFF00
      //match 91fcc2
      try {
        const highlights = createMatchedHighlights(teacherHl, studentHl, isPdf);
        return highlights;
      } catch (e) {
        captureException(e, `failed creating matching highlights`);
        return [];
      }
    },
    [CFIByFileType, splitHighlights]
  );

  useEffect(() => {
    if (!isPdf) return;
    if (pdfHighlights) setPdfHighlights([]);

    let teacherHighlights = question.quotes;
    let highlights = getHighlights(teacherHighlights, studentHighlights, isPdf);

    highlights.forEach((highlight) => {
      const cfi = highlight.cfi;
      const color = highlight.color;
      const pdfPosition = highlight.pdfPosition;
    });
    setPdfHighlights(highlights);
  }, [isPdf, question.quotes, studentHighlights]);

  useEffect(() => {
    if (!rendition) return;

    let teacherHighlights = question.quotes;
    const isPdf = false;
    let highlights = getHighlights(teacherHighlights, studentHighlights, isPdf);
    removeAllAnotationsOfType(rendition, ANNOTATION_TYPES.HIGHLIGHT);
    highlights.forEach((highlight) => {
      const cfi = highlight.cfi;
      const color = highlight.color;
      rendition.annotations.add(
        ANNOTATION_TYPES.HIGHLIGHT,
        cfi,
        { id: highlight.id },
        () => {},
        ANNOTATION_TYPES.HIGHLIGHT.toLowerCase(),
        {
          "z-index": 20,
          "mix-blend-mode": "multiply",
          "fill-opacity": 0.8,
          fill: getHighlightColor(color, darkMode)
        }
      );
    });
  }, [darkMode, getHighlights, question.quotes, rendition, studentHighlights]);

  const addHighlightToAnswer = (cfi) => {
    setLinksCfi([...linksCfi, cfi]);
  };
  const removeHighlightFromAnswer = (hl) => {
    setLinksCfi(linksCfi.filter((el) => el !== hl.cfi));
  };

  const renderHighlightsComparison = useCallback(
    (params) => {
      if (params.shouldClose) {
        dispatch(closeAnnotatorBar());
      } else {
        addHighlightToAnswer(params.selection.cfi);
        let content = rendition && rendition.getContents()[0];

        if (content && content.window && content.window.getSelection()) {
          content.window.getSelection().empty();
        }
      }
    },
    [dispatch, rendition, addHighlightToAnswer]
  );

  const renderTextLinkDialog = () => {
    return (
      <Dialog
        open={openLinkDialog}
        PaperProps={{
          style: {
            direction: textDirection,
            width: "90%",
            maxWidth: "90%"
          }
        }}
        aria-labelledby="form-dialog-title">
        <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
          <DialogActions
            className={clsx(
              classes.dialogActions,
              textDirection === "rtl" && classes.dialogActionsRtl
            )}>
            <Typography className={classes.dialogHeader}>
              {" "}
              <FormattedMessage id="text.mark" defaultMessage="Mark in Text" />
            </Typography>
            <Button
              variant="outlined"
              className={classes.dialogBtn}
              onClick={() => {
                setOpenLinkDialog(false);
                if (linksCfi.length > 0) {
                  dispatch(setLinkType("text"));
                  dispatch(setLinkContent({ cfis: linksCfi }));
                } else {
                  dispatch(resetLink());
                }
                setLinksCfi([]);
              }}>
              <FormattedMessage defaultMessage="Done" id="gr.confirm.btn" />
            </Button>
          </DialogActions>
        </DialogTitle>
        <DialogContent ref={epubElemRef} className={classes.dialog}>
          <ReactReaderActions
            id="reactReaderActions"
            minimal={true}
            highlightFunc={addHighlightToAnswer}
            deleteHighlight={removeHighlightFromAnswer}>
            <div id="epubViewContainer" className={classes.epubViewContainer}>
              {isPdf ? (
                <PdfView
                  zoom={zoom}
                  isVisible={true}
                  handleTextSelected={renderHighlightsComparison}
                  url={text.url}
                />
              ) : (
                <EpubView
                  ref={epubRef}
                  fullSize={true}
                  fontSize={fontSize[fontSizeValue]}
                  epubOptions={{ flow: "scrolled-doc" }}
                  // loadingView={inputRef}
                  textDirection={text.text_language === "he" ? "rtl" : "ltr"}
                  url={text.url}
                  location={text.file_location}
                  // locationChanged={locationChanged}
                  handleTextSelected={(params) => {
                    if (params.shouldClose) {
                      dispatch(closeAnnotatorBar());
                    } else {
                      addHighlightToAnswer(params.selection.cfi);
                      let content = rendition && rendition.getContents()[0];

                      if (
                        content &&
                        content.window &&
                        content.window.getSelection()
                      ) {
                        content.window.getSelection().empty();
                      }
                    }
                  }}
                  onRenditionCreated={() => {}}
                />
              )}
            </div>
          </ReactReaderActions>
        </DialogContent>
      </Dialog>
    );
  };

  const currentUser = firebaseApp.auth().currentUser;

  useEffect(() => {
    if (clickedMatch) {
      setOpenDialog(true);
      dispatch(setClickedMatch(false));
    }
  }, [clickedMatch, dispatch, setOpenDialog]);

  const rendeHighlightsDialog = () => {
    return (
      <Dialog
        open={openDialog}
        PaperProps={{
          style: {
            direction: textDirection,
            width: "60%",
            maxWidth: "60%"
          }
        }}
        aria-labelledby="form-dialog-title">
        <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
          {showMatch &&
            (question.type === "FIND_IN_TEXT" || question.includeCitation) && (
              <Box className={classes.colorsReader}>
                <Typography
                  style={{
                    // TODO: color should not be hard coded
                    background: getHighlightColor("#B9E7FF", darkMode)
                  }}
                  className={classes.yourColor}>
                  {currentUser.uid === task.creator
                    ? "Your "
                    : task.teacher_name + "'s "}
                  marks in blue
                </Typography>
                <Typography
                  style={{
                    // TODO: color should not be hard coded
                    background: getHighlightColor("#F5BC21", darkMode)
                  }}
                  className={classes.studentColor}>
                  {currentUser.uid === submission.owner
                    ? "Your "
                    : submission.user_name.split(" ")[0] + "'s "}
                  marks in yellow
                </Typography>

                <Typography
                  style={{
                    background: getHighlightColor("#BEF3BF", darkMode)
                  }}
                  className={classes.matchColor}>
                  Match in green
                </Typography>
              </Box>
            )}
          <DialogActions className={classes.dialogActions}>
            <Button
              variant="outlined"
              className={classes.dialogBtn}
              onClick={() => {
                setOpenDialog(false);
              }}>
              <FormattedMessage defaultMessage="Done" id="gr.confirm.btn" />
            </Button>
          </DialogActions>
        </DialogTitle>
        <DialogContent ref={epubElemRef} className={classes.dialog}>
          <div id="epubViewContainer" className={classes.epubViewContainer}>
            {isPdf ? (
              <PdfView
                zoom={zoom}
                highlightClicked={() => {}}
                isVisible={true}
                handleTextSelected={renderHighlightsComparison}
                highlights={pdfHighlights}
                // underlines={underlines}
                url={text.url}
              />
            ) : (
              <EpubView
                fullSize={true}
                fontSize={fontSize[fontSizeValue]}
                epubOptions={{ flow: "scrolled-doc" }}
                url={text.url}
                textDirection={text.text_language === "he" ? "rtl" : "ltr"}
                location={text.file_location}
                onRenditionCreated={() => {}}
              />
            )}
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  const renderMenu = () => {
    return (
      <Menu
        direction={"auto"}
        anchorEl={linkBtnRef.current}
        keepMounted
        open={Boolean(menuOpen)}
        onClose={() => setMenuOpen(false)}
        disableScrollLock={true}>
        {(["OPEN_ENDED"].includes(question.interaction_subtype) ||
          task.task_type === "guidedReading") && (
          <MenuItem
            onClick={() => {
              setMenuOpen(false);
              dispatch(setLinkType("answer"));

              dispatch(setLinkTarget("editor"));
            }}>
            <ListItemText>
              <FormattedMessage
                id="task.feedback.link.answer"
                defaultMessage="Link to a passage from student's work"
              />
            </ListItemText>
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            setMenuOpen(false);
            dispatch(setLinkTarget("text"));
            setOpenLinkDialog(true);
          }}>
          <ListItemText>
            {" "}
            <FormattedMessage
              id="task.feedback.link.text"
              defaultMessage="Link to task related text"
            />
          </ListItemText>
        </MenuItem>
      </Menu>
    );
  };

  useEffect(() => {
    if (teacherFeedback.id && teacherFeedback.points >= 0) {
      setPoints(Math.floor(teacherFeedback.points));
    }
  }, [teacherFeedback.id, teacherFeedback.points]);

  const createFeedbackWithPoints = useCallback(
    (points) => {
      const id = uuid();
      dispatch(
        createInteraction({
          id: id,
          interactionId: question.id,
          interactionType: "feedbacks",
          points: Math.round(points),
          interaction_type: INTERACTION_TYPES.FEEDBACK,
          interaction_subtype: INTERACTION_TYPES.QUESTION,
          user_type: USER_TYPE.TEACHER
        })
      );
      httpCallables
        .interactionFunctions({
          func_name: "createQuestionFeedback",
          id: id,
          interaction_id: question.id,
          submission_id: Number(submission_id),
          text_id: task.text_id,
          course_id: task.course_id,
          points: points,
          task_id: task.id
        })
        .catch((err) => {
          // Rollback
          // Snackbar
          captureException(err);
        });
    },
    [dispatch, question.id, submission_id, task.course_id, task.text_id]
  );

  function updateFeedbackWithPoints(feedback, points) {
    dispatch(
      updateInteraction({
        interaction: feedback,
        update: { points: Math.round(points) }
      })
    );
  }

  useEffect(() => {
    if (teacherFeedback.id || !question.points) return;

    const points = Math.round(Number(question.points));
    if (Number.isNaN(points) || points === 0) return;
    const highlightMatch = calculateFindInTextScore(
      question,
      studentHighlights
    );

    const conceptMapping = mapConcepts(question, studentAnswer);

    let calc = 0;
    if (question.interaction_subtype === "FIND_IN_TEXT") {
      calc = getMatchScore(question, highlightMatch);
    }
    if (["OPEN_ENDED"].includes(question.interaction_subtype)) {
      let questionPart;
      const foundConcepts = conceptMapping.filter(
        (concept) => concept.found
      ).length;

      if (conceptMapping.length === 0) questionPart = points;
      else if (foundConcepts === 0) questionPart = 0;
      else {
        questionPart = Math.ceil(
          (conceptMapping.filter((concept) => concept.found).length /
            conceptMapping.length) *
            points
        );
      }
      if (question.includeCitation && studentHighlights.length) {
        let quotePart = getMatchScore(question, highlightMatch);
        calc = 0.5 * quotePart + 0.5 * questionPart;
      } else calc = questionPart;
    }
    if (question.interaction_subtype === "MULTI_CHOICE") {
      let questionPart =
        Number(question.shouldSelect) === Number(studentAnswer.choice)
          ? parseInt(points)
          : 0;
      if (question.includeCitation && studentHighlights.length) {
        let quotePart = getMatchScore(question, highlightMatch);
        calc = 0.5 * quotePart + 0.5 * questionPart;
      } else calc = questionPart;
    }
    setPoints(Math.floor(calc));
    createFeedbackWithPoints(Math.floor(calc));
  }, [
    createFeedbackWithPoints,
    teacherFeedback.id,
    question,
    studentAnswer,
    studentHighlights
  ]);

  function getMatchScore(quesion, match) {
    if (match === 0) return 0;
    let factored = match;
    if (match > 0.7) {
      factored = 1;
    } else if (match > 0.6) {
      factored = factored * 1.25;
    } else {
      factored = Math.min(0.6, factored * 1.55);
    }
    return Math.ceil(factored * parseFloat(quesion.points));
  }

  const [titleEditMargin, setTitleEditMargin] = useState(0);

  useEffect(() => {
    // TODO: Figure out WTF this is
    let marginVal = "0px";
    marginVal =
      titleSpanRef && titleSpanRef.current
        ? titleSpanRef.current.getBoundingClientRect().width + "px"
        : teacherFeedback.title.length * 0.7 + "ch";

    if (textDirection === "rtl") {
      setTitleEditMargin({ marginRight: marginVal });
    } else {
      setTitleEditMargin({ marginLeft: marginVal });
    }
  }, [teacherFeedback, titleSpanRef, index, setTitleEditMargin, textDirection]);

  const renderPoints = () => {
    if (question.points) {
      return (
        <>
          <TextField
            variant="standard"
            className={classes.points}
            id="standard-number"
            aria-label="task-overall-points-input"
            type="number"
            InputProps={{ disableUnderline: true, readOnly: readOnly }}
            value={points >= 0 ? points : ""}
            onChange={(e) => {
              if (e.target.value <= question.points) {
                setPoints(Math.floor(e.target.value));
                setValidPointsPerQuestion(true);
              } else setValidPointsPerQuestion(false);
            }}
            onBlur={(e) => {
              if (readOnly) return;
              else
                isEmpty(teacherFeedback)
                  ? createFeedbackWithPoints(e.target.value)
                  : updateFeedbackWithPoints(teacherFeedback, e.target.value);
              setEditingTitle(false);
            }}
            inputProps={{
              "aria-label": `Points out of ${question.points}`
            }}
          />{" "}
          <Typography
            className={classes.pointsMsg}
            variant="body1"
            component="div">
            <FormattedMessage
              defaultMessage="Points out of"
              id="task.points_of"
            />{" "}
            {question.points}
          </Typography>
          <Box sx={{ display: "flex", flexFlow: "column" }}>
            {!validPointsPerQuestion && (
              <Typography
                variant="body2"
                component="span"
                sx={{ color: "#D32F2F" }}>
                <FormattedMessage
                  defaultMessage="*You exceeded the maximum of "
                  id="task.feedback.invalidPoint"
                />
                {question.points} points.
              </Typography>
            )}
          </Box>
        </>
      );
    }
  };

  useEffect(() => {
    if (!teacherFeedback.title) setTitle("");
    else setTitle(teacherFeedback.title);
  }, [teacherFeedback.title]);

  function createFeedbackWithTitle(titleEditMargin) {
    if (readOnly) return;

    const id = uuid();
    dispatch(
      createInteraction({
        id: id,
        interactionId: question.id,
        interactionType: "feedbacks",
        title: title,
        interaction_type: INTERACTION_TYPES.FEEDBACK,
        interaction_subtype: INTERACTION_TYPES.QUESTION,
        user_type: USER_TYPE.TEACHER
      })
    );
    httpCallables
      .interactionFunctions({
        func_name: "createQuestionFeedback",
        id: id,
        interaction_id: question.id,
        submission_id: Number(submission_id),
        text_id: task.text_id,
        course_id: task.course_id,
        title: title,
        task_id: task.id
      })
      .catch((err) => {
        // Rollback
        // Snackbar
        captureException(err);
      });
  }

  function updateFeedbackWithTitle(feedback, title) {
    if (readOnly) return;
    dispatch(
      updateInteraction({
        interaction: feedback,
        update: { title }
      })
    );
  }

  function createFeedbackWithContent(content) {
    if (readOnly) return;

    const { plainText, richText, wordCount } = content;
    const id = uuid();
    dispatch(
      createInteraction({
        id: id,
        content: plainText,
        interactionId: question.id,
        interactionType: "feedbacks",
        rich_text: richText,
        word_count: wordCount,
        interaction_type: INTERACTION_TYPES.FEEDBACK,
        interaction_subtype: INTERACTION_TYPES.QUESTION,
        user_type: USER_TYPE.TEACHER
      })
    );
    httpCallables
      .interactionFunctions({
        func_name: "createQuestionFeedback",
        id: id,
        content: plainText,
        interaction_id: question.id,
        task_id: task.id,
        submission_id: Number(submission_id),
        text_id: task.text_id,
        course_id: task.course_id,
        rich_text: richText,
        word_count: wordCount
      })
      .catch((err) => {
        // Rollback
        // Snackbar
        captureException(err);
      });
  }

  function updateFeedbackWithContent(feedback, content) {
    if (readOnly) return;

    const { plainText, richText, wordCount } = content;
    dispatch(
      updateInteraction({
        disableRollback: true, // we don't want to to erase users input if the request fails
        interaction: feedback,
        update: {
          content: plainText,
          rich_text: richText,
          word_count: wordCount
        }
      })
    );
  }

  // Render
  return (
    <Card className={clsx(classes.feedbackCard, classes.left)}>
      <Typography
        variant="h6"
        className={clsx(classes.feedbackHeader, classes.questionLtr)}>
        <FormattedMessage
          id="task.question.feedback"
          defaultMessage="Question feedback"
        />
      </Typography>
      {(!readOnly || teacherFeedback.title) && <Divider />}
      <Box className={classes.feedbackBody}>
        <span ref={titleSpanRef} className={classes.hidden}>
          {!readOnly && (!teacherFeedback || !teacherFeedback.title)
            ? intl.formatMessage({
                defaultMessage: "Enter evaluation title",
                id: "task.title.placeholder"
              })
            : teacherFeedback.title}
        </span>

        <TextField
          variant="standard"
          inputRef={titleRef}
          aria-label="feedback-question-container-input"
          fullWidth
          InputProps={{
            readOnly: !editingTitle || readOnly,
            disableUnderline: true,
            startAdornment: !editingTitle && !readOnly && (
              <InputAdornment
                style={{ ...titleEditMargin, position: "absolute" }}
                position="start">
                <IconButton
                  className={classes.adornment}
                  onClick={(e) => {
                    setEditingTitle(true);
                    titleRef.current.focus();
                    // if (titleRef.current.setSelectionRange && feedback.title) {
                    //   titleRef.current.setSelectionRange(feedback.title.length);
                    // }

                    e.stopPropagation();
                  }}
                  aria-label="edit evaluation title"
                  size="large">
                  <EditIcon />
                </IconButton>
              </InputAdornment>
            ),
            className: classes.eval
          }}
          className={classes.eval}
          label=""
          placeholder={
            readOnly
              ? ""
              : intl.formatMessage({
                  defaultMessage: "Enter evaluation title",
                  id: "task.title.placeholder"
                })
          }
          value={title}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              isEmpty(teacherFeedback)
                ? createFeedbackWithTitle(e.target.value)
                : updateFeedbackWithTitle(teacherFeedback, e.target.value);
              setEditingTitle(false);
            }
          }}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          onBlur={(e) => {
            isEmpty(teacherFeedback)
              ? createFeedbackWithTitle(e.target.value)
              : updateFeedbackWithTitle(teacherFeedback, e.target.value);
            setEditingTitle(false);
          }}
        />
        <TextEditor
          content={feedbackContent}
          variant="contained"
          disabled={readOnly}>
          <TextEditorInput
            onChange={
              isEmpty(teacherFeedback)
                ? (content) => {
                    createFeedbackWithContent(content);
                  }
                : (content) => {
                    updateFeedbackWithContent(teacherFeedback, content);
                  }
            }
            fontSize="small"
            placeholder={
              currentUser.uid === task.creator
                ? intl
                    .formatMessage({
                      id: "task.feedback.placeholder",
                      defaultMessage: "Let $STUDENT know how well they did"
                    })
                    .replace("$STUDENT", submission.user_name)
                : teacherFeedback.content
            }
          />
        </TextEditor>
      </Box>
      <Divider />
      <Box className={classes.pointsContainer}>
        <Box className={classes.feedbackBtns}>
          <Tooltip
            title={intl.formatMessage({
              id: "feedback.tooltip.showText",
              defaultMessage: "Show text"
            })}
            arrow
            placement="top">
            <IconButton
              className={classes.textIcon}
              onClick={() => setOpenDialog(true)}
              size="large">
              <LibraryBooksIcon />
            </IconButton>
          </Tooltip>
        </Box>
        {renderPoints()}
      </Box>
      {openDialog && rendeHighlightsDialog()}
      {openLinkDialog && renderTextLinkDialog()}
      {menuOpen && renderMenu()}
    </Card>
  );
}
