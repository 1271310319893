import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, IconButton, Popover, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { clearSelection } from "../../reader/utils";
import RichTooltip from "../../SharedComponents/RichTooltip";
import CitationDialog from "./CitationDialog";
import AssignmentQuestionConcepts from "./AssignmentQuestionConcepts";
import { addHighlight } from "../../../redux/interactionsSlice";
import { useDispatch } from "react-redux";
function AssignmentQuestionAdditionalOptions({
  text,
  highlights = [],
  readOnly = false,
  concepts = [],
  onConcepts,
  interactionSubtype = "OPEN_ENDED",
  tempId,
  onUpdate
}) {
  // Hooks
  const intl = useIntl();
  const dispatch = useDispatch();

  //Variables
  const [showOptions, setShowOptions] = useState(false);
  const [openCitationsDialog, setOpenCitationsDialog] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const showTooltip = Boolean(anchorEl);
  const tooltipId = showTooltip
    ? "additional-options-tooltip-popover"
    : undefined;

  const handleTooltipClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleTooltipClose = () => {
    setAnchorEl(null);
  };

  const renderTooltipBody = () => {
    return (
      <Box>
        <Typography variant="h6">
          {intl.formatMessage({
            id: "task.create.assignment.highlightSupportingEvidenceTooltipTitle",
            defaultMessage: "Highlight supporting evidence"
          })}
        </Typography>
        <Typography variant="body2" sx={{ marginBottom: "10px" }}>
          {intl.formatMessage({
            id: "task.create.assignment.assignmentTypeTooltipStandard",
            defaultMessage:
              "Verify your students' understanding: mark the key sections in the text and Alethea will match your markings with the students'."
          })}
        </Typography>
        <Typography variant="h6">
          {intl.formatMessage({
            id: "task.create.assignment.defineKeyConceptsTooltipTitle",
            defaultMessage: "Define key concepts"
          })}
        </Typography>
        <Typography variant="body2">
          {intl.formatMessage({
            id: "task.create.assignment.defineKeyConceptsTooltipBody",
            defaultMessage:
              "Seeking a quick overview? Define key words or concepts to search for in your students' written answers."
          })}
        </Typography>
      </Box>
    );
  };

  function addInteraction(highlight) {
    const quotes = [...highlights, highlight];
    onUpdate({ quotes }, tempId);
    dispatch(addHighlight(highlight));
    return clearSelection();
  }

  function removeHighlight(highlight) {
    let quotes;
    if (typeof highlight === "string") {
      quotes = highlights.filter((quote) => quote.id !== highlight);
    } else {
      quotes = highlights.filter((quote) => quote.id !== highlight.id);
    }
    onUpdate({ quotes }, tempId);
  }

  // Render
  return (
    <Box sx={{ alignItems: "center" }}>
      <Box sx={{ display: "flex" }}>
        <Typography
          role="button"
          onClick={() => setShowOptions(!showOptions)}
          color="primary"
          tabIndex="0"
          aria-expanded={showOptions}
          aria-controls="advanced-settings-content"
          sx={{ cursor: "pointer", display: "flex", alignItems: "center" }}
          onKeyDown={(event) => {
            if (event.key === "Enter" || event.key === " ") {
              setShowOptions(!showOptions);
            }
          }}>
          {intl.formatMessage({
            id: "task.create.assignment.additionalOptions",
            defaultMessage: "Additional options"
          })}
          {showOptions ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Typography>
        <IconButton
          color="primary"
          onClick={handleTooltipClick}
          aria-label={"Additional options"}
          aria-haspopup={"true"}
          aria-expanded={showTooltip}
          aria-controls={tooltipId}>
          <InfoOutlinedIcon size="small" />
        </IconButton>
        {showTooltip && (
          <Popover
            id={tooltipId}
            open={showTooltip}
            anchorEl={anchorEl}
            onClose={handleTooltipClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            sx={{ left: "20px" }}>
            <RichTooltip
              style={{ width: "300px" }}
              title="task.create.assignment.additionalOptions"
              bodyHtml={renderTooltipBody()}
            />
          </Popover>
        )}
      </Box>
      {showOptions && (
        <Box>
          <Typography
            role="button"
            onClick={() => setOpenCitationsDialog(true)}
            color="primary"
            tabIndex="0"
            aria-expanded={openCitationsDialog}
            aria-controls="advanced-settings-content"
            onKeyDown={(event) => {
              if (event.key === "Enter" || event.key === " ") {
                setOpenCitationsDialog(true);
              }
            }}
            variant="body2"
            sx={{ cursor: "pointer", paddingLeft: "20px", marginTop: "10px" }}>
            {intl.formatMessage({
              id: "task.create.assignment.supportingEvidence",
              defaultMessage: "Highlight supporting evidence (optional)"
            })}
          </Typography>
          <CitationDialog
            openDialog={openCitationsDialog}
            setOpenDialog={setOpenCitationsDialog}
            url={text.url}
            location={text.file_location}
            highlights={highlights}
            readOnly={readOnly}
            color="primary"
            addHighlight={!readOnly && addInteraction}
            removeHighlight={!readOnly && removeHighlight}
            citationTitleFormattedMessage={
              <FormattedMessage id="text.mark" defaultMessage="Mark in Text" />
            }
          />
          {interactionSubtype === "OPEN_ENDED" && (
            <Box>
              <AssignmentQuestionConcepts
                readOnly={readOnly}
                onChange={onConcepts}
                concepts={concepts}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

AssignmentQuestionAdditionalOptions.propTypes = {
  text: PropTypes.object,
  highlights: PropTypes.array,
  readOnly: PropTypes.bool,
  concepts: PropTypes.array,
  onConcepts: PropTypes.func,
  interactionSubtype: PropTypes.string,
  tempId: PropTypes.string,
  onUpdate: PropTypes.oneOfType([PropTypes.func, PropTypes.bool])
};

export default AssignmentQuestionAdditionalOptions;
