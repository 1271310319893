// Dependencies
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { peerReviewAPI } from "../../../api";
import { isEmpty } from "lodash";
import { useQuery } from "../../../hooks";
import {
  PEER_REVIEW_TASK,
  INTERACTION_TYPES,
  SECONDARY_SIDEBAR_ACTION_BUTTONS
} from "../../../consts";
import { useRendition } from "../../../RenditionContext";
import { handleThumbnailPanel } from "../../SharedComponents/buttons/utils";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { setBreadcrumbs } from "../../../redux/readerActionsSlice";
import { selectCurrentText, isPdfSelector } from "../../../redux/textsSlice";
import {
  selectTask,
  selectSubmissionCollaboration,
  selectSubmission
} from "../../../redux/tasksSlice";
import {
  selectPeerReviewQuestion,
  selectPeerReviewReflection,
  selectPeerReviewReview,
  updateInteraction
} from "../../../redux/interactionsSlice";
import { selectCourseByTaskId } from "../../../redux/coursesSlice";
import { selectIsThumbnails } from "../../../redux/firestoreSelectors";

// Components
import {
  TextEditor,
  TextEditorFooter,
  TextEditorHeader,
  TextEditorInput,
  TextEditorSavingIndicator
} from "../../SharedComponents/textEditor";
import PeerReviewTabs from "./PeerReviewTabs";
import PeerReviewTabPanels from "./PeerReviewTabPanels";
import PeerReviewReviewModal from "./PeerReviewReviewModal";
import PeerReviewReply from "./PeerReviewReply";
import ScrollBox from "../../SharedComponents/ScrollBox";
import RenderEditorContent from "../../SharedComponents/RenderEditorContent";
import PDFThumbBar from "../../reader/pdf/sidebar/PDFThumbBar";
import useConvertToTimezone from "../../../hooks/useConvertToTimezone";
import ToggleBtnConstructor from "../../SharedComponents/buttons/toggleBtnConstructor";

// Material UI
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Button, IconButton, Typography } from "@mui/material";
import TooltipWithIntl from "../../SharedComponents/tooltip/TooltipWithIntl";
import useCreatePortal from "../../../hooks/useCreatePortal";
import { pdfThumbnailsIconClicked } from "../../../redux/layoutSlice";
import { PdfControls } from "../../menus/PdfControls";
import HeaderToolbar from "../../menus/HeaderToolbar";

// Styles
const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    columnGap: theme.spacing(2)
  },
  tabs: {
    gridColumnEnd: "span 12",
    zIndex: 2
  },

  textContainer: {
    gridColumnEnd: "span 8",
    height: "calc(100vh - 168px)",
    position: "Relative",
    paddingInlineStart: theme.spacing(5)
  },

  reviewsContainer: {
    display: "flex",
    gridColumnEnd: "span 4",
    height: "calc(100vh - 168px)"
  },

  reviewContainerExpanded: {
    gridColumn: "3 / 11",
    paddingInline: "0"
  },

  editorActive: {
    paddingBlock: theme.spacing(3),
    paddingInlineEnd: theme.spacing(5),
    "& $reviewHeader": {
      borderBottom: "1px solid",
      borderBottomColor: theme.palette.grey.main,
      paddingBlockStart: theme.spacing(1.5),
      paddingInline: theme.spacing(3),
      height: theme.spacing(9)
    },
    "& $userInput": {
      height: `calc(100% - ${theme.spacing(9)})`
    }
  },

  paddedScrollBox: {
    paddingInlineEnd: theme.spacing(5),
    paddingInlineStart: 0,
    paddingBlock: theme.spacing(3)
  },

  reviews: {
    color: theme.palette.text.primary,
    position: "relative",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    borderRadius: "4px",
    lineHeight: "2"
  },
  outlined: {
    border: `1px solid ${theme.palette.divider}`
  },

  reviewHeader: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    color: theme.palette.text.blue,
    fontSize: "12px",
    fontWeight: 600,
    textTransform: "uppercase",
    letterSpacing: "1px",
    paddingBlock: theme.spacing(1.5)
  },

  reviewFooter: {
    marginBlockEnd: "12px"
  },

  userInput: {
    fontFamily: '"Crimson Pro", "Frank Ruhl Libre"',
    lineHeight: 1.5,
    fontSize: "16px"
  },

  userInputExpanded: {
    fontSize: "22px"
  },
  thumbnailContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "250px",
    gridColumnEnd: "span 1",
    height: "calc(100vh - 167px)"
  },
  textContainerWithThumbnails: {
    gridColumnEnd: "span 11"
  },
  lightIcon: {
    color: "#FFFFFF !important",
    backgroundColor: "transparent !important"
  }
}));

export default function PeerReviewReview({
  reply,
  setReply,
  currentTab,
  zenMode,
  setZenMode,
  onRenditionCreated,
  saveCurrentTabLocation,
  textBookmark,
  setCurrentTab,
  answerBookmark,
  answerRef
}) {
  // Hooks
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const { rendition } = useRendition();
  const { submission_id } = useQuery();
  const ActionButtonPortal = useCreatePortal(
    document && document.getElementById("global-action-btn")
  );
  const ThumbnailsPortal = useCreatePortal(
    document && document.getElementById("global-thumbnails-btn")
  );
  const PdfControlPortal = useCreatePortal(
    document && document.getElementById("global-pdfControl-btn")
  );
  const HeaderToolbarPortal = useCreatePortal(
    document && document.getElementById("global-headerToolBar-btn")
  );
  // Redux state
  const currentUser = useSelector((state) => state.user.auth);

  // this submission is the reviewer's submission
  const submission = useSelector((state) =>
    selectSubmissionCollaboration(state, Number(submission_id))
  );

  const task = useSelector((state) => selectTask(state, submission.task_id));
  const course = useSelector((state) =>
    selectCourseByTaskId(state, submission.task_id)
  );
  const parent_submission = useSelector((state) =>
    selectSubmission(state, submission.related_submission_id)
  );
  const text = useSelector(selectCurrentText);

  const question = useSelector((state) =>
    selectPeerReviewQuestion(state, submission.task_id)
  );
  const isPdf = useSelector(isPdfSelector);

  const reflection = useSelector((state) =>
    selectPeerReviewReflection(state, submission.task_id)
  );
  const review = useSelector((state) =>
    selectPeerReviewReview(state, Number(submission.id))
  );
  const isThumbnailsInView = useSelector((state) => selectIsThumbnails(state));
  const userUid = useSelector((state) => state.user.auth.uid);
  const fontSizeValue = useSelector((state) => state.user.userProfile.fontSize);
  const fontSize = useSelector((state) => state.user.fontSizeOptions);

  // Ephemeral state
  const [, TZDeadline] = useConvertToTimezone(
    task.collaborator_accept_date,
    course.timezone
  ); // This hook has to be after the redux stuff since it relays on feedbackTask
  const [dialogOpen, setDialogOpen] = useState(false);

  // Variables
  const currentTabIsText = currentTab === 1;

  const isActive = submission.status === "Pending";
  const isOwner = submission.owner === userUid;

  // Behavior

  // set breadcrumbs
  const author = text.author;
  const text_title = text.name;
  const course_name = course.name;
  const taskName = task.title;

  useEffect(() => {
    let parts = [];
    task &&
      task.id &&
      parts.push({
        text: intl.formatMessage({
          id: "appBar.tasks",
          defaultMessage: "Assignments"
        }),
        url: "/tasks",
        brightText: !!task
      });
    parts.push({
      text: course_name,
      url: `/tasks?course_id=${course.id}`,
      brightText: !!task
    });

    taskName &&
      parts.push({
        text: taskName,
        brightText: !!task
      });

    parts.push({
      url: `/tasks?course_id=${course.id}`,
      text: author + " - " + text_title,
      brightText: !!task
    });

    dispatch(
      setBreadcrumbs({
        breadcrumbs: parts,
        blue: task && task.id,
        showTextMenu: true
      })
    );
  }, [
    author,
    course.id,
    course_name,
    dispatch,
    intl,
    task,
    taskName,
    text_title
  ]);

  function createReview(content) {
    const { richText, plainText, wordCount } = content;
    peerReviewAPI
      .createReview({
        content: plainText,
        rich_text: richText,
        course_id: course.id,
        task_id: task.id,
        submission_id: submission.id,
        text_id: text.id,
        interaction_id: reflection.id,
        word_count: wordCount,
        interaction_type: INTERACTION_TYPES.REVIEW
      })
      .then(({ data }) => {});
  }

  function updateReview(interaction, content) {
    const { richText, plainText, wordCount } = content;
    dispatch(
      updateInteraction({
        disableRollback: true, // we don't want to to erase users input if the request fails
        interaction: interaction,
        update: {
          content: plainText,
          rich_text: richText,
          word_count: wordCount
        }
      })
    );
  }

  const toggleZenMode = () => {
    if (zenMode === false) saveCurrentTabLocation();
    setZenMode(!zenMode);
  };

  useEffect(() => {
    if (course.course_role === "Teacher" && isActive && TZDeadline > new Date())
      setDialogOpen(true);
  }, [TZDeadline, course.course_role, isActive]);

  useEffect(() => {
    rendition && rendition.themes.default(fontSize[fontSizeValue]);
  }, [fontSize, fontSizeValue, rendition]);

  useEffect(() => {
    if (!currentTabIsText && isThumbnailsInView) {
      dispatch(pdfThumbnailsIconClicked());
    }
  }, [dispatch, currentTabIsText, isThumbnailsInView]);

  useEffect(() => {
    if (isThumbnailsInView) dispatch(pdfThumbnailsIconClicked());
  }, []);

  function getTitle() {
    if (isOwner) return PEER_REVIEW_TASK.REVIEW.MY_REVIEW;
    else {
      return review.user_name
        ? `${review.user_name}'s review`
        : PEER_REVIEW_TASK.REVIEW.REVIEW;
    }
  }

  //render
  return (
    <Box className={classes.container}>
      <PeerReviewReviewModal
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        review={review}
        co_author={parent_submission.user_name}
      />
      {isPdf && currentTabIsText && (
        <>
          <ThumbnailsPortal>
            <ToggleBtnConstructor
              type={SECONDARY_SIDEBAR_ACTION_BUTTONS.THUBMNAILS}
              handleBtnAction={handleThumbnailPanel}
              secondarySideBarMode={isThumbnailsInView}
              disabled={zenMode || !currentTabIsText}
            />
          </ThumbnailsPortal>
          <PdfControlPortal>
            <PdfControls />
          </PdfControlPortal>
        </>
      )}
      {currentTabIsText && (
        <HeaderToolbarPortal>
          <HeaderToolbar />
        </HeaderToolbarPortal>
      )}
      <ActionButtonPortal>
        {isActive && (
          <Button
            onClick={() => setDialogOpen(true)}
            data-test-id="submit-task-button"
            size={PEER_REVIEW_TASK.SIZE.SMALL}>
            {intl.formatMessage({
              id: "task.submission.submit",
              defaultMessage: "Submit"
            })}
          </Button>
        )}

        {!isActive && (
          <Button
            onClick={() => {
              history.push(`/tasks?course_id=${course.id}`);
            }}
            data-test-id="submit-task-button"
            size={PEER_REVIEW_TASK.SIZE.SMALL}>
            {intl.formatMessage({
              id: "gr.confirm.btn",
              defaultMessage: "Done"
            })}
          </Button>
        )}
      </ActionButtonPortal>

      <Box className={classes.tabs}>
        <PeerReviewTabs
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          answerBookmark={answerBookmark}
          zenMode={zenMode}
          saveCurrentTabLocation={saveCurrentTabLocation}
        />
      </Box>

      {!zenMode && (
        <Box
          className={clsx(
            classes.textContainer,
            isThumbnailsInView && classes.textContainerWithThumbnails
          )}>
          <PeerReviewTabPanels
            currentTab={currentTab}
            zenMode={zenMode}
            setZenMode={setZenMode}
            onRenditionCreated={onRenditionCreated}
            textBookmark={textBookmark}
            answerRef={answerRef}
          />
        </Box>
      )}

      {isThumbnailsInView && !zenMode ? (
        <Box className={isThumbnailsInView && classes.thumbnailContainer}>
          <PDFThumbBar />
        </Box>
      ) : (
        <Box
          className={clsx(
            classes.reviewsContainer,
            isActive && classes.editorActive,
            zenMode && classes.reviewContainerExpanded
          )}>
          {isOwner && isActive && (
            <TextEditor content={review.rich_text}>
              <TextEditorHeader title={getTitle()}>
                <TooltipWithIntl
                  intlStringId={
                    zenMode
                      ? PEER_REVIEW_TASK.ZEN_MODE.ICON_EXIT
                      : PEER_REVIEW_TASK.ZEN_MODE.ICON_FULL
                  }
                  defaultMessage={
                    zenMode
                      ? PEER_REVIEW_TASK.ZEN_MODE.EXIT_FULL_SCREEN
                      : PEER_REVIEW_TASK.ZEN_MODE.FULL_SCREEN
                  }
                  placement="bottom">
                  <IconButton
                    aria-label={
                      zenMode
                        ? PEER_REVIEW_TASK.ZEN_MODE.EXIT_FULL_SCREEN
                        : PEER_REVIEW_TASK.ZEN_MODE.FULL_SCREEN
                    }
                    onClick={toggleZenMode}
                    size="large">
                    {zenMode ? <FullscreenExitIcon /> : <FullscreenIcon />}
                  </IconButton>
                </TooltipWithIntl>
              </TextEditorHeader>

              <TextEditorInput
                fontSize={
                  zenMode
                    ? PEER_REVIEW_TASK.SIZE.LARGE
                    : PEER_REVIEW_TASK.SIZE.SMALL
                }
                onChange={(content) =>
                  isEmpty(review)
                    ? createReview(content)
                    : updateReview(review, content)
                }
                placeholder={intl.formatMessage({
                  id: "task.open.placeholder",
                  defaultMessage: "Type your review here"
                })}
              />

              <TextEditorFooter
                showWordCount={true}
                wordLimit={question.wordLimit}>
                <TextEditorSavingIndicator status={review.status} />
              </TextEditorFooter>
            </TextEditor>
          )}

          {!isActive && (
            <ScrollBox className={classes.paddedScrollBox}>
              <Box className={classes.reviewHeader}>{getTitle}</Box>

              <Box className={classes.userInput}>
                <RenderEditorContent>{review.rich_text}</RenderEditorContent>
              </Box>
              {(currentUser.uid === reflection.user_uid || reply?.content) && (
                <PeerReviewReply reply={reply} setReply={setReply} />
              )}
            </ScrollBox>
          )}
        </Box>
      )}
    </Box>
  );
}
