// Dependencies
import React, { Children, useState } from "react";
import PropTypes from "prop-types";
import { useTextEditorContext } from "./TextEditor";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";

// Styles
const useStyles = makeStyles((theme) => ({
  footer: {
    height: 48,
    borderTop: "1px solid",
    borderTopColor: theme.palette.grey.main,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    flexGrow: 0
  },
  outlined: {
    borderTop: "1px solid",
    borderTopColor: theme.palette.grey.main
  },
  contained: {
    border: "none"
  },
  actions: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "flex-end"
  },
  action: {
    "&:not(:last-child)": {
      marginInlineEnd: theme.spacing(1)
    }
  },
  wordCount: {
    marginLeft: "auto",
    color: theme.palette.warning.main
  }
}));

// Behavior
function TextEditorFooter({ children, className, wordLimit, wordCount }) {
  //Hooks
  const classes = useStyles();
  const { variant } = useTextEditorContext();
  // Redux state

  // Variables
  const childrenArray = Children.toArray(children);

  // Behavior
  //Render
  return (
    <Box className={clsx(classes.footer, classes[variant], className)}>
      {/* Actions ↓ */}
      <Box className={classes.actions}>
        {childrenArray?.map((child, index) => (
          <Box key={index} className={classes.action}>
            {child}
          </Box>
        ))}
        <Box
          sx={{ paddingInlineStart: "8px" }}
          className={wordCount > wordLimit && classes.wordCount}>
          {wordLimit > 0 && wordCount >= 0 && ` ${wordCount}/ ${wordLimit}`}
        </Box>
      </Box>
    </Box>
  );
}

TextEditorFooter.propTypes = {
  modalVisible: PropTypes.bool,
  setModalVisible: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]), // TODO: tighten the enforcment of what can be passes in as a child
  className: PropTypes.string
};

export default TextEditorFooter;
