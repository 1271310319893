// Dependancies
import React from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";
import { accessorPropsType, callAccessor } from "../utils";

import makeStyles from "@mui/styles/makeStyles";

// Styles
const useStyles = makeStyles((theme) => ({
  bars: {
    // fill: Stacked bars are getting their fill from the group,
    transition: "all 0.3s ease-out"
  }
}));

function StackedBars({
  data,
  keyAccessor,
  xAccessor,
  yAccessor,
  zAccessor,
  widthAccessor,
  heightAccessor,
  ...props
}) {
  const classes = useStyles();
  return data.map((stack, i) => {
    //stack is an array of arrays
    return (
      <g key={stack.key} fill={callAccessor(zAccessor, stack, i)}>
        {stack.map((bar, i) => {
          //   bar looks like this: [0, 13, data: {…}]
          let width = callAccessor(widthAccessor, bar, i);
          if (width) {
            //if width is larger than 0, make it at least 15 so text elements would have a place
            width = Math.max(width, 15);
          }
          width = d3.max([width, 0]);
          const height = callAccessor(heightAccessor, bar, i);
          const text = bar[1] - bar[0] || "";

          return (
            <React.Fragment key={keyAccessor(bar, i)}>
              <rect
                {...props}
                className={classes.bars}
                x={callAccessor(xAccessor, bar, i)}
                y={callAccessor(yAccessor, bar, i)}
                width={width}
                height={height}
              />
              <text
                fill="white"
                x={callAccessor(xAccessor, bar, i) + width - 4}
                y={callAccessor(yAccessor, bar, i) + height / 2}
                textAnchor="end"
                alignmentBaseline="middle">
                {text}
              </text>
            </React.Fragment>
          );
        })}
      </g>
    );
  });
}

StackedBars.propTypes = {
  data: PropTypes.array,
  keyAccessor: accessorPropsType,
  xAccessor: accessorPropsType,
  yAccessor: accessorPropsType,
  widthAccessor: accessorPropsType,
  heightAccessor: accessorPropsType
};

StackedBars.defaultProps = {};

export default StackedBars;
