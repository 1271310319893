import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { TableRow } from "@mui/material";

export default function SortableText(props) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: `sort-libary-texts-${props.id}`,
      data: {
        textId: props.id,
        type: "SortLibraryTexts",
        onSortTexts: props.onSortTexts
      }
    });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };
  const sortableProps =
    props.course?.course_role === "Teacher"
      ? { ref: setNodeRef, style: style, ...attributes, ...listeners }
      : {};
  const { onSortTexts, ...nonDnDProps } = props;

  return (
    <TableRow {...sortableProps} {...nonDnDProps} role={"row"}>
      {props.children}
    </TableRow>
  );
}
