import React from "react";
import { Route, Switch } from "react-router-dom";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import CoursesSelectorSidebar from "./components/SharedComponents/courseSelectorSidebar/CourseSelectorSidebar";
import { useGetTheme } from "./hooks";

export default function Sidebar() {
  const theme = useGetTheme();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Switch>
          {/* Exclude sidebar from create new task */}
          <Route path="/tasks/new" />
          <Route exact path="/" component={CoursesSelectorSidebar} />
          <Route exact path="/tasks" component={CoursesSelectorSidebar} />
          <Route path="/library" component={CoursesSelectorSidebar} />
        </Switch>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
