import React, { useState } from "react";
import PropTypes from "prop-types";
import { httpCallables } from "../../firebase";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Button,
  DialogActions
} from "@mui/material";
function CreateNewIntitutionDialog({
  newInstitutionDialogVisible,
  setNewInstitutionDialogVisible
}) {
  const [institutionName, setInstitutionName] = useState("");
  const [lmsClient, setLmsClient] = useState(null);

  const handleCreateInstitution = () => {
    httpCallables
      .institutionsFunctions({
        func_name: "createInstitution",
        institutionName,
        lmsClient
      })
      .then(() => {
        setNewInstitutionDialogVisible(false);
        httpCallables
          .institutionsFunctions({ func_name: "getAllInstitutions" })
          .then(({ data }) => {
            setInstitutionName("");
          });
      });
  };

  function handleClose() {
    setInstitutionName("");
    setNewInstitutionDialogVisible(false);
  }
  return (
    <Dialog
      open={newInstitutionDialogVisible} // Fixed typo in prop name
      onClose={handleClose}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Add an institution</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              onChange={(e) => {
                setInstitutionName(e.target.value);
              }}
              autoFocus
              required
              margin="dense"
              id="institution_name"
              label="Institution name"
              type="text"
              value={institutionName}
              fullWidth
              inputProps={{
                "aria-label": "institution-name-input" // Added aria-label
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              onChange={(e) => {
                setLmsClient(e.target.value);
              }}
              margin="dense"
              id="lms_client_id"
              label="LMS client id"
              type="text"
              value={lmsClient || ""}
              fullWidth
              inputProps={{
                "aria-label": "institution-name-input" // Added aria-label
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleCreateInstitution} color="primary">
          Create institution
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CreateNewIntitutionDialog.propTypes = {
  institutions: PropTypes.array,
  setInstitutions: PropTypes.func,
  newInstitutionDialogVisible: PropTypes.bool,
  setNewInstitutionDialogVisible: PropTypes.func
};

export default CreateNewIntitutionDialog;
