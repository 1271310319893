import React, { useEffect } from "react";
import { logout } from "../../redux/userSlice";
import { firebaseApp } from "../../firebase";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PangeaSpinner from "../SharedComponents/PangeaSpinner";
import { captureException } from "../../utils/errorHandlers";
import { userAPI } from "../../api";
import { ACTIVITY_EVENT, IDLE_PERIOD } from "../../consts";
import { add } from "date-fns";
function Logout() {
  const history = useHistory();
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.firebase.auth.uid);

  useEffect(() => {
    const now = new Date();
    const shouldAwait = true;
    const logoutUser = async () => {
      try {
        await userAPI.log(
          {
            action_name: "ACTIVITY_EVENT",
            user_id: userId,
            created_at: now,
            ttl: add(now, { months: 1 }),
            payload: {
              type: ACTIVITY_EVENT.END,
              idle_period: IDLE_PERIOD
            }
          },
          shouldAwait
        );
        await firebaseApp.auth().signOut();
        dispatch(logout());
        history.push("/");
      } catch (err) {
        captureException(err);
      }
    };

    logoutUser();
  }, [history, dispatch, userId]);

  return <PangeaSpinner />;
}

export default Logout;
