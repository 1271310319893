// Dependencies
import { captureException } from "../../../utils/errorHandlers";
import {
  fetchMentor,
  mapStateToFetchProps,
  orchestrateMentorResponse
} from "./utils";
import { v4 as uuid } from "uuid";
// Redux
import store from "../../store";
import {
  incomingMessage,
  initMentor,
  sessionIsLoading,
  sessionLoaded,
  closeChatbox
} from "../../chatSlice";
import { addSnackbarItem } from "../../snackbarSlice";

// rxJs
import { ofType } from "redux-observable";
import { EMPTY } from "rxjs";
import {
  tap,
  catchError,
  concatMap,
  map,
  mergeMap,
  withLatestFrom,
  filter,
  pipe
} from "rxjs/operators";
import { selectIsImpersonation } from "../../userSlice";

export const initMentorEpic = (action$, state$) => {
  return action$.pipe(
    ofType(initMentor.type),
    withLatestFrom(state$),
    filter(([action, state]) => {
      // this is a workaround, we don't have a session id yet so we use the question id in order to know if the session in loading
      const { interactionId } = action.payload;
      const { loadingSessions } = state.chat;
      return (
        !loadingSessions.includes(interactionId) &&
        !selectIsImpersonation(state)
      );
    }),
    tap(([action, _]) => {
      // setting the session as loading using the question id
      // this should, idealy, not happen here
      const { interactionId } = action.payload;
      store.dispatch(sessionIsLoading({ sessionId: interactionId }));
    }),
    map(([action, state]) => mapStateToFetchProps(action, state)),
    mergeMap((props) =>
      fetchMentor(props).pipe(
        tap(({ interaction, ...rest }) => {
          // removeing the question id from the loading sessions
          // this should, idealy, not happen here
          store.dispatch(
            sessionLoaded({ sessionId: interaction.interaction_id })
          );
        }),
        catchError((error) => {
          store.dispatch(closeChatbox());
          store.dispatch(sessionLoaded({ sessionId: props.questionId }));
          store.dispatch(
            addSnackbarItem({
              id: uuid(),
              intlId: "initMentorEpic.error",
              intlDefaultMessage: "Error in chat, contact us for details."
            })
          );
          captureException(error, "Error in initMentorEpic");
          return EMPTY;
        })
      )
    ),
    concatMap(({ interaction, interactionNumber }) => {
      // in this case the sessionId is the id becouse it is the anchor interaction
      const sessionId = interaction.id;
      return orchestrateMentorResponse(
        interaction,
        sessionId,
        interactionNumber
      );
    }),
    map((payload) => ({ type: incomingMessage.type, payload })),
    catchError((error) => {
      captureException(error, "Error in initMentorEpic");
      return EMPTY;
    })
  );
};
