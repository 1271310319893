import React, { useState, useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { Typography } from "@mui/material";
import { useIntl, FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import { Check2 as CheckIcon } from "@styled-icons/bootstrap/Check2";
import {
  motion,
  useAnimation,
  MotionConfig,
  AnimatePresence
} from "framer-motion";

const useStyles = makeStyles((theme) => ({
  highlightsAnswersTogglerWrapper: {
    width: "100%"
  },
  description: {
    color: theme.palette.text.primary
  },
  descriptionContainer: {
    width: `calc(100% - ${theme.spacing(6)})`
  }
}));

function ExportStepsToggler({ step, setStep, type }) {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <div className={classes.highlightsAnswersTogglerWrapper}>
      <MotionConfig transition={{ type: "spring", damping: 15 }}>
        <AnimatePresence>
          {type === "texts" && step === 0 ? (
            <motion.div
              className={classes.descriptionContainer}
              initial={{ x: "-100%", opacity: 0 }}
              animate={{ x: "0", opacity: 1 }}
              exit={{ x: "-100%", opacity: 0 }}>
              <Typography variant="body2" className={classes.description}>
                Select the text you wish to share.
              </Typography>
            </motion.div>
          ) : type === "tasks" && step === 0 ? (
            <motion.div
              className={classes.descriptionContainer}
              initial={{ x: "-100%", opacity: 0 }}
              animate={{ x: "0", opacity: 1 }}
              exit={{ x: "-100%", opacity: 0 }}>
              <Typography variant="body2" className={classes.description}>
                Select the assignments you wish to share.
                <br />
                The related reading is attached
              </Typography>
            </motion.div>
          ) : (
            <motion.div
              className={classes.descriptionContainer}
              initial={{ x: "100%", opacity: 0 }}
              animate={{ x: "0", opacity: 1 }}
              exit={{ x: "100%", opacity: 0 }}>
              <Typography variant="body2" className={classes.description}>
                Select the destination course
              </Typography>
            </motion.div>
          )}
        </AnimatePresence>
      </MotionConfig>
    </div>
  );
}

export default ExportStepsToggler;
