import React from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  menuItem: {
    width: 400
  },
  select: {}
}));
const SelectStudent = ({ selectedUser, setSelectedUser, courseUsers }) => {
  const classes = useStyles();
  if (!courseUsers.length) return;
  return (
    <FormControl sx={{ m: 1, width: 140, minWidth: 140 }} size="small">
      <InputLabel id="select-label-student">Student</InputLabel>

      <Select
        value={selectedUser.name}
        className={classes.select}
        label="Student">
        {courseUsers.map((student) => (
          <MenuItem
            key={student.id}
            value={student.name}
            className={classes.menuItem}
            onClick={() => setSelectedUser(student)}>
            {`${student.name}`}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectStudent;
