// Dependencies
import React from "react";

// Components
import PieChartLegend from "./PieChartLegend";
import PieChartBody from "./PieChartBody";

//Redux

// Material-UI
import { Box } from "@mui/material";

function PieChart({
  type,
  data,
  totalSubmissions,
  includeFutureAssignments = true,
  courseAssignments = []
}) {
  // Hooks

  // Redux

  // Ephemeral state

  // Behavior
  return (
    <Box
      sx={{
        display: "flex",
        flexFlow: "row nowrap",
        width: "100%",
        alignItems: "flex-start"
      }}>
      <PieChartLegend
        type={type}
        data={data}
        totalSubmissions={totalSubmissions}
        includeFutureAssignments={includeFutureAssignments}
        courseAssignments={courseAssignments}
      />
      <PieChartBody
        type={type}
        data={data}
        totalSubmissions={totalSubmissions}
        includeFutureAssignments={includeFutureAssignments}
        courseAssignments={courseAssignments}
      />
    </Box>
  );
}

export default PieChart;
