/* eslint react/prop-types: 0 */

// Dependencies
import React from "react";
import { TextField } from "@mui/material";

const Temperature = ({ temperature, setTemperature }) => {
  return (
    <>
      <TextField
        variant="outlined"
        onChange={(e) => {
          setTemperature(e.target.value);
        }}
        value={temperature}
        aria-label="course-academic-year-input"
        autoFocus
        margin="dense"
        id="course id to extract data from"
        label="temperature"
        type="number"
      />
    </>
  );
};

export default Temperature;
