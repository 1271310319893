import React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    alignSelf: "flex-start",
    paddingBlockEnd: theme.spacing(1)
  }
}));

const GraphTitle = ({ text }) => {
  const classes = useStyles();
  return (
    <Box component="header" className={classes.title}>
      <Typography variant="subtitle1">{text}</Typography>
    </Box>
  );
};

export default GraphTitle;
