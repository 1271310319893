export const stepTitle = {
  texts: {
    0: "Select Texts",
    1: "Select Destination"
  },
  tasks: {
    0: "Select Assignments",
    1: "Select Destination"
  }
};

export const COURSE_MATERIAL_TYPE = {
  TEXT: "texts",
  TASK: "tasks"
};

export const ACCEPTENCE_STATUS = {
  PENDING: "pending",
  ACCEPTED: "accepted",
  DENIED: "denied"
};
