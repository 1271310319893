// Dependencies
import { captureException } from "../../../utils/errorHandlers";
import { CHAT, FEATURES } from "../../../consts";

// Redux
import { initMentor, openChatbox } from "../../chatSlice";
import { mentorFabClicked } from "../../fabSlice";
// rxJs
import { ofType } from "redux-observable";
import { EMPTY } from "rxjs";
import { map, withLatestFrom, catchError } from "rxjs/operators";
import { determineFeatureByPathname } from "../../firestoreSelectors";

export const mentorFabClickedEpic = (action$, state$) => {
  const isReader =
    determineFeatureByPathname(
      state$.value.router.location.pathname
    ).toUpperCase() === FEATURES.READER;
  return action$.pipe(
    ofType(mentorFabClicked.type),
    withLatestFrom(state$),
    map(([action, state]) => {
      const { selectedInteractionId } = state.interactions;

      return state.chat.sessionId === selectedInteractionId
        ? {
            type: initMentor.type,
            payload: {
              chatType: isReader ? CHAT.TYPE.READER : CHAT.TYPE.REVIEW,
              interactionId: selectedInteractionId
            }
          }
        : { type: openChatbox.type, payload: {} };
    }),
    catchError((error) => {
      captureException(error, "Error in mentorFabClickedEpic");
      return EMPTY;
    })
  );
};
