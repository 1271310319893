import React, { useEffect, useState } from "react";

import clsx from "clsx";
import { useIntl } from "react-intl";
import { formatDistanceStrict } from "date-fns";
import { he, enUS } from "date-fns/locale";
import {
  COMMENT_PANEL_VIEW,
  appActions,
  PRIVACY,
  INTERACTION_TYPES
} from "../../consts";
import { commentsAPI } from "../../api";
import { useRendition } from "../../RenditionContext";
// Redux dependencies
import { useDispatch, useSelector } from "react-redux";
import {
  deleteContainer,
  selectThread,
  setCommentPanelState
} from "../../redux/realtimeInteractionsSlice";
import { selectCurrentQuestion } from "../../redux/interactionsSlice";
import {
  selectAvatar,
  selectLocale,
  selectTextDirection
} from "../../redux/firestoreSelectors";
//Components
import { CustomAvatar } from "../SharedComponents";
import {
  TextEditor,
  TextEditorButton,
  TextEditorFooter,
  TextEditorHeader,
  TextEditorInput
} from "../SharedComponents/textEditor";

// Material UI
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { showInText } from "../../utils/showInTextHelper";

const useStyles = makeStyles((theme) => ({
  commentBox: {
    font: '"Chivo"',
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    paddingBlock: theme.spacing(1),
    alignItems: "flex-end",
    fontSize: "14px",
    height: "fit-content"
  },
  buttonContainer: {
    paddingInline: theme.spacing(2)
  },
  commentTitle: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginTop: theme.spacing(1),
    whiteSpace: "nowrap"
  },
  commentHeader: {
    display: "inline-flex",
    justifyContent: "flex-start",
    paddingInlineStart: theme.spacing(2),
    paddingBlockEnd: theme.spacing(0.5),
    textTransform: "none",
    color: theme.palette.text.primary
  },
  username: {
    display: "-webkit-box",
    "-webkit-box-orient": "horizontal"
  },
  dateCreated: {
    color: theme.palette.text.disabled,
    paddingLeft: theme.spacing(0.8)
  },
  commentContent: {
    display: "flex",
    whiteSpace: "normal",
    flexDirection: "column",
    paddingInline: theme.spacing(2),
    fontSize: "14px",
    color: theme.palette.text.secondary
  },
  button: {
    color: theme.palette.text.disabled,
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "transparent",
      color: theme.palette.secondary.main
    },
    padding: 0,
    margin: 0,
    marginInlineEnd: theme.spacing(1.5),
    minWidth: "max-content"
  },
  showInTextButton: {
    color: theme.palette.text.disabled,
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  showInTextIcon: {
    color: theme.palette.text.disabled,
    "&:hover": {
      color: theme.palette.secondary.main
    }
  },
  avatar: {
    height: "26px",
    width: "26px",
    fontSize: "small",
    marginInlineEnd: theme.spacing(0.5)
  }
}));
const Comment = ({
  index,
  comment,
  setSortedThread = null,
  stopNumber = null
}) => {
  // Hooks
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const rendition = useRendition();
  // Redux State
  const avatar = useSelector((state) => selectAvatar(state));

  // Ephemeral state
  const [isReadOnly, setIsReadOnly] = useState(true);
  // Redux State
  const selectedThreadId = useSelector(
    (state) => state.realtimeInteractions.selectedThreadId
  );
  const locale = useSelector((state) => selectLocale(state));
  const textDirection = useSelector((state) => selectTextDirection(state));

  const thread = useSelector((state) => selectThread(state, selectedThreadId));
  const currentUser = useSelector((state) => state.firebase.auth);

  const currentQuestionIndex = useSelector(selectCurrentQuestion).order;
  //Variables
  const isAuthor = currentUser.uid === comment?.user_uid;
  const localeToDateFnsOnj = {
    he: he,
    en: enUS
  };
  // Behavior
  const deleteCommentFromThread = (comment) => {
    commentsAPI.deleteComment(comment).then((success) => {
      if (success) {
        setViewByThreadState(comment);
      }
    });
  };

  function setViewByThreadState(comment) {
    let filteredThread = thread.filter((i) => i.id !== comment.id);
    setSortedThread(filteredThread);
    if (!filteredThread.length) {
      if (comment.privacy === PRIVACY.ME_ONLY)
        dispatch(deleteContainer(comment.interaction_id));
      dispatch(setCommentPanelState(COMMENT_PANEL_VIEW.ALL_THREADS));
    }
  }
  async function updateCommentThread(content) {
    const { richText, plainText, wordCount } = content;
    comment = {
      ...comment,
      content: plainText,
      rich_text: richText,
      word_count: wordCount
    };

    commentsAPI.updateComment(comment).then((success) => {
      if (success) {
        // TODO: open snackbar
        // Implament undo
        return true;
      }
    });
  }

  const showInTextHandler = () => {
    const renditionObj = rendition.rendition;
    showInText(appActions.SHOULD_LOG, comment, currentUser.uid, renditionObj);
  };

  if (!comment | !comment?.id)
    return <Box className={classes.commentBox}>There are no insights</Box>;
  return (
    <Box className={classes.commentBox}>
      <Box className={classes.commentTitle}>
        <TextEditor
          content={comment.rich_text}
          variant="contained"
          className={classes.editor}
          disabled={isReadOnly}>
          <Box>
            <TextEditorHeader
              title={
                <>
                  <CustomAvatar
                    className={classes.avatar}
                    fontSize="small"
                    id={
                      comment.interaction_type === INTERACTION_TYPES.REASON
                        ? comment.id
                        : comment.user_uid
                    }
                    src={comment.user_uid === currentUser.uid ? avatar : ""}
                    name={
                      comment.interaction_type === INTERACTION_TYPES.REASON
                        ? "insights"
                        : currentUser.displayName
                    }
                    minimized={true}
                  />

                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.username}>
                    {comment.interaction_type === INTERACTION_TYPES.REASON
                      ? "Insights"
                      : isAuthor
                        ? intl.formatMessage({
                            id: "comments.commentOwner",
                            defaultMessage: "Me"
                          })
                        : comment.user_name}
                  </Typography>
                </>
              }
              className={classes.commentHeader}>
              <Typography
                component="span"
                variant="caption"
                className={classes.dateCreated}
                dir={textDirection}>
                {comment.interaction_type === INTERACTION_TYPES.REASON
                  ? `Q${currentQuestionIndex + 1} Stop ${stopNumber}`
                  : formatDistanceStrict(
                      new Date(comment.created_at),
                      new Date(),
                      {
                        addSuffix: true,
                        locale: localeToDateFnsOnj[locale]
                      }
                    )}
              </Typography>
            </TextEditorHeader>
          </Box>
          <TextEditorInput
            className={clsx(classes.commentContent, classes.buttonContainer)}
          />

          {isAuthor && (
            <>
              <TextEditorFooter showWordCount={false}>
                {isReadOnly ? (
                  <Box className={classes.buttonContainer}>
                    <TextEditorButton
                      className={classes.button}
                      onClick={() => {
                        setIsReadOnly(false);
                      }}
                      type="edit">
                      {intl.formatMessage({
                        id: "comments.buttons.editReply",
                        defaultMessage: "Edit"
                      })}
                    </TextEditorButton>
                    <TextEditorButton
                      disabled={false}
                      onClick={() => {
                        deleteCommentFromThread(comment);
                      }}
                      className={classes.button}>
                      {intl.formatMessage({
                        id: "comments.buttons.deleteReply",
                        defaultMessage: "Delete"
                      })}
                    </TextEditorButton>
                  </Box>
                ) : (
                  <Box className={classes.buttonContainer}>
                    <TextEditorButton
                      className={classes.button}
                      onClick={async (content) => {
                        setIsReadOnly(true);
                        await updateCommentThread(content);
                      }}
                      type="update">
                      {intl.formatMessage({
                        id: "comments.thread.postReply",
                        defaultMessage: "Save"
                      })}
                    </TextEditorButton>
                    <TextEditorButton
                      className={classes.button}
                      onClick={() => {
                        setIsReadOnly(true);
                      }}
                      type="restore">
                      {intl.formatMessage({
                        id: "comments.thread.cancelReply",
                        defaultMessage: "Cancel"
                      })}
                    </TextEditorButton>
                  </Box>
                )}
              </TextEditorFooter>
            </>
          )}
        </TextEditor>
      </Box>
    </Box>
  );
};

export default Comment;
