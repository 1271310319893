import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

// Redux dependencies
import { useSelector } from "react-redux";
import { selectCurrentText } from "../../../redux/textsSlice";

//API
import { userAPI } from "../../../api";
import { QUESTION, QUESTION_INTL, USER_ACTIONS } from "../../../consts";
// Components
import RichTooltip from "../../SharedComponents/RichTooltip";
import AssignmentQuestionAdditionalOptions from "./AssignmentQuestionAdditionalOptions";
import AssignmentQuestionMultiChoice from "./AssignmentQuestionMultiChoice";

import makeStyles from "@mui/styles/makeStyles";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {
  Box,
  MenuItem,
  TextField,
  IconButton,
  Typography,
  ListItemText,
  Paper,
  Select,
  Popover
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import RefreshIcon from "@mui/icons-material/Refresh";
import AIIcon from "../../SharedComponents/AIIcon";
import { TooltipWithIntl } from "../../SharedComponents";

// Styles
const useStyles = makeStyles((theme) => ({
  menuBg: {
    "& ul": {},
    borderBottomRightRadius: "8px",
    borderBottomLeftRadius: "8px",
    "& .MuiListItemText-root": { lineHeight: "unset" }
  },
  inputContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginTop: theme.spacing(3)
  },
  pointsCtr: {},
  pntInput: {
    width: "100px",
    height: "42px"
  },
  selectContainer: {
    verticalAlign: "bottom",
    "& .MuiListItemText-root": {
      marginTop: "3px"
    }
  },
  selectItem: {
    lineHeight: "1rem"
  },
  btncontainer: {},
  questionTxt: {
    flexBasis: "100%"
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "14px",
    lineHeight: "36px",
    borderTop: "1px solid #dedede"
  },
  questionOnly: {
    paddingBlockEnd: theme.spacing(3)
  },
  radio: {
    marginLeft: "20px"
  },
  popover: {
    left: "20px"
  }
}));

function AssignmentQuestion({
  id,
  taskId,
  key,
  quotes = [],
  interaction_subtype = "OPEN_ENDED",
  content,
  concepts,
  taskType,
  points,
  questionIndex,
  options = [],
  wordLimit,
  readOnly,
  onUpdate,
  onDelete,
  shouldSelect = -1,
  aiQuestion = false,
  regenerateAiQuestion,
  bot,
  tempId,
  aiQuestionGenerating = false
}) {
  // Hooks
  const classes = useStyles();
  const intl = useIntl();
  const wordLimitRef = useRef();
  const tempIdRef = useRef();

  // Redux State
  const text = useSelector(selectCurrentText);

  //Variables
  const questionTypes = [
    QUESTION.OPEN_ENDED,
    QUESTION.FIND_IN_TEXT,
    QUESTION.MULTI_CHOICE
  ];
  const [anchorEl, setAnchorEl] = useState(null);
  const shoQuestionTypeTooltip = Boolean(anchorEl);
  const tooltipId = shoQuestionTypeTooltip ? "tooltip-popover" : undefined;
  const [questionInput, setQuestionInput] = useState("");

  const handleTooltipClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleTooltipClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const isNewContent = tempId !== tempIdRef.current;
    if (content && aiQuestion && isNewContent && !id) {
      tempIdRef.current = tempId;
      let i = 0;
      const typingInterval = setInterval(() => {
        if (i < content.length) {
          setQuestionInput(content.slice(0, i + 1));
          i++;
        } else {
          clearInterval(typingInterval);
        }
      }, 20); // Adjust typing speed here

      return () => clearInterval(typingInterval);
    } else {
      setQuestionInput(content);
    }
  }, [content]);

  const renderTooltipBody = () => {
    return (
      <Box>
        <Typography variant="h6">
          {intl.formatMessage({
            id: QUESTION_INTL.OPEN_ENDED.translationId,
            defaultMessage: QUESTION_INTL.OPEN_ENDED.defaultMessage
          })}
        </Typography>
        <Typography variant="body2" sx={{ marginBottom: "10px" }}>
          {intl.formatMessage({
            id: "task.create.assignment.openEndedTooltip",
            defaultMessage: "Open Ended"
          })}
        </Typography>
        <Typography variant="h6">
          {intl.formatMessage({
            id: QUESTION_INTL.FIND_IN_TEXT.translationId,
            defaultMessage: QUESTION_INTL.FIND_IN_TEXT.defaultMessage
          })}
        </Typography>
        <Typography variant="body2" sx={{ marginBottom: "10px" }}>
          {intl.formatMessage({
            id: "task.create.assignment.findInTextTooltip",
            defaultMessage: "Find in Text"
          })}
        </Typography>
        <Typography variant="h6">
          {intl.formatMessage({
            id: QUESTION_INTL.MULTI_CHOICE.translationId,
            defaultMessage: QUESTION_INTL.MULTI_CHOICE.defaultMessage
          })}
        </Typography>
        <Typography variant="body2">
          {intl.formatMessage({
            id: "task.create.assignment.multiChoiceTooltip",
            defaultMessage: "Multi Choice"
          })}
        </Typography>
      </Box>
    );
  };

  const AiBlinkingDots = () => (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        height: "100%",
        marginLeft: "8px",
        position: "absolute",
        top: "25%",
        transform: "translateY(-50%)"
      }}>
      <span className="dot-1">.</span>
      <span className="dot-2">.</span>
      <span className="dot-3">.</span>
      <style>{`
        @keyframes blink {
          0% {
            opacity: 0;
          }
          33% {
            opacity: 1;
          }
          66% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
        .dot-1,
        .dot-2,
        .dot-3 {
          animation: blink 1.4s infinite;
          animation-fill-mode: both;
          font-size: 56px;
          font-family: Roboto;
          line-height: 0.5;
          display: inline-block;
        }
        .dot-2 {
          animation-delay: 0.2s;
        }
        .dot-3 {
          animation-delay: 0.4s;
        }
      `}</style>
    </Box>
  );

  // Render
  return (
    <Paper
      key={key}
      sx={{
        borderRadius: "12px",
        border: 1,
        borderColor: "rgba(0, 0, 0, 0.23)",
        boxShadow: 0,
        "&:hover": { boxShadow: readOnly ? 0 : 3 },
        padding: "24px"
      }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          maxHeight: "32px"
        }}>
        <Box>
          <Typography variant="h6" display="inline">
            {["guidedReading", "standard"].includes(taskType) &&
              `${intl.formatMessage({
                id:
                  taskType === "standard" ? "task.question" : "task.grQuestion",
                defaultMessage: "Question"
              })} #${questionIndex + 1} `}

            {interaction_subtype === "PEER_REVIEW" &&
              `${intl.formatMessage({
                id: "task.question",
                defaultMessage: "Question"
              })}`}
          </Typography>

          {taskType === "guidedReading" && (
            <Typography
              display="inline"
              style={{ fontSize: "12px", opacity: "0.5", fontWeight: "600" }}>
              {intl.formatMessage({
                id: "task.grQuestionOptional",
                defaultMessage: "Optional"
              })}
            </Typography>
          )}
        </Box>
        {aiQuestion && (
          <Box sx={{ display: "flex" }}>
            {!readOnly && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <TooltipWithIntl
                  defaultMessage="Regenerate question"
                  intlStringId="task.create.assignment.bot.regenerateQuestion"
                  placement="left">
                  <IconButton
                    aria-label="refresh-ai-question"
                    color="primary"
                    onClick={() => {
                      userAPI.logAction({
                        action_name: USER_ACTIONS.TASK_REGENERATE_AI_QUESTION,
                        payload: {
                          task_id: taskId
                        }
                      });

                      regenerateAiQuestion(tempId);
                    }}>
                    <RefreshIcon />
                  </IconButton>
                </TooltipWithIntl>
              </Box>
            )}
            <Box
              sx={{
                backgroundColor: "rgba(21, 101, 192, 0.3)",
                width: "56px",
                height: "56px",
                top: "-24px",
                right: "-8px",
                position: "relative",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "center",
                paddingBottom: "8px"
              }}>
              <AIIcon />
            </Box>
          </Box>
        )}
      </Box>
      <Box className={clsx(classes.inputContainer)}>
        <TextField
          variant="outlined"
          sx={{
            flex: 1,
            "& .MuiInputBase-root": {
              display: "flex",
              alignItems: "flex-start",
              minHeight: "56px",
              position: "relative"
            },
            "& .MuiInputBase-input": {
              paddingTop: 0,
              paddingBottom: 0
            }
          }}
          name="question-formulation"
          aria-label="task-question-formulation-input"
          InputProps={{
            readOnly: readOnly,
            startAdornment: aiQuestionGenerating ? <AiBlinkingDots /> : <></>,
            value: aiQuestionGenerating ? "" : questionInput || ""
          }}
          className={clsx(
            classes.questionTxt,
            interaction_subtype === "PEER_REVIEW" && classes.questionOnly
          )}
          multiline={true}
          minRows={1}
          label={intl.formatMessage({
            id: "task.question.formulation",
            defaultMessage: "Question"
          })}
          value={questionInput || ""}
          onChange={(e) => {
            setQuestionInput(e.target.value);
            onUpdate({ content: e.target.value }, tempId);
          }}
          disabled={readOnly}
        />
        <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Select
              variant="outlined"
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  "&:focus": { borderLeft: "1px solid rgba(0, 0, 0, 0.23)" }
                },
                "& .MuiOutlinedInput-root": { paddingLeft: "20px" },
                width: "200px"
              }}
              aria-label="task-question-type-input"
              id={`questionType${questionIndex}`}
              inputProps={{
                readOnly: readOnly
              }}
              name="select-question-type"
              className={clsx(classes.selectContainer)}
              value={interaction_subtype}
              dropdownmenuprops={{ classes: { paper: classes.menuBg } }}
              MenuProps={{ classes: { paper: classes.menuBg } }}
              onChange={(e) => {
                onUpdate(
                  {
                    interaction_subtype: e.target.value,
                    options: ["", "", ""]
                  },
                  tempId
                );
              }}
              disabled={readOnly}>
              {questionTypes.map((item, index) => {
                return (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      onUpdate(
                        {
                          interaction_subtype: item.interaction_subtype,
                          options: ["", "", ""]
                        },
                        tempId
                      );
                    }}
                    value={item.interaction_subtype}>
                    <ListItemText
                      disableTypography={true}
                      className={classes.selectItem}>
                      {intl.formatMessage({
                        id: QUESTION_INTL[item.interaction_subtype]
                          .translationId,
                        defaultMessage:
                          QUESTION_INTL[item.interaction_subtype].defaultMessage
                      })}
                    </ListItemText>
                  </MenuItem>
                );
              })}
            </Select>
            <IconButton
              onClick={handleTooltipClick}
              sx={{ justifyContent: "flex-end", marginLeft: "24px" }}
              color="primary"
              disabled={readOnly}
              aria-label={"Question type"}
              aria-haspopup={"true"}
              aria-expanded={shoQuestionTypeTooltip}
              aria-controls={tooltipId}>
              <InfoOutlinedIcon size="small" />
            </IconButton>
            {shoQuestionTypeTooltip && (
              <Popover
                id={tooltipId}
                open={shoQuestionTypeTooltip}
                anchorEl={anchorEl}
                onClose={handleTooltipClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center"
                }}
                className={classes.popover}>
                <RichTooltip
                  style={{ width: "300px" }}
                  title="task.create.assignment.questionTypeTooltipTitle"
                  bodyHtml={renderTooltipBody()}
                />
              </Popover>
            )}
          </Box>
        </Box>
      </Box>
      {interaction_subtype === "MULTI_CHOICE" && (
        <AssignmentQuestionMultiChoice
          options={options}
          shouldSelect={shouldSelect}
          readOnly={readOnly}
          onUpdate={onUpdate}
          tempId={tempId}
        />
      )}
      <Box className={clsx(classes.inputContainer)}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: "5px",
            width: "100%",
            maxWidth: "calc(100% - 264px)"
          }}>
          <AssignmentQuestionAdditionalOptions
            showDefineKeyConcepts={true}
            text={text}
            readOnly={readOnly}
            highlights={quotes}
            interactionSubtype={interaction_subtype}
            onUpdate={onUpdate}
            tempId={tempId}
            concepts={concepts}
            onConcepts={(concepts) => {
              onUpdate({ concepts: concepts }, tempId);
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}>
          <Box
            className={classes.wordLimit}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: "8px",
              width: "100%"
            }}>
            {interaction_subtype === "OPEN_ENDED" && (
              <TextField
                sx={{ maxWidth: "100px", width: "100px" }}
                variant="outlined"
                inputRef={wordLimitRef}
                aria-label="open-answer-input"
                id="standard-number"
                className={classes.wordInput}
                value={wordLimit >= 0 ? wordLimit : ""}
                onChange={(e) => {
                  onUpdate({ wordLimit: Number(e.target.value) }, tempId);
                }}
                type="number"
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{
                  readOnly: readOnly,
                  style: { height: "36px", marginRight: "5px" }
                }}
                label={intl.formatMessage({
                  id: "task.wordLimit",
                  defaultMessage: "Word Limit"
                })}
              />
            )}
            <TextField
              sx={{ maxWidth: "100px", width: "100px" }}
              variant="outlined"
              id={`points${questionIndex}`}
              aria-label="task-points-input"
              value={Number(points)}
              className={classes.pntInput}
              onChange={(e) => {
                let value = parseInt(e.target.value, 10);
                if (value < 0 || isNaN(value)) value = 0;
                onUpdate({ points: Number(value) }, tempId);
              }}
              type="text"
              inputMode="numeric"
              pattern="[0-9]*"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                readOnly: readOnly,
                style: { height: "36px" }
              }}
              label={intl.formatMessage({
                id: "task.questions.points",
                defaultMessage: "Points"
              })}
              disabled={readOnly}
            />
          </Box>
          <IconButton
            onClick={onDelete}
            sx={{ marginLeft: "24px", justifyContent: "flex-end" }}
            aria-label="delete question"
            disabled={readOnly}
            color="primary">
            <DeleteIcon size="small" />
          </IconButton>
        </Box>
      </Box>
    </Paper>
  );
}

AssignmentQuestion.propTypes = {
  id: PropTypes.string,
  question: PropTypes.object,
  quotes: PropTypes.array,
  concepts: PropTypes.array,
  type: PropTypes.number,
  points: PropTypes.number,
  questionIndex: PropTypes.number,
  options: PropTypes.array,
  text: PropTypes.object,
  wordLimit: PropTypes.number,
  showType: PropTypes.bool,
  showPoints: PropTypes.bool,
  readOnly: PropTypes.bool,
  onUpdate: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onDelete: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  key: PropTypes.number,
  aiQuestion: PropTypes.bool,
  regenerateAiQuestion: PropTypes.func,
  bot: PropTypes.object,
  tempId: PropTypes.string,
  aiQuestionGenerating: PropTypes.bool
};

export default AssignmentQuestion;
