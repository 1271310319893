// Dependencies
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useIntl } from "react-intl";
import { firebaseApp, httpCallables } from "../../../firebase";
import { useQuery } from "../../../hooks";

//Redux Dependencies
import { setBreadcrumbs } from "../../../redux/readerActionsSlice";
import { useSelector, useDispatch } from "react-redux";
import {
  setTasks,
  setSubmissions,
  selectTask,
  selectTaskSubmissions
} from "../../../redux/tasksSlice";
import {
  selectText,
  setSelectedTextId,
  setTextUrl
} from "../../../redux/textsSlice";
import { selectCourseByTaskId } from "../../../redux/coursesSlice";
import {
  setInteractions,
  selectQuestions
} from "../../../redux/interactionsSlice";

// Components
import OpenQuestionStats from "./OpenQuestionStats";
import MultiChoiceStats from "./MultiChoiceStats";
import FindInTextStats from "./FindInTextStats";
import StudentEvaluation from "./StudentEvaluation";
import ScrollBox from "../../SharedComponents/ScrollBox";

import makeStyles from "@mui/styles/makeStyles";
import { Box, Typography } from "@mui/material";
import { PangeaSpinner } from "../../SharedComponents";
// Styles
const useStyles = makeStyles((theme) => {
  return {
    container: {
      width: "100%"
    },
    content: {
      width: "82%",
      margin: "0 auto"
    },
    pageTitle: {
      marginTop: theme.spacing(5),
      marginBottom: "24px"
    },
    question: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(3),
      gridColumn: "1 / 4",
      boxShadow: 2
    }
  };
});

export default function StudentTaskStatsView({
  questions,
  task,
  text,
  submissions
}) {
  // Hooks
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { task_id } = useQuery();

  // Redux State
  const course = useSelector((state) =>
    selectCourseByTaskId(state, Number(task_id))
  );

  const [annonymize, setAnnonymize] = useState(true);
  // Derived State

  // Behavior
  if (questions.length)
    return (
      <Box className={classes.container}>
        <ScrollBox>
          <Box className={classes.content}>
            <Typography
              className={clsx(classes.left, classes.pageTitle)}
              variant="h4">
              {intl.formatMessage({
                id: "task.statistics.pageTitle",
                defaultMessage: "Assignment heat maps"
              })}
            </Typography>
            <Box component="header">
              {questions.map((question, index) => {
                return (
                  <React.Fragment key={question.id}>
                    {question.interaction_subtype === "MULTI_CHOICE" && (
                      <Box key={question.id} className={classes.question}>
                        <MultiChoiceStats
                          submissions={submissions}
                          question={question}
                          index={index}
                          task={task}
                          text={text}
                          isTeacher={false}
                        />
                      </Box>
                    )}
                    {question.interaction_subtype === "FIND_IN_TEXT" && (
                      <Box key={index} className={classes.question}>
                        <FindInTextStats
                          submissions={submissions}
                          question={question}
                          index={index}
                          task={task}
                          text={text}
                          isTeacher={false}
                        />
                      </Box>
                    )}

                    {["OPEN_ENDED"].includes(question.interaction_subtype) && (
                      <Box key={index} className={classes.question}>
                        <OpenQuestionStats
                          submissions={submissions}
                          question={question}
                          index={index}
                          task={task}
                          text={text}
                          isTeacher={false}
                          annonymizeForm={annonymize}
                        />
                      </Box>
                    )}
                  </React.Fragment>
                );
              })}
              <StudentEvaluation submissions={submissions} />
            </Box>
          </Box>
        </ScrollBox>
      </Box>
    );
  else return <PangeaSpinner />;
}
