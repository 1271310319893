// Dependencies
import React from "react";
import PropTypes from "prop-types";

// Material UI
import { Box } from "@mui/system";
import { DatePicker, DateTimePicker, TimePicker } from "@mui/x-date-pickers";

function ButtonField(props) {
  const {
    children,
    InputProps: { ref } = {},
    inputProps: { "aria-label": ariaLabel } = {}
  } = props;

  return (
    <Box aria-label={ariaLabel} ref={ref}>
      <input type="hidden" />
      {children}
    </Box>
  );
}

export default function DeadLineDatePicker(props) {
  switch (props.type) {
    case "date":
      return (
        <DatePicker
          open={props.isOpen}
          onOpen={() => props.setIsOpen(true)}
          onClose={() => props.setIsOpen(false)}
          label={props.label}
          value={new Date(props.deadline)}
          onAccept={props.onChange}
          minDate={props.minDate || new Date()}
          slots={{ field: ButtonField }}
          slotProps={{ field: { ...props } }}
        />
      );
    case "time":
      return (
        <TimePicker
          open={props.isOpen}
          onOpen={() => props.setIsOpen(true)}
          onClose={() => props.setIsOpen(false)}
          label={props.label}
          value={new Date(props.deadline)}
          onAccept={props.onChange}
          minDate={props.minDate || new Date()}
          slots={{ field: ButtonField }}
          slotProps={{ field: { ...props } }}
        />
      );
    case "dateTime":
      return (
        <DateTimePicker
          open={props.isOpen}
          onOpen={() => props.setIsOpen(true)}
          onClose={() => props.setIsOpen(false)}
          label={props.label}
          value={new Date(props.deadline)}
          onAccept={props.onChange}
          minDate={props.minDate || new Date()}
          slots={{ field: ButtonField }}
          slotProps={{ field: { ...props } }}
        />
      );
  }
}

DeadLineDatePicker.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  deadline: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  type: PropTypes.oneOf(["date", "time", "dateTime"]).isRequired
};
