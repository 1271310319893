import { useRef, useLayoutEffect, useState, useCallback } from "react";
import ResizeObserver from "resize-observer-polyfill";

export const useResizeObserver = (customRef) => {
  const [observerEntry, setObserverEntry] = useState({});
  const [node, setNode] = useState(null);
  const observer = useRef(null);

  const disconnect = useCallback(() => observer.current?.disconnect(), []);

  const observe = useCallback(() => {
    observer.current = new ResizeObserver(([entry]) => setObserverEntry(entry));
    if (node) observer.current.observe(node);
  }, [node]);

  useLayoutEffect(() => {
    if (customRef?.current) {
      setNode(customRef.current);
    }
  }, [customRef]);

  useLayoutEffect(() => {
    observe();
    return () => disconnect();
  }, [disconnect, observe]);

  return {
    ref: setNode,
    entry: observerEntry,
    width: observerEntry.contentRect?.width,
    height: observerEntry.contentRect?.height
  };
};

export default useResizeObserver;
