import React from "react";
import useLogUserSession from "../../hooks/useLogUserSession";

export function ChatActivityRecorder({
  course_id,
  task_id,
  text_id,
  question_id,
  session_id
}) {
  useLogUserSession("CHAT_SESSION", {
    course_id,
    task_id,
    text_id,
    question_id,
    session_id
  });

  return <></>;
}
