import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useGetTheme } from "../../hooks";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { resetChat } from "../../redux/chatSlice";
import { httpCallables } from "../../firebase";
import store from "../../redux/store";
import { mentorFabClicked } from "../../redux/fabSlice";
import { CHAT } from "../../consts";

// Styles
const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    padding: 0,
    marginBottom: theme.spacing(3)
  },
  dialogContent: {
    padding: 0,
    marginBottom: theme.spacing(1.5)
  },
  dialogActions: {
    paddingBlock: 0,
    "& button": {
      fontWeight: "400",
      color: theme.palette.primary.main,
      textTransform: "uppercase"
    }
  }
}));

export default function RestartChatModal({
  modalVisible,
  setModalVisible,
  setDisableRestart
}) {
  //Hooks
  const classes = useStyles();
  const theme = useGetTheme();
  const selectedInteractionId = useSelector(
    (state) => state.interactions.selectedInteractionId
  );

  // Behavior
  function closeModal() {
    setModalVisible(false);
  }

  function onRestartClick() {
    setDisableRestart(true);
    httpCallables
      .coursesFunctions({
        func_name: "resetMentorForUserQuestion",
        question_id: selectedInteractionId
      })
      .then(({ data }) => {
        const { success } = data;
        const { deletedInteractions } = JSON.parse(data.payload);
        if (success && deletedInteractions) {
          store.dispatch(resetChat(deletedInteractions));
          store.dispatch(mentorFabClicked({ chatType: CHAT.TYPE.REVIEW }));
        }
        setDisableRestart(false);
      });
    closeModal();
  }

  return (
    <Dialog
      open={modalVisible}
      onClose={closeModal}
      aria-labelledby="restart-chat-dialog"
      aria-describedby="restart-chat"
      PaperProps={{
        sx: {
          width: "30%",
          padding: theme.spacing(2),
          textAlign: "start"
        }
      }}>
      <DialogTitle
        className={classes.dialogTitle}
        id="restart-chat-dialog-title">
        <FormattedMessage
          id="chat.restartConversation"
          defaultMessage="Restart Conversation"
        />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        Restarting will clear the chat history. Are you sure you want to
        continue?
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={closeModal}>
          <FormattedMessage id="general.cancel" defaultMessage="Cancel" />
        </Button>
        <Button onClick={onRestartClick}>
          <FormattedMessage id="chat.restart" defaultMessage="Restart" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

RestartChatModal.propTypes = {
  modalVisible: PropTypes.bool,
  setModalVisible: PropTypes.func
};
