import React, { useRef, useEffect } from "react";
import { InputBase } from "@mui/material";
import { useSelector } from "react-redux";
import { selectLocale } from "../../redux/firestoreSelectors";

export default function PangeaInputBase(props) {
  const myRef = useRef();
  const locale = useSelector((state) => selectLocale(state));
  useEffect(() => {
    myRef.current
      .getElementsByTagName("textarea")[0]
      .setAttribute("dir", "auto");
  }, []);
  let fontFamility =
    locale === "he"
      ? '"Open Sans Hebrew", "Rosario", "Helvetica", "Arial"'
      : "Chivo";
  return (
    <InputBase
      {...props}
      ref={myRef}
      style={{
        color: "inherit"
      }}
      inputProps={{
        style: {
          fontFamily: fontFamility
        }
      }}
    />
  );
}
