/* eslint react/prop-types: 0 */
// Dependencies
import React, { useEffect, useCallback } from "react";
import { firebaseFunctions } from "../../../firebase";

//Hooks
import { usePromptTemplate } from "./hooks/usePrompTemplate";

//Components
import PlaygroundBody from "./PlaygroundBody";
import Header from "./header/Header";

// Styles
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  container: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexFlow: "column",
    position: "relative",
    alignItems: "flex-start"
  }
}));

const initialState = {
  messages: [],
  conversation: [],
  allCourses: [],
  selectedCourse: {},
  courseTasks: [],
  selectedTask: {},
  taskQuestions: [],
  selectedQuestion: {},
  courseUsers: [],
  selectedUser: {},
  taskSubmissions: [],
  selectedSubmission: {},
  selectedAssistant: "mentor",
  assistantName: "mentor",
  temperature: 0,
  template: "",
  assistantInstructions: "",
  modelName: ""
};

const reducer = (state, action) => {
  // Sometimes We need to reset stte, this will hold the properties we want to reset
  let emptyObj = {};
  switch (action.type) {
    case "SET_MESSAGES":
      return { ...state, messages: action.payload };
    case "SET_CONVERSATION":
      return {
        ...state,
        conversation: [...state.conversation, ...action.payload]
      };
    case "SET_MODEL_CONFIG":
      //reset Conversation
      emptyObj = { messages: [], conversation: [] };
      return {
        ...state,
        ...emptyObj,
        template: action.payload.template,
        temperature: action.payload.config?.temperature || 0.89,
        modelName: action.payload.config?.model
      };
    case "SET_ALL_COURSES":
      return { ...state, allCourses: action.payload };
    case "SET_SELECTED_COURSE":
      if (state.selectedCourse !== action.payload) {
        emptyObj = { messages: [], conversation: [] };
      }
      return { ...state, ...emptyObj, selectedCourse: action.payload };
    case "SET_COURSE_TASKS":
      return { ...state, courseTasks: action.payload };
    case "SET_SELECTED_TASK":
      return { ...state, ...emptyObj, selectedTask: action.payload };
    case "SET_TASK_QUESTIONS":
      return { ...state, taskQuestions: action.payload };
    case "SET_SELECTED_QUESTION":
      if (state.selectedQuestion?.id !== action.payload?.id) {
        emptyObj = { messages: [], conversation: [] };
      }

      return { ...state, ...emptyObj, selectedQuestion: action.payload };
    case "SET_TASK_SUBMISSIONS":
      return { ...state, taskSubmissions: action.payload };
    case "SET_SELECTED_SUBMISSION":
      if (state.selectedSubmission?.id !== action.payload?.id) {
        emptyObj = { messages: [], conversation: [] };
      }
      return { ...state, ...emptyObj, selectedSubmission: action.payload };
    case "SET_COURSE_USERS":
      return { ...state, courseUsers: action.payload };
    case "SET_SELECTED_USER":
      if (state.selectedUser?.course_user !== action.payload?.course_user) {
        emptyObj = { messages: [], conversation: [] };
      }

      return { ...state, ...emptyObj, selectedUser: action.payload };
    case "SET_SELECTED_ASSISTANT":
      return { ...state, selectedAssistant: action.payload };
    case "SET_ASSISTANT_NAME":
      return { ...state, assistantName: action.payload };
    case "SET_ASSISTANT_INSTRUCTIONS":
      return { ...state, assistantInstructions: action.payload };
    case "SET_MODEL_NAME":
      return { ...state, modelName: action.payload };
    case "SET_TEMPERATURE":
      return { ...state, temperature: action.payload };
    case "SET_TEMPLATE":
      if (state.template !== action.payload) {
        emptyObj = { messages: [], conversation: [] };
      }
      return { ...state, ...emptyObj, template: action.payload };
    default:
      return state;
  }
};

const Playground = () => {
  const classes = useStyles();

  const getEmentorPrompt = firebaseFunctions.httpsCallable(
    "ementoring-interactionsv2"
  );

  //Hooks

  const [state, dispatch] = React.useReducer(reducer, initialState);
  const templateDoc = usePromptTemplate(state.assistantName);

  const [runQuery, setRunQuery] = React.useState(false);

  const user_id = state.selectedUser?.course_user;
  const prompt = state.assistantInstructions;
  const chatMessages = state.conversation;

  useEffect(() => {
    // only update if valid data
    templateDoc &&
      templateDoc.template &&
      dispatch({ type: "SET_MODEL_CONFIG", payload: templateDoc });
  }, [templateDoc]);

  function removeKeysAndProperties(arrayOfObjects) {
    // Map over the array and create new objects without "type" and "created_at" keys
    const newArray = arrayOfObjects.flatMap((obj) => {
      // Destructure the object to create a new object without the specified keys
      if (obj.type === "ANSWER") {
        //invalid, ignore
        return [];
      }
      const { content, role } = obj;
      return [
        {
          content: `${content}`,
          role
        }
      ];
    });

    return newArray;
  }

  const fetchChatData = useCallback(async () => {
    const response = await getEmentorPrompt({
      func_params: {
        promptAction: "chat",
        user_id,
        messages: removeKeysAndProperties(chatMessages) || [],
        prompt: prompt,
        config: {
          temperature: state.temperature,
          model: state.modelName
        }
      },
      func_name: "promptFunction"
    });
    const { data } = response;
    let { payload: mentorResponse } = data;
    if (mentorResponse.indexOf("```json") >= 0) {
      mentorResponse = mentorResponse.replace("```json", "").replace("```", "");
    }

    try {
      const { textResponse, generatedAnswer } = JSON.parse(
        JSON.parse(mentorResponse)
      );
      const message = {
        type: "INCOMING",
        content: textResponse,
        answer: generatedAnswer,
        created_at: new Date(),
        role: "assistant"
      };

      dispatch({
        type: "SET_CONVERSATION",
        payload: [message]
      });
    } catch (err) {
      console.log(err);
      const message = {
        type: "ANSWER",
        content: "INVALID RESPONSE",
        created_at: new Date(),
        role: "assistant"
      };

      dispatch({
        type: "SET_CONVERSATION",
        payload: [message]
      });
    }

    setRunQuery(false);
  }, [
    getEmentorPrompt,
    user_id,
    prompt,
    state.temperature,
    state.modelName,
    chatMessages,
    dispatch,
    setRunQuery
  ]);

  useEffect(() => {
    if (!runQuery) return;
    fetchChatData();
  }, [runQuery, fetchChatData]);

  const fetchPrompt = () => {
    const user_id = state.selectedUser?.course_user;
    const text_id = state.selectedQuestion?.text_id;
    const question_id = state.selectedQuestion?.id;
    const submission_id = state.selectedSubmission?.id;
    const fetchPromptData = async () => {
      const getEmentorPrompt = firebaseFunctions.httpsCallable(
        "ementoring-interactionsv2"
      );

      await getEmentorPrompt({
        func_params: {
          promptAction: "getPrompt",
          template: state.template,
          userId: user_id,
          textId: text_id,
          questionId: question_id,
          submissionId: submission_id
        },
        func_name: "promptFunction"
      }).then(({ data }) => {
        const { payload } = data;
        const { prompt } = JSON.parse(payload);

        dispatch({
          type: "SET_ASSISTANT_INSTRUCTIONS",
          payload: prompt
        });
      });
    };

    fetchPromptData();
  };

  return (
    <Box className={classes.container}>
      <Header
        // Course
        allCourses={state.allCourses}
        setAllCourses={(courses) =>
          dispatch({ type: "SET_ALL_COURSES", payload: courses })
        }
        selectedCourse={state.selectedCourse}
        setSelectedCourse={(course) =>
          dispatch({ type: "SET_SELECTED_COURSE", payload: course })
        }
        // Tasks
        courseTasks={state.courseTasks}
        setCourseTasks={(tasks) =>
          dispatch({ type: "SET_COURSE_TASKS", payload: tasks })
        }
        selectedTask={state.selectedTask}
        setSelectedTask={(task) =>
          dispatch({ type: "SET_SELECTED_TASK", payload: task })
        }
        // Questions
        taskQuestions={state.taskQuestions}
        setTaskQuestions={(questions) =>
          dispatch({ type: "SET_TASK_QUESTIONS", payload: questions })
        }
        selectedQuestion={state.selectedQuestion}
        setSelectedQuestion={(question) =>
          dispatch({ type: "SET_SELECTED_QUESTION", payload: question })
        }
        // Users
        courseUsers={state.courseUsers}
        setCourseUsers={(users) =>
          dispatch({ type: "SET_COURSE_USERS", payload: users })
        }
        selectedUser={state.selectedUser}
        setSelectedUser={(users) =>
          dispatch({ type: "SET_SELECTED_USER", payload: users })
        }
        // Submissions
        taskSubmissions={state.taskSubmissions}
        setTaskSubmissions={(submissions) =>
          dispatch({ type: "SET_TASK_SUBMISSIONS", payload: submissions })
        }
        selectedSubmission={state.selectedSubmission}
        setSelectedSubmission={(submission) =>
          dispatch({ type: "SET_SELECTED_SUBMISSION", payload: submission })
        }
        // SelectedAssistant
        selectedAssistant={state.selectedAssistant}
        setSelectedAssistant={(assistant) => {
          dispatch({ type: "SET_SELECTED_ASSISTANT", payload: assistant });
        }}
        // Start Query
        setRunQuery={setRunQuery}
        runQuery={runQuery}
        assistantInstructions={state.assistantInstructions}
      />
      <PlaygroundBody
        fetchPrompt={fetchPrompt}
        // Assistant
        selectedAssistant={state.selectedAssistant}
        setSelectedAssistant={(assistant) => {
          dispatch({ type: "SET_SELECTED_ASSISTANT", payload: assistant });
        }}
        // Assistant name
        assistantName={state.assistantName}
        setAssistantName={(name) => {
          dispatch({ type: "SET_ASSISTANT_NAME", payload: name });
        }}
        // Instructions
        assistantInstructions={state.assistantInstructions}
        setAssistantInstructions={(instructions) => {
          dispatch({
            type: "SET_ASSISTANT_INSTRUCTIONS",
            payload: instructions
          });
        }}
        // Model name
        modelName={state.modelName}
        setModelName={(model) => {
          dispatch({ type: "SET_MODEL_NAME", payload: model });
        }}
        // Temperature
        temperature={state.temperature}
        setTemperature={(temp) => {
          dispatch({ type: "SET_TEMPERATURE", payload: temp });
        }}
        // Template
        template={state.template}
        setTemplate={(template) => {
          dispatch({ type: "SET_TEMPLATE", payload: template });
        }}
        // messages
        messages={state.messages}
        setMessages={(messages) =>
          dispatch({ type: "SET_MESSAGES", payload: messages })
        }
        // Submission
        selectedSubmission={state.selectedSubmission}
        // Conversation
        conversation={state.conversation}
        setConversation={(conversation) =>
          dispatch({
            type: "SET_CONVERSATION",
            payload: conversation
          })
        }
        // Other stuff
        selectedUser={state.selectedUser}
        selectedQuestion={state.selectedQuestion}
        selectedTask={state.selectedTask}
        runQuery={runQuery}
        setRunQuery={setRunQuery}
        selectedCourse={state.selectedCourse}
      />
    </Box>
  );
};

export default Playground;
