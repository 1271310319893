// Dependencies
import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";
import { useTextEditorContext } from "./TextEditor";

// Styles
const useStyles = makeStyles((theme) => ({
  header: {
    paddingInline: theme.spacing(3),

    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    //TODO: Ideally all the fonst stlyle will go in a typography component
    fontFamily: "Chivo",
    fontSize: 12,
    textTransform: "uppercase",
    letterSpacing: "1px"
    //TODO: get this from theme so it will have a dark mode settings
  },
  outlined: {
    height: 48,
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.grey.main
  },
  contained: {
    border: "none"
  }
}));

function TextEditorHeader({ children, title, className }) {
  //Hooks
  const classes = useStyles();
  const { variant } = useTextEditorContext();

  // Redux state

  // Ephemeral state

  // Variables

  // Behavior

  //Render
  return (
    <Box
      className={clsx(classes.header, className, classes[variant])}
      sx={{ color: "text.secondary" }}>
      {/* Title ↓ */}
      {title}
      {/* Actions ↓ */}
      <Box>{children}</Box>
    </Box>
  );
}

TextEditorHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  children: PropTypes.element // TODO: tighten the enforcment of what can be passes in as a child
};

export default TextEditorHeader;
