import React, { useState } from "react";
import { Select, FormControl, MenuItem, InputLabel } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { truncateMiddle } from "../utils";

const useStyles = makeStyles(() => ({
  menuItem: {
    maxWidth: 400
  },
  select: {
    // width: "300px"
  }
}));

const SelectCourse = ({ allCourses, setSelectedCourse, selectedCourse }) => {
  const classes = useStyles();

  const handleCourseChange = (event) => {
    const selectedCourseObject = allCourses.find(
      (course) => course.name === event.target.value
    );
    setSelectedCourse(selectedCourseObject);
  };

  return (
    <FormControl sx={{ m: 1, width: 140, minWidth: 140 }} size="small">
      <InputLabel id="select-label-course">Course</InputLabel>

      <Select
        value={selectedCourse.name || ""}
        onChange={handleCourseChange}
        className={classes.select}
        label="Course"
        fullWidth
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: "500px" // Set your desired max height here
            }
          }
        }}>
        {allCourses.map((course) => (
          <MenuItem
            key={course.id}
            value={course.name}
            className={classes.menuItem}>
            {` ${course.id} - ${truncateMiddle(course.name, 25)}`}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectCourse;
