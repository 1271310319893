import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Box } from "@mui/material";
import { useGetTheme, useStepStage } from "../../hooks";
import { useSelector, useDispatch } from "react-redux";
import { selectCurrentText } from "../../redux/textsSlice";
import {
  selectGrQuestions,
  selectUserGrQuestions,
  setSelectedInteractionId
} from "../../redux/interactionsSlice";
import { interactionsAPI, grAPI, standardTaskAPI } from "../../api";
import EditingButton from "../EditingButton";
import makeStyles from "@mui/styles/makeStyles";
import { TASK } from "../../consts";

const useStyles = makeStyles((theme) => ({
  liLtr: {
    "& button": {
      right: "0px"
    }
  },
  editmodeLine: {
    paddingLeft: theme.spacing(2),
    minHeight: "40px"
  },
  selectedQuestion: {
    color: "black"
  },
  selectedQuestionTask: {
    backgroundColor: theme.palette.primary.light
  },
  selectedQuestionReader: {
    backgroundColor: theme.palette.secondary.light
  }
}));

function NewQuestion({
  newQuestionMode,
  setNewQuestionMode,
  scrollToBottom,
  taskId,
  submissionId
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const questions = useSelector(selectGrQuestions);
  const userQuestions = useSelector(selectUserGrQuestions);

  const selectedTextId = useSelector((state) => state.texts.selectedTextId);
  const text = useSelector(selectCurrentText);
  const [step] = useStepStage();
  const newQuestionModeRef = useRef(newQuestionMode); // we use a ref to store the correct value of "newQuestionMode" since it is not updated inside the addEventListener callback

  useEffect(() => {
    newQuestionModeRef.current = newQuestionMode;
  }, [JSON.stringify(newQuestionMode)]);

  useEffect(() => {
    // Useing an onBlur instead of a clickOutside becouse clicking on the reader's Iframe won't trigger a click outside
    window.addEventListener("blur", onBlur);
    function onBlur() {
      addQuestion();
    }
    return () => {
      window.removeEventListener("blur", onBlur);
    };
  }, []);

  function handleKeyPress(e) {
    if (e.key === "Enter") {
      addQuestion();
      e.preventDefault();
    }
  }

  function selectQuestion(question) {
    if (question.submission_id) {
      standardTaskAPI.updateSelectedQuestionId(question.id, question.task_id);
    } else {
      grAPI.updateSelectedQuestionId(question.id);
    }
  }

  async function addQuestion() {
    if (newQuestionModeRef.current.value) {
      const taskData = submissionId
        ? { task_id: taskId, submission_id: Number(submissionId) }
        : {};
      let question = {
        ...taskData,
        content: newQuestionModeRef.current.value,
        text_id: selectedTextId,
        course_id: text.course_id,
        order: userQuestions.length,
        question_context: TASK.TYPE.GUIDED_READING
      };
      interactionsAPI.createQuestion(question, selectedTextId, text.course_id, {
        afterReduxCallback: (id) => {
          // TODO: the first dispatch should go once switching to react-redux-firebase
          dispatch(setSelectedInteractionId(id));
          grAPI.updateGrState({ grQuestionId: id });
          question = { ...question, id: id };
        }
      });
      setNewQuestionMode({
        adding: false,
        value: "",
        addValue: "",
        shouldAdd: false
      });
      selectQuestion(question);
      scrollToBottom();
    }
  }

  return (
    <Box
      className={clsx(
        classes.liLtr,
        classes.editmodeLine,
        classes.selectedQuestion,
        submissionId
          ? classes.selectedQuestionTask
          : classes.selectedQuestionReader
      )}
      aria-labelledby="new-question-input">
      <EditingButton
        editIcon={<React.Fragment />}
        colorClass="black"
        onFocusOut={() => {
          addQuestion();
          setNewQuestionMode({
            adding: false,
            value: "",
            addValue: "",
            shouldAdd: false
          });
        }}
        multiline={true}
        key={questions.length}
        text={newQuestionMode.value}
        onChange={(e) => {
          setNewQuestionMode({ adding: true, value: e.target.value });
        }}
        editingMode={true}
        onKeyPress={handleKeyPress}
      />
    </Box>
  );
}

NewQuestion.propTypes = {
  newQuestionMode: PropTypes.object.isRequired,
  setNewQuestionMode: PropTypes.func.isRequired,
  scrollToBottom: PropTypes.func.isRequired,
  taskId: PropTypes.number,
  submissionId: PropTypes.number
};

export default NewQuestion;
