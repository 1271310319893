// Dependencies
import React, { useState, useEffect } from "react";

// Components
import StackedGraphBody from "./StackedGraphBody";
import StackedGraphLegend from "./StackedGraphLegend";

//Redux

// Material-UI
import { Box } from "@mui/material";

function SubmissionsStackedGraph({
  type,
  data,
  totalSubmissions = null,
  includeFutureAssignments = true,
  courseAssignments = {},
  courseStudents = []
}) {
  // Hooks

  // Redux
  const [selectedAssignment, setSelectedAssignment] = useState(null);
  // Ephemeral state

  function findLowestAssignmentId(objects) {
    let lowestId = Infinity;
    for (const obj of objects) {
      if (obj.id < lowestId) {
        lowestId = obj.id;
      }
    }
    return lowestId;
  }
  function setCurrentTaskData(taskId) {
    const assignment = courseAssignments.find(
      (assignment) => assignment.id === taskId
    );
    setSelectedAssignment(assignment);
  }
  useEffect(() => {
    const firstAssignmentId = findLowestAssignmentId(courseAssignments);
    setCurrentTaskData(firstAssignmentId);
  }, []);

  // Behavior
  return (
    <Box
      sx={{
        display: "flex",
        flexFlow: "column nowrap",
        width: "100%",
        height: "100%",
        alignItems: "center"
      }}>
      <StackedGraphBody
        type={type}
        data={data}
        includeFutureAssignments={includeFutureAssignments}
        courseAssignments={courseAssignments}
        setCurrentTaskData={setCurrentTaskData}
        selectedAssignment={selectedAssignment}
      />
      <StackedGraphLegend
        type={type}
        data={data}
        selectedAssignment={selectedAssignment}
        courseStudents={courseStudents}
      />
    </Box>
  );
}

export default SubmissionsStackedGraph;
