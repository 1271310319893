// Dependencies
import React, { useState, useRef, useEffect } from "react";
import { useIntl } from "react-intl";
import { firebaseApp, httpCallables } from "../../../firebase";
import { useQuery } from "../../../hooks";

//Redux Dependencies
import { setBreadcrumbs } from "../../../redux/readerActionsSlice";
import { useSelector, useDispatch } from "react-redux";
import {
  setTasks,
  setSubmissions,
  selectTask,
  selectTaskSubmissions
} from "../../../redux/tasksSlice";
import {
  selectText,
  setSelectedTextId,
  setTextUrl
} from "../../../redux/textsSlice";
import { selectCourseByTaskId } from "../../../redux/coursesSlice";
import {
  setInteractions,
  selectQuestions
} from "../../../redux/interactionsSlice";

// Components
import TeacherTaskStatsView from "./TeacherTaskStatsView";
import StudentTaskStatsView from "./StudentTaskStatsView";
import ScrollBox from "../../SharedComponents/ScrollBox";

import makeStyles from "@mui/styles/makeStyles";
import { Box, Typography } from "@mui/material";
import { USER_TYPE } from "../../../consts";
// Styles
const useStyles = makeStyles((theme) => {
  return {
    // FIXME: Temp fix for the background color, shold be removed once the background is set on a global level
    container: {
      width: "100%"
    },
    content: {
      width: "82%",
      margin: "0 auto"
    },
    pageTitle: {
      marginBottom: "24px"
    },
    pageSubTitle: {
      marginBottom: "80px"
    },
    main: {
      display: "grid",
      // this will have to change once the background color is sorted out
      paddingBottom: "48px",
      gridTemplateColumns: "0.65fr 1.1fr 1.1fr",
      gap: "24px 24px",
      gridAutoFlow: "row"
    },
    general: {
      gridColumn: "1 / 2",
      gridRow: "1"
    },
    timeManagment: {
      gridColumn: "2 / 3",
      gridRow: "1"
    },
    grades: {
      gridColumn: "3 / 4",
      gridRow: "1"
    },
    question: {
      gridColumn: "1 / 4"
    },

    generalStats: {
      textAlign: "center",
      "& h6": { margin: "0" }
    },
    statContainer: {
      padding: "32px"
    },
    questionTitle: {
      fontWeight: "bold",
      marginBottom: "12px"
    },
    lightIcon: {
      color: "white"
    },
    activeIcon: {
      backgroundColor: "#42A5F5",
      border: "1px solid #42A5F5",
      borderRadius: "8px",
      color: "#FFFFFF",
      "&:hover": {
        background: "#42A5F5"
      }
    }
  };
});

export default function TaskStatsView() {
  // Hooks
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { task_id } = useQuery();

  // Redux State

  const course = useSelector((state) =>
    selectCourseByTaskId(state, Number(task_id))
  );
  const courseRole = course.course_role;
  const task = useSelector((state) => selectTask(state, task_id));
  const text = useSelector((state) => selectText(state, task.text_id));
  const submissions = useSelector((state) =>
    selectTaskSubmissions(state, task_id)
  );
  const questions = useSelector((state) => selectQuestions(state, task_id));
  // Ephemeral State

  // Derived State
  // Ephemeral State

  // Derived State

  const isTeacher = courseRole === "Teacher";
  const isAdmin = courseRole === "Admin";

  // Behavior
  useEffect(() => {
    if (!task_id) {
      return;
    }
    httpCallables
      .taskFunctions({
        func_name: "readTaskStats",
        task_id: Number(task_id)
      })
      .then(
        ({ data }) => {
          const { task, submissions, interactions } = JSON.parse(data);
          dispatch(setTasks([task]));
          dispatch(
            setSubmissions(
              submissions.filter((s) => {
                return s.role?.toUpperCase() !== USER_TYPE.TEACHER;
              })
            )
          );
          dispatch(setInteractions(interactions));
        },
        [task_id]
      );
  }, [dispatch, task_id]);

  useEffect(() => {
    if (!text.id) return;
    dispatch(setSelectedTextId(text.id));
  }, [dispatch, text.id]);

  useEffect(() => {
    if (!text.id) return;
    httpCallables
      .textFunctions({
        func_name: "getTextDownloadUrl",
        courseId: text.course_id,
        fileUrl: text.file_url
      })
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setTextUrl({ url: response.data.payload, text_id: text.id })
          );
        }
      });
  }, [dispatch, text.course_id, text.file_url, text.id]);

  //breadcrumbs effect
  useEffect(() => {
    let parts = [];
    parts.push({
      url: "/tasks",
      text: intl.formatMessage({
        id: "appBar.tasks",
        defaultMessage: "Assignments"
      })
    });
    course.name &&
      parts.push({ url: `/tasks?course_id=${course.id}`, text: course.name });

    task.name &&
      parts.push({
        url: `/tasks?course_id=${course.id}&task_id=${task.id}`,
        text: task.name
      });
    parts.push({ url: `/task/stats?task_id=${task.id}`, text: "Statistics" });
    dispatch(setBreadcrumbs({ breadcrumbs: parts, blue: true }));
    return () => {};
  }, [course.name, dispatch, task.id, task.name, course.id, intl]);
  return (
    <Box className={classes.container}>
      <ScrollBox>
        {isTeacher || isAdmin ? (
          <TeacherTaskStatsView
            questions={questions}
            task={task}
            text={text}
            submissions={submissions}
          />
        ) : (
          <StudentTaskStatsView
            questions={questions}
            text={text}
            task={task}
            submissions={submissions}
          />
        )}
      </ScrollBox>
    </Box>
  );
}
