import { appActions, ENGAGEMENT_CATEGORIES } from "../../../../consts";
import { isAfter, isBefore } from "date-fns";

export function generateActionBucketsByDay(actions) {
  return actions.reduce((accumulator, current) => {
    // we are using the date as a key in a hash
    const key = getItemBucketName(current);

    if (key in accumulator) accumulator[key].push(current);
    else accumulator[key] = [];

    return accumulator;
  }, {});
}

export function getItemBucketName(item) {
  // const timeStamp = item.created_at;
  // return fromUnixTime(timeStamp._seconds).toDateString();
  return item.created_at.toDateString();
}

function countActions(action_name, actions, attr, filterOptions) {
  const actionsByName = filterActionByName(action_name, actions, filterOptions);
  const actionsByAttribute = groupActionsByAttribute(actionsByName, attr);

  return { action_name, count: actionsByAttribute };
}

function filterActionByName(action_name, actions, options = []) {
  let filteredActions = actions.filter(
    (action) => action.action_name === action_name
  );
  options.forEach((option) => {
    const [entry] = Object.entries(option);
    filteredActions = filteredActions.filter((action) => {
      // using double equal to check equality of undefind and null
      return action["payload"][entry[0]] == entry[1];
    });
  });
  return filteredActions;
}

function groupActionsByAttribute(actions, attribute) {
  return actions.reduce(
    (accumulator, current) => {
      const key = current.payload[attribute];
      accumulator.total++;

      if (!key) return accumulator;
      else if (key in accumulator) accumulator[key]++;
      else accumulator[key] = 1;

      return accumulator;
    },
    { total: 0 }
  );
}

export function filterActionByUserId(user_id, actions) {
  return actions.filter((action) => action.user_id === user_id);
}

export function aggregateActionsByDay(actionsHash) {
  const data = [];
  for (const bucket in actionsHash) {
    data.push({
      date: bucket,
      logins: countActions(appActions.LOGIN, actionsHash[bucket]),
      tasksVisited: countActions(
        appActions.READ_TASK,
        actionsHash[bucket],
        "task_id"
      ),
      feedbackVisited: countActions(
        appActions.READ_FEEDBACK,
        actionsHash[bucket],
        "task_id"
      ),
      tasksCreated: countActions(
        appActions.CREATE_TASK,
        actionsHash[bucket],
        "task_id"
      ),
      textVisited: countActions(
        appActions.READ_TEXT,
        actionsHash[bucket],
        "text_id"
      ),
      grQuestionsCreated: countActions(
        appActions.CREATE_INTERACTION,
        actionsHash[bucket],
        "text_id",
        [
          ({ interaction_type: "QUESTION" },
          { interaction_subtype: "GUIDED_READING" })
        ]
      ),
      grHighlightsCreated: countActions(
        appActions.CREATE_INTERACTION,
        actionsHash[bucket],
        "text_id",
        [{ interaction_subtype: "QUOTE" }, { task_id: null }]
      )
    });
  }
  return data;
}

export function formatDuration(value) {
  let durationMillisec = typeof value === "string" ? Number(value) : value;

  if (isNaN(durationMillisec) || durationMillisec === Infinity) {
    return "Invalid duration";
  }

  // Convert milliseconds to minutes, hours, and days
  const minutes = Math.floor(durationMillisec / 60000);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  let parts = [];
  if (days > 0) parts.push(days + "d");
  if (hours % 24 > 0) parts.push((hours % 24) + "h");
  if (minutes % 60 > 0) parts.push((minutes % 60) + "m");

  return parts.join(" ");
}

// user submissions utils
export function getSubmissionStatus(submission) {
  // takes submission obj and return an obj with user uid and submission status as string
  return {
    id: submission.owner,
    status: calculateSubmissionStatus(submission)
  };
}

export function calculateSubmissionStatus(submission) {
  // takses a submission obj and return the status a string
  const submissionsDate = submission.submission_date;
  const today = new Date();
  const dueDate = new Date(submission.due_date);
  const submissionDate = submissionsDate ? new Date(submissionsDate) : null;
  const isSubmitted = Boolean(submissionDate);

  // submitted before the due date
  if (isSubmitted && isBefore(submissionDate, dueDate)) return "submitted";
  // submitted after the due date
  else if (isSubmitted && isAfter(submissionDate, dueDate)) return "late";
  // not submitted and due date passed
  else if (!isSubmitted && isAfter(today, dueDate)) return "missed";
  // not submitted and due date not passed
  else return "pending";
}

export function countUserSubmissionStatus(data) {
  if (!data.length) return;
  return data.reduce(
    (accumulator, current) => {
      const id = current.id;
      const status = current.status;

      // initialize the user if not present. initializing...
      // ...  with id because it is needed in the final array
      if (!(id in accumulator)) accumulator.id = id;

      // initialize or add one to user's status
      accumulator[status]
        ? (accumulator[status] += 1)
        : (accumulator[status] = 1);

      return accumulator;
    },
    { pending: 0, submitted: 0, late: 0, missed: 0 }
  );
}

export function sortUsersBySubmissionStatus(userIds, submissions) {
  // Create an array to hold user objects with submission status count
  const usersWithStatusCount = [];

  // Iterate through each user
  userIds.forEach((userId) => {
    // Filter submissions for the current user
    const userSubmissions = submissions.filter(
      (submission) => submission.owner === userId
    );
    // console.log(userSubmissions);
    // Initialize submission status count with all counts set to zero
    let submissionStatusCount = {
      missed: 0,
      pending: 0,
      late: 0,
      submitted: 0
    };

    // Check if there are submissions for the user
    if (userSubmissions.length > 0) {
      // Calculate submission status count for the user
      submissionStatusCount = countUserSubmissionStatus(
        userSubmissions.map((submission) => getSubmissionStatus(submission))
      );
    }

    // Add user object with status count to the array
    usersWithStatusCount.push({
      id: userId,
      submissionStatusCount: submissionStatusCount
    });
  });

  // Sort users based on missed submissions, pending, late, and submitted
  usersWithStatusCount.sort((a, b) => {
    // Compare missed submissions count
    if (a.submissionStatusCount.missed !== b.submissionStatusCount.missed) {
      return b.submissionStatusCount.missed - a.submissionStatusCount.missed;
    }
    // Compare pending submissions count
    else if (
      a.submissionStatusCount.pending !== b.submissionStatusCount.pending
    ) {
      return b.submissionStatusCount.pending - a.submissionStatusCount.pending;
    }
    // Compare late submissions count
    else if (a.submissionStatusCount.late !== b.submissionStatusCount.late) {
      return b.submissionStatusCount.late - a.submissionStatusCount.late;
    }
    // Compare submitted submissions count
    else {
      return (
        b.submissionStatusCount.submitted - a.submissionStatusCount.submitted
      );
    }
  });

  return usersWithStatusCount;
}

export function sortCurrentUsersIdsBySubmissionStatus(
  currentUsers,
  sortedUsers
) {
  // Create a mapping of user IDs to their submission status counts
  const userStatusMap = {};
  sortedUsers.forEach((user) => {
    userStatusMap[user.id] = user.submissionStatusCount;
  });

  // Sort currentUsers based on submission status counts
  currentUsers.sort((a, b) => {
    // Get submission status counts for the current users
    const statusCountA = userStatusMap[a] || {
      missed: 0,
      pending: 0,
      late: 0,
      submitted: 0
    };
    const statusCountB = userStatusMap[b] || {
      missed: 0,
      pending: 0,
      late: 0,
      submitted: 0
    };

    // Compare missed submissions count
    if (statusCountA.missed !== statusCountB.missed) {
      return statusCountB.missed - statusCountA.missed;
    }
    // Compare pending submissions count
    else if (statusCountA.pending !== statusCountB.pending) {
      return statusCountB.pending - statusCountA.pending;
    }
    // Compare late submissions count
    else if (statusCountA.late !== statusCountB.late) {
      return statusCountB.late - statusCountA.late;
    }
    // Compare submitted submissions count
    else {
      return statusCountB.submitted - statusCountA.submitted;
    }
  });

  return currentUsers;
}
