// Dependencies
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useQuery, useStepStage } from "../../hooks";
import { HIGHLIGHTS, SECONDARY_SIDEBAR_ACTION_BUTTONS } from "../../consts";

import clsx from "clsx";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { selectSubmission } from "../../redux/tasksSlice";
import { isPdfSelector } from "../../redux/textsSlice";
import {
  selectCurrentQuestion,
  selectedQuestionHighlights
} from "../../redux/interactionsSlice";
import {
  selectIsComments,
  selectIsThumbnails
} from "../../redux/firestoreSelectors";
import { CHATBOX_STATUS } from "../../redux/chatSlice";
import { pdfThumbnailsIconClicked } from "../../redux/layoutSlice";
import { setHighlightColor } from "../../redux/readerActionsSlice";
import {
  handleCommentPanel,
  handleThumbnailPanel
} from "../SharedComponents/buttons/utils";

// Components
import ToggleBtnConstructor from "../SharedComponents/buttons/toggleBtnConstructor";
import { ScrollBox } from "../SharedComponents";
import CardsView from "../SharedComponents/cards/CardsView";
import BookView from "./BookView";
import useCreatePortal from "../../hooks/useCreatePortal";
import { PdfControls } from "../menus/PdfControls";
import HeaderToolbar from "../menus/HeaderToolbar";
import ReviewEvidenceToolTip from "../SharedComponents/ReviewEvidenceToolTip";
import EmptyState from "../SharedComponents/emptyState/EmptyState";
import OpenEnded from "../Tasks/standardTask/openEnded/OpenEnded";
import MultiChoice from "../Tasks/standardTask/multiChoice/MultiChoice";

// Material UI
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import useSelectedQuestion from "../../hooks/firestore/useSelectedQuestion";

// Styles
const useStyles = makeStyles((theme) => {
  return {
    innerWrapper: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      top: 0,
      left: 0
    },
    stageHeader: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      paddingTop: theme.spacing(2)
    },
    stageHeaderContent: {
      display: "flex",
      flexDirection: "row",
      maxWidth: 700,
      width: "66%",
      justifyContent: "flex-start"
    },
    stagePanel: {
      display: "flex",
      width: "100%",
      flexFlow: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    stagePanelWithChat: { width: "calc(100% - 280px)" },
    stagePanelHeader: {
      display: "flex",
      width: "100%",
      maxWidth: 700,
      justifyContent: "flex-start",
      flexFlow: "row nowrap"
    },
    cardsList: { width: "100%" }
  };
});

function ReaderContent() {
  // Hooks
  const intl = useIntl();
  const classes = useStyles();
  const { submission_id } = useQuery();
  const dispatch = useDispatch();
  const ThumbnailsPortal = useCreatePortal(
    document && document.getElementById("global-thumbnails-btn")
  );
  const PdfControlPortal = useCreatePortal(
    document && document.getElementById("global-pdfControl-btn")
  );
  const CommentsPortal = useCreatePortal(
    document && document.getElementById("global-comments-btn")
  );
  const HeaderToolbarPortal = useCreatePortal(
    document && document.getElementById("global-headerToolBar-btn")
  );

  // Redux Selectors
  const submission = useSelector((state) =>
    selectSubmission(state, Number(submission_id))
  );
  const [step] = useStepStage();
  const questionHighlights = useSelector(selectedQuestionHighlights);
  const isPdf = useSelector(isPdfSelector);
  const isThumbnailsInView = useSelector((state) => selectIsThumbnails(state));
  const isCommentsInView = useSelector((state) => selectIsComments(state));
  const chatStatus = useSelector((state) => state.chat.status);
  const currentQuestion = useSelector(selectCurrentQuestion);
  const currentQuestionIndex = useSelectedQuestion();
  const selectedHighlightColor = useSelector(
    (state) => state.readerActions.persistentActionState.highlightColor
  );

  // Ephemeral state

  //Derived state
  const isSubmitted = submission.status === "Submitted";
  const chatIsVisible = chatStatus === CHATBOX_STATUS.OPEN;
  const questionType = currentQuestion?.interaction_subtype;

  //Behavior
  useEffect(() => {
    if (!isPdf && isThumbnailsInView) dispatch(pdfThumbnailsIconClicked());
  }, []);

  // Render
  switch (true) {
    case step === "review":
      return (
        <Box className={classes.innerWrapper}>
          <ScrollBox>
            <Box
              className={clsx(
                classes.stagePanel,
                chatIsVisible && classes.stagePanelWithChat
              )}>
              <Box
                className={clsx(
                  classes.stageHeader,
                  chatIsVisible && classes.stagePanelHeader
                )}>
                {questionHighlights.length ? (
                  <Box className={classes.stageHeaderContent}>
                    <Typography variant="h6" sx={{ lineHeight: "40px" }}>
                      {intl.formatMessage({
                        id: "task.stage.ReviewSupportingEvidence",
                        defaultMessage: "Review supporting evidence"
                      })}
                    </Typography>
                    {!isSubmitted && <ReviewEvidenceToolTip />}
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
              <Box className={classes.cardsList}>
                <CardsView
                  color={"secondary"}
                  context={HIGHLIGHTS.CONTEXT.ANSWERS} // TODO: find a way to remove this prop. verry confusing...
                  cards={questionHighlights}
                  disable={submission.status === "Submitted"}
                />
              </Box>
            </Box>
          </ScrollBox>
        </Box>
      );

    case step === "answer":
      if (questionType === "OPEN_ENDED") {
        return (
          <OpenEnded question={currentQuestion} index={currentQuestionIndex} />
        );
      } else if (questionType === "MULTI_CHOICE") {
        return <MultiChoice />;
      } else {
        return <EmptyState step={step} />;
      }
    default:
      return (
        <>
          {isPdf && (
            <>
              <ThumbnailsPortal>
                <ToggleBtnConstructor
                  type={SECONDARY_SIDEBAR_ACTION_BUTTONS.THUBMNAILS}
                  handleBtnAction={handleThumbnailPanel}
                  secondarySideBarMode={isThumbnailsInView}
                />
              </ThumbnailsPortal>
              <PdfControlPortal>
                <PdfControls />
              </PdfControlPortal>
            </>
          )}
          <CommentsPortal>
            <ToggleBtnConstructor
              type={SECONDARY_SIDEBAR_ACTION_BUTTONS.COMMENTS}
              handleBtnAction={handleCommentPanel}
              secondarySideBarMode={isCommentsInView}
            />
          </CommentsPortal>
          <HeaderToolbarPortal>
            <HeaderToolbar />
          </HeaderToolbarPortal>
          <BookView
            readOnly={submission.status === "Submitted"}
            minimalBar={false}
            disableInteractions={isSubmitted}
            isVisible={true}
          />
        </>
      );
  }
}

ReaderContent.propTypes = {};

export default ReaderContent;
