// Dependencies
import React, { useEffect, useState } from "react";
import { useGetTheme } from "../../../hooks";
import { differenceInDays, format } from "date-fns";

// Material-UI
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow
} from "@mui/material";
import SubmissionStatusChip from "./SubmissionStatusChip";
import { truncateMiddle } from "../../contactUs/utils";

const TableHeadComponent = ({ showGradesColumn }) => (
  <TableHead>
    <TableRow>
      <TableCell sx={{ fontWeight: 900, borderBottom: "none" }}>
        Assignments
      </TableCell>
      <TableCell sx={{ fontWeight: 900, borderBottom: "none" }}>
        Status
      </TableCell>
      {showGradesColumn && (
        <TableCell sx={{ fontWeight: 900, borderBottom: "none" }}>
          Grade
        </TableCell>
      )}
      <TableCell sx={{ fontWeight: 900, borderBottom: "none" }}>
        Submission date
      </TableCell>
    </TableRow>
  </TableHead>
);
const mapAssignments = (assignments) =>
  new Map(assignments.map((assignment) => [assignment.id, assignment]));

const StudentSubmissionsStatus = ({ submissions, assignments, statuses }) => {
  // Hooks
  const theme = useGetTheme();

  // Ephemeral state
  const [courseAssignments, setCourseAssignments] = useState(
    mapAssignments(assignments)
  );

  useEffect(() => {
    submissions.sort((a, b) => new Date(a.due_date) - new Date(b.due_date));
    setCourseAssignments(mapAssignments(assignments));
  }, [assignments, submissions]);

  const showGrade = (submission) => {
    const assignmentGrade = courseAssignments.get(
      submission.task_id
    )?.max_grade;
    const submissionGrade = submission.grade;
    if (submissionGrade !== null && assignmentGrade > 0) {
      return `${submissionGrade}/${assignmentGrade}`;
    }
    return null;
  };

  const calcDaysLate = (submission) => {
    if (!submission.submission_date || !submission.due_date) return null;
    const difference = differenceInDays(
      new Date(submission.submission_date),
      new Date(submission.due_date)
    );
    return difference > 0 ? difference : null;
  };
  const showGradesColumn = submissions.find((submission) =>
    showGrade(submission)
  );

  return (
    <Box
      sx={{
        padding: "0 24px 24px 24px",
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: "5px"
      }}>
      {/* HEADER */}
      <Box
        sx={{
          display: "flex",
          height: "60px",
          width: "100%",
          flexFlow: "row nowrap",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: `1px solid ${theme.palette.border.secondary}`
        }}>
        <Typography variant="subtitle1">Submission statuses</Typography>
      </Box>

      {/* TABLE */}
      <Box
        sx={{
          display: "flex",
          flexFlow: "column nowrap",
          width: "inherit",
          height: "100%"
        }}>
        <Box>
          <TableContainer>
            <Table aria-label="Submission statuses table">
              <TableHeadComponent showGradesColumn={showGradesColumn} />
              <TableBody>
                {submissions.map((submission, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      borderBottom: `1px solid ${theme.palette.border.secondary}`
                    }}>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        minWidth: 220,
                        maxWidth: 400,
                        height: "auto",
                        borderBottom: "none",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center"
                      }}>
                      <Box display="flex">
                        <Typography
                          style={{ marginInlineEnd: "12px" }}
                          variant="body2">
                          {index + 1}
                        </Typography>
                        <Typography variant="body2">
                          <span>
                            {truncateMiddle(
                              courseAssignments.get(submission.task_id)?.name,
                              170
                            )}
                          </span>
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell scope="row" sx={{ borderBottom: "none" }}>
                      <Box display="flex" alignItems="center">
                        <SubmissionStatusChip
                          status={statuses[submission.task_id]}
                        />
                      </Box>
                    </TableCell>
                    {showGradesColumn && (
                      <TableCell scope="row" sx={{ borderBottom: "none" }}>
                        <Box display="flex" alignItems="center">
                          <Typography variant="body2">
                            {showGrade(submission)}
                          </Typography>
                        </Box>
                      </TableCell>
                    )}
                    <TableCell scope="row" sx={{ borderBottom: "none" }}>
                      <Box display="flex" alignItems="center">
                        <Typography variant="body2">
                          {submission.submission_date
                            ? format(submission.submission_date, "MM/dd/yyyy")
                            : null}
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center">
                        <Typography variant="body2">
                          {submission.submission_date
                            ? format(submission.submission_date, "hh:mm:ss aa")
                            : null}
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center">
                        <Typography
                          sx={{
                            fontSize: 12,
                            color: theme.palette.pieChart.SubmissionMissed_total
                          }}
                          variant="subtitle2">
                          {calcDaysLate(submission)
                            ? `${calcDaysLate(submission)} Days Late`
                            : null}
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default StudentSubmissionsStatus;
