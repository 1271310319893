import { userAPI } from "../api";
import { appActions } from "../consts";
import { add } from "date-fns";
import { scrollPageAndThumbnailIntoView } from "../components/reader/pdf/utils";
import { setShouldShowLocation } from "../redux/pdfSlice";
import store from "../redux/store";
export const showInText = (
  should_log = false,
  item = {},
  user_id,
  rendition = null,
  cb = null
) => {
  const {
    pdfPosition = {},
    course_id,
    text_id,
    isPdf = false,
    cfi,
    privacy = null,
    interaction_id = null,
    interaction_subtype
  } = item;
  const data = {
    pdfPosition,
    user_id,
    course_id,
    text_id,
    refPoint: isPdf ? item.id : cfi,
    rendition,
    isPdf,
    privacy,
    thread_id: interaction_id
  };
  if (isPdf && interaction_subtype === "COMMENT") {
    data.refPoint = interaction_id;
  }
  showInTextHelper(should_log, data, cb);
};

export const showInTextHelper = (should_log, data = {}, callback = null) => {
  should_log && setShowInTextLog(data);
  callback && callback();
  scrollToViewByTextType(data);
};

const setShowInTextLog = (data) => {
  const now = new Date();
  const {
    user_id,
    course_id,
    text_id,
    privacy = null,
    thread_id = null
  } = data;

  userAPI.log({
    action_name: appActions.SHOW_IN_TEXT,
    user_id: user_id,
    course_id: course_id,
    created_at: now,
    privacy,
    thread_id,
    ttl: add(now, { months: 1 }),
    payload: { text_id }
  });
};

const scrollToViewByTextType = (data) => {
  const { refPoint, rendition, isPdf } = data;
  if (isPdf || !rendition) {
    let options = { behavior: "smooth" };
    store.dispatch(setShouldShowLocation(refPoint));
    scrollPageAndThumbnailIntoView(data?.pdfPosition[0]?.pageNumber, options);
  } else {
    rendition.display(refPoint);
  }
};
