// Dependencies
import React from "react";
// Redux dependencies

import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";

//Styles
const useStyles = makeStyles((theme) => ({
  notificationAlert: {
    width: "100vw",
    position: "fixed",
    top: "0px",
    background: theme.palette.error.main,
    color: "#FFFFFF",
    textAlign: "center",
    zIndex: 1000000,
    "&>span": { marginInline: theme.spacing(3) }
  }
}));

function UnsupportedDeviceBanner() {
  //Hooks
  const classes = useStyles();

  //Redux State

  //Derived State

  // Behavior
  const userAgent = navigator.userAgent;

  if (
    userAgent.match(/iPad/i) ||
    userAgent.match(/Tablet/i) ||
    userAgent.match(/iPhone/i) ||
    userAgent.match(/Mobile/i) ||
    userAgent.match(/Android/i) ||
    userAgent.match(/Windows Phone/i)
  ) {
    return (
      <Box className={classes.notificationAlert}>
        <Box component="span">
          Alethea is designed for desktop use. Kindly access it from a computer.
        </Box>
      </Box>
    );
  } else {
    return null;
  }
  //Render
}

export default UnsupportedDeviceBanner;
