import React from "react";

// Redux Dependencies
import { useSelector } from "react-redux";

import makeStyles from "@mui/styles/makeStyles";
import { IconButton } from "@mui/material";
import ArrowRight from "@mui/icons-material/ArrowRight";
import ArrowLeft from "@mui/icons-material/ArrowLeft";
import ArrowDown from "@mui/icons-material/ArrowDropDown";
import { selectTextDirection } from "../../redux/firestoreSelectors";

// Styles
const useStyles = makeStyles((theme) => ({
  button: {
    color: "currentcolor"
  }
}));

// Reusable toggle triangle button fir dropdowns and acordion UI elements
// The component use the RTL redux state to decide which way the closed triangle points
// Requierd Props:  isOpen (boollean), decides the open/closed state of the triangle
// Optional Props:  onClick (function), executed when clicked
//                  Size (string, 'small' | 'medium')
//                  light (boolean), true overrides the theme, always showing a light arrow

export default function ToggleTriangle({
  isOpen,
  onClick,
  size = "medium",
  ...props
}) {
  const classes = useStyles();
  // Redux State
  const textDirection = useSelector((state) => selectTextDirection(state));

  //Style

  return (
    <IconButton
      className={classes.button}
      onClick={onClick}
      size={size}
      aria-label={isOpen ? "collapse" : "expand"}>
      {isOpen && <ArrowDown />}
      {!isOpen && (textDirection === "rtl" ? <ArrowLeft /> : <ArrowRight />)}
    </IconButton>
  );
}
