import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import update from "immutability-helper";
import { updateSq3r, updateTask } from "../../../redux/firebaseMiddleware";
import { selectCurrentText } from "../../../redux/textsSlice";
import CitationDialog from "../../Tasks/CreateTask/CitationDialog";
import { useSelector, useDispatch } from "react-redux";
import { interactionsAPI } from "../../../api";
import { showInText } from "../../../utils/showInTextHelper";
import { useRendition } from "../../../RenditionContext";
import { appActions } from "../../../consts";

import { useRouteMatch } from "react-router-dom";
import { Cards } from "./Cards";

function CardsView({ context, cards = [], disable = false }) {
  const dispatch = useDispatch();
  const { rendition } = useRendition();
  const path = useRouteMatch().path;
  const task = useSelector((state) => state.tasks.tasks[0]);

  const highlights = useSelector((state) => state.interactions.highlights);
  const questions = useSelector((state) => {
    return state.interactions.questions;
  });
  const user_id = useSelector((state) => state.user.auth.uid);
  const selectedTextId = useSelector((state) => state.texts.selectedTextId);
  const selectedText = useSelector(selectCurrentText);
  const selectedQuestion = useSelector((state) => {
    let filtered = state.gr?.questions?.filter(
      (q) => q.id === state.gr.selectedQuestionId
    );
    if (filtered && filtered.length) return filtered[0];
    else return false;
  });
  const color = path.includes("task") ? "primary" : "secondary";

  const answers = selectedQuestion && selectedQuestion.answers;
  const visibleAnswers =
    answers && answers.filter && answers.filter((el) => !el.hidden);
  const hiddenAnswers =
    answers && answers.filter && answers.filter((el) => el.hidden);

  const [showDialog, setShowDialog] = useState(false);
  const [highlight, setHighlight] = useState([]);

  const showInTextHandler = (answer) => {
    const cb = () => {
      setHighlight([answer]);
      setShowDialog(true);
    };

    showInText(appActions.SHOULD_LOG, answer, user_id, rendition, cb);
  };

  return (
    <>
      <Cards
        disable={disable}
        cards={cards}
        showInText={(answer) => {
          showInTextHandler(answer);
        }}
      />
      {showDialog && (
        <CitationDialog
          color={color}
          setOpenDialog={setShowDialog}
          url={selectedText.url}
          location={selectedText.file_location}
          highlights={highlight}
          openDialog={showDialog}
          context={context}
          removeHighlight={(highlight) => {
            interactionsAPI.deleteHighlight(highlight);
            setHighlight([]);
          }}
          citationTitleFormattedMessage={
            <FormattedMessage
              id="show.location.inText"
              defaultMessage="Show in Text"
            />
          }
        />
      )}
    </>
  );
}

CardsView.propTypes = {
  cards: PropTypes.array, // TODO: add shape definitian of array items
  color: PropTypes.oneOf(["secondary", "primary"]),
  disable: PropTypes.bool
};

export default CardsView;
