import React from "react";
import { useSelector } from "react-redux";
import { selectTextDirection } from "../../redux/firestoreSelectors";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export default function DirectionalArrowIcon({ fontSize }) {
  const textDirection = useSelector((state) => selectTextDirection(state));

  if (textDirection === "rtl") return <ArrowForwardIcon fontSize={fontSize} />;
  else return <ArrowBackIcon fontSize={fontSize} />;
}
