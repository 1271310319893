const en = {
  "appBar.library": "Library",
  "appBar.reader": "Reader",
  "appBar.tasks": "Assignments",
  "appBar.task": "Assignment",
  "appBar.grTask": "Guided Reading Assignment",
  "card.title": " ",
  "highlight.showAllHighlights": "Show all highlights",
  "gr.light.stage.1": "Survey and Questions",
  "gr.light.stage.1.instructions":
    "Read the title, subheadings, and the first sentence of each paragraph. Then come up with questions regarding the text. ",
  "gr.light.stage.1.instructions.task":
    "Read the title, subheadings, and the first sentence of each paragraph. Then come up with questions of your own. Pay special attention to questions given by your teacher in case there are any. ",
  "gr.light.stage.2": "Read and Highlight",
  "gr.light.stage.2.instructions":
    "Read the text while actively seeking answers to your questions. Whenever you find one, click on the question and then highlight the relevant passage. ",
  "gr.light.stage.3": "Answer",
  "gr.light.stage.3.instructions": "Answer the questions in your own words. ",
  "gr.full.stage.1": "Survey",
  "gr.full.stage.1.instructions":
    "To get an overview of the content before diving into the details, read the title, subheadings, abstract or introduction, and the opening sentence of each paragraph.",
  "gr.full.stage.2": "Questions",
  "gr.full.stage.2.instructions":
    "Now that you have an overview of the text, come up with question and add them right here. Asking questions about the text helps you stay focused while reading it.",
  "gr.full.stage.3": "Read and Highlight",
  "gr.full.stage.3.instructions":
    "Read the text while actively seeking answers to your questions. Whenever you find one, click on the question and then highlight the relevant segment.",
  "gr.stage.4": "Review",
  "gr.stage.4.instructions":
    "Go over the highlight cards of every question and examine them. You can add titles, rearrange, and hide cards as you see fit.",
  "gr.stage.5": "Answer",
  "gr.stage.5.instructions":
    "In order to retain the knowledge in your memory try answering the questions in your own words. You can take a look at the highlights on the left side panel at any time. Once done, you can give yourself some feedback.",
  "gr.highlightsView": "Highlight view",
  "gr.answersView": "Answer view",
  "tasks.manager": "Class assignments",
  "tasks.create": "Create",
  "tasks.share": "Share",
  "tasks.export": "Export",
  "export.step.texts": "Select the text you with to export to another course",
  "export.step.tasks":
    "Select the assignments you with to export to another course",
  "export.step.course": "Select the destination course",
  "tasks.export.title": "Export Assignment",
  "task.highlightsToggler":
    "Select a question and highlight supporting evidence in the text",
  "feedback.textRef": "text reference",
  Draft: "Draft",
  Submitted: "Submitted",
  interactions: {
    highlightsView: "Highlight view",
    mandatory: "Highlighting is mandatory",
    optional: "Highlighting is optional"
  },
  tasks: {
    manager: {
      student: "Student",
      status: "Status",
      submissionDate: "Submission date",
      feedback: "Feedback",
      task: "Assignment",
      deadline: "Due date",
      dueTime: "Due time",
      lastSaved: "Last saved at ",
      editDueTime: "Edit due time",
      editDueDate: "Edit due date",
      resubmitTooltip: "Allow resubmission",
      resubmitDialotTitle: "Allow Assignment Resubmission",
      resubmitDialotBody:
        "Are you sure you want to allow {name} to resubmit their assignment? Please note, the previous submission will be overwritten.",
      resubmitDialotAction: "Allow Resubmission",
      resubmitted: "Resubmission enabled"
    }
  },
  task: {
    submission: {
      submit: "Submit",
      publish: "Publish",
      missingCitations: "Missing mandatory highlights in question",
      didntAnswer: "Missing your answer in question",
      missingGrQuestions: "Missing questions in Guided Reading assignment.",
      supportingEvidence: "Supporting evidence in question",
      questionResponse: "Your response in question",
      incompleteAssignment:
        "It seems like you're about to submit an incomplete assignment. Are you sure you want" +
        " to proceed? Take a moment to review the following:",
      incomplete: "Incomplete Assignment"
    },
    stage: {
      answer: "Select a question and answer it according to instructions",
      review:
        "Review your supporting evidence, rearrange them as you see fit and add titles",
      highlight:
        "Select a question and highlight supporting evidence in the text",
      reviewHighlights: "Review highlights",
      ReviewSupportingEvidence: "Review supporting evidence",
      ReviewSupportingEvidenceToolTip:
        "You can choose to review your highlighted evidence with the help of Alethea's academic coach chat bot. It's optional, and the conversation will not be visible to your instructor."
    },
    status: {
      submitted: "Submitted",
      passed: "Passed due date",
      pending: "Pending"
    },
    type: {
      standard: "Standard",
      guidedReading: "Guided Reading",
      peerReview: "Peer Review"
    },
    description: {
      guidedReading:
        "Task description for guided reading tasks is provided by Alethea",
      placeholder: "Assignment description..."
    },
    title: {
      placeholder: "Type title here..."
    },
    question: "Question",
    grQuestion: "Instructor question",
    grQuestionOptional: "Optional",
    newTask: "New Assignment",
    deleted: "Assignment deleted",
    saveDraft: "Save & leave",
    beforYouLeave: "Before you leave",
    saveAsDraft: "Save assignment as draft?",
    questions: {
      points: "Points"
    },
    create: {
      screen: {
        readings: "Readings",
        assignment: "Assignment",
        publish: "Publish",
        title: "Create a new reading assignment",
        cancelDialogTitle: "Discrad changes",
        cancelDialogContent: "Are you sure you want to discard changes?",
        cancelDialogKeepEditing: "Keep editing",
        cancelDialogDiscard: "Discard",
        deleteTask: "Delete assignment",
        deleteDialogTitle: "Delete assignment",
        deleteDialogContent:
          "This assignment has already been published, any work done by your students on this task will be deleted as well.",
        editTaskEnabled: "Edit assignment",
        editTaskDisabled: "Editing disabled - students working",
        deleteTaskDisabled: "Deleting disabled - students working",
        draftSaved: "Draft saved at "
      },
      readings: {
        readingMaterial: "Reading material",
        selectText: "Type the title or author name",
        orUpload: "Or",
        upload: "Upload",
        assignmentType: "Assignment type",
        assignmentTypeTooltipStandard:
          "Ask your students any type of question regarding the text and have them highlight supporting evidence, review the evidence, and respond.",
        assignmentTypeTooltipGuidedReading:
          "Let your students come up with questions regarding the text, highlight supporting evidence, review the evidence, and respond.",
        advancedSettings: "Advanced settings",
        coachQuestion: "Enable academic coach chatbot for your students?",
        academicCoach: "Academic Coach",
        academicCoachTooltipCoach:
          "Alethea’s academic coach is a GenAI chatbot designed to promote critical, systematic, and reflective thinking among students while providing necessary support during the learning process.",
        academicCoachTooltipGeneration:
          "The conversation with the Academic Coach can result in a generated answer based on the student's input.",
        academicCoachMode: "Academic coach mode",
        academicCoachDisable: "Disable",
        academicCoachFull: "Enable with generated answers",
        academicCoachChat: "Enable without generated answers",
        lmsQuestion: "Would you like to show assignment on LMS?",
        showAssignmentOnLMS: "Show assignment on LMS"
      },
      assignment: {
        assignmentTitle: "Assignment title",
        assignmentDescription: "Assignment description (optional)",
        composeQuestion: "Compose question",
        questionTypeTooltipTitle: "Question type",
        openEndedTooltip:
          "Highlight supporting evidence, review the evidence and compose a written response.",
        findInTextTooltip:
          "Highlight supporting evidence and review the evidence.",
        multiChoiceTooltip:
          "Highlight supporting evidence, review the evidence and select the correct response.",
        additionalOptions: "Additional options",
        supportingEvidence: "Highlight supporting evidence (optional)",
        defineKeyConcepts: "Define key concepts (optional)",
        multiChiceInstructions:
          "Type answer options and mark the correct answer:",
        addAnotherChoice: "Add another choice",
        highlightSupportingEvidenceTooltipTitle:
          "Highlight supporting evidence",
        highlightSupportingEvidenceTooltipBody:
          "Verify your students' understanding: mark the key sections in the text and Alethea will match your markings with the students'.",
        defineKeyConceptsTooltipTitle: "Define key concepts",
        defineKeyConceptsTooltipBody:
          "Seeking a quick overview? Define key words or concepts to search for in your students' written answers.",
        bot: {
          title: "AI Assistant",
          craftWithAI: "Craft your assignment effortlessly with AI",
          questionKind: "What kind of questions are you looking for?",
          questionKinds: {
            comprehension: "Comprehension",
            analysis: "Analysis",
            interpretation: "Interpretation",
            comparative: "Comparative",
            critique: "Critique"
          },
          readingFocus: "What’s the focus of this reading?",
          readingFocusPlaceholder:
            "Tell us about the specific topics you want your students to focus on (optional)...",
          dfficulty: "Level of difficulty",
          generateAssignmentButton: "Generate Assignment",
          generateQuestion: "Generate question",
          regenerateQuestion: "Regenerate question",
          regenerateQuestionsDialogTitle: "Update Questions",
          regenerateQuestionsDialogContent:
            "Do you want to add new questions to the list or replace the current questions?",
          regenerateQuestionsDialogReplace: "Replace",
          regenerateQuestionsDialogAdd: "Add",
          regenerateQuestionDialogTitle: "AI Input Updated",
          regenerateQuestionDialogContent:
            "Your changes to the AI input have been noted. The new question will be created based on the update.",
          regenerateQuestionsDialogGenerate: "Generate"
        }
      },
      publish: {
        timeFrame: "Time Frame"
      }
    }
  },
  feedback: {
    questionFeedback: "QUESTION FEEDBACK",
    typeYourFeedback: "Type your feedback...",
    points: "Points",
    outOfPoints: "Out of {points}",
    pointsExceeded: "Maximum points exceeded",
    showText: "Show text",
    title: "Title (optional)",
    overallEvaluation: "OVERALL EVALUATION",
    send: "Send feedback",
    findInText: "Find in text",
    inYourOwnWords: "In your own words",
    multiple: "Multiple choice",
    answer: "Answer",
    wordCount: "{count} words",
    findInTextAnswer:
      "This is a “find in text” question, no written response is required.",
    multipleChoiceAnswer: "Choose the correct answer"
  },
  gr: {
    title: "Guided Reading",
    description: "Follow the steps below",
    addQuestion: "Add Question",
    selectionAddQuestion: {
      message: "Add a question to create associated highlights",
      action: "Add question"
    },
    adaptHighlights: {
      message: "Select a question to relate highlight",
      action: "Cancel"
    },
    instructorQuestion: "Instructor question",
    misc: {
      learnMore: {
        close: "Got it"
      }
    },
    light: {
      step: {
        1: {
          learnMore: `<h2>Surveying the text</h2>
          <p>In order to easily understand the text, it is important to first get a general sense of the topic, the arguments, and the conclusion. Select the titles, the first sentence of each paragraph, and all concepts that seem important. Every passage you select will be automatically highlighted. To remove a highlight click on the highlighted text and then on the highlight icon that appears above it.</p>
          <h2>Tips</h2>
          <ul>
            <li>Try to complete the process without stopping in the middle.</li>
            <li>Don’t start summarising or worry about the details just yet.</li>
            <li>Take the time to properly understand each sentence you highlight. The key word here is efficiency, don’t rush yourself and try not to linger.</li>
          </ul>
          <h2>Asking questions</h2>
          <p>After surveying the text come up with questions about the things you found most important. Reading the text while actively seeking for answers will focus your reading, reduce time and effort, and help you to gain better understanding. To add questions, just click the “Add question” button and type your question. You can edit and delete questions by using the question menu.</p>
          <h2>Tips</h2>
          <ul>
            <li>The more specific and focused your questions are - the better your understanding will be.</li>
            <li>Use “Highlight view” to easily browse through your highlights.</li>
          </ul>`
        },
        2: {
          learnMore: `<h2>Reading while looking for answers</h2>
          <p>Using the “Read and Answer” method makes your reading more focused and effective. It saves time and provides a better understanding of the text. On top of that, your answer cards are saved and can be used as an index of the most important parts of the text.</p>
          <h2>How to create answer cards</h2>
          <p>When coming across an answer in your reading, first click on the question and then select the relevant passage in the text. The text will be highlighted and automatically saved as an answer card. You can view your answers by clicking on the question’s expand arrow icon or by switching to ‘Answer view’. You can link one answer to more than one question by dragging the card from one answer to another. The card will be automatically saved under both questions. </p>
          <h2>Tips</h2>
          <ul>
            <li>Try to make it a habit to give your answer cards titles. It will help you organise your materials, and will surprisingly increase the chances of retaining the answers in your memory.</li>
            <li>Use "answers view" to arrange your cards in a way that makes sense to you. Hide cards that seem less relevant by using the eye icon, go over your titles and readjust them.</li>
          </ul>`
        },
        3: {
          learnMore: `<h2>The purpose of exercising</h2>
              <p>The point of this step is to help you decide how much of the text you actually understand and what’s left for you to figure out. There’s no better way to do so than testing yourself. After having completed this third and final step you will be more than ready to be tested, write a paper or take part in class discussions.</p>
              <h2>How to exercise</h2>
              <p>Select a question by clicking on it and enter your answer in the box. You can take a look at the answer cards but try sticking to your own words instead of copying phrases from the text. When done, give yourself feedback and move on to the next question. You can always go back and change an answer until you feel comfortable with it.</p>
              <h2>Tips</h2>
              <ul>
                <li>Feedback helps you decide for yourself what needs a bit more work. Use it to get better, and don’t worry, no one else can see it.</li>
              </ul>`
        }
      }
    },
    full: {
      step: {
        1: {
          learnMore: `<h2>Why survey?</h2>
          <p>In order to easily understand the text, it is important to first get a general sense of the topic, the main issues, the arguments, and the conclusion. Surveying the text before reading makes the actual reading simpler and saves time.</p>
          <h2>How to use survey</h2>
          <p>Simply highlight the first sentence of each paragraph, and any other phrase or concept that looks important. You can always change your mind and remove highlight - Just click on the text and use the highlight icon that appears above it.</p>
          <h2>Tips</h2>
          <ul>
            <li>Try to complete the process without stopping in the middle. Don’t start summarizing or worry about the details just yet.</li>
            <li>Take the time to properly understand each sentence you highlight, but without lingering. The key word here is efficiency.</li>
            <li>This process may raise some questions in your mind - that’s good! Save them for the next step.</li>
          </ul>`
        },
        2: {
          learnMore: `<h2>Why questions?</h2>
          <ul>
            <li>Asking questions is the basis of every meaningful scientific project. This is your chance to sharpen this crucial tool.</li>
            <li>Reading the text while seeking answers will focus your reading, reduce time and effort, and will allow you better understanding.</li>
            <li>Composing questions ahead of your reading stimulates your curiosity and brings your own unique experiences into your studies.</li>
          </ul>
          <h2>How to use questions</h2>
          <ul>
            <li>To add questions, just click the “Add question” button and type your question.</li>
            <li>You can edit and delete questions using the question menu.</li>
            <li>To rearrange the list just drag a question up or down.</li>
          </ul>
          <h2>Tips</h2>
          <ul>
            <li>Use your natural curiosity, your fields of interests, even things that bother you, as inspiration for your questions.</li>
            <li>The more specific and focused your questions are - the better your understanding will be.</li>
          </ul>`
        },
        3: {
          learnMore: `<h2>Why read and answer?</h2>
              <p>Using the “read and answer” method makes your reading more focused and effective. It saves time and provides a better understanding of the text. On top of that, your answer cards are saved and can be used as an index of the most important parts of the text.</p>
              <h2>How to read and answer</h2>
              <p>Create as many answer cards as you like. You can link one answer card to more than one question. You can then give titles or delete cards by clicking on the bin icon. To review your answer cards, just click on the arrow next to every question.</p>
              <h2>Tips</h2>
              <ul>
                <li>Try to make it a habit to give your answer cards titles. It will help you organizing your materials, and will surprisingly increase the chances of retaining the answers in your memory.</li>
                <li>In some cases you might find it necessary to make some changes to your questions. You are encouraged to so. Simply click on the menu icon and select edit.</li>
              </ul>`
        },
        4: {
          learnMore: `<h2>Why review?</h2>
              <p>Now, that the hard work is behind you, it is time to organize, prioritize, and make your discoveries clear for yourself. Reviewing your work promises a ‘big picture’ understanding of the text. it exposes the interconnections between the different questions and answers you’ve gathered.{br}As simple as it may seem, this step utilizes higher-order cognitive skills and is proven to increase chances of knowledge retention.</p>
              <h2>How to review</h2>
              <p>Arrange your cards in a way that makes sense to you{br}Hide cards that seem less relevant using the eye icon{br}Go over your titles and readjust them{br}To remove a card completely click “Show in text” and then use the bin icon</p>`
        },
        5: {
          learnMore: `<h2>Why excercise?</h2>
              <p>The point of this step is to let you determine for yourself whether or not you feel confident about your understanding. There’s no better way to do so then testing yourself and giving yourself feedback. When completing this step you are more than likely ready to be tested, write a paper or engage in discussions.</p>
              <h2>How to excercise</h2>
              <p>Select a question by clicking on it and write your answer in the box. You can take a look at the answer cards but try sticking to your own words instead of copying phrases from the text. When done, save your answer, give yourself some feedback and move on to the next question. You can always go back and change an answer until you feel comfortable with it.</p>
              <h2>Tips</h2>
              <p>Feedback helps you decide for yourself what needs a bit more work. Use it to get better, and don’t worry, no one else can see it.</p>`
        }
      }
    },
    stage: {
      highlight:
        "After surveying the text, add questions, select each question and highlight relevant passage in the text.",
      review:
        "Review your supporting evidence, rearrange them as you see fit and add titles.",
      answer: "Select a question and add an answer or note in your own words.",
      reviewHighlights: "Review highlights"
    }
  },
  fab: {
    MENTOR_REVIEW_LABEL: `Hello! I'm Alethea's academic coach and I can assist you with reviewing your highlights and putting together answers to your questions.\n Want to get started?`,
    MENTOR_READER_LABEL: `Hello! I'm Alethea's academic coach and I can assist you with reviewing your highlights and putting together answers to your questions.\n Want to get started?`
  },

  // error messages
  "error.undoFailed": "Undo failed",
  "error.defaultSavingError":
    "There was a problem saving your changes, please try again or contact support",
  "error.saveingAnswerFailed":
    "There was a problem savingh your answer, please try again or contact support",

  // For test

  "test.intl": "test message from intl file",
  notifications: {
    title: "Notifications",
    markAllAsRead: "Mark all as read",
    showAll: "Show all"
  },
  general: {
    cancel: "Cancel",
    confirm: "Confirm",
    publish: "Publish",
    save: "Save",
    Update: "Update",
    optional: "Optional",
    delete: "Delete",
    close: "Close"
  },
  admin: {
    removeUser: {
      button: "REMOVE",
      menu: {
        deleteUser: "Delete user",
        deleteTransientUser: "Delete user transiently",
        removeUserFromCourse: "Remove user from course",
        deleteUserFromCourse: "Permanently delete user from course"
      },
      dialotTitle: "Delete confirmation",
      dialogContent: {
        deleteUser: "Are you sure you want to delete the user?",
        deleteTransientUser:
          "Are you sure you want to delete the user transiently?",
        removeUserFromCourse:
          "Are you sure you want to remove the user from the course?",
        deleteUserFromCourse:
          "Are you sure you want to permanently delete the user from the course?"
      },
      checkbox: "I'm sure"
    }
  },
  texts: {
    dialog: {
      author: "Author",
      title: "Title",
      category: "Category"
    }
  },
  chat: {
    restartConversation: "Restart Conversation",
    restart: "Restart"
  },
  signin: {
    needHelp: "Need help?",
    tutorial: "How to access Alethea tutorial",
    or: "or",
    contact: "contact us"
  },
  analytics: {
    excludeUsersTitle: "Excluded students",
    excludeUsers:
      "By excluding these students, all graphs and charts on the analytics page will be updated.",
    internalReport: "Internal report",
    studentAnalytics: "Student analytics"
  },
  roles: {
    admin: "Admin",
    dataViewer: "Data Viewer",
    userRole: "User role"
  }
};
export default en;
