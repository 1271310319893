import { isAfter, isBefore } from "date-fns";
import { SUBMISSION_STATUS } from "../../../consts";

export function calculateSubmissionStatus(
  dueDate,
  submissionDate = null,
  originalDueDate
) {
  dueDate = new Date(dueDate);
  submissionDate = submissionDate ? new Date(submissionDate) : null;
  originalDueDate = new Date(originalDueDate);
  const maxDueDate = isAfter(dueDate, originalDueDate)
    ? dueDate
    : originalDueDate;

  const today = new Date();
  const isSubmitted = Boolean(submissionDate);
  const hasExtension = isAfter(dueDate, originalDueDate);

  let submissionStatus;
  if (isSubmitted && isBefore(submissionDate, maxDueDate)) {
    // Submitted before the due date
    submissionStatus = SUBMISSION_STATUS.ON_TIME;
  } else if (!isSubmitted && hasExtension && isAfter(today, maxDueDate)) {
    // Not submitted, got extension and initial deadline has passed
    submissionStatus = SUBMISSION_STATUS.LATE_PENDING;
  } else if (!isSubmitted && isAfter(today, maxDueDate)) {
    // not submitted and all deadlines passed
    submissionStatus = SUBMISSION_STATUS.MISSED;
  } else if (isSubmitted && isAfter(submissionDate, maxDueDate)) {
    // submitted after the due date (regurdless of extension)
    submissionStatus = SUBMISSION_STATUS.LATE;
  } else {
    // implicity, not submitted and deadline has not passed
    submissionStatus = SUBMISSION_STATUS.PENDING;
  }

  return submissionStatus;
}
