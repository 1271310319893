// Dependencies
import React from "react";
import { useQuery } from "../../../hooks";

// Redux dependencies
import { useSelector } from "react-redux";
import { selectPeerReviewReflection } from "../../../redux/interactionsSlice";
import { selectTask, selectSubmission } from "../../../redux/tasksSlice";

// Components
import RenderEditorContent from "../../SharedComponents/RenderEditorContent";

import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";
import ScrollBox from "../../SharedComponents/ScrollBox";

// Styles
const useStyles = makeStyles((theme) => ({
  tabPanel: {
    fontSize: "22px",
    height: "100%",
    paddingBlockStart: theme.spacing(4.5),
    paddingInlineStart: theme.spacing(2),
    "& :not($reflectionHeader)": {
      fontFamily: '"Crimson Pro", "Frank Ruhl Libre"'
    }
  },
  reflectionHeader: {
    color: theme.palette.text.blue,
    fontSize: "12px",
    fontWeight: 600,
    textTransform: "uppercase",
    letterSpacing: "1px"
  },
  reflectionText: {
    lineHeight: "32px"
  }
}));

export default function PeerReviewReflectionPanel({ answerRef }) {
  // Hooks
  const classes = useStyles();
  const { submission_id } = useQuery();

  // Redux state
  const submission = useSelector((state) =>
    selectSubmission(state, Number(submission_id))
  );
  const currentUser = useSelector((state) => state.user.auth);
  const reflection = useSelector((state) =>
    selectPeerReviewReflection(state, Number(submission_id))
  );
  // Ephemeral state

  // Variables

  // Behavior

  //render
  return (
    <ScrollBox ref={answerRef}>
      <Box
        className={classes.tabPanel}
        role="tabpanel"
        id={`tabpanel-${0}`}
        aria-labelledby={`reflection`}>
        <Box className={classes.reflectionHeader}>
          {currentUser.uid === submission.owner && `My reflection`}
          {currentUser.uid !== submission.owner &&
            (reflection.user_name
              ? `${reflection.user_name}'s reflection`
              : `reflection`)}
        </Box>
        <Box className={classes.reflectionText}>
          <RenderEditorContent>{reflection.rich_text}</RenderEditorContent>
        </Box>
      </Box>
    </ScrollBox>
  );
}
