// Dependencies
import React, { useEffect } from "react";

// Redux

// Components
import { ScrollBox } from "../../../SharedComponents";

// Material UI
import { Box, Typography, Divider } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

// Styles
const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: "flex",
      flexFlow: "column"
      //   height: "90%"
    },
    emptyConversations: {
      alignSelf: "center"
    },
    conversation: {
      marginBlockEnd: theme.spacing(2)
    },
    conversationPart: {
      marginBlockEnd: theme.spacing(2)
    }
  };
});

const OldConversation = ({ conversations }) => {
  const classes = useStyles();

  useEffect(() => {}, []);
  return (
    <ScrollBox className={classes.container}>
      {Object.keys(conversations).length === 0 ? (
        <Typography variant="h4" className={classes.emptyConversations}>
          No conversations
        </Typography>
      ) : (
        <>
          {Object.keys(conversations).map((conversationId) => {
            const conversation = conversations[conversationId];
            return (
              <Box className={classes.conversation} key={conversationId}>
                <Typography variant="h5">{conversation.date}</Typography>
                <Typography variant="h6">
                  Article: {conversation.articleName}
                </Typography>

                {conversation.question && (
                  <Typography variant="h6">{`Question: ${conversation.question}`}</Typography>
                )}

                {conversation.content.map((interaction) => {
                  const content = interaction.content.replace(/<\/?p>/g, "");
                  return (
                    <Box
                      className={classes.conversationPart}
                      key={interaction.id}>
                      <Typography variant="body1">
                        {`${interaction.user_type}: ${content}`}
                      </Typography>
                    </Box>
                  );
                })}
                <Divider />
              </Box>
            );
          })}
        </>
      )}
    </ScrollBox>
  );
};

export default OldConversation;
