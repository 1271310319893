import React, { useEffect } from "react";
import PropTypes from "prop-types";
import ReactReader from "./ReactReader/ReactReader";
import ReactPdfReader from "./pdf/ReactPdfReader";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";
import { isPdfSelector } from "../../redux/textsSlice";
import { setShowAllHighlights } from "../../redux/readerActionsSlice";
import { selectIsSuggestions } from "../../redux/firestoreSelectors";

// Fix for v3 branch of epub.js -> needs ePub to by a global var
//const storage = global.localStorage || null;

const useStyles = makeStyles((theme) => ({
  bookView: {
    flexGrow: "1",
    flexDirection: "column",
    position: "relative",
    height: "100%",
    width: "100%"
  },
  bookViewWithSuggestions: {
    paddingTop: theme.spacing(6)
  }
}));

function BookView({
  readOnly,
  minimalBar,
  disableInteractions = false,
  isVisible = false
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isPdf = useSelector(isPdfSelector);
  const showAllHighlights = useSelector(
    (state) => state.readerActions.persistentActionState.showAllHighlights
  );
  const isSuggestions = useSelector((state) => selectIsSuggestions(state));

  useEffect(() => {
    return () => {
      if (showAllHighlights) {
        dispatch(setShowAllHighlights());
      }
    };
  }, []);
  return (
    <div
      className={clsx(
        classes.bookView,
        isSuggestions && isPdf && classes.bookViewWithSuggestions
      )}
      id="the-text-reader-view">
      {isPdf ? (
        <ReactPdfReader
          disableInteractions={disableInteractions}
          minimalBar={minimalBar}
          readOnly={readOnly}
          isVisible={isVisible}
        />
      ) : (
        <ReactReader
          minimalBar={minimalBar}
          readOnly={readOnly}
          disableInteractions={disableInteractions}
          isVisible={isVisible}
        />
      )}
    </div>
  );
}

BookView.propTypes = {
  readOnly: PropTypes.bool,
  disableInteractions: PropTypes.bool,
  isVisible: PropTypes.bool,
  rendition: PropTypes.object
};

export default BookView;
