// Dependencies
import React from "react";
import {
  filterActionByUserId,
  generateActionBucketsByDay
} from "../courseActivity/utils";
import { useQuery } from "../../../../hooks";

//Components
import ReportTable from "./ReportTable";

// Material UI
import { Box, Typography } from "@mui/material";

// Styles

export default function CourseActivityReport({ actions }) {
  //Hooks
  const { user_id } = useQuery();
  // Ephemeral State

  //Derived state
  const userActions = filterActionByUserId(user_id, actions);
  const userActionsByDay = generateActionBucketsByDay(userActions);

  //Behavior

  return (
    <Box style={{ width: "100%" }}>
      {userActions.length ? (
        <ReportTable actionsByDay={userActionsByDay} />
      ) : (
        <Typography variant="h4">
          No user activity between the selected dates
        </Typography>
      )}
    </Box>
  );
}
