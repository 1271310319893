import React from "react";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { dequeueFlashMessage, flashClosed, undo } from "./redux/userSlice";
import { SnackbarContent, Snackbar, Button, Box } from "@mui/material";
import { Alert } from "@mui/material";
import {
  selectAlertsDuration,
  selectTextDirection
} from "./redux/firestoreSelectors";

export default function FlashMessages() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const textDirection = useSelector((state) => selectTextDirection(state));
  const flashMessages = useSelector((state) => state.user.flashMessages);
  const alertsDuration = useSelector((state) => selectAlertsDuration(state));

  function parseMessage(message) {
    if (Array.isArray(message)) {
      return (
        <>
          {message.map((line) => (
            <Box key={line}>{line}</Box>
          ))}
        </>
      );
    } else {
      return message;
    }
  }

  return flashMessages ? (
    <Snackbar
      open={Boolean(flashMessages)}
      autoHideDuration={parseInt(alertsDuration)}
      onClose={() => {
        dispatch(dequeueFlashMessage());
        dispatch(undo(false));
        dispatch(flashClosed(true));
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: textDirection === "rtl" ? "left" : "right"
      }}
      ClickAwayListenerProps={{
        mouseEvent: false,
        touchEvent: "onTouchStart"
      }}>
      {flashMessages.severity ? (
        <Alert severity={flashMessages.severity}>
          {parseMessage(flashMessages.message)}
        </Alert>
      ) : (
        <SnackbarContent
          message={flashMessages.message}
          sx={{ color: "snackbar.text" }}
          action={
            flashMessages.undoButton &&
            flashMessages.undoButton === true && (
              <Button
                onClick={() => {
                  dispatch(undo(true));
                  dispatch(dequeueFlashMessage());
                }}
                sx={{ textTransform: "uppercase", color: "snackbar.button" }}
                size="small">
                {intl.formatMessage({ id: "undo", defaultMessage: "undo" })}
              </Button>
            )
          }
        />
      )}
    </Snackbar>
  ) : null;
}
