import React, { useRef, useEffect, useState } from "react";
import * as d3 from "d3";
import { useChartDimensions } from "../../../../../hooks";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";

// Styles
const useStyles = makeStyles((theme) => ({
  chartContainer: {},
  tooltip: {
    // top: 10,
    // right: 10,
    backgroundColor: "rgba(97, 97, 97, 0.9)",
    color: "#fff", // Optionally, set the text color
    padding: "10px",
    borderRadius: "5px",
    width: "fit-content"
  }
}));

export default function StackedPieChart({
  data,
  width,
  height,
  removeSubmissionAndReplaceUnderscore,
  totalSubmissions
}) {
  const { ref, dimensions } = useChartDimensions({});
  const classes = useStyles();
  const chartRef = useRef();

  const [hoveredVal, setHoveredVal] = useState(null);

  useEffect(() => {
    if (!data.length || dimensions.width <= 0 || dimensions.height <= 0) return;

    // Remove any existing elements
    d3.select(chartRef.current).selectAll("*").remove();

    const pie = d3.pie().value((d) => d.val);
    const arcGenerator = d3
      .arc()
      .innerRadius((0.6 * Math.min(dimensions.width, dimensions.height)) / 2)
      .outerRadius((0.8 * Math.min(dimensions.width, dimensions.height)) / 2);

    const arcs = pie(data);

    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", dimensions.width)
      .attr("height", dimensions.height);

    const g = svg
      .append("g")
      .attr(
        "transform",
        `translate(${dimensions.width / 2}, ${dimensions.height / 2})`
      );

    const tooltip = d3
      .select(chartRef.current) // Use the chart container as the reference
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0)
      .classed(classes.tooltip, true);

    g.selectAll("path")
      .data(arcs)
      .enter()
      .append("path")
      .attr("d", arcGenerator)
      .attr("fill", (d, i) => data[i].color)
      .on("mouseover", (event, d) => {
        const [x, y] = d3.pointer(event);
        const box = tooltip.node().getBoundingClientRect();

        tooltip.transition().duration(200).style("opacity", 0.9);
        tooltip
          .html(
            ` ${removeSubmissionAndReplaceUnderscore(
              d.data.cat
            )} submissions: <br/> ${d.data.val}`
          )
          .style("left", x + dimensions.width / 2 + "px")
          .style("top", y - dimensions.height / 2 + "px")
          .style("position", "relative");
        setHoveredVal(d.data.val);
      })
      .on("mouseout", () => {
        tooltip.transition().duration(500).style("opacity", 0);
        setHoveredVal(null);
      });

    // Add text label to the center of the entire chart
    hoveredVal &&
      svg
        .append("text")
        .attr(
          "transform",
          `translate(${dimensions.width / 2}, ${dimensions.height / 1.9})`
        )
        .style("text-anchor", "middle")
        .style("fill", "black")
        .style("font-size", "48px") // Adjust font size as needed
        .text(`${((Number(hoveredVal) / totalSubmissions) * 100).toFixed(0)}%`);
  }, [
    data,
    dimensions,
    hoveredVal,
    classes.tooltip,
    removeSubmissionAndReplaceUnderscore,
    totalSubmissions
  ]);

  return (
    <Box
      width={width}
      height={height}
      ref={ref}
      className={classes.chartContainer}>
      {/* The chart will be rendered here */}
      {/* The SVG element will be appended inside this div */}
      <div ref={chartRef} />
    </Box>
  );
}
