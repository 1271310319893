import { grAPI } from "../api";
import {
  COMMENT_PANEL_VIEW,
  NOTIFICATION_INTL,
  SECONDARY_SIDEBAR_STATE
} from "../consts";
import { setSecondarySidebarViewMode } from "../redux/layoutSlice";
import {
  setCommentPanelState,
  setSelectedThreadId
} from "../redux/realtimeInteractionsSlice";
import store from "../redux/store";

export function generateNotificationContent(notification, intl, userRole) {
  let redux_user_role = userRole?.toUpperCase();
  let notification_message = "";
  if (!notification) return;

  if (notification.content) {
    return notification.content;
  } else {
    let { type, data, userRole } = notification.metadata;
    userRole = userRole || redux_user_role;
    if (
      type === "notifyStudentOnNewCommentReply" ||
      type === "notifyCourseOnNewCommentReply"
    ) {
      let { userName, data } = notification.metadata;
      notification_message = intl
        .formatMessage({
          id: `appBar.notifications.${userRole}.${type}`,
          defaultMessage: NOTIFICATION_INTL[userRole][type]
        })
        .replace("$DATA", userName)
        .replace("$DATA2", data.text_name);
    } else if (type === "notifyCourseOnNewComment") {
      notification_message = intl
        .formatMessage({
          id: `appBar.notifications.${userRole}.${type}`,
          defaultMessage: NOTIFICATION_INTL[userRole][type]
        })
        .replace("$DATA", data?.text_name);
    } else {
      notification_message = intl
        .formatMessage({
          id: `appBar.notifications.${userRole}.${type}`,
          defaultMessage: NOTIFICATION_INTL[userRole][type]
        })
        .replace("$DATA", data);
    }
  }
  return notification_message;
}

export async function navigateToCommentNotification(
  notification,
  isComments,
  isSingleThread
) {
  await updateGrStateView();
  const commentNotifiaction = [
    "notifyStudentOnNewCommentReply",
    "notifyCourseOnNewCommentReply",
    "notifyCourseOnNewComment"
  ];
  if (commentNotifiaction.includes(notification.type)) {
    if (!isComments) {
      store.dispatch(
        setSecondarySidebarViewMode(SECONDARY_SIDEBAR_STATE.COMMENTS)
      );
    }
    if (!isSingleThread)
      store.dispatch(setCommentPanelState(COMMENT_PANEL_VIEW.SINGLE_THREAD));
    store.dispatch(setSelectedThreadId(notification.metadata.interaction_id));
  }
}

export async function updateGrStateView() {
  const state = store.getState();
  const grStep = state.gr.stage;
  const showHighlights = state.gr.showHighlights;
  if (grStep !== 0 && grStep !== "highlight") {
    grAPI.updateGrState({ grStage: 0 });
  }
  if (showHighlights) {
    grAPI.updateGrState({ grShowHighlights: !showHighlights });
  }
}
