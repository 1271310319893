/**
 * This silly component does a single thing:
 * If in iframe - Renders the button,
 * else - renders App.js
 */
import React from "react";
import RedirectIframe from "./RedirectIframe";
import App from "./App";

function AppWrapper() {
  const isInIframe = window.self !== window.top;

  if (isInIframe) {
    return <RedirectIframe />;
  } else return <App />;
}

export default AppWrapper;
