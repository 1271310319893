/* eslint react/prop-types: 0 */
// Dependencies
import React, { useEffect, useState, useCallback } from "react";

// Redux

// Components
import NewConversion from "./chat/NewConversion";
import OldConversation from "./chat/OldConversation";
import ChatHeader from "./chat/ChatHeader";

// Material UI
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { httpCallables } from "../../../firebase";
import { filterConversationsByQuestionId } from "./utils";

// Styles
const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      height: "100%",
      paddingInline: theme.spacing(4)
    },
    chatBox: {
      display: "flex",
      flexFlow: "row nowrap",
      height: "90%"
    }
  };
});

const PlaygroundChat = ({
  selectedUser,
  selectedQuestion,
  selectedCourse,
  setMessages,
  runQuery,
  setRunQuery,
  conversation,
  setConversation
}) => {
  const classes = useStyles();
  const [showLogs, setShowLogs] = useState(false);
  const [conversations, setConversations] = useState({});

  const user_id = selectedUser?.course_user;
  const course_id = selectedCourse?.id;
  const selectedQuestionId = selectedQuestion?.id;
  // Behavior
  const seperateConversations = useCallback((interactions) => {
    return interactions.reduce((accumulator, current) => {
      if (current.articleName) {
        accumulator[current.id] = {
          date: new Date(current.created_at).toLocaleDateString("en-US"),
          articleName: current.articleName,
          question: current.questions.length ? current.questions[0] : null,
          content: [current],
          question_id: current.interaction_id
        };
      } else {
        const sessionId = current.interaction_id;
        accumulator[sessionId] && accumulator[sessionId].content.push(current);
      }
      return accumulator;
    }, {});
  }, []);

  useEffect(() => {
    if (!course_id || !user_id) return;
    httpCallables
      .interactionFunctions({
        func_name: "readConversationsByUserId",
        course_id: Number(course_id),
        user_id
      })
      .then(({ data }) => {
        const { interactions } = JSON.parse(data.payload);
        setMessages(interactions);
        const conversations = seperateConversations(interactions);

        setConversations(conversations);
      });
  }, [course_id, seperateConversations, user_id, selectedQuestionId]);

  return (
    <Box className={classes.container}>
      <ChatHeader
        setShowLogs={setShowLogs}
        showLogs={showLogs}
        selectedQuestionId={selectedQuestionId}
      />
      <Box className={classes.chatBox}>
        <NewConversion
          showLogs={showLogs}
          runQuery={runQuery}
          setRunQuery={setRunQuery}
          conversation={conversation}
          setConversation={setConversation}
        />
        {showLogs && (
          <OldConversation
            conversations={filterConversationsByQuestionId(
              conversations,
              selectedQuestionId
            )}
          />
        )}
      </Box>
    </Box>
  );
};

export default PlaygroundChat;
