// Dependancies
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { firebaseFunctions, httpCallables } from "../../firebase";
import { useIntl } from "react-intl";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { collection } from "firebase/firestore";

import { ScrollBox } from "../SharedComponents";

import {
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Box,
  Autocomplete,
  TextField,
  Switch,
  Typography,
  Checkbox,
  IconButton,
  Popover,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  Paper,
  CircularProgress,
  DialogContentText,
  Tooltip,
  Chip
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import RestoreIcon from "@mui/icons-material/Restore";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

function FeatureFlags({ courses, institutions }) {
  const intl = useIntl();
  const firestore = useFirestore();

  const [featureFlags, setFeatureFlags] = useState([]);
  const [newFlagName, setNewFlagName] = useState("");
  const [newFlagDescription, setNewFlagDescription] = useState("");
  const [newFlagValue, setNewFlagValue] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [confirmationAction, setConfirmationAction] = useState(null);

  const addFlagOpen = Boolean(anchorEl);
  const flagsRef = collection(firestore, "featureFlags");
  const { data: flagsDB } = useFirestoreCollectionData(flagsRef);

  useEffect(() => {
    if (flagsDB) {
      const defaultFlags = flagsDB.filter((f) => f.level === "default");
      setFeatureFlags(defaultFlags);
    }
  }, [flagsDB]);

  const institutionsWithFlags = useMemo(() => {
    if (flagsDB) {
      const institutionsWithFlags = institutions.map((i) => {
        let flags = {};
        const defaultFlags = flagsDB.filter((f) => f.level === "default");
        defaultFlags.map((f) => {
          const flag = flagsDB.find(
            (ii) =>
              ii.level === "institution" &&
              ii.flag === f.flag &&
              i.id === ii.targetId
          );
          flags = { ...flags, ...(flag && { [f.flag]: flag }) };
        });
        return { ...i, ...(flags ? { flags: flags } : {}) };
      });
      // console.log("institutionsWithFlags", institutionsWithFlags);
      return institutionsWithFlags;
    }
    return institutions.map((i) => ({ ...i, ...{ flags: {} } }));
  }, [flagsDB, institutions]);

  const enabledInstitutionsWithFlags = useMemo(() => {
    const enabledInstitutionsWithFlags = {};
    institutionsWithFlags.map((i) =>
      Object.keys(i.flags).map(
        (f) =>
          (enabledInstitutionsWithFlags[f] = [
            ...(enabledInstitutionsWithFlags[f] ?? []),
            ...[i]
          ])
      )
    );
    // console.log("enabledInstitutionsWithFlags", enabledInstitutionsWithFlags);
    return enabledInstitutionsWithFlags;
  }, [institutionsWithFlags]);

  const coursesWithFlags = useMemo(() => {
    if (flagsDB) {
      const coursesWithFlags = courses.map((c) => {
        let flags = {};
        const defaultFlags = flagsDB.filter((f) => f.level === "default");
        defaultFlags.map((f) => {
          const flag = flagsDB.find(
            (cc) =>
              cc.level === "course" &&
              cc.flag === f.flag &&
              c.id === cc.targetId
          );
          flags = { ...flags, ...(flag && { [f.flag]: flag }) };
        });
        return { ...c, ...(flags ? { flags: flags } : {}) };
      });
      // console.log("coursesWithFlags", coursesWithFlags);
      return coursesWithFlags;
    }
    return courses.map((i) => ({ ...i, ...{ flags: {} } }));
  }, [flagsDB, courses]);

  const enabledCoursesWithFlags = useMemo(() => {
    const enabledCoursesWithFlags = {};
    coursesWithFlags.map((c) =>
      Object.keys(c.flags).map(
        (f) =>
          (enabledCoursesWithFlags[f] = [
            ...(enabledCoursesWithFlags[f] ?? []),
            ...[c]
          ])
      )
    );
    // console.log("enabledCoursesWithFlags", enabledCoursesWithFlags);
    return enabledCoursesWithFlags;
  }, [coursesWithFlags]);

  const updateFeatureFlag = (flag, level, targetId, value) => {
    setLoading(true);
    httpCallables
      .adminFunctions({
        func_name: "updateFeatureFlag",
        flag,
        level,
        targetId,
        value
      })
      .then(({ data }) => {
        setLoading(false);
      });
  };

  const addFeatureFlag = () => {
    setLoading(true);
    setAnchorEl(null);
    httpCallables
      .adminFunctions({
        func_name: "addFeatureFlag",
        flag: newFlagName.trim(),
        description: newFlagDescription,
        value: newFlagValue
      })
      .then(({ data }) => {
        setLoading(false);
        setNewFlagName("");
        setNewFlagDescription("");
        setNewFlagValue(false);
      });
  };

  const resetFlag = (flag) => {
    setLoading(true);
    httpCallables
      .adminFunctions({ func_name: "resetFeatureFlag", flag })
      .then(({ data }) => {
        setLoading(false);
      });
  };

  const deleteFlag = (flag) => {
    setLoading(true);
    httpCallables
      .adminFunctions({ func_name: "removeFeatureFlag", flag })
      .then(({ data }) => {
        setLoading(false);
      });
  };

  const openConfirmationDialog = (type, action, param) => {
    setConfirmationAction({ type, action, param });
    setConfirmationDialogOpen(true);
  };

  const renderConfirmationDialog = () => {
    const handleConfirm = () => {
      confirmationAction.action(confirmationAction.param);
      setConfirmationDialogOpen(false);
    };

    const actionText = confirmationAction ? confirmationAction.type : "";
    const dialogTitle = `Confirm ${actionText}`;
    const dialogContent = `Are you sure you want to ${actionText} the flag ${confirmationAction.param}?`;

    return (
      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogContent}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setConfirmationDialogOpen(false)}
            color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <ScrollBox>
      <Box sx={{ padding: "40px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px"
          }}>
          <Typography variant="h4">
            {intl.formatMessage({
              id: "admin.courseAdmin",
              defaultMessage: "Feature Flags"
            })}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {loading && (
              <CircularProgress size={24} sx={{ marginRight: "20px" }} />
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
              }}>
              Add
            </Button>
            <Popover
              open={addFlagOpen}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left"
              }}>
              <Paper style={{ padding: "20px", maxWidth: "300px" }}>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ marginBottom: "20px" }}>
                  New Feature Flag
                </Typography>
                <TextField
                  label="Name"
                  value={newFlagName}
                  onChange={(event) => {
                    setNewFlagName(event.target.value);
                  }}
                  sx={{ marginBottom: "10px", width: "100%" }}
                />
                <TextField
                  multiline
                  maxRows={3}
                  label="Description"
                  value={newFlagDescription}
                  onChange={(event) => {
                    setNewFlagDescription(event.target.value);
                  }}
                  sx={{ marginBottom: "10px", width: "100%" }}
                />
                <Select
                  label="Value"
                  value={newFlagValue}
                  onChange={(event) => {
                    setNewFlagValue(Boolean(event.target.value));
                  }}>
                  <MenuItem value={true}>True</MenuItem>
                  <MenuItem value={false}>False</MenuItem>
                </Select>
                <Box
                  sx={{
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "flex-end"
                  }}>
                  <Button onClick={() => setAnchorEl(null)}>Cancel</Button>
                  <Button onClick={addFeatureFlag}>Submit</Button>
                </Box>
              </Paper>
            </Popover>
          </Box>
        </Box>
        <TableContainer>
          <Table aria-label="feature flags table">
            <TableHead>
              <TableRow>
                <TableCell>Flag</TableCell>
                <TableCell>Description</TableCell>
                <TableCell></TableCell>
                <TableCell>Default</TableCell>
                <TableCell>Institution</TableCell>
                <TableCell>Course</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {featureFlags.map((flag) => (
                <TableRow key={flag.flag}>
                  <TableCell align="left" sx={{ width: "300px" }}>
                    {flag.flag}
                  </TableCell>
                  <TableCell align="left" sx={{ width: "500px" }}>
                    {flag.description}
                  </TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell align="left">
                    <Switch
                      checked={flag.enabled}
                      onChange={(event) =>
                        updateFeatureFlag(
                          flag.flag,
                          "default",
                          0,
                          event.target.checked
                        )
                      }
                      inputProps={{ "aria-label": "feature-flag-switch" }}
                    />
                  </TableCell>
                  <TableCell align="left" sx={{ width: "150px" }}>
                    <Autocomplete
                      multiple
                      id="autocomplete-institutions"
                      disableCloseOnSelect
                      disableClearable
                      options={institutionsWithFlags}
                      getOptionLabel={(i) => i.name}
                      value={enabledInstitutionsWithFlags[flag.flag] ?? []}
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Institution" />
                      )}
                      renderOption={(props, option) => {
                        const { key, ...optionProps } = props;
                        return (
                          <li key={key} {...optionProps}>
                            <Checkbox
                              icon={
                                <CheckBoxOutlineBlankIcon fontSize="small" />
                              }
                              checkedIcon={<CheckBoxIcon fontSize="small" />}
                              indeterminateIcon={
                                <IndeterminateCheckBoxIcon
                                  fontSize="small"
                                  sx={{ color: "LightGray" }}
                                />
                              }
                              style={{ marginRight: 8 }}
                              checked={!!option.flags[flag.flag]?.enabled}
                              indeterminate={!option.flags[flag.flag]}
                              onChange={(event) =>
                                updateFeatureFlag(
                                  flag.flag,
                                  "institution",
                                  option.id,
                                  event.target.checked
                                )
                              }
                            />
                            <Box
                              sx={{
                                color: option.flags[flag.flag]
                                  ? "black"
                                  : "LightGray"
                              }}>
                              {option.name}
                            </Box>
                          </li>
                        );
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                          const { key, onDelete, ...tagProps } = getTagProps({
                            index
                          });
                          return (
                            <Chip
                              color={
                                option.flags[flag.flag].enabled
                                  ? "primary"
                                  : "error"
                              }
                              label={option.name}
                              key={key}
                              {...tagProps}
                            />
                          );
                        })
                      }
                    />
                  </TableCell>
                  <TableCell align="left" sx={{ width: "300px" }}>
                    <Autocomplete
                      multiple
                      id="autocomplete-courses"
                      disableCloseOnSelect
                      disableClearable
                      options={coursesWithFlags}
                      getOptionLabel={(i) => i.name}
                      value={enabledCoursesWithFlags[flag.flag] ?? []}
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Course" />
                      )}
                      renderOption={(props, option) => {
                        const { key, ...optionProps } = props;
                        return (
                          <li key={key} {...optionProps}>
                            <Checkbox
                              icon={
                                <CheckBoxOutlineBlankIcon fontSize="small" />
                              }
                              checkedIcon={<CheckBoxIcon fontSize="small" />}
                              indeterminateIcon={
                                <IndeterminateCheckBoxIcon
                                  fontSize="small"
                                  sx={{ color: "LightGray" }}
                                />
                              }
                              style={{ marginRight: 8 }}
                              checked={!!option.flags[flag.flag]?.enabled}
                              indeterminate={!option.flags[flag.flag]}
                              onChange={(event) =>
                                updateFeatureFlag(
                                  flag.flag,
                                  "course",
                                  option.id,
                                  event.target.checked
                                )
                              }
                            />
                            <Box
                              sx={{
                                color: option.flags[flag.flag]
                                  ? "black"
                                  : "LightGray"
                              }}>
                              {option.name}
                            </Box>
                          </li>
                        );
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                          const { key, onDelete, ...tagProps } = getTagProps({
                            index
                          });
                          return (
                            <Chip
                              color={
                                option.flags[flag.flag].enabled
                                  ? "primary"
                                  : "error"
                              }
                              label={option.name}
                              key={key}
                              {...tagProps}
                            />
                          );
                        })
                      }
                    />
                  </TableCell>
                  <TableCell sx={{ width: "122px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        justifyContent: "end",
                        width: "90px"
                      }}>
                      <Tooltip title="Reset flag">
                        <IconButton
                          aria-label="reset"
                          onClick={() =>
                            openConfirmationDialog(
                              "reset",
                              resetFlag,
                              flag.flag
                            )
                          }>
                          <RestoreIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Remove flag">
                        <IconButton
                          aria-label="delete"
                          onClick={() =>
                            openConfirmationDialog(
                              "remove",
                              deleteFlag,
                              flag.flag
                            )
                          }>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                      {confirmationDialogOpen && renderConfirmationDialog()}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </ScrollBox>
  );
}

FeatureFlags.propTypes = {
  courses: PropTypes.array.isRequired,
  institutions: PropTypes.array.isRequired
};

export default FeatureFlags;
