/* eslint react/prop-types: 0 */
// Dependencies
import React from "react";
import { Autocomplete, TextField } from "@mui/material";

const AssistantModel = ({ modelName, handleModelChange }) => {
  const modelNames = [];
  return (
    <Autocomplete
      id="combo-box-demo"
      options={modelNames}
      freeSolo
      sx={{
        fontWeight: "bold",
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            transition: "border-color 0.2s" // Optional: Add a transition for a smoother effect
          },
          "&:hover fieldset": {
            borderColor: "initial" // Reset border color on hover
          }
        }
      }}
      renderInput={(params) => <TextField {...params} label="Bot Name" />}
      value={modelName}
      onChange={(event, newValue) => {
        handleModelChange(newValue);
      }}
    />
  );
};

export default AssistantModel;
