import React from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { useGetTheme } from "../../hooks";
import { setDetailedInstructions } from "../../redux/grSlice";
import {
  //Fab,
  Box,
  Button,
  IconButton,
  Typography
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: "37px",
    marginLeft: "104px",
    marginRight: "104px",
    width: "556px",
    color: "white",
    "& h2": {
      color: "#e8e8e8",
      fontSize: "48px",
      fontWeight: "400",
      lineHeight: "56px",
      marginTop: "45px"
    },
    "& p": {
      color: "#e0e0e0",
      marginTop: "25px"
    },
    "& ul": {
      margin: 0,
      marginTop: 37,
      paddingLeft: theme.spacing(3)
    }
  },
  ltr: {
    textAlign: "left"
  },
  dialog: {
    backgroundColor: "#515151",
    // height: "100vh",
    overflowY: "auto",
    paddingBottom: theme.spacing(10),
    width: "100%",
    height: "100%"
  },
  closeBtn: {
    position: "absolute",
    color: "#fafafa"
  },
  gotitBtn: {
    marginTop: "42px"
  },
  closeBtnLtr: {
    right: "8px"
  }
}));

export function GrInstructions(props) {
  const stage = useSelector((state) => state.gr.stage);
  const classes = useStyles();
  const dispatch = useDispatch();
  const grMode = useSelector((state) => state.gr.mode);
  const theme = useGetTheme();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Box className={classes.dialog}>
          <IconButton
            className={clsx(classes.closeBtn, classes.closeBtnLtr)}
            onClick={() => {
              dispatch(setDetailedInstructions(false));
            }}
            color="primary"
            aria-label="close"
            size="large">
            <ClearIcon />
          </IconButton>

          <Box className={clsx(classes.content, classes.ltr)}>
            <FormattedMessage
              id={`gr.${grMode}.step.${stage + 1}.learnMore`}
              defaultMessage="learnMore"
              values={{
                h2: (...chunks) => (
                  <Typography variant="h2">{chunks}</Typography>
                ),
                ul: (...chunks) => <ul>{chunks}</ul>,
                li: (...chunks) => (
                  <li>
                    <Typography variant="body1" component="span">
                      {chunks}
                    </Typography>
                  </li>
                ),
                p: (...chunks) => (
                  <Typography variant="body1">{chunks}</Typography>
                ),
                br: <br />
              }}
            />

            <Button
              className={classes.gotitBtn}
              onClick={() => {
                dispatch(setDetailedInstructions(false));
              }}
              variant="outlined"
              color="secondary">
              <FormattedMessage
                id="gr.misc.learnMore.close"
                defaultMessage="Got it2"
              />
            </Button>
          </Box>
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
