import { createSlice, createSelector } from "@reduxjs/toolkit";
import { shallowEqual } from "react-redux";
import { FEATURES, USER_PROFILE } from "../consts";

//Thunk

export const STATUS = {
  IDLE: "IDLE",
  PENDING: "PENDING"
};

const initialState = {
  status: STATUS.IDLE, // this is a temp solution until we get rid of the loading state
  activeGrTaskId: null,
  selectedTaskId: null,
  tasks: [],
  submissions: [],
  taskCreationBot: false,
  taskCreationBotMode: "full"
};
export const tasksSlice = createSlice({
  name: "tasks", //TODO: change the name to texts slice once the update is compleated
  initialState,
  reducers: {
    resetTasks: () => initialState,
    setTasks: (state, value) => {
      state.tasks = value.payload;
    },
    setTask: (state, value) => {
      const { task, submissions } = value.payload;

      const updatedTasks = upsertItemsInArray(task, state.tasks);
      const updatedSubmissions = upsertItemsInArray(
        submissions,
        state.submissions
      );

      state.tasks = updatedTasks;
      state.submissions = updatedSubmissions;
    },
    removeTask: (state, value) => {
      const { taskId } = value.payload;
      const updatedTasks = state.tasks.filter((task) => task.id !== taskId);
      state.tasks = updatedTasks;
    },
    setSelectedTaskId: (state, value) => {
      state.selectedTaskId = value.payload;
    },
    // TODO: rename
    setActiveGrTaskId: (state, value) => {
      const taskId = value.payload;
      if (!taskId) state.activeGrTaskId = null;
      else state.activeGrTaskId = Number(taskId);
    },

    setSubmissions: (state, value) => {
      state.submissions = value.payload;
    },

    setSubmission: (state, value) => {
      // TODO: this is the same as set task, refactor
      const newSubmission = value.payload;
      const match = state.submissions.some(
        (submission) => submission.id === newSubmission.id
      );
      if (match) {
        state.submissions = state.submissions.map((submission) =>
          submission.id === newSubmission.id ? newSubmission : submission
        );
      } else {
        state.submissions.push(newSubmission);
      }
    },

    setTaskAndSubmissionsDueDate: (state, value) => {
      const { task_id, due_date } = value.payload;
      const updatedTasks = state.tasks.map((task) =>
        task.id === task_id ? { ...task, original_due_date: due_date } : task
      );
      const updatedSubmissions = state.submissions.map((submission) =>
        submission.task_id === task_id
          ? { ...submission, due_date }
          : submission
      );

      state.tasks = updatedTasks;
      state.submissions = updatedSubmissions;
    },

    setSubmissionDueDate: (state, value) => {
      const { submission_id, due_date } = value.payload;
      const updatedSubmissions = state.submissions.map((submission) =>
        submission.id === submission_id
          ? { ...submission, due_date }
          : submission
      );

      state.submissions = updatedSubmissions;
    },

    updateSubmissions: (state, value) => {
      const payload = value.payload;
      const updatedSubmissions = upsertItemsInArray(payload, state.submissions);

      state.submissions = updatedSubmissions;
    },

    reorderTasks: (state, value) => {
      const tasks = value.payload;
      const updatedTasks = tasks.map((task, index) => {
        return { ...task, display_priority: index };
      });

      state.tasks = updatedTasks;
    },
    deleteSubmissionsByTaskId: (state, value) => {
      const { task_id } = value.payload;
      const submissions = state.submissions.filter(
        (submission) => submission.task_id !== task_id
      );

      state.submissions = submissions;
    },
    updateTaskSubmissionStatus: (state, value) => {
      const { id, status, submission_date } = value.payload;
      const submission = state.submissions.find(
        (submission) => submission.id === id
      );

      submission.submission_date = submission_date;
      submission.status = status;
    },
    updateSubmissionIsChecked: (state, value) => {
      const { id, is_checked } = value.payload;
      const submission = state.submissions.find(
        (submission) => submission.id === Number(id)
      );
      submission.is_checked = is_checked;
    },
    setTaskAndSubmissions: (state, value) => {
      const { tasks, submissions } = value.payload;

      state.tasks = tasks;
      state.submissions = submissions;
    },

    publishTask: (state, value) => {
      const { course_id, name, due_date } = value.payload;
      const placeholderTask = {
        course_id: course_id,
        name: name,
        original_due_date: due_date,
        status: "PENDING"
      };
      state.tasks.push(placeholderTask);
    },
    setStatus: (state, value) => {
      const { status } = value.payload;
      state.status = status;
    },
    // Actions for epics
    //Submit Standard Task
    submitStandardTask: (state, value) => {
      const { submission_id } = value.payload;
      // change to submission status to submitting in order to show the loading state
      const submission = state.submissions.find(
        (submission) => submission.id === submission_id
      );

      submission.status = "SUBMITTING";
    },
    undoSubmitStandardTask: () => {},
    reFetchSubmission: () => {},

    // read tasks
    fetchTeacherCourseTasks: () => {},
    fetchStudentCourseTasks: () => {}
  }
});

export const {
  resetTasks,
  setTasks,
  setTask,
  removeTask,
  setSelectedTaskId,
  setActiveGrTaskId,
  setSubmissions,
  updateSubmissions,
  reorderTasks,
  updateTaskSubmissionStatus,
  updateSubmissionIsChecked,
  deleteSubmissionsByTaskId,
  setTaskAndSubmissionsDueDate,
  setSubmissionDueDate,
  submitStandardTask,
  undoSubmitStandardTask,
  reFetchSubmission,
  publishTask,
  fetchTeacherCourseTasks,
  fetchStudentCourseTasks,
  setTaskAndSubmissions,
  setSubmission,
  setStatus
} = tasksSlice.actions;

// Selectors
export const selectTasks = createSelector(
  [(state) => state.tasks.tasks, (state, course_id) => Number(course_id)],
  (tasks, course_id) => {
    const unsortedTasks = tasks.filter((task) => task.course_id === course_id);

    return unsortedTasks.sort((a, b) => sortByOrderAndDueDate(a, b));
  }
);

export const selectTask = createSelector(
  //Takes in a task id and return a submission
  [(state) => state.tasks.tasks, (state, task_id) => Number(task_id)],
  (tasks, task_id) => tasks.find((task) => task.id === task_id) || {},
  { memoizeOptions: { resultEqualityCheck: shallowEqual } }
);

export const selectCurrentTask = createSelector(
  [(state) => state.tasks.selectedTaskId, (state) => state.tasks.tasks],
  (id, tasks) => tasks.find((task) => task.id === id) || {},
  { memoizeOptions: { resultEqualityCheck: shallowEqual } }
);

export const selectCourseSubmissionsSortedByUserDueDate = createSelector(
  [
    (state) => state.tasks.tasks,
    (state) => state.tasks.submissions,
    (state, course_id) => course_id
  ],
  (tasks, submissions, course_id) =>
    submissions
      .filter((submission) => submission.course_id === course_id)
      .map((submission) => {
        const task = tasks.find((task) => task.id === submission.task_id);
        if (task)
          return { ...submission, display_priority: task.display_priority };
        else return { ...submission, display_priority: null };
      })
      .sort((a, b) => sortByOrderAndDueDate(a, b))
);

export const selectCourseSubmissionsSortedByUserName = createSelector(
  [(state) => state.tasks.submissions, (state, course_id) => course_id],
  (submissions, course_id) =>
    submissions
      .filter((submission) => submission.course_id === course_id)
      .sort((a, b) => a.user_name.localeCompare(b.user_name))
);

export const selectTaskSubmissions = createSelector(
  //Takes in a task id and return an array of submission for that task
  [(state) => state.tasks.submissions, (state, task_id) => Number(task_id)],
  (submissions, task_id) =>
    submissions.filter((submission) => submission.task_id === task_id)
);

export const selectTaskSubmission = createSelector(
  //Takes in a task id and return a submission
  [(state) => state.tasks.submissions, (state, task_id) => Number(task_id)],
  (submissions, task_id) =>
    submissions.find((submission) => submission.task_id === task_id) || {},
  { memoizeOptions: { resultEqualityCheck: shallowEqual } }
);

export const selectSubmission = createSelector(
  //Takes in a submission id and return a submission
  [(state) => state.tasks.submissions, (state, submission_id) => submission_id],
  (submissions, submission_id) =>
    submissions.find((submission) => submission.id === submission_id) || {},
  { memoizeOptions: { resultEqualityCheck: shallowEqual } }
);

export const selectSubmissionCollaboration = createSelector(
  //Takes in a submission id and return a submission
  [(state) => state.tasks.submissions, (state, submission_id) => submission_id],
  (submissions, submission_id) =>
    submissions.find(
      (submission) => submission.related_submission_id === submission_id
    ) || {},
  { memoizeOptions: { resultEqualityCheck: shallowEqual } }
);

export const selectActiveGrTask = createSelector(
  (state) => state.tasks.tasks,
  (state) => state.tasks.submissions,
  (state) => state.texts.selectedText?.id,
  (tasks, submissions, text_id) => {
    if (!text_id) return null;
    const now = new Date();
    const task = tasks
      .filter((task) => task.text_id === Number(text_id))
      .filter((task) => task.task_type === "guidedReading")
      .find((task) => {
        const date = task.original_due_date;
        return new Date(date) >= now;
      });

    if (task) {
      const submission = submissions.find(
        (submission) =>
          submission.task_id === task.id && submission.status !== "Submitted"
      );
      return submission ? task : null;
    } else return null;
  }
);

// Utils

function upsertItemsInArray(items, arrayOfItems) {
  if (!Array.isArray(items)) items = [items];

  arrayOfItems = [...arrayOfItems]; // create a shallow copy

  for (const item of items) {
    const index = arrayOfItems.findIndex((current) => current.id === item.id);
    if (index === -1) {
      arrayOfItems.push(item);
    } else {
      arrayOfItems[index] = item;
    }
  }

  return arrayOfItems;
}

export function sortByOrderAndDueDate(a, b) {
  const aDeadline = a.original_due_date || a.due_date;
  const bDeadline = b.original_due_date || b.due_date;

  // TODO: setting a default value for display_priority will clean this up
  if (!isDisplayPriority(a) && !aDeadline) return 1; // only applicable for draft

  if (!isDisplayPriority(a) && !isDisplayPriority(b)) {
    return new Date(aDeadline) > new Date(bDeadline) ? 1 : -1;
  }
  if (!isDisplayPriority(a)) return 1;
  if (!isDisplayPriority(b)) return -1;

  return a.display_priority - b.display_priority;
}

function isDisplayPriority(obj) {
  return obj.display_priority == null || !("display_priority" in obj)
    ? false
    : true;
}

export function setTaskCreationStates(key, value) {
  return (dispatch, getState, { getFirestore }) => {
    const state = getState();
    const firestore = getFirestore();
    const userId = state.firebase.auth.uid;

    firestore.set(
      `${USER_PROFILE.CUSTOM_CONFIG_PATH}/${userId}/${FEATURES.TASK}`,
      { [key]: value },
      { merge: true }
    );
  };
}

export default tasksSlice.reducer;
