// Dependencies
import React, { useState, useRef, useEffect } from "react";
import clsx from "clsx";
import useConvertToTimezone from "../../../hooks/useConvertToTimezone";
import { useIntl } from "react-intl";
import { isEmpty } from "lodash";
import { firebaseApp, httpCallables } from "../../../firebase";
import { useQuery } from "../../../hooks";

//Redux Dependencies
import { setBreadcrumbs } from "../../../redux/readerActionsSlice";
import { useSelector, useDispatch } from "react-redux";
import {
  setTasks,
  setSubmissions,
  selectTask,
  selectTaskSubmissions
} from "../../../redux/tasksSlice";
import {
  selectText,
  setSelectedTextId,
  setTextUrl
} from "../../../redux/textsSlice";
import { selectCourseByTaskId } from "../../../redux/coursesSlice";
import {
  setInteractions,
  selectQuestions
} from "../../../redux/interactionsSlice";

// Components
import GeneralStats from "./GeneralStats";
import TimeManagmentStats from "./TImeManagmentStats";
import GradeDistributionStats from "./GradeDistributionStats";
import OpenQuestionStats from "./OpenQuestionStats";
import MultiChoiceStats from "./MultiChoiceStats";
import FindInTextStats from "./FindInTextStats";
import StudentEvaluation from "./StudentEvaluation";
import ScrollBox from "../../SharedComponents/ScrollBox";

import makeStyles from "@mui/styles/makeStyles";
import { Box, Typography } from "@mui/material";
import useCreatePortal from "../../../hooks/useCreatePortal";
import FaceRetouchingOffOutlinedIcon from "@mui/icons-material/FaceRetouchingOffOutlined";
import HeaderButtonConstructor from "../../SharedComponents/HeadeButtonConstructor";
// Styles
const useStyles = makeStyles((theme) => {
  return {
    // FIXME: Temp fix for the background color, shold be removed once the background is set on a global level
    container: {
      width: "100%",
      marginTop: "40px"
    },
    content: {
      width: "82%",
      margin: "0 auto"
    },
    pageTitle: {
      marginBottom: "24px"
    },
    pageSubTitle: {
      marginBottom: "80px"
    },
    main: {
      display: "grid",
      // this will have to change once the background color is sorted out
      paddingBottom: "48px",
      gridTemplateColumns: "0.65fr 1.1fr 1.1fr",
      gap: "24px 24px",
      gridAutoFlow: "row"
    },
    general: {
      gridColumn: "1 / 2",
      gridRow: "1"
    },
    timeManagment: {
      gridColumn: "2 / 3",
      gridRow: "1"
    },
    grades: {
      gridColumn: "3 / 4",
      gridRow: "1"
    },
    question: {
      gridColumn: "1 / 4"
    },

    generalStats: {
      textAlign: "center",
      "& h6": { margin: "0" }
    },
    statContainer: {
      padding: "32px"
    },
    questionTitle: {
      fontWeight: "bold",
      marginBottom: "12px"
    },
    lightIcon: {
      color: "#FFFFFF",
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.1)"
      }
    },
    activeIcon: {
      backgroundColor: "#42A5F5",
      border: "1px solid #42A5F5",
      borderRadius: "8px",
      color: "#FFFFFF",
      "&:hover": {
        background: "#42A5F5"
      }
    }
  };
});

export default function TeacherTaskStatsView({
  questions,
  task,
  text,
  submissions
}) {
  // Hooks
  const classes = useStyles();
  const generalStatsNumRef = useRef();
  const [generalStatsHeight, setGeneralStatsHeight] = useState(null);
  const intl = useIntl();
  const dispatch = useDispatch();
  const { task_id } = useQuery();
  const AnnonymizePortal = useCreatePortal(
    document && document.getElementById("global-annonymize-btn")
  );

  // Redux State
  const course = useSelector((state) =>
    selectCourseByTaskId(state, Number(task_id))
  );

  // Ephemeral State
  const [tzFormatedPublishDate, tzPublishDate] = useConvertToTimezone(
    task.publish_date,
    course.timezone
  );
  const [tzFormatedDueDate, tzdueDate] = useConvertToTimezone(
    task.original_due_date,
    course.timezone
  );
  const [annonymize, setAnnonymize] = useState(false);
  // Derived State
  const totalPoints = questions.reduce(
    (accumulator, current) => accumulator + Number(current.points),
    0
  );

  //   Behavior
  useEffect(() => {
    if (generalStatsNumRef) {
      setGeneralStatsHeight(generalStatsNumRef.current.offsetHeight);
    }
  }, [generalStatsNumRef]);

  return (
    <Box className={classes.container}>
      <ScrollBox>
        <Box className={classes.content}>
          <AnnonymizePortal>
            <HeaderButtonConstructor
              intlStringId="menu.tooltip.annonimize"
              defaultMessage="Anonymized view"
              placement="bottom"
              iconLabel="Anonymized view"
              className={clsx(
                classes.lightIcon,
                annonymize && classes.activeIcon
              )}
              handleClick={() => {
                setAnnonymize(!annonymize);
              }}
              icon={<FaceRetouchingOffOutlinedIcon />}
            />
          </AnnonymizePortal>
          <Box component="header">
            <Typography
              className={clsx(classes.left, classes.pageTitle)}
              variant="h2">
              {intl.formatMessage({
                id: "task.statistics.pageTitle",
                defaultMessage: "Assignment Statistics"
              })}
            </Typography>
            {!isEmpty(task) && (
              <Typography
                className={clsx(classes.left, classes.pageSubTitle)}
                variant="body1">
                {`${course.name} / ${task.name} / ${text.author} -
                ${text.name} / ${tzFormatedPublishDate} - ${tzFormatedDueDate}`}
              </Typography>
            )}
          </Box>
          <Box className={classes.main}>
            <Box className={clsx(classes.general, classes.generalStats)}>
              <GeneralStats
                submissions={submissions}
                text={text}
                task={task}
                generalStatsNumRef={generalStatsNumRef}
                totalPoints={totalPoints}
              />
            </Box>
            <Box className={clsx(classes.timeManagment, classes.generalStats)}>
              <TimeManagmentStats
                submissions={submissions}
                text={text}
                task={task}
                generalStatsHeight={generalStatsHeight}
              />
            </Box>
            <Box className={clsx(classes.grades, classes.generalStats)}>
              <GradeDistributionStats
                submissions={submissions}
                text={text}
                task={task}
                totalPoints={totalPoints}
                generalStatsHeight={generalStatsHeight}
              />
            </Box>
            {questions.map((question, index) => {
              return (
                <React.Fragment key={question.id}>
                  {question.interaction_subtype === "MULTI_CHOICE" && (
                    <Box key={question.id} className={classes.question}>
                      <MultiChoiceStats
                        submissions={submissions}
                        question={question}
                        index={index}
                        task={task}
                        text={text}
                      />
                    </Box>
                  )}
                  {question.interaction_subtype === "FIND_IN_TEXT" && (
                    <Box key={index} className={classes.question}>
                      <FindInTextStats
                        submissions={submissions}
                        question={question}
                        index={index}
                        task={task}
                        text={text}
                      />
                    </Box>
                  )}

                  {["OPEN_ENDED"].includes(question.interaction_subtype) && (
                    <Box key={index} className={classes.question}>
                      <OpenQuestionStats
                        submissions={submissions}
                        question={question}
                        index={index}
                        task={task}
                        text={text}
                        annonymizeForm={annonymize}
                      />
                    </Box>
                  )}
                </React.Fragment>
              );
            })}
            <StudentEvaluation submissions={submissions} />
          </Box>
        </Box>
      </ScrollBox>
    </Box>
  );
}
