import React from "react";
import {
  FormControl,
  Checkbox,
  FormControlLabel,
  Typography,
  TextField,
  Box,
  Button,
  IconButton
} from "@mui/material";
import ScrollBox from "../../../SharedComponents/ScrollBox";
import SearchIcon from "@mui/icons-material/Search";

const truncateEnd = (text, maxLength, variant) => {
  if (!text) return "";
  if (text.length <= maxLength) {
    return <Typography variant={variant}>{text}</Typography>;
  }
  const ellipsis = "...";
  const snippetLength = Math.floor((maxLength - ellipsis.length) / 2);
  const snippetStart = text.slice(0, snippetLength);
  const snippetEnd = text.slice(-snippetLength);
  return (
    <Typography variant={variant} sx={{ width: "270px" }}>
      {snippetStart}
      {snippetEnd}
      {ellipsis}
    </Typography>
  );
};

export const renderTextItem = (
  texts,
  selectedTexts,
  handleChange,
  handleSelectAll
) => {
  return (
    <ScrollBox>
      <FormControl
        sx={{
          width: "100%"
        }}>
        <Box
          sx={{
            display: "flex",
            flexFlow: "row",
            alignContent: "center",
            alignItems: "center",
            borderBottom: "1px solid #7D7D7D",
            paddingBlock: "0px",
            paddingInlineStart: "8px",
            width: "100%"
          }}>
          <FormControlLabel
            control={
              <Checkbox
                indeterminate={
                  selectedTexts.length > 0 &&
                  selectedTexts.length < texts.length
                }
              />
            }
            label={"Select All"}
            name="texts"
            onChange={(e) => handleSelectAll(e)}
            checked={selectedTexts.length === texts.length}
          />
        </Box>
        {texts.map((item) => {
          return (
            <Box
              sx={{
                display: "flex",
                flexFlow: "row",
                alignContent: "center",
                alignItems: "center",
                borderBottom: "1px solid #e0e0e0",
                paddingBlock: "4px",
                paddingInlineStart: "8px",
                width: "100%"
              }}
              key={item.id}>
              <FormControlLabel
                key={item.id}
                checked={selectedTexts.some((text) => text.id === item.id)}
                control={<Checkbox value={item.name} />}
                label={item.name}
                name="texts"
                onChange={(e) => handleChange(e, item)}
              />
            </Box>
          );
        })}
      </FormControl>
    </ScrollBox>
  );
};

export const renderTasksItem = (
  tasks,
  texts,
  handleChange,
  setHovered,
  hovered,
  onIncludeTextToggle,
  selectedTasks,
  handleSelectAll
) => {
  return (
    <ScrollBox>
      <FormControl
        sx={{
          width: "100%"
        }}>
        <Box
          sx={{
            display: "flex",
            flexFlow: "row",
            alignItems: "flex-start",
            borderBottom: "1px solid #7D7D7D",
            paddingBlock: "4px",
            paddingInlineStart: "8px",
            width: "100%"
          }}>
          <FormControlLabel
            label={"Select All"}
            checked={selectedTasks.length === tasks.length}
            control={
              <Checkbox
                sx={{ margin: 0, padding: 0, marginInlineEnd: "15px" }}
                indeterminate={
                  selectedTasks.length > 0 &&
                  selectedTasks.length < tasks.length
                }
                onChange={(e) => handleSelectAll(e)}></Checkbox>
            }
            name="tasks"
          />
        </Box>
        {tasks.map((item) => {
          const currentTask = selectedTasks.filter(
            (task) => task.id === item.id
          )[0];
          return (
            <Box
              sx={{
                display: "flex",
                flexFlow: "row",
                alignItems: "flex-start",
                borderBottom: "1px solid #e0e0e0",
                paddingBlock: "4px",
                paddingInlineStart: "8px",
                width: "100%"
              }}
              key={item.id}
              onMouseEnter={() => setHovered(item.id)}
              onMouseLeave={() => setHovered(null)}>
              <FormControlLabel
                checked={selectedTasks.some((task) => task.id === item.id)}
                key={item.id}
                aria-label={"Select task"}
                control={
                  <Checkbox
                    value={item.name}
                    defaultChecked
                    onChange={(e) => handleChange(e, item)}
                    sx={{ margin: 0, padding: 0 }}
                  />
                }
                name="tasks"
              />
              <Box sx={{ display: "flex", flexFlow: "column" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexFlow: "row",
                    alignItems: "flex-end",
                    padding: 0
                  }}>
                  {truncateEnd(item.name, 30, "body1")}
                  {item.status === "Draft" && (
                    <Typography
                      variant="caption"
                      sx={{ marginInlineStart: "4px" }}>
                      - Draft
                    </Typography>
                  )}
                </Box>
                <Box sx={{ display: "flex", flexFlow: "row" }}>
                  {truncateEnd(
                    texts.length &&
                      texts.filter((text) => text.id === item.text_id)[0]?.name,
                    40,
                    "caption"
                  )}
                </Box>
              </Box>
              {currentTask && (
                <Button
                  variant="outlined"
                  sx={{
                    marginRight: "4px",
                    marginLeft: "auto",
                    marginTop: "4px",
                    opacity: hovered === item.id ? 1 : 0,
                    "&:focus": {
                      opacity: 1
                    }
                  }}
                  onClick={() => {
                    if (currentTask) {
                      onIncludeTextToggle(currentTask.id);
                    }
                  }}>
                  {currentTask && currentTask.includeText
                    ? "Remove reading"
                    : "Reattach reading"}
                </Button>
              )}
            </Box>
          );
        })}
      </FormControl>
    </ScrollBox>
  );
};

export const renderCourseAndTeacher = (
  coursesAndTeachers,
  searchQuery,
  filteredResults,
  handleSearch,
  handleChange,
  handleSelectAll,
  selectedCourses
) => {
  return (
    <ScrollBox>
      <FormControl
        sx={{
          width: "100%"
        }}>
        <TextField
          id="outlined-basic"
          label="Search course"
          variant="outlined"
          value={searchQuery}
          sx={{ margin: "10px" }}
          onChange={handleSearch}>
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </TextField>
        <Box
          sx={{
            display: "flex",
            flexFlow: "row",
            alignItems: "center",
            alignContent: "center",
            borderBottom: "1px solid #7D7D7D",
            paddingInlineStart: "8px",
            width: "100%"
          }}>
          <FormControlLabel
            label={"Select All"}
            control={
              <Checkbox
                sx={{ marginInlineEnd: "15px" }}
                indeterminate={
                  selectedCourses.length > 0 &&
                  selectedCourses.length < coursesAndTeachers.length
                }
                onChange={(e) => handleSelectAll(e)}>
                checked={selectedCourses.length === coursesAndTeachers.length}
              </Checkbox>
            }
            name="courses"
          />
        </Box>

        {filteredResults.map((item) => (
          <Box
            sx={{
              display: "flex",
              flexFlow: "row",
              alignContent: "center",
              alignItems: "center",
              borderBottom: "1px solid #e0e0e0",
              paddingBlock: "4px",
              paddingInlineStart: "8px",
              width: "100%"
            }}
            key={item.id}>
            <FormControlLabel
              checked={selectedCourses.some((course) => course.id === item.id)}
              key={item.id}
              onChange={(e) => handleChange(e, item)}
              control={<Checkbox value={item.name} />}
              name="courses"
              aria-label={"Select course"}
            />
            <Box sx={{ display: "flex", flexFlow: "column" }}>
              <Box sx={{ padding: 0 }}>
                <Typography variant="body1">{item.name}</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexFlow: "row",
                  alignContent: "flex-start"
                }}>
                <Box
                  sx={{
                    display: "flex",
                    flexFlow: "row"
                  }}>
                  {item.teachers.slice(0, 2).map((teacher, index) => (
                    <React.Fragment key={teacher}>
                      <Typography variant="body2">{teacher}</Typography>

                      {index === 1 && item.teachers.length > 2 && (
                        <span>...</span>
                      )}
                      {index !== Math.min(1, item.teachers.length - 1) &&
                        index !== 1 && <span>,&nbsp; </span>}
                    </React.Fragment>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
      </FormControl>
    </ScrollBox>
  );
};

export const extractDataForTasksShare = (array) => {
  return array.map((item) => {
    const { id, text_id, includeText, status } = item;
    return { id, text_id, includeText, status };
  });
};

export const extractIdsFromArray = (array) => {
  return array.map((item) => item.id);
};
