// Dependencies
import React from "react";
import PropTypes from "prop-types";
import { motion, AnimatePresence } from "framer-motion";

// Redux dependencies

//Components
import TooltipWithIntl from "../tooltip/TooltipWithIntl";

// Material UI
import { IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  iconWrapper: {
    // we are wrapping the icon for animation, so this is needed keep the ripple circleular
    display: "inline-flex"
  }
}));

// A wrapper for IconButton that provides styles, tooltip and animation as well as the onClick handler that returns the interaction's content

export default function InteractionCardAction({
  // Props that are passed implicitly from the parent Card component
  interaction,

  // Other props
  children,
  onClick = null,
  intlStringId,
  defaultMessage,
  tooltipPlacement = "top",
  size = "small",
  disabled = false
}) {
  // Hooks
  const classes = useStyles();
  // Ephemeral state

  // Derived state

  // Validate that only a single child is passed
  React.Children.only(children);
  return (
    <TooltipWithIntl
      intlStringId={intlStringId}
      defaultMessage={defaultMessage}
      placement={tooltipPlacement}>
      <IconButton
        aria-label={defaultMessage}
        disabled={disabled}
        size={size}
        onClick={() => {
          onClick && onClick({ content: interaction.content });
        }}>
        <AnimatePresence>
          <motion.div
            initial={{ scale: 0, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0, opacity: 0 }}
            className={classes.iconWrapper}>
            {/* clone the children passing the size prop */}
            {React.cloneElement(children, { fontSize: size })}
          </motion.div>
        </AnimatePresence>
      </IconButton>
    </TooltipWithIntl>
  );
}

InteractionCardAction.propTypes = {
  interaction: PropTypes.object, // interaction is passed implicitly from the parent
  children: PropTypes.element.isRequired, // Only a single child is required
  onClick: PropTypes.func,
  intlStringId: PropTypes.string.isRequired,
  defaultMessage: PropTypes.string.isRequired,
  tooltipPlacement: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium"]),
  disabled: PropTypes.bool
};
