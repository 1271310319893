const academicFields = [
  {
    field: "Agricultural - General",
    discipline: "Agriculture"
  },
  {
    field: "Agricultural Extension",
    discipline: "Agriculture"
  },
  {
    field: "Agronomy/Plant & Soil Sciences",
    discipline: "Agriculture"
  },
  {
    field: "Animal Genomics",
    discipline: "Agriculture"
  },
  {
    field: "Animal Science",
    discipline: "Agriculture"
  },
  {
    field: "Biosystems Engineering",
    discipline: "Agriculture"
  },
  {
    field: "Botany",
    discipline: "Agriculture"
  },
  {
    field: "Dairy Science",
    discipline: "Agriculture"
  },
  {
    field: "Economics",
    discipline: "Agriculture"
  },
  {
    field: "Engineering/Engineering Extension",
    discipline: "Agriculture"
  },
  {
    field: "Entomology",
    discipline: "Agriculture"
  },
  {
    field: "Environmental Science & Ecology",
    discipline: "Agriculture"
  },
  {
    field: "Food & Nutritional Science",
    discipline: "Agriculture"
  },
  {
    field: "Forest Ecology & Management",
    discipline: "Agriculture"
  },
  {
    field: "Horticulture",
    discipline: "Agriculture"
  },
  {
    field: "Landscape Architecture",
    discipline: "Agriculture"
  },
  {
    field: "Land & Water Resource Engineering",
    discipline: "Agriculture"
  },
  {
    field: "Meat Science",
    discipline: "Agriculture"
  },
  {
    field: "Pathobiology",
    discipline: "Agriculture"
  },
  {
    field: "Plant Genetics",
    discipline: "Agriculture"
  },
  {
    field: "Plant Pathology",
    discipline: "Agriculture"
  },
  {
    field: "Poultry Science",
    discipline: "Agriculture"
  },
  {
    field: "Statistics",
    discipline: "Agriculture"
  },
  {
    field: "Veterinary Science/Medicine",
    discipline: "Agriculture"
  },
  {
    field: "Viticulture and Enology",
    discipline: "Agriculture"
  },
  {
    field: "Water Management/Policy",
    discipline: "Agriculture"
  },
  {
    field: "Zoology/Genetics",
    discipline: "Agriculture"
  },
  {
    field: "Business - General",
    discipline: "Business"
  },
  {
    field: "Accounting and/or Taxes",
    discipline: "Business"
  },
  {
    field: "Arts Administration",
    discipline: "Business"
  },
  {
    field: "Business Communications",
    discipline: "Business"
  },
  {
    field: "Business Ethics",
    discipline: "Business"
  },
  {
    field: "Business Law",
    discipline: "Business"
  },
  {
    field: "Construction Management",
    discipline: "Business"
  },
  {
    field: "Decision Sciences",
    discipline: "Business"
  },
  {
    field: "Economics",
    discipline: "Business"
  },
  {
    field: "Entrepreneurship",
    discipline: "Business"
  },
  {
    field: "Finance",
    discipline: "Business"
  },
  {
    field: "General Management",
    discipline: "Business"
  },
  {
    field: "Health Systems Management",
    discipline: "Business"
  },
  {
    field: "Hospitality/Tourism Management",
    discipline: "Business"
  },
  {
    field: "Human Resources",
    discipline: "Business"
  },
  {
    field: "Insurance/Risk Management",
    discipline: "Business"
  },
  {
    field: "International Business",
    discipline: "Business"
  },
  {
    field: "Logistics/Supply Chain Management",
    discipline: "Business"
  },
  {
    field: "Management Information Systems - All",
    discipline: "Business"
  },
  {
    field: "Marketing",
    discipline: "Business"
  },
  {
    field: "MBA & Executive MBA",
    discipline: "Business"
  },
  {
    field: "Nonprofit Organizations",
    discipline: "Business"
  },
  {
    field: "Organizational Studies",
    discipline: "Business"
  },
  {
    field: "Production/Operations Management",
    discipline: "Business"
  },
  {
    field: "Public Policy & Administration",
    discipline: "Business"
  },
  {
    field: "Real Estate",
    discipline: "Business"
  },
  {
    field: "Quantitative Analysis",
    discipline: "Business"
  },
  {
    field: "Sport Management",
    discipline: "Business"
  },
  {
    field: "Statistics & Actuarial Science",
    discipline: "Business"
  },
  {
    field: "Technology & Information Management",
    discipline: "Business"
  },
  {
    field: "Administration - Executive",
    discipline: "Community Colleges"
  },
  {
    field: "Administration - Academic Unit",
    discipline: "Community Colleges"
  },
  {
    field: "Administration - Accounting & Finance",
    discipline: "Community Colleges"
  },
  {
    field: "Administration - Counseling Services",
    discipline: "Community Colleges"
  },
  {
    field: "Administration - Human Resources",
    discipline: "Community Colleges"
  },
  {
    field: "Administration - Student Affairs",
    discipline: "Community Colleges"
  },
  {
    field: "Administration - Undergraduate Education",
    discipline: "Community Colleges"
  },
  {
    field: "Administration - Other",
    discipline: "Community Colleges"
  },
  {
    field: "Agriculture/Vet Med",
    discipline: "Community Colleges"
  },
  {
    field: "Business",
    discipline: "Community Colleges"
  },
  {
    field: "Culinary Arts/Food Management",
    discipline: "Community Colleges"
  },
  {
    field: "Education",
    discipline: "Community Colleges"
  },
  {
    field: "Engineering",
    discipline: "Community Colleges"
  },
  {
    field: "Fine Arts - Dance",
    discipline: "Community Colleges"
  },
  {
    field: "Fine Arts - Drama/Theater",
    discipline: "Community Colleges"
  },
  {
    field: "Fine Arts - Music",
    discipline: "Community Colleges"
  },
  {
    field: "Fine Arts - Visual Arts",
    discipline: "Community Colleges"
  },
  {
    field: "Fine Arts - Other",
    discipline: "Community Colleges"
  },
  {
    field: "Health Sciences",
    discipline: "Community Colleges"
  },
  {
    field: "Humanities - English",
    discipline: "Community Colleges"
  },
  {
    field: "Humanities - Foreign Languages",
    discipline: "Community Colleges"
  },
  {
    field: "Humanities - History",
    discipline: "Community Colleges"
  },
  {
    field: "Humanities - Literature",
    discipline: "Community Colleges"
  },
  {
    field: "Humanities - Philosophy",
    discipline: "Community Colleges"
  },
  {
    field: "Humanities - Other",
    discipline: "Community Colleges"
  },
  {
    field: "Law",
    discipline: "Community Colleges"
  },
  {
    field: "Medicine",
    discipline: "Community Colleges"
  },
  {
    field: "Sciences - Biological",
    discipline: "Community Colleges"
  },
  {
    field: "Sciences - Chemistry",
    discipline: "Community Colleges"
  },
  {
    field: "Sciences - Computer Science",
    discipline: "Community Colleges"
  },
  {
    field: "Sciences - Math",
    discipline: "Community Colleges"
  },
  {
    field: "Sciences - Physics",
    discipline: "Community Colleges"
  },
  {
    field: "Sciences - Other",
    discipline: "Community Colleges"
  },
  {
    field: "Social Sciences - Communication",
    discipline: "Community Colleges"
  },
  {
    field: "Social Sciences - Economics",
    discipline: "Community Colleges"
  },
  {
    field: "Social Sciences - Geography",
    discipline: "Community Colleges"
  },
  {
    field: "Social Sciences - Political Science",
    discipline: "Community Colleges"
  },
  {
    field: "Social Sciences - Psychology",
    discipline: "Community Colleges"
  },
  {
    field: "Social Sciences - Sociology",
    discipline: "Community Colleges"
  },
  {
    field: "Social Sciences - Other",
    discipline: "Community Colleges"
  },
  {
    field: "Vocational/Technical",
    discipline: "Community Colleges"
  },
  {
    field: "Education - General",
    discipline: "Curriculum & Education"
  },
  {
    field: "Administration/Supervision",
    discipline: "Curriculum & Education"
  },
  {
    field: "Adult and Distance Education",
    discipline: "Curriculum & Education"
  },
  {
    field: "Art Education",
    discipline: "Curriculum & Education"
  },
  {
    field: "Audiology/Speech Pathology",
    discipline: "Curriculum & Education"
  },
  {
    field: "Counseling/Human Services",
    discipline: "Curriculum & Education"
  },
  {
    field: "Childhood/Youth Education",
    discipline: "Curriculum & Education"
  },
  {
    field: "Curriculum/Instruction",
    discipline: "Curriculum & Education"
  },
  {
    field: "Education Policy",
    discipline: "Curriculum & Education"
  },
  {
    field: "Educational Psychology/Behavior Analysis",
    discipline: "Curriculum & Education"
  },
  {
    field: "Educational Technology",
    discipline: "Curriculum & Education"
  },
  {
    field: "Elementary Education",
    discipline: "Curriculum & Education"
  },
  {
    field: "English Education",
    discipline: "Curriculum & Education"
  },
  {
    field: "English as a Second Language",
    discipline: "Curriculum & Education"
  },
  {
    field: "Health & Human Performance",
    discipline: "Curriculum & Education"
  },
  {
    field: "Higher Education Administration",
    discipline: "Curriculum & Education"
  },
  {
    field: "Human Development",
    discipline: "Curriculum & Education"
  },
  {
    field: "Information Studies",
    discipline: "Curriculum & Education"
  },
  {
    field: "Instructional Design/Technology",
    discipline: "Curriculum & Education"
  },
  {
    field: "Math Education",
    discipline: "Curriculum & Education"
  },
  {
    field: "Middle School",
    discipline: "Curriculum & Education"
  },
  {
    field: "Music Education",
    discipline: "Curriculum & Education"
  },
  {
    field: "Reading/Developmental Education",
    discipline: "Curriculum & Education"
  },
  {
    field: "Organization and Leadership",
    discipline: "Curriculum & Education"
  },
  {
    field: "Physical Education & Kinesiology",
    discipline: "Curriculum & Education"
  },
  {
    field: "Professional Development",
    discipline: "Curriculum & Education"
  },
  {
    field: "Rehabilitation Counseling/Psychology",
    discipline: "Curriculum & Education"
  },
  {
    field: "Science Education",
    discipline: "Curriculum & Education"
  },
  {
    field: "Secondary Education",
    discipline: "Curriculum & Education"
  },
  {
    field: "Social Foundations",
    discipline: "Curriculum & Education"
  },
  {
    field: "Sociology",
    discipline: "Curriculum & Education"
  },
  {
    field: "Special Education",
    discipline: "Curriculum & Education"
  },
  {
    field: "Sports Studies",
    discipline: "Curriculum & Education"
  },
  {
    field: "Teacher Education",
    discipline: "Curriculum & Education"
  },
  {
    field: "Urban Education",
    discipline: "Curriculum & Education"
  },
  {
    field: "Vocational/Technical",
    discipline: "Curriculum & Education"
  },
  {
    field: "Dentistry - General",
    discipline: "Dentistry"
  },
  {
    field: "Biological/Materials Sciences",
    discipline: "Dentistry"
  },
  {
    field: "Cariology",
    discipline: "Dentistry"
  },
  {
    field: "Dental Anesthesiology",
    discipline: "Dentistry"
  },
  {
    field: "Dental Hygiene",
    discipline: "Dentistry"
  },
  {
    field: "Dental Technology",
    discipline: "Dentistry"
  },
  {
    field: "Dentistry & Stomatology",
    discipline: "Dentistry"
  },
  {
    field: "Endodontics",
    discipline: "Dentistry"
  },
  {
    field: "Family Dentistry",
    discipline: "Dentistry"
  },
  {
    field: "Gerodontology",
    discipline: "Dentistry"
  },
  {
    field: "Oral & Maxillofacial Surgery",
    discipline: "Dentistry"
  },
  {
    field: "Oral Biology",
    discipline: "Dentistry"
  },
  {
    field: "Oral Medicine/Pathology/Oncology",
    discipline: "Dentistry"
  },
  {
    field: "Oral Radiology",
    discipline: "Dentistry"
  },
  {
    field: "Orthodontics",
    discipline: "Dentistry"
  },
  {
    field: "Pediatric Dentistry",
    discipline: "Dentistry"
  },
  {
    field: "Periodontics/Periodontology",
    discipline: "Dentistry"
  },
  {
    field: "Preventive & Community Dentistry",
    discipline: "Dentistry"
  },
  {
    field: "Prosthodontics/Restorative Dentistry",
    discipline: "Dentistry"
  },
  {
    field: "Public Health/Epidemiology",
    discipline: "Dentistry"
  },
  {
    field: "Engineering - Other",
    discipline: "Engineering"
  },
  {
    field: "Aerospace/Aeronautical/Astronautics",
    discipline: "Engineering"
  },
  {
    field: "Agricultural",
    discipline: "Engineering"
  },
  {
    field: "Architectural (Building & Construction)",
    discipline: "Engineering"
  },
  {
    field: "Bioengineering (all Bio-related fields)",
    discipline: "Engineering"
  },
  {
    field: "Chemical/Petroleum",
    discipline: "Engineering"
  },
  {
    field: "Civil Engineering",
    discipline: "Engineering"
  },
  {
    field: "Construction Engineering/Management",
    discipline: "Engineering"
  },
  {
    field: "Computer Science",
    discipline: "Engineering"
  },
  {
    field: "Computer Engineering",
    discipline: "Engineering"
  },
  {
    field: "Education Systems & Design",
    discipline: "Engineering"
  },
  {
    field: "Electrical and/or Electronics",
    discipline: "Engineering"
  },
  {
    field: "Energy Technology",
    discipline: "Engineering"
  },
  {
    field: "Engineering Mechanics",
    discipline: "Engineering"
  },
  {
    field: "Engineering Physics",
    discipline: "Engineering"
  },
  {
    field: "Ecological and Environmental",
    discipline: "Engineering"
  },
  {
    field: "Food Process Engineering",
    discipline: "Engineering"
  },
  {
    field: "Geomatics",
    discipline: "Engineering"
  },
  {
    field: "Geotechnical",
    discipline: "Engineering"
  },
  {
    field: "Human Factors Engineering/Ergonomics",
    discipline: "Engineering"
  },
  {
    field: "Industrial & Systems Engineering",
    discipline: "Engineering"
  },
  {
    field: "Manufacturing & Quality Engineering",
    discipline: "Engineering"
  },
  {
    field: "Material/Metallurgy",
    discipline: "Engineering"
  },
  {
    field: "Mechanical Engineering",
    discipline: "Engineering"
  },
  {
    field: "Mechatronics",
    discipline: "Engineering"
  },
  {
    field: "Mining/Minerals",
    discipline: "Engineering"
  },
  {
    field: "Naval Architecture & Marine Engineering",
    discipline: "Engineering"
  },
  {
    field: "Nuclear",
    discipline: "Engineering"
  },
  {
    field: "Ocean Engineering",
    discipline: "Engineering"
  },
  {
    field: "Optics & Optical Engineering",
    discipline: "Engineering"
  },
  {
    field: "Polymer Science",
    discipline: "Engineering"
  },
  {
    field: "Pulp & Paper Science",
    discipline: "Engineering"
  },
  {
    field: "Risk Management & Financial Engineering",
    discipline: "Engineering"
  },
  {
    field: "Robotics",
    discipline: "Engineering"
  },
  {
    field: "Structural Engineering",
    discipline: "Engineering"
  },
  {
    field: "Sustainable Engineering",
    discipline: "Engineering"
  },
  {
    field: "Textile Engineering",
    discipline: "Engineering"
  },
  {
    field: "Transportation Engineering",
    discipline: "Engineering"
  },
  {
    field: "Water Resources Engineering",
    discipline: "Engineering"
  },
  {
    field: "Fine Arts - General",
    discipline: "Fine Arts"
  },
  {
    field: "Architecture/Landscape Architecture",
    discipline: "Fine Arts"
  },
  {
    field: "Art Administration",
    discipline: "Fine Arts"
  },
  {
    field: "Art Education",
    discipline: "Fine Arts"
  },
  {
    field: "Art History",
    discipline: "Fine Arts"
  },
  {
    field: "Art - General",
    discipline: "Fine Arts"
  },
  {
    field: "Art - Ceramics",
    discipline: "Fine Arts"
  },
  {
    field: "Art - Painting/Drawing",
    discipline: "Fine Arts"
  },
  {
    field: "Art - Photography",
    discipline: "Fine Arts"
  },
  {
    field: "Art - Printmaking",
    discipline: "Fine Arts"
  },
  {
    field: "Art - Sculpture",
    discipline: "Fine Arts"
  },
  {
    field: "Communications/Information Systems",
    discipline: "Fine Arts"
  },
  {
    field: "Culinary Arts/Gastronomy",
    discipline: "Fine Arts"
  },
  {
    field: "Dance",
    discipline: "Fine Arts"
  },
  {
    field: "Design - Fashion & Textile Design",
    discipline: "Fine Arts"
  },
  {
    field: "Design - Graphic Design/Illustration",
    discipline: "Fine Arts"
  },
  {
    field: "Design - Industrial Art/Design",
    discipline: "Fine Arts"
  },
  {
    field: "Design - Interior Design",
    discipline: "Fine Arts"
  },
  {
    field: "Design - Jewelry Design",
    discipline: "Fine Arts"
  },
  {
    field: "Design - Product Design",
    discipline: "Fine Arts"
  },
  {
    field: "Digital Media",
    discipline: "Fine Arts"
  },
  {
    field: "Drama/Theater/Theater Education",
    discipline: "Fine Arts"
  },
  {
    field: "Film/Video",
    discipline: "Fine Arts"
  },
  {
    field: "Game Design/Animation",
    discipline: "Fine Arts"
  },
  {
    field: "Music Education",
    discipline: "Fine Arts"
  },
  {
    field: "Music Industry Studies",
    discipline: "Fine Arts"
  },
  {
    field: "Music - General",
    discipline: "Fine Arts"
  },
  {
    field: "Music - Classical",
    discipline: "Fine Arts"
  },
  {
    field: "Music - Composition",
    discipline: "Fine Arts"
  },
  {
    field: "Music - Conducting",
    discipline: "Fine Arts"
  },
  {
    field: "Music - Ethnomusicology",
    discipline: "Fine Arts"
  },
  {
    field: "Music - Jazz",
    discipline: "Fine Arts"
  },
  {
    field: "Music - Musicology/Music History",
    discipline: "Fine Arts"
  },
  {
    field: "Music - Vocal",
    discipline: "Fine Arts"
  },
  {
    field: "Music - Orchestra/Symphony",
    discipline: "Fine Arts"
  },
  {
    field: "Music - Piano/Organ/Keyboard",
    discipline: "Fine Arts"
  },
  {
    field: "Music - Psychology",
    discipline: "Fine Arts"
  },
  {
    field: "Music - Scientific Study/Theory",
    discipline: "Fine Arts"
  },
  {
    field: "Music - Song Performance",
    discipline: "Fine Arts"
  },
  {
    field: "Performance Studies",
    discipline: "Fine Arts"
  },
  {
    field: "Television/Media Studies",
    discipline: "Fine Arts"
  },
  {
    field: "Health Sciences - General",
    discipline: "Health Sciences"
  },
  {
    field: "Acupuncture/Oriental Medicine",
    discipline: "Health Sciences"
  },
  {
    field: "Allied Health Professions",
    discipline: "Health Sciences"
  },
  {
    field: "Anatomy & Applied Anatomy",
    discipline: "Health Sciences"
  },
  {
    field: "Anesthesiology",
    discipline: "Health Sciences"
  },
  {
    field: "Audiology",
    discipline: "Health Sciences"
  },
  {
    field: "Biomedical Sciences",
    discipline: "Health Sciences"
  },
  {
    field: "Chiropractic",
    discipline: "Health Sciences"
  },
  {
    field: "Clinical Psychology",
    discipline: "Health Sciences"
  },
  {
    field: "Communications Disorders",
    discipline: "Health Sciences"
  },
  {
    field: "Community Health",
    discipline: "Health Sciences"
  },
  {
    field: "Dental Hygiene/Dental Assisting",
    discipline: "Health Sciences"
  },
  {
    field: "Emergency Medicine",
    discipline: "Health Sciences"
  },
  {
    field: "Environmental/Occupational Health",
    discipline: "Health Sciences"
  },
  {
    field: "Family Medicine",
    discipline: "Health Sciences"
  },
  {
    field: "Forensic Sciences",
    discipline: "Health Sciences"
  },
  {
    field: "Health Administration & Policy",
    discipline: "Health Sciences"
  },
  {
    field: "Health Economics",
    discipline: "Health Sciences"
  },
  {
    field: "Health Education & Promotion",
    discipline: "Health Sciences"
  },
  {
    field: "Health Services Management",
    discipline: "Health Sciences"
  },
  {
    field: "Health Services/Research & Policy",
    discipline: "Health Sciences"
  },
  {
    field: "Health Wellness & Injury Prevention",
    discipline: "Health Sciences"
  },
  {
    field: "Hospice/Palliative Care",
    discipline: "Health Sciences"
  },
  {
    field: "Immunology & Infectious Diseases",
    discipline: "Health Sciences"
  },
  {
    field: "Industrial Hygiene",
    discipline: "Health Sciences"
  },
  {
    field: "Informatics - All Categories",
    discipline: "Health Sciences"
  },
  {
    field: "Medical Microbiology",
    discipline: "Health Sciences"
  },
  {
    field: "Medicine/Laboratory Medicine",
    discipline: "Health Sciences"
  },
  {
    field: "Midwifery",
    discipline: "Health Sciences"
  },
  {
    field: "Neurodevelopmental Disorders",
    discipline: "Health Sciences"
  },
  {
    field: "Nutrition and Dietetics",
    discipline: "Health Sciences"
  },
  {
    field: "Nursing - General",
    discipline: "Health Sciences"
  },
  {
    field: "Nursing - Administration",
    discipline: "Health Sciences"
  },
  {
    field: "Nursing - Adult Care",
    discipline: "Health Sciences"
  },
  {
    field: "Nursing - Clinical (all categories)",
    discipline: "Health Sciences"
  },
  {
    field: "Nursing - Geriatrics",
    discipline: "Health Sciences"
  },
  {
    field: "Nursing - Licensed Practical Nurse (LPN)",
    discipline: "Health Sciences"
  },
  {
    field: "Nursing - Obstetrics & Gynecology",
    discipline: "Health Sciences"
  },
  {
    field: "Nursing - Pediatric",
    discipline: "Health Sciences"
  },
  {
    field: "Nursing - Practitioner (all categories)",
    discipline: "Health Sciences"
  },
  {
    field: "Nursing - Psychiatric",
    discipline: "Health Sciences"
  },
  {
    field: "Nursing - Women's Health",
    discipline: "Health Sciences"
  },
  {
    field: "Occupational Therapy",
    discipline: "Health Sciences"
  },
  {
    field: "Optometry/Vision Science",
    discipline: "Health Sciences"
  },
  {
    field: "Pathology",
    discipline: "Health Sciences"
  },
  {
    field: "Pharmaceutical Sciences",
    discipline: "Health Sciences"
  },
  {
    field: "Physical Education & Kinesiology",
    discipline: "Health Sciences"
  },
  {
    field: "Physical Therapy/Rehabilitation",
    discipline: "Health Sciences"
  },
  {
    field: "Physician Assistant",
    discipline: "Health Sciences"
  },
  {
    field: "Physiology/Exercise Physiology",
    discipline: "Health Sciences"
  },
  {
    field: "Physiology/Human Physiology",
    discipline: "Health Sciences"
  },
  {
    field: "Public Health/Biostatistics/Epidemiology",
    discipline: "Health Sciences"
  },
  {
    field: "Public Health/Management/Administration",
    discipline: "Health Sciences"
  },
  {
    field: "Rehabilitation Counseling",
    discipline: "Health Sciences"
  },
  {
    field: "Respiratory Therapy",
    discipline: "Health Sciences"
  },
  {
    field: "Social and Behavioral Medicine",
    discipline: "Health Sciences"
  },
  {
    field: "Social Work",
    discipline: "Health Sciences"
  },
  {
    field: "Speech/Hearing Sciences",
    discipline: "Health Sciences"
  },
  {
    field: "Sport & Exercise Science",
    discipline: "Health Sciences"
  },
  {
    field: "Toxicology",
    discipline: "Health Sciences"
  },
  {
    field: "Women's Health",
    discipline: "Health Sciences"
  },
  {
    field: "Humanities - General",
    discipline: "Humanities"
  },
  {
    field: "African American Studies",
    discipline: "Humanities"
  },
  {
    field: "American Studies",
    discipline: "Humanities"
  },
  {
    field: "Anthropology",
    discipline: "Humanities"
  },
  {
    field: "Archaeology",
    discipline: "Humanities"
  },
  {
    field: "Applied Linguistics",
    discipline: "Humanities"
  },
  {
    field: "Classics",
    discipline: "Humanities"
  },
  {
    field: "Communication",
    discipline: "Humanities"
  },
  {
    field: "Creative or Technical Writing",
    discipline: "Humanities"
  },
  {
    field: "English",
    discipline: "Humanities"
  },
  {
    field: "English as a Second Language",
    discipline: "Humanities"
  },
  {
    field: "Ethnic/Multicultural Studies",
    discipline: "Humanities"
  },
  {
    field: "Foreign Languages/Literatures",
    discipline: "Humanities"
  },
  {
    field: "LGBTQ Studies",
    discipline: "Humanities"
  },
  {
    field: "History - General",
    discipline: "Humanities"
  },
  {
    field: "History - Art",
    discipline: "Humanities"
  },
  {
    field: "History - African",
    discipline: "Humanities"
  },
  {
    field: "History - African American",
    discipline: "Humanities"
  },
  {
    field: "History - Asian",
    discipline: "Humanities"
  },
  {
    field: "History - Cultural",
    discipline: "Humanities"
  },
  {
    field: "History - European",
    discipline: "Humanities"
  },
  {
    field: "History - Latin American",
    discipline: "Humanities"
  },
  {
    field: "History - Middle East",
    discipline: "Humanities"
  },
  {
    field: "History - Modern U.S.",
    discipline: "Humanities"
  },
  {
    field: "History - U.S. Colonial",
    discipline: "Humanities"
  },
  {
    field: "History - Women's",
    discipline: "Humanities"
  },
  {
    field: "Interdisciplinary Studies",
    discipline: "Humanities"
  },
  {
    field: "Journalism",
    discipline: "Humanities"
  },
  {
    field: "Latina/o Studies",
    discipline: "Humanities"
  },
  {
    field: "Linguistics",
    discipline: "Humanities"
  },
  {
    field: "Literature - General",
    discipline: "Humanities"
  },
  {
    field: "Literature - Comparative",
    discipline: "Humanities"
  },
  {
    field: "Literature - 18th-to-20th-Century British",
    discipline: "Humanities"
  },
  {
    field: "Literature - African-American",
    discipline: "Humanities"
  },
  {
    field: "Literature - American After 1865",
    discipline: "Humanities"
  },
  {
    field: "Literature - Colonial American",
    discipline: "Humanities"
  },
  {
    field: "Literature - and Gender",
    discipline: "Humanities"
  },
  {
    field: "Literature - Criticism and Theory",
    discipline: "Humanities"
  },
  {
    field: "Literature - Medieval/Renaissance",
    discipline: "Humanities"
  },
  {
    field: "Middle Eastern Studies",
    discipline: "Humanities"
  },
  {
    field: "Native American Studies",
    discipline: "Humanities"
  },
  {
    field: "Museum Studies",
    discipline: "Humanities"
  },
  {
    field: "Philosophy",
    discipline: "Humanities"
  },
  {
    field: "Religion and Theology",
    discipline: "Humanities"
  },
  {
    field: "Speech/Rhetoric",
    discipline: "Humanities"
  },
  {
    field: "Women's Studies",
    discipline: "Humanities"
  },
  {
    field: "Elementary Education",
    discipline: "K-12 Education"
  },
  {
    field: "Administration",
    discipline: "K-12 Education"
  },
  {
    field: "Computer Science / Technology",
    discipline: "K-12 Education"
  },
  {
    field: "ELL / ESOL / ESL",
    discipline: "K-12 Education"
  },
  {
    field: "English / Reading / Language Arts",
    discipline: "K-12 Education"
  },
  {
    field: "Art/Music/Theater",
    discipline: "K-12 Education"
  },
  {
    field: "Health/Behavioral Health",
    discipline: "K-12 Education"
  },
  {
    field: "Math",
    discipline: "K-12 Education"
  },
  {
    field: "Montessori/Preschool",
    discipline: "K-12 Education"
  },
  {
    field: "Physical Education",
    discipline: "K-12 Education"
  },
  {
    field: "Religion",
    discipline: "K-12 Education"
  },
  {
    field: "Science",
    discipline: "K-12 Education"
  },
  {
    field: "Social Studies/History",
    discipline: "K-12 Education"
  },
  {
    field: "Special Education",
    discipline: "K-12 Education"
  },
  {
    field: "Professional Staff",
    discipline: "K-12 Education"
  },
  {
    field: "World Languages",
    discipline: "K-12 Education"
  },
  {
    field: "Staff",
    discipline: "K-12 Education"
  },
  {
    field: "Vocational/Technical",
    discipline: "K-12 Education"
  },
  {
    field: "Other",
    discipline: "K-12 Education"
  },
  {
    field: "Speech / Communication",
    discipline: "Law"
  },
  {
    field: "Law - General",
    discipline: "Law"
  },
  {
    field: "Administrative Law",
    discipline: "Law"
  },
  {
    field: "Appellate Advocacy",
    discipline: "Law"
  },
  {
    field: "Arbitration/Mediation/Dispute Resolution",
    discipline: "Law"
  },
  {
    field: "Banking, Business & Financial Law",
    discipline: "Law"
  },
  {
    field: "Clinical Training",
    discipline: "Law"
  },
  {
    field: "Commercial & Bankruptcy Law",
    discipline: "Law"
  },
  {
    field: "Constitutional Law",
    discipline: "Law"
  },
  {
    field: "Consumer Law/Product Liability",
    discipline: "Law"
  },
  {
    field: "Contracts",
    discipline: "Law"
  },
  {
    field: "Copyright/Trademark/Intellectual Property/Patent",
    discipline: "Law"
  },
  {
    field: "Corporate Law",
    discipline: "Law"
  },
  {
    field: "Courts and Procedures",
    discipline: "Law"
  },
  {
    field: "Criminal Law",
    discipline: "Law"
  },
  {
    field: "Death Penalty",
    discipline: "Law"
  },
  {
    field: "Election/Voting Law",
    discipline: "Law"
  },
  {
    field: "Employment/Employee/Labor Law",
    discipline: "Law"
  },
  {
    field: "Environmental/Natural Resource Law",
    discipline: "Law"
  },
  {
    field: "Estate Planning/Wills & Trusts",
    discipline: "Law"
  },
  {
    field: "Family/Juvenile/Child Law",
    discipline: "Law"
  },
  {
    field: "Federal Courts & Jurisdiction",
    discipline: "Law"
  },
  {
    field: "Federal Indian Law",
    discipline: "Law"
  },
  {
    field: "Healthcare Law",
    discipline: "Law"
  },
  {
    field: "History of Law",
    discipline: "Law"
  },
  {
    field: "Human Rights",
    discipline: "Law"
  },
  {
    field: "Indigenous and Aboriginal Law",
    discipline: "Law"
  },
  {
    field: "International Law",
    discipline: "Law"
  },
  {
    field: "Jurisprudence",
    discipline: "Law"
  },
  {
    field: "Landlord/Tenant & Housing Law",
    discipline: "Law"
  },
  {
    field: "Law & Technology",
    discipline: "Law"
  },
  {
    field: "Law and Public Policy",
    discipline: "Law"
  },
  {
    field: "Legal Ethics",
    discipline: "Law"
  },
  {
    field: "Legal Research & Writing",
    discipline: "Law"
  },
  {
    field: "Litigation",
    discipline: "Law"
  },
  {
    field: "Military Law",
    discipline: "Law"
  },
  {
    field: "Privacy",
    discipline: "Law"
  },
  {
    field: "Real Property & Real Estate Finance",
    discipline: "Law"
  },
  {
    field: "Sports Law",
    discipline: "Law"
  },
  {
    field: "State & Local Government",
    discipline: "Law"
  },
  {
    field: "Tax Law",
    discipline: "Law"
  },
  {
    field: "Torts & Tort Reform",
    discipline: "Law"
  },
  {
    field: "Trial Advocacy",
    discipline: "Law"
  },
  {
    field: "Zoning/Land Use Planning",
    discipline: "Law"
  },
  {
    field: "Medicine - General",
    discipline: "Medicine"
  },
  {
    field: "Allergy/Rheumatology",
    discipline: "Medicine"
  },
  {
    field: "Anatomy",
    discipline: "Medicine"
  },
  {
    field: "Anesthesiology",
    discipline: "Medicine"
  },
  {
    field: "Biochemistry & Cellular Biology",
    discipline: "Medicine"
  },
  {
    field: "Bioethics",
    discipline: "Medicine"
  },
  {
    field: "Biomedical Engineering/Biomedical Sciences",
    discipline: "Medicine"
  },
  {
    field: "Cardiology/Cardiovascular Medicine",
    discipline: "Medicine"
  },
  {
    field: "Clinical Research",
    discipline: "Medicine"
  },
  {
    field: "Dermatology",
    discipline: "Medicine"
  },
  {
    field: "Diagnostic Radiology/Nuclear Medicine",
    discipline: "Medicine"
  },
  {
    field: "Emergency Medicine",
    discipline: "Medicine"
  },
  {
    field: "Endocrinology/Metabolism/Nutrition",
    discipline: "Medicine"
  },
  {
    field: "Family & Community Medicine",
    discipline: "Medicine"
  },
  {
    field: "Gastroenterology",
    discipline: "Medicine"
  },
  {
    field: "Genetics, Genomics & Molecular Biology",
    discipline: "Medicine"
  },
  {
    field: "Geriatrics",
    discipline: "Medicine"
  },
  {
    field: "Health Policy/Management/Administration",
    discipline: "Medicine"
  },
  {
    field: "History & Philosophy of Medicine",
    discipline: "Medicine"
  },
  {
    field: "Homeopathy",
    discipline: "Medicine"
  },
  {
    field: "Hospice/Palliative Care",
    discipline: "Medicine"
  },
  {
    field: "Immunology & Infectious Diseases",
    discipline: "Medicine"
  },
  {
    field: "Internal Medicine",
    discipline: "Medicine"
  },
  {
    field: "Medical Informatics & Clinical Epidemiology",
    discipline: "Medicine"
  },
  {
    field: "Microbiology & Virology",
    discipline: "Medicine"
  },
  {
    field: "Musculoskeletal Medicine",
    discipline: "Medicine"
  },
  {
    field: "Nephrology",
    discipline: "Medicine"
  },
  {
    field: "Neurobiology/Neuroscience",
    discipline: "Medicine"
  },
  {
    field: "Neurology",
    discipline: "Medicine"
  },
  {
    field: "Neurosurgery",
    discipline: "Medicine"
  },
  {
    field: "Obstetrics & Gynecology",
    discipline: "Medicine"
  },
  {
    field: "Oncology & Hematology",
    discipline: "Medicine"
  },
  {
    field: "Ophthalmology",
    discipline: "Medicine"
  },
  {
    field: "Orthopedics & Orthopedic Surgery",
    discipline: "Medicine"
  },
  {
    field: "Osteopathy",
    discipline: "Medicine"
  },
  {
    field: "Otolaryngology/Head & Neck Surgery",
    discipline: "Medicine"
  },
  {
    field: "Pathology & Laboratory Medicine",
    discipline: "Medicine"
  },
  {
    field: "Pediatrics - All Categories",
    discipline: "Medicine"
  },
  {
    field: "Pharmacology & Toxicology",
    discipline: "Medicine"
  },
  {
    field: "Physical Medicine & Rehabilitation",
    discipline: "Medicine"
  },
  {
    field: "Physician Assistant",
    discipline: "Medicine"
  },
  {
    field: "Physiology & Biophysics",
    discipline: "Medicine"
  },
  {
    field: "Plastic/Reconstructive Surgery",
    discipline: "Medicine"
  },
  {
    field: "Preventive Medicine",
    discipline: "Medicine"
  },
  {
    field: "Psychiatry/Psychology/Behavioral Science",
    discipline: "Medicine"
  },
  {
    field: "Public Health & Epidemiology",
    discipline: "Medicine"
  },
  {
    field: "Pulmonary & Critical Care Medicine",
    discipline: "Medicine"
  },
  {
    field: "Radiation Oncology",
    discipline: "Medicine"
  },
  {
    field: "Radiology & Radiological Sciences",
    discipline: "Medicine"
  },
  {
    field: "Sleep Medicine",
    discipline: "Medicine"
  },
  {
    field: "Surgery - General",
    discipline: "Medicine"
  },
  {
    field: "Transplant Surgery",
    discipline: "Medicine"
  },
  {
    field: "Urology",
    discipline: "Medicine"
  },
  {
    field: "Vascular Biology",
    discipline: "Medicine"
  },
  {
    field: "Vascular Surgery",
    discipline: "Medicine"
  },
  {
    field: "Pharmacy - General",
    discipline: "Pharmacy"
  },
  {
    field: "Administration",
    discipline: "Pharmacy"
  },
  {
    field: "Biomedical Sciences",
    discipline: "Pharmacy"
  },
  {
    field: "Biopharmaceutical Sciences",
    discipline: "Pharmacy"
  },
  {
    field: "Clinical Pharmacy",
    discipline: "Pharmacy"
  },
  {
    field: "Clinical Sciences",
    discipline: "Pharmacy"
  },
  {
    field: "Economics & Policy Studies",
    discipline: "Pharmacy"
  },
  {
    field: "Medicinal Chemistry",
    discipline: "Pharmacy"
  },
  {
    field: "Molecular Pharmacology",
    discipline: "Pharmacy"
  },
  {
    field: "Natural Products Chemistry",
    discipline: "Pharmacy"
  },
  {
    field: "Pharmaceutical Chemistry",
    discipline: "Pharmacy"
  },
  {
    field: "Pharmaceutical Sciences",
    discipline: "Pharmacy"
  },
  {
    field: "Pharmaceutics",
    discipline: "Pharmacy"
  },
  {
    field: "Pharmacokinetics",
    discipline: "Pharmacy"
  },
  {
    field: "Pharmacology",
    discipline: "Pharmacy"
  },
  {
    field: "Pharmacotherapy",
    discipline: "Pharmacy"
  },
  {
    field: "Pharmacy Practice",
    discipline: "Pharmacy"
  },
  {
    field: "Therapeutics",
    discipline: "Pharmacy"
  },
  {
    field: "Toxicology",
    discipline: "Pharmacy"
  },
  {
    field: "Sciences - General",
    discipline: "Sciences"
  },
  {
    field: "Anatomy/Physiology",
    discipline: "Sciences"
  },
  {
    field: "Astronomy and Astrophysics",
    discipline: "Sciences"
  },
  {
    field: "Atmospheric Sciences",
    discipline: "Sciences"
  },
  {
    field: "Biology - General",
    discipline: "Sciences"
  },
  {
    field: "Biology - Developmental/Evolutionary",
    discipline: "Sciences"
  },
  {
    field: "Biology - Genetics",
    discipline: "Sciences"
  },
  {
    field: "Biology - Biochemistry",
    discipline: "Sciences"
  },
  {
    field: "Biology - Biostatistics",
    discipline: "Sciences"
  },
  {
    field: "Biology - Cell Biology",
    discipline: "Sciences"
  },
  {
    field: "Biology - Computational",
    discipline: "Sciences"
  },
  {
    field: "Biology - Microbiology",
    discipline: "Sciences"
  },
  {
    field: "Biology - Molecular",
    discipline: "Sciences"
  },
  {
    field: "Biology - Neuroscience/Neurobiology",
    discipline: "Sciences"
  },
  {
    field: "Biology - Reproductive",
    discipline: "Sciences"
  },
  {
    field: "Biomedical Sciences",
    discipline: "Sciences"
  },
  {
    field: "Botany/Plant Sciences",
    discipline: "Sciences"
  },
  {
    field: "Chemistry - General",
    discipline: "Sciences"
  },
  {
    field: "Chemistry - Analytical",
    discipline: "Sciences"
  },
  {
    field: "Chemistry - Biochemistry",
    discipline: "Sciences"
  },
  {
    field: "Chemistry - Inorganic",
    discipline: "Sciences"
  },
  {
    field: "Chemistry - Medicinal",
    discipline: "Sciences"
  },
  {
    field: "Chemistry - Organic",
    discipline: "Sciences"
  },
  {
    field: "Chemistry - Physical",
    discipline: "Sciences"
  },
  {
    field: "Computer/Information Sciences",
    discipline: "Sciences"
  },
  {
    field: "Earth Sciences",
    discipline: "Sciences"
  },
  {
    field: "Environmental Sciences/Ecology/Forestry",
    discipline: "Sciences"
  },
  {
    field: "Fire Sciences",
    discipline: "Sciences"
  },
  {
    field: "Food Sciences/Technology/Toxicology",
    discipline: "Sciences"
  },
  {
    field: "Forensic Sciences",
    discipline: "Sciences"
  },
  {
    field: "Geodetic Sciences",
    discipline: "Sciences"
  },
  {
    field: "Geology/Geosciences - General",
    discipline: "Sciences"
  },
  {
    field: "Geology - Geochemistry",
    discipline: "Sciences"
  },
  {
    field: "Geology - Geophysics",
    discipline: "Sciences"
  },
  {
    field: "Geology - GIS",
    discipline: "Sciences"
  },
  {
    field: "Geology - Hydrogeology",
    discipline: "Sciences"
  },
  {
    field: "Geology - Paleontology",
    discipline: "Sciences"
  },
  {
    field: "Geology - Sedimentology/Stratigraphy",
    discipline: "Sciences"
  },
  {
    field: "Geology - Tectonics/Structure",
    discipline: "Sciences"
  },
  {
    field: "Geography",
    discipline: "Sciences"
  },
  {
    field: "Industrial Design/Technology",
    discipline: "Sciences"
  },
  {
    field: "Marine/Freshwater Sciences",
    discipline: "Sciences"
  },
  {
    field: "Materials Sciences/Polymer Sciences",
    discipline: "Sciences"
  },
  {
    field: "Mathematics/Applied Mathematics",
    discipline: "Sciences"
  },
  {
    field: "Natural Sciences",
    discipline: "Sciences"
  },
  {
    field: "Nanotechnology",
    discipline: "Sciences"
  },
  {
    field: "Occupational Health/Safety",
    discipline: "Sciences"
  },
  {
    field: "Physics - General",
    discipline: "Sciences"
  },
  {
    field: "Physics - Atomic/Molecular/Optical/Plasma",
    discipline: "Sciences"
  },
  {
    field: "Physics - Condensed Matter/Low Temperature",
    discipline: "Sciences"
  },
  {
    field: "Physics - Elementary Particles/Nuclear",
    discipline: "Sciences"
  },
  {
    field: "Planetary Sciences",
    discipline: "Sciences"
  },
  {
    field: "Statistics & Actuarial Science",
    discipline: "Sciences"
  },
  {
    field: "Textile Sciences",
    discipline: "Sciences"
  },
  {
    field: "University Administration",
    discipline: "Senior Administration"
  },
  {
    field: "Senior Administration",
    discipline: "Senior Administration"
  },
  {
    field: "Administration - General",
    discipline: "Senior Administration"
  },
  {
    field: "Administration - Academic Unit",
    discipline: "Senior Administration"
  },
  {
    field: "Academic Advising",
    discipline: "Senior Administration"
  },
  {
    field: "Academic Affairs",
    discipline: "Senior Administration"
  },
  {
    field: "Accounting",
    discipline: "Senior Administration"
  },
  {
    field: "Admissions/Financial Aid",
    discipline: "Senior Administration"
  },
  {
    field: "Alumni Relations",
    discipline: "Senior Administration"
  },
  {
    field: "Athletics",
    discipline: "Senior Administration"
  },
  {
    field: "Business & Administration",
    discipline: "Senior Administration"
  },
  {
    field: "Computing/Informational Services",
    discipline: "Senior Administration"
  },
  {
    field: "Continuing Education/Distance Learning",
    discipline: "Senior Administration"
  },
  {
    field: "Development/Institutional Advancement",
    discipline: "Senior Administration"
  },
  {
    field: "Enrollment Management/Registrar",
    discipline: "Senior Administration"
  },
  {
    field: "Facilities Operations",
    discipline: "Senior Administration"
  },
  {
    field: "Financial Planning/Budget Management",
    discipline: "Senior Administration"
  },
  {
    field: "Governmental Relations",
    discipline: "Senior Administration"
  },
  {
    field: "Graduate Education",
    discipline: "Senior Administration"
  },
  {
    field: "Health Services",
    discipline: "Senior Administration"
  },
  {
    field: "Human Resources",
    discipline: "Senior Administration"
  },
  {
    field: "International Programs/Study Abroad",
    discipline: "Senior Administration"
  },
  {
    field: "Legal",
    discipline: "Senior Administration"
  },
  {
    field: "Library Services",
    discipline: "Senior Administration"
  },
  {
    field: "Multicultural Affairs/Diversity",
    discipline: "Senior Administration"
  },
  {
    field: "Public Relations/Marketing",
    discipline: "Senior Administration"
  },
  {
    field: "Purchasing",
    discipline: "Senior Administration"
  },
  {
    field: "Research",
    discipline: "Senior Administration"
  },
  {
    field: "Residential Life",
    discipline: "Senior Administration"
  },
  {
    field: "Student Affairs",
    discipline: "Senior Administration"
  },
  {
    field: "Teaching & Learning",
    discipline: "Senior Administration"
  },
  {
    field: "Undergraduate Education",
    discipline: "Senior Administration"
  },
  {
    field: "Other Administrative Departments",
    discipline: "Senior Administration"
  },
  {
    field: "Social Sciences - General",
    discipline: "Social Sciences"
  },
  {
    field: "Anthropology",
    discipline: "Social Sciences"
  },
  {
    field: "Archaeology",
    discipline: "Social Sciences"
  },
  {
    field: "Cognitive science",
    discipline: "Social Sciences"
  },
  {
    field: "Communications/Journalism (all)",
    discipline: "Social Sciences"
  },
  {
    field: "Criminology/Criminal Justice",
    discipline: "Social Sciences"
  },
  {
    field: "Economics - General",
    discipline: "Social Sciences"
  },
  {
    field: "Economics - Industrial Organization",
    discipline: "Social Sciences"
  },
  {
    field: "Economics - International",
    discipline: "Social Sciences"
  },
  {
    field: "Economics - Macroeconomics",
    discipline: "Social Sciences"
  },
  {
    field: "Economics - Microeconomics",
    discipline: "Social Sciences"
  },
  {
    field: "Economics - Public Finance",
    discipline: "Social Sciences"
  },
  {
    field: "Economics - Resources",
    discipline: "Social Sciences"
  },
  {
    field: "Environmental Policy",
    discipline: "Social Sciences"
  },
  {
    field: "Family Studies/Child Development",
    discipline: "Social Sciences"
  },
  {
    field: "Gender Studies",
    discipline: "Social Sciences"
  },
  {
    field: "Geography",
    discipline: "Social Sciences"
  },
  {
    field: "Gerontology",
    discipline: "Social Sciences"
  },
  {
    field: "Government - National Security",
    discipline: "Social Sciences"
  },
  {
    field: "Government - Policy/Public Affairs",
    discipline: "Social Sciences"
  },
  {
    field: "Home Economics/Consumer Sciences",
    discipline: "Social Sciences"
  },
  {
    field: "Human Development",
    discipline: "Social Sciences"
  },
  {
    field: "Human Ecology",
    discipline: "Social Sciences"
  },
  {
    field: "Interdisciplinary Studies",
    discipline: "Social Sciences"
  },
  {
    field: "International Relations/Foreign Service",
    discipline: "Social Sciences"
  },
  {
    field: "Labor/Industrial Relations",
    discipline: "Social Sciences"
  },
  {
    field: "Library Sciences (all categories)",
    discipline: "Social Sciences"
  },
  {
    field: "Political Science - General",
    discipline: "Social Sciences"
  },
  {
    field: "Political Science - American",
    discipline: "Social Sciences"
  },
  {
    field: "Political Science - Comparative",
    discipline: "Social Sciences"
  },
  {
    field: "Political Science - International",
    discipline: "Social Sciences"
  },
  {
    field: "Political Science - Theory",
    discipline: "Social Sciences"
  },
  {
    field: "Psychology - General",
    discipline: "Social Sciences"
  },
  {
    field: "Psychology - Behavior Analysis",
    discipline: "Social Sciences"
  },
  {
    field: "Psychology - Clinical",
    discipline: "Social Sciences"
  },
  {
    field: "Psychology - Cognitive",
    discipline: "Social Sciences"
  },
  {
    field: "Psychology - Developmental",
    discipline: "Social Sciences"
  },
  {
    field: "Psychology - Educational",
    discipline: "Social Sciences"
  },
  {
    field: "Psychology - Experimental",
    discipline: "Social Sciences"
  },
  {
    field: "Psychology - Industrial/Organizational",
    discipline: "Social Sciences"
  },
  {
    field: "Psychology - Neuropsychology",
    discipline: "Social Sciences"
  },
  {
    field: "Psychology - School",
    discipline: "Social Sciences"
  },
  {
    field: "Psychology - Social",
    discipline: "Social Sciences"
  },
  {
    field: "Public Policy & Administration",
    discipline: "Social Sciences"
  },
  {
    field: "Recreation/Leisure studies",
    discipline: "Social Sciences"
  },
  {
    field: "Research Methods/Data Analysis",
    discipline: "Social Sciences"
  },
  {
    field: "Social Work",
    discipline: "Social Sciences"
  },
  {
    field: "Sociology - General",
    discipline: "Social Sciences"
  },
  {
    field: "Sociology - Crime, Justice & Law",
    discipline: "Social Sciences"
  },
  {
    field: "Sociology - Economic",
    discipline: "Social Sciences"
  },
  {
    field: "Sociology - Historical",
    discipline: "Social Sciences"
  },
  {
    field: "Sociology - Humanities",
    discipline: "Social Sciences"
  },
  {
    field: "Sociology - Social Psychology",
    discipline: "Social Sciences"
  },
  {
    field: "Sociology - Social Stratification",
    discipline: "Social Sciences"
  },
  {
    field: "Sociology - of Culture",
    discipline: "Social Sciences"
  },
  {
    field: "Urban Studies and Planning",
    discipline: "Social Sciences"
  },
  {
    field: "Women's Studies",
    discipline: "Social Sciences"
  },
  {
    field: "Administrative Support/Services",
    discipline: "Staff & Non-Managerial Professionals"
  },
  {
    field: "Admissions/Student Records/Registrar",
    discipline: "Staff & Non-Managerial Professionals"
  },
  {
    field: "Agriculture/Animal Care",
    discipline: "Staff & Non-Managerial Professionals"
  },
  {
    field: "Alumni Relations",
    discipline: "Staff & Non-Managerial Professionals"
  },
  {
    field: "Arts/Museum/Theater",
    discipline: "Staff & Non-Managerial Professionals"
  },
  {
    field: "Athletics and Recreation Services",
    discipline: "Staff & Non-Managerial Professionals"
  },
  {
    field: "Child and Social Services",
    discipline: "Staff & Non-Managerial Professionals"
  },
  {
    field: "Communications/Public Relations",
    discipline: "Staff & Non-Managerial Professionals"
  },
  {
    field: "Counseling Services",
    discipline: "Staff & Non-Managerial Professionals"
  },
  {
    field: "Curatorial/Archival",
    discipline: "Staff & Non-Managerial Professionals"
  },
  {
    field: "Graphic Design/Marketing",
    discipline: "Staff & Non-Managerial Professionals"
  },
  {
    field: "Dining Services",
    discipline: "Staff & Non-Managerial Professionals"
  },
  {
    field: "Educational Services",
    discipline: "Staff & Non-Managerial Professionals"
  },
  {
    field: "Facilities/Maintenance/Transportation",
    discipline: "Staff & Non-Managerial Professionals"
  },
  {
    field: "Finance/Investment Management",
    discipline: "Staff & Non-Managerial Professionals"
  },
  {
    field: "Financial Aid",
    discipline: "Staff & Non-Managerial Professionals"
  },
  {
    field: "Fiscal Services",
    discipline: "Staff & Non-Managerial Professionals"
  },
  {
    field: "Grant Writer/Technical Writer",
    discipline: "Staff & Non-Managerial Professionals"
  },
  {
    field: "Health Services",
    discipline: "Staff & Non-Managerial Professionals"
  },
  {
    field: "Human Resources",
    discipline: "Staff & Non-Managerial Professionals"
  },
  {
    field: "Information Technology",
    discipline: "Staff & Non-Managerial Professionals"
  },
  {
    field: "Institutional Advancement",
    discipline: "Staff & Non-Managerial Professionals"
  },
  {
    field: "Legal Services",
    discipline: "Staff & Non-Managerial Professionals"
  },
  {
    field: "Library",
    discipline: "Staff & Non-Managerial Professionals"
  },
  {
    field: "Multicultural Affairs/Diversity",
    discipline: "Staff & Non-Managerial Professionals"
  },
  {
    field: "Public Safety",
    discipline: "Staff & Non-Managerial Professionals"
  },
  {
    field: "Research/Technical/Laboratory",
    discipline: "Staff & Non-Managerial Professionals"
  },
  {
    field: "Residential Life",
    discipline: "Staff & Non-Managerial Professionals"
  },
  {
    field: "Student Services",
    discipline: "Staff & Non-Managerial Professionals"
  },
  {
    field: "Veterinary Medicine - General",
    discipline: "Veterinary Medicine"
  },
  {
    field: "Anatomy, Physiology, & Cell Biology",
    discipline: "Veterinary Medicine"
  },
  {
    field: "Anesthesiology",
    discipline: "Veterinary Medicine"
  },
  {
    field: "Animal Genomics",
    discipline: "Veterinary Medicine"
  },
  {
    field: "Animal Sciences",
    discipline: "Veterinary Medicine"
  },
  {
    field: "Aquatic Animal Health",
    discipline: "Veterinary Medicine"
  },
  {
    field: "Avian Medicine",
    discipline: "Veterinary Medicine"
  },
  {
    field: "Biomedical Sciences",
    discipline: "Veterinary Medicine"
  },
  {
    field: "Biostatistics",
    discipline: "Veterinary Medicine"
  },
  {
    field: "Bovine Medicine",
    discipline: "Veterinary Medicine"
  },
  {
    field: "Cancer Biology & Oncology",
    discipline: "Veterinary Medicine"
  },
  {
    field: "Cardiology & Cardiovascular Science",
    discipline: "Veterinary Medicine"
  },
  {
    field: "Clinical/Medical Sciences",
    discipline: "Veterinary Medicine"
  },
  {
    field: "Dentistry",
    discipline: "Veterinary Medicine"
  },
  {
    field: "Dermatology/Allergy",
    discipline: "Veterinary Medicine"
  },
  {
    field: "Emergency Medical Care",
    discipline: "Veterinary Medicine"
  },
  {
    field: "Equine Medicine/Surgery",
    discipline: "Veterinary Medicine"
  },
  {
    field: "Exotic Animal Medicine",
    discipline: "Veterinary Medicine"
  },
  {
    field: "Field Service",
    discipline: "Veterinary Medicine"
  },
  {
    field: "Immunology",
    discipline: "Veterinary Medicine"
  },
  {
    field: "Large Animal Medicine",
    discipline: "Veterinary Medicine"
  },
  {
    field: "Medicine & Epidemiology",
    discipline: "Veterinary Medicine"
  },
  {
    field: "Molecular Genetics",
    discipline: "Veterinary Medicine"
  },
  {
    field: "Molecular Biosciences/Microbiology",
    discipline: "Veterinary Medicine"
  },
  {
    field: "Nutrition & Animal Health",
    discipline: "Veterinary Medicine"
  },
  {
    field: "Neurosciences (all categories)",
    discipline: "Veterinary Medicine"
  },
  {
    field: "Ophthalmology",
    discipline: "Veterinary Medicine"
  },
  {
    field: "Ovine Medicine",
    discipline: "Veterinary Medicine"
  },
  {
    field: "Parasitology",
    discipline: "Veterinary Medicine"
  },
  {
    field: "Pathology/Pathobiology",
    discipline: "Veterinary Medicine"
  },
  {
    field: "Pharmacology",
    discipline: "Veterinary Medicine"
  },
  {
    field: "Porcine Medicine",
    discipline: "Veterinary Medicine"
  },
  {
    field: "Radiological Sciences",
    discipline: "Veterinary Medicine"
  },
  {
    field: "Reproductive Biology/Endocrinology",
    discipline: "Veterinary Medicine"
  },
  {
    field: "Small/Lab Animal Medicine",
    discipline: "Veterinary Medicine"
  },
  {
    field: "Surgery/Surgical Sciences",
    discipline: "Veterinary Medicine"
  },
  {
    field: "Theriogenology",
    discipline: "Veterinary Medicine"
  },
  {
    field: "Toxicology",
    discipline: "Veterinary Medicine"
  },
  {
    field: "Vaccines",
    discipline: "Veterinary Medicine"
  },
  {
    field: "Veterinary Behavior",
    discipline: "Veterinary Medicine"
  },
  {
    field: "Virology",
    discipline: "Veterinary Medicine"
  },
  {
    field: "Sandbox",
    discipline: "Alethea"
  }
];

export default academicFields;
