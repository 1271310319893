import React from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";

import Chart from "../Chart";
import StackedBars from "../chartPrimitives/StackedBars";
import Axis from "../chartPrimitives/Axis";
import { accessorPropsType } from "../utils";
import { useChartDimensions } from "../../../../hooks";
import { useTheme } from "@mui/material";

function SingleHorizontalStackedBar({
  data = [],
  yAccessor,
  zDomain,
  colors,
  formatTick,
  allowExpand,
  hideY = true,
  hideX = true,
  barHeight = 24,
  hideAxisLines,
  label
}) {
  const theme = useTheme();
  const { ref, dimensions } = useChartDimensions({
    height: barHeight,
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0
  });
  // TODO: set a default to colors
  // TODO: we can assume that the z domain is all the passed in numerical values - the x
  const stack = d3.stack().keys(zDomain);

  const stackedData = stack(data);
  const xMax = d3.max(stackedData.flat(2));

  const yScale = d3
    .scaleBand()
    .domain(data.map(yAccessor))
    .range([dimensions.boundedHeight, 0])
    .rangeRound([0, data.length * barHeight]);

  const xScale = d3
    .scaleLinear()
    .domain([0, xMax])
    .range([0, dimensions.boundedWidth]);

  const zScale = d3.scaleOrdinal().domain(zDomain).range(colors);

  const keyAccessor = (d, i) => i;
  const yAccessorScaled = (d) => yScale(d.data.id);
  const xAccessorScaled = (d) => Math.min(xScale(d[0]), xScale(d[1]));
  const zAccessorScaled = (d) => zScale(d.key);
  const widthAccessorScaled = (d) => Math.abs(xScale(d[0]) - xScale(d[1]));
  const heightAccessorScaled = (d) => barHeight || yScale.bandwidth();

  return (
    <Chart ref={ref} dimensions={dimensions}>
      {!hideX && (
        <Axis
          dimensions={dimensions}
          dimension="x"
          scale={xScale}
          hideAxisLines={hideAxisLines}
          hideX={hideX}
        />
      )}
      {!hideY && (
        <Axis
          dimension="y"
          ticks={data.map(yAccessor)}
          formatTick={formatTick}
          scale={yScale}
          hideAxisLines={hideAxisLines}
          hideY={hideY}
        />
      )}

      {data.length ? (
        <StackedBars
          data={stackedData}
          keyAccessor={keyAccessor}
          xAccessor={xAccessorScaled}
          yAccessor={yAccessorScaled}
          zAccessor={zAccessorScaled}
          widthAccessor={widthAccessorScaled}
          heightAccessor={heightAccessorScaled}
        />
      ) : (
        <rect
          // {...props}
          // className={classes.bars}
          fill={theme.palette.grey[300]}
          x={0}
          y={0}
          width={"100%"}
          height={barHeight || yScale.bandwidth()}
        />
      )}
    </Chart>
  );
}

SingleHorizontalStackedBar.propTypes = {
  xAccessor: accessorPropsType,
  yAccessor: accessorPropsType,
  xLabel: PropTypes.string,
  yLabel: PropTypes.string
};

SingleHorizontalStackedBar.defaultProps = {
  xAccessor: (d) => d.x,
  yAccessor: (d) => d.y,
  hideAxisLines: false,
  allowExpand: false
};
export default SingleHorizontalStackedBar;
