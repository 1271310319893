// Dependencies
import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Typography, TextField } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    fontWeight: "bold",
    fontSize: theme.typography.caption.fontSize
  },
  assistantInstructions: {
    minHeight: "80px",
    fontSize: theme.typography.body1.fontSize,
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        transition: "border-color 0.2s" // Optional: Add a transition for a smoother effect
      },
      "&:hover fieldset": {
        borderColor: "initial" // Reset border color on hover
      },
      "&.Mui-focused fieldset": {
        border: `2px solid ${theme.palette.greenText.light}`
      }
    }
  }
}));

const AssistantInstructions = ({
  assistantInstructions,
  handleInstructionsChange,
  label
}) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="p" className={classes.sectionTitle}>
        {label}
      </Typography>
      <TextField
        className={classes.assistantInstructions}
        value={assistantInstructions}
        fullWidth
        multiline
        placeholder="Please selet a course, task & user and click on fetch prompt to generate a prompt"
        variant="outlined"
        minRows={10}
        maxRows={10}
        onChange={handleInstructionsChange}
        InputProps={{
          style: { fontSize: "14px", padding: "5px", margin: "0" }
        }}
      />
    </>
  );
};

export default AssistantInstructions;
