// Dependencies
import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import clsx from "clsx";
import { ScrollBox } from "./components/SharedComponents";
import { he, enUS } from "date-fns/locale";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { setBreadcrumbs } from "./redux/readerActionsSlice";
// Components
import {
  generateNotificationContent,
  navigateToCommentNotification
} from "./utils/comments-utils";

import makeStyles from "@mui/styles/makeStyles";

import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Pagination,
  Link
} from "@mui/material";
import { formatDistanceStrict } from "date-fns";
import { selectIsComments, selectLocale } from "./redux/firestoreSelectors";
import DirectionalArrowIcon from "./components/SharedComponents/DirectionalArrowIcon";
import { selectIsSingleThread } from "./redux/realtimeInteractionsSlice";
import { collection, doc, onSnapshot, updateDoc } from "firebase/firestore";
import { useFirestore } from "reactfire";
import { notificationsAPI } from "./api";

const PAGE_COUNT = 10;

// Styles
const useStyles = makeStyles((theme) => ({
  container: {
    paddingInline: "25vw"
  },
  header: {
    position: "relative"
  },
  navigateBack: {
    top: "15px",
    left: "-77px",
    position: "absolute"
  },
  heading: {
    margin: "24px 0"
  },
  notification: {
    cursor: "pointer"
  },
  notificationContent: {
    display: "flex",
    flexDirection: "column"
  },
  isRead: {
    color: theme.palette.text.secondary
  },
  courseName: {
    fontWeight: "bold"
  },
  time: {
    color: theme.palette.text.secondary
  },
  pagination: {
    margin: "16px 0",
    display: "flex",
    justifyContent: "flex-end"
  }
}));

export default function Notifications({ ...props }) {
  // Hooks
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();
  const firestore = useFirestore();

  // Redux state
  const userId = useSelector((state) => state.user.auth.uid);
  const userRole = useSelector(
    (state) => state.user.userProfile.selectedCourse.course_role
  );
  const locale = useSelector((state) => selectLocale(state));

  const [firebaseNotifications, setFirebaseNotifications] = useState([]);
  const [sortedNotifications, setSortedNotifications] = useState([]);
  const [page, setPage] = useState(1);

  const isSingleThread = useSelector((state) => selectIsSingleThread(state)); // display in panel a single selected thread
  const isComments = useSelector((state) => selectIsComments(state)); // display in panel several selected threads
  const notificationsRef = collection(
    firestore,
    "notifications",
    `${userId}/userNotifications`
  );
  // Ephemeral state

  // Variables
  const localeToDateFnsOnj = {
    he: he,
    en: enUS
  };
  //Behavior

  const fetchData = useCallback(() => {
    const unsubscribe = onSnapshot(notificationsRef, (querySnapshot) => {
      let notifications = [];
      querySnapshot.forEach((doc) => {
        notifications.push({ id: doc.id, ...doc.data() });
      });
      setFirebaseNotifications(notifications);
    });

    return unsubscribe; // This function will be called when the component unmounts
  }, [notificationsRef]);

  useEffect(() => {
    const unsubscribe = fetchData();

    return () => {
      // Unsubscribe when the component unmounts
      unsubscribe();
    };
  }, []);

  //Set breadcrumbs
  useEffect(() => {
    let parts = [];
    parts.push({
      text: intl.formatMessage({
        id: "appBar.notifications",
        defaultMessage: "Notifications"
      })
    });

    dispatch(setBreadcrumbs({ breadcrumbs: parts }));
  }, []);

  // Sorting the notifications based on time created from newest to oldest
  useEffect(() => {
    if (firebaseNotifications) {
      setSortedNotifications(
        [...firebaseNotifications]
          // .filter(notification => notification.course_id === course_id) <-- Optional filtering by course id, won't take into account older generated notifications
          .sort((a, b) => {
            return (
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            );
          })
          .filter(
            (notification, i) =>
              i > (page - 1) * PAGE_COUNT - 1 && i < page * PAGE_COUNT
          )
      );
    }
  }, [firebaseNotifications, page]);

  const markAsRead = async (notificationId) => {
    const notificationRef = doc(
      firestore,
      `notifications/${userId}/userNotifications`,
      notificationId
    );
    // Use updateDoc to update the 'read' field to true
    await updateDoc(notificationRef, {
      isRead: true,
      includeInCounter: false
    }).catch((error) => {
      console.error("Error marking notification as read:", error);
    });
  };

  async function navigateToRoute(notification) {
    await navigateToCommentNotification(
      notification,
      isComments,
      isSingleThread
    );
    history.push(notification.link);
  }

  // Render
  return (
    <ScrollBox className={classes.container}>
      <Box component="header" className={classes.header}>
        <IconButton
          size="large"
          className={classes.navigateBack}
          onClick={() => {
            history.goBack();
          }}
          aria-label="back to previous page">
          <DirectionalArrowIcon fontSize="large" />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}>
        <Typography variant="h4" component="h1" className={classes.heading}>
          {intl.formatMessage({
            id: "notifications.title",
            defaultMessage: "Notifications"
          })}
        </Typography>
        <Link
          variant="body1"
          color="secondary"
          component="button"
          href="#"
          underline="none"
          onClick={async (e) => {
            await notificationsAPI.markAllAsRead();
          }}>
          {intl.formatMessage({
            id: "notifications.markAllAsRead",
            defaultMessage: "Mark all as read"
          })}
        </Link>
      </Box>
      <TableContainer>
        <Table>
          <TableBody>
            {sortedNotifications?.map((notification) => {
              return (
                <TableRow
                  key={notification.id}
                  className={classes.notification}
                  onClick={async () => {
                    await notificationsAPI.markAsRead(notification.id);
                    await navigateToRoute(notification);
                  }}>
                  <TableCell align="left">
                    <Box
                      className={clsx(
                        classes.notificationContent,
                        notification.isRead && classes.isRead
                      )}>
                      <Typography
                        component="span"
                        variant="subtitle2"
                        className={classes.courseName}>
                        {notification.course}
                      </Typography>

                      <Typography
                        component="span"
                        variant="subtitle2"
                        className={classes.notification}>
                        {generateNotificationContent(
                          notification,
                          intl,
                          userRole
                        )}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      component="span"
                      variant="subtitle2"
                      className={classes.time}>
                      {formatDistanceStrict(
                        new Date(notification.createdAt),
                        new Date(),
                        {
                          addSuffix: true,
                          locale: localeToDateFnsOnj[locale]
                        }
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        className={classes.pagination}
        count={Math.round(firebaseNotifications.length / PAGE_COUNT)}
        page={page}
        onChange={(event, page) => setPage(page)}
      />
    </ScrollBox>
  );
}
