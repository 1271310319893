import { Chip } from "@mui/material";
import { useIntl } from "react-intl";
import React from "react";

const ClassChip = ({ rating }) => {
  const intl = useIntl();

  const RATINGS = [
    {
      label: intl.formatMessage({
        id: "task.multi.low",
        defaultMessage: "Low"
      }),
      color: "error.dark"
    },
    {
      label: intl.formatMessage({
        id: "task.multi.low",
        defaultMessage: "Medium"
      }),
      color: "warning.dark"
    },
    {
      label: intl.formatMessage({
        id: "task.multi.low",
        defaultMessage: "High"
      }),
      color: "secondary.dark"
    }
  ];

  return (
    <Chip
      variant="outlined"
      label={RATINGS[rating - 1].label}
      sx={{
        color: RATINGS[rating - 1].color,
        borderColor: RATINGS[rating - 1].color,
        height: "24px"
      }}
    />
  );
};

export default ClassChip;
