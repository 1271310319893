import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { TooltipWithIntl } from "../SharedComponents";

export const truncateMiddle = (text, maxLength) => {
  if (!text) return;
  if (text.length <= maxLength) {
    return text;
  }
  const ellipsis = "...";
  const snippetLength = Math.floor((maxLength - ellipsis.length) / 2);
  const snippetStart = text.slice(0, snippetLength);
  const snippetEnd = text.slice(-snippetLength);
  return (
    <TooltipWithIntl
      intlStringId={text}
      defaultMessage={text}
      placement={"bottom"}>
      {snippetStart}
      {ellipsis}
      {snippetEnd}
    </TooltipWithIntl>
  );
};

export function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        {...props}
        aria-label="text upload progress"
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}>
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}
