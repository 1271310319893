import React from "react";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";
import TooltipWithIntl from "../SharedComponents/tooltip/TooltipWithIntl";

const useStyles = makeStyles((theme) => ({
  annotateBar: {},
  btn: { display: "inline" },
  disabledBtn: {
    cursor: "not-allowed",
    marginInlineEnd: theme.spacing(2),
    color: "#aaaaaa"
  },
  highlightBtn: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    marginInline: theme.spacing(1),
    "&:hover": {
      color: "#BEF3BF"
    },
    "&:focus": {
      outline: "2px solid #BEF3BF" // Ensure focus styles are visible
    }
  }
}));

const ReaderAction = React.forwardRef(
  (
    {
      Icon,
      selected,
      style,
      onMouseEnter,
      onMouseLeave,
      enabled = true,
      minimal = false,
      intlStringId,
      defaultMessage,
      placement,
      handleClick
    },
    ref
  ) => {
    const classes = useStyles();

    const handleKeyDown = (event) => {
      if (event.key === "Enter" || event.key === " ") {
        event.preventDefault();
        handleClick();
      }
    };

    return (
      <Box
        ref={ref}
        role="button"
        tabIndex={enabled ? 0 : -1} // Make it focusable if enabled
        aria-disabled={!enabled}
        className={clsx(
          classes.btn,
          enabled && classes.highlightBtn,
          !enabled && classes.disabledBtn,
          minimal && classes.minimal
        )}
        onClick={enabled ? handleClick : undefined}
        onKeyDown={enabled ? handleKeyDown : undefined}
        style={{
          backgroundColor: selected ? "rgba(255,255,255, 0.15)" : "transparent",
          borderRadius: 3,
          ...style
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}>
        <TooltipWithIntl
          intlStringId={intlStringId}
          defaultMessage={defaultMessage}
          placement={placement}>
          <Icon fontSize={"medium"} />
        </TooltipWithIntl>
      </Box>
    );
  }
);

ReaderAction.displayName = "ReaderAction";

export default ReaderAction;
