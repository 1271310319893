// Dependencies
import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

// Redux dependencies
import { updateInteraction } from "../../../redux/interactionsSlice";

//Components
import InlineEditTextarea from "../InlineEditTextarea";

// Material UI

import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  title: { marginBlockEnd: theme.spacing(3) }
}));

const InteractionCardEditableHeader = React.forwardRef((props, ref) => {
  const {
    // Props that are passed implicitly from the parent Card component
    interaction,

    // Other props
    maxRows = 10
  } = props;

  // Hooks
  const classes = useStyles();
  const dispatch = useDispatch();

  // Ephemeral state

  // Derived state

  function updateTitle(interaction, value) {
    // updates the title attribute in the interaction's metadata
    dispatch(
      updateInteraction({
        interaction: interaction,
        update: { title: value }
      })
    );
  }
  return (
    <Box className={clsx(interaction?.title && classes.title)}>
      <InlineEditTextarea
        ref={ref}
        text={interaction?.title}
        onChange={(value) => updateTitle(interaction, value)}
        maxRows={maxRows}
        disablePadding
      />
    </Box>
  );
});

InteractionCardEditableHeader.propTypes = {
  interaction: PropTypes.object, // interaction is passed implicitly from the parent
  maxRows: PropTypes.number
};
InteractionCardEditableHeader.displayName = "InteractionCardEditableHeader";

export default InteractionCardEditableHeader;
