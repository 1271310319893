import React from "react";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";

import { Chip } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  chip: {
    marginTop: "8px",
    marginBottom: "8px",
    marginLeft: "8px",
    marginRight: "8px",
    "&:first-child": {
      marginLeft: "16px"
    }
  }
}));
export default function ConceptChip(props) {
  const { concept, found } = props;
  const classes = useStyles();

  //Concepts score stuff

  return (
    <Chip
      variant="outlined"
      sx={{
        color: found ? "secondary.dark" : "error.dark",
        borderColor: found ? "secondary.dark" : "error.dark"
      }}
      label={concept}
      className={classes.chip}
    />
  );
}
