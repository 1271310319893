import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectDarkMode, selectLocale } from "../redux/firestoreSelectors";
import {
  getGlobalTheme,
  getGlobalDarkTheme,
  getHebrewTheme,
  getHebrewDarkTheme
} from "../styles/theme";

export default function useGetTheme({ alwase } = {}) {
  const locale = useSelector((state) => selectLocale(state));
  const darkMode = useSelector((state) => selectDarkMode(state));
  const [theme, setTheme] = useState(getGlobalTheme());

  useEffect(() => {
    switch (alwase) {
      case "light": {
        if (locale === "en") {
          setTheme(getGlobalTheme());
        } else {
          setTheme(getHebrewTheme());
        }
        break;
      }
      case "dark": {
        if (locale === "en") {
          setTheme(getGlobalDarkTheme());
        } else {
          setTheme(getHebrewDarkTheme());
        }
        break;
      }
      default: {
        if (locale === "en" && !darkMode) {
          setTheme(getGlobalTheme());
        } else if (locale === "en" && darkMode) {
          setTheme(getGlobalDarkTheme());
        } else if (locale === "he" && !darkMode) {
          setTheme(getHebrewTheme());
        } else if (locale === "he" && darkMode) {
          setTheme(getHebrewDarkTheme());
        } else {
          setTheme(getGlobalTheme());
        }
      }
    }
  }, [locale, darkMode]);

  return theme;
}
