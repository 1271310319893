// Dependencies
import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import clsx from "clsx";
import { normalize } from "./utils";
import { USER_TYPE } from "../../../consts";
// Components
import CitationChart from "./CitationChart";
import HeatMap from "./HeatMap";
import {
  selectQuestionHighlights,
  selectQuestionAnswers
} from "../../../redux/interactionsSlice";
import { useSelector } from "react-redux";
import { selectCurrentText } from "../../../redux/textsSlice";
import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Button,
  Typography,
  Paper,
  Divider,
  LinearProgress,
  Chip,
  Card
} from "@mui/material";

// Styles
const useStyles = makeStyles((theme) => {
  return {
    statContainer: {
      padding: "32px"
    },
    generalStatsHeader: {
      padding: "24px 0",
      fontWeight: "bold"
    },
    questionTitle: {
      fontWeight: theme.typography.fontWeightBold
    },
    multiChoiceContainer: {
      display: "flex",
      flexFlow: "row nowrap"
    },
    citationContainer: {
      flexBasis: "50%",
      padding: "32px",
      borderRight: "1px solid rgba(0, 0, 0, 0.12)"
    },
    mainStatsContainer: {
      display: "flex",
      flexBasis: "100%",
      flexFlow: "column nowrap",
      justifyContent: "space-between",
      "&.withCitations": {
        flexBasis: "50%"
      }
    },
    answerDistributionContainer: {
      flexBasis: "100%",
      display: "grid",
      gridTemplateColumns: "0.1fr 0.7fr",
      gap: "36px 24px",
      gridAutoFlow: "row",
      padding: "32px",
      "&.withCitations": {
        flexBasis: "50%",
        gridTemplateColumns: "0.15fr 1fr",
        gridTemplateRows: "max-content"
      }
    },

    chipContainer: {
      gridColumn: "1 / 2",
      position: "relative"
    },
    chip: {
      position: "absolute",
      top: "5px"
    },
    optionContainer: {
      gridColumn: "2 / 3"
    },
    optionText: {
      display: "flex",
      flexFlow: "row nowrap",
      justifyContent: "space-between",
      fontSize: "20px"
    },
    optionPresantage: {
      fontFamily: `Helvetica, "sans-serif"`
    },
    statBar: {
      height: "8px",
      backgroundColor: "#D8D8D8",
      "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: "#168FEE"
      }
    },
    citationsActions: {
      textAlign: "end",
      margin: "0 34px 34px 34px"
    }
  };
});

export default function MultiChoiceStats({
  submissions,
  question,
  index,
  task,
  isTeacher = true
}) {
  // Hooks
  const classes = useStyles();
  const intl = useIntl();

  // Redux State
  const questionHighlights = useSelector(
    (state) => selectQuestionHighlights(state, question.id),
    (a, b) => JSON.stringify(a.length) === JSON.stringify(b.length)
  );
  const questionAnswers = useSelector((state) =>
    selectQuestionAnswers(state, question.id)
  );
  const text = useSelector(selectCurrentText);

  // Ephemeral State
  const [openHeatmap, setOpenHeatmap] = useState(false);

  // Derived state
  const withCitations = question.includeCitation;
  const teacherHighlights = question.quotes || [];
  const studentHighlights = questionHighlights.filter(
    (highlight) => highlight.user_type === USER_TYPE.STUDENT
  );
  const questionOptions = question.options;

  const submittedAnswers = questionAnswers
    .filter((answer) => answer.user_type === USER_TYPE.STUDENT)
    .filter((answer) => answer.interaction_subtype === "SELECTION");

  let choiceDistribution = new Map();

  questionOptions.forEach((option, optionIndex) => {
    // count the occurrences in the student choices array
    const occurrences = submittedAnswers.reduce((accumulator, currentValue) => {
      return Number(currentValue.choice) === optionIndex
        ? accumulator + 1
        : accumulator;
    }, 0);
    choiceDistribution.set(optionIndex, occurrences);
  });

  // Behavior
  if (isTeacher)
    return (
      <Paper component="section" elevation={0}>
        <HeatMap
          totalCount={
            submissions.filter((submission) => submission.submission_date)
              .length
          }
          openDialog={openHeatmap}
          teacherHighlights={teacherHighlights}
          setOpenDialog={setOpenHeatmap}
          url={text.url}
          location={text.file_location}
          hlOpacity={1}
          hlColor="#4AA96C"
          highlights={studentHighlights}
          addHighlight={() => {}}
          removeHighlight={() => {}}
        />
        <Box component="header" className={classes.statContainer}>
          <Typography className={clsx(classes.questionTitle)} variant="h5">
            {intl.formatMessage({
              id: "task.question",
              defaultMessage: "Question"
            })}{" "}
            #{index + 1} —{" "}
            {intl.formatMessage({
              id: "task.type.multi",
              defaultMessage: "Multiple choice"
            })}
          </Typography>
          <Typography
            className={clsx(classes.questionSubtitle)}
            variant="h6"
            component="span">
            {question.content}
          </Typography>
        </Box>
        <Divider />
        <Box className={clsx(classes.multiChoiceContainer)}>
          {withCitations && (
            <Box className={classes.citationContainer}>
              <CitationChart
                question={question}
                i={index}
                task={task}
                submissions={submissions}
                answers={studentHighlights}
              />
            </Box>
          )}
          <Box
            className={clsx(
              classes.mainStatsContainer,
              withCitations && "withCitations"
            )}>
            <Box
              className={clsx(
                classes.answerDistributionContainer,
                withCitations && "withCitations"
              )}>
              {questionOptions.map((option, optionIndex) => {
                //TODO: change the attribute should select so that it is consistent with the student's attribute
                const correctAnswer = Number(question.shouldSelect);
                const isCorrect = correctAnswer === optionIndex;
                return (
                  <React.Fragment key={optionIndex}>
                    <Box className={classes.chipContainer}>
                      <Chip
                        // label="Correct"
                        label={
                          isCorrect
                            ? intl.formatMessage({
                                id: "task.statistics.correct",
                                defaultMessage: "Correct"
                              })
                            : intl.formatMessage({
                                id: "task.statistics.incorrect",
                                defaultMessage: "Incorrect"
                              })
                        }
                        //   color="primary"
                        variant={isCorrect ? "default" : "outlined"}
                        style={
                          isCorrect
                            ? {
                                backgroundColor: "#168FEE",
                                color: "#FFFFFF"
                              }
                            : null
                        }
                        className={classes.chip}
                      />
                    </Box>

                    <Box className={classes.optionContainer}>
                      <Box className={classes.optionText}>
                        <Box component="span">{option}</Box>
                        <Box
                          className={classes.optionPresantage}
                          component="span">
                          {(choiceDistribution &&
                            normalize(
                              choiceDistribution.get(optionIndex),
                              0,
                              submittedAnswers.length
                            )) ||
                            0}
                          %
                        </Box>
                      </Box>
                      <LinearProgress
                        variant="determinate"
                        aria-label="stats bar"
                        value={
                          choiceDistribution &&
                          normalize(
                            choiceDistribution.get(optionIndex),
                            0,
                            submittedAnswers.length
                          )
                        }
                        className={classes.statBar}
                      />
                    </Box>
                  </React.Fragment>
                );
              })}
            </Box>
            {withCitations && (
              <Box>
                <Button
                  color="primary"
                  size="small"
                  style={{
                    backgroundColor: "#168FEE",
                    color: "#FFFFFF",
                    padding: "4px 8px"
                  }}
                  className={classes.actionButton}
                  onClick={() => setOpenHeatmap(true)}>
                  {intl.formatMessage({
                    id: "task.statistics.showHeatmap",
                    defaultMessage: "Show heatmap"
                  })}
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Paper>
    );
  else {
    return (
      <>
        <Card
          component="section"
          elevation={1}
          className={classes.statContainer}>
          <HeatMap
            totalCount={
              submissions.filter((submission) => submission.submission_date)
                .length
            }
            openDialog={openHeatmap}
            teacherHighlights={teacherHighlights}
            setOpenDialog={setOpenHeatmap}
            url={text.url}
            location={text.file_location}
            hlOpacity={1}
            hlColor="#4AA96C"
            highlights={studentHighlights}
            addHighlight={() => {}}
            removeHighlight={() => {}}
          />
          <Box component="header">
            <Typography
              className={clsx(classes.questionTitle)}
              variant="h5"
              style={{
                marginBottom: "15px"
              }}>
              {intl.formatMessage({
                id: "task.question",
                defaultMessage: "Question"
              })}{" "}
              #{index + 1} —{" "}
              {intl.formatMessage({
                id: "task.type.multi",
                defaultMessage: "Multiple choice"
              })}
            </Typography>
            <Divider />
            <Box
              style={{
                display: "flex",
                flexFlow: "row",
                justifyContent: "space-between",
                paddingTop: "15px"
              }}>
              <Typography
                className={clsx(classes.questionSubtitle)}
                variant="h6">
                {question.content}
              </Typography>
              {withCitations && (
                <Box>
                  <Button
                    color="primary"
                    size="small"
                    style={{
                      backgroundColor: "#168FEE",
                      color: "#FFFFFF",
                      padding: "4px 8px"
                    }}
                    className={classes.actionButton}
                    onClick={() => setOpenHeatmap(true)}>
                    {intl.formatMessage({
                      id: "task.statistics.showHeatmap",
                      defaultMessage: "Show heatmap"
                    })}
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Card>
      </>
    );
  }
}
