import {
  PointerSensor as LibPointerSensor,
  KeyboardSensor as LibKeyboardSensor
} from "@dnd-kit/core";

export class CustomPointerSensor extends LibPointerSensor {
  static activators = [
    {
      eventName: "onPointerDown",
      handler: ({ nativeEvent: event }) => {
        return shouldHandleEvent(event.target);
      }
    }
  ];
}

export class CustomKeyboardSensor extends LibKeyboardSensor {
  static activators = [
    {
      eventName: "onKeyDown",
      handler: ({ nativeEvent: event }) => {
        return shouldHandleEvent(event.target);
      }
    }
  ];
}

function shouldHandleEvent(element) {
  while (element) {
    if (element.dataset && element.dataset.noDnd) {
      return false;
    }
    element = element.parentElement;
  }

  return true;
}

export function onDragEnd(event) {
  const { active, over } = event;
  // console.log("onDragEnd", active, over); // FIXME

  if (!!active && !!over && active?.id !== over?.id) {
    const dragType = active.data.current.type;
    if (dragType === "SortStudentQuestions") {
      sortQuestion(active, over);
    } else if (dragType === "CopyQuestionCard") {
      copyCard(active, over);
    } else if (dragType === "SortLibraryTexts") {
      sortTexts(active, over);
    }
  }
}

const sortQuestion = (active, over) => {
  // console.log("sortQuestion"); // FIXME
  const activeData = active.data?.current;
  const overData = over.data?.current;
  activeData.onSortQuestions?.(activeData.questionId, overData.questionId);
};

const copyCard = (active, over) => {
  // console.log("copyCard"); // FIXME
  const activeData = active.data?.current;
  const overData = over.data?.current;
  activeData.onCardDrop(activeData.card, overData.questionId);
};

const sortTexts = (active, over) => {
  // console.log("sortTexts"); // FIXME
  const activeData = active.data?.current;
  const overData = over.data?.current;
  activeData.onSortTexts(activeData.textId, overData.textId);
};
