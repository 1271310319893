// Dependencies
import React from "react";

// Material UI
import { TableRow, TableCell, IconButton } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

// Styles

export default function ExpandableTableRow({
  children,
  expandComponent,
  ...otherProps
}) {
  //Hooks

  // Ephemeral State
  const [isExpanded, setIsExpanded] = React.useState(false);

  //Derived state

  //Behavior
  return (
    <>
      <TableRow {...otherProps}>
        <TableCell padding="checkbox">
          <IconButton onClick={() => setIsExpanded(!isExpanded)} size="large">
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {children}
      </TableRow>
      {isExpanded && <TableRow>{expandComponent}</TableRow>}
    </>
  );
}
