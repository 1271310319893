import React from "react";
import { Typography, IconButton } from "@mui/material";
import { useGetTheme } from "../../../hooks";

export const CustomIconButton = ({ icon: Icon, label, isActive, onClick }) => {
  const theme = useGetTheme();
  return (
    <IconButton onClick={onClick} size="large">
      {<Icon fontSize="small" />}
      <Typography
        variant="body2"
        sx={{
          marginInlineStart: "8px",
          textDecoration: isActive ? "underline" : "none",
          color: theme.palette.text.primary
        }}>
        {label}
      </Typography>
    </IconButton>
  );
};
