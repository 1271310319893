import React, { useRef, useEffect } from "react";
import * as d3 from "d3";
import { useChartDimensions } from "../../../hooks";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";

// Styles
const useStyles = makeStyles((theme) => ({
  chartContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center", // Center the content horizontally
    alignItems: "center" // Center the content vertically
  },
  tooltip: {
    // top: 10,
    // right: 10,
    backgroundColor: "rgba(97, 97, 97, 0.9)",
    color: "#fff", // Optionally, set the text color
    padding: "10px",
    borderRadius: "5px",
    width: "fit-content"
  }
}));

export default function PieChart({ data, width, height }) {
  const { ref, dimensions } = useChartDimensions({});
  const classes = useStyles();
  const chartRef = useRef();

  useEffect(() => {
    if (dimensions.width > 0 && dimensions.height > 0) {
      // Render the chart using D3.js
      const svg = d3.select(chartRef.current);

      // Create a tooltip
      const tooltip = d3
        .select("body")
        .append("div")
        .style("position", "absolute")
        .style("visibility", "hidden")
        // .style("background", "#ccc")
        .style("border-radius", "5px")
        .style("padding", "10px")
        .classed(classes.tooltip, true);

      svg
        .selectAll("g")
        .remove()
        .append("svg")
        .attr("width", dimensions.width)
        .attr("height", dimensions.boundedHeight)
        .attr(
          "transform",
          `translate(${dimensions.marginLeft}, ${dimensions.marginTop})`
        );
      const pie = d3.pie().value((d) => d.val);
      const arcGenerator = d3
        .arc()
        .innerRadius((0.6 * Math.min(dimensions.width, dimensions.height)) / 2)
        .outerRadius((0.8 * Math.min(dimensions.width, dimensions.height)) / 2);

      const arcs = pie(data);

      const g = svg
        .append("g")
        .attr(
          "transform",
          `translate(${dimensions.width / 2}, ${dimensions.height / 2})`
        );

      g.selectAll("path")
        .data(arcs)
        .enter()
        .append("path")
        .attr("d", arcGenerator)
        .attr("fill", (d, i) => data[i].color)
        .on("mouseover", (d, i) => {
          tooltip.style("visibility", "visible");
          tooltip.text(`Value: ${i.value}`);
        })
        .on("mousemove", (event) => {
          tooltip
            .style("top", event.pageY - 10 + "px")
            .style("left", event.pageX + 10 + "px");
        })
        .on("mouseout", function () {
          tooltip.style("visibility", "hidden");
        });

      // Cleanup tooltip on component unmount
      return () => {
        tooltip.remove();
      };
    }
  }, [data, dimensions, classes.tooltip]);
  return (
    <Box
      width={width}
      height={height}
      ref={ref}
      className={classes.chartContainer}>
      {/* The chart will be rendered here */}
      {/* The SVG element will be appended inside this div */}
      <svg width={dimensions.width} height={dimensions.height} ref={chartRef} />
    </Box>
  );
}
