import React from "react";
import { FirestoreProvider, useFirebaseApp } from "reactfire";
import Layout from "./Layout";
import { doc, getFirestore } from "firebase/firestore";

//The authenticated user app
export const UserApp = () => {
  // Hooks

  const firebaseApp = useFirebaseApp();
  const firestoreInstance = getFirestore(firebaseApp);

  return (
    <FirestoreProvider sdk={firestoreInstance}>
      <Layout />
    </FirestoreProvider>
  );
};
