// Dependencies
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { he, enUS } from "date-fns/locale";
import { PropTypes } from "prop-types";
// Redux dependencies
import { useSelector } from "react-redux";
// Components
import {
  generateNotificationContent,
  navigateToCommentNotification
} from "../../utils/comments-utils";
import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Typography,
  Paper,
  Popper,
  Link,
  Divider
} from "@mui/material";
import { formatDistanceStrict } from "date-fns";
import { selectIsComments, selectLocale } from "../../redux/firestoreSelectors";
import { selectIsSingleThread } from "../../redux/realtimeInteractionsSlice";
import { notificationsAPI } from "../../api";

// Styles
const ITEM_HEIGHT = 56; //px;
const DIVIDER_HEIGHT = 9; //px;

const useStyles = makeStyles((theme) => ({
  notifications: {
    zIndex: 2
  },
  notificationPopper: {
    zIndex: theme.zIndex.drawer + 3
  },
  paper: {
    maxHeight: `${16 + 48 + DIVIDER_HEIGHT * 1 + ITEM_HEIGHT * 10 + DIVIDER_HEIGHT * 9 + 48}px`,
    overflow: "hidden",
    width: "360px"
  },
  notificationHeader: {
    display: "flex",
    justifyContent: "space-between"
  },
  notificationContent: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "330px"
  },
  courseName: {
    fontWeight: "bold"
  },
  time: {
    color: theme.palette.text.secondary
  },
  notification: {
    height: `${ITEM_HEIGHT}px`
  },
  divider: {
    marginTop: "4px !important",
    marginBottom: "4px !important"
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "8px",
    marginBottom: "12px",
    padding: "0 16px"
  },
  footer: {
    marginTop: "12px",
    marginBottom: "12px",
    padding: "0 16px"
  }
}));

export default function NotificationsMenu({
  notifications,
  isNotificationsOpen,
  notificationIconsRef,
  handleClose
}) {
  // Hooks
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();

  // Redux state
  const userId = useSelector((state) => state.user.auth.uid);
  const userRole = useSelector(
    (state) => state.user.userProfile.selectedCourse.course_role
  );
  const locale = useSelector((state) => selectLocale(state));

  const isSingleThread = useSelector((state) => selectIsSingleThread(state)); // display in panel a single selected thread
  const isComments = useSelector((state) => selectIsComments(state)); // display in panel several selected threads

  // Ephemeral state
  const [sortedNotifications, setSortedNotifications] = useState([]);

  // Locale State
  const localeToDateFnsOnj = {
    en: enUS,
    he: he
  };
  const notificationsCount =
    16 +
      48 +
      DIVIDER_HEIGHT * 1 +
      ITEM_HEIGHT * 10 +
      DIVIDER_HEIGHT * 9 +
      48 +
      60 >
    window.innerHeight
      ? 5
      : 10;

  //Behavior

  // Sorting the notifications based on time created from newest to oldest
  useEffect(() => {
    if (notifications) {
      setSortedNotifications(
        notifications
          .filter((notification) => !notification.isRead)
          .sort((a, b) => {
            return (
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            );
          })
          .slice(0, notificationsCount)
      );
    }
  }, [notifications]);

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(isNotificationsOpen);
  React.useEffect(() => {
    if (prevOpen.current === true && isNotificationsOpen === false) {
      notificationIconsRef.current.focus();
    }

    prevOpen.current = isNotificationsOpen;
  }, [isNotificationsOpen, notificationIconsRef]);

  async function navigateToTask(notification) {
    await navigateToCommentNotification(
      notification,
      isComments,
      isSingleThread
    );
    history.push(notification.link);
  }

  // Render
  return (
    <Popper
      open={isNotificationsOpen}
      anchorEl={notificationIconsRef.current}
      placement="bottom-end"
      transition
      className={classes.notificationPopper}>
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper className={classes.paper}>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList
                autoFocusItem={isNotificationsOpen}
                variant={"menu"}
                id="menu-list-grow"
                className={classes.notifications}>
                <MenuItem className={classes.header}>
                  <Typography variant="h6">
                    {intl.formatMessage({
                      id: "notifications.title",
                      defaultMessage: "Notifications"
                    })}
                  </Typography>
                  <Link
                    variant="body2"
                    component="button"
                    color="secondary"
                    underline="none"
                    href="#"
                    onClick={async (e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      await notificationsAPI.markAllAsRead();
                      handleClose(e);
                    }}>
                    {intl.formatMessage({
                      id: "notifications.markAllAsRead",
                      defaultMessage: "Mark all as read"
                    })}
                  </Link>
                </MenuItem>
                <Divider
                  variant="fullWidth"
                  component="li"
                  className={classes.divider}
                />
                {sortedNotifications?.map((notification, i) => {
                  return (
                    <>
                      <MenuItem
                        className={classes.notification}
                        onClick={async () => {
                          await notificationsAPI.markAsRead(notification.id);
                          await navigateToTask(notification);
                        }}
                        key={notification.id}
                        tabIndex={0}>
                        <Box className={classes.notificationContainer}>
                          <Box className={classes.notificationHeader}>
                            <Typography
                              component="span"
                              variant="subtitle2"
                              className={classes.courseName}>
                              {notification.course}
                            </Typography>
                            <Typography
                              component="span"
                              variant="subtitle2"
                              className={classes.time}>
                              {formatDistanceStrict(
                                new Date(notification.createdAt),
                                new Date(),
                                {
                                  addSuffix: true,
                                  locale: localeToDateFnsOnj[locale]
                                }
                              )}
                            </Typography>
                          </Box>
                          <Box className={classes.notificationContent}>
                            <Typography
                              component="span"
                              variant="body2"
                              className={classes.notification}
                              noWrap>
                              {generateNotificationContent(
                                notification,
                                intl,
                                userRole
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      </MenuItem>
                      {i < sortedNotifications.length - 1 && (
                        <Divider
                          variant="middle"
                          component="li"
                          className={classes.divider}
                        />
                      )}
                    </>
                  );
                })}
                <MenuItem className={classes.footer}>
                  <Link
                    variant="body2"
                    color="secondary"
                    href="#"
                    underline="none"
                    onClick={() => {
                      history.push("/notifications");
                    }}>
                    {intl.formatMessage({
                      id: "notifications.showAll",
                      defaultMessage: "Show all"
                    })}
                  </Link>
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}

NotificationsMenu.propTypes = {
  notifications: PropTypes.array,
  setIsNotificationsOpen: PropTypes.func,
  isNotificationsOpen: PropTypes.bool,
  notificationIconsRef: PropTypes.object,
  handleClose: PropTypes.func
};
