import React from "react";
import Skeleton from "@mui/material/Skeleton";

const CustomSkeleton = ({ height, width }) => {
  return (
    <Skeleton
      variant="rectangular"
      width={width ? width : "100%"}
      height={height ? height : "100%"}
    />
  );
};

export default CustomSkeleton;
