import React from "react";
import clsx from "clsx";
import { Tooltip, Box } from "@mui/material";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

const TooltipWithIntl = ({
  intlStringId,
  defaultMessage,
  placement,
  children,
  className
}) => {
  const intl = useIntl();

  return (
    <Tooltip
      role={"tooltip"}
      className={clsx(className)}
      title={intl.formatMessage({
        id: intlStringId,
        defaultMessage: defaultMessage
      })}
      arrow
      placement={placement}>
      <Box sx={{ display: "inline-block" }}>{children}</Box>
    </Tooltip>
  );
};

TooltipWithIntl.propTypes = {
  className: PropTypes.string,
  intlStringId: PropTypes.string,
  defaultMessage: PropTypes.string,
  placement: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element])
};

export default TooltipWithIntl;
