// Dependencies
import React from "react";

//Components

// Material UI
import { Table, TableRow, TableCell } from "@mui/material";

// Styles

export default function TableDropdown({ actionsCount, courseActionName }) {
  //Hooks

  // Ephemeral State

  //Derived state
  const dropdownActions = actionsCount.map((actions) => {
    return {
      ...actions[courseActionName]["count"]
    };
  });

  // Get all the keys
  const rows = dropdownActions.reduce((accumulator, current) => {
    const keys = Object.keys(current);
    keys.forEach((key) => {
      if (key === "total") return;
      else if (accumulator.includes(key)) return;
      else accumulator.push(key);
    });
    return accumulator;
  }, []);

  //Behavior
  return (
    <Table>
      {rows.map((key) => (
        <TableRow key={key}>
          <TableCell padding="checkbox" />
          <TableCell component="th" scope="row">
            {key}
          </TableCell>

          {dropdownActions.map((action) => (
            <TableCell align="center" key={action[key]}>
              {action[key] || 0}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </Table>
  );
}
