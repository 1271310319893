// dependancies
import { captureException } from "../../../utils/errorHandlers";
import { CHAT } from "../../../consts";

// Redux
import { CHATBOX_STATUS, initMentor, closeChatbox } from "../../chatSlice";
import { setSelectedInteractionId } from "../../interactionsSlice";

// rx
import { ofType } from "redux-observable";
import { EMPTY, filter, withLatestFrom, of } from "rxjs";
import { map, catchError, switchMap, ignoreElements } from "rxjs/operators";
import { questionHasHighlights } from "./utils";

export const changeSessionEpic = (action$, state$, { fetchAsObservable }) => {
  return action$.pipe(
    ofType(setSelectedInteractionId.type),
    withLatestFrom(state$),
    filter(([_, state]) => state.chat.status === CHATBOX_STATUS.OPEN),
    map(([action, _]) => action.payload),
    switchMap((interaction_id) =>
      of(interaction_id).pipe(
        withLatestFrom(state$),
        map(([interaction_id, state]) => {
          const { selectedInteractionId } = state.interactions;
          const messeges = state.chat.interactions;
          const hasHighlights = questionHasHighlights(state);

          // the anchor interaction's interaction_id equals to selected interaction id
          const anchor = messeges.find(
            (messeges) => messeges.interaction_id === interaction_id
          );

          // hide the chat box if there are not highlights
          if (!hasHighlights) return { type: closeChatbox.type, payload: {} };
          // start a new if there isn't a session in progress
          else if (!anchor)
            return {
              type: initMentor.type,
              payload: {
                chatType: CHAT.TYPE.REVIEW,
                interactionId: selectedInteractionId
              }
            };
          // if there is already a session in progress do nothing. the display logic is handled by the slice
          else ignoreElements();
        })
      )
    ),
    catchError((error) => {
      captureException(error, "Error in fetchConversationEpic");
      return EMPTY;
    })
  );
};
