// Dependencies
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useHistory } from "react-router-dom";

import { useRouteMatch } from "react-router-dom";
import { useDocument } from "react-firebase-hooks/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseApp, firestore, firebaseFunctions } from "../../../firebase";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { setProfile } from "../../../redux/userSlice";

import makeStyles from "@mui/styles/makeStyles";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography
} from "@mui/material";

// Styles
const useStyles = makeStyles((theme) => {
  return {
    divider: {
      backgroundColor: theme.palette.grey["700"]
    },
    noCourseMsg: {
      padding: "16px",
      color: "white"
    },
    accordionHeader: {
      color: "white"
    },
    courseName: {
      width: "100%",
      color: theme.palette.secondary.contrast
    },
    inActive: {
      color: theme.palette.grey["500"]
    }
  };
});

export default function CoursesDropdown() {
  // Hooks
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  let { url } = useRouteMatch();

  // Redux state
  const courses = useSelector((state) => state.courses.courses);
  const didSetCourses = useSelector((state) => state.courses.didSetCourses);
  // Ephemeral State
  const [sortedCourses, setSortedCourses] = useState([]);
  const [openYears, setOpenYears] = useState([]);

  const [user, authLoading, error] = useAuthState(firebaseApp.auth());
  const [userProfileDoc, profileLoading, userProfileError] = useDocument(
    firestore.doc("users/" + user?.uid)
  );
  const userProfile = userProfileDoc?.exists && userProfileDoc.data();

  // Derived state
  const currentCourseYear =
    (userProfile && userProfile.selectedCourse?.academic_year) || null;

  // Bahavior

  useEffect(() => {
    if (!courses.length) return;

    const classesByYear = courses.reduce((accumulator, current) => {
      if (current.academic_year in accumulator) {
        accumulator[current.academic_year].push(current);
      } else {
        accumulator[current.academic_year] = [current];
      }
      return accumulator;
    }, {});

    let unsortedCourses = Object.keys(classesByYear).map(function (key) {
      // add each course isActive value
      classesByYear[key] = classesByYear[key].map((course) => ({
        ...course,
        isActive: isCourseActive(course.course_start, course.course_end)
      }));
      //sort inner array by course isActive desc and then by course_start desc
      classesByYear[key].sort((a, b) =>
        a.isActive !== b.isActive
          ? b.isActive - a.isActive
          : new Date(b?.course_start).getTime() -
            new Date(a?.course_start).getTime()
      );
      return [key, classesByYear[key]];
    });

    let sorted = unsortedCourses.sort(function (first, second) {
      const firstStartYear = parseInt(first[0], 10);
      const secondStartYear = parseInt(second[0], 10);
      return secondStartYear - firstStartYear;
    });
    // Sort the array based on the second element
    setSortedCourses(sorted);
  }, [courses, user]); //user is there for updating the sidebar if impersonating

  useEffect(() => {
    currentCourseYear
      ? setOpenYears([currentCourseYear])
      : setOpenYears([sortedCourses?.[0]?.[0]]);
  }, [currentCourseYear, sortedCourses]);

  function toggleYear(year) {
    if (openYears.includes(year)) {
      setOpenYears(openYears.filter((el) => el !== year));
    } else {
      setOpenYears([...openYears, year]);
    }
  }

  function isCourseActive(courseStart, courseEnd) {
    const startDate = new Date(courseStart);
    const endDate = new Date(courseEnd);
    const currentDate = new Date();
    return currentDate >= startDate && currentDate <= endDate;
  }

  const isOpenYear = (year) => {
    return openYears.includes(year);
  };
  const sanitizeUrl = (url) => {
    if (url.slice(-1) === "/") {
      return url.slice(0, -1);
    } else return url;
  };
  if (!didSetCourses) {
    //empty for now, do sth better
    return <></>;
  } else {
    // Render
    if (sortedCourses.length === 0) {
      return (
        <Typography className={classes.noCourseMsg}>
          Please Contact an admin to register to a course
        </Typography>
      );
    } else
      return (
        <>
          <List component="nav" className={classes.root} disablePadding>
            {sortedCourses.map((course, index) => {
              return (
                <React.Fragment key={index}>
                  <ListItem
                    className={clsx(classes.accordionHeader)}
                    button
                    onClick={() => toggleYear(course[0])}
                    aria-label={
                      openYears.includes(course[0])
                        ? `collapse class list from ${course[0]}`
                        : `expand class list from ${course[0]}`
                    }>
                    <ListItemText primary={course[0]} />
                    {openYears.includes(course[0]) ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </ListItem>
                  <Collapse
                    in={isOpenYear(course[0])}
                    timeout="auto"
                    unmountOnExit>
                    <List component="div" disablePadding>
                      {course[1].map((course, index) => {
                        return (
                          <ListItem
                            sx={{
                              backgroundColor:
                                userProfile?.selectedCourse?.id === course.id
                                  ? "#3f3f3f"
                                  : null,
                              "&:hover": {
                                backgroundColor: "#3f3f3f"
                              }
                            }}
                            key={index}
                            onClick={() => {
                              if (userProfileDoc?.exists) {
                                dispatch(
                                  setProfile({
                                    ...userProfileDoc.data(),
                                    selectedCourse: course
                                  })
                                );
                              }
                              history.push(
                                // TODO: Not sure that this is necessary. If the routes are standardized we could just add the query param
                                `${sanitizeUrl(url)}?course_id=${course.id}`
                              );
                            }}
                            button
                            className={classes.nested}>
                            <Typography
                              className={clsx(
                                classes.courseName,
                                !course.isActive && classes.inActive
                              )}>
                              {course.name}
                            </Typography>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Collapse>
                  <Divider className={classes.divider} />
                </React.Fragment>
              );
            })}
          </List>
        </>
      );
  }
}
