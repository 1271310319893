// Dependencies
import React, {
  useEffect,
  useState,
  useMemo,
  useReducer,
  useCallback
} from "react";
import { useGetTheme, useQuery } from "../../../hooks";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import { httpCallables } from "../../../firebase";
import { captureException } from "../../../utils/errorHandlers";
import { useIntl } from "react-intl";
import { CHARTS_VIEW_TYPE } from "../../admin/reports/consts";
import { getTaskChartsDataFromApiResponse } from "../../admin/reports/courseActivity/transformUtils";
import { processSubmissionsStatus } from "./utils";

// Redux
import { selectCourse } from "../../../redux/coursesSlice";
import { setBreadcrumbs } from "../../../redux/readerActionsSlice";
import { addSnackbarItem } from "../../../redux/snackbarSlice";

// Components
import CourseOverview from "./CourseOverview";
import SubmissionsReport from "./submissions/SubmissionsReport";
import TimeOnAssignmentReport from "./assignments/TimeOnAssignmentReport";
import StudentEngagementReport from "./engagement/StudentEngagementReport";
import CourseGradesReport from "./grades/CourseGradesReport";
import ScrollBox from "../ScrollBox";
import CustomSkeleton from "../CustomSkeleton";
// Material-UI
import { Box, Typography } from "@mui/material";

function usersReducer(state, action) {
  switch (action.type) {
    case "set":
      return { ...action.payload };
    case "toggle": {
      let { user } = action.payload;
      return {
        ...state,
        [user]: { ...state[user], selected: !state[user].selected }
      };
    }
    case "update": {
      let { user: userToUpdate, ...rest } = action.payload;
      return {
        ...state,
        [userToUpdate]: { ...state[userToUpdate], ...rest }
      };
    }
    default:
      throw new Error();
  }
}

const CourseExternalReport = (props) => {
  // Hooks
  const { course_id } = useQuery();
  const intl = useIntl();
  const dispatch = useDispatch();
  const theme = useGetTheme();
  // Redux
  const course = useSelector((state) => selectCourse(state, Number(course_id)));

  // Ephemeral state
  const [users, dispatchUsers] = useReducer(usersReducer, {});

  const [submissions, setSubmissions] = useState([{}]);
  const [rawSubmissions, setRawSubmissions] = useState([]);
  const [courseAssignments, setCourseAssignments] = useState([]);
  const [timeSpentOnAssignmentData, setTimeSpentOnAssignmentData] = useState(
    []
  );
  const [taskChartData, setTaskChartsData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [taskIds, setTaskIds] = useState([]);
  const [maxPagePerTextData, setMaxPagePerTextData] = useState([]);
  // Behavior
  const courseStudentsList = useMemo(() => {
    return Object.keys(users)
      .filter((key) => users[key].course_role === "Student")
      .map((key) => users[key]);
  }, [users]);

  const courseStart = course.course_start;
  const courseEnd = course.course_end;

  const removeUnselectedUsersFromBothObjects = (
    users,
    engagementReport,
    secondObject
  ) => {
    // Filter out the selected users
    const selectedUsers = Object.entries(users)
      .filter(([userId, user]) => user.selected)
      .map(([userId]) => userId);

    // Check if userBased property exists and is not null
    const userBased = engagementReport.userBased || {};

    // Remove unselected users from engagementReport
    const filteredUserBased = Object.fromEntries(
      Object.entries(userBased).filter(([userId]) =>
        selectedUsers.includes(userId)
      )
    );

    const filteredEngagementReport = {
      ...engagementReport,
      userBased: filteredUserBased
    };

    // Remove unselected users from secondObject
    const filteredSubmissions = Object.fromEntries(
      Object.entries(secondObject).filter(([userId]) =>
        selectedUsers.includes(userId)
      )
    );

    return {
      filteredEngagementReport,
      filteredSubmissions
    };
  };

  const generateInitalUsersState = useCallback((users) => {
    return users.reduce((accumulator, current) => {
      const user = current.course_user;
      const selected = true;
      return {
        ...accumulator,
        [user]: { ...current, selected }
      };
    }, {});
  }, []);

  // Get Course task data
  useEffect(() => {
    if (course) {
      setIsLoading(true);

      const getTaskDataPromise = httpCallables.adminGetStats({
        course_id: Number(course_id),
        start: courseStart,
        end: courseEnd,
        type: CHARTS_VIEW_TYPE.TASK.toUpperCase()
      });

      Promise.all([getTaskDataPromise])
        .then(([taskResponse]) => {
          if (taskResponse.data.success) {
            const submissionsData = processSubmissionsStatus(
              taskResponse.data.payload.SUBMISSIONS_STATUS
            );
            const rawStudentsSubmissions =
              taskResponse.data.payload.SUBMISSIONS_STATUS.rawSubmissions;

            setRawSubmissions(rawStudentsSubmissions);
            setSubmissions(submissionsData);
            setCourseAssignments(
              taskResponse.data.payload.PUBLISHED_TASKS.tasks
            );
            const taskData = getTaskChartsDataFromApiResponse(
              taskResponse.data.payload
            );
            setTaskChartsData(taskData);

            // extract time spent on assignment
            const timePerStep = {
              HIGHLIGHT_TIME: taskResponse.data.payload.HIGHLIGHT_TIME,
              REVIEW_TIME: taskResponse.data.payload.REVIEW_TIME,
              ANSWER_TIME: taskResponse.data.payload.ANSWER_TIME
            };
            setTimeSpentOnAssignmentData(timePerStep);

            //extract task ids as array
            const publishedTasks =
              taskResponse.data.payload.PUBLISHED_TASKS.tasks;
            const taskIds = publishedTasks.map((task) => task.id);
            setTaskIds(taskIds);

            dispatchUsers({
              type: "set",
              payload: generateInitalUsersState(
                taskResponse.data.payload.COURSE_USER_NAME
              )
            });
          } else {
            dispatch(
              addSnackbarItem({
                intlId: "error.readCourseUsersFailed",
                intlDefaultMessage:
                  "There was a problem getting the course information. Please check your connection and try again",
                id: uuid()
              })
            );
            if (!taskResponse.data.success) {
              captureException(
                taskResponse.data.error,
                `Failed to get task Stats`
              );
            }
          }
        })
        .catch((error) => {
          // Handle any errors that occurred during the promises
          console.error("Error fetching data:", error);
        });
    }
  }, [
    courseStart,
    courseEnd,
    course_id,
    course,
    dispatch,
    generateInitalUsersState
  ]);

  // Set breadcrumbs
  useEffect(() => {
    let parts = [];
    parts.push({
      text: course.name,
      url: `/tasks?course_id=${course_id}`
    });
    parts.push({
      url: `/CourseExternalReport?course_id=${course_id}`,
      text: intl.formatMessage({
        id: "internalReports.internalReport",
        defaultMessage: "Course report"
      }),
      course: course
    });
    dispatch(
      setBreadcrumbs({ breadcrumbs: parts, blue: true, showTextMenu: false })
    );
  }, [course_id, course.name, course, dispatch, intl]);

  useEffect(() => {
    if (submissions && users && courseAssignments.length && taskChartData) {
      setIsLoading(false);
    }
  }, [submissions, users, courseAssignments, taskChartData]);

  useEffect(() => {
    if (users && taskChartData && submissions) {
      // TODO - filterout data by excluded users + persistancy state
      const { filteredEngagementReport, filteredSubmissions } =
        removeUnselectedUsersFromBothObjects(users, taskChartData, submissions);
    }
  }, [users, submissions, taskChartData]);

  useEffect(() => {
    if (!taskIds.length) return;
    else {
      httpCallables
        .getMaxPagePerText({
          course_id: Number(course_id),
          task_ids: taskIds
        })
        .then(({ data }) => {
          if (data.success) {
            const response = JSON.parse(data.payload);

            setMaxPagePerTextData(response.payload);
          }
        });
    }
  }, [taskIds, course_id]);

  return (
    <ScrollBox>
      <Box
        sx={{
          width: "100%",
          overFlowY: "scroll",
          display: "flex",
          flexFlow: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          paddingInlineEnd: "16px",
          paddingBlockEnd: "80px",
          backgroundColor: theme.palette.background.default
        }}>
        <Box
          sx={{
            width: "950px",
            display: "flex",
            flexFlow: "column nowrap",
            justifyContent: "flex-start",
            alignItems: "flex-start"
          }}>
          <Typography variant="h6" sx={{ marginBlockStart: "40px" }}>
            {course.name}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ marginBlockEnd: "16px", marginBlockStart: "32px" }}>
            Course overview
          </Typography>
          <Box sx={{ width: "100%" }}>
            <CourseOverview
              courseStudents={courseStudentsList}
              submissionRate={taskChartData ? submissions.submissionRate : 0}
              totalAssignments={
                taskChartData ? taskChartData.PUBLISHED_TASKS.data.length : 0
              }
              timeSpentOnAssignmentData={timeSpentOnAssignmentData}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              marginBlockStart: "32px"
            }}>
            <Typography variant="subtitle1" sx={{ marginBlockEnd: "16px" }}>
              Course submissions
            </Typography>
            {isLoading ? (
              <CustomSkeleton height={"200px"} />
            ) : (
              <SubmissionsReport
                submissions={submissions}
                courseStudents={courseStudentsList}
                courseAssignments={courseAssignments}
              />
            )}
          </Box>
          <Box
            sx={{
              width: "100%",
              marginBlockStart: "32px"
            }}>
            <Typography variant="subtitle1" sx={{ marginBlockEnd: "16px" }}>
              Time allocation
            </Typography>
            {isLoading ? (
              <CustomSkeleton height={"200px"} />
            ) : (
              <>
                <TimeOnAssignmentReport
                  data={timeSpentOnAssignmentData}
                  courseAssignments={courseAssignments}
                  courseStudents={courseStudentsList}
                />
              </>
            )}
          </Box>
          <Box sx={{ width: "100%", marginBlockStart: "32px" }}>
            <Typography variant="subtitle1" sx={{ marginBlockEnd: "16px" }}>
              Grades
            </Typography>
            {isLoading ? (
              <CustomSkeleton height={"400px"} />
            ) : (
              <CourseGradesReport
                data={rawSubmissions}
                courseAssignments={courseAssignments}
                courseStudents={courseStudentsList}
              />
            )}
          </Box>
          <Box sx={{ width: "100%", marginBlockStart: "32px" }}>
            <Typography variant="subtitle1" sx={{ marginBlockEnd: "16px" }}>
              Engagement
            </Typography>
            {/*TBD: Add code for engagement report*/}
            {isLoading ? (
              <CustomSkeleton height={"400px"} />
            ) : (
              <StudentEngagementReport
                courseStudents={courseStudentsList}
                users={users}
                dispatchUsers={dispatchUsers}
                start={courseStart}
                end={courseEnd}
                timeSpentOnAssignmentData={timeSpentOnAssignmentData}
                rawSubmissions={submissions.userBased}
                maxPagePerTextData={maxPagePerTextData}
              />
            )}
          </Box>
        </Box>
      </Box>
    </ScrollBox>
  );
};

export default CourseExternalReport;
