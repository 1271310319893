export const Typography = {
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontFamily: "Chivo",
  small: {
    fontSize: 12
  },
  caption: {
    fontSize: 12,
    lineHeight: "20px"
  },
  subtitle2: {
    fontSize: 14,
    lineHeight: "22px",
    letterSpacing: "0.1px"
  },
  body1: {
    fontSize: 16,
    lineHeight: "24px",
    letterSpacing: "0.15px"
  },
  body2: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0.2px"
  },
  h2: {
    fontSize: 60,
    lineHeight: "72px",
    letterSpacing: "-0.5px"
  },
  h4: {
    fontSize: 34,
    lineHeight: "42px",
    letterSpacing: "0.25px"
  },
  h6: {
    fontSize: 20,
    lineHeight: "32px",
    letterSpacing: "0.15px"
  }
};
