import React from "react";
import PropTypes from "prop-types";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { httpCallables } from "../../firebase";

const CourseInstitutionSelection = ({
  institutions,
  course_id,
  courseInstitution,
  setCourseInstitution
}) => {
  const handleInstitutionChange = async (event) => {
    const selectedInstitution = event.target.value;
    httpCallables.institutionsFunctions({
      func_name: "linkCourseToInstitution",
      course_id,
      institutionId: selectedInstitution
    });

    setCourseInstitution(selectedInstitution);
  };

  if (!institutions.length) return;
  return (
    <FormControl sx={{ minWidth: "300px" }}>
      {!courseInstitution && (
        <InputLabel id="institution-label">Institutions</InputLabel>
      )}
      <Select
        labelId="institution-label"
        id="institution-select"
        variant="outlined"
        value={courseInstitution || ""}
        disabled={!!courseInstitution}
        fullWidth
        onChange={handleInstitutionChange}>
        {institutions.map((institution) => (
          <MenuItem key={institution.id} value={institution.id}>
            {institution.id}- {institution.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

CourseInstitutionSelection.propTypes = {
  institutions: PropTypes.array,
  courseInstitution: PropTypes.number,
  course_id: PropTypes.number,
  setCourseInstitution: PropTypes.func
};

export default CourseInstitutionSelection;
