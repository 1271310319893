import React, { useLayoutEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Typography, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useGetTheme, useQuery } from "../../../hooks";
import { selectSubmission, selectTask } from "../../../redux/tasksSlice";
import { INTERACTION_SUBTYPES, TASK } from "../../../consts";
import clsx from "clsx";
import { selectCurrentQuestion } from "../../../redux/interactionsSlice";

import { grAPI, standardTaskAPI } from "../../../api";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  Inner: {
    width: 500,
    textAlign: "center",
    padding: theme.spacing(2)
  },
  title: { marginBlock: theme.spacing(3) },
  paragraph: { marginBottom: theme.spacing(6) },
  btn: {
    color: theme.palette.secondary.main
  },
  taskBtn: {
    color: theme.palette.primary.main
  }
}));

function EmptyState({ step }) {
  const dispatch = useDispatch();
  const theme = useGetTheme();
  const classes = useStyles({ theme });
  const { text_id, submission_id } = useQuery();

  const grMode = useSelector((state) => state.gr.mode);

  const [image, setImage] = useState();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [callback, setCallback] = useState();

  // Redux state
  const submission = useSelector((state) =>
    selectSubmission(state, Number(submission_id))
  );
  const task = useSelector((state) => selectTask(state, submission.task_id));
  const selectedQuestionIndex = useSelector(
    (state) => state.task.selectedQuestionIndex
  );
  const selectedQuestion = useSelector(selectCurrentQuestion);
  // Derived state
  const graphicsUrl = `${process.env.PUBLIC_URL}/emptyStateGraphics`;
  const standardTask = task.task_type === TASK.TYPE.STANDARD;
  const questionTypeFindInText =
    selectedQuestion.interaction_subtype === INTERACTION_SUBTYPES.FIND_IN_TEXT;
  const answerStep = step === "answer";
  const isTask = !!submission_id;

  const changeStep = (newStep) => {
    isTask
      ? standardTaskAPI.updateSelectedStep(newStep, submission.task_id, step)
      : grAPI.updateSelectedStep(newStep, step);
  };

  // Behavior
  useLayoutEffect(() => {
    switch (true) {
      case !answerStep:
        setImage("homework");
        setTitle("Create highlights");
        setContent(
          standardTask
            ? "Create highlights to your instructor’s questions in step 1 to be able to review them here."
            : "Create highlights to questions in step 1 in order to review them here."
        );
        setButtonText("Back to step 1");
        setCallback(() => () => changeStep("highlight", selectedQuestionIndex));
        break;
      case answerStep && questionTypeFindInText:
        setImage("Learning");
        setTitle("Written response not required");
        setContent(
          "This is a “find in text” question, no written response is required. Feel free to proceed with your work."
        );
        setButtonText("");
        setCallback(() => () => {});
        break;
      case answerStep && !standardTask:
        setImage("homework");
        setTitle("Create questions");
        setContent(
          "Create questions about the text in step 1 in order to answer them here."
        );
        setButtonText("Back to step 1");
        setCallback(() => () => changeStep("highlight", selectedQuestionIndex));
        break;
      default:
        break;
    }
  }, [dispatch, grMode, answerStep]);

  return (
    <Box className={classes.container}>
      <Box className={classes.Inner}>
        <picture>
          <source type="image/svg+xml" srcSet={`${graphicsUrl}/${image}.svg`} />
          <img src={`${graphicsUrl}/${image}.png`} alt="empty state" />
        </picture>

        <Typography variant="h5" className={classes.title}>
          {title}
        </Typography>
        <Typography className={classes.paragraph}>{content}</Typography>
        {buttonText && (
          <Button
            className={clsx(classes.btn, isTask && classes.taskBtn)}
            variant="outlined"
            color={isTask ? "primary" : "secondary"}
            onClick={callback}>
            {buttonText}
          </Button>
        )}
      </Box>
    </Box>
  );
}

export default EmptyState;
