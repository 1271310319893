import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import clsx from "clsx";
import { formatDistanceStrict } from "date-fns";
import { he, enUS } from "date-fns/locale";
import { FEATURES, PRIVACY, appActions } from "../../consts";

// Redux dependencies
import { useDispatch, useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import {
  selectAvatar,
  selectCommentsPrivacy
} from "../../redux/firestoreSelectors";
//Components

// Material UI
import { Box, Button, Typography, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  selectLocale,
  selectTextDirection
} from "../../redux/firestoreSelectors";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import PrivacyDropdown from "./PrivacyDropdown";
import { CustomAvatar, TooltipWithIntl } from "../SharedComponents";
import { useRendition } from "../../RenditionContext";
import { showInText } from "../../utils/showInTextHelper";
import { setDetachedCommentMode } from "../../redux/readerActionsSlice";
import {
  selectCurrentInteraction,
  setSelectedInteractionId
} from "../../redux/interactionsSlice";
import { setSelectedQuestion } from "../../redux/grSlice";
import { setCustomCursor } from "../../redux/layoutSlice";

const useStyles = makeStyles((theme) => ({
  commentContainer: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 120px)",
    width: "100%",
    maxWidth: "250px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none"
    },
    fontFamily: "Chivo",
    margin: 0
  },
  commentHeader: {
    color: theme.palette.text.blue,
    fontWeight: 400,
    position: "fixed",
    letterSpacing: "1px",
    textDecoration: "none",
    alignContent: "center",
    paddingBottom: theme.spacing(1.3),
    paddingTop: theme.spacing(1.3),
    width: "240px",
    backgroundColor: theme.palette.background.paper,
    zIndex: 10,
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  active: {
    paddingInline: theme.spacing(2.5),
    display: "flex",
    justifyContent: "space-between"
  },
  commentsBox: {
    lineHeight: 1.5,
    display: "flex",
    minHeight: "150px",
    flexDirection: "column",
    justifyContent: "space-between",
    fontSize: "16px",
    margin: 0,
    marginTop: theme.spacing(5),
    overflowY: "none"
  },
  commentBox: {
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
      cursor: "pointer"
    },
    paddingBottom: theme.spacing(1)
  },
  commentTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginTop: theme.spacing(1.5),
    whiteSpace: "nowrap",
    paddingInlineEnd: theme.spacing(2),
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    maxWidth: "fit-content"
  },
  commentsEmpty: {
    display: "flex",
    marginTop: theme.spacing(1.5),
    paddingInline: theme.spacing(1),
    flexDirection: "column",
    alignItems: "center",
    color: theme.palette.text.disabled
  },
  username: {
    flexDirection: "column",
    textOverflow: "ellipsis",
    marginTop: theme.spacing(0.3),
    fontWeight: 400,
    paddingRight: theme.spacing(1),
    display: "-webkit-box",
    "-webkit-box-orient": "horizontal",
    overflow: "hidden"
  },
  dateCreated: {
    color: theme.palette.text.disabled,
    fontSize: theme.typography.small.fontSize,
    marginTop: theme.spacing(0.5)
  },
  commentBody: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: theme.spacing(2),
    paddingInlineEnd: theme.spacing(1.5)
  },
  commentContent: {
    display: "-webkit-box",
    whiteSpace: "normal",
    flexDirection: "column",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": 4,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    color: theme.palette.text.secondary
  },
  commentFooterActions: {
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "fit-content",
    height: "28px"
  },
  buttons: {
    padding: "0",
    margin: "0",
    display: "flex",
    justifyContent: "flex-start",
    fontSize: "14px",
    letterSpacing: "1px",
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "transparent",
      color: "#2E7D32"
    },
    color: "#4CAF50"
  },
  avatar: {
    height: "26px",
    width: "26px",
    fontSize: "small",
    marginInlineEnd: theme.spacing(0.5)
  },
  minimizedAvatar: {
    height: "16px",
    width: "16px",
    fontSize: "small",
    marginInlineEnd: theme.spacing(0.25)
  },
  showInTextIcon: {
    color: theme.palette.text.disabled,
    "&:hover": {
      color: theme.palette.secondary.main
    }
  },
  plusIconBtn: {
    borderRadius: "50%",
    height: "26px",
    width: "26px",
    "&:hover": {
      cursor: "pointer",
      background: theme.palette.background.hover
    },
    background: theme.palette.background.light
  }
}));

const ThreadsList = ({
  filterThreadsByCfiOnHighlightClick,
  navigateToThread,
  setSortedComments,
  sortedComments
}) => {
  // Hooks
  const classes = useStyles();
  const intl = useIntl();
  const rendition = useRendition();
  const dispatch = useDispatch();
  // Redux State
  const locale = useSelector((state) => selectLocale(state));
  const textDirection = useSelector((state) => selectTextDirection(state));

  const currentUser = useSelector((state) => state.user.auth);
  const threads = useSelector((state) => state.realtimeInteractions.threads);
  const privacy = useSelector((state) => selectCommentsPrivacy(state));
  const selectedRealtimeInteractions = useSelector(
    (state) => state.realtimeInteractions.selectedRealtimeInteractions
  );
  const selectedQuestion = useSelector(selectCurrentInteraction);
  const avatar = useSelector((state) => selectAvatar(state));

  useFirestoreConnect([
    {
      collection: "userProfiles",
      doc: "customConfig",
      subcollections: [{ collection: currentUser.uid, doc: FEATURES.COMMENTS }],
      storeAs: "commentsCustomConfig"
      // type: "once"
    }
  ]);

  // Ephemeral State
  const [hoveredCommentIndex, setHoveredCommentIndex] = useState(null);
  const [hoveredCommentBox, setHoveredCommentBox] = useState(null);

  // Derived State
  const localeToDateFnsOnj = {
    he: he,
    en: enUS
  };

  //Behavior

  const handleCommentBoxHover = (index) => {
    setHoveredCommentIndex(index);
  };

  const handleCommentBoxUnhover = () => {
    setHoveredCommentIndex(null);
  };
  const setFirstCommentOfEachThread = (threads) => {
    let newArray = Object.keys(threads).reduce((accumulator, id) => {
      if (threads[id].length > 0) accumulator.push(threads[id][0]);
      return accumulator;
    }, []);
    let filteredComments = filterCommentsByPrivacy(newArray);
    filteredComments.sort((a, b) => {
      return (
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );
    });
    setSortedComments(filteredComments);
  };

  const filterCommentsByPrivacy = (newArray) => {
    return newArray.filter((comment) => {
      if (privacy === PRIVACY.PUBLIC) return comment.privacy === PRIVACY.PUBLIC;
      return (
        comment.privacy === privacy && comment.user_uid === currentUser.uid
      );
    });
  };

  useEffect(() => {
    if (selectedRealtimeInteractions == false)
      setFirstCommentOfEachThread(threads);
    else {
      const commentsArray = filterThreadsByCfiOnHighlightClick(
        threads,
        selectedRealtimeInteractions
      );
      setSortedComments(commentsArray);
    }
  }, [privacy, selectedRealtimeInteractions, threads]);

  //Variables
  const singleCommentReply = intl.formatMessage({
    id: "comments.singleCommentReply",
    defaultMessage: "1 Reply"
  });
  const multipleCommentReply = intl.formatMessage({
    id: "comments.multipleCommentReply",
    defaultMessage: "Replies"
  });
  const noCommentReply = intl.formatMessage({
    id: "comments.noCommentReply",
    defaultMessage: "Reply.."
  });

  const setAmountOfRepliesToThread = (comment) => {
    const { interaction_id } = comment;
    if (!threads || !Object.keys(threads).length) return;
    return (
      <Button className={classes.buttons} color="primary" size="small">
        {threads[interaction_id].length > 2
          ? `${threads[interaction_id].length - 1} ${multipleCommentReply}`
          : threads[interaction_id].length === 2
            ? `${singleCommentReply}`
            : `${noCommentReply}`}
      </Button>
    );
  };

  function setRepliesAvatar(comment, isHovered) {
    const { interaction_id } = comment;
    if (isHovered)
      return threads[interaction_id].map((reply, index) => {
        if (index === 0) return <></>;
        return (
          <CustomAvatar
            key={reply.user_uid}
            className={classes.minimizedAvatar}
            id={reply.user_uid}
            src={reply.user_uid === currentUser.uid ? avatar : ""}
            name={reply.user_name}
            minimized={true}
          />
        );
      });
  }
  const showInTextHandler = (comment) => {
    const renditionObj = rendition.rendition;
    showInText(appActions.SHOULD_LOG, comment, currentUser.uid, renditionObj);
  };
  return (
    <Box className={clsx(classes.commentContainer)}>
      <Box className={clsx(classes.commentHeader, classes.active)}>
        <PrivacyDropdown />
        <Box>
          <IconButton
            aria-label={"Add new highlight to comment button"}
            color="primary"
            className={clsx(classes.plusIconBtn)}
            onClick={() => {
              dispatch(setDetachedCommentMode(true));
              dispatch(setCustomCursor(true));
              if (selectedQuestion.id) {
                dispatch(setSelectedInteractionId(null));
                dispatch(setSelectedQuestion(null));
              }
            }}
            size="small">
            <AddOutlinedIcon color={"disabled"} />
          </IconButton>
        </Box>
      </Box>
      <Box className={classes.commentsBox}>
        {sortedComments && !sortedComments.length ? (
          <Box className={classes.commentsEmpty}>
            <Typography component="span" variant="body2">
              {intl.formatMessage({
                id: "comments.noCommentsYet",
                defaultMessage:
                  "Click + icon and then select the relevant text passage."
              })}
            </Typography>
          </Box>
        ) : (
          <>
            {sortedComments &&
              sortedComments.map((comment, index) => {
                const isRepliesHovered = index === hoveredCommentIndex;
                const isCommentBoxHovered = index === hoveredCommentBox;

                return (
                  <Box
                    key={comment.id}
                    className={classes.commentBox}
                    onClick={() => {
                      showInTextHandler(comment);
                      navigateToThread(comment.interaction_id);
                    }}
                    onMouseEnter={() => {
                      setHoveredCommentBox(index);
                    }}
                    onMouseLeave={() => {
                      setHoveredCommentBox(null);
                    }}>
                    <Box className={classes.commentTitle}>
                      <CustomAvatar
                        className={classes.avatar}
                        id={comment.user_uid}
                        src={comment.user_uid === currentUser.uid ? avatar : ""}
                        name={currentUser.displayName}
                        minimized={true}
                      />
                      <Typography
                        component="span"
                        variant="subtitle2"
                        className={classes.username}>
                        {currentUser.uid == comment.user_uid
                          ? intl.formatMessage({
                              id: "comments.commentOwner",
                              defaultMessage: "Me"
                            })
                          : comment.user_name}
                      </Typography>
                      <Typography
                        component="span"
                        variant="caption"
                        className={classes.dateCreated}
                        dir={textDirection}>
                        {formatDistanceStrict(
                          new Date(comment.created_at),
                          new Date(),
                          {
                            addSuffix: true,
                            locale: localeToDateFnsOnj[locale]
                          }
                        )}
                      </Typography>
                    </Box>
                    <Box className={classes.commentBody} dir={textDirection}>
                      <Typography
                        component="span"
                        variant="subtitle2"
                        className={classes.commentContent}>
                        {comment.content}
                      </Typography>
                      <Box className={classes.commentFooterActions}>
                        <Box
                          sx={{ display: "flex" }}
                          onMouseEnter={() => handleCommentBoxHover(index)}
                          onMouseLeave={handleCommentBoxUnhover}>
                          {setAmountOfRepliesToThread(comment)}
                          <Box
                            sx={{
                              width: "auto",
                              maxWidth: "50%",
                              maxHeight: "30px",
                              display: "flex",
                              overflow: "hidden",
                              marginTop: "4px",
                              marginInlineStart: "8px",
                              flexFlow: "row wrap"
                            }}>
                            {setRepliesAvatar(comment, isRepliesHovered)}
                          </Box>
                        </Box>
                        {isCommentBoxHovered && (
                          <Box
                            sx={{
                              marginInlineEnd: "4px"
                            }}>
                            <TooltipWithIntl
                              intlStringId="highlights.showInText"
                              defaultMessage="Show in text"
                              placement="left">
                              <LocationOnOutlinedIcon
                                className={classes.showInTextIcon}
                                fontSize="medium"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  showInTextHandler(comment);
                                }}
                              />
                            </TooltipWithIntl>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Box>
                );
              })}
          </>
        )}
      </Box>
    </Box>
  );
};

export default ThreadsList;
