import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  FormControl,
  TextField,
  FormLabel,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { useDebounce } from "../../../hooks/useDebounce";

const useStyles = makeStyles((theme) => ({
  input: {
    width: "100%",
    marginRight: 0,
    "& .MuiFormControlLabel-label": {
      width: "100%"
    }
  },
  txtInput: {
    width: "100%"
  },
  radio: {
    marginRight: "20px",
    marginLeft: "20px"
  }
}));

const Option = React.memo(function Option({
  option,
  index,
  updateOption,
  removeOption,
  shouldSelect,
  readOnly,
  selectOption
}) {
  const classes = useStyles();
  const intl = useIntl();
  const newInputRef = useRef();

  const [localOption, setLocalOption] = useState(option);
  const debouncedUpdateOption = useDebounce(updateOption);

  useEffect(() => {
    setLocalOption(option);
  }, [option]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setLocalOption(newValue);
    debouncedUpdateOption(newValue, index);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        marginBottom: "15px"
      }}>
      <FormControlLabel
        key={index}
        id={`option-${index}`}
        className={classes.input}
        value={index}
        control={
          <Radio
            className={classes.radio}
            checked={shouldSelect}
            onChange={() => selectOption({ target: { value: index } })}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                selectOption({ target: { value: index } });
              }
            }}
            inputProps={{ "aria-labelledby": `option-label-${index}` }}
          />
        }
        label={
          <TextField
            variant="outlined"
            size="small"
            id={`option-label-${index}`}
            className={classes.txtInput}
            value={localOption}
            inputRef={newInputRef}
            multiline={true}
            name="multi-choice-option"
            aria-label={`Option ${index + 1}`}
            placeholder={intl.formatMessage({
              id: "task.addOption",
              defaultMessage: "Enter an answer choice"
            })}
            onChange={handleChange}
            disabled={readOnly}
          />
        }
      />
      {!readOnly && (
        <IconButton
          onClick={() => removeOption(index)}
          aria-label={intl.formatMessage({
            id: "task.removeOption",
            defaultMessage: "Remove option"
          })}
          sx={{ marginRight: "20px", marginLeft: "20px" }}>
          <CloseIcon />
        </IconButton>
      )}
    </Box>
  );
});

export default function AssignmentQuestionMultiChoice({
  options,
  onUpdate,
  tempId,
  shouldSelect,
  readOnly
}) {
  const classes = useStyles();
  const intl = useIntl();

  const updateOption = (option, index) => {
    const newOptions = options.map((o, i) => (i === index ? option : o));
    onUpdate({ options: newOptions }, tempId);
  };

  const addOption = () => {
    onUpdate({ options: [...options, ""] }, tempId);
  };

  const removeOption = (index) => {
    const newOptions = options.filter((o, i) => i !== index);
    onUpdate({ options: newOptions }, tempId);
  };

  const selectOption = (event) => {
    const newValue = Number(event.target.value);
    onUpdate(
      { shouldSelect: newValue === shouldSelect ? null : newValue },
      tempId
    );
  };

  return (
    <Box>
      <FormControl
        sx={{ display: "flex", justifyContent: "space-between" }}
        disabled={readOnly}
        aria-labelledby="multi-choice-options-label">
        <FormLabel
          id="multi-choice-options-label"
          sx={{
            marginTop: "24px",
            marginBottom: "15px",
            "&.Mui-focused": {
              color: "text.secondary"
            }
          }}>
          {intl.formatMessage({
            id: "task.create.assignment.multiChiceInstructions",
            defaultMessage: "Type answer options and mark the correct answer:"
          })}
        </FormLabel>
        <RadioGroup
          value={shouldSelect}
          onChange={selectOption}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              selectOption(e);
            }
          }}>
          {options.map((option, index) => (
            <Option
              option={option}
              key={index}
              index={index}
              updateOption={updateOption}
              removeOption={removeOption}
              shouldSelect={shouldSelect === index}
              readOnly={readOnly}
              selectOption={selectOption}
            />
          ))}
        </RadioGroup>
        {!readOnly && (
          <Button
            onClick={addOption}
            startIcon={<AddIcon />}
            sx={{ width: "fit-content", marginLeft: "66px" }}>
            {intl.formatMessage({
              id: "task.create.assignment.addAnotherChoice",
              defaultMessage: "Add another choice"
            })}
          </Button>
        )}
      </FormControl>
    </Box>
  );
}
