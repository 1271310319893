// Dependencies
import React from "react";
import { isEmulated, crossed } from "../../firebase";
import { useImpersonate } from "../../hooks";
// Redux dependencies
import { useSelector } from "react-redux";

import makeStyles from "@mui/styles/makeStyles";
import { Box, Link } from "@mui/material";

//Styles
const useStyles = makeStyles((theme) => ({
  adminNotifications: {
    width: "100vw",
    position: "fixed",
    top: "0px",
    background: "#0288D1",
    textAlign: "center",
    zIndex: 1000000,
    "&>span": { marginInline: theme.spacing(3) }
  }
}));

function AdminNotification() {
  //Hooks
  const classes = useStyles();
  const { stopImpersonate } = useImpersonate();

  //Redux State
  const originalAuth = useSelector((state) => state.user.original_auth);

  //Derived State
  const isImpersonation = originalAuth && originalAuth !== -1;

  // Behavior

  //Render
  if (isEmulated || crossed || isImpersonation) {
    return (
      <Box className={classes.adminNotifications}>
        {isEmulated && <Box component="span">Emulated</Box>}

        {crossed && <Box component="span">crossed with {crossed} backend</Box>}

        {isImpersonation && (
          <Box component="span">
            Impersonation -{" "}
            <Link
              component="button"
              style={{ color: "black" }}
              onClick={stopImpersonate}
              underline="hover">
              Stop Impersonation
            </Link>
          </Box>
        )}
      </Box>
    );
  } else return null;
}

export default AdminNotification;
