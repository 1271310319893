// Dependencies
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useIntl } from "react-intl";
import { useResizeDetector } from "react-resize-detector";
import { useHistory } from "react-router-dom";
import { peerReviewAPI } from "../../../api";
import { isEmpty } from "lodash";
import { useQuery, useGetTheme } from "../../../hooks";
import { useRendition } from "../../../RenditionContext";
import {
  PEER_REVIEW_TASK,
  INTERACTION_TYPES,
  INTERACTION_SUBTYPES,
  SECONDARY_SIDEBAR_ACTION_BUTTONS
} from "../../../consts";
import { handleThumbnailPanel } from "../../SharedComponents/buttons/utils";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { setBreadcrumbs } from "../../../redux/readerActionsSlice";
import { selectCurrentText, isPdfSelector } from "../../../redux/textsSlice";
import { selectTask, selectSubmission } from "../../../redux/tasksSlice";
import { selectZoom } from "../../../redux/pdfSlice";
import {
  selectPeerReviewReflection,
  updateInteraction,
  selectPeerReviewQuestion
} from "../../../redux/interactionsSlice";
import { selectCourseByTaskId } from "../../../redux/coursesSlice";
import {
  selectDarkMode,
  selectIsThumbnails
} from "../../../redux/firestoreSelectors";

// Components
import PDFThumbBar from "../../reader/pdf/sidebar/PDFThumbBar";
import PdfView from "../../reader/pdf/PdfView";
import {
  TextEditor,
  TextEditorHeader,
  TextEditorFooter,
  TextEditorInput,
  TextEditorSavingIndicator
} from "../../SharedComponents/textEditor";
import EpubView from "../../reader/EpubView/EpubView";
import RenderEditorContent from "../../SharedComponents/RenderEditorContent";
import PeerReviewReflectionModal from "./PeerReviewReflectionModal";
import ToggleBtnConstructor from "../../SharedComponents/buttons/toggleBtnConstructor";

// Material UI
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Button, IconButton } from "@mui/material";
import ScrollBox from "../../SharedComponents/ScrollBox";
import TooltipWithIntl from "../../SharedComponents/tooltip/TooltipWithIntl";
import useCreatePortal from "../../../hooks/useCreatePortal";
import { PdfControls } from "../../menus/PdfControls";
import HeaderToolbar from "../../menus/HeaderToolbar";
import { pdfThumbnailsIconClicked } from "../../../redux/layoutSlice";

// Styles
const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    columnGap: theme.spacing(2)
  },

  textContainer: {
    gridColumnEnd: "span 8",
    height: "calc(100vh - 120px)",
    position: "Relative"
  },

  reflectionContainer: {
    display: "flex",
    gridColumnEnd: "span 4",
    height: "calc(100vh - 120px)",
    "& $reflectionHeader": {
      paddingBlockStart: theme.spacing(4.5)
    }
  },

  reflectionContainerExpanded: {
    gridColumn: "3 / 11",
    paddingInline: "0"
  },

  editorActive: {
    paddingBlock: theme.spacing(3),
    paddingInlineEnd: theme.spacing(5),
    "& $reflectionHeader": {
      borderBottom: "1px solid",
      borderBottomColor: theme.palette.grey.main,
      paddingBlockStart: theme.spacing(1.5),
      paddingInline: theme.spacing(3),
      height: theme.spacing(9)
    }
  },

  reflection: {
    color: theme.palette.text.primary,
    position: "relative",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    borderRadius: "4px",
    lineHeight: "2"
  },

  reflectionHeader: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    color: theme.palette.text.blue,
    fontSize: "12px",
    fontWeight: 600,
    textTransform: "uppercase",
    letterSpacing: "1px",
    paddingBlockEnd: theme.spacing(1.5)
  },
  paddedScrollBox: {
    paddingInlineEnd: theme.spacing(5),
    paddingInlineStart: 0
  },
  userInput: {
    fontFamily: '"Crimson Pro", "Frank Ruhl Libre"',
    lineHeight: 1.5,
    fontSize: "16px",
    height: `calc(100% - ${theme.spacing(9)})`
  },

  userInputCollapsed: {
    fontSize: "16px"
  },

  userInputExpanded: {
    fontSize: "22px"
  },
  thumbnailContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "250px",
    gridColumnEnd: "span 1",
    position: "relative"
  },
  textContainerWithThumbnails: {
    gridColumnEnd: "span 11"
  },
  lightIcon: {
    color: "#FFFFFF !important",
    backgroundColor: "transparent !important"
  },
  draftStatus: { color: theme.palette.text.secondary }
}));
export default function PeerReviewReflection({
  onRenditionCreated,
  saveTextLocation,
  textBookmark
}) {
  // Hooks
  const theme = useGetTheme();

  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const history = useHistory();
  const { rendition } = useRendition();
  const { submission_id } = useQuery();
  const ActionButtonPortal = useCreatePortal(
    document && document.getElementById("global-action-btn")
  );
  const ThumbnailsPortal = useCreatePortal(
    document && document.getElementById("global-thumbnails-btn")
  );
  const PdfControlPortal = useCreatePortal(
    document && document.getElementById("global-pdfControl-btn")
  );
  const HeaderToolbarPortal = useCreatePortal(
    document && document.getElementById("global-headerToolBar-btn")
  );
  const { ref, width } = useResizeDetector({
    handleHeight: false,
    skipOnMount: true,
    onResize: () => {
      // setTimeout is fixing an issue where the resize is not setting the correct size when starting with the sidebar closed
      setTimeout(() => {
        if (rendition) {
          rendition.resize();
        }
      }, 100);
    }
  });

  // Redux state
  const submission = useSelector((state) =>
    selectSubmission(state, Number(submission_id))
  );
  const task = useSelector((state) => selectTask(state, submission.task_id));
  const course = useSelector((state) =>
    selectCourseByTaskId(state, submission.task_id)
  );
  const question = useSelector((state) =>
    selectPeerReviewQuestion(state, submission.task_id)
  );
  const text = useSelector(selectCurrentText);
  const reflection = useSelector((state) =>
    selectPeerReviewReflection(state, Number(submission_id))
  );

  const currentUser = useSelector((state) => state.user.auth);
  const darkMode = useSelector((state) => selectDarkMode(state));
  const fontSizeValue = useSelector((state) => state.user.userProfile.fontSize);
  const fontSize = useSelector((state) => state.user.fontSizeOptions);
  const isPdf = useSelector(isPdfSelector);
  const zoom = useSelector(selectZoom);
  const isThumbnailsInView = useSelector((state) => selectIsThumbnails(state));

  // Ephemeral state
  const [zenMode, setZenMode] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  // Variables
  const isActive =
    submission.status === "Pending" || submission.status === "Active";
  const SAVING = "Saving...";
  const SAVED = "Saved";
  // Behavior

  // set breadcrumbs
  const author = text.author;
  const text_title = text.name;
  const course_name = course.name;
  const taskName = task.title;

  useEffect(() => {
    if (isThumbnailsInView) dispatch(pdfThumbnailsIconClicked());
  }, []);

  useEffect(() => {
    let parts = [];
    task.id &&
      parts.push({
        text: intl.formatMessage({
          id: "appBar.tasks",
          defaultMessage: "Assignments"
        }),
        url: "/tasks",
        brightText: !!task.id
      });
    parts.push({
      text: course_name,
      url: `/tasks?course_id=${course.id}`,
      brightText: !!task.id
    });
    taskName &&
      parts.push({
        text: taskName,
        brightText: !!task.id
      });
    parts.push({
      url: `/tasks?course_id=${course.id}`,
      text: author + " - " + text_title,
      brightText: !!task.id
    });
    dispatch(
      setBreadcrumbs({
        breadcrumbs: parts,
        blue: true,
        showTextMenu: true
      })
    );
  }, [
    author,
    course.id,
    course_name,
    dispatch,
    intl,
    task.id,
    taskName,
    text_title
  ]);

  function createReflection(content) {
    const { richText, plainText, wordCount } = content;
    peerReviewAPI
      .createReflection({
        content: plainText,
        rich_text: richText,
        word_count: wordCount,
        course_id: course.id,
        task_id: task.id,
        submission_id: submission.id,
        text_id: text.id,
        interaction_type: INTERACTION_TYPES.ANSWER,
        interaction_subtype: INTERACTION_SUBTYPES.COMMENT
      })
      .then(({ data }) => {
        const { success } = data;
      });
  }
  function updateComment(interaction, content) {
    const { richText, plainText, wordCount } = content;
    dispatch(
      updateInteraction({
        disableRollback: true, // we don't want to to erase users input if the request fails
        interaction: interaction,
        update: {
          content: plainText,
          rich_text: richText,
          word_count: wordCount
        }
      })
    );
  }

  const handleClick = () => {
    if (zenMode === false) saveTextLocation();
    setZenMode(!zenMode);
  };

  useEffect(() => {
    rendition && rendition.themes.default(fontSize[fontSizeValue]);
  }, [fontSize, fontSizeValue, rendition]);

  function shouldShowEditorBySubmissionOwnerAndEditorState(
    currentUser,
    submission
  ) {
    const current_user_is_ownwer = currentUser.uid === submission.owner;
    if (current_user_is_ownwer && isActive) {
      return (
        <>
          <TextEditor content={reflection.rich_text}>
            <TextEditorHeader title={PEER_REVIEW_TASK.REFLECTION.MY_REFLECTION}>
              <TooltipWithIntl
                defaultMessage={
                  zenMode
                    ? PEER_REVIEW_TASK.ZEN_MODE.EXIT_FULL_SCREEN
                    : PEER_REVIEW_TASK.ZEN_MODE.FULL_SCREEN
                }
                intlStringId={
                  zenMode
                    ? PEER_REVIEW_TASK.ZEN_MODE.ICON_EXIT
                    : PEER_REVIEW_TASK.ZEN_MODE.ICON_FULL
                }
                placement="bottom">
                <IconButton
                  aria-label={
                    zenMode
                      ? PEER_REVIEW_TASK.ZEN_MODE.EXIT_FULL_SCREEN
                      : PEER_REVIEW_TASK.ZEN_MODE.FULL_SCREEN
                  }
                  onClick={handleClick}
                  size="large">
                  {zenMode ? <FullscreenExitIcon /> : <FullscreenIcon />}
                </IconButton>
              </TooltipWithIntl>
            </TextEditorHeader>

            <TextEditorInput
              fontSize={
                zenMode
                  ? PEER_REVIEW_TASK.SIZE.LARGE
                  : PEER_REVIEW_TASK.SIZE.SMALL
              }
              onChange={(content) =>
                isEmpty(reflection)
                  ? createReflection(content)
                  : updateComment(reflection, content)
              }
              placeholder={intl.formatMessage({
                id: "task.open.placeholder.reflection",
                defaultMessage: "Type your reflection here"
              })}
            />

            <TextEditorFooter wordLimit={question.wordLimit}>
              <TextEditorSavingIndicator status={reflection.status} />
            </TextEditorFooter>
          </TextEditor>
        </>
      );
    }
  }
  // render
  return (
    <>
      <PeerReviewReflectionModal
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        reflection={reflection}
      />
      {isPdf && (
        <>
          <ThumbnailsPortal>
            <ToggleBtnConstructor
              type={SECONDARY_SIDEBAR_ACTION_BUTTONS.THUBMNAILS}
              handleBtnAction={handleThumbnailPanel}
              secondarySideBarMode={isThumbnailsInView}
            />
          </ThumbnailsPortal>
          <PdfControlPortal>
            <PdfControls />
          </PdfControlPortal>
        </>
      )}
      <HeaderToolbarPortal>
        <HeaderToolbar />
      </HeaderToolbarPortal>
      <ActionButtonPortal>
        {isActive && (
          <Button
            onClick={() => setDialogOpen(true)}
            data-test-id="submit-task-button"
            size={PEER_REVIEW_TASK.SIZE.SMALL}>
            {intl.formatMessage({
              id: "task.share",
              defaultMessage: "Share"
            })}
          </Button>
        )}

        {!isActive && (
          <Button
            onClick={() => {
              history.push(`/tasks?course_id=${course.id}`);
            }}
            data-test-id="submit-task-button"
            size={PEER_REVIEW_TASK.SIZE.SMALL}>
            {intl.formatMessage({
              id: "gr.confirm.btn",
              defaultMessage: "Done"
            })}
          </Button>
        )}
      </ActionButtonPortal>

      <Box className={classes.container}>
        {!zenMode && (
          <Box
            className={clsx(
              classes.textContainer,
              isThumbnailsInView && classes.textContainerWithThumbnails
            )}
            ref={ref}
            style={{
              marginInlineStart: isPdf ? theme.spacing(0) : theme.spacing(5)
            }}>
            {isPdf ? (
              <PdfView
                zoom={zoom}
                handleTextSelected={() => {}}
                isVisible={true}
                url={text.url}
              />
            ) : (
              <EpubView
                fullSize={false}
                fontSize={fontSize[fontSizeValue]}
                epubOptions={{ flow: "scrolled-doc" }}
                darkMode={darkMode}
                textDirection={text.text_language === "he" ? "rtl" : "ltr"}
                url={text.url}
                location={textBookmark}
                defaultLocation={text.file_location}
                onRenditionCreated={onRenditionCreated}
                text_id={Number(text.id)}
                bodyClassName="scrollBox"
              />
            )}
          </Box>
        )}

        {isThumbnailsInView && !zenMode ? (
          <Box className={isThumbnailsInView && classes.thumbnailContainer}>
            <PDFThumbBar />
          </Box>
        ) : (
          <Box
            className={clsx(
              classes.reflectionContainer,
              isActive && classes.editorActive,
              zenMode && classes.reflectionContainerExpanded
            )}>
            {shouldShowEditorBySubmissionOwnerAndEditorState(
              currentUser,
              submission
            )}

            {!isActive && (
              <ScrollBox className={classes.paddedScrollBox}>
                <Box className={classes.reflectionHeader}>
                  <Box>{PEER_REVIEW_TASK.REFLECTION.MY_REFLECTION}</Box>
                </Box>
                <Box className={classes.userInput}>
                  <RenderEditorContent>
                    {reflection.rich_text}
                  </RenderEditorContent>
                </Box>
              </ScrollBox>
            )}
          </Box>
        )}
      </Box>
    </>
  );
}

PeerReviewReflection.propTypes = {
  rendition: PropTypes.object,
  onRenditionCreated: PropTypes.func,
  saveTextLocation: PropTypes.func,
  textBookmark: PropTypes.string
};
