// Dependancies

import React from "react";

// Components

// Mui
import { Menu } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

// styles
const useStyles = makeStyles((theme) => ({}));

const ChartFilterMenu = (props, children) => {
  // hooks
  const classes = useStyles();
  // const anchorEl = useRef(null);

  // Ephemeral state

  //Derived state
  const ITEM_HEIGHT = 48;
  return (
    <Menu
      keepMounted
      elevation={0}
      // getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      PaperProps={{
        style: {
          maxHeight: ITEM_HEIGHT * 4.5,
          width: "1000px"
        }
      }}
      {...props}>
      {children}
    </Menu>
  );
};

export default ChartFilterMenu;
