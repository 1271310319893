// Dependencies
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Divider,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import ToggleChips from "../../SharedComponents/ToggleChips";

//Styles
const useStyles = makeStyles((theme) => ({
  section: {
    marginBottom: "40px"
  },
  divider: {
    backgroundColor: "#dedede",
    marginBottom: "20px",
    marginTop: "8px"
  },
  popover: {
    left: "20px"
  },
  coach: {
    display: "flex",
    justifyContent: "flex-start"
  }
}));

function AssignmentAssistantCard({
  readOnly,
  selectedDifficulty,
  setSelectedDifficulty,
  readingFocus,
  setReadingFocus,
  selectedQuestionKind,
  setSelectedQuestionKind,
  generateAiAssignment,
  botGenerating,
  aiQuestions,
  botConfigDirty,
  questionRegenerating,
  visibleQuestions
}) {
  //Hooks
  const intl = useIntl();
  const classes = useStyles();

  // State
  const [showRegeneratingDialog, setShowRegeneratingDialog] = useState(false);

  // Variables
  const questionKindOptions = [
    {
      key: "Comprehension",
      label: intl.formatMessage({
        id: "task.create.assignment.bot.questionKinds.comprehension",
        defaultMessage: "Comprehension"
      })
    },
    {
      key: "Analysis",
      label: intl.formatMessage({
        id: "task.create.assignment.bot.questionKinds.analysis",
        defaultMessage: "Analysis"
      })
    },
    {
      key: "Interpretation",
      label: intl.formatMessage({
        id: "task.create.assignment.bot.questionKinds.interpretation",
        defaultMessage: "Interpretation"
      })
    },
    {
      key: "Comparative",
      label: intl.formatMessage({
        id: "task.create.assignment.bot.questionKinds.comparative",
        defaultMessage: "Comparative"
      })
    },
    {
      key: "Critique",
      label: intl.formatMessage({
        id: "task.create.assignment.bot.questionKinds.critique",
        defaultMessage: "Critique"
      })
    }
  ];
  const difficultyOptions = [
    { key: "1", label: "1" },
    { key: "2", label: "2" },
    { key: "3", label: "3" },
    { key: "4", label: "4" },
    { key: "5", label: "5" }
  ];

  const renderRegeneratingDialog = () => {
    return (
      <Dialog
        open={showRegeneratingDialog}
        onClose={() => setShowRegeneratingDialog(false)}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="sm">
        <ClearIcon
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            cursor: "pointer"
          }}
          onClick={() => setShowRegeneratingDialog(false)}
        />
        <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
          {intl.formatMessage({
            id: "task.create.assignment.bot.regenerateQuestionsDialogTitle",
            defaultMessage: "Update Questions"
          })}
        </DialogTitle>
        <DialogContent className={clsx(classes.dialog, classes.ltr)}>
          <Typography>
            {intl.formatMessage({
              id: "task.create.assignment.bot.regenerateQuestionsDialogContent",
              defaultMessage:
                "Do you want to add new questions to the list or replace the current questions?"
            })}
          </Typography>
        </DialogContent>
        <DialogActions className={classes.btns}>
          <Button
            className={clsx(classes.cancelBtn, classes.cancelBtnLtr)}
            onClick={() => {
              setShowRegeneratingDialog(false);
              generateAiAssignment(true);
            }}
            color="primary">
            {intl.formatMessage({
              id: "task.create.assignment.bot.regenerateQuestionsDialogReplace",
              defaultMessage: "Replace"
            })}
          </Button>
          <Button
            className={clsx(classes.yesBtn, classes.yesBtnLtr)}
            onClick={() => {
              setShowRegeneratingDialog(false);
              generateAiAssignment(false);
            }}
            color="primary">
            {intl.formatMessage({
              id: "task.create.assignment.bot.regenerateQuestionsDialogAdd",
              defaultMessage: "Add"
            })}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  //Render
  return (
    <Box sx={{ opacity: questionRegenerating ? 0.6 : 1 }}>
      <Box className={classes.section}>
        <Box sx={{ display: "flex" }}>
          <Typography variant="body1">
            {intl.formatMessage({
              id: "task.create.assignment.bot.questionKind",
              defaultMessage: "What kind of questions are you looking for?"
            })}
          </Typography>
          &nbsp;
          <Typography variant="body1" sx={{ color: "gray" }}>
            (
            {intl
              .formatMessage({
                id: "general.optional",
                defaultMessage: "Optional"
              })
              .toLowerCase()}
            )
          </Typography>
        </Box>
        <Divider className={classes.divider} />
        <Box className={clsx(classes.coach, classes.sectionContent)}>
          <ToggleChips
            options={questionKindOptions}
            selectedChips={selectedQuestionKind}
            setSelectedChips={setSelectedQuestionKind}
            readOnly={readOnly || botGenerating}
            multi
          />
        </Box>
      </Box>
      <Box className={classes.section}>
        <Box sx={{ display: "flex" }}>
          <Typography variant="body1">
            {intl.formatMessage({
              id: "task.create.assignment.bot.readingFocus",
              defaultMessage: "What’s the focus of this reading?"
            })}
          </Typography>
          &nbsp;
          <Typography variant="body1" sx={{ color: "gray" }}>
            (
            {intl
              .formatMessage({
                id: "general.optional",
                defaultMessage: "Optional"
              })
              .toLowerCase()}
            )
          </Typography>
        </Box>
        <Divider className={classes.divider} />
        <Box className={clsx(classes.coach, classes.sectionContent)}>
          <TextField
            multiline
            maxRows={4}
            disabled={readOnly || botGenerating}
            placeholder={intl.formatMessage({
              id: "task.create.assignment.bot.readingFocusPlaceholder",
              defaultMessage:
                "Tell us about the specific topics you want your students to focus on (optional)..."
            })}
            value={readingFocus}
            onChange={(event) => setReadingFocus(event.target.value)}
          />
        </Box>
      </Box>
      <Box className={classes.section}>
        <Box sx={{ display: "flex" }}>
          <Typography variant="body1">
            {intl.formatMessage({
              id: "task.create.assignment.bot.difficulty",
              defaultMessage: "Level of difficulty"
            })}
          </Typography>
          &nbsp;
          <Typography variant="body1" sx={{ color: "gray" }}>
            (
            {intl
              .formatMessage({
                id: "general.optional",
                defaultMessage: "optional"
              })
              .toLowerCase()}
            )
          </Typography>
        </Box>
        <Divider className={classes.divider} />
        <Box className={clsx(classes.coach, classes.sectionContent)}>
          <ToggleChips
            options={difficultyOptions}
            selectedChips={selectedDifficulty}
            setSelectedChips={setSelectedDifficulty}
            spacing="16px"
            readOnly={readOnly || botGenerating}
          />
        </Box>
      </Box>
      {!readOnly && (
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            onClick={() => {
              if (!visibleQuestions.length) return generateAiAssignment(true);
              else
                aiQuestions.length === 0
                  ? generateAiAssignment(false)
                  : setShowRegeneratingDialog(true);
            }}
            sx={{ minHeight: "36px", minWidth: "175px", display: "flex" }}
            disabled={(aiQuestions.length && !botConfigDirty) || botGenerating}>
            {intl.formatMessage({
              id: "task.create.assignment.bot.generateAssignmentButton",
              defaultMessage: "Generate Assignment"
            })}
          </Button>
          {showRegeneratingDialog && renderRegeneratingDialog()}
        </Box>
      )}
    </Box>
  );
}

AssignmentAssistantCard.propTypes = {
  readOnly: PropTypes.bool,
  selectedDifficulty: PropTypes.array,
  setSelectedDifficulty: PropTypes.func,
  readingFocus: PropTypes.string,
  setReadingFocus: PropTypes.func,
  selectedQuestionKind: PropTypes.array,
  setSelectedQuestionKind: PropTypes.func,
  generateAiAssignment: PropTypes.func,
  botGenerating: PropTypes.bool,
  aiQuestions: PropTypes.array,
  botConfigDirty: PropTypes.bool,
  questionRegenerating: PropTypes.bool
};

export default AssignmentAssistantCard;
