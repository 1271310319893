import React from "react";
import { TooltipWithIntl } from "../SharedComponents";
import { IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { COMMENT_PANEL_VIEW } from "../../consts";
import { setCommentPanelState } from "../../redux/realtimeInteractionsSlice";
import DirectionalArrowIcon from "../SharedComponents/DirectionalArrowIcon";
import { setAnnotatorMode } from "../../redux/readerActionsSlice";
import {
  removeTempHighlight,
  setSelectedInteractionId
} from "../../redux/interactionsSlice";
import { setSelectedQuestion } from "../../redux/grSlice";
import { selectSelectedHighlight } from "../../redux/highlightSlice";

const NavigateBackButton = ({ className }) => {
  // Hooks
  const dispatch = useDispatch();

  // Redux state
  const action = useSelector(
    (state) => state.readerActions.persistentActionState.actionBar
  );
  const commentPanelView = useSelector(
    (state) => state.realtimeInteractions.commentPanelState
  );

  const detachedComment = useSelector(
    (state) => state.readerActions.detachedComment
  );
  const questions = useSelector((state) => state.interactions.questions || []);
  const activeQuestion = questions.find((q) => q.order)
    ? questions.filter((question) => question.order === 0)[0]
    : questions[0];
  const selectedHighlight = useSelector((state) =>
    selectSelectedHighlight(state)
  );

  // Ephemeral state

  const navigateToThreadsView = () => {
    if (commentPanelView !== COMMENT_PANEL_VIEW.ALL_THREADS)
      dispatch(setCommentPanelState(COMMENT_PANEL_VIEW.ALL_THREADS));
    // reset readerActions state based on view
    if (detachedComment) {
      if (["poc", ""].includes(action)) dispatch(setAnnotatorMode(action));
      else dispatch(setAnnotatorMode("highlight"));
      if (activeQuestion) {
        dispatch(setSelectedInteractionId(activeQuestion.id));
        dispatch(setSelectedQuestion(activeQuestion.id));
      }
    }
    if (selectedHighlight && !selectedHighlight.interaction_id) {
      dispatch(removeTempHighlight(selectedHighlight.id));
    }
  };

  return (
    <TooltipWithIntl
      defaultMessage="Back to Threads"
      intlStringId={"stepBack"}
      placement="bottom">
      <IconButton
        aria-label={"Back to Threads"}
        className={className}
        onClick={navigateToThreadsView}
        size="large">
        <DirectionalArrowIcon fontSize="small" />
      </IconButton>
    </TooltipWithIntl>
  );
};

export default NavigateBackButton;
