// Dependencies
import React, { useEffect, useState } from "react";
import { firebaseFunctions, httpCallables } from "../../firebase";
import { useQuery } from "../../hooks";
import clsx from "clsx";

// Components
import PangeaSpinner from "../SharedComponents/PangeaSpinner";
import ScrollBox from "../SharedComponents/ScrollBox";
import CourseTasks from "./CourseTasks";
import CourseTexts from "./CourseTexts";
import CourseUsers from "./CourseUsers";

import makeStyles from "@mui/styles/makeStyles";
import { Box, Typography } from "@mui/material";
import CourseFeatures from "./CourseFeatures";
import CourseRollOver from "./CourseRollOver";
import CourseInstitutionSelection from "./CourseInstitutionSelection";
import { useSelector } from "react-redux";

// Styles
const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    columnGap: theme.spacing(2),
    padding: "30px"
  },
  courseDetails: {
    gridColumnEnd: "span 12",
    marginBlock: theme.spacing(5)
  },
  title: {
    gridColumnEnd: "span 2",
    height: "fit-content",
    paddingBlock: theme.spacing(2),
    marginBlockStart: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`
  },
  table: {
    gridColumnEnd: "span 10",
    height: "fit-content",
    marginBlockStart: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
    paddingBlock: theme.spacing(2)
  },
  duplicateCourseAction: {
    display: "flex",
    flexFlow: "row nowrap"
  }
}));

function Course() {
  // Hooks
  const classes = useStyles();
  const { course_id } = useQuery();
  const currentUser = useSelector((state) => state.user.auth);

  // Ephemeral State
  const [status, setStatus] = useState("loading");
  const [courseName, setCourseName] = useState(null);
  const [academicYear, setAcademicYear] = useState(null);
  const [lmsClient, setLmsClient] = useState(null);
  const [lmsCourseId, setLmsCourseId] = useState(null);
  const [courseTimezone, setCourseTimezone] = useState(null);
  const [courseInstitution, setCourseInstitution] = useState(null);
  const [institutions, setInstitutions] = useState([]);

  // Behavior
  useEffect(() => {
    if (status === "idle") return;
    setStatus("loading");
    httpCallables
      .adminFunctions({ func_name: "getCourseById", courseId: course_id })
      .then((response) => {
        const { data } = response;
        const {
          academic_year,
          lms_client,
          lms_course_id,
          name,
          timezone,
          institution_id
        } = data;
        setCourseName(name);
        setAcademicYear(academic_year);
        setLmsClient(lms_client);
        setLmsCourseId(lms_course_id);
        setCourseTimezone(timezone);
        setStatus("idle");
        setCourseInstitution(institution_id);
      });
  }, [course_id, status]);
  useEffect(() => {
    httpCallables
      .institutionsFunctions({ func_name: "getAllInstitutions" })
      .then(({ data }) => {
        setInstitutions(data);
      });
  }, []);

  if (status === "loading") {
    return <PangeaSpinner />;
  } else {
    return (
      <ScrollBox className={classes.container}>
        <Box className={classes.courseDetails}>
          <Typography variant="h2">{courseName}</Typography>
          <Typography variant="h5">{academicYear}</Typography>
          {courseTimezone && (
            <Typography variant="h5">
              {" "}
              {`Time zone: ${courseTimezone}`}
            </Typography>
          )}
          {lmsClient && (
            <Typography variant="h5"> {`LMS: ${lmsClient}`} </Typography>
          )}
          {lmsCourseId && (
            <Typography variant="h5">
              {`LMS Course ID: ${lmsCourseId}`}{" "}
            </Typography>
          )}
        </Box>
        <Box className={classes.title}>
          <Typography variant="h5">Course Institution:</Typography>
        </Box>
        <Box className={clsx(classes.table, classes.duplicateCourseAction)}>
          <CourseInstitutionSelection
            institutions={institutions}
            course_id={Number(course_id)}
            courseInstitution={courseInstitution}
            setCourseInstitution={setCourseInstitution}
          />
        </Box>
        <Box className={classes.title}>
          <Typography variant="h5">Users</Typography>
        </Box>
        <Box className={classes.table}>
          <CourseUsers courseId={course_id} />
        </Box>
        <Box className={classes.title}>
          <Typography variant="h5">Texts</Typography>
        </Box>
        <Box className={classes.table}>
          <CourseTexts courseId={course_id} />
        </Box>
        <Box className={classes.title}>
          <Typography variant="h5">Tasks</Typography>
        </Box>
        <Box className={classes.table}>
          <CourseTasks courseId={course_id} />
        </Box>
        {currentUser.isAdmin && (
          <>
            <Box className={classes.title}>
              <Typography variant="h5">Features</Typography>
            </Box>
            <Box className={classes.table}>
              <CourseFeatures courseId={course_id} />
            </Box>
            <Box className={classes.title}>
              <Typography variant="h5">Duplicate Course Data</Typography>
            </Box>
            <Box className={clsx(classes.table, classes.duplicateCourseAction)}>
              <CourseRollOver
                status={status}
                setStatus={setStatus}
                course_id={course_id}
              />
            </Box>
          </>
        )}
      </ScrollBox>
    );
  }
}

export default Course;
