import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";

import { Stepper, Step, StepLabel } from "@mui/material";
import { useSelector } from "react-redux";
import { selectDarkMode } from "../../../redux/firestoreSelectors";

const useStyles = makeStyles((theme) => ({
  right: {
    textAlign: "right"
  },
  left: {
    textAlign: "left"
  },
  tasksHeader: {
    marginTop: "40px",
    width: "100%",
    position: "relative"
  },
  stepper: {
    marginTop: "40px",
    width: "100%",
    paddingLeft: "0px",
    paddingRight: "0px"
  },
  btnContainer: {
    marginTop: "40px",
    marginBottom: "40px"
  },
  btn: {
    borderRadius: "8px",
    marginLeft: "4px",
    marginRight: "4px"
  },
  stepRtl: {
    "& .MuiStepLabel-iconContainer": {
      paddingLeft: "8px",
      paddingRight: "0px"
    }
  },
  stepFirstLtr: {
    paddingLeft: "0px"
  },
  stepFirstRtl: {
    paddingRight: "0px"
  },
  stepLast: {
    paddingRight: "0px"
  },
  step: {
    cursor: "pointer !important"
  },
  cancelBtn: {
    position: "absolute"
  },
  cancelBtnRtl: {
    left: "0px"
  },
  cancelBtnLtr: {
    right: "0px"
  }
}));

export default function TaskStepper(props) {
  const classes = useStyles();
  const darkMode = useSelector((state) => selectDarkMode(state));

  return (
    <Stepper
      className={classes.stepper}
      activeStep={props.activeStep}
      nonLinear={props.viewOnly}>
      {props.steps.map((label, index) => {
        return (
          <Step
            key={index}
            className={clsx(
              classes.step,
              index === 0 && !props.rtl && classes.stepFirstLtr,
              classes.step,
              index === 0 && props.rtl && classes.stepFirstRtl,
              index === props.steps.length - 1 && classes.stepLast,
              props.rtl && classes.stepRtl
            )}
            name="new-task-step"
            onClick={() =>
              (index <= props.activeStep || props.viewOnly) &&
              props.setActiveStep(index)
            }>
            <StepLabel
              StepIconProps={{
                sx: {
                  ".MuiStepIcon-text": { fill: darkMode ? "black" : "white" }
                }
              }}>
              {label}
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
}
