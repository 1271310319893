import React, { useEffect, useState, useCallback } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Chip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";

const useStyles = makeStyles((theme) => ({
  pending: {
    backgroundColor: theme.palette.grey[300]
  },
  teacher: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  Low: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  },
  Moderate: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText
  },
  Extraordinary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  High: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  risk: {
    backgroundColor: "rgba(211, 47, 47, 0.12)",
    fontWeight: 500,
    color: theme.palette.text.primary,
    paddingInline: theme.spacing(0.5)
  }
}));

const EngagementChip = ({
  usersBySubmissionStatus,
  timeSpentOnAssignmentData,
  userId,
  maxPagePerTextData,
  rawSubmissions,
  setStudentsAtRiskIds
}) => {
  const classes = useStyles();

  const [label, setLabel] = useState("");
  const [style, setStyle] = useState(null);
  const [userSubmittedAssignmentIds, setUserSubmittedAssignmentIds] = useState(
    []
  );
  const [userTimeOnAssignment, setUserTimeOnAssignment] = useState({});
  const [readingScoreForUser, setReadingScoreForUser] = useState(0);
  const [averageReadingTime, setAverageReadingTime] = useState(0);

  const getWordCountPerText = useCallback((taskId, tasksData) => {
    const task = tasksData.find((task) => task.task_id === taskId);
    return task ? task.max_page * 300 : 1;
  }, []);

  const sumDurationsToSecondsByTaskId = useCallback((timeMetrics) => {
    const taskDurations = {};

    for (const metricType in timeMetrics) {
      timeMetrics[metricType].forEach((entry) => {
        taskDurations[metricType] = taskDurations[metricType] || {};
        taskDurations[metricType][entry.task_id] =
          (taskDurations[metricType][entry.task_id] || 0) +
          entry.duration / 1000;
      });
    }

    return taskDurations;
  }, []);

  const calculateScore = useCallback(
    (wordCount, readingTime, secondsPerPage) => {
      const requiredTimePer300Words = secondsPerPage; // Assuming 180 seconds (3 minutes) per page
      if (readingTime < requiredTimePer300Words) {
        return 0; // If reading time is less than the required time per page, score is 0
      }

      const ratio = readingTime / (wordCount / requiredTimePer300Words);
      return Math.min(1, Math.round(ratio * 10) / 10); // Round to nearest 0.1, but maximum score is 1
    },
    []
  );
  const getScoresForTasks = useCallback(() => {
    const scores = userSubmittedAssignmentIds.map((taskId) => {
      const wordCount = getWordCountPerText(taskId, maxPagePerTextData);
      const readingTime = userTimeOnAssignment.HIGHLIGHT_TIME?.[taskId] || 0;
      return calculateScore(wordCount, readingTime, 180);
    });

    const sumOfScores = scores.reduce((total, value) => total + value, 0);
    return sumOfScores / userSubmittedAssignmentIds.length;
  }, [
    userSubmittedAssignmentIds,
    maxPagePerTextData,
    userTimeOnAssignment,
    getWordCountPerText,
    calculateScore
  ]);

  const filterByUserId = useCallback((data, userId) => {
    const filteredData = {};

    for (const category in data) {
      filteredData[category] = data[category].filter(
        (item) => item.user_id === userId
      );
    }

    return filteredData;
  }, []);

  const calculateEngagement = useCallback(
    (user) => {
      const { submitted, missed, pending, late } = user.submissionStatusCount;
      const totalSubmissions = submitted + missed + late;

      const submittedOnTimePercentage = (submitted / totalSubmissions) * 100;
      const submittedPercentage = ((submitted + late) / totalSubmissions) * 100;

      let engagementLevel = "Low";

      if (submittedOnTimePercentage >= 90 && readingScoreForUser >= 0.9) {
        engagementLevel = "Extraordinary";
      } else if (submittedPercentage >= 75 && readingScoreForUser >= 0.75) {
        engagementLevel = "High";
      } else if (submittedPercentage >= 50 && readingScoreForUser >= 0.4) {
        engagementLevel = "Moderate";
      }

      if (submittedPercentage < 20) {
        setStudentsAtRiskIds((prev) => {
          const existingUser = prev.find((item) => item.user_id === user.id);

          if (!existingUser) {
            const missedSubmissionsPercentage =
              (missed / totalSubmissions) * 100;

            const warning = "Time spent: below average";

            return [
              ...prev,
              {
                user_id: user.id,
                percentage: missedSubmissionsPercentage,
                warning
              }
            ];
          }

          return prev;
        });

        engagementLevel = "risk";
      }

      return engagementLevel;
    },
    [readingScoreForUser, setStudentsAtRiskIds, userTimeOnAssignment]
  );

  useEffect(() => {
    if (!usersBySubmissionStatus || usersBySubmissionStatus.length === 0)
      return;

    const submittedAssignmentIds = rawSubmissions
      .filter((submission) => submission.submission_date)
      .map((submission) => submission.task_id);

    setUserSubmittedAssignmentIds(submittedAssignmentIds);

    const filteredTimeOnAssignment = filterByUserId(
      timeSpentOnAssignmentData,
      userId
    );
    const summedDurations = sumDurationsToSecondsByTaskId(
      filteredTimeOnAssignment
    );
    setUserTimeOnAssignment(summedDurations);

    const engagementCategory = calculateEngagement(usersBySubmissionStatus[0]);
    setStyle(engagementCategory);
    setLabel(engagementCategory);
  }, [
    usersBySubmissionStatus,
    timeSpentOnAssignmentData,
    userId,
    rawSubmissions,
    calculateEngagement,
    filterByUserId,
    sumDurationsToSecondsByTaskId
  ]);

  useEffect(() => {
    setReadingScoreForUser(getScoresForTasks());
  }, [getScoresForTasks]);

  return (
    <Chip
      className={clsx(classes.chip, style && classes[style])}
      label={label === "risk" ? "Student at risk" : label}
      size="small"
      icon={
        label === "risk" ? (
          <FlagOutlinedIcon style={{ color: "rgba(211, 47, 47, 1)" }} />
        ) : (
          <></>
        )
      }
      clickable={false}
    />
  );
};

EngagementChip.propTypes = {
  usersBySubmissionStatus: PropTypes.array.isRequired,
  timeSpentOnAssignmentData: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
  maxPagePerTextData: PropTypes.array.isRequired,
  rawSubmissions: PropTypes.array.isRequired,
  setStudentsAtRiskIds: PropTypes.func.isRequired
};

export default EngagementChip;
