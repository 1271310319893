// Dependencies
import React, { useState } from "react";
import { useIntl } from "react-intl"; // We don't need both, use only the hook
import { useHistory } from "react-router-dom";
import { isEmpty } from "lodash";
import { useQuery } from "../../hooks";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { selectSubmission } from "../../redux/tasksSlice";
import { selectCourseByTaskId } from "../../redux/coursesSlice";

// Components
import SubmitTaskModal from "../Tasks/TaskAnswer/SubmitTaskModal";

import { Button } from "@mui/material";
import useCreatePortal from "../../hooks/useCreatePortal";

export default function TasksButton() {
  //Hooks
  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { submission_id } = useQuery();

  const ActionButtonPortal = useCreatePortal(
    document && document.getElementById("global-action-btn")
  );

  //Redux State
  const submission = useSelector((state) =>
    selectSubmission(state, Number(submission_id))
  );
  const actionBar = useSelector(
    (state) => state.readerActions.persistentActionState.actionBar
  );
  const course = useSelector((state) =>
    selectCourseByTaskId(state, submission.task_id)
  );

  // Ephemeral State
  const [dialogOpen, setDialogOpen] = useState(false);

  //Deravied state
  const locked = submission?.status === "Submitted";
  const isTeacher = course.course_role === "Teacher";
  const isTaskActive =
    submission.status === "Pending" || submission.status === "Active";
  const showSubmit = isTaskActive;
  const showDone = !isTaskActive;

  return (
    <>
      <ActionButtonPortal>
        {showSubmit && (
          <Button
            onClick={() => setDialogOpen(true)}
            data-test-id="submit-task-button"
            size="small">
            {intl.formatMessage({
              id: "task.submission.submit",
              defaultMessage: "Submit"
            })}
          </Button>
        )}
        {showDone && (
          <Button
            onClick={() => {
              history.push(`/tasks?course_id=${course.id}`);
            }}
            data-test-id="submit-task-button"
            size="small">
            {intl.formatMessage({
              id: "gr.confirm.btn",
              defaultMessage: "Done"
            })}
          </Button>
        )}
      </ActionButtonPortal>
      {!isEmpty(submission) && (
        <SubmitTaskModal
          taskId={submission.task_id}
          modalVisible={dialogOpen}
          setModalVisible={setDialogOpen}
        />
      )}
    </>
  );
}

TasksButton.propTypes = {};
