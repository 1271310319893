import React from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { truncateMiddle } from "../utils";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  menuItem: {
    maxWidth: "fit-content"
  },
  select: {}
}));
const SelectTaskQuestions = ({
  taskQuestions,
  setSelectedQuestion,
  selectedQuestion
}) => {
  const classes = useStyles();
  const handleQuestionChange = (event) => {
    const selectedCourseObject = taskQuestions.find(
      (question) => question.content === event.target.value
    );
    setSelectedQuestion(selectedCourseObject);
  };

  if (!taskQuestions.length) return;

  return (
    <FormControl sx={{ m: 1, width: 140, minWidth: 140 }} size="small">
      <InputLabel id="select-label-question">Question</InputLabel>
      <Select
        value={selectedQuestion.content}
        label="Question"
        className={classes.select}
        onChange={handleQuestionChange}>
        {taskQuestions.map((question) => (
          <MenuItem
            className={classes.menuItem}
            key={question.id}
            value={question.content}
            // onClick={() => setSelectedStudentChat(student)}
          >
            {question.content}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectTaskQuestions;
