import React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { formatDuration } from "../utils";

const useStyles = makeStyles((theme) => ({
  footer: {
    display: "flex",
    flexFlow: "row",
    justifyContent: "space-between",
    width: "65%"
  }
}));

export const Footer = ({
  footerText,
  totalOfStudents,
  colorMapping,
  selectedGraph,
  plotTypeWeek,
  data,
  title
}) => {
  const classes = useStyles();
  if (!footerText) return null;
  const calculatePercentage = (data) => {
    if (!data.length) return "Total unique: 0%";
    const percentage = (data[0].allActiveUsers / totalOfStudents) * 100;
    return `Total unique: ${percentage.toFixed(1)}%`;
  };
  const footNote = `Total students: ${totalOfStudents}`;

  const calculateTotalAverage = (data) => {
    if (!data.length) return "Total average: 0";

    const total = data.reduce((sum, item) => sum + item[selectedGraph], 0);
    const average = total / data.length;
    const result = formatDuration(average);

    if (title === "Comments created") {
      return average.toFixed(0);
    } else return result;
  };

  return (
    <Box
      sx={{
        display: "inline-flex",
        flexFlow: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        marginBlockStart: "8px"
      }}>
      <Typography
        variant="p"
        align="right"
        sx={{
          fontSize: "10 px",
          marginInlineEnd: "16px",
          width: "35%",
          textAlign: "left"
        }}>
        {footNote}
      </Typography>
      <Box className={classes.footer}>
        {!plotTypeWeek && (
          <Box
            sx={{
              display: "flex",
              flexFlow: "row",
              width: "100%",
              justifyContent: "space-between"
            }}>
            <Typography variant="p" align="right" sx={{ fontSize: "10 px" }}>
              {selectedGraph} average: {calculateTotalAverage(data)}
            </Typography>
            <Typography variant="p" align="right" sx={{ fontSize: "10 px" }}>
              {calculatePercentage(data)}
            </Typography>
          </Box>
        )}
        {plotTypeWeek &&
          selectedGraph !== "Unique" &&
          footerText &&
          Object.values(footerText).map((note, i) => {
            return (
              <Box component="footer" key={i}>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "8px"
                  }}>
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      backgroundColor: colorMapping[note],
                      marginRight: "5px"
                    }}></div>
                  <Typography variant="p">{note}</Typography>
                </Box>
              </Box>
            );
          })}
      </Box>
    </Box>
  );
};

Footer.propTypes = {
  footerText: PropTypes.object,
  totalOfStudents: PropTypes.number,
  colorMapping: PropTypes.object,
  selectedGraph: PropTypes.string,
  plotTypeWeek: PropTypes.bool,
  data: PropTypes.array,
  title: PropTypes.string
};
