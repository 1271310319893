import React, { useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import makeStyles from "@mui/styles/makeStyles";
import { Box, Chip, List, ListItem } from "@mui/material";

// Styles
const useStyles = makeStyles((theme) => ({
  tooltip: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    zIndex: 1500,
    padding: theme.spacing(2),
    borderRadius: 10,
    paddingInline: theme.spacing(2),
    backgroundColor: "#ffffff",
    width: "200px",
    fontSize: "14px"
  }
}));

export default function ToggleChips({
  options,
  spacing = "8px",
  selectedChips,
  setSelectedChips,
  multi = false,
  readOnly = false
}) {
  const handleSelectingChips = (key) => {
    if (multi) {
      if (selectedChips.includes(key)) {
        const keyIndex = selectedChips.indexOf(key);
        setSelectedChips(selectedChips.toSpliced(keyIndex, 1));
      } else {
        setSelectedChips([...selectedChips, ...[key]]);
      }
    } else {
      setSelectedChips([key]);
    }
  };

  return (
    <List sx={{ display: "flex", padding: 0 }}>
      {options.map((option) => {
        return (
          <ListItem key={option.key} sx={{ padding: 0 }}>
            <Chip
              label={option.label}
              sx={{
                marginRight: spacing,
                backgroundColor: selectedChips.includes(option.key)
                  ? "rgba(21, 101, 192, 0.3)"
                  : "",
                "&:hover": {
                  backgroundColor: selectedChips.includes(option.key)
                    ? "rgba(21, 101, 192, 0.35)"
                    : ""
                }
              }}
              onClick={() => !readOnly && handleSelectingChips(option.key)}
              clickable={!readOnly}
            />
          </ListItem>
        );
      })}
    </List>
  );
}

ToggleChips.propTypes = {
  options: PropTypes.array,
  spacing: PropTypes.string,
  selectedChips: PropTypes.array,
  setSelectedChips: PropTypes.func,
  multi: PropTypes.bool,
  readOnly: PropTypes.bool
};
