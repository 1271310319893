// Dependencies
import React from "react";
import clsx from "clsx";
import { useQuery } from "../../../hooks";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { setAction } from "../../../redux/readerActionsSlice";
import { selectTask, selectSubmission } from "../../../redux/tasksSlice";
import { selectPeerReviewQuestion } from "../../../redux/interactionsSlice";
import { motion, AnimatePresence } from "framer-motion";
import { selectPrimarySidebarState } from "../../../redux/firestoreSelectors";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography, IconButton } from "@mui/material";
import { PRIMARY_SIDEBAR_STATE } from "../../../consts";
import { togglePrimarySidebar } from "../../../redux/layoutSlice";

// Styles
const useStyles = makeStyles((theme) => ({
  divider: {
    backgroundColor: "#616161",
    margin: "16px 0"
  },
  container: {
    paddingInline: theme.spacing(3),
    paddingBlockStart: theme.spacing(1.5),
    paddingBlockEnd: theme.spacing(6),
    flexGrow: 1,
    backgroundColor: theme.palette.background.sidePanel
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& button": { color: "white" }
  },
  title: {
    color: "white",
    fontSize: "20px",
    marginBottom: theme.spacing(2)
  },
  subtitle: {
    color: "#bdbdbd",
    fontSize: "12px",
    fontWeight: "300",
    fontStyle: "normal",
    letterSpacing: "1px",
    textTransform: "uppercase"
  },
  instructions: {
    color: "white",
    fontStyle: "normal",
    whiteSpace: "pre-wrap"
  },
  body: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  editorContainer: {
    height: "100%"
  },
  editor: {
    height: "100%",
    paddingInline: theme.spacing(3),
    paddingBlock: theme.spacing(2),
    border: "1px solid #616161",
    borderRadius: "4px",
    color: "white",
    lineHeight: "2"
  },
  editorHeader: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    textTransform: "uppercase",
    fontSize: "12px",
    "& button": { color: "white" }
  },

  userInput: {
    fontFamily: '"Crimson Pro", "Frank Ruhl Libre"',
    lineHeight: 1.5,
    fontSize: "16px"
  }
}));

export default function PeerReviewSidebar() {
  // Hooks
  const classes = useStyles();
  const { submission_id } = useQuery();
  const dispatch = useDispatch();

  // Redux state
  const primarySidebarState = useSelector((state) =>
    selectPrimarySidebarState(state)
  );
  const submission = useSelector((state) =>
    selectSubmission(state, Number(submission_id))
  );
  const task = useSelector((state) => selectTask(state, submission.task_id));
  const question = useSelector((state) =>
    selectPeerReviewQuestion(state, submission.task_id)
  );

  // Ephemeral state

  //Derived State
  const isSidebarExpanded =
    primarySidebarState === PRIMARY_SIDEBAR_STATE.EXPANDED;

  // Behavior

  return (
    <>
      <Box className={classes.container}>
        <AnimatePresence>
          <motion.div
            key="main-screen"
            className={classes.innerWrapper}
            initial={{ x: "-100%" }}
            animate={{ x: "0" }}
            exit={{ x: "-100%" }}
            transition={{ type: "spring", damping: 35 }}>
            <Box className={classes.header}>
              <Typography className={classes.subtitle} color="textPrimary">
                Peer review task
              </Typography>
              <IconButton
                aria-label="close sidebar"
                size="small"
                onClick={() => {
                  dispatch(togglePrimarySidebar());
                }}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
            <Typography className={clsx(classes.title)} color="textPrimary">
              {task.name}
            </Typography>

            <Typography className={classes.instructions} color="textPrimary">
              {/* {task.task_description} */}
              {question.content}
            </Typography>
          </motion.div>
        </AnimatePresence>
      </Box>
    </>
  );
}
