// Dependencies
import React, { useEffect, useState } from "react";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";
import {
  Avatar,
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Paper,
  Typography
} from "@mui/material";

// Styles
const useStyles = makeStyles((theme) => {
  return {
    evaluation: {
      gridColumn: "1 / 4"
    },
    statContainer: {
      padding: "32px"
    },
    questionTitle: {
      fontWeight: theme.typography.fontWeightBold
    },
    evaluationContainer: {
      paddingInline: "32px"
    },
    actionBtn: {
      right: "32px",
      backgroundColor: "rgb(255, 100, 50)",
      color: "black",
      "&:hover": {
        backgroundColor: "rgb(255, 100, 50)"
      }
    },
    inline: {
      display: "inline"
    }
  };
});

export default function StudentEvaluation({ submissions, ...props }) {
  // Hooks
  const classes = useStyles();

  // Redux State

  // Ephemeral State
  const [evaluations, setEvaluations] = useState(null);

  // Variables

  //Behavior
  useEffect(() => {
    if (submissions) {
      setEvaluations(
        submissions
          .filter((submission) => submission.evaluation)
          .map((submission) => {
            return {
              student_name: submission.user_name,
              avatar: submission.avatar,
              evaluation: submission.evaluation
            };
          })
      );
    }
  }, [submissions]);

  //Render
  if (!evaluations || evaluations.length === 0) {
    return false;
  } else {
    return (
      <Box className={clsx(classes.evaluation)}>
        <Paper component="section" elevation={0}>
          <Box component="header" className={classes.statContainer}>
            <Typography className={clsx(classes.questionTitle)} variant="h5">
              Warnings
            </Typography>
            <Typography
              className={clsx(classes.questionSubtitle)}
              variant="h6"
              component="span">
              Student you should keep an eye on
            </Typography>
          </Box>
          <Divider />
          <List disablePadding>
            {evaluations.map((evaluation, index) => {
              return (
                <>
                  <ListItem
                    className={classes.evaluationContainer}
                    alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar
                        alt={`${evaluation.student_name} avatar`}
                        src={evaluation.avatar}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography
                          component="div"
                          variant="body1"
                          style={{ fontWeight: "bold" }}>
                          {evaluation.student_name}
                        </Typography>
                      }
                      secondary={
                        <Typography component="div" variant="body1">
                          {evaluation.evaluation}
                        </Typography>
                      }
                    />
                    <ListItemSecondaryAction>
                      <Button
                        disabled
                        edge="end"
                        variant="contained"
                        disableElevation
                        className={classes.actionBtn}
                        onClick={() => {}}>
                        Reach out
                      </Button>
                    </ListItemSecondaryAction>
                  </ListItem>
                  {submissions.length !== index + 1 && <Divider />}
                </>
              );
            })}
          </List>
          <Divider />
        </Paper>
      </Box>
    );
  }
}
