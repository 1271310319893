import { toZonedTime, fromZonedTime } from "date-fns-tz";

export function systemTimezoneToUtc(date) {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return fromZonedTime(date, timezone).toISOString();
}
export function utcToSystemTimezone(date) {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return toZonedTime(new Date(date), timezone).toISOString();
}
