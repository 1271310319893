// Dependancies
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";
import ClipLoader from "react-spinners/ClipLoader";

// Components
import Thumbnail from "./Thumbnail";
import TooltipWithIntl from "../../../SharedComponents/tooltip/TooltipWithIntl";

//Redux
import { useDispatch, useSelector } from "react-redux";
import { selectIsThumbnails } from "../../../../redux/firestoreSelectors";
import { pdfThumbnailsIconClicked } from "../../../../redux/layoutSlice";

//Material UI
import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { setThumbnailsAreReady } from "../../../../redux/pdfSlice";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    background: theme.palette.background.paper,
    height: "calc(100vh - 120px)",
    width: "100%",
    maxWidth: "250px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  thumbnailsView: {
    marginTop: theme.spacing(7)
  },
  thumbnailContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },

  loadingStatus: {
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "20px",
    width: "100%",
    marginTop: theme.spacing(5)
  },
  pageNumber: {
    marginBlock: theme.spacing(1)
  },
  collapsed: {
    display: "none"
  },
  thumbnailsHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: theme.palette.background.paper,
    position: "fixed",
    width: "250px",
    borderBottom: `1px solid ${theme.palette.grey.main}`,
    marginBottom: theme.spacing(5)
  },
  thubmnailsHeaderText: {
    color: theme.palette.text.disabled,
    fontSize: "14px",
    paddingInlineStart: theme.spacing(1.5),
    marginTop: theme.spacing(2)
  }
}));

function PDFThumbBar({ setPagesState }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedTextId = useSelector((state) => state.texts.selectedTextId);
  const currentPage = useSelector((state) => state.pdf.currentPage);

  // Redux State
  const isThumbnails = useSelector((state) => selectIsThumbnails(state));
  const thumbnailsAreReady = useSelector(
    (state) => state.pdf.thumbnailsAreReady
  );
  // Variables
  const [thumbnails, setThumbnails] = useState([]);

  useEffect(() => {
    if (thumbnailsAreReady || !thumbnails.length) {
      let response = window.localStorage.getItem(
        `${selectedTextId}-thumbnails`
      );
      response = response ? JSON.parse(response) : [];
      setThumbnails(response);
    }
    return () => {
      dispatch(setThumbnailsAreReady(false));
    };
  }, [dispatch, thumbnailsAreReady, selectedTextId, thumbnails.length]);
  if (!thumbnails.length) {
    return (
      <>
        <Box className={classes.loadingStatus}>
          <Typography component="span" variant="subtitle2">
            Loading PDF thumbnails...
          </Typography>
          <ClipLoader color="#5ec891" loading="true" size={50} />
        </Box>
      </>
    );
  } else {
    return (
      <Box
        className={clsx(isThumbnails ? classes.container : classes.collapsed)}>
        <Box className={classes.thumbnailsHeader}>
          <Typography
            variant="paragraph"
            id="toggleThumbnails"
            className={classes.thubmnailsHeaderText}>
            <FormattedMessage
              id="toggle.thumbnails"
              defaultMessage="Thumbnails"
            />
          </Typography>
          <TooltipWithIntl
            defaultMessage={"close thumbnails"}
            intlStringId={"close thumbnails"}
            placement="bottom">
            <IconButton
              aria-label="close thumbnails"
              size="medium"
              label="Thumbnails"
              className={classes.closeThumbnailsButton}
              onClick={() => {
                dispatch(pdfThumbnailsIconClicked());
              }}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </TooltipWithIntl>
        </Box>
        <Box className={classes.thumbnailsView}>
          {thumbnails.map((thumbnail) => {
            const pageNumber = thumbnail.pageNum;
            return (
              <Box key={pageNumber} className={classes.thumbnailContainer}>
                <Thumbnail
                  key={pageNumber}
                  pageNum={pageNumber}
                  thumbnailSrc={thumbnail.thumbnailSrc}
                  currentPage={currentPage}
                />
                <Typography
                  className={classes.pageNumber}
                  variant="caption"
                  component={"span"}>
                  {pageNumber}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  }
}

export default PDFThumbBar;

PDFThumbBar.propTypes = {
  thumbnails: PropTypes.array,
  setPagesState: PropTypes.func
};
