// Dependencies
import React, { useState, useEffect } from "react";
import { httpCallables } from "../../../../firebase";
import { useQuery } from "../../../../hooks";
import { CHARTS_VIEW_TYPE } from "../consts";

// Redux dependencies
import { useDispatch } from "react-redux";
import { setBreadcrumbs } from "../../../../redux/readerActionsSlice";

// Components
import InstitutionOverview from "./InstitutionOverview";
import ClassChip from "./ClassChip";
import InstitutionCourseOverview from "./InstitutionCourseOverview";
import { PangeaSpinner } from "../../../SharedComponents";
import ScrollBox from "../../../SharedComponents/ScrollBox";

// MUI
import { Box, FormControlLabel, Switch, Typography } from "@mui/material";

export default function InstitutionActivityReport() {
  const { institution_id } = useQuery();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [institution, setInstitution] = useState();
  const [courses, setCourse] = useState({});
  const [ratings, setRatings] = useState({
    overall: 1,
    1: 0,
    2: 0,
    3: 0
  });
  const [ignoreInternalCourses, setIgnoreInternalCourses] = useState(true);

  useEffect(() => {
    const parts = [{ text: "Admin", url: "/admin" }];
    dispatch(setBreadcrumbs({ breadcrumbs: parts }));
  }, []);

  const organizeCourses = (courses) => {
    // Helper function to standardize academic year format only if it includes two years
    const standardizeAcademicYear = (year) => {
      if (year.includes("-")) {
        const [start, end] = year.split("-").map((y) => y.trim());
        return `${start} - ${end || parseInt(start) + 1}`;
      }
      return year.trim(); // Return as is if it's a single year
    };

    // Group courses by academic year and semester
    const groupedCourses = courses.reduce((acc, course) => {
      const standardYear = standardizeAcademicYear(course.academicYear);
      if (!acc[standardYear]) {
        acc[standardYear] = { withSemester: {}, withoutSemester: [] };
      }
      if (course.semester) {
        if (!acc[standardYear].withSemester[course.semester]) {
          acc[standardYear].withSemester[course.semester] = [];
        }
        acc[standardYear].withSemester[course.semester].push(course);
      } else {
        acc[standardYear].withoutSemester.push(course);
      }
      return acc;
    }, {});

    // Convert the grouped object to the desired array structure
    const organizedCourses = Object.entries(groupedCourses).map(
      ([academicYear, { withSemester, withoutSemester }]) => ({
        academicYear,
        coursesBySemester: [
          ...Object.entries(withSemester).map(([semester, courses]) => ({
            semester,
            courses
          })),
          ...(withoutSemester.length > 0
            ? [{ semester: "", courses: withoutSemester }]
            : [])
        ]
      })
    );

    // Sort academic years descending
    organizedCourses.sort((a, b) => {
      // Extract the first year if it's a range, otherwise use the whole string
      const getYear = (str) => parseInt(str.split("-")[0].trim());
      return getYear(b.academicYear) - getYear(a.academicYear);
    });

    // Sort semesters ascending within each academic year, empty semester at the end
    organizedCourses.forEach((year) => {
      year.coursesBySemester.sort((a, b) => {
        if (a.semester === "") return 1;
        if (b.semester === "") return -1;
        return a.semester.localeCompare(b.semester);
      });
    });

    // Wrap the result in an object with "coursesByAcademicYear" key
    return { coursesByAcademicYear: organizedCourses };
  };

  const fetchData = async () => {
    setLoading(true);
    const response = await httpCallables.adminGetStats({
      institution_id,
      ignoreInternalCourses,
      type: CHARTS_VIEW_TYPE.INSTITUTION.toUpperCase()
    });
    if (response?.data?.success) {
      const institution = JSON.parse(response.data.payload);
      setInstitution(institution);
      const overallRatings = { overall: 1, 1: 0, 2: 0, 3: 0 };
      for (const course of institution.courses) {
        overallRatings[course.rating]++;
        overallRatings.overall += course.rating * course.rating;
      }
      overallRatings.overall = institution.courses.length
        ? Math.max(
            1,
            Math.round(
              overallRatings.overall / (institution.courses.length * 3)
            )
          )
        : overallRatings.overall;

      setRatings(overallRatings);
      const organizedCourses = organizeCourses(institution.courses);
      setCourse(organizedCourses);
      console.log(
        "institution",
        institution,
        overallRatings,
        organizedCourses,
        institution.courses.length
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [institution_id, ignoreInternalCourses]);

  return (
    <ScrollBox>
      {loading ? (
        <PangeaSpinner />
      ) : (
        <Box
          sx={{
            width: "100%",
            padding: "40px 100px"
          }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "20px",
              marginBottom: "40px"
            }}>
            <Typography variant="h6">{institution.name}</Typography>
            <Box>
              <FormControlLabel
                sx={{ marginRight: 2 }}
                control={
                  <Switch
                    sx={{ marginRight: 2 }}
                    checked={ignoreInternalCourses}
                    onChange={(event) =>
                      setIgnoreInternalCourses(event.target.checked)
                    }
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Ignore internal courses"
              />
            </Box>
          </Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginBlockEnd: "16px",
                marginBlockStart: "32px"
              }}>
              <Typography variant="subtitle1" sx={{ marginRight: "8px" }}>
                Overview
              </Typography>
              <ClassChip rating={ratings.overall} />
            </Box>
            <Box sx={{ width: "100%", marginBottom: "16px" }}>
              <InstitutionOverview
                activeCourses={institution.activeCourses}
                publishedAssignments={institution.publishedAssignments}
                activeStudents={institution.activeStudents}
                submissionRate={institution.submissionRate}
                ratings={ratings}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "60px"
              }}>
              {[3, 2, 1].map((rating) => {
                return (
                  <Box
                    key={rating}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "8px"
                    }}>
                    <ClassChip rating={rating} />
                    <Typography sx={{ marginLeft: "8px" }}>
                      {ratings[rating]} Classes
                    </Typography>
                  </Box>
                );
              })}
            </Box>
            <Box>
              {courses.coursesByAcademicYear.map((year) => {
                return (
                  <Box key={year.academicYear}>
                    <Typography variant="h6" sx={{ marginTop: "40px" }}>
                      {year.academicYear}
                    </Typography>
                    {year.coursesBySemester.map((semester) => {
                      return (
                        <Box key={semester.semester}>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              marginBottom: "16px",
                              color: "text.secondary"
                            }}>
                            {semester.semester}
                          </Typography>
                          {semester.courses.map((course) => {
                            return (
                              <Box
                                key={course.name}
                                sx={{ marginBottom: "24px" }}>
                                <InstitutionCourseOverview
                                  course={course}
                                  fetchData={fetchData}
                                />
                              </Box>
                            );
                          })}
                        </Box>
                      );
                    })}
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
      )}
    </ScrollBox>
  );
}
