// Dependancies

import React from "react";

import { format, formatDuration, millisecondsToMinutes } from "date-fns";
import { keyBy, truncate } from "lodash";

// Components

import ChartTooltip from "../../../../../SharedComponents/chart/chartPrimitives/ChartTooltip";

// Mui
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useLocation } from "react-router";

// styles
const useStyles = makeStyles((theme) => ({
  tooltipHeader: {
    marginBlockEnd: theme.spacing(4)
  }
}));

export default function EngagementChartTooltip({
  data,
  open,
  anchorEl,
  userIdsMap = {}
}) {
  const classes = useStyles();
  const { pathname } = useLocation();

  // Ephemeral state

  // derived state
  const CITATIONS_CREATED = "CITATIONS_CREATED";
  const QUESTIONS_CREATED = "QUESTIONS_CREATED";
  const COMMENTS_CREATED = "COMMENTS_CREATED";
  const READING_SESSIONS = "READING_SESSIONS";
  const WRITING_SESSIONS = "WRITING_SESSIONS";
  const SESSIONS = "SESSIONS";

  const studentReport = pathname === "/tasks/stats";
  // Behavior
  function getFormattedBraekdowndown(data, dataType, aggrigationType) {
    const raw = getBraekdowndown(data, dataType, aggrigationType);
    return formatAggrigation(raw, aggrigationType);
  }

  function getBraekdowndown(data, dataType, aggrigationType) {
    const breakdown = keyBy(data.activities, "type");
    return breakdown[dataType] ? breakdown[dataType][aggrigationType] : 0;
  }

  function formatAggrigation(data, aggrigationType) {
    if (aggrigationType === "duration") {
      const durationInSecounds = millisecondsToMinutes(data);
      const hours = Math.floor(durationInSecounds / 60);
      const minutes = durationInSecounds % 60;
      return formatDuration({ hours, minutes });
    } else return data;
  }

  if (!data) return null; //nothing to show if data doesn't exist

  return (
    <ChartTooltip anchorEl={anchorEl} open={open}>
      <Box className={classes.tooltipHeader}>
        <Typography variant="h5">Engagement</Typography>
        {/* hack: forcing a line brake by doubling the tag */}
        <Typography variant="h5">Breakdown</Typography>
        <Typography variant="body2">
          {format(new Date(data.date), "PP")}
        </Typography>
      </Box>

      <>
        {data.key === "class" ? (
          <Typography variant="body2">Class average</Typography>
        ) : (
          <Typography variant="body2">
            {userIdsMap[data.key]
              ? userIdsMap[data.key]
              : ` User: ${truncate(data.key, { length: 9 })}`}
          </Typography>
        )}

        <Typography component="div" variant="body2">
          {data.key === "class" && (
            <>
              <Box>Total Users: {data.totalUsers}</Box>
              <Box>Total Active Users: {data.participants?.length}</Box>
            </>
          )}

          <Box>
            {`Reading Time: ${
              getFormattedBraekdowndown(data, READING_SESSIONS, "duration") || 0
            }`}
          </Box>
          <Box>
            {`Writing Time: ${
              getFormattedBraekdowndown(data, WRITING_SESSIONS, "duration") || 0
            }`}
          </Box>
          <Box>
            {`Citations created: ${
              getFormattedBraekdowndown(data, CITATIONS_CREATED, "count") || 0
            }`}
          </Box>
          <Box>
            {`Questions created: ${
              getFormattedBraekdowndown(data, QUESTIONS_CREATED, "count") || 0
            }`}
          </Box>
          <Box>
            {`Comments: ${
              getFormattedBraekdowndown(data, COMMENTS_CREATED, "count") || 0
            }`}
          </Box>
          <Box>
            {`Total activity duration: ${
              getFormattedBraekdowndown(data, SESSIONS, "duration") || 0
            }`}
          </Box>
        </Typography>
      </>
    </ChartTooltip>
  );
}
