import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  expand: true,
  stage: "highlight",
  submittedTextId: 0,
  selectedLocation: false,
  shownLocation: false,
  status: "active",
  forceHighlight: false,
  detailedInstructions: false,
  seenStages: [],
  text_id: undefined,
  movedThroughState: ["highlight"],
  selectedQuestionId: 0,
  selectedAnswer: false,
  mode: "light",
  showAnswers: false,
  showHighlights: false,
  loading: true,
  actionCopy: "poc",
  openQuestions: []
};

export const grSlice = createSlice({
  name: "gr",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase("gotSq3r", (state, value) => {
        // state.questions =
        //   value.payload.questions?.map((q, i) =>
        //     Object.assign(q, { id: q.id === undefined ? i : q.id })
        //   ) || [];
        // state.highlights = value.payload.highlights;
        state.stage = value.payload.grStage || "highlight";
        state.mode = value.payload.grMode || "light";
        state.selectedQuestionId = value.payload.grQuestionId;
        state.showAnswers = value.payload.grShowAnswers || false;
        state.showHighlights = value.payload.grShowHighlights || false;
        state.text_id = value.payload.text_id;
        state.questionsOrder = value.payload.questionsOrder || [];
        if (value.payload.status) {
          state.status = value.payload.status;
        }
        state.loading = false;
      })
      .addCase("readerActions/setAction", (state, value) => {
        // This is a hack - one of many that i believe will be redundant if we use the url as state manager when possible.
        switch (true) {
          case value.payload === "poc" && state.actionCopy === "task":
          case value.payload === "task" && state.actionCopy === "poc":
            state.loading = true;
            Object.assign(state, initialState);
            break;
          default:
            break;
        }
        state.actionCopy = value.payload;
      })
      .addCase("readerActions/setLocation", (state, value) => {
        if (value.payload && "questionId" in value.payload) {
          state.selectedQuestionId = value.payload.questionId;
        }
      });
  },
  reducers: {
    selectLocation: (state, value) => {
      if (value.payload) {
        if ("questionId" in value.payload) {
          state.selectedQuestionId = value.payload.questionId;
        }
        state.selectedLocation = true;
        state.selectedAnswer = value.payload;
        state.stage = "highlight";
      }
    },
    setDetailedInstructions: (state, value) => {
      state.detailedInstructions = value.payload;
    },
    setForceHighlight: (state, value) => {
      state.forceHighlight = value.payload;
    },
    setGrMode: (state, value) => {
      state.mode = value.payload;
    },
    setShowAnswers: (state, value) => {
      state.showAnswers = value.payload;
    },
    setShowHighlights: (state, value) => {
      state.showHighlights = value.payload;
    },
    setStage: (state, value) => {
      if ("stage" in value.payload) {
        // auto-select question
        if (state.mode === "full") {
          if (
            value.payload.stage >= 3 &&
            state.questions &&
            state.questions[0] &&
            !state.selectedLocation &&
            !current(state.movedThroughState).includes(value.payload.stage)
          ) {
            state.selectedQuestionId = state.questions[0].id;
          }
        } else {
          // gr-mode is 'light'
          if (
            value.payload.stage !== "highlight" &&
            state.questions &&
            state.questions[0] &&
            !state.selectedLocation &&
            !current(state.movedThroughState).includes(value.payload.stage)
          ) {
            state.selectedQuestionId = state.questions[0].id;
          }
        }

        state.selectedAnswer = false;
        state.stage = value.payload.stage;
        if (!current(state.movedThroughState).includes(value.payload.stage))
          state.movedThroughState.push(value.payload.stage);
      }

      if (
        "lastStage" in value.payload && // TODO: Ofer - investigate this
        !(value.payload.lastStage in state.seenStages)
      ) {
        state.seenStages.push(value.payload.lastStage);
      }
    },
    deleteSelectedAnswer: (state, value) => {
      state.selectedAnswer = false;
    },
    setSubmittedText: (state, value) => {
      state.submittedTextId = value.payload;
    },

    setSelectedQuestion: (state, value) => {
      state.selectedQuestionId = value.payload;
    },
    setQuestions: (state, value) => {
      state.questions = value.payload;
    },
    setQuestionsOrder: (state, value) => {
      const order = value.payload;
      order.map((id, index) => {
        const question = state.questions.find((question) => question.id === id);
        question.order = index;
      });
    },
    setOpenQuestions: (state, value) => {
      state.openQuestions = value.payload;
    },
    resetGRState: () => initialState,
    updateGrStateUiOnly: (state, value) => {
      const attributes = Object.keys(value.payload);
      attributes.forEach((attribute) => {
        if (attribute === "grStage") {
          state.stage = value.payload[attribute];
        } else {
          state[attribute] = value.payload[attribute];
        }
      });
    },
    // actions for epics
    fetchGRInteractions: () => {}
  }
});

export const {
  setDetailedInstructions,
  setStage,
  setGrMode,
  setExpand,
  setSubmittedText,
  deleteSelectedAnswer,
  setQuestions,
  setSelectedQuestion,
  setForceHighlight,
  selectLocation,
  setShowAnswers,
  setShowHighlights,
  resetGRState,
  updateGrStateUiOnly,
  setOpenQuestions,
  setQuestionsOrder,
  fetchGRInteractions
} = grSlice.actions;

export default grSlice.reducer;
