// Dependencies
import React, { useEffect, useRef, useCallback, useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { PdfPosition } from "./PdfTypes";
import { useQuery, useStepStage } from "../../../hooks";
import {
  clearSelection,
  fetchAllUserHighlightsPerText,
  mergeOverlappingHighlightsBySelection
} from "../utils";
import { interactionsAPI } from "../../../api";
import store from "../../../redux/store";
import { v4 as uuid } from "uuid";

// Redux dependencies
import {
  selectIsComments,
  selectCommentsPrivacy,
  selectIsSuggestions
} from "../../../redux/firestoreSelectors";
import { useSelector, useDispatch } from "react-redux";
import {
  selectThreads,
  setCommentPanelState,
  setNewCommentObject
} from "../../../redux/realtimeInteractionsSlice";
import {
  selectedQuestionHighlights,
  selectCurrentInteraction,
  selectGrStepOneHighlights,
  selectCurrentTaskHighlights,
  selectReaderHighlights,
  setSelectedInteractionId,
  selectSuggestionsByQuestionId,
  addHighlight as addHighlightReducer
} from "../../../redux/interactionsSlice";

import { selectTask, selectSubmission } from "../../../redux/tasksSlice";
import { selectText } from "../../../redux/textsSlice";
import { selectZoom, resetDocument } from "../../../redux/pdfSlice";
import { setSelectedQuestion } from "../../../redux/grSlice";
import { selectCourseByTextId } from "../../../redux/coursesSlice";
import {
  updateHighlights,
  updateSq3r
} from "../../../redux/firebaseMiddleware";
import {
  closeAnnotatorBar,
  toggleAnnotatorBar,
  openAnnotatorBar,
  setSelectedHighlight,
  toggleNoQuestionsMessage
} from "../../../redux/highlightSlice";

// Components
import PdfView from "./PdfView";
import ReactReaderActions from "../../annotations/ReactReaderActions";

// Material UI
import {
  COMMENT_PANEL_VIEW,
  FEATURE_FLAGS,
  INTERACTION_SUBTYPES,
  INTERACTION_TYPES
} from "../../../consts";
import { Box, Snackbar } from "@mui/material";
import { setCustomCursor } from "../../../redux/layoutSlice";
import { isFeatureFlagEnabled } from "../../../redux/epics/eMentorEpics/utils";
import { useHighlightColor } from "../../../hooks/useHighlightColor";

function ReactPdfReader({
  disableInteractions = false,
  minimalBar,
  readOnly,
  isVisible = false
}) {
  const { submission_id } = useQuery();
  const intl = useIntl();
  //Hooks
  const dispatch = useDispatch();
  const containerRef = useRef(null);

  //Redux State
  const zoom = useSelector(selectZoom);

  const submission = useSelector((state) =>
    selectSubmission(state, Number(submission_id))
  );
  const task = useSelector((state) => selectTask(state, submission.task_id));
  const isCommentsInSecondarySidebar = useSelector((state) =>
    selectIsComments(state)
  );

  const selectCommentPrivacyMode = useSelector((state) =>
    selectCommentsPrivacy(state)
  );

  const action = useSelector(
    (state) => state.readerActions.persistentActionState.actionBar
  );
  const annotatorMode = useSelector(
    (state) => state.readerActions.persistentActionState.annotatorMode
  );
  const detachedComment = useSelector(
    (state) => state.readerActions.detachedComment
  );

  const grMode = useSelector((state) => state.gr.mode);
  const [step] = useStepStage();

  const selectedTextId = useSelector((state) => {
    return state.texts.selectedTextId;
  });
  const currentPage = useSelector((state) => state.pdf.currentPage);

  const text = useSelector((state) => selectText(state, selectedTextId));
  const isSuggestions = useSelector((state) => selectIsSuggestions(state));

  const selectedCourse = useSelector(selectCourseByTextId);

  const selectedQuestion = useSelector(selectCurrentInteraction);
  const questions = useSelector((state) => state.interactions.questions);
  let selectedGrQuestion = useSelector((state) => state.gr.selectedGrQuestion);
  const isAnnotatorBarOpen = useSelector(
    (state) => state.highlighter.isAnnotatorBarOpen
  );
  const readerHighlights = useSelector(selectReaderHighlights);
  const socialHighlights = useSelector(selectThreads);
  const grStepOneHighlights = useSelector(selectGrStepOneHighlights);
  const questionHighlights = useSelector(selectedQuestionHighlights);
  const taskHighlights = useSelector(selectCurrentTaskHighlights);
  const showHighlights = useSelector(
    (state) => state.readerActions.persistentActionState.showHighlights
  );
  const showAllHighlights = useSelector(
    (state) => state.readerActions.persistentActionState.showAllHighlights
  );
  const highlightColor = useHighlightColor(selectedQuestion.id);

  const suggestionsArray = useSelector(selectSuggestionsByQuestionId);
  const selectedSuggestion = useSelector(
    (state) => state.interactions.selectedSuggestion
  );
  const isFeatureEnabled = isFeatureFlagEnabled(
    store.getState(),
    FEATURE_FLAGS.E_MENTOR_JOURNEY
  );
  //Ephemeral State
  const [highlights, setHighlights] = useState([]);

  const [underlines, setUnderlines] = useState([]);

  //Variables
  const isTask = action === "task";
  const isGuidedReading = task.task_type === "guidedReading";
  const isPeerReview = task.task_type === "peerReview";
  const isSelectedQuestion = Boolean(selectedQuestion.id);
  const isPdf = true;

  async function setUsersHighlightsPerText() {
    let userInteractions = await fetchAllUserHighlightsPerText(text.id);
    if (taskHighlights && taskHighlights.length) {
      userInteractions = userInteractions.concat(taskHighlights);
    }
    userInteractions = userInteractions.filter((obj, index, self) => {
      return index === self.findIndex((t) => t.id === obj.id);
    });
    if (isCommentsInSecondarySidebar)
      userInteractions = userInteractions.filter(
        (obj) =>
          obj.interaction_type !== "COMMENT" &&
          obj.interaction_type !== "CONTAINER"
      );
    if (userInteractions.length) renderHighlights(userInteractions);
    else return;
  }
  // This effect sets the highlight
  useEffect(() => {
    switch (true) {
      case showAllHighlights:
        setUsersHighlightsPerText();
        break;
      case action === "" && showHighlights && !showAllHighlights:
        renderHighlights(readerHighlights);
        break;
      case Boolean(selectedQuestion?.id) && !showAllHighlights:
        renderHighlights(questionHighlights);
        break;
      case !isTask && !selectedQuestion?.id && !showAllHighlights:
        renderHighlights(grStepOneHighlights);
        break;
      case isTask && !selectedQuestion?.id && !showAllHighlights:
        renderHighlights([]);
        break;
      case isPeerReview:
        setHighlights([]);
        break;

      default:
        renderHighlights([]);
    }
  }, [
    JSON.stringify(readerHighlights),
    JSON.stringify(questionHighlights),
    JSON.stringify(grStepOneHighlights),
    JSON.stringify(socialHighlights),
    JSON.stringify(taskHighlights),
    JSON.stringify(suggestionsArray),
    action,
    selectedQuestion,
    step,
    selectedSuggestion,
    showHighlights,
    isTask,
    showAllHighlights,
    isSuggestions,
    isPeerReview,
    isCommentsInSecondarySidebar,
    selectCommentPrivacyMode
  ]);

  //Thie effect sets the underlines
  useEffect(() => {
    switch (true) {
      case action === "poc" && step !== "highlight":
        setUnderlines(grStepOneHighlights);
        break;
      case action === "poc" && step === "highlight":
        setUnderlines([]);
        break;
      default:
        setUnderlines([]);
    }
  }, [JSON.stringify(grStepOneHighlights), action, step]);

  //Behavior

  // TODO: this solution is flimsy, moving all the doc context in to a global provider (such as the useRendition) will be ideal.
  const renderHighlights = (highlightsArray) => {
    let filteredSuggestions = filterSuggestionsBySelectedSuggestion();

    if (!isCommentsInSecondarySidebar) {
      if (isSuggestions && isFeatureEnabled && filteredSuggestions.length !== 0)
        return setHighlights(highlightsArray.concat(filteredSuggestions));
      return setHighlights(highlightsArray);
    }
    let newArray = JSON.parse(JSON.stringify(socialHighlights));
    newArray = newArray
      .filter((comment) => comment.privacy === selectCommentPrivacyMode)
      .map((el) => {
        el = {
          ...el,
          color: "#dcdcdc"
        };
        return el;
      });
    if (isSuggestions && filteredSuggestions.length !== 0)
      newArray = newArray.concat(filteredSuggestions);
    if (!highlightsArray.length) {
      // highlightsArray can be empty. (for example in step 2 in GR with no questions), and then we want only social highlights to get rendered.
      setHighlights(newArray);
    } else setHighlights(newArray.concat(highlightsArray));
  };

  const addHighlightToBook = useCallback(
    (highlight, color, shouldDelete = false) => {
      if (annotatorMode === "poc") {
        let updatedGrHighlights = [
          ...highlights.filter((el) => el.cfi !== highlight.cfi),
          {
            ...highlight,
            source: "poc",
            color: highlightColor
          }
        ];
        dispatch(
          updateSq3r({
            text_id: selectedTextId,
            questions: questions,
            highlights: updatedGrHighlights
          })
        );
      } else {
        let adjustedHighlightColor = color ? color : highlightColor;

        let adjustedHighlight = {
          ...highlight,
          source: "highlight",
          color: adjustedHighlightColor
        };
        let highlightColl = highlights.filter(
          (el) => el.source === "highlight" && el.cfi !== highlight.cfi
        );
        if (!shouldDelete) {
          highlightColl.push(adjustedHighlight);
        }
        dispatch(
          updateHighlights({
            text_id: selectedTextId,
            highlights: highlightColl
          })
        );
      }
    },
    [
      annotatorMode,
      dispatch,
      highlightColor,
      highlights,
      questions,
      selectedTextId
    ]
  );

  const filterHlByPage = (hlCollection, page) => {
    let allHighlights = [];
    if (hlCollection.length) {
      return hlCollection.flatMap((el) => {
        if (el.interaction_type === "SUGGESTION") {
          if (el.pdfPosition.pageNumber === page) {
            return [{ ...el, ...el.pdfPosition }];
          } else return [];
        } else
          return el.pdfPosition.flatMap((pagePart) => {
            if (pagePart.pageNumber === page) {
              return [{ ...el, ...pagePart }];
            } else return [];
          });
      });
    } else return allHighlights;
  };

  function filterSuggestionsBySelectedSuggestion() {
    let filteredSuggestions = [];
    setHighlights([]);
    filteredSuggestions = suggestionsArray.filter(
      (suggestion) => suggestion.id != selectedSuggestion.id
    );
    if ("id" in selectedSuggestion)
      filteredSuggestions.push(selectedSuggestion);
    return filteredSuggestions;
  }

  //TODO This has a lot in common with EPUB logic, refactor it
  //using memo becuse we can't have constant rerenders without good enough reasons
  const onTextSelected = useCallback(
    (params) => {
      const isGuidedReading = task.task_type === "guidedReading";
      const isSelectedQuestion = Boolean(selectedQuestion.id);
      const filterefHighlights = highlights.filter(
        (el) => el.interaction_type !== INTERACTION_TYPES.SUGGESTION
      );
      const { selection, clientRect } = params;
      let pageHighlights = filterHlByPage(filterefHighlights, currentPage);
      let { newHighlight, mergedHighlights } =
        mergeOverlappingHighlightsBySelection(pageHighlights, isPdf, selection);

      let clientRects = clientRect[0].pageRects[0];
      if (disableInteractions) return;

      switch (true) {
        /** Do Nothing */
        case readOnly:
          return;
        case detachedComment: {
          newHighlight = Object.assign(newHighlight, {
            interaction_type: INTERACTION_TYPES.CONTAINER,
            interaction_subtype: INTERACTION_SUBTYPES.COMMENT,
            color: "rgba(0, 0, 0, 0.12)"
          });
          setHighlights((prev) => [...prev, newHighlight]);
          dispatch(setCustomCursor(false));
          dispatch(setCommentPanelState(COMMENT_PANEL_VIEW.NEW_COMMENT));
          dispatch(setNewCommentObject(newHighlight));
          break;
        }
        /** Update answer quotes */
        case isTask && isGuidedReading && isSelectedQuestion:
        case isTask && task.task_type === "standard" && isSelectedQuestion: {
          newHighlight = Object.assign(newHighlight, {
            interaction_type: INTERACTION_TYPES.ANSWER,
            interaction_subtype: INTERACTION_SUBTYPES.QUOTE,
            interaction_id: selectedQuestion.id,
            task_id: task.id,
            submission_id: submission.id
          });
          addHighlight(newHighlight, mergedHighlights);
          break;
        }
        case action === "" && annotatorMode === "highlight": {
          newHighlight = Object.assign(newHighlight, {
            interaction_type: INTERACTION_TYPES.READER,
            interaction_subtype: INTERACTION_SUBTYPES.QUOTE,
            task_id: task?.id,
            submission_id: submission?.id
          });
          addHighlight(newHighlight, mergedHighlights);
          break;
        }
        case action === "poc" && annotatorMode === "poc": {
          if (!selection.content || !selection.content.trim()) return;
          if (!selectedQuestion.id) {
            if (questions.length) {
              const id = uuid();
              const highlight = {
                id,
                ...newHighlight,
                text_id: selectedTextId,
                course_id: selectedCourse.id,
                interaction_type: INTERACTION_TYPES.ANSWER,
                interaction_subtype: INTERACTION_SUBTYPES.QUOTE,
                color: "rgba(0, 0, 0, 0.12)",
                task_id: task?.id,
                submission_id: submission?.id
              };
              dispatch(addHighlightReducer(highlight));
              clearSelection();
              dispatch(
                setSelectedHighlight({
                  clientRectangle: clientRects,
                  selectedHighlight: highlight.id,
                  isHover: true,
                  isClickOnMark: true,
                  minimal: highlight.minimal
                })
              );
              dispatch(openAnnotatorBar());
            } else {
              return dispatch(
                toggleNoQuestionsMessage({
                  clientRectangle: clientRects
                })
              );
            }
          } else if (step === "highlight") {
            newHighlight = Object.assign(newHighlight, {
              interaction_type: INTERACTION_TYPES.ANSWER,
              interaction_subtype: INTERACTION_SUBTYPES.QUOTE,
              interaction_id: selectedQuestion.id,
              task_id: task?.id,
              submission_id: submission?.id
            });
            addHighlight(newHighlight, mergedHighlights);
          } else {
            if (newHighlight?.content) {
              dispatch(
                toggleAnnotatorBar({
                  clientRectangle: clientRects,
                  selectedText: newHighlight
                })
              );
            }
          }
          break;
        }
        case !annotatorMode: {
          if (newHighlight?.content) {
            dispatch(
              toggleAnnotatorBar({
                clientRectangle: clientRects,
                selectedText: newHighlight
              })
            );
          }
          break;
        }

        default:
          break;
      }
    },
    [
      disableInteractions,
      dispatch,
      addHighlightToBook,
      action,
      annotatorMode,
      highlightColor,
      grMode,
      task,
      selectedCourse,
      selectedTextId,
      selectedQuestion,
      step,
      text,
      submission,
      highlights,
      readOnly,
      detachedComment
    ]
  );

  const highlightClicked = useCallback(
    (clickEvent, highlight) => {
      if ((task && readOnly) || checkIfElementIsSuggestion(highlight)) return;
      if (isAnnotatorBarOpen) {
        return dispatch(closeAnnotatorBar());
      } else {
        const clientRect = clickEvent.target.getBoundingClientRect();
        const parentPos = containerRef.current.getBoundingClientRect();
        const highlightedRect = {
          top: clientRect.top + containerRef.current.scrollTop - parentPos.top,
          left:
            clientRect.left + containerRef.current.scrollLeft - parentPos.left,
          x: clientRect.left + containerRef.current.scrollLeft - parentPos.left,
          y: clientRect.top + containerRef.current.scrollTop - parentPos.top,

          width: clientRect.width,
          height: clientRect.height,
          bottom:
            clientRect.top +
            containerRef.current.scrollTop -
            parentPos.top +
            clientRect.height,
          right:
            clientRect.left +
            containerRef.current.scrollLeft -
            parentPos.left +
            clientRect.width
        };
        dispatch(
          setSelectedHighlight({
            clientRectangle: highlightedRect,
            selectedHighlight: highlight.id, // TODO: We can store the highlight ID only and avoid duplicaed data
            isHover: true,
            isClickOnMark: true,
            minimal: highlight.minimal
          })
        );
        dispatch(openAnnotatorBar());
      }
    },
    [dispatch, isAnnotatorBarOpen, grMode, step]
  );

  const setHighlightColorCb = (highlight, color) => {
    if (highlight.source === "poc" && annotatorMode === "poc") {
      let updatedGrHighlights = [
        ...highlights.filter((el) => el.cfi !== highlight.cfi),
        {
          ...highlight,
          source: "poc",
          color: color
        }
      ];
      dispatch(
        updateSq3r({
          text_id: selectedTextId,
          questions: questions,
          highlights: updatedGrHighlights
        })
      );
    } else if (highlight.source === "grQuestion" && annotatorMode === "poc") {
      let item = selectedQuestion.answers.filter(
        (a) => a.cfi === highlight.cfi
      )[0];
      let answers = [
        ...selectedQuestion.answers.filter((a) => a.cfi !== highlight.cfi),
        { ...item, color: color }
      ];
      let question = { ...selectedQuestion, answers: answers };

      let updatedQuestions = questions.map((q) => {
        if (q.id === selectedQuestion?.id) {
          return question;
        } else return q;
      });

      dispatch(
        updateSq3r({
          text_id: selectedTextId,
          questions: updatedQuestions,
          highlights
        })
      );
    } else addHighlightToBook(highlight, color);
  };

  function addHighlight(highlight, mergedHighlights = []) {
    interactionsAPI.createHighlight(
      {
        ...highlight,
        color: highlightColor
      },
      selectedTextId,
      selectedCourse.id
    );

    deleteHighlights(mergedHighlights);
    clearSelection();
  }

  function deleteHighlights(oldIntersectedHighlights) {
    for (const el of oldIntersectedHighlights) {
      interactionsAPI.deleteHighlight(el, false);
    }
  }

  function checkIfElementIsSuggestion(highlight) {
    const element = suggestionsArray.find(
      (suggestion) => suggestion.id === highlight.id
    );
    if (!element) return false;
    else return true;
  }

  //save the current page when unmounted
  useEffect(() => {
    return () => {
      dispatch(resetDocument());
    };
  }, [dispatch]);
  return (
    <>
      <Box ref={containerRef} style={{ height: "100%", position: "relative" }}>
        <ReactReaderActions
          id="reactReaderActions"
          minimal={minimalBar}
          addHighlight={addHighlight}
          setHighlightColor={setHighlightColorCb}
          deleteHighlight={interactionsAPI.deleteHighlight}
          canComment={true}>
          <PdfView
            zoom={zoom}
            isVisible={isVisible}
            highlightClicked={highlightClicked}
            handleTextSelected={onTextSelected}
            highlights={highlights}
            underlines={underlines}
            url={text.url}
          />
        </ReactReaderActions>
      </Box>
    </>
  );
}

ReactPdfReader.propTypes = {
  location: PropTypes.shape(PdfPosition),
  disableInteractions: PropTypes.bool,
  minimalBar: PropTypes.bool,
  readOnly: PropTypes.bool,
  isVisible: PropTypes.bool,
  locationChanged: PropTypes.func
};

export default ReactPdfReader;
