import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { PdfPosition } from "./PdfTypes";
import { Page } from "react-pdf";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import { captureException } from "../../../utils/errorHandlers";
import { expandTextDivs } from "./pdfTextSelectionFix";

const useStyles = makeStyles(() => ({
  page: {
    textAlign: "center",
    margin: "12px auto"
    /*  useful for debugging mismatch of text and highlights/ pdf 
   
   '& .textLayer span':{

        color:'black',
      }
    /* */
  }
}));

const PdfPageMemo = ({
  scale,
  width,
  pageNumber,
  renderMode,
  onLoadSuccess,
  onRenderedText,
  onRenderSuccess
}) => {
  const classes = useStyles();
  const onRenderError = useCallback((err) => captureException(err), []);
  const onRenderTextLayerSuccess = useCallback(
    (textLayerRenderer, textParameters) => {
      if (renderMode === "canvas") {
        onRenderedText(new Date().getTime(), textLayerRenderer, textParameters);
      }
    },
    [onRenderedText, renderMode]
  );

  useEffect(() => {
    return () => onRenderedText(0);
  }, [onRenderedText]);

  function showSpinner() {
    return (
      <lottie-player
        src="/loading_book_lottie.json"
        mode="bounce"
        background="transparent"
        speed="1"
        style={{
          width: "300px",
          height: "300px",
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)"
        }}
        loop
        autoplay></lottie-player>
    );
  }

  return (
    <Page
      scale={scale}
      renderMode={renderMode}
      loading={showSpinner}
      data-page-number={pageNumber}
      onLoadSuccess={onLoadSuccess}
      onRenderError={onRenderError}
      enhanceTextSelection={true}
      onRenderTextLayerSuccess={onRenderTextLayerSuccess}
      width={width}
      className={clsx(classes.page, `pdf-page-${pageNumber}`)}
      pageIndex={pageNumber - 1}
      onRenderSuccess={onRenderSuccess}></Page>
  );
};

PdfPageMemo.propTypes = {
  scale: PropTypes.number,
  width: PropTypes.number,
  location: PropTypes.shape(PdfPosition),

  interactive: PropTypes.bool,
  renderMode: PropTypes.oneOf(["svg", "canvas", "none"]),
  pageNumber: PropTypes.number,
  onLoadSuccess: PropTypes.func.isRequired,
  onRenderedText: PropTypes.func.isRequired,
  onRenderSuccess: PropTypes.func.isRequired
};
export default PdfPageMemo;
