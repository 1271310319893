export const TABLE_VIEW = {
  COURSE_VIEW: "COURSE_VIEW",
  ASSIGNMENT_VIEW: "ASSIGNMENT_VIEW",
  AVERAGE_VIEW: "AVERAGE_VIEW",
  DISTRIBUTION_VIEW: "DISTRIBUTION_VIEW"
};

export const colorMapping = {
  SubmissionPending_no: "#E0E0E0",
  SubmissionMissed_total: "#A32650",
  SubmissionMissed_incomplete: "#A32650",
  SubmissionMissed: "#A32650",
  SubmissionMissed_no: "#A32650",
  SubmissionPending_total: "#E0E0E0",
  SubmissionPending_complete: "#E0E0E0",
  SubmissionPending: "#E0E0E0",
  SubmissionLate_complete: "#96CD5F",
  SubmissionLate_incomplete: "#D3760D",
  SubmissionOnTime_complete: "#1B5E20",
  SubmissionOnTime_incomplete: "#4D9528",
  REVIEW_SESSION_total: "#1565C0",
  HIGHLIGHT_SESSION_total: "#5E92F3",
  ANSWER_SESSION_total: "#003C8F"
};

export const LEGEND_MARKER_FOR_TIME_SPENT_ON_ASSIGNMENT = {
  HIGHLIGHT_SESSION_total: "Step 1: Read and highlight - ",
  REVIEW_SESSION_total: "Step 2: Review and chat - ",
  ANSWER_SESSION_total: "Step 3: Answer - "
};
