import { useRef, useEffect, useMemo, useCallback } from "react";

import { debounce } from "lodash";

export const useDebounce = (callback) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  return useCallback(
    debounce((...args) => {
      ref.current?.(...args);
    }, 1000),
    []
  );
};
