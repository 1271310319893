import React from "react";
import clsx from "clsx";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Box, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { selectTextDirection } from "../../../redux/firestoreSelectors";
import TooltipWithIntl from "../../SharedComponents/tooltip/TooltipWithIntl";

const useStyles = makeStyles((theme) => ({
  right: {
    textAlign: "right"
  },
  left: {
    textAlign: "left"
  },
  tasksHeader: {
    marginTop: "40px",
    width: "100%",
    position: "relative"
  },
  blueBg: {
    backgroundColor: "#1565C0",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#1172be"
    }
  },

  stepper: {
    marginTop: "20px",
    width: "100%",
    paddingLeft: "0px",
    paddingRight: "0px"
  },
  btnContainer: {
    marginTop: "40px",
    marginBottom: "40px",
    textAlign: "right"
  },
  btn: {
    color: theme.palette.lightText.main,
    marginLeft: "4px",
    marginRight: "4px"
  },
  btnPublish: {
    color: theme.palette.lightText.main,
    marginLeft: "4px",
    marginRight: "4px"
  },
  stepLabel: {
    fontSize: "24px"
  },
  stepRtl: {
    "& .MuiStepLabel-iconContainer": {
      paddingLeft: "8px",
      paddingRight: "0px"
    }
  },
  stepLast: {
    paddingRight: "0px"
  },
  stepIcon: {
    width: "36px",
    height: "36px"
  },
  cancelBtn: {
    position: "absolute"
  },
  cancelBtnRtl: {
    left: "0px"
  },
  cancelBtnLtr: {
    right: "0px"
  }
}));

export default function NavigationButtons(props) {
  const classes = useStyles();

  function renderButtons() {
    let backBtn = "";

    if (props.activeStep >= 1) {
      backBtn = (
        <Button
          onClick={() => props.setActiveStep(props.activeStep - 1)}
          variant="contained"
          size="large"
          className={clsx(classes.btn, classes.blueBg)}>
          {"Back"}
        </Button>
      );
    }

    if (props.activeStep === 2 && !props.readOnly) {
      return props.isEdit ? (
        <Box className={classes.btnContainer}>
          {backBtn}
          <Button
            className={clsx(classes.btnPublish, classes.blueBg)}
            onClick={() => {
              props.validateForm() && props.saveTask();
            }}
            variant="contained"
            size="large">
            <FormattedMessage defaultMessage="Update" id="general.update" />
          </Button>
        </Box>
      ) : (
        <Box className={classes.btnContainer}>
          {backBtn}
          <Button
            className={clsx(classes.btnPublish, classes.blueBg)}
            onClick={() => {
              props.validateForm() && props.publishTask();
            }}
            variant="contained"
            size="large">
            <FormattedMessage defaultMessage="Publish" id="general.publish" />
          </Button>
        </Box>
      );
    }
    return (
      <Box className={classes.btnContainer}>
        {backBtn}
        {!props.readOnly && (
          <Button
            className={clsx(classes.btn, classes.blueBg)}
            name="next-step-button"
            variant="contained"
            size="large"
            onClick={() => {
              props.validateForm() && props.setActiveStep(props.activeStep + 1);
            }}>
            {"Next"}
          </Button>
        )}
      </Box>
    );
  }

  return <>{renderButtons()}</>;
}
