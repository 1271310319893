import React from "react";

// Componenets
import { ScrollBox } from "../../SharedComponents";
import AssistantName from "./panel/AssistantName";
import AssistantInstructions from "./panel/AssistantInstructions";
import AssistantModel from "./panel/AssistantModel";
import AssistantTemperature from "./panel/AssistantTemperature";

// import AssistantTools from "./panel/AssistantTools";  - TBD

// Styles
import { makeStyles } from "@mui/styles";
import { Box, Typography, Button } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexFlow: "column",
    alignItems: "center"
  },
  sectionHeader: {
    width: "100%",
    display: "flex",
    flexFlow: "column",
    marginBlockStart: theme.spacing(2),
    paddingInline: theme.spacing(2),
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  section: {
    width: "100%",
    display: "inline-flex",
    flexFlow: "column nowrap",
    paddingInline: theme.spacing(3),
    marginBlock: theme.spacing(1.5)
  },
  sectionFiles: {
    display: "flex",
    paddingInline: theme.spacing(3),
    alignItems: "center",
    width: "100%",
    flexFlow: "row nowrap",
    justifyContent: "space-between"
  },
  filesTitle: {
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.lightText.muted
  }
}));

const PlaygroundPanel = ({
  selectedAssistant,
  assistantName,
  setAssistantName,
  assistantInstructions,
  setAssistantInstructions,
  modelName,
  setModelName,
  temperature,
  setTemperature,
  template,
  setTemplate,
  fetchPrompt
}) => {
  const classes = useStyles();

  const handleNameChange = (value) => {
    setAssistantName(value);
  };

  const handleInstructionsChange = (event) => {
    setAssistantInstructions(event.target.value);
  };

  const handleModelChange = (model) => {
    setModelName(model);
  };

  return (
    <ScrollBox>
      <Box className={classes.container}>
        <Box className={classes.section}>
          <AssistantName
            botType={selectedAssistant}
            assistantName={assistantName}
            handleNameChange={handleNameChange}
          />
        </Box>

        <Box className={classes.section}>
          <AssistantInstructions
            label="Template"
            assistantInstructions={template}
            handleInstructionsChange={(e) => setTemplate(e.target.value)}
          />
          <Button
            variant="outlined"
            color="primary"
            size="small"
            //   className={classes.button}
            onClick={() => {
              fetchPrompt();
            }}>
            fetch prompt
          </Button>
          <AssistantInstructions
            label="Instructions"
            assistantInstructions={assistantInstructions}
            handleInstructionsChange={handleInstructionsChange}
          />
        </Box>
        <Box className={classes.section}>
          <AssistantModel
            modelName={modelName}
            handleModelChange={handleModelChange}
          />
          <AssistantTemperature
            temperature={temperature}
            setTemperature={setTemperature}
          />
        </Box>
        <Box className={classes.section}>
          {/* <AssistantTools />  // TBD */}
        </Box>
        <Box className={classes.sectionFiles}>
          <Typography variant="p" className={classes.filesTitle}>
            FILES
          </Typography>
        </Box>
      </Box>
    </ScrollBox>
  );
};

export default PlaygroundPanel;
