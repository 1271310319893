import React, { useEffect, useState } from "react";

import { firebaseFunctions, httpCallables } from "../../firebase";
import makeStyles from "@mui/styles/makeStyles";
import { DataGrid } from "@mui/x-data-grid";
import { useImpersonate } from "../../hooks";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  TextField,
  InputLabel,
  Select,
  DialogActions,
  DialogContent,
  MenuItem,
  Typography,
  LinearProgress
} from "@mui/material";
import { Link } from "react-router-dom";
import RemoveUserMenu from "./RemoveUserMenu";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  link: {
    cursor: "pointer",
    color: "blue",
    textTransform: "underline"
  },
  container: {
    width: "100w",
    height: "100vh"
  },
  dialog: {
    backgroundColot: "red"
  },
  tableContainer: {
    width: "80%",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: theme.spacing(3)
  },
  tableHead: {
    fontWeight: "800"
  }
}));

function CourseUsers({ courseId }) {
  const classes = useStyles();
  const { impersonate } = useImpersonate();
  const currentUser = useSelector((state) => state.user.auth);

  const [users, setUsers] = useState([]);
  const [openUsers, setOpenUsers] = React.useState(false);

  const [email, setEmail] = React.useState("");
  const [name, setName] = React.useState("");
  const [role, setRole] = React.useState("Student");

  const [usersFetched, setUsersFetched] = React.useState(false);

  useEffect(() => {
    setUsersFetched(false);
    httpCallables
      .adminFunctions({
        func_name: "getAllCourseUsers",
        course: courseId
      })
      .then((response) => {
        setUsers(response.data);
        setUsersFetched(true);
      });
  }, [courseId]);

  const saveUser = () => {
    httpCallables
      .adminFunctions({
        func_name: "addCourseUser",
        email: email.trim(),
        course_id: Number(courseId),
        name: name,
        role: role
      })
      .then(({ data }) => {
        if (data.success) {
          const { user } = data;
          setUsers([
            ...users,
            {
              id: user.id,
              name: user.uid,
              email: user.email,
              role,
              course_user: user.uid
            }
          ]);
        }
      });
    handleClose();
  };

  const handleClose = () => {
    setOpenUsers(false);
    setEmail("");
    setName("");
    setRole("Student");
  };

  const renderCreateCourseDialog = () => {
    return (
      <Dialog
        open={openUsers}
        PaperProps={{
          style: {
            // backgroundColor: "white",
          }
        }}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
        <DialogContent className={classes.dialog}>
          <TextField
            variant="standard"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            autoFocus
            margin="dense"
            aria-label="course-user-email-input"
            id="email"
            label="email"
            type="text"
            value={email}
            fullWidth
          />
          <TextField
            variant="standard"
            onChange={(e) => {
              setName(e.target.value);
            }}
            value={name}
            aria-label="course-user-name-input"
            autoFocus
            margin="dense"
            id="name"
            label="name"
            type="text"
            fullWidth
          />
          <InputLabel shrink id="demo-simple-select-placeholder-label-label">
            Role
          </InputLabel>
          <Select
            variant="standard"
            labelId="demo-simple-select-placeholder-label-label"
            id="demo-simple-select-placeholder-label"
            value={role}
            displayEmpty
            className={classes.selectEmpty}>
            {["Student", "Teacher", "Admin"].map((item, index) => {
              return (
                <MenuItem
                  key={index}
                  onClick={() => {
                    setRole(item);
                  }}
                  value={item}>
                  {" "}
                  {item}
                </MenuItem>
              );
            })}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={saveUser} color="primary">
            Add User
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const columns = [
    { field: "email", headerName: "Email", width: 200 }, // width: 70
    { field: "name", headerName: "Name", width: 150 }, //width: 130
    { field: "role", headerName: "Role", width: 100 }, //width: 130
    { field: "status", headerName: "Status", width: 100 }, //width: 130

    {
      field: "userConversations",
      headerName: "",
      width: 160,
      renderCell: ({ row }) => {
        return (
          <Link
            to={`/admin/conversations?course_id=${courseId}&user_id=${row.course_user}`}>
            User&apos;s conversations
          </Link>
        );
      }
    },
    {
      field: "userActions",
      headerName: "",
      width: 130,
      renderCell: ({ row }) => {
        return (
          currentUser.isAdmin && (
            <RemoveUserMenu row={row} courseId={courseId} setUsers={setUsers} />
          )
        );
      }
    }
  ];

  const impColumns = [
    {
      field: "last_active",
      headerName: "Last Active",
      type: "dateTime",
      width: 250
    },
    {
      field: "course_user",
      headerName: "Impersondate",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 300,
      renderCell: (params) => {
        return (
          <Typography
            className={classes.link}
            onClick={() => {
              impersonate(params.value);
            }}>
            {params.value}
          </Typography>
        );
      }
    }
  ];

  const topUsers = [
    "gS7LsuEEP7OYp9ZjSEVOO0HLlIa2",
    "1bjgVIcI8dTpATOIZUEZPo3N8Dw1",
    "LaNTsYWwzyOuYHyeRESRVxvaPhL2"
  ];
  const usersSorted = [
    ...users.filter((u) => topUsers.includes(u.course_user)),
    ...users.filter((u) => !topUsers.includes(u.course_user))
  ];

  return usersFetched ? (
    <Box>
      {currentUser.isAdmin && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setOpenUsers(true);
          }}
          sx={{ marginBottom: "16px" }}>
          Add User
        </Button>
      )}
      <div style={{ height: "max-content", width: "90%" }}>
        <DataGrid
          autoHeight={true}
          rows={users}
          columns={columns}
          // className={classes.backgroundWhite}
          pageSize={50}
        />
      </div>
      {currentUser.isAdmin && (
        <>
          <Typography sx={{ marginBottom: "8px", marginTop: "16px" }}>
            Impersonate Users:
          </Typography>

          <div style={{ height: "max-content", width: "90%" }}>
            <DataGrid
              autoHeight={true}
              rows={usersSorted}
              columns={impColumns}
              className={classes.backgroundWhite}
              pageSize={50}
            />
          </div>
        </>
      )}
      {renderCreateCourseDialog()}
    </Box>
  ) : (
    <Box sx={{ height: "32px", display: "flex", alignItems: "center" }}>
      <LinearProgress color="secondary" sx={{ width: "80%" }} />
    </Box>
  );
}

export default CourseUsers;
