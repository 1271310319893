import React from "react";
import useLogUserSession from "../../hooks/useLogUserSession";

export function StepSessionRecorder({ step, task }) {
  const { course_id, id, text_id } = task;
  const LogUserSessionComponent = () => {
    useLogUserSession(`${step}_SESSION`, {
      course_id,
      task_id: id,
      text_id
    });
    return null;
  };
  return <LogUserSessionComponent />;
}
