import { IconButton, Popover, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import RichTooltip from "./RichTooltip";
import React, { useState } from "react";
import { useIntl } from "react-intl";

function ReviewEvidenceToolTip() {
  // Hooks
  const intl = useIntl();

  // Ephemeral state
  const [AnchorEl, setAnchorEl] = useState(null);

  //ToolTip
  const showToolTip = Boolean(AnchorEl);
  const tooltipId = showToolTip
    ? "review-supporting-evidence-tooltip-popover"
    : undefined;

  return (
    <>
      <IconButton
        aria-label={"Review supporting evidence info"}
        aria-haspopup={"true"}
        aria-expanded={showToolTip}
        aria-controls={tooltipId}
        onClick={(event) => setAnchorEl(event.currentTarget)}>
        <InfoOutlinedIcon color="primary" size="small" />
      </IconButton>
      <Popover
        id={tooltipId}
        open={showToolTip}
        anchorEl={AnchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}>
        <RichTooltip
          style={{ width: "300px" }}
          title="task.stage.ReviewSupportingEvidence"
          bodyHtml={
            <Typography>
              {intl.formatMessage({
                id: "task.stage.ReviewSupportingEvidenceToolTip",
                defaultMessage:
                  "You can choose to review your highlighted evidence with the help of " +
                  "Alethea's academic coach chat bot. It's optional, and the conversation will not be " +
                  "visible to your instructor."
              })}
            </Typography>
          }
        />
      </Popover>
    </>
  );
}

export default ReviewEvidenceToolTip;
