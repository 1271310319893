import React, { useEffect, useRef, useMemo } from "react";
import { useIntl } from "react-intl";
import { Box, Divider, Typography } from "@mui/material";
import * as d3 from "d3";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  pieChartContainer: {
    backgroundColor: theme.palette.background.paper,
    // border: `1px solid ${theme.palette.divider}`,
    borderRadius: "15px",
    padding: theme.spacing(2)
  },
  pieChart: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  title: {
    alignSelf: "flex-start",
    paddingBlockEnd: theme.spacing(1)
  },
  footer: {
    alignSelf: "flex-start",
    paddingBlockStart: theme.spacing(1)
  }
}));

const TitleText = ({ text, ...restProps }) => {
  const classes = useStyles();

  return (
    <Box component="header" {...restProps} className={classes.title}>
      <Typography variant="h5">{text}</Typography>
    </Box>
  );
};

export const Footer = ({ footer, placeholders, ...restProps }) => {
  const calcNote = useMemo(() => {
    return (note, placeholders) => {
      return Object.entries(placeholders || {}).reduce((prev, [key, value]) => {
        return prev.replace(key, value);
      }, note);
    };
  }, []);
  const classes = useStyles();
  const intl = useIntl();
  const footerNote = calcNote(
    intl.formatMessage({
      id: `${footer}`,
      defaultMessage: footer
    }),
    placeholders
  )
    .replace("$DATA", "7")
    .replace("$DATA2", "30");
  return (
    <Box component="header" {...restProps} className={classes.footer}>
      <Typography variant="p">{footerNote}</Typography>
    </Box>
  );
};

export const PieChart = ({
  data,
  width,
  height,
  title,
  footer,
  placeholders
}) => {
  const chartRef = useRef();
  const intl = useIntl();
  const classes = useStyles();
  useEffect(() => {
    if (width > 0 && height > 0) {
      // Render the chart using D3.js
      const svg = d3
        .select(chartRef.current)
        .append("svg")
        .attr("width", width)
        .attr("height", height);

      try {
        const pie = d3.pie().value((d) => d.val);

        const arcGenerator = d3
          .arc()
          .innerRadius((0.6 * Math.min(width, height)) / 2.5)
          .outerRadius((0.8 * Math.min(width, height)) / 2.5);

        const arcs = pie(data);

        const arcGroup = svg
          .append("g")
          .attr("transform", `translate(${width / 2.2}, ${height / 2.6})`);

        // Add path elements for each pie slice
        arcGroup
          .selectAll("path")
          .data(arcs)
          .enter()
          .append("path")
          .attr("d", (d) =>
            arcGenerator({
              startAngle: 0,
              endAngle: (d.data.percentage / 100) * (2 * Math.PI) // Adjusting endAngle based on val
            })
          )
          .attr("fill", (d, i) => `rgba(0, 60, 143, 1)`);

        // Add grey part to complete the circle
        arcGroup
          .append("path")
          .attr(
            "d",
            arcGenerator({
              startAngle: (data[0].percentage / 100) * (2 * Math.PI),
              endAngle: 2 * Math.PI
            })
          )
          .attr("fill", "rgba(0, 0, 0, 0.12)");

        // Add text label to the center of the entire chart
        svg
          .append("text")
          .attr("transform", `translate(${width / 2.1}, ${height / 2.4})`)
          .style("text-anchor", "middle")
          .style("fill", "black")
          .style("font-size", "48px") // Adjust font size as needed
          .text(`${data[0].percentage}%`);
      } catch (error) {
        console.error("Error rendering chart:", error);
      }
    }
  }, [data, width, height]);

  return (
    <div
      style={{ width: "100%", height: "100%" }}
      className={classes.pieChartContainer}>
      <TitleText
        text={intl.formatMessage({
          id: `${title}`,
          defaultMessage: `${title}`
        })}
      />
      <Divider />
      <Box className={classes.pieChart}>
        <svg ref={chartRef} width={width} height={height / 1.2} />
      </Box>
      <Divider />
      <Footer footer={footer} placeholders={placeholders} />
    </div>
  );
};
