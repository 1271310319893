import { firestore } from "../firebase";
import { doc, updateDoc } from "firebase/firestore";
import store from "../redux/store";

const notificationsAPI = {
  markAsRead: async (notificationId) => {
    const state = store.getState();
    const currentUserUid = state.firebase.auth.uid;

    const notificationRef = doc(
      firestore,
      `notifications/${currentUserUid}/userNotifications`,
      notificationId
    );

    await updateDoc(notificationRef, {
      isRead: true,
      includeInCounter: false
    }).catch((error) => {
      console.error("Error marking notification as read:", error);
    });
  },
  markAllAsRead: async () => {
    const state = store.getState();
    const currentUserUid = state.firebase.auth.uid;

    const batch = firestore.batch();
    firestore
      .collection("notifications")
      .doc(currentUserUid)
      .collection("userNotifications")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const docRef = firestore
            .collection("notifications")
            .doc(currentUserUid)
            .collection("userNotifications")
            .doc(doc.id);
          batch.update(docRef, {
            isRead: true,
            includeInCounter: false
          });
        });

        batch.commit();
      })
      .catch((error) => {
        console.error("Error marking notifications as read:", error);
      });
  }
};

export default notificationsAPI;
