// Dependencies
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocalStorage } from "./hooks/useLocalStorage";

// Components
import { FormattedMessage } from "react-intl";

// Material UI
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogActions
} from "@mui/material";

// Utility function to validate version format
const isValidVersion = (version) => {
  // Adjusted to accept basic versioning like "x.y" or "x.y.z"
  return typeof version === "string" && /^\d+(\.\d+)*$/.test(version);
};

// Main component
export default function VersionUpdateDialog() {
  // Getting the current app version from the Firestore state
  const firestoreAppVersion = useSelector(
    (state) => state.firestore.data?.defaultConfig?.VERSION
  );
  // Using a custom hook to manage remote config stored in local storage
  const [remoteConfigRaw, setRemoteConfigRaw] = useLocalStorage(
    "conf.lastRemoteConfig",
    "{}" // Defaulting to an empty JSON string if not present
  );

  // Function to parse the remote config string into an object
  const parseRemoteConfig = (raw) => {
    try {
      // Attempt to parse JSON string to object
      return JSON.parse(JSON.parse(raw));
    } catch (error) {
      console.error("Failed to parse remoteConfigRaw:", error);
      // Return a default empty object in case of parse error
      return {};
    }
  };

  // Memoized calculation to determine if the version update dialog should be shown
  const shouldShow = useMemo(() => {
    // Parse the remote configuration
    const remoteConfigParsed = parseRemoteConfig(remoteConfigRaw);

    // Ensure the version exists and is valid in both remoteConfigParsed and firestoreAppVersion
    if (!isValidVersion(firestoreAppVersion)) {
      console.warn(
        "Invalid Firestore version format detected:",
        firestoreAppVersion
      );
      return false;
    }

    if (!isValidVersion(remoteConfigParsed?.version)) {
      console.warn(
        "Invalid remote version format detected:",
        remoteConfigParsed?.version
      );
      return false;
    }

    // Check if versions are different
    return firestoreAppVersion !== remoteConfigParsed.version;
  }, [remoteConfigRaw, firestoreAppVersion]); // Dependencies for useMemo

  // Function to update the remote config in local storage with the latest version
  const updateRemoteConfig = () => {
    const remoteConfigParsed = parseRemoteConfig(remoteConfigRaw);

    // Prepare the payload to update local storage
    const payload = {
      recaptcha_key: remoteConfigParsed?.recaptcha_key || "",
      version: firestoreAppVersion
    };

    // Save the updated configuration as a stringified JSON object
    setRemoteConfigRaw(JSON.stringify(payload));
  };

  // Render the dialog with the update message
  return (
    <Dialog open={shouldShow} onClose={updateRemoteConfig}>
      <DialogContent>
        <Typography>
          <FormattedMessage
            id="upgrade.msg"
            defaultMessage="A new Alethea version is available, click ‘Reload’ to update"
          />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          aria-label={"Reload"}
          sx={{
            backgroundColor: "#168fee",
            color: "#ffffff",
            textTransform: "none",
            "&:hover": {
              background: "#1172be"
            }
          }}
          onClick={() => {
            updateRemoteConfig();
            window.location.reload(true);
          }}>
          <FormattedMessage id="upgrade.reload" defaultMessage="Reload" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
