import React, { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export const ZendeskLabels = ({ searchTerm = "", labels = [] }) => {
  // Hooks
  const { pathname } = useLocation();
  // Global State
  const currentRole = useSelector(
    (state) =>
      state.user.userProfile.selectedCourse?.course_role?.toLowerCase() || ""
  );
  const selectedTaskId = useSelector((state) => state.tasks.selectedTaskId);
  const tasks = useSelector((state) => state.tasks.tasks);
  const step = useSelector((state) => state.task.step);
  const grStep = useSelector((state) => state.gr.stage);

  const zendeskLabels = useMemo(() => {
    // Generate base label from pathname
    let baseLabel = pathname.replace(/\//g, "::");

    // Define flags for specific paths
    const isNewTask = pathname === "/tasks/new";
    const isTasksPage = pathname === "/tasks";
    const isSpecificPath = ["/task", "/reader"].includes(pathname);

    // Define a student FAQ label if the current role is student
    const studentFaqLabel = currentRole === "student" ? ["student::faq"] : [];

    // Handle specific cases
    if (isNewTask) {
      baseLabel = "new::task";
      return [...studentFaqLabel, `${baseLabel}`, ...labels];
    }

    if (isTasksPage) {
      return [...studentFaqLabel, `${currentRole}${baseLabel}`, ...labels];
    }

    if (isSpecificPath) {
      const stepByLocation = pathname === "/reader" ? grStep : step;

      if (selectedTaskId) {
        const filteredTask = tasks.find(
          (task) => task.id === Number(selectedTaskId)
        );
        const taskType = filteredTask?.task_type;

        if (taskType === "standard" && step === "highlight") {
          return [
            ...studentFaqLabel,
            `${currentRole}::${taskType}::step::${stepByLocation}`,
            ...labels
          ];
        }

        if (taskType === "guidedReading" && step === "highlight") {
          return [
            ...studentFaqLabel,
            `${currentRole}::gr::step::${stepByLocation}`,
            ...labels
          ];
        }
      }

      return [
        ...studentFaqLabel,
        `${currentRole}${baseLabel}::step::${stepByLocation}`,
        ...labels
      ];
    }

    // Default return value
    return [...studentFaqLabel, `${currentRole}${baseLabel}`, ...labels];
  }, [pathname, labels, currentRole, step, grStep, tasks, selectedTaskId]);

  useEffect(() => {
    const suggestions = {
      url: false,
      search: searchTerm || true,
      labels: zendeskLabels
    };

    if (window?.zE) {
      window.zE("webWidget", "helpCenter:setSuggestions", suggestions);
    }
  }, [zendeskLabels, searchTerm]);

  return null;
};
