import { colorMapping } from "./consts";
import {
  parseISO,
  format,
  formatDuration,
  millisecondsToMinutes
} from "date-fns";
import { isAfter, isBefore } from "date-fns";

export const processSubmissionsStatus = (SUBMISSIONS_STATUS) => {
  // Deep copy of the original object to avoid mutation
  let result = JSON.parse(JSON.stringify(SUBMISSIONS_STATUS));
  let totalSubmissions = result.totalSubmissions;
  delete result.totalSubmissions;

  const processTask = (task) => {
    const processedTask = {};
    Object.keys(task).forEach((status) => {
      const currentStatus = task[status];

      if (status === "SubmissionPending" || status === "SubmissionMissed") {
        processedTask[status] = {
          total:
            currentStatus.complete +
            currentStatus.incomplete +
            (currentStatus.no_activity || 0)
        };
      } else {
        processedTask[status] = {
          complete: currentStatus.complete,
          incomplete:
            currentStatus.incomplete + (currentStatus.no_activity || 0)
        };
      }
    });
    return processedTask;
  };
  const updatedResult = {
    statusBased: processTask(result.statusBased),
    userBased: result.userBased
  };

  // Calculate submissionRate as the percentage of the sum of SubmissionOnTime and SubmissionLate complete and incomplete divided by totalSubmissions

  let totalSubmissionsOnTimeAndLate = 0;
  const calculateTotal = (task) => {
    if (task.SubmissionOnTime) {
      totalSubmissionsOnTimeAndLate +=
        task.SubmissionOnTime.complete + task.SubmissionOnTime.incomplete;
    }
    if (task.SubmissionLate) {
      totalSubmissionsOnTimeAndLate +=
        task.SubmissionLate.complete + task.SubmissionLate.incomplete;
    }
  };

  calculateTotal(updatedResult.statusBased);

  updatedResult.submissionRate =
    Number(
      ((totalSubmissionsOnTimeAndLate / totalSubmissions) * 100).toFixed(0)
    ) || 0;
  updatedResult.totalSubmissions = totalSubmissions;
  return updatedResult;
};

export function flattenObject(obj, parentKey = "", separator = "_") {
  let flattened = {};

  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      let newKey = parentKey ? parentKey + separator + key : key;
      if (typeof obj[key] === "object" && obj[key] !== null) {
        Object.assign(flattened, flattenObject(obj[key], newKey, separator));
      } else {
        flattened[newKey] = obj[key];
      }
    }
  }

  return flattened;
}

export const sortSubmissionsObject = (data) => {
  if (!data) return;
  const sortOrder = {
    SubmissionOnTime: 0,
    SubmissionLate: 1,
    SubmissionMissed: 2,
    SubmissionPending: 3
  };

  const dataArray = Object.entries(data);

  // If a key is not found in sortOrder, it will get a default value of Infinity, placing it at the end
  dataArray.sort(
    (a, b) =>
      (sortOrder[a[0]] !== undefined ? sortOrder[a[0]] : Infinity) -
      (sortOrder[b[0]] !== undefined ? sortOrder[b[0]] : Infinity)
  );

  const sortedData = {};
  dataArray.forEach(([key, value]) => {
    sortedData[key] = value;
  });

  return sortedData;
};

export const sortTimeSpentOnAssignmentObject = (data) => {
  if (!data) return;

  const sortOrder = {
    HIGHLIGHT_SESSION: 0,
    REVIEW_SESSION: 1,
    ANSWER_SESSION: 2
  };

  const dataArray = Object.entries(data);

  // If a key is not found in sortOrder, it will get a default value of Infinity, placing it at the end
  dataArray.sort(
    (a, b) =>
      (sortOrder[a[0]] !== undefined ? sortOrder[a[0]] : Infinity) -
      (sortOrder[b[0]] !== undefined ? sortOrder[b[0]] : Infinity)
  );

  const sortedData = {};
  dataArray.forEach(([key, value]) => {
    sortedData[key] = value;
  });

  return sortedData;
};

export function formatLegendLabel(label) {
  if (label.includes("Submission")) {
    // Remove 'Submission' and process the rest
    const restOfLabel = label.replace("Submission", "");

    if (restOfLabel.includes("Missed")) {
      return "Missed";
    } else if (restOfLabel.includes("Pending")) {
      return "Pending";
    } else {
      const prefix = restOfLabel.includes("OnTime") ? "On Time" : "Late";

      if (restOfLabel.includes("incomplete")) {
        return `${prefix} incomplete`;
      } else if (restOfLabel.includes("complete")) {
        return prefix;
      }

      return restOfLabel.replace(/_/g, " ").trim();
    }
  }
  return label;
}

export function calculateSubmissionPercentage(data, totalSubmissions) {
  return `${Math.floor((Number(data) / totalSubmissions) * 100)}%`;
}

export const transformSubmissionsData = (data, theme) => {
  const transformedData = data.map((item) => {
    return {
      cat: item[0],
      val: item[1],
      color: theme.palette.pieChart[item[0]] || "#E0E0E0"
    };
  });

  return transformedData;
};

export const removeFutureAssignments = (obj) => {
  let totalPending = 0;
  // Helper function to recursively traverse and copy the object
  function traverseAndCopy(obj) {
    let newObj = Array.isArray(obj) ? [] : {};

    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (key === "SubmissionPending" && obj[key].total) {
          totalPending += obj[key].total;
        } else if (typeof obj[key] === "object" && obj[key] !== null) {
          newObj[key] = traverseAndCopy(obj[key]);
        } else {
          newObj[key] = obj[key];
        }
      }
    }

    return newObj;
  }

  function removeSubmissionPending(obj) {
    let newObj = {};
    for (let key in obj) {
      if (
        Object.prototype.hasOwnProperty.call(obj, key) &&
        key !== "SubmissionPending"
      ) {
        newObj[key] = traverseAndCopy(obj[key]);
      }
    }
    return newObj;
  }

  const cleanedData = removeSubmissionPending(obj);

  return { cleanedData, totalPending: totalPending }; // because it sums for both taskBased & statusBased
};

export const formatDate = (dateString) => {
  const date = parseISO(dateString);
  return format(date, "dd/MM/yyyy");
};

function calculatePercentages(data) {
  const result = {};

  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const entry = data[key];
      const total = entry.total;

      // Initialize a new entry with the same id and total
      result[key] = { id: entry.id, total: total };

      // Calculate percentages for each key-value pair except id and total
      for (const prop in entry) {
        if (
          Object.prototype.hasOwnProperty.call(entry, prop) &&
          prop !== "id" &&
          prop !== "total"
        ) {
          result[key][prop] = ((entry[prop] / total) * 100).toFixed(0);
        }
      }
    }
  }

  return result;
}

export const processStackedGraphDataBySubmissionStatus = (data) => {
  const keys = [
    "SubmissionMissed_total",
    "SubmissionLate_complete",
    "SubmissionLate_incomplete",
    "SubmissionOnTime_complete",
    "SubmissionOnTime_incomplete",
    "SubmissionPending_total"
  ];
  let formattedData = {};

  for (const [key, value] of Object.entries(data)) {
    const [userId, id, category, prefix, subcategory] = key.split("_");
    if (!formattedData[id]) {
      formattedData[id] = {
        id,
        total: 0,
        SubmissionMissed_total: 0,
        SubmissionLate_complete: 0,
        SubmissionLate_incomplete: 0,
        SubmissionOnTime_complete: 0,
        SubmissionOnTime_incomplete: 0,
        SubmissionPending_total: 0
      };
    }
    const fullKey =
      subcategory || category === "SubmissionMissed"
        ? `${category}_total`
        : `${category}_${prefix}`;
    formattedData[id].total += Number(value);

    formattedData[id][fullKey] += Number(value);
  }
  formattedData = calculatePercentages(formattedData);
  return { keys, data: Object.values(formattedData) };
};

export const processStackedGraphDataByAssignmentStepTime = (data) => {
  const keys = [
    "REVIEW_SESSION_total",
    "HIGHLIGHT_SESSION_total",
    "ANSWER_SESSION_total"
  ];

  let formattedData = {};

  for (const [key, value] of Object.entries(data)) {
    const [id, sessionType, session] = key.split("_");

    if (!formattedData[id]) {
      formattedData[id] = {
        id,
        total: 0,
        REVIEW_SESSION_total: 0,
        HIGHLIGHT_SESSION_total: 0,
        ANSWER_SESSION_total: 0
      };
    }

    formattedData[id][`${sessionType}_${session}_total`] +=
      millisecondsToMinutes(Number(value));
    formattedData[id].total += Number(value);
  }

  return { keys, data: Object.values(formattedData) };
};

// Function to format time duration in minutes
export function formatMillisecondsTime(data, courseAssignments = null) {
  const durationInMinutes = courseAssignments?.length
    ? millisecondsToMinutes(data) / courseAssignments.length
    : millisecondsToMinutes(data);

  // Round down to the nearest whole number of minutes
  const roundedMinutes = Math.floor(durationInMinutes);

  // Return the formatted time in minutes
  return `${roundedMinutes} min`;
}

export const truncateMiddle = (text, maxLength) => {
  if (text.length <= maxLength) {
    return text;
  }

  const ellipsis = "...";

  // Split the text into words
  const words = text.split(" ");

  // Initialize variables to track the length of the truncated text
  let truncatedText = "";
  let currentLength = 0;

  // Iterate through the words
  for (const word of words) {
    // Check if adding the current word exceeds the maxLength
    if (currentLength + word.length + ellipsis.length <= maxLength) {
      truncatedText += `${word} `;
      currentLength += word.length + 1; // Add 1 for the space
    } else {
      // Stop if adding the current word exceeds the maxLength
      break;
    }
  }

  // Trim the trailing space and add the ellipsis
  truncatedText = truncatedText.trim() + ellipsis;

  return truncatedText;
};

export function normalizeGrade(grade) {
  if (typeof grade === "string") {
    // Normalize from A-F scale
    grade = grade.toUpperCase();
    const letterGrades = {
      "A+": 100,
      A: 95,
      "A-": 90,
      "B+": 87,
      B: 85,
      "B-": 80,
      "C+": 77,
      C: 75,
      "C-": 70,
      "D+": 67,
      D: 65,
      "D-": 60,
      F: 50
    };
    return letterGrades[grade] !== undefined ? letterGrades[grade] : 0;
  } else if (typeof grade === "number") {
    if (grade >= 0 && grade <= 10) {
      // Normalize from 1-10 scale to 1-100 scale
      return (grade / 10) * 100;
    } else if (grade >= 0 && grade <= 100) {
      // Already in the correct scale
      return grade;
    } else {
      // For any other number, assume it's in a 0-100 scale but needs to be clamped
      return Math.min(Math.max(grade, 0), 100);
    }
  }
  // If the grade type is unrecognized, return 0
  return 0;
}

export function reverseNormalizeGrade(grade, maxGrade) {
  if (typeof grade === "string") {
    // Reverse normalize from A-F scale
    const letterGrades = {
      100: "A+",
      95: "A",
      90: "A-",
      87: "B+",
      85: "B",
      80: "B-",
      77: "C+",
      75: "C",
      70: "C-",
      67: "D+",
      65: "D",
      60: "D-",
      50: "F"
    };
    return letterGrades[normalizeGrade(grade)] !== undefined ? grade : "F";
  } else if (typeof grade === "number") {
    if (maxGrade === 100) {
      // Reverse normalize from 0-100 scale to 0-10 scale
      return grade;
    } else if (maxGrade < 100 && maxGrade !== 0) {
      // Already in the correct scale
      return (grade / maxGrade) * 100;
    }
  }
  // If the grade type is unrecognized, return 0
  return 0;
}

export const sumTaskById = (data) => {
  let accumulatedTasks = {};

  Object.values(data).forEach((userTasks) => {
    Object.entries(userTasks).forEach(([taskId, taskStatuses]) => {
      if (!accumulatedTasks[taskId]) {
        accumulatedTasks[taskId] = {
          SubmissionMissed: 0,
          SubmissionOnTime_complete: 0,
          SubmissionOnTime_incomplete: 0,
          SubmissionPending: 0,
          SubmissionLate_complete: 0,
          SubmissionLate_incomplete: 0
        };
      }

      Object.entries(taskStatuses).forEach(([statusType, statusCounts]) => {
        if (
          statusType === "SubmissionMissed" ||
          statusType === "SubmissionPending"
        ) {
          accumulatedTasks[taskId][statusType] += statusCounts.no_activity || 0;
        } else if (
          statusType === "SubmissionOnTime" ||
          statusType === "SubmissionLate"
        ) {
          accumulatedTasks[taskId][`${statusType}_complete`] +=
            statusCounts.complete || 0;
          accumulatedTasks[taskId][`${statusType}_incomplete`] +=
            statusCounts.incomplete || 0;
        }
      });
    });
  });

  return accumulatedTasks;
};

export function transformDataPerAssignment(data) {
  const taskBasedTimeSpentOnAssignment = {};

  function addToTask(taskId, sessionType, duration, userId) {
    if (!taskBasedTimeSpentOnAssignment[taskId]) {
      taskBasedTimeSpentOnAssignment[taskId] = {
        REVIEW_SESSION: 0,
        HIGHLIGHT_SESSION: 0,
        ANSWER_SESSION: 0
      };
    }
    if (!taskBasedTimeSpentOnAssignment[taskId][sessionType]) {
      taskBasedTimeSpentOnAssignment[taskId][sessionType] = {
        totalDuration: 0,
        userIds: new Set()
      };
    }
    taskBasedTimeSpentOnAssignment[taskId][sessionType].totalDuration +=
      duration;
    taskBasedTimeSpentOnAssignment[taskId][sessionType].userIds.add(userId);
  }

  data.REVIEW_TIME.forEach((item) => {
    addToTask(item.task_id, "REVIEW_SESSION", item.duration, item.user_id);
  });

  data.HIGHLIGHT_TIME.forEach((item) => {
    addToTask(item.task_id, "HIGHLIGHT_SESSION", item.duration, item.user_id);
  });

  data.ANSWER_TIME.forEach((item) => {
    addToTask(item.task_id, "ANSWER_SESSION", item.duration, item.user_id);
  });

  // Calculate average duration per user
  for (const taskId in taskBasedTimeSpentOnAssignment) {
    for (const sessionType in taskBasedTimeSpentOnAssignment[taskId]) {
      if (
        sessionType !== "REVIEW_SESSION" &&
        sessionType !== "HIGHLIGHT_SESSION" &&
        sessionType !== "ANSWER_SESSION"
      ) {
        continue;
      }
      const sessionData = taskBasedTimeSpentOnAssignment[taskId][sessionType];
      const userCount = sessionData?.userIds?.size;
      if (userCount > 0) {
        taskBasedTimeSpentOnAssignment[taskId][sessionType] = Number(
          sessionData.totalDuration / userCount
        ).toFixed(0);
      } else {
        taskBasedTimeSpentOnAssignment[taskId][sessionType] = 0;
      }
    }
  }

  return taskBasedTimeSpentOnAssignment;
}

export function aggregateTaskData(taskData) {
  const courseViewTimeSpentOnAssignment = {
    REVIEW_SESSION: { total: 0, taskIds: new Set() },
    HIGHLIGHT_SESSION: { total: 0, taskIds: new Set() },
    ANSWER_SESSION: { total: 0, taskIds: new Set() }
  };
  Object.entries(taskData).forEach(([taskId, sessionData]) => {
    ["REVIEW_SESSION", "HIGHLIGHT_SESSION", "ANSWER_SESSION"].forEach(
      (sessionType) => {
        if (sessionData[sessionType]) {
          courseViewTimeSpentOnAssignment[sessionType].total += Number(
            sessionData[sessionType]
          );
          courseViewTimeSpentOnAssignment[sessionType].taskIds.add(taskId);
        }
      }
    );
  });

  ["REVIEW_SESSION", "HIGHLIGHT_SESSION", "ANSWER_SESSION"].forEach(
    (sessionType) => {
      const taskCount =
        courseViewTimeSpentOnAssignment[sessionType].taskIds.size;
      courseViewTimeSpentOnAssignment[sessionType].total /= taskCount;
      delete courseViewTimeSpentOnAssignment[sessionType].taskIds; // Clean up user IDs from the result
    }
  );

  return courseViewTimeSpentOnAssignment;
}

export function getSessionAveragesPerTaskAndStudent(data) {
  const courseViewTimeSpentOnAssignment = {
    REVIEW_SESSION: { total: 0, userIds: new Set() },
    HIGHLIGHT_SESSION: { total: 0, userIds: new Set() },
    ANSWER_SESSION: { total: 0, userIds: new Set() }
  };

  function addToTotal(sessionType, duration, userId) {
    courseViewTimeSpentOnAssignment[sessionType].total += duration;
    courseViewTimeSpentOnAssignment[sessionType].userIds.add(userId);
  }

  data?.REVIEW_TIME.forEach((item) => {
    addToTotal(
      "REVIEW_SESSION",
      item.duration,
      item.user_id + "_" + item.task_id
    );
  });

  data?.HIGHLIGHT_TIME.forEach((item) => {
    addToTotal(
      "HIGHLIGHT_SESSION",
      item.duration,
      item.user_id + "_" + item.task_id
    );
  });

  data?.ANSWER_TIME.forEach((item) => {
    addToTotal(
      "ANSWER_SESSION",
      item.duration,
      item.user_id + "_" + item.task_id
    );
  });

  for (const sessionType in courseViewTimeSpentOnAssignment) {
    const sessionData = courseViewTimeSpentOnAssignment[sessionType];
    const distinctUserCount = sessionData.userIds.size;
    sessionData.total /= distinctUserCount;
    delete sessionData.userIds; // Clean up user IDs from the result
  }
  return courseViewTimeSpentOnAssignment;
}

export function extractAssignmentNumberFromString(label) {
  // Use a regular expression to match the number at the beginning of the string
  const match = label.match(/^\d+/);
  // If a match is found, return it as a number
  return match ? parseInt(match[0], 10) : null;
}

// user submissions utils
export function getSubmissionStatus(submission) {
  // takes submission obj and return an obj with user uid and submission status as string
  return {
    id: submission.owner,
    status: calculateSubmissionStatus(submission)
  };
}

export function calculateSubmissionStatus(submission) {
  // takses a submission obj and return the status a string
  const submissionsDate = submission.submission_date;
  const today = new Date();
  const dueDate = new Date(submission.due_date);
  const submissionDate = submissionsDate ? new Date(submissionsDate) : null;
  const isSubmitted = Boolean(submissionDate);

  // submitted before the due date
  if (isSubmitted && isBefore(submissionDate, dueDate)) return "submitted";
  // submitted after the due date
  else if (isSubmitted && isAfter(submissionDate, dueDate)) return "late";
  // not submitted and due date passed
  else if (!isSubmitted && isAfter(today, dueDate)) return "missed";
  // not submitted and due date not passed
  else return "pending";
}

export function unifyIncompleteToComplete(obj) {
  function processObject(target) {
    if (typeof target === "object" && target !== null) {
      for (const key in target) {
        if (typeof target[key] === "object" && target[key] !== null) {
          processObject(target[key]);
        }
        if (key === "complete" && "incomplete" in target) {
          target[key] += target.incomplete;
          delete target.incomplete;
        }
      }
    }
  }

  const result = JSON.parse(JSON.stringify(obj)); // Deep copy the object
  processObject(result);
  return result;
}
